import React from 'react'
import { Button } from '../../common_component/base/button'
import { useForm } from "react-hook-form";
import TopUpInfo from './TopUpInfo';
import InputField from '../../common_component/form/InputField';
import api from 'networking/api';
import PoliLogo from '../../assets/images/poli-logo.png';
import { tryConvertDollarStringToCents, formatCentsPriceForDisplay } from '../../util/string_util';
import analytics from '../../util/googleAnalytics';
import { useStore } from '../../store/store';
import PoliBankTypeLogos from '../../assets/images/poli-bank-logos.png';
import KindoWell from '../../common_component/KindoWell'

type FormData = {
  amount: string;
};

type Props = {
  totalPrice?: number;
  orderIds?: any;
  onPurchase?: () => void;
  onError?: (error: any) => void;
};

const PoliForm = ({ totalPrice, orderIds, onPurchase, onError }: Props) => {
  const store = useStore();
  const isCommunityShop = store.shop.isCommunityShop;

  const { handleSubmit, control, setValue } = useForm<FormData>({
    defaultValues: {
      amount: isCommunityShop ? formatCentsPriceForDisplay(totalPrice, { dollarSign: false }) : ''
    }
  });

  const onSubmitTopUp = (values: FormData) => {
    const amountInCents = tryConvertDollarStringToCents(values.amount) as number;
    let data = {
      'amount_in_cents': amountInCents,
      'internet_banking_opt': false,
      'options': {},
      'purchase_detail': {
        orders_too_late_fox_bx: [],
        saved_order_ids: orderIds,
        voucher_token: null
      },
      'topup_method': "poli"
    }

    api.family.topup(data, !!orderIds)
      .then((res) => {
        if (res) {
          return res.json();
        }
      }).then((responseData) => {
        if (responseData.rtype === 'error') {
          if (onError) {
            onError(responseData);
          } else {
            alert(responseData.message)
          }
        } else if (responseData.url) {
          analytics.placeOrder({ priceInCents: amountInCents, initiatedFrom: 'Poli' })
          if (onPurchase) {
            onPurchase();
          }
          alert('You are now leaving the Kindo site to complete the topup process  -after which you will be returned to Kindo.')
          window.location.href = responseData.url
        } else {
          alert('OOPS. A topup URL was not generated. This is probably due to a communication failure between our website and our payment provider.\n\nNo funds have been transferred.\n\nPlease try again.')
        }
      })
  }

  const { data: motdData } = api.partner.useMessageOfTheDay('KINDO_ALL', 'poli');

  return (
    <form onSubmit={handleSubmit(onSubmitTopUp)}>
      {
        motdData?.motds && motdData?.motds.length > 0 && motdData?.motds.sort((a, b) => a.priority - b.priority).map((motd) => (
          <KindoWell
            variant={motd.info_type}
            key={`${motd.info_type + '_' + motd.priority}`}
            className='mb-2'
            messages={[motd.text]}
          />
        ))
      }
      <div className='flex flex-col gap-6'>
        <div className='grid grid-cols-1 gap-10 md:grid-cols-2 space-between'>
          <div className='flex flex-col gap-1 md:max-w-[280px]'>
            <InputField
              label={isCommunityShop ? 'Amount' : 'Top up amount'}
              required
              control={control}
              name="amount"
              rules={{
                pattern: {
                  value: /^[0-9]+(\.[0-9]+)?$/,
                  message: 'Please enter a valid amount',
                },
                min: {
                  value: formatCentsPriceForDisplay(totalPrice, { dollarSign: false }) || '0',
                  message: `Minimum amount of ${formatCentsPriceForDisplay(totalPrice)} is required`,
                },
              }}
              disabled={isCommunityShop}
            />
            {(totalPrice && !isCommunityShop) &&
              <p
                data-testid='button-topup-full-amount'
                className='text-sm cursor-pointer hover:underline text-secondary'
                onClick={() => setValue('amount', formatCentsPriceForDisplay(totalPrice, { dollarSign: false }))}
              >
                Top up full amount {formatCentsPriceForDisplay(totalPrice)}
              </p>
            }
          </div>
          <TopUpInfo />
        </div>

        <div className='flex flex-col justify-between gap-4 sm:flex-row'>
          <div className='flex flex-row flex-wrap items-center gap-4'>
            <img src={PoliLogo} alt="Poli logo" className='h-8' />
            <img src={PoliBankTypeLogos} alt="Poli bank logos" className='h-4' />
          </div>
          <div>
            <Button size='sm' className='font-museo' variant="default">
              {orderIds ? 'Complete payment' : 'Complete top up'}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
}

export default PoliForm