import useSWR, { useSWRConfig } from 'swr';
import { baseApi } from './constants';
import { KindoNetworkError } from './models/common';
import { LateClosingKey, RefundPurchase, SavedFor, ReOrderPurchase, SavedOrderData, CancelTransaction, Messages } from './models/customer';
import fetcher from './util/fetcher';

const customer = {
  // Queries (starts with "use")
  useLateClosingKey: () => {
    const data = useSWR<LateClosingKey, KindoNetworkError>(`${baseApi}/customer/late_closing_key`);
    return data;
  },

  useSavedForList: () => {
    const data = useSWR<SavedFor, KindoNetworkError>(`${baseApi}/customer/save_for`);
    return data;
  },

  useMessages: (id: string) => {
    const params = new URLSearchParams({
      family_id: id
    }).toString();
    const data = useSWR<Messages, KindoNetworkError>(`${baseApi}/msg/dispatched?${params}`);
    return data;
  },

  useMessageDispatch: ({
    familyId,
    dispatchId
  }: {
    familyId: string,
    dispatchId?: string
  }) => {
    const params = new URLSearchParams({
      family_id: familyId,
      ...(dispatchId && { dispatch_id: dispatchId })
    }).toString();
    const data = useSWR<Messages, KindoNetworkError>(dispatchId ? `${baseApi}/msg/dispatched?${params}` : undefined);
    return data;
  },

  // Mutations
  searchFamily: ({
    terms
  }: {
    terms: {}
  }) => {
    return fetcher(`${baseApi}/search/family`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        exact: false,
        max: 20,
        rtype: 'search',
        terms
      }),
    })
  },

  addNote: ({
    note,
    id
  }: {
    note: any,
    id: string
  }) => {
    return fetcher(`${baseApi}/track`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        creator: "connect_page",
        etype: "family",
        family_id: id,
        rtype: "track",
        track_type: "note",
        visibility: "internal",
        text: [note.note],
        remote_id: note.remoteId
      }),
    })
  },

  sendConfirmation: ({
    targetFamilyId,
    purchaseId
  }: {
    targetFamilyId: string,
    purchaseId: string
  }) => {
    return fetcher(`${baseApi}/customer/send_confirmation_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: "send_confirmation_email_from_helpdesk",
        targetFamilyId: targetFamilyId,
        purchase_id: purchaseId
      }),
    })
  },

  refundPurchase: (refundData: RefundPurchase) => {
    return fetcher(`${baseApi}/customer/refund_purchase`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify(refundData),
    });
  },

  reOrderPurchase: (data: ReOrderPurchase) => {
    return fetcher(`${baseApi}/orders/reorders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify(data),
    });
  },

  removeSavedOrder: ({
    data,
    orderId
  }: {
    data: SavedOrderData,
    orderId: string | null
  }) => {
    return fetcher(`${baseApi}/orders/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify(data),
    })
  },

  placeSavedOrder: (data: SavedOrderData) => {
    return fetcher(`${baseApi}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify(data),
    });
  },

  cancelTransaction: (data: CancelTransaction) => {
    return fetcher(`${baseApi}/cancellations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify(data),
    });
  },

  sendQuickMessage: ({
    targetFamilyId,
    message
  }: {
    targetFamilyId: string,
    message: string
  }) => {
    return fetcher(`${baseApi}/msg/txt`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: "sms_msg",
        family_id: targetFamilyId,
        body: message
      }),
    })
  },

  instantTopup: ({
    targetFamilyId,
    comment,
    amount
  }: {
    targetFamilyId: string,
    comment: string,
    amount: number
  }) => {
    return fetcher(`${baseApi}/customer/instant_topup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: "instant_topup",
        targetFamilyId: targetFamilyId,
        topup_amount: amount,
        topup_comment: comment
      }),
    })
  },

  pushPopPassword: ({
    targetFamilyId,
    reason,
    rtype
  }: {
    targetFamilyId: string,
    reason: string,
    rtype: string
  }) => {
    return fetcher(`${baseApi}/customer/${rtype}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: rtype,
        targetFamilyId: targetFamilyId,
        reason: reason
      }),
    })
  },

  disableReEnableFamily: ({
    targetFamilyId,
    comment,
    isDisabled
  }: {
    targetFamilyId: string,
    comment: string,
    isDisabled: boolean
  }) => {
    return fetcher(`${baseApi}/family/${targetFamilyId}/status`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: "family_status",
        family_id: targetFamilyId,
        is_disabled: isDisabled,
        history_comment: comment
      }),
    })
  },

  setFACL: ({
    targetFamilyId,
    partnerId,
    op
  }: {
    targetFamilyId: string,
    partnerId: string,
    op: string
  }) => {
    return fetcher(`${baseApi}/tgcl_admin/afc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        family_id: targetFamilyId,
        permission: "INVOICE_EMAIL",
        partner_id: partnerId,
        op: op
      }),
    })
  },
}

export default customer;