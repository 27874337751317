import './util/compat/compat_warner';
import './util/compat/polyfiller';
import React from 'react';
import 'mobx-react/batchingForReactDom';
import * as serviceWorker from './serviceWorker';
import { SWRConfig } from 'swr'
import "./styles/globals.css";
import { StoresProvider, useStore } from './store/store';
import { Toaster } from './routes/root_services/Toaster';
import * as Sentry from '@sentry/react';
import Helmet from 'react-helmet';
import { baseApi, platformEnv } from 'networking/constants';

// Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import {
  matchPath,
  RouterProvider,
} from "react-router-dom";

import router from './routes';

import { initializeSentry } from './error/error_reporting';
import { KindoNetworkError } from 'error/server_error';
import { deleteCookie } from './util/util';
import RootContentDialogProvider from 'components/dialog/RootContentDialog';
import fetcher from 'networking/util/fetcher';

initializeSentry();
function FallbackComponent() {
  return (
    <div>An error has occured</div>
  )
}

const SWRProvider = ({ children }: { children: React.ReactNode }) => {
  const store = useStore();

  const isLoginNotRequired = router.state.location.pathname === '/app/login'
    || router.state.location.pathname === '/app/reset-password'
    || !!matchPath('/app/proffer/:profferId', router.state.location.pathname)
    || !!matchPath('/app/registration/supplier', router.state.location.pathname);

  React.useEffect(() => {
    // Fetch CSRF token from backend
    fetch(`${baseApi}/get-csrf-token`, {
      credentials: 'include', // Ensure cookies are included in the request
    })
  }, []);
  return (
    <SWRConfig
      value={{
        revalidateOnFocus: false,
        shouldRetryOnError: false,
        fetcher: async (resource, init) => {
          const response = await fetcher(resource, {
            ...init,
            credentials: 'include',
          });
          if (response.ok) {
            return response.json();
          } else {
            const error = await response.json() as KindoNetworkError;

            if (!isLoginNotRequired) {
              if (error?.name === 'SessionMissingException' || error?.name === 'SessionClosedException' || error?.name === 'SessionNotFoundException') {
                if (!store.shop.isCommunityShop) {
                  store.family.setShowLoginDialog(true);
                } else if (store.shop.isCommunityShop && error?.name === 'SessionClosedException') {
                  deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
                } else {
                  store.shop.setIsAccountCreationRequired(true);
                }
                store.family.setIsAuthenticated(false);
              }
            }


            throw error;
          }
        }
      }}
    >
      {children}
    </SWRConfig>
  )
}

const App = () => (
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={FallbackComponent} showDialog>
      <StoresProvider>
        <RootContentDialogProvider>
          <SWRProvider>
            <Helmet
              defaultTitle='myKindo'
              titleTemplate="myKindo - %s"
            />
            <RouterProvider router={router} />
            <Toaster />
          </SWRProvider>
        </RootContentDialogProvider>
      </StoresProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>
)

export default App;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
