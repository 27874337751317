import React from 'react';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  VisibilityState,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table"
import { useVirtualizer } from '@tanstack/react-virtual'

import { Button } from "../../../common_component/base/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common_component/base/table";
import { Spinner } from '../../../common_component/base/spinner';
import { columns, RowItem } from './Columns';
import { parse } from 'date-fns';
import useAdminPayables from 'networking/hooks/useAdminPayables';
import { useToast } from 'components/base/use-toast';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { formatCentsPriceForDisplay } from 'util/string_util';
import PayableRollOverDialog from './dialogs/PayableRollOverDialog';
import { b64decode } from 'util/data/base64_utf8_tools';
import { useRolloverContext } from '.';
import useRemoteConfig from 'util/hooks/useRemoteConfig';

export function PayableRollOverTable() {
  const {
    school,
    filters,
  } = useRolloverContext();
  const { toast } = useToast();
  const navigate = useNavigate();

  const { getRemoteConfigValue } = useRemoteConfig();
  const rolloverYear = getRemoteConfigValue('payable_rollover_year').asString();
  const { data: payablesData, isLoading, getPayableByPermanentId, getPaymentStatus, isLoadingStudentPayables, studentPayableStats, rollOverPayables, error: payablesError, mutate: refetchPayablesData, refreshPayables } = useAdminPayables({
    schoolId: school,
    rolloverYear,
  });
  const [payableToRollOverId, setPayableToRollOverId] = React.useState<string>();

  const data = React.useMemo(() => {
    return (payablesData?.products.filter((item) => {
      let result = true;
      if (filters.category) {
        result = result && item.pcat === filters.category;
      }
      return result;
    }) || [
      ]).map((item) => ({
        permanentId: item.permanent_id,
        name: item.product,
        year: item.year,
        status: item.status,
        description: item.description ? b64decode(item.description) : '',
        applied: 0,
        glCode: item.remarks1,
        paymentStatus: isLoadingStudentPayables ? 'loading' : getPaymentStatus(item?.proto_payable_id),
        price: formatCentsPriceForDisplay(item.price_in_cents),
        payableCategory: item.pcat,
        gst: item.gst,
        protoPayableId: item?.proto_payable_id,
        isDonation: item.is_donation,
        originalProduct: item,
      }))
  }, [filters, payablesData?.products, isLoading, isLoadingStudentPayables, studentPayableStats]);

  const handleDuplicatePayable = (payable: RowItem) => {
    const permanentId = payable.permanentId;
    setPayableToRollOverId(permanentId);
  }

  const handleSkip = (payable: RowItem) => {
    const originalProduct = getPayableByPermanentId(payable.permanentId);
    if (originalProduct) {
      rollOverPayables.trigger({
        data: {
          after: [
            {
              ...originalProduct,
              rollover: 'no',
            }
          ],
          before: [
            {
              ...originalProduct,
              proto_payable_id: undefined,
            }
          ],
          product_question_before: {},
          product_question_after: {},
        }
      }).then((response) => {
        if (!response?.error) {
          refreshPayables.trigger();
          refetchPayablesData();
          toast({
            description: `'${payable.name}' has been skipped for rollover`,
          });
        } else {
          toast({
            description: 'Failed to skip payable for rollover',
            variant: 'destructive',
          });
        }
      })
    }
  }

  const columnsWithActions = React.useMemo(() => {
    return columns.concat([
      {
        id: 'actions',
        header: 'Actions',
        meta: {
          textAlign: 'right',
        },
        size: 130,
        cell: ({ row }) => (
          <div className='flex flex-row-reverse items-center gap-2'>

            <Button size="xs" className='whitespace-nowrap' onClick={() => {
              handleDuplicatePayable(row.original);
            }}>
              Rollover
            </Button>
            <Button disabled={rollOverPayables.isMutating} size="xs" className='whitespace-nowrap' variant={'outline'} onClick={() => {
              handleSkip(row.original);
            }}>
              Dismiss
            </Button>
          </div>
        ),
      },
    ]);
  }, [data, rollOverPayables.isMutating]);

  React.useEffect(() => {
    if (payablesError) {
      toast({
        title: 'Failed to fetch payables',
        description: payablesError.message,
        variant: 'destructive',
      });
    }
  }, [payablesError])

  React.useEffect(() => {
    if (!isLoading) {
      refreshData()
    }
  }, []);

  const parentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({
    // Default visibility state
    // applied: false,
    // rule: false,
    // comments: false,
  })
  const [sorting, setSorting] = React.useState<SortingState>([{
    id: 'name',
    desc: false,
  }])

  const table = useReactTable({
    data,
    columns: columnsWithActions,
    globalFilterFn: 'includesString',
    // onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    sortingFns: {
      dateSorting: (a, b, columnId) => {
        const desc = sorting[0].desc;
        const dateA = parse(a.original[columnId], 'MMM dd, yyyy', new Date());
        const dateB = parse(b.original[columnId], 'MMM dd, yyyy', new Date());

        // if date is not set, always push to the bottom
        if (dateA.toString() === 'Invalid Date') {
          return desc ? -1 : 1;
        }
        if (dateB.toString() === 'Invalid Date') {
          return desc ? 1 : -1;
        }
        return dateA.getTime() - dateB.getTime();
      }
    },
    state: {
      rowSelection,
      sorting,
      globalFilter: filters.searchText,
      columnVisibility,
    }
  })

  const { rows } = table.getRowModel()
  // const { flatRows: selectedRows } = table.getSelectedRowModel()
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 65,
    overscan: 5,
  })

  const virtualItems = rowVirtualizer.getVirtualItems();

  const refreshData = () => {
    refetchPayablesData();
  }

  React.useEffect(() => {
    if (data) {
      // Original data changed, reset row selection
      setRowSelection({});
    }
  }, [data]);

  React.useEffect(() => {
    setColumnVisibility({
      ...columnVisibility,
    })
  }, []);

  return (
    <>
      <PayableRollOverDialog
        open={!!payableToRollOverId}
        onOpenChange={(open) => {
          if (!open) {
            setPayableToRollOverId(undefined);
          }
        }}
        permanentId={payableToRollOverId}
        school={school}
      />
      <div className='flex flex-col text-card-foreground'>
        <div className="flex items-center gap-3 p-4">
          <div className="flex-1" />
          {!isLoading && (
            <>
              {/* 
            REFRESH BUTTON, Uncomment if required
            <div className='flex items-center pr-4 border-border border-r-1'>
              <Button variant="icon" size="default" disabled={isValidating} onClick={refreshData}>
                <MdRefresh className={cn('w-5 h-5', {
                  'animate-spin': isValidating
                })} />
              </Button>
            </div> */}
              <p className="text-sm">
                {`Displaying ${rows.length} items`}
              </p>
            </>
          )}
        </div>
        <div ref={parentRef} className="border-t max-h-[calc(100vh_-_380px)] min-h-[400px] overflow-auto">
          <div style={{ height: `${rowVirtualizer.getTotalSize() + 60}px` }} className='min-h-[200px]'>
            <Table>
              <TableHeader
                className='sticky top-0 z-10 table-header-group bg-gray-50 border-b-1 border-border'
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id} >
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id} className='font-museo'
                          style={{
                            width: header.getSize() || undefined,
                            textAlign: header.column.columnDef.meta?.textAlign,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {
                  isLoading && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 1} className="h-24 text-center">
                        <div className='flex items-center justify-center gap-2'>
                          <Spinner size="xs" />
                          Loading please wait
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                }
                {rows?.length ? (
                  virtualItems.map((virtualRow, index) => {
                    const row = rows[virtualRow.index];
                    return (
                      <TableRow
                        className='border-none'
                        key={row.id}
                        style={{
                          height: `${virtualRow.size}px`,
                          transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                        }}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} align={cell.column.columnDef.meta?.textAlign}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })
                ) : (
                  !isLoading &&
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} className="h-24 text-center">
                      {!payablesData?.products || payablesData?.products.length === 0 ? (
                        'There are currently no payables to rollover.'
                      ) : (
                        'No results.'
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}
