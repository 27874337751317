import React from 'react'
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from '../../../common_component/base/navigation-menu';
import { Button } from '../../../common_component/base/button';

import { NavButtonProps } from './NavButton'
import { Link } from 'react-router-dom';

type Props = {
  label: string | React.ReactNode;
  items: NavButtonProps[];
}

const NavigationDropdown = (props: Props) => {
  const { label, items } = props;

  return (
    <NavigationMenu className='self-start'>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger className='bg-transparent pt-4 h-[42px] font-museo rounded-tl-none rounded-tr-none hover:bg-pale focus:bg-pale focus:outline-none hover:text-primary focus:text-primary data-[active]:bg-primary-light/50 data-[state=open]:bg-primary-light/50'>
            {
              label
            }
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <div className='w-[160px] text-card-foreground flex flex-col gap-1 py-2'>
              {
                items.map((item) => {
                  const button = (
                    <Button
                      className='w-full h-[30px] justify-start gap-2 font-museo'
                      variant="link"
                      onClick={() => {
                        if (item.url) {
                          window.location.href = item.url;
                        }
                        if (item.onClick) {
                          item.onClick();
                        }
                      }}
                    >
                      {item.icon}
                      {item.label}
                    </Button>
                  )
                  return (
                    <NavigationMenuLink
                      asChild
                      className={item.className}
                      key={item.label}
                    >
                      <Link to={item.to || item.url || ''} reloadDocument={!!item.url}>
                        {button}
                      </Link>
                    </NavigationMenuLink>
                  )
                })
              }
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

export default NavigationDropdown