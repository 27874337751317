import React from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { MdAttachMoney, MdCheck, MdOpenInNew } from "react-icons/md";
import { Helmet } from "react-helmet";
import { observer } from "mobx-react";
import _ from "lodash";

import { useStore } from "../../../store/store";
import api from "networking/api";
import { useDebounce } from "../../../util/hooks/useDebounce";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../../common_component/base/card";
import Breadcrumbs from "../../../common_component/layout/components/Breadcrumbs";
import ComboBoxField from "../../../common_component/form/ComboBoxField";
import { Button } from "../../../common_component/base/button";
import CheckboxField from "../../../common_component/form/CheckboxField";
import InputField from "../../../common_component/form/InputField";
import KindoWell from "../../../common_component/KindoWell";
import FormGroup from "../../../common_component/form/FormGroup";
import RadioGroupField from "../../../common_component/form/RadioGroup";
import InfoTooltip from "../../../common_component/tooltip/InfoTooltip";
import { useToast } from "../../../common_component/base/use-toast";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader, DialogTrigger, DialogClose } from '../../../common_component/base/dialog'

import { rootBreadcrumbItems } from "../AdminPage";
import { formatCentsPriceForDisplay, tryConvertDollarStringToCents } from "../../../util/string_util";
import PayableApplyTask, { PayableApplyTaskProps } from "./PayableApplyTask";
import { isTgclAdmin } from "util/permissions";

type FormData = {
  applyTo: string; // metagroup
  studentIdExt?: string;
  groupId?: string;
  roomId?: string;
  yearId?: string;
  campusId?: string;
  caregiverIdExt?: string;
  protoPayableId?: string;
  startAmount?: string;
  comment?: string;
  preview?: boolean;
  feeType?: string;
  smsGroups?: string;
  smsGroupCategory?: string;
  alternateName?: string;
  defer?: string;
}

const PayableApplyPage = observer(() => {
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const store = useStore();
  const [deferredTasks, setDeferredTasks] = React.useState<PayableApplyTaskProps[]>([]);
  const [isPreview, setIsPreview] = React.useState(false);
  const [showApplyToLeftStudentDialog, setShowApplyToLeftStudentDialog] = React.useState(false);
  const [showRemoveFromListDialog, setShowRemoveFromListDialog] = React.useState(false);
  const [successMessages, setSuccessMessage] = React.useState<string[]>([]);
  const [errorMessages, setErrorMessage] = React.useState<string[]>([]);
  const [studentSearchText, setStudentSearchText] = React.useState('');
  const [studentSearchTextSnapshot, setStudentSearchTextSnapshot] = React.useState(params.get('studentSearchTextSnapshot') || '');
  const [refreshedPayables, setRefreshedPayables] = React.useState(false);
  const debouncedStudentSearch = useDebounce(studentSearchText, 400);
  const { control, watch, setValue, handleSubmit, formState: { isSubmitting } } = useForm<FormData>({
    defaultValues: {
      applyTo: '',
      ...Object.fromEntries([...params]),
      preview: params.get('preview') === 'true',
      smsGroups: params.get('smsGroups') || 'no',
    },
  });
  const permanentId = params.get('permanentId')
  const filterValues = watch();
  const currentSchool = store.admin.currentSchool;
  const { toast } = useToast();
  const { data } = api.admin.useFamily();
  const { data: configData } = api.admin.useKpConfig(currentSchool);
  const { data: roomData, isLoading: isLoadingRooms } = api.admin.useMetaGroups(filterValues.applyTo === 'room' ? currentSchool : '', 'room');
  const { data: yearData, isLoading: isLoadingYears } = api.admin.useMetaGroups(filterValues.applyTo === 'year_level' ? currentSchool : '', 'year_level');
  const { data: smsGroupData, isLoading: isLoadingSmsGroups } = api.admin.useGroups(currentSchool, true)
  const { data: groupsData, isLoading: isLoadingGroups } = api.admin.useGroups(filterValues.applyTo === 'adhoc' ? currentSchool : '', filterValues.smsGroups === 'yes');
  const { data: campusData, isLoading: isLoadingCampuses } = api.admin.useMetaGroups(currentSchool, 'suba');
  const { data: payableCategoriesData, isLoading: isLoadingPayableCategories } = api.admin.usePayableCategories(currentSchool);
  const { data: protoPayablesData, isLoading: isLoadingProtoPayables, mutate: mutateProtoPayables } = api.admin.useProtoPayables(refreshedPayables ? currentSchool : undefined, {
  });
  const { data: studentData, isLoading: isLoadingStudents } = api.admin.useStudentSearch(currentSchool, debouncedStudentSearch || studentSearchTextSnapshot)
  const { data: caregiverData, isLoading: isLoadingCaregiver } = api.admin.useCaregiverSearchByStudentIdExt(currentSchool, filterValues.studentIdExt)
  const canAccessAdminPage = isTgclAdmin(data);
  const schoolConfig = configData?.school_confs.find((conf) => conf.school_id === currentSchool);
  const showAlternateName = schoolConfig?.kp_conf.enable_payable_alt_name;
  const showFeeType = schoolConfig?.kp_conf.ff_sma;
  const allowTaggedCaregiver = schoolConfig?.kp_conf.allow_tagged_caregiver;
  const hasSmsGroups = (smsGroupData?.roll_adhoc_groups || []).length > 0;
  const smsGroupCategories = Object.keys(_.groupBy(smsGroupData?.roll_adhoc_groups, 'gcat'));
  const selectedPayable = protoPayablesData?.proto_payables.find((protoPayable) => protoPayable.proto_payable_id === filterValues.protoPayableId);
  const selectedStudent = studentData?.matches.find((student) => student.student_id_ext === filterValues.studentIdExt);
  const getStandardAmount = (protoPayable?: any) => {
    return (protoPayable?.has_own_price ? (protoPayable.price_in_cents?.excl_cents || 0) + (protoPayable.price_in_cents?.gst_cents || 0) : protoPayable?.product_price_in_cents) || 0
  }
  const standardAmount = getStandardAmount(selectedPayable);
  const onSubmit = async (values: FormData) => {
    setSuccessMessage([]);
    setErrorMessage([]);
    const isPreview = !!values.preview;
    setIsPreview(isPreview);
    const { groupIdentifier, groupName, metagroupName } = (() => {
      switch (values.applyTo) {
        case 'student': {
          const student = studentData?.matches.find((student) => student.student_id_ext === values.studentIdExt);
          if (student) {
            return {
              groupIdentifier: values.studentIdExt,
              groupName: `${student.preferred_first_name} ${student.preferred_last_name}`,
              metagroupName: 'Student',
            };
          }
          return {
            groupIdentifier: values.studentIdExt,
            groupName: 'Student',
            metagroupName: 'Student',
          };
        }
        case 'room':
          return {
            groupIdentifier: values.roomId,
            groupName: values.roomId,
            metagroupName: 'Room',
          };
        case 'year_level':
          return {
            groupIdentifier: values.yearId,
            groupName: values.yearId,
            metagroupName: 'Year',
          }
        case 'suba':
          return {
            groupIdentifier: values.campusId,
            groupName: values.campusId,
            metagroupName: 'Campus',
          }
        case 'adhoc':
          return {
            groupIdentifier: values.groupId,
            groupName: values.groupId,
            metagroupName: 'Group',
          };
        case 'school':
          return {
            groupIdentifier: currentSchool,
            groupName: currentSchool,
            metagroupName: 'Entire School',
          }
        default:
          return {
            groupIdentifier: undefined,
            groupName: undefined,
            metagroupName: undefined,
          };
      }
    })();
    if (values.protoPayableId && groupIdentifier) {
      const commonVariables = {
        schoolId: currentSchool,
        preview: values.preview,
        protoPayableId: values.protoPayableId,
        ...(values.feeType && showFeeType ? {
          feeType: values.feeType,
        } : {}),
      }
      let response = null;
      if (values.applyTo === 'student') {

        if (values.studentIdExt) {
          if (selectedStudent?.left_date && (!showApplyToLeftStudentDialog)) {
            setShowApplyToLeftStudentDialog(true);
            return;
          }
          response = await api.admin.bindPayableToStudent({
            ...commonVariables,
            studentId: values.studentIdExt,
            altStartAmount: tryConvertDollarStringToCents(`${values.startAmount}`)!,
            comment: values.comment,
            ...(allowTaggedCaregiver ? {
              caregiverId: values.caregiverIdExt,
            } : {}),
            ...(showAlternateName && values.alternateName ? {
              altName: values.alternateName,
            } : {}),
            allowWhenLeft: showApplyToLeftStudentDialog,
          });
          setShowApplyToLeftStudentDialog(false);
        }
      } else {
        response = await api.admin.bindPayable({
          ...commonVariables,
          groupIdentifier,
          metagroup: values.applyTo,
          ...(canAccessAdminPage && values.defer ? {
            defer: values.defer,
          } : {}),
        });
      }
      const payable = protoPayablesData?.proto_payables.find((protoPayable) => protoPayable.proto_payable_id === values.protoPayableId);
      if (response?.data) {
        const successMessages = values.applyTo === 'student' ? [

          `Payable ${values.preview ? 'Preview' : 'Applied'}`,
          `${payable?.proto_payable_name} ${values.preview ? 'can be' : 'has been'} applied to ${metagroupName} ${groupName}`,
          ...(response?.data.rtype === 'student_payable_refs' && response.data.student_payable_refs.length > 1 ? [
            `It has been split ${response.data.student_payable_refs.length} ways`,
          ] : []),
        ] : [
          `${values.preview ? 'Preview' : 'Created rule'}`,
          `${values.preview ? 'A rule can be' : 'A rule has been'} created for ${payable?.proto_payable_name} on ${metagroupName} ${groupName}`,
          `${response.data.rtype === 'bind_payable_ref' && response.data?.apply_result?.student_count! > 0 ? `${values.preview ? 'It would be ' : 'It has been '} applied to ${response.data.apply_result?.student_count} students` : ''}`
        ]
        if (response.data.rtype === 'defer') {
          const newTask = {
            errorMessages: [
              `Failed to ${values.preview ? 'Preview' : 'Apply'} Payable`,
              `${payable?.proto_payable_name} failed to apply to ${metagroupName} ${groupName}`,
            ],
            successMessages,
            inProgressMessages: [
              <div className="flex items-center gap-2 text-sm">
                {isPreview ? 'Previewing...' : `Applying...`}
                <InfoTooltip
                  tooltip={'We are currently working on applying. Please do not close this page'}
                />
              </div>,
              `Please wait while we ${isPreview ? 'preview' : 'apply'} ${payable?.proto_payable_name} to ${metagroupName} ${groupName}`,
            ],
            isPreview: !!values.preview,
            deferredTaskId: response.data.task_id,
          }
          setDeferredTasks((prev) => [
            ...prev,
            newTask,
          ])
        } else {
          setSuccessMessage(successMessages);
        }
      } else {
        setErrorMessage([
          `Failed to ${values.preview ? 'Preview' : 'Apply'} Payable`,
          `${payable?.proto_payable_name} failed to apply to ${metagroupName} ${groupName}, could have already been applied`
        ]);
      }
    }
  }

  React.useEffect(() => {
    // Refresh payables
    api.admin.refreshPayables({
      schoolId: currentSchool
    }).then((response) => {
      setRefreshedPayables(true);
      if (response.error) {
        toast({
          variant: 'destructive',
          title: 'Failed to refresh payables',
        });
      }
    })
  }, [])

  React.useEffect(() => {
    if (debouncedStudentSearch) {
      // Preserve the search text when the user is typing
      setStudentSearchTextSnapshot(debouncedStudentSearch)
    }
  }, [debouncedStudentSearch]);

  React.useEffect(() => {
    if (!isLoadingGroups) {
      const existingGroup = groupsData?.roll_adhoc_groups.find((group) => group.group_id === filterValues.groupId);
      if (!existingGroup) {
        setValue('groupId', '');
      }
    }
  }, [isLoadingGroups, filterValues.smsGroups]);

  React.useEffect(() => {
    if (standardAmount > 0) {
      setValue('startAmount', parseFloat(formatCentsPriceForDisplay(standardAmount, { dollarSign: false })).toFixed(2))
    }
  }, [filterValues.protoPayableId]);

  React.useEffect(() => {
    if (filterValues.studentIdExt && filterValues.caregiverIdExt) {
      // Reset caregiver id if student id is changed
      setValue('caregiverIdExt', '')
    }
  }, [filterValues.studentIdExt]);

  React.useEffect(() => {
    if (permanentId) {
      // Find proto payable id using permanent id
      const protoPayable = protoPayablesData?.proto_payables.find((protoPayable) => protoPayable.product_permanent_id === permanentId)
      setValue('protoPayableId', protoPayable?.proto_payable_id)
    }
  }, [permanentId, protoPayablesData?.proto_payables]);

  const handleEditProtoPayable = () => {
    setSearchParams({
      ...params,
      ...(Object.keys(filterValues).reduce((acc, key) => {
        if (filterValues[key as keyof FormData] !== undefined) {
          acc[key] = filterValues[key as keyof FormData];
        }
        return acc;
      }
        , {})),
      studentSearchTextSnapshot,
    });
    navigate(`/app/admin/payable-apply/${filterValues.protoPayableId}`)
  }
  const handleDismissTask = (taskId: string) => {
    setDeferredTasks((prev) => prev.filter((task) => task.deferredTaskId !== taskId));
  }

  const handleRemoveFromList = async () => {
    if (filterValues.protoPayableId) {
      const response = await api.admin.removeProtoPayable({
        schoolId: currentSchool,
        protoPayableId: filterValues.protoPayableId,
      });

      if (response.ok) {
        toast({
          title: `Payable removed from list`,
        });
        void mutateProtoPayables();
        setShowRemoveFromListDialog(false);
      } else {
        toast({
          variant: 'destructive',
          title: `Failed to remove payable from list`,
        });
      }

    }
  }
  return (
    <div>
      <Helmet>
        <title>Payable Apply</title>
      </Helmet>
      <div className="flex flex-col gap-4">
        <div>
          <Breadcrumbs
            items={[
              ...(rootBreadcrumbItems),
              { label: 'Payable Apply', to: '/app/admin/payable-apply', active: true },
            ]}
          />
          <p className="text-sm">
            {currentSchool}
          </p>
        </div>

        <Dialog open={showRemoveFromListDialog} onOpenChange={setShowRemoveFromListDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Remove Payable from List?</DialogTitle>
            </DialogHeader>
            <div className='flex flex-col gap-4 text-text-body'>
              <p>Are you sure you want to remove {selectedPayable?.proto_payable_name} from the list?</p>
              <p>
                Note: No existing student payables will be changed by this action.
                <Link to="https://support.kindo.co.nz/portal/en/kb/articles/removing-a-payable-from-your-list" target="_blank">
                  <Button
                    variant="icon"
                    size="xs"
                    className="text-primary"
                  >
                    <MdOpenInNew className='w-4 h-4 ml-1' />
                  </Button>
                </Link>
              </p>
            </div>
            <DialogFooter>
              <DialogClose>
                <Button variant="outline" onClick={() => setShowRemoveFromListDialog(false)}>
                  Cancel
                </Button>
              </DialogClose>
              <Button type="button" disabled={isSubmitting} onClick={handleRemoveFromList}>
                Remove Payable
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <Dialog open={showApplyToLeftStudentDialog} onOpenChange={setShowApplyToLeftStudentDialog}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Apply Payable to Student who has left the school?</DialogTitle>
            </DialogHeader>
            <div className='flex flex-col gap-4 text-text-body'>
              <p>Are you sure you want to apply payable to {selectedStudent?.preferred_first_name} {selectedStudent?.preferred_last_name} who has left the school?</p>
            </div>
            <DialogFooter>
              <DialogClose>
                <Button variant="outline" onClick={() => setShowApplyToLeftStudentDialog(false)}>
                  Cancel
                </Button>
              </DialogClose>
              <Button type="button" disabled={isSubmitting}
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
              >
                Apply
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <div className="flex flex-col gap-2">
          <Card className="transition-shadow shadow-md hover:shadow-xl rounded-3xl">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardHeader>
                <CardTitle>
                  Payable Apply
                </CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-4">
                <FormGroup>
                  <ComboBoxField
                    label="Payable"
                    name="protoPayableId"
                    control={control}
                    minWidth={250}
                    isLoading={isLoadingProtoPayables}
                    placeholder="Search or select a payable"
                    rules={{
                      required: 'Please select a payable'
                    }}
                    options={[
                      ...(protoPayablesData?.proto_payables
                        .filter((protoPayable) => protoPayable.allow_apply)
                        .map((protoPayable) => ({ label: `${protoPayable.proto_payable_name} (${formatCentsPriceForDisplay(getStandardAmount(protoPayable))})`, value: protoPayable.proto_payable_id })) ?? [])
                        .sort((a, b) => (a.label || '').localeCompare(b.label || '', 'en', { sensitivity: 'base' }))
                    ]}
                    // Uncomment this if we need it back, otherwise remove it if it's not needed few months later
                    // labelRight={(
                    //   filterValues.protoPayableId &&
                    //   <div className="flex items-center gap-1 opacity-90">
                    //     <Button
                    //       className="p-0 h-[16px] text–secondary gap-1"
                    //       disabled={!filterValues.protoPayableId}
                    //       variant="link"
                    //       size="sm"
                    //       type="button"
                    //       onClick={() => setShowRemoveFromListDialog(true)}
                    //     >
                    //       remove from list
                    //       <InfoTooltip
                    //         messages={[
                    //           'IMPORTANT: This action will not affect the visibility of outstanding payables for your caregivers BUT does remove the item from some reports including the Outstanding Payables (advanced) report which is used for View Only reporting.  If you remove an item in error, helpdesk can add it back.'
                    //         ]}
                    //       />
                    //     </Button>
                    //   </div>
                    // )}
                    withSearchIcon
                  />
                  <div className="flex items-center gap-2 pt-6">
                    <Button
                      disabled={!filterValues.protoPayableId}
                      variant="outline"
                      size="sm"
                      onClick={handleEditProtoPayable}
                    >
                      Edit Payable
                    </Button>
                    <InfoTooltip
                      messages={[
                        '• Add or update the selected payable\'s category',
                        '• Set the maximum number of times the selected payable can be applied to any student',
                      ]}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <RadioGroupField
                    name="applyTo"
                    label="Apply to"
                    rules={{
                      required: 'Please select an option'
                    }}
                    orientation="horizontal"
                    options={[
                      { label: 'Student', value: 'student' },
                      { label: 'Room', value: 'room' },
                      { label: 'Group', value: 'adhoc' },
                      { label: 'Year', value: 'year_level' },
                      ...((campusData?.groups || []).filter((c) => !!c).length > 0 ? [{ label: 'Campus', value: 'suba' }] : []),
                      { label: 'Entire School', value: 'school' },
                    ]}
                    control={control}
                  />
                </FormGroup>
                <FormGroup>
                  {
                    filterValues.applyTo === 'student' && (
                      <>
                        <ComboBoxField
                          label="Student"
                          name="studentIdExt"
                          control={control}
                          isLoading={isLoadingStudents}
                          rules={{
                            required: 'Please select a student'
                          }}
                          minWidth={200}
                          placeholder="Search student"
                          noOptionsMessage={() => debouncedStudentSearch ? 'No students found' : 'Type to search students'}
                          inputValue={studentSearchText}
                          onInputChange={(text) => setStudentSearchText(text)}
                          options={[
                            ...(studentData?.matches.map((student) => ({ label: `${student.preferred_first_name} ${student.preferred_last_name}${student.room && student.room !== 'UNKNOWN' ? ` (${student.room})` : ' (Room not set)'}${student.left_date ? ' (left)' : ''}`, value: student.student_id_ext })) ?? [])
                          ]}
                          withSearchIcon
                        />
                        {
                          allowTaggedCaregiver && (
                            <ComboBoxField
                              label="Payable by"
                              name="caregiverIdExt"
                              control={control}
                              isDisabled={!filterValues.studentIdExt}
                              isLoading={isLoadingCaregiver}
                              minWidth={150}
                              placeholder="Any caregiver"
                              options={[
                                { label: 'Any caregiver', value: '' },
                                ...(caregiverData?.matches.map((caregiver) => ({ label: `${caregiver.caregiver_first_name} ${caregiver.caregiver_last_name}`, value: caregiver.caregiver_id_ext })) ?? [])
                              ]}
                            />
                          )
                        }

                        <InputField
                          label="Start Amount"
                          name="startAmount"
                          control={control}
                          startAdornment={<MdAttachMoney />}
                          type="number"
                          step="0.01"
                          min="0.00"
                          rules={{
                            validate: {
                              positveNumber: (value) => {
                                if (parseFloat(value) <= 0) {
                                  return 'Please enter a value greater than 0';
                                }
                              }
                            }
                          }}
                        />
                        <InputField
                          label="Comment (shown on internal reports)"
                          name="comment"
                          control={control}
                        />
                        {
                          showAlternateName && (
                            <InputField
                              label="Alternate Name"
                              name="alternateName"
                              control={control}
                            />
                          )
                        }
                      </>
                    )
                  }
                  {
                    filterValues.applyTo === 'room' && (
                      <>
                        <ComboBoxField
                          label="Room"
                          name="roomId"
                          control={control}
                          isLoading={isLoadingRooms}
                          minWidth={150}
                          placeholder="Select room"
                          rules={{
                            required: 'Please select a room'
                          }}
                          options={[
                            ...(roomData?.groups.map((room) => ({ label: room, value: room })) ?? [])
                          ]}
                          withSearchIcon
                        />
                      </>
                    )
                  }
                  {
                    filterValues.applyTo === 'year_level' && (
                      <>
                        <ComboBoxField
                          label="Year"
                          name="yearId"
                          control={control}
                          isLoading={isLoadingYears}
                          minWidth={150}
                          placeholder="Select year"
                          rules={{
                            required: 'Please select a year'
                          }}
                          options={[
                            ...(yearData?.groups.map((year) => ({ label: year, value: year })) ?? [])
                          ]}
                          withSearchIcon
                        />
                      </>
                    )
                  }
                  {
                    filterValues.applyTo === 'suba' && (
                      <>
                        <ComboBoxField
                          label="Campus"
                          name="campusId"
                          control={control}
                          isLoading={isLoadingCampuses}
                          minWidth={150}
                          placeholder="Select campus"
                          rules={{
                            required: 'Please select a campus'
                          }}
                          options={[
                            ...(campusData?.groups
                              .filter((c) => !!c)
                              .map((campus) => ({ label: campus, value: campus })) ?? [])
                          ]}
                          withSearchIcon
                        />
                      </>
                    )
                  }
                  {
                    filterValues.applyTo === 'adhoc' && (
                      <>
                        {
                          !isLoadingSmsGroups && hasSmsGroups && (
                            <RadioGroupField
                              control={control}
                              name="smsGroups"
                              label="Show groups from"
                              options={[
                                {
                                  label: 'Kindo',
                                  value: 'no',
                                },
                                {
                                  label: 'Student Management System',
                                  value: 'yes',
                                }
                              ]}
                              orientation="horizontal"
                            />
                          )
                        }
                        {
                          filterValues.smsGroups === 'yes' && hasSmsGroups && (
                            <ComboBoxField
                              label="Category"
                              name="smsGroupCategory"
                              control={control}
                              isLoading={isLoadingGroups}
                              minWidth={150}
                              placeholder="Any category"
                              options={[
                                {
                                  label: 'Any category',
                                  value: '',
                                },
                                ...(
                                  smsGroupCategories
                                    .map((gCategory) => ({ label: gCategory, value: gCategory })) ?? [])
                              ]}
                              withSearchIcon
                            />
                          )
                        }
                        <ComboBoxField
                          label="Group"
                          name="groupId"
                          control={control}
                          isLoading={isLoadingGroups}
                          minWidth={250}
                          placeholder="Search and select group"
                          rules={{
                            required: 'Please select a group'
                          }}
                          options={[
                            ...(groupsData?.roll_adhoc_groups
                              .filter((group) => group.status === 'enabled')
                              .filter((group) => filterValues.smsGroups === 'yes' && filterValues.smsGroupCategory ? group.gcat === filterValues.smsGroupCategory : true)
                              .map((group) => ({ label: group.group_id, value: group.group_id })) ?? [])
                          ]}
                          withSearchIcon
                        />
                        {
                          filterValues.groupId && (
                            <div className="mt-7">
                              <Button
                                className="px-0"
                                variant="link"
                                type="button"
                                size="xs"
                                onClick={() => navigate(`/app/admin/groups?groupId=${filterValues.groupId}&smsGroups=${filterValues.smsGroups}`)}
                              >
                                View Group
                              </Button>
                            </div>
                          )
                        }
                      </>
                    )
                  }
                  {
                    showFeeType && (
                      <ComboBoxField
                        label="Fee Type"
                        name="feeType"
                        control={control}
                        minWidth={150}
                        placeholder="Any"
                        options={[
                          {
                            label: 'Any',
                            value: '',
                          },
                          {
                            label: 'Only NZ Govt funded / Domestic',
                            value: 'FUNDED',
                          },
                          {
                            label: 'Only Foreign Fee Paying / International',
                            value: 'FF'
                          }
                        ]}
                      />
                    )
                  }
                </FormGroup>
              </CardContent>
              <CardFooter className="gap-4">
                <Button disabled={isSubmitting}>
                  Apply
                </Button>
                {
                  canAccessAdminPage && (
                    <CheckboxField
                      control={control}
                      name="preview"
                      label="Preview (@tgcl only)"
                    />
                  )
                }

                {
                  canAccessAdminPage && (
                    <ComboBoxField
                      label="Defer (@tgcl only)"
                      name="defer"
                      control={control}
                      minWidth={100}
                      placeholder="May Defer"
                      options={[
                        {
                          label: 'May defer',
                          value: 'MAY_DEFER',
                        },
                        {
                          label: 'Must defer',
                          value: 'MUST_DEFER',
                        },
                        {
                          label: 'No defer',
                          value: 'NO_DEFER'
                        },
                        {
                          label: 'Test defer',
                          value: 'TEST_DEFER',
                        }
                      ]}
                    />
                  )
                }
              </CardFooter>
              {
                successMessages.length > 0 && (
                  <CardFooter>
                    <KindoWell
                      variant={isPreview ? 'info' : 'success'}
                      messages={successMessages}
                      icon={<MdCheck />}
                    />
                  </CardFooter>
                )
              }
              {
                deferredTasks.length > 0 && (
                  <CardFooter className="flex flex-col gap-2">
                    {
                      deferredTasks.map((deferredTask) => (
                        <PayableApplyTask
                          key={deferredTask.deferredTaskId}
                          {...deferredTask}
                          onDismiss={() => handleDismissTask(deferredTask.deferredTaskId)}
                        />
                      ))
                    }
                  </CardFooter>
                )
              }

              {
                errorMessages.length > 0 && (
                  <CardFooter>
                    <KindoWell
                      variant={"danger"}
                      messages={errorMessages}
                    />
                  </CardFooter>
                )
              }
            </form>
          </Card>
        </div>

      </div>
    </div>
  )
})

export default PayableApplyPage;
