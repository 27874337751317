import React from 'react'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../common_component/base/dialog";
import { Button } from '../../../common_component/base/button';
import { Product } from 'networking/models/family';
import { formatCentsPriceForDisplay } from '../../../util/string_util';
import Flags from '../../../common_component/product/Flags';
import _ from 'lodash';
import AvailableDays from '../MenuCategory/AvailableDays';
import { b64decode } from '../../../util/data/base64_utf8_tools';
import api from 'networking/api';
import { Spinner } from '../../../common_component/base/spinner';

type Props = {
  availableServiceDays?: number[];
  product: Product;
  productImageSrc?: string;
  open: boolean;
  stock?: number;
  packProdUrl?: string;
  submitButton?: React.ReactNode;
  showAvailableDays?: boolean;
  onOpenChange: (open: boolean) => void;
}

const ProductDetail = ({
  availableServiceDays,
  open,
  product,
  productImageSrc,
  stock,
  showAvailableDays,
  onOpenChange,
  packProdUrl,
  submitButton,
}: Props) => {
  const [imageFailedToLoad, setImageFailedToLoad] = React.useState(false);
  const hasChoices = (product.choice_names || []).length > 0;
  const hasOptions = product.opt_names.length > 0;
  const availableDays = (product.avail_days && product.avail_days.length > 0 && !(availableServiceDays || []).every((d) => product.avail_days.includes(d))) ? product.avail_days : [];
  const { data, isLoading } = api.order.usePackProds((product.pack_prods || []).length > 0 ? packProdUrl : undefined);

  const handleCancel = () => {
    onOpenChange(false);
  }
  return (
    <Dialog open={open} onOpenChange={(open) => {
      onOpenChange(open);
    }}>
      <DialogContent className='overflow-auto h-[100%] sm:h-auto max-h-[80vh]'>
        <div>
          {
            productImageSrc && !imageFailedToLoad && (
              <div className='flex justify-center w-full'>
                <img
                  onError={() => {
                    setImageFailedToLoad(true);
                  }}
                  alt={product.product}
                  src={productImageSrc}
                  className='object-contain max-h-[250px] h-fit mt-5 rounded-sm'
                />
              </div>
            )
          }
          <DialogHeader>
            <DialogTitle className='flex flex-col gap-2 font-sans'>
              <p className='font-museo'>
                {
                  product.product
                }
              </p>
              <p className='flex items-center gap-4 text-card-foreground'>
                {
                  formatCentsPriceForDisplay(parseInt(product.price_in_cents, 10))
                }
                {
                  product.flags && (
                    <div>
                      <Flags flags={product.flags} />
                    </div>
                  )
                }
              </p>

              {_.isNumber(stock) && (
                <p className='text-xs font-normal text-left text-card-foreground'>
                  {stock > 0 ? `${stock} left` : 'Sold out'}
                </p>
              )}
              {
                showAvailableDays && (
                  <div className='text-sm font-normal'>
                    <AvailableDays availableDays={availableDays} weekDaysOnly={!availableServiceDays.includes(6) && !availableServiceDays.includes(7)} />
                  </div>
                )
              }
            </DialogTitle>
            {
              product.summary && (
                <DialogDescription>
                  <div className='mt-3 text-sm text-left text-card-foreground [&_a]:text-primary [&_a]:underline' dangerouslySetInnerHTML={{
                    __html: b64decode(product.summary)
                  }} />
                </DialogDescription>
              )
            }
          </DialogHeader>
          <div className='flex flex-col gap-6 py-4 text-card-foreground'>
            {
              product.description && (
                <div>
                  <p className='mb-1 text-sm font-semibold font-museo'>
                    Description
                  </p>
                  {
                    product.description ? (
                      <div className='mt-3 text-sm [&_a]:text-primary [&_a]:underline' dangerouslySetInnerHTML={{
                        __html: b64decode(product.description)
                      }} />
                    ) : (
                      <p className='text-sm'>No description available</p>
                    )
                  }
                </div>
              )
            }
            {
              hasChoices && (
                <div>
                  <p className='mb-1 text-sm font-semibold font-museo'>
                    Choices
                  </p>
                  <div className='flex gap-3'>
                    {
                      (product.choice_names || []).length > 0 && (
                        <div className='flex flex-col gap-2'>
                          {
                            (product.choice_names || []).map((choiceName, index) => (
                              <p key={choiceName} className='text-sm'>
                                {choiceName}
                              </p>
                            ))
                          }
                        </div>
                      )
                    }
                  </div>
                </div>
              )
            }
            {
              hasOptions && (
                <div>
                  <p className='mb-1 text-sm font-semibold font-museo'>
                    {product.opt_name || 'Options'}
                  </p>
                  {
                    product.option_price_in_cents > 0 && (
                      <>
                        <p className='mb-1 text-sm'>
                          Price per option {formatCentsPriceForDisplay(product.option_price_in_cents)}
                        </p>
                        {
                          product.included_option_count > 0 && (
                            <p className='mb-1 text-sm'>
                              {product.included_option_count} included without charge
                            </p>
                          )
                        }
                      </>
                    )
                  }
                  <div className='flex gap-3'>
                    {
                      (product.opt_names || []).length > 0 && (
                        <ul className='flex flex-col gap-2 ml-4'>
                          {
                            (product.opt_names || []).map((optName, index) => (
                              <li key={optName} className='text-sm list-disc'>
                                {optName}
                              </li>
                            ))
                          }
                        </ul>
                      )
                    }
                  </div>
                </div>

              )
            }
            {
              product.pack_prods && (
                <div>
                  <p className='mb-1 text-sm font-semibold'>
                    Items included in package
                  </p>
                  {
                    isLoading && (
                      <div className='flex items-center justify-center w-full pt-2'>
                        <Spinner />
                      </div>
                    )
                  }
                  {
                    !isLoading && product.pack_prods.map((packProd) => {
                      const prod = data?.items.find((item) => item.permanent_id === packProd[0]);
                      const quantity = packProd[1];
                      return (
                        <div key={packProd[0]}>
                          <p className='text-sm'>
                            {`${quantity}x ${prod?.product} - ${formatCentsPriceForDisplay(parseInt(prod?.price_in_cents ?? '0', 10))} each`}
                          </p>
                        </div>
                      )
                    })
                  }
                </div>
              )
            }
          </div>
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={handleCancel}>
            Close
          </Button>
          {submitButton}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ProductDetail