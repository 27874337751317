import React from 'react'
import { observer } from 'mobx-react';
import { useLocation, useMatch } from 'react-router-dom';

import { platformEnv } from 'networking/constants';
import { useStore } from 'store/store';
import { parsePageParameters } from 'util/page_param_helpers';
import { setCookie } from 'util/util';
import { isMobileSafari, isMobile, isChrome, isIOS } from 'react-device-detect';

/**
 * Handles deep linking for native app
 */
const AppDeepLinking = observer(() => {
  const matchesShopHome = !!useMatch('/app/shop');
  const matchesCheckout = !!useMatch('/app/checkout');
  const matchesMyOrders = !!useMatch('/app/my-orders');

  const location = useLocation();
  const store = useStore();

  React.useEffect(() => {
    if (!store.family.isZohoVisible) {
      // Wait until zoho is loaded to prevent zoho from interrupting prompt to app
      return;
    }
    // Handle school and service deep link to app
    let schoolId = '';
    let serviceId = '';
    let isPartnerPreview = false;
    if (location.search) {
      // loop through search query and set school and service id
      location.search.slice(1).split('&').forEach((param) => {
        const [key, value] = param.split('=');

        if (['shop', 'school', 'schoolId', 'school_id'].includes(key)) {
          schoolId = decodeURI(value);
        } else if (key === 'service') {
          serviceId = decodeURI(value);
        } else if (key === 'partner_preview') {
          isPartnerPreview = true;
        }
      });
    }

    if (schoolId && !isPartnerPreview) {
      // Try to open school and service in app if url is valid
      const params = new URLSearchParams();
      params.append('service', serviceId);
      if (isMobileSafari) {
        // if deep link is valid then prompt to open, otherwise continue to shareable link in browswer (this is a fix for when a user doesn't have an app installed)
        setTimeout(() => {
          window.location.href = `/app/shop?${params.toString()}`;
        }, 25);
        window.location.href = `mykindo://deep-link/school/${schoolId}/${serviceId ? `service/${serviceId}` : ''}`;
      } else if (isMobile && isChrome && isIOS && store.family.isZohoVisible) {
        setTimeout(() => {
          window.location.href = `/app/shop?${params.toString()}`;
        }, 25);
        window.location.href = `mykindo://deep-link/school/${schoolId}/${serviceId ? `service/${serviceId}` : ''}`;
      } else {
        window.location.href = `mykindo://deep-link/school/${schoolId}/${serviceId ? `service/${serviceId}` : ''}`;
      }
    }
  }, [store.family.isZohoVisible]);

  React.useEffect(() => {
    /**
     * Handles shared session from URL parameter
     * For example: If user goes to /app/shop?session=1234&test=123, it will set the session to 1234 and redirect to /app/shop?test=123
     * It also checks client_type param and sets isRenderingFromNativeApp to true if client_type is app for example.
     */
    const params = parsePageParameters();
    const session = params.get('session');
    const clientType = params.get('client_type');
    if (clientType === 'app') {
      store.family.setIsRenderingFromNativeApp(true);
    }
    if (session) {
      // Set session cookie
      setCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`, session, 7, '/', '.kindo.co.nz');
      // Navigate with original path and search, but without session
      const search = new URLSearchParams(location.search);
      search.delete('session');
      search.delete('client_type');
      window.location.href = location.pathname + `${search.size > 0 ? '?' + search.toString() : ''}`;
    }
  }, []);

  React.useEffect(() => {
    // handle native app deep linking
    if (store.family.isRenderingFromNativeApp) {
      if (matchesShopHome) {
        window.location.href = `mykindo://shop/home`
      }
      if (matchesCheckout) {
        window.location.href = `mykindo://checkout`
      }
      if (matchesMyOrders) {
        window.location.href = `mykindo://my-orders`
      }
    }
  }, [matchesMyOrders, matchesShopHome, matchesCheckout, store.family.isRenderingFromNativeApp]);

  return null;
});

export default AppDeepLinking