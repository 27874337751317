import React from "react"
import { Table } from "@tanstack/react-table"

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../common_component/base/dropdown-menu"
import { Select, SelectTrigger } from "components/base/select"

interface DataTableViewOptionsProps<TData> {
  table: Table<TData>
  icon?: React.ReactNode;
}

export function ColumnToggle<TData>({
  table,
  icon,
}: DataTableViewOptionsProps<TData>) {
  const [open, setOpen] = React.useState(false);
  return (
    <DropdownMenu open={open}
      onOpenChange={(open) => {
        // This is a workaround to prevent the dropdown from closing when the user clicks on a menu item.
        // While still allowing the dropdown to open when the user clicks on the button or opens with the keyboard.
        if (open) {
          setOpen(open);
        }
        // The dropdown can be closed when the user clicks outside the dropdown. see below onPointerDownOutside
      }}
    >
      <DropdownMenuTrigger>
        <Select>
          <SelectTrigger
            className="h-[36px]"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {icon ? icon : 'Select table columns'}
          </SelectTrigger>
        </Select>
      </DropdownMenuTrigger>
      <DropdownMenuContent onPointerDownOutside={() => setOpen(false)} align="end" className="w-[150px] text-card-foreground">
        <DropdownMenuLabel className="font-museo text-primary">Column Visibility</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {table
          .getAllColumns()
          .filter(
            (column) =>
              typeof column.accessorFn !== "undefined" && column.getCanHide()
          )
          .map((column) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
              >
                {column.columnDef.meta?.columnTitle || column.id}
              </DropdownMenuCheckboxItem>
            )
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
