import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from "../../../common_component/base/button";
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { useForm } from 'react-hook-form';
import SelectField from '../../../common_component/form/SelectField';
import { MenuSupplier } from 'networking/models/family';
import ComboBoxField from "../../../common_component/form/ComboBoxField";

type Props = {
  familyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  familySupplier: MenuSupplier[];
}

type FormProps = {
  op: string,
  partnerId: string
}

const InvoiceFACLDialog = ({ familyId, open, onOpenChange, familySupplier }: Props) => {
  const { toast } = useToast();

  const { control, handleSubmit } = useForm<FormProps>({});

  const { trigger, isMutating, error } = useSWRMutation('/customer/set-facl', async (key, { arg }: { arg: FormProps }) => {
    try {
      const response = await api.customer.setFACL({ targetFamilyId: familyId, partnerId: arg.partnerId, op: arg.op });

      if (response) {
        onOpenChange(false);
        const res = await response.json();
        if (res.rtype === 'error') {
          toast({
            title: `${res.message}`,
            duration: 3000,
          });
        } else if (res.public_message) {
          toast({
            title: `${res.public_message}`,
            duration: 3000,
          });
        } else if (res.success) {
          toast({
            title: 'Invoice FACL has been set successfully.',
            duration: 3000,
          });
        } else {
          toast({
            title: 'Something went wrong, please try again',
            duration: 3000,
          });
        }
      } else {
        throw new Error('Something went wrong, please try again');
      }
    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  const onSubmit = (data: FormProps) => {
    trigger(data);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Adjust invoice FACL</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-3 pb-2 mb-10'>
            <ComboBoxField
              label="Supplier"
              name="partnerId"
              control={control}
              borderColor='primary'
              required
              maxMenuHeight={100}
              options={[
                ...(familySupplier.map((partner) => ({
                  value: partner.id,
                  label: partner.name
                })) ?? [])
              ]}
            />
            <SelectField
              control={control}
              name="op"
              label="Operation"
              rules={{
                required: `Operation is required`
              }}
              items={[
                {
                  label: 'Grant',
                  value: 'grant'
                },
                {
                  label: 'Revoke',
                  value: 'revoke'
                }
              ]}
            />
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
            <Button type="submit" disabled={isMutating}>Submit</Button>
          </DialogFooter>
        </form>
        {error && <div className="mt-2 text-sm text-destructive">{error}</div>}
      </DialogContent>
    </Dialog>
  )
}

export default InvoiceFACLDialog