import React from 'react'
import { FamilyNotes, NoteTracks } from 'networking/models/family'
import { format } from 'date-fns';
import { MdOpenInNew, MdAddCircleOutline } from "react-icons/md";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../common_component/base/dialog'
import { Button } from "../../common_component/base/button";
import { useForm } from 'react-hook-form';
import api from 'networking/api';
import useSWRMutation from 'swr/mutation';
import TextField from '../../common_component/form/TextField';
import InputField from '../../common_component/form/InputField';
import { useToast } from '../../common_component/base/use-toast';

type Props = {
  notes: FamilyNotes;
  familyId: string;
  mutate: () => void;
}

type FormData = {
  remoteId: string;
  note: [];
}

const Notes = ({ notes, familyId, mutate }: Props) => {
  const { toast } = useToast();

  const [showNotesDialog, setShowNotesDialog] = React.useState(false);
  const { control, handleSubmit, reset } = useForm<FormData>({});

  const { trigger, isMutating, error } = useSWRMutation('/customer/add-notes', (key, { arg }: { arg: FormData }) => {
    try {
      return api.customer.addNote({
        id: familyId,
        note: arg
      }).then(async (response) => {
        return response;
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })

  const onSubmit = (values: FormData) => {
    setShowNotesDialog(false);
    trigger({
      ...values
    }).then((response) => {
      if (response && response.ok) {
        mutate();
        toast({
          title: 'Note has been added',
          duration: 3000,
        })
        resetForm();
      }
    });
  };

  const resetForm = () => {
    reset({
      remoteId: '',
      note: []
    })
  };

  return (
    <>
      {
        notes.tracks ?
          <div className='grid grid-cols-1 gap-4 tablet:grid-cols-menu-2 lg:grid-cols-menu-3 2xl:grid-cols-menu-4 3xl:grid-cols-menu-5 4xl:grid-cols-menu-6 5xl:grid-cols-menu-7 6xl:grid-cols-menu-7 7xl:grid-cols-menu-8 8xl:grid-cols-menu-9'>
            {notes.tracks.map((track: NoteTracks) => (
              <div
                key={track.track_id}
                className='flex flex-col gap-1 p-4 shadow-lg sm:shadow-none sm:rounded-lg border-x-0 sm:border-x-1 border-1 border-border hover:shadow-md'
              >
                <div className='flex justify-between'>
                  <p className='text-xs text-text-helper'>{format(new Date(track.created), 'PPPP')}</p>
                  {track.remote_id &&
                    <a className='text-xs' target={"_blank"} href={`https://support.mykindo.co.nz/support/tgcltd/ShowHomePage.do#Cases/search/AllDep/${track.remote_id}`} rel="noreferrer"> <MdOpenInNew /></a>
                  }
                </div>
                <p className='h-20 pt-2 text-sm'>{track.text[0]}</p>
                <p className='text-xs italic text-text-helper'>{track.creating_family_email} ({track.visibility})</p>
              </div>
            ))}
          </div>
          :
          <div>
            <p>No notes to display.</p>
          </div>
      }
      <div className='flex items-center gap-2 pt-4 cursor-pointer text-primary' onClick={() => { setShowNotesDialog(true) }}>
        <MdAddCircleOutline />
        <p className='text-sm'>Add new note</p>
      </div>
      <Dialog open={showNotesDialog} onOpenChange={() => { }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add a note</DialogTitle>
          </DialogHeader>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className='flex flex-col gap-3 pb-2'>
              <TextField
                name="note"
                required
                control={control}
                rows={4}
                label="Note"
                rules={{
                  required: 'Note is required',
                }}
              />
              <InputField
                name="remoteId"
                control={control}
                label="ZohoDesk Ticket"
              />
            </div>
            <DialogFooter>
              <Button type='button' variant="outline" onClick={() => { setShowNotesDialog(false); resetForm(); }}>Cancel</Button>
              <Button disabled={isMutating} type="submit">Save</Button>
            </DialogFooter>
          </form>
          {error && <div className="mt-2 text-sm text-destructive">{error}</div>}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default Notes