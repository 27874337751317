import React from 'react'
import { Controller, type UseControllerProps } from 'react-hook-form'
import { Checkbox } from '../base/checkbox';

type Props = {
  label?: string | React.ReactNode;
  hideAsterisk?: boolean;
  helperText?: string;
} & UseControllerProps<any>;

const CheckboxField: React.FC<Props> = ({
  name,
  control,
  rules,
  label,
  helperText,
  hideAsterisk = false,
}) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, name, onBlur }, fieldState: { error } }) => (
          <div className='flex flex-col space-y-3'>
            <Checkbox
              checked={value}
              label={
                <div className='flex flex-col gap-1'>
                  <p>
                    {label}{rules?.required && !hideAsterisk ? <span className="text-danger-foreground"> *</span> : ''}
                  </p>

                  {helperText && (
                    <div className="text-xs text-text-helper">{helperText}</div>
                  )}
                </div>
              }
              name={name}
              onBlur={onBlur}
              onCheckedChange={(value: boolean) => onChange(value)}
            />
            {!!error && (
              <div className="text-sm text-destructive">{error.message}</div>
            )}
          </div>
        )}
      />
    </div>
  )
}

export default CheckboxField