import React from 'react';
import { createStores, type RootStore } from "./createStore";

const storeContext = React.createContext<RootStore | null>(null);

interface ProviderProps {
  children?: React.ReactNode,
}
// Only used in Root App.tsx component
export const StoresProvider = ({ children }: ProviderProps) => {
  const [store, setStore] = React.useState<RootStore | null>(null);
  const setupStore = async () => {
    const store = await createStores();
    // We wait until all stores have finished hydrating
    setStore(store);
  }
  React.useEffect(() => {
    setupStore();
  }, [])

  if (store) {
    return <storeContext.Provider value={store}>{children}</storeContext.Provider>
  } else {
    return null;
  }
}

// Hook to import when using the store.
export const useStore = () => {
  const store = React.useContext(storeContext)
  if (!store) {
    // this is especially useful in TypeScript so you don't need to be checking for null all the time
    throw new Error('useStore must be used within a StoreProvider.')
  }
  return store
}