import React from 'react';
import { Controller, type UseControllerProps } from "react-hook-form";

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../base/select"
import { Label } from '../base/label';
import { cn } from 'util/tailwind';

interface SelectProps extends UseControllerProps<any> {
  label?: string;
  items: { value: string; label: string, disabled?: boolean; }[];
  required?: boolean;
  placeholder?: string;
  position?: 'item-aligned' | 'popper';
  helperText?: string;
  className?: string;
  disabled?: boolean;
};

const SelectField: React.FC<SelectProps> = ({
  label,
  items,
  required,
  placeholder,
  name,
  control,
  helperText,
  position = 'popper',
  className,
  rules,
  disabled,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, name, onBlur }, fieldState: { error } }) => (
        <div className='flex flex-col'>
          {label && (
            <Label htmlFor={name} className="mb-1">
              {label}{rules?.required ? <span className="text-danger-foreground"> *</span> : ''}

              {
                helperText && (
                  <span className="mb-1 ml-2 text-xs font-normal text-text-helper">
                    {helperText}
                  </span>
                )
              }
            </Label>
          )}
          <Select
            required={required}
            value={value}
            disabled={disabled}
            onValueChange={(value) => onChange(value)}
          >
            <SelectTrigger data-testid={`button-${name}`} className={cn("w-full", className)} onBlur={onBlur}>
              <SelectValue placeholder={placeholder} />
            </SelectTrigger>
            <SelectContent position={position}>
              <SelectGroup id={name}>
                {items.map((item) => (
                  <SelectItem disabled={item.disabled} key={item.value} value={item.value}>
                    {item.label}
                  </SelectItem>
                ))}
              </SelectGroup>
            </SelectContent>
          </Select>
          {error?.message ? (
            <div className="mt-1 text-xs text-destructive">{error?.message}</div>
          ) : null}
        </div>
      )}
    />

  );
};

export default SelectField;
