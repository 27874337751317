import React from 'react';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  VisibilityState,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table"
import { useVirtualizer } from '@tanstack/react-virtual'
import { useForm } from 'react-hook-form';
import Papa from 'papaparse';
import { MdClose } from 'react-icons/md';

import { Button } from "../../../common_component/base/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common_component/base/table";
import { Input } from '../../../common_component/base/input';
import { Spinner } from '../../../common_component/base/spinner';
import SelectField from '../../../common_component/form/SelectField';
import { ColumnToggle } from '../../../common_component/table/ColumnToggle';
import ActionsDialog, { actions, ActionType } from './ActionsDialog';
import EmailDialog from './EmailDialog';
import { RowItem } from './Columns';
import { format, parse } from 'date-fns';
import { tryConvertDollarStringToCents, formatCentsPriceForDisplay } from 'util/string_util';
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  isLoading?: boolean;
  onRefresh?: () => void;
  showIdColumn?: boolean;
  showProtoPayableIdColumn?: boolean;
  showHiddenColumn?: boolean;
  showExemptColumn?: boolean;
  school: string;
  userName?: string;
}

type FormData = {
  actionType?: ActionType;
}

const COLUMN_EXPORT_ORDER = [
  'id',
  'protoPayableId',
  'studentId',
  'firstName',
  'lastName',
  'room',
  'item',
  'applied',
  'requested',
  'rule',
  'paid',
  'unpaid',
  'hidden',
  'exempt',
  'payableType',
  'comments',
]
export function PayableTable<TValue>({
  columns,
  data,
  isLoading,
  school,
  onRefresh,
  showIdColumn,
  showProtoPayableIdColumn,
  showHiddenColumn,
  showExemptColumn,
  userName,
}: DataTableProps<RowItem, TValue>) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({
    // Default visibility state
    id: !!showIdColumn,
    protoPayableId: !!showProtoPayableIdColumn,
    applied: false,
    rule: false,
    comments: false,
  })
  const [emailDialogOpened, setEmailDialogOpened] = React.useState(false)
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const { control, watch, setValue } = useForm<FormData>({
    defaultValues: {
      actionType: ActionType.NONE,
    }
  });

  const table = useReactTable({
    data,
    columns,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    sortingFns: {
      dateSorting: (a, b, columnId) => {
        const desc = sorting[0].desc;
        const dateA = parse(a.original[columnId], 'MMM dd, yyyy', new Date());
        const dateB = parse(b.original[columnId], 'MMM dd, yyyy', new Date());

        // if date is not set, always push to the bottom
        if (dateA.toString() === 'Invalid Date') {
          return desc ? -1 : 1;
        }
        if (dateB.toString() === 'Invalid Date') {
          return desc ? 1 : -1;
        }
        return dateA.getTime() - dateB.getTime();
      }
    },
    state: {
      rowSelection,
      sorting,
      globalFilter,
      columnVisibility,
    }
  })

  const { rows } = table.getRowModel()
  const { flatRows: selectedRows } = table.getSelectedRowModel()
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 65,
    overscan: 5,
  })

  const virtualItems = rowVirtualizer.getVirtualItems();

  const actionType = watch('actionType');
  const handleActionDialogToggle = (open: boolean) => {
    if (!open) {
      setValue('actionType', ActionType.NONE);
    }
  }
  const handleEmailDialogChange = (open: boolean) => {
    setEmailDialogOpened(open)
  }

  const refreshData = () => {
    if (onRefresh) {
      onRefresh();
    }
  }

  const selectedPayables = React.useMemo(() => {
    return selectedRows.map((row) => row.original?.payable)
  }, [selectedRows]);

  const getColumnName = (key: string) => {
    switch (key) {
      case 'id':
        return 'id';
      case 'protoPayableId':
        return 'Proto';
      case 'applied':
        return 'Applied';
      case 'firstName':
        return 'First Name';
      case 'lastName':
        return 'Last Name';
      case 'requested':
        return 'Requested';
      case 'rule':
        return 'Rule';
      case 'paid':
        return `Paid (${formatCentsPriceForDisplay(_.sum(data.map(row => tryConvertDollarStringToCents(row.paid.toString()))))})`;
      case 'unpaid':
        return `Unpaid (${formatCentsPriceForDisplay(_.sum(data.map(row => tryConvertDollarStringToCents(row.unpaid.toString()))))})`;
      case 'hidden':
        return 'Hidden';
      case 'exempt':
        return 'Exempt';
      case 'item':
        return 'Item';
      case 'room':
        return 'Room';
      case 'name':
        return 'Name';
      case 'studentId':
        return 'Student id'
      case 'payableType':
        return 'Payable Type';
      case 'comments':
        return 'Comments';
      default:
        return '';
    }
  }
  const handleExport = () => {
    const visibleColumns = table.getVisibleFlatColumns();
    const orderedColumns = visibleColumns.reduce<string[]>((all, column) => {
      if (column.id && getColumnName(column.id)) {
        return [
          ...all,
          column.id,
        ]
      }
      return all;
    }, ['studentId', 'payableType']) // Additional columns
      .sort((a, b) => {
        return COLUMN_EXPORT_ORDER.indexOf(a) - COLUMN_EXPORT_ORDER.indexOf(b);
      });

    // Preparing data for building csv
    const data = rows.reduce<string[][]>((allData, row) => {
      const rowData = orderedColumns.reduce<string[]>((all, columnKey) => {
        const value = row.original[columnKey as keyof RowItem] as string;
        return [
          ...all,
          value,
        ]
      }, []);;
      return [
        ...allData,
        rowData,
      ]
    }, []);
    const csvData = Papa.unparse({
      fields: orderedColumns.map((columnKey: string) => getColumnName(columnKey)),
      data
    });

    // Create a blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a hidden <a> element and click it to trigger the download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `payables-${school}-${format(new Date(), 'dd-MM-yyyy')}.csv`);
    link.style.visibility = 'hidden';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }
  React.useEffect(() => {
    if (data) {
      // Original data changed, reset row selection
      setRowSelection({});
    }
  }, [data]);

  React.useEffect(() => {
    setColumnVisibility({
      ...columnVisibility,
      hidden: !!showHiddenColumn,
      exempt: !!showExemptColumn,
      id: !!showIdColumn,
      protoPayableId: !!showProtoPayableIdColumn,
    })
  }, [showHiddenColumn, showExemptColumn, showIdColumn, showProtoPayableIdColumn])
  return (
    <div className='flex flex-col text-card-foreground'>
      <div className="flex items-center gap-3 p-4">
        {!isLoading && (
          <>
            {
              Object.keys(rowSelection).length > 0 && (
                <Button
                  variant="icon"
                  onClick={() => {
                    setRowSelection({});
                  }}
                >

                  <MdClose
                    size={24}
                  />
                </Button>
              )
            }
            <p className="font-museo">
              {Object.keys(rowSelection).length > 0 ? `${Object.keys(rowSelection).length} items selected` : `${rows.length} items`}
            </p>
            {
              Object.keys(rowSelection).length === 0 && (
                <p className='text-sm text-text-helper'>
                  Select student/s to access actions or send payment request
                </p>
              )
            }
          </>
        )}
        {
          Object.keys(rowSelection).length > 0 && (
            <>
              <SelectField
                placeholder="Action"
                control={control}
                name='actionType'
                items={actions}
              />
              <Button variant="outline" size="sm" onClick={() => setEmailDialogOpened(true)}>
                Send Payment Request
              </Button>
            </>
          )
        }
        <ActionsDialog
          actionType={actionType}
          open={!!actionType}
          userName={userName}
          onOpenChange={handleActionDialogToggle}
          onActionSuccess={({ actionType, removedBindingIds, groupName }) => {
            switch (actionType) {
              case ActionType.PREPAID:
              case ActionType.CHANGE_START_AMOUNT:
              case ActionType.HIDE:
              case ActionType.UNHIDE:
              case ActionType.EXEMPT:
              case ActionType.UNAPPLY:
              case ActionType.RETIRE:
              case ActionType.REFUND:
                refreshData();
                break;
            }
          }}
          payables={selectedPayables}
        />
        <EmailDialog
          open={emailDialogOpened}
          onOpenChange={handleEmailDialogChange}
          payables={selectedPayables}
          onActionSuccess={refreshData}
        />
        <div className="flex-1" />
        <Input
          placeholder='Search'
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
        {
          onRefresh && (
            <Button variant="outline" size="sm" disabled={isLoading} onClick={onRefresh}>
              Refresh
            </Button>
          )
        }
        <Button disabled={isLoading} variant="outline" size="sm" onClick={handleExport}>
          Export
        </Button>
        <ColumnToggle table={table} />
      </div>
      <div ref={parentRef} className="border-t max-h-[800px] overflow-auto">
        <div style={{ height: `${rowVirtualizer.getTotalSize() + 60}px` }} className='min-h-[200px]'>
          <Table>
            <TableHeader
              className='sticky top-0 z-10 table-header-group bg-gray-50 border-b-1 border-border'
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} >
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className='font-bold font-museo'
                        style={{
                          width: header.getSize() || undefined,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {
                isLoading && (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      <div className='flex items-center justify-center gap-2'>
                        <Spinner size="xs" />
                        Loading please wait
                      </div>
                    </TableCell>
                  </TableRow>
                )
              }
              {rows?.length ? (
                virtualItems.map((virtualRow, index) => {
                  const row = rows[virtualRow.index];
                  return (
                    <TableRow
                      className='border-none'
                      key={row.id}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                      }}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })
              ) : (
                !isLoading &&
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
