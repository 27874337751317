import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from "../../../common_component/base/button";
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { useForm } from 'react-hook-form';
import InputField from '../../../common_component/form/InputField';

type Props = {
  familyEmail: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  mutate: () => void;
}

type FormProps = {
  email: string
}

const ResetPasswordDialog = ({ familyEmail, open, onOpenChange, mutate }: Props) => {
  const { toast } = useToast();

  const { control, handleSubmit, setValue } = useForm<FormProps>({});

  React.useEffect(() => {
    if (familyEmail) {
      setValue('email', familyEmail)
    }
  }, [familyEmail, setValue]);

  const { trigger, isMutating, error } = useSWRMutation('/family/reset-password', async (key) => {
    try {
      const response = await api.family.resetPassword(familyEmail);

      if (response) {
        onOpenChange(false);
        const res = await response.json();
        if (res.rtype === 'error') {
          toast({
            title: `${res.message}`,
            duration: 3000,
          });
        } else if (res.sent_email) {
          toast({
            title: `Email has been sent to:`,
            description: `${res.email}`,
            duration: 3000,
          });
          mutate()
        } else {
          toast({
            title: 'Something went wrong, please try again',
            duration: 3000,
          });
        }
      } else {
        throw new Error('Something went wrong, please try again');
      }
    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  const onSubmit = () => {
    trigger();
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Reset password</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-3 pb-2'>
            <p className="text-sm text-card-foreground">Are you sure you want to reset password for email below?</p>
            <InputField
              control={control}
              name="email"
              disabled
            />
            <p className="text-sm text-card-foreground">An email will be sent to the user with the link where they can change their password.</p>
          </div>
          {error && <div className="mt-2 text-sm text-destructive">{error}</div>}
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
            <Button type="submit" disabled={isMutating}>Submit</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ResetPasswordDialog