import React from 'react'
import { Button } from '../../../common_component/base/button';
import { Student, Product } from 'networking/models/family';

import ProductChoices from '../dialog/ProductChoices';
import { MenuCategoryItemProps } from '.';
import { cn } from '../../../util/tailwind';
import api from 'networking/api';
import { useToast } from '../../../common_component/base/use-toast';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from '../../../common_component/base/popover'
import { useStore } from '../../../store/store'

type Props = {
  disabled?: boolean;
} & MenuCategoryItemProps;

const QuickButtons = ({ student, disabled, onRemove, onAdd }: { student: Student; disabled: boolean; onRemove: (student: Student) => void; onAdd: (student: Student) => void }) => {
  return (
    <div className='flex items-center justify-end h-[100%] gap-[2px]'>
      <Button
        name='quick-remove-from-cart'
        className='flex items-center justify-center text-xl w-[32px] h-[100%] p-0 bg-primary brightness-125 rounded-none'
        onClick={(e) => {
          e.stopPropagation();
          onRemove(student);
        }}
      >
        -
      </Button>
      <Button
        name="quick-add-to-cart"
        disabled={disabled}
        className='flex items-center justify-center text-xl w-[32px] h-[100%] p-0 bg-primary brightness-125 rounded-none rounded-tr-sm rounded-br-sm'
        onClick={(e) => {
          e.stopPropagation();
          onAdd(student);
        }}
      >
        +
      </Button>
    </div>
  )
}
const AddToCartButton = ({ currentService, disabled, questions, studentsWithProducts, category, item, isServiceClosed, onPurchase, onRemove, students }: Props) => {
  const [selectedStudent, setSelectedStudent] = React.useState(students[0]);
  const { data, isLoading } = api.order.usePackProds(((item?.pack_prods || []).length) > 0 ? currentService?.pack_prod_url : undefined);
  const [productChoicesQueue, setProductChoicesQueue] = React.useState<{ product: Product; quantity: number }[]>([]);
  const [showStudentSelector, setShowStudentSelector] = React.useState(false);
  const { mutate } = api.family.useFamily();
  const { toast } = useToast();
  const store = useStore();

  const productToShowChoice = productChoicesQueue[0]?.product;
  const productToShowChoiceQuantity = productChoicesQueue[0]?.quantity;
  const questionsForProduct = questions ? questions[productToShowChoice?.permanent_id] : [];
  const onlyOneStudent = students.length === 1;
  const quantityForFirstStudent = (studentsWithProducts && students[0]) && studentsWithProducts[students[0].student_id];
  const quantityForAllStudents = students.reduce((acc, student) => {
    const quantity = studentsWithProducts[student.student_id];
    return acc + (quantity || 0);
  }, 0);
  const isDisabled = disabled || isLoading || students.length === 0;
  const handleAddToCart = (student: Student) => {
    if (currentService?.pack_prod_url && item.pack_prods) {
      item.pack_prods.map((packProd) => {
        const prodId = packProd[0];
        const quantity = packProd[1];
        const product = data?.items.find((prod) => prod.permanent_id === prodId);
        if (product) {
          addToCart(student, product, quantity);
        }
      });
    } else {
      addToCart(student, item);
    }
  }

  const addToCart = (student: Student, product: Product, quantity = 1) => {
    const shouldShowProductChoiceFlow = (product?.choice_names || []).length > 0
      || product.opt_names.length > 0
      || product.has_questions
      || (questionsForProduct || []).length > 0
      || (!store.shop.isCommunityShop && (!student.room && product.delivery_address === 'room'));
    if (shouldShowProductChoiceFlow) {
      setSelectedStudent(student);
      setShowStudentSelector(false);
      setProductChoicesQueue((p) => [
        ...p,
        {
          product,
          quantity,
        }
      ]);
      return Promise.resolve(true);
    } else {
      onPurchase(product, category, student, quantity);
    }
  }


  const handleRemoveFromCart = (student: Student) => {
    onRemove(item.productId, student);
  }

  const shiftProductFromQueue = () => {
    if (productChoicesQueue.length > 0) {
      // Remove from queue
      setProductChoicesQueue((p) => p.slice(1));
    }
  }
  return (
    <>
      {
        productToShowChoice && (
          <ProductChoices
            open={!!productToShowChoice}
            onOpenChange={(open) => {
              if (!open) {
                // Remove from queue
                shiftProductFromQueue();
              }
            }}
            student={selectedStudent}
            item={productToShowChoice}
            questions={questionsForProduct ?? []}
            defaultValues={{
              questions: (questionsForProduct || []).map((question) => ({
                answer: '',
                ...question,
              })),
            }}
            onSubmit={async (data) => {
              if (!selectedStudent.room && data.room) {
                await api.family.updateStudentRoom({
                  room: data.room,
                  studentSchoolId: selectedStudent.student_school_id,
                }).then((response) => {
                  console.log('updated student room', response)
                  if (response.ok) {
                    // Refetch family data
                    mutate().then(() => {
                      toast({
                        title: "Room updated",
                        description: `Room for ${selectedStudent.first_name} has been set to ${data.room}`
                      })
                    });
                  }
                });
              }
              return onPurchase({
                ...productToShowChoice,
                ...data,
              }, category, {
                ...selectedStudent,
                ...(data.room ? {
                  room: data.room,
                } : {}),
              }, productToShowChoiceQuantity).then((success) => {
                if (success) {
                  // Remove from queue
                  shiftProductFromQueue();
                }
                return success
              });
            }}
          />
        )
      }
      <Popover
        modal
        open={showStudentSelector}
        onOpenChange={(open) => {
          if (students.length > 1) {
            setShowStudentSelector(open);
          }
          return false;
        }}
      >
        <PopoverTrigger disabled={isDisabled}>
          <Button
            name="add-to-cart"
            variant={'default'}
            className={cn(
              'w-full',
              {
                'pl-3 pr-[4px] py-[4px] gap-3 overflow-hidden flex items-center justify-between text-left': quantityForFirstStudent > 0 && onlyOneStudent,
                'bg-pale text-pale-foreground hover:bg-pale': showStudentSelector,
              }
            )}
            disabled={isDisabled}
            onClick={(e) => {
              e.stopPropagation();
              if (students.length === 1) {
                handleAddToCart(students[0])
              } else if (students.length > 1) {
                setShowStudentSelector(() => true);
              }
            }}
          >
            {
              isServiceClosed ? (
                <p>Service is closed</p>
              ) : (
                <p className='flex-1'>
                  {showStudentSelector ? 'Select which child' : onlyOneStudent ? `${quantityForAllStudents > 0 ? `${quantityForAllStudents} in cart` : 'Add to cart'}` : `Add to cart${quantityForAllStudents > 0 ? ` (${quantityForAllStudents})` : ''}`}
                </p>
              )
            }

            {
              quantityForAllStudents > 0 && onlyOneStudent && (
                <QuickButtons
                  student={students[0]}
                  disabled={isDisabled}
                  onAdd={handleAddToCart}
                  onRemove={handleRemoveFromCart}
                />
              )
            }
          </Button>
        </PopoverTrigger>
        <PopoverContent asChild side="top" sideOffset={10} className='w-full p-0 bg-transparent border-0 shadow-none'>
          <div className='flex flex-col gap-2'>
            {students.map((student) => {
              const quantityForStudent = studentsWithProducts[student.student_id] ?? 0;
              return (
                <Button
                  name='add-to-cart-student'
                  className='min-w-[170px] pr-[4px] py-[4px] gap-3 overflow-hidden flex items-center justify-between text-left'
                  disabled={isDisabled}
                  key={student.student_id}
                  variant='default'
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddToCart(student);
                  }}
                >
                  <p className='flex-1 text-left'>
                    {quantityForStudent > 0 ? `${student.first_name} - ${quantityForStudent} added` : student.first_name}
                  </p>
                  {
                    quantityForStudent > 0 && (
                      <QuickButtons
                        student={student}
                        disabled={isDisabled}
                        onAdd={handleAddToCart}
                        onRemove={handleRemoveFromCart}
                      />
                    )
                  }
                </Button>
              )
            })}
          </div>
        </PopoverContent>
      </Popover>
    </>
  )
}

export default AddToCartButton