import React from 'react'
import { observer } from 'mobx-react';
import { formatCentsPriceForDisplay } from '../../util/string_util'

type Props = {
  result?: any;
};

const TopupDeferredPurchase = observer(({ result }: Props) => {
  const actionedPaymentResult = result.purchase_results.actioned_payment_detail;
  const actionedPurchaseResult = result.purchase_results.actioned_purchase_detail;
  const noSuccessfulPurchase = actionedPurchaseResult.count === 0;

  return (
    <div className='flex flex-col gap-2 px-4 py-6 sm:px-6'>
      <div className='flex flex-col text-sm'>
        {result.purchase_results.email &&
          <p className='text-card-foreground'>You will receive an email confirmation shortly. To view or make changes to your order, please visit <a className='font-semibold underline text-primary' href='/app/completion-loading#myorders'>my orders</a>.</p>
        }
        {noSuccessfulPurchase ?
          <>
            <p className='text-card-foreground'>A topup with a total amount of <span className='font-semibold text-primary'>{formatCentsPriceForDisplay(result.amount_in_cents_without_fee)}</span> has been added to your myKindo wallet.</p>
            <p className='text-card-foreground'>To view or make changes to your order, please visit <a className='font-semibold underline text-primary' href='/app/completion-loading#myorders'>my orders</a>.</p>
          </>
          :
          <p className='text-card-foreground'>{actionedPurchaseResult.count} purchase{(actionedPurchaseResult.count > 1) ? 's were' : ' was'} completed with a total amount of <span className='font-semibold text-primary'>{formatCentsPriceForDisplay(actionedPurchaseResult.total_in_cents)}</span>.</p>
        }
        {actionedPaymentResult && actionedPaymentResult.has_acknowledgement_failures &&
          <>
            <p className='text-card-foreground'>
              We were unable to notify the school of {actionedPaymentResult.acknowledged_count - actionedPaymentResult.payment_count} payment.
              <b>Don't worry, your payment is safe.</b> We will automatically retry the notification until it succeeds.
              In the meantime your Kindo records will show the payment as made, however in some cases, the school may not be aware of this.
            </p>
            <p className='text-card-foreground'>
              {actionedPaymentResult.acknowledged_count} school{(actionedPaymentResult.acknowledged_count > 1) && 's'} has been notified of the payment with total of <span className='font-semibold text-primary'>{formatCentsPriceForDisplay(actionedPaymentResult.total_acknowledged_payments_in_cents)}.</span>
            </p>
          </>
        }
        {actionedPurchaseResult && actionedPurchaseResult.has_errors && (
          <>
            <p className='pt-4 text-card-foreground'>
              Some items in your order have encountered an error:
            </p>
            {actionedPurchaseResult.error_names.map((error: string) => (
              <p key={error}>{error.replace(/Exception/g, '').replace(/([a-z])([A-Z])/g, '$1 $2')}</p>
            ))}
          </>
        )}
      </div>
    </div>
  )
})

export default TopupDeferredPurchase
