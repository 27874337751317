import React, { useCallback, useState } from "react";
import { ClassicEditor, Bold, Essentials, Italic, Paragraph, Undo, List, Indent, Link } from 'ckeditor5';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { Label } from "./label";

import 'ckeditor5/ckeditor5.css';
import './editor.css';
import { cn } from "util/tailwind";

interface EditorState {
  editor: ClassicEditor | null
  showEditor: boolean
  isCurrentlyEditing: boolean
}
interface EditorProps {
  value: string
  onChange: (newValue: string) => void
  placeholder: string
  label?: string;
  error?: string;
  required?: boolean;
  onBlur: () => void;
}
export const Editor = ({ error, label, value, required, onChange, onBlur, placeholder }: EditorProps) => {
  const [state, setState] = useState<EditorState>({
    editor: null,
    showEditor: false,
    isCurrentlyEditing: false
  })

  const onReadyCallback = useCallback((editor) => {
    if (state.isCurrentlyEditing) {
      // @ts-ignore
      editor.editing.view.focus()
    }

    setState({
      ...state,
      editor: editor
    })
  }, [state, setState])

  const onFocusCallback = useCallback(() => {
    setState({
      ...state,
      isCurrentlyEditing: true
    })
  }, [state, setState])

  const onBlurCallback = useCallback(() => {
    setState({
      ...state,
      isCurrentlyEditing: false
    });
    onBlur();
  }, [state, setState])

  const onChangeCallback = useCallback((e: any, editor: ClassicEditor) => {
    onChange(editor.getData())
  }, [onChange])

  React.useEffect(() => {
    if (error) {
      // Focus on editor when error occurs
      if (state.editor) {
        // @ts-ignore
        state.editor.editing.view.focus();
      }
    }
  }, [error, state.editor])

  return (
    <div className="">
      <Label className="mb-1">
        {label}{required ? <span className="text-danger-foreground"> *</span> : ''}
      </Label>
      <div className={cn(`prose text-text-body text-sm rounded-lg max-w-none w-full`, {
        'border border-destructive': !!error
      })}>
        <CKEditor
          editor={ClassicEditor}
          data={value}
          onReady={onReadyCallback}
          onFocus={onFocusCallback}
          onBlur={onBlurCallback}
          onChange={onChangeCallback}
          config={{
            placeholder: `${placeholder}`,
            toolbar: ['undo', 'redo', '|', 'bold', 'italic', '|', 'numberedList', 'bulletedList', '|', 'link', '|', 'outdent', 'indent'],
            plugins: [
              Bold, Essentials, Italic, Paragraph, Undo, List, Indent, Link
            ],
            link: {
              addTargetToExternalLinks: true,
              defaultProtocol: "https://"
            },
          }}
        />
      </div>

      {error && (
        <p className="mt-1 text-sm font-semibold text-destructive">
          {error}
        </p>
      )}
    </div>
  )
}

export default Editor