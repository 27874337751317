import React from 'react'
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../common_component/base/dialog";
import InputField from '../../../common_component/form/InputField';
import { Button } from '../../../common_component/base/button';
import api from 'networking/api';
import { useToast } from '../../../common_component/base/use-toast';
import { KindoNetworkError } from 'error/server_error';

type FormData = {
  pcat?: string;
}
type Props = {
  schoolId: string;
  pcat?: string;
  open: boolean;
  isNew?: boolean;
  onCreated: (pcat: string) => void;
  onOpenChange: (open: boolean) => void;
}

const PayableCategoryFormDialog = (props: Props) => {
  const {
    schoolId,
    pcat,
    open,
    isNew,
    onOpenChange,
    onCreated,
  } = props;

  const { toast } = useToast();
  const { control, handleSubmit, reset, formState: { isSubmitting } } = useForm<FormData>({
    defaultValues: {
      pcat
    },
  });

  const onSubmit = async (values: FormData) => {
    if (values.pcat) {
      const response = !isNew && pcat ?
        await api.admin.updatePayableCategory({
          schoolId,
          categoryName: pcat,
          newCategoryName: values.pcat
        }) :
        await api.admin.createPayableCategory({
          schoolId,
          categoryName: values.pcat
        });
      if (response.ok) {
        toast({
          title: `Payable Category has been ${!isNew ? 'updated' : 'created'}`,
        });
        onCreated(values.pcat);
        onOpenChange(false);
      } else {
        const error = await response.json() as KindoNetworkError;
        toast({
          variant: 'destructive',
          title: `Failed to ${!isNew ? 'update' : 'create'} payable category`,
          ...(error?.name === 'EntityConflictException' ? {
            description: 'Payable Category name already exists'
          } : {}),
        });
      }
    }
  };

  React.useEffect(() => {
    // initialize form when dialog is opened
    if (open) {
      reset({
        pcat,
      })
    }
  }, [open])

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {!isNew ? 'Edit Payable Category' : 'Create Payable Category'}
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4 pb-1 text-card-foreground'>
            <InputField
              name="pcat"
              control={control}
              label="Payable Category Name"
              rules={{
                required: 'Payable Category name is required'
              }}
            />
            {
              !isNew && (
                <p className='text-sm text-text-helper'>
                  Note: Changing the name of a payable category will unset all payables in that category. If you do not want to unset the category of the payables, please create a new payable category instead.
                </p>
              )
            }
          </div>
          <DialogFooter>
            <div className='flex items-center gap-3'>
              <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
              <Button type="submit" disabled={isSubmitting}>{!isNew ? 'Update' : 'Create'}</Button>
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default PayableCategoryFormDialog