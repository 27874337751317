import { baseUrl } from "networking/constants";
import AdminLayout from "../../common_component/layout/AdminLayout";
import React from "react";
import { Outlet } from "react-router-dom";

export const rootBreadcrumbItems = [
  {
    label: 'Admin',
    to: `/app/admin`,
  },
]
const AdminPage = () => {

  return (
    <div>
      <AdminLayout
      >
        <>
          <Outlet />
        </>
      </AdminLayout>
    </div>
  )
}

export default AdminPage;
