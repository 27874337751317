import React from 'react';
import { Controller, type UseControllerProps } from "react-hook-form";
import Editor from 'components/base/editor';

// Removing duplicate prop conflict from InputProps since UseControllerProps already has it
interface Props extends UseControllerProps<any> {
  placeholder: string
  label?: string;
  required?: boolean;
}

const EditorField = ({
  name,
  control,
  rules,
  placeholder,
  label,
  required,
  ...props
}: Props) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ fieldState: { error }, field: { onChange, onBlur, ...field } }) => (
      <Editor
        label={label}
        required={required}
        value={field.value || ''}
        onChange={(val) => onChange(val)}
        error={error?.message}
        onBlur={onBlur}
        placeholder={placeholder || ''}
      />
    )}
  />
)

export default EditorField;