import React, { useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../common_component/base/dialog";
import { Button } from '../../common_component/base/button'
import { useForm, useFieldArray } from "react-hook-form";
import InputField from '../../common_component/form/InputField';
import ComboBoxField from '../../common_component/form/ComboBoxField';
import { useToast } from '../../common_component/base/use-toast';
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import { TaintedPattern } from "../../util/tainted_regexp";
import SelectField from '../../common_component/form/SelectField';

import { useStore } from '../../store/store';
import { observer } from 'mobx-react';

import api from 'networking/api';
import { useNavigate } from 'react-router-dom';
import HandshakeDialog from './HandshakeDialog';
import { privacyTerms } from 'networking/constants';
import { getCookie, deleteCookie } from '../../util/util';
import { platformEnv } from 'networking/constants';
import PasswordRequirements from 'components/PasswordRequirements';

type FormData = {
  email: string;
  password: string;
  firstName: string;
  surname: string;
  number: string;
  students?: {
    first_name: string;
    last_name: string;
    school: string;
  }[];
  phoneCode: {
    label: string;
    description: string;
    value: string;
  };
};

export const phoneCode = [
  {
    label: 'mob',
    description: '',
    value: 'mob-',
  },
  {
    label: '09',
    description: '',
    value: '09-',
  },
  {
    label: '07',
    description: '',
    value: '07-',
  },
  {
    label: '06',
    description: '',
    value: '06-',
  },
  {
    label: '04',
    description: '',
    value: '04-',
  }, {
    label: '03',
    description: '',
    value: '03-',
  },
]

const FlowJoinDialog = observer(() => {
  const store = useStore();
  const navigate = useNavigate();
  const { toast } = useToast();

  const isRenderingFromNativeApp = store.family.isRenderingFromNativeApp;

  const [step, setStep] = useState(1);

  const { control, handleSubmit, watch, formState: { isSubmitting } } = useForm<FormData>({
    mode: 'onSubmit',
    reValidateMode: 'onBlur',
    defaultValues: {
      students: [{ first_name: '', last_name: '', school: '' }],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'students',
  });

  const [showPassword, setShowPassword] = React.useState(false)

  const [email, setEmail] = useState('');

  const { data: userStatusData, isLoading: userStatusDataLoading } = api.shop.useUserStatus(email);
  const { data: schools, isLoading: isLoadingSchools, error, mutate: mutateSchools } = api.school.useSchools();

  const currentPassword = watch('password');
  const atLeast8Chars = currentPassword?.length >= 8;
  const hasStrongPassword = currentPassword && TaintedPattern.password.test(currentPassword);
  const lastStep = store.shop.isCommunityShop ? 4 : 5;
  const { mutate: termsMutate } = api.family.useTermsAndConditions();
  const [showSeededDialog, setShowSeededDialog] = React.useState(false);

  const addStudent = () => {
    append({ first_name: '', last_name: '', school: '' });
  };

  const removeStudent = (index: number) => {
    remove(index);
  };

  const sessionCookie = getCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);

  React.useEffect(() => {
    if (sessionCookie) {
      deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
    }
  }, [sessionCookie])

  React.useEffect(() => {
    if (error?.name === 'SessionClosedException') {
      deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
    }
  }, [error?.name])

  React.useEffect(() => {
    if (isRenderingFromNativeApp) {
      setStep(2)
    }
  }, [isRenderingFromNativeApp])

  const goToCheckout = () => {
    navigate('/app/prepare-orders');
    store.shop.setShowPrecheckout(false);
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  };

  const handleNext = async (values: FormData) => {
    if (step === 2) {
      if (userStatusData?.status === 'walkup') {
        setStep(step + 1);
        mutateSchools()
      } else if (userStatusData?.status === 'joined') {
        goToLogin()
        toast({
          description: 'This email is already registered. Please login to continue.',
          duration: 4000,
        });
      } else if (userStatusData?.status === 'seeded') {
        setShowSeededDialog(true)
      }
    } else if (step === lastStep) {
      const familyData = {
        contact_name: values.firstName,
        email: values.email,
        family_name: values.surname,
        contact_telephone: `${values.phoneCode}${values.number}`,
        password: values.password,
        students: store.shop.isCommunityShop ? [] : values.students,
        phones: [],
        creator: store.shop.isCommunityShop ? 'community' : undefined
      }

      try {
        const familyResponse = await api.family.createFamily(familyData);

        if (familyResponse.ok) {
          const loginResponse = await api.family.login({
            email: values.email,
            password: values.password
          });

          if (loginResponse.ok) {
            const termsData = await termsMutate();
            if (termsData) {
              const acceptTermsResponse = await api.family.acceptTerms(termsData);

              if (acceptTermsResponse.ok) {
                if (store.shop.isCommunityShop) {
                  goToCheckout();
                } else {
                  navigate('/app/shop', { replace: true });
                  window.location.reload();
                }
                store.shop.setIsAccountCreationRequired(false);
                store.family.setShowFlowJoinDialog(false);
                store.family.setIsAuthenticated(true);
              }
            }
          }
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    } else {
      setStep(step + 1);
    }
  }

  const handleBack = () => {
    if (step > 1) {
      if (isRenderingFromNativeApp && step === 2) {
        window.location.href = `mykindo://auth/login`
      } else {
        setStep(step - 1)
      }
    } else {
      if (isRenderingFromNativeApp) {
        window.location.href = `mykindo://auth/login`
      } else {
        store.family.setShowFlowJoinDialog(false);
      }
    }
  }

  const goToLogin = () => {
    if (store.shop.isCommunityShop) {
      store.family.setShowLoginDialog(true)
    } else {
      store.family.setShowFlowJoinDialog(false)
    }
  }

  const getStepTitle = () => {
    switch (step) {
      case 1:
        return store.shop.isCommunityShop ? 'Hi there,' : 'Create an account';
      case 2:
        return `Step 1 of ${lastStep - 2}`;
      case 3:
        return `Step 2 of ${lastStep - 2}`;
      case 4:
        return store.shop.isCommunityShop ? `Thank you` : `Step 3 of ${lastStep - 2}`;
      case 5:
        return store.shop.isCommunityShop ? null : `Thank you`;
      default:
        return '';
    }
  }

  // Start of Steps
  const LandingStep = (
    <div>
      <p className='pb-4 text-sm text-card-foreground'>
        Simply provide a password and some details to help organise your order.
      </p>
      <p className='text-sm text-card-foreground'>
        If you have an account, please <Button type='button' variant='linkUnderline' className='p-0 m-0' onClick={goToLogin}>login</Button>.
      </p>
    </div>
  );

  const LoginCredentialsStep = (
    <>
      <div className='flex flex-col gap-6'>
        <div>
          <InputField
            label='Email address'
            autoFocus
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: TaintedPattern.email,
                message: 'Invalid email address',
              },
              validate: {
                schoolManagedAccount: () => {
                  if (userStatusData?.status === 'school_managed_account') {
                    return `This account is managed by ${userStatusData.holder}. To claim it, please contact the school.`;
                  }
                }
              },
            }}
            onBlur={() => {
              const email = watch('email');
              setEmail(email);
            }}
            name="email"
          />
          {store.shop.isCommunityShop && (
            <p className='pt-1 text-sm text-text-helper'>We use this to send your purchase confirmation. We won't send you promotional emails.</p>
          )}
          {
            userStatusData?.status === 'joined' && (
              <p className="pt-1 text-sm font-bold text-success">This email is already registered. Please <Button type='button' variant='linkUnderline' className='h-auto p-0 m-0 font-semibold text-flags-vegetarian' onClick={goToLogin}>login</Button></p>
            )
          }
        </div>
        {userStatusData?.status && userStatusData?.status === 'walkup' && (
          <div>
            <InputField
              label="Password"
              control={control}
              type={showPassword ? "text" : "password"}
              rules={{
                required: 'Enter a password',
                minLength: {
                  value: 8,
                  message: 'Password must be at least 8 characters long'
                },
                validate: {
                  hasNumber: (value) => TaintedPattern.numbersRegex.test(value) || 'Password must contain at least one number',
                  hasLowercase: (value) => TaintedPattern.lowercaseRegex.test(value) || 'Password must contain at least one lowercase letter',
                  hasUppercase: (value) => TaintedPattern.uppercaseRegex.test(value) || 'Password must contain at least one uppercase letter',
                  hasSpecialCharacter: (value) => TaintedPattern.specialCharactersRegex.test(value) || 'Password must contain at least one of these !@#$%^&* special characters',
                }
              }}
              autoComplete='new-password'
              endAdornment={
                <button type="button" className="" onClick={togglePasswordVisibility}>
                  {
                    showPassword ? (
                      <MdVisibility className="w-4 h-4 text-gray-500" />
                    ) : (
                      <MdVisibilityOff className="w-4 h-4 text-gray-500" />
                    )
                  }
                </button>
              }
              name="password"
            />
            <PasswordRequirements password={currentPassword} />
          </div>
        )}

        {(userStatusData?.status === 'seeded') &&
          <HandshakeDialog open={showSeededDialog} onOpenChange={(open) => setShowSeededDialog(open)} email={userStatusData.email} seedSchools={userStatusData.seed_schools} />
        }
      </div>
    </>
  );

  const UserDetailStep = (
    <div>
      <div className='flex flex-col gap-6'>
        <InputField
          label="First name"
          autoFocus
          control={control}
          rules={{
            required: 'First name is required',
          }}
          name="firstName"
        />

        <InputField
          label="Surname"
          control={control}
          rules={{
            required: 'Surname is required',
          }}
          name="surname"
        />

        <div>
          <p className='pb-1 text-sm text-card-foreground'>
            Phone number
          </p>
          <div className='grid grid-cols-3 gap-2'>
            <SelectField
              control={control}
              name='phoneCode'
              items={phoneCode}
              rules={{
                required: 'Phone area code is required',
              }}
            />
            <div className='col-span-2'>
              <InputField
                control={control}
                rules={{
                  required: 'Phone number is required',
                  pattern: {
                    value: TaintedPattern.telephoneNumberOnly,
                    message: 'Invalid number',
                  },
                }}
                type='number'
                name="number"
              />
            </div>
          </div>
          <p className='pt-1 text-sm text-text-helper'>We'll use your phone number if we need to contact you about your order.</p>
        </div>
      </div>
    </div>
  );

  const MemberStep = (
    <div>
      <div className='flex flex-col gap-4'>
        <div>
          <p className='text-lg font-semibold font-museo'>Add a student</p>
          <p className='text-sm text-card-foreground'>You can access and edit your student details once you have setup your mykindo account.</p>
        </div>
        <div>
          {fields.map((student, index) => (
            <div key={index}>
              {index > 0 && <p className='pt-6 font-semibold text-md font-museo'>{`Student ${index + 1}`}</p>}
              <div className='flex flex-col gap-6'>
                <InputField
                  label="First name"
                  autoFocus
                  control={control}
                  defaultValue={student.first_name}
                  rules={{
                    required: 'First name is required',
                  }}
                  name={`students[${index}].first_name`}
                />

                <InputField
                  label="Last name"
                  control={control}
                  defaultValue={student.last_name}
                  rules={{
                    required: 'Last name is required',
                  }}
                  name={`students[${index}].last_name`}
                />

                <ComboBoxField
                  className='mb-2'
                  label="Student's school"
                  name={`students[${index}].school`}
                  control={control}
                  isLoading={isLoadingSchools}
                  defaultValue={student.school}
                  rules={{
                    required: 'Please select a school',
                  }}
                  options={schools?.map(({ school_id }) => ({ label: school_id, value: school_id })) || []}
                  borderColor='primary'
                />
              </div>
              {index === fields.length - 1 && (
                <div className='flex justify-between'>
                  <Button
                    type="button"
                    className='px-0 font-semibold font-museo'
                    variant="linkUnderline"
                    onClick={addStudent}>
                    Add another student +
                  </Button>
                  {index > 0 &&
                    <Button
                      type="button"
                      className='px-0 font-semibold font-museo'
                      variant="linkUnderline"
                      onClick={() => removeStudent(index)}>
                      Remove student
                    </Button>
                  }
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );

  const ThankYouStep = (
    <div className='text-sm'>
      <p className='text-card-foreground'>By continuing to checkout, you agree to our <a className='underline text-foreground' href={privacyTerms} target='_blank' rel="noreferrer">Terms & Conditions</a></p>
    </div>
  );

  // End of Steps
  return (
    <Dialog open={store.family.showFlowJoinDialog}>
      <DialogContent hideCloseButton>
        <DialogHeader>
          <DialogTitle>
            {getStepTitle()}
          </DialogTitle>
        </DialogHeader>
        <form
          className='flex flex-col gap-6'
          onSubmit={handleSubmit(handleNext)}
        >
          <div className=''>
            {step === 1 && LandingStep}
            {step === 2 && LoginCredentialsStep}
            {step === 3 && UserDetailStep}
            {(store.shop.isCommunityShop && step === 4 && ThankYouStep) ||
              (!store.shop.isCommunityShop && (
                (step === 4 && MemberStep) || (step === 5 && ThankYouStep)
              ))}
          </div>

          <DialogFooter className='flex flex-col w-full sm:flex-col'>
            <Button
              type="submit"
              variant="default"
              disabled={isSubmitting}
              className='text-base font-semibold font-museo'
            >
              Continue {(store.shop.isCommunityShop && step === lastStep) && 'to checkout'}
            </Button>
            <Button
              variant="ghost"
              type="button"
              className='text-base font-semibold font-museo'
              onClick={handleBack}
            >
              {step === 1 ? 'Cancel' : 'Back'}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
});

export default FlowJoinDialog;
