"use client"

import * as React from "react"
import * as CheckboxPrimitive from "@radix-ui/react-checkbox"
import { MdCheck, MdHorizontalRule } from "react-icons/md"

import { cn } from "../../util/tailwind"
import { Label } from "./label"

type CheckboxProps = React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
  label?: string | React.ReactNode
  indeterminate?: boolean
}
const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, label, indeterminate, name, ...props }, ref) => {
  const checkbox = (
    <CheckboxPrimitive.Root
      ref={ref}
      id={name}
      name={name}
      data-testid={`checkbox-${name}`}
      className={cn(
        "peer h-6 w-6 shrink-0 bg-input rounded-sm border-1 border-border-input ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-primary data-[state=checked]:bg-primary",
        className
      )}
      {...props}
    >
      <CheckboxPrimitive.Indicator
        className={cn("flex items-center justify-center text-primary-foreground")}
      >
        {
          indeterminate ? (
            <MdHorizontalRule className="w-5 h-5" />
          ) : (
            <MdCheck className="w-5 h-5" />
          )
        }
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  )
  if (!label) return checkbox;
  return (
    <div className="flex items-start gap-2">
      {checkbox}
      <label className="text-sm pt-1 cursor-pointer leading-[18px] text-text-body peer-disabled:cursor-not-allowed peer-disabled:opacity-70" htmlFor={name}>{label}</label>
    </div>
  )
})
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
