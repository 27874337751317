import React from 'react'
import { useForm } from "react-hook-form";
import InputField from '../../common_component/form/InputField';
import { Button } from '../../common_component/base/button'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../common_component/base/dialog";
import { useStore } from '../../store/store';
import api from 'networking/api';
import { observer } from 'mobx-react';
import { baseUrl, platformEnv } from 'networking/constants';
import { useNavigate } from 'react-router-dom';
import { deleteCookie } from 'util/util';

type FormData = {
  email: string;
  password: string;
};

const LoginDialog = observer(() => {
  const store = useStore();
  const navigate = useNavigate();
  const [error, setError] = React.useState('');
  const { control, handleSubmit, formState: { isSubmitting } } = useForm<FormData>();

  const goToCheckout = () => {
    navigate('/app/prepare-orders');
    store.shop.setShowPrecheckout(false);
  }

  const handleLogin = (values: FormData) => {
    deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`, '/');
    return api.family.login({
      email: values.email,
      password: values.password
    }).then(async (response) => {
      if (response.ok) {
        try {
          const body = await response.text();
          const invalidUserNameOrPassword = body.includes('Email address was not found or password was incorrect');
          const attemptsExceeded = body.includes('maximum number of login attempts has been exceeded');
          if (invalidUserNameOrPassword) {
            setError('Invalid email or password');
          } else if (attemptsExceeded) {
            setError('Maximum number of login attempts has been exceeded. Please reset your password or wait 1 day');
          } else {
            store.family.setIsAuthenticated(true);
            store.family.setShowLoginDialog(false);
            if (store.family.showFlowJoinDialog) {
              store.family.setShowFlowJoinDialog(false)
              store.shop.setIsAccountCreationRequired(false)
              goToCheckout();
            } else {
              window.location.reload();
            }
          }
        } catch (e) {
          setError('Invalid email or password');
        }
      } else {
        setError('Invalid email or password');
      }
    })
  }

  return (
    <Dialog open={store.family.showLoginDialog}>
      <DialogContent hideCloseButton>
        <DialogHeader>
          <DialogTitle className='text-lg font-semibold font-museo'>
            {store.shop.isCommunityShop ?
              'Login'
              :
              'You seem to have been logged out'
            }
          </DialogTitle>
          {!store.shop.isCommunityShop && (
            <DialogDescription>
              This usually happens when you or a family member logs in from another device.
            </DialogDescription>
          )}
        </DialogHeader>
        <form
          className='flex flex-col gap-6'
          onSubmit={handleSubmit(handleLogin)}
        >
          <InputField
            label="Email address"
            autoFocus
            control={control}
            rules={{
              required: 'Email is required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Please enter a valid email address',
              },
            }}
            name="email"
          />

          <div>
            <InputField
              label="Password"
              control={control}
              type="password"
              rules={{
                required: 'Password is required'
              }}
              name="password"
            />
            <div className='flex justify-end'>
              <Button
                className='text-xs w-fit'
                type="button"
                size="xs"
                variant="linkUnderline"
                onClick={() => {
                  store.family.setShowFlowJoinDialog(false);
                  store.family.setShowForgotPasswordDialog(true);
                }}
              >
                Forgot password?
              </Button>
            </div>
          </div>
          {
            error &&
            <p className='text-sm font-semibold text-destructive'>
              {error}
            </p>
          }
          <DialogFooter className='flex flex-col-reverse w-full gap-y-3 sm:flex-col-reverse sm:space-x-0'>
            {!store.shop.isCommunityShop ?
              <Button
                type="button"
                variant="link"
                className='text-base font-semibold font-museo'
                onClick={() => {
                  navigate('/app/login');
                  store.family.setShowLoginDialog(false);
                }}
              >
                Back to main login
              </Button>
              :
              <Button
                type="button"
                variant="link"
                className='text-base font-semibold font-museo'
                onClick={() => {
                  store.family.setShowLoginDialog(false)
                }}
              >
                Back
              </Button>
            }
            <Button
              disabled={isSubmitting}
              size="lg"
              type="submit"
              className='text-base font-semibold font-museo'
            >
              Login
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
});

export default LoginDialog