import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog';
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from '../../../common_component/base/button';
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { useForm } from 'react-hook-form';
import InputField from '../../../common_component/form/InputField';
import DatePickerField from "../../../common_component/form/DatePickerField"
import { format } from 'date-fns';
import RadioGroupField from "../../../common_component/form/RadioGroup"
import { ReOrderPurchase } from 'networking/models/customer';

type Props = {
  purchaseId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  mutate: () => void;
};

type FormProps = {
  cancel: boolean;
  deliveryDate: string;
  preview: boolean;
  excludeMemberFirstNames: string;
}

const ReOrderPurchaseDialog = ({ purchaseId, open, onOpenChange, mutate }: Props) => {
  const { toast } = useToast();
  const [error, setError] = React.useState('');
  const { control, handleSubmit, watch } = useForm<FormProps>({
    defaultValues: {
      cancel: false,
      deliveryDate: format(new Date(), 'yyyy-MM-dd'),
      preview: true,
      excludeMemberFirstNames: ''
    }
  });

  const { trigger, isMutating } = useSWRMutation('/customer/reorder', async (key, { arg }: { arg: ReOrderPurchase }) => {
    try {
      const response = await api.customer.reOrderPurchase(arg);

      if (response) {
        const res = await response.json();
        if (res.rtype === "error") {
          setError(res.message)
        } else if (res.success) {
          onOpenChange(false);

          toast({
            title: `${res.message}`,
            duration: 3000,
          });
          mutate();
          setError('')
        } else {
          setError(res.message)
        }
      } else {
        setError('Something went wrong. Please try again.');
      }
    } catch (e) {
      setError('Something went wrong, please try again.');
    }
  });

  const onSubmit = (data: FormProps) => {
    const reOrderData = {
      cancel: data.cancel,
      delivery_date: data.deliveryDate,
      purchase_id: purchaseId,
      rtype: "reorder",
      preview: data.preview,
      ...(data.excludeMemberFirstNames ? { exclude_member_first_names: data.excludeMemberFirstNames } : {}),
    }
    trigger(reOrderData)
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Re-order purchase</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-5' >
            <DatePickerField
              transform={{
                input: (value) => value ? new Date(value) : null,
                output: (value) => {
                  return value ? format(value, 'yyyy-MM-dd') : '';
                }
              }}
              control={control}
              label="Enter new date"
              name="deliveryDate"
            />
            <InputField
              control={control}
              name="excludeMemberFirstNames"
              label="Enter name of student to remove (or leave empty)"
            />
            <RadioGroupField
              control={control}
              name="cancel"
              label="Keep original purchase? (choose cancel to cancel the original)"
              options={[
                {
                  label: 'Cancel',
                  value: true,
                },
                {
                  label: 'Keep',
                  value: false,
                }
              ]}
              orientation="horizontal"
            />
            <RadioGroupField
              control={control}
              name="preview"
              label={`Please confirm you want to reorder for ${format(new Date(watch('deliveryDate')), 'PPPP')} ${watch('excludeMemberFirstNames') ? `without items for ${watch('excludeMemberFirstNames')}` : 'with no member changes'}`}
              options={[
                {
                  label: 'Preview',
                  value: true,
                },
                {
                  label: 'Re-order',
                  value: false,
                }
              ]}
              orientation="horizontal"
            />
          </div>
          {error && <div className="mt-6 text-sm text-destructive">{error}</div>}
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
            <Button type="submit" disabled={isMutating}>Submit</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ReOrderPurchaseDialog;