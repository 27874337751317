import React from "react";
import { Button, ButtonProps } from '../base/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../base/dialog";

export type ContentDialogProps = {
  open?: boolean;
  onOpenChange: (open: boolean) => void;
  content?: React.ReactNode;
  title: string;
  description?: string | React.ReactNode;
  primaryActionLabel?: string;
  primaryActionProps?: ButtonProps;
  secondaryActionLabel?: string;
  secondaryActionProps?: ButtonProps
  hideCloseButton?: boolean;
}

const ContentDialog = (props: ContentDialogProps) => {
  const {
    open,
    onOpenChange,
    content,
    title,
    description,
    primaryActionLabel = 'Save',
    primaryActionProps,
    secondaryActionLabel = 'Cancel',
    secondaryActionProps,
    hideCloseButton,
  } = props;
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='w-auto sm:min-w-[35vw]'>
        <DialogHeader className="z-50">
          <DialogTitle className='text-lg font-semibold font-museo'>
            {title}
          </DialogTitle>
          {
            description && (
              <DialogDescription>
                {description}
              </DialogDescription>
            )
          }
        </DialogHeader>
        <form>
          {content}
          <DialogFooter className="flex-row">
            {
              !hideCloseButton && (
                <Button
                  type="button"
                  variant='outline'
                  className='flex-1 sm:flex-none font-semibold font-museo sm:min-w-[80px]'
                  onClick={() => onOpenChange(false)}
                  {...secondaryActionProps}
                >
                  {secondaryActionLabel}
                </Button>
              )
            }
            <Button
              type="button"
              className='font-semibold  sm:flex-none font-museo  flex-1 sm:min-w-[80px]'
              {...primaryActionProps}
            >
              {primaryActionLabel}
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog >
  )
};

export default ContentDialog