import * as Sentry from "@sentry/react";
import { IN_PRODUCTION } from "../util/util";
import { ErrorInfo as ReactErrorInfo } from 'react';
import { KindoNetworkError, KindoServerError, ServerSessionExceptionRequiresRefresh } from "./server_error";
import React from "react";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

if (IN_PRODUCTION) {
  console.log("In production mode.")
}
else {
  console.log(`Starting with version: ${process.env.RELEASE_ID}`)
  console.log("Not in production.")
}

export const initializeSentry = () => {
  if (IN_PRODUCTION) {
    // https://docs.sentry.io/platforms/javascript/guides/react/features/react-router/
    Sentry.init({
      dsn: "https://6d7252d380fb40fa9182086de794e134@o416404.ingest.sentry.io/5403539",
      release: process.env.RELEASE_ID,
      enabled: IN_PRODUCTION,
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.reactRouterV6Instrumentation(
            React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes
          ),
        }),
      ],
      environment: (window.location.hostname.split('.')[0].endsWith('2')) ? 'test' : 'production',
      ignoreErrors: [
      ],
      allowUrls: [
        // Allow urls that are in the same domain.
        /https?:\/\/.*tgcl.co.nz\/.*/,
      ],
      beforeSend: (event: Sentry.Event, hint?: Sentry.EventHint) => {
        if (hint !== undefined && hint.originalException !== undefined) {
          const error = hint.originalException

          if (!shouldSendError(error)) {
            // don't send session issues to sentry.
            return null
          }
        }

        return event
      }
    })
  } else {
    console.log('Not enabling sentry...')
    return
  }
}

const shouldSendError = (error: any) => {
  // returns true if the error should be sent.
  if (error instanceof KindoServerError) {
    if (error.name === 'PasswordNotResetableUserException') {
      return false
    }
  }
  else if (error instanceof TypeError && error.message === "NetworkError when attempting to fetch resource.") {
    console.warn("Not sending Network Error as it is likely due to internet connection issues")
    return false
  }

  return !(error instanceof ServerSessionExceptionRequiresRefresh)
}

Sentry.addGlobalEventProcessor(((event, hint) => {
  console.log(event.exception)

  return event
}));

interface UpdateErrorUserDetailProps {
  userId?: string
  userHandle?: number
}
export function setErrorUserDetail({ userId, userHandle }: UpdateErrorUserDetailProps) {
  Sentry.setUser({
    id: userId,
    handle: userHandle?.toString()
  })
}

const contextForException = (error: unknown) => {
  if (error instanceof KindoNetworkError) {
    // Unwrap all the details into the error report.
    console.log(`Unwrapping error: ${error}...`)
    return {
      tags: {
        kindoError: 'true'
      },
      extra: {
        'primaryMessage': error.primaryMessage,
        'secondaryMessage': error.secondaryMessage,
        'detailMessage': error.detailMessage,
        'wrappedError': error.wrappedError,
        'context': error.context,
        ...((error instanceof KindoServerError) ? {
          'rtype': error.rtype,
          'errorName': error.errorName,
          'errorMessage': error.errorMessage,
        } : {})

      }
    }
  }

  return {}
}

export function reportComponentError(error: any, { componentStack }: ReactErrorInfo) {
  Sentry.captureException(
    error,
    {
      ...contextForException(error),
      contexts: { react: { componentStack } }
    }
  )
}

export function reportError(error: any) {
  Sentry.captureException(error, contextForException(error));
}