import React from "react";
import { Controller, type UseControllerProps } from "react-hook-form";
import { RadioGroup, RadioGroupItem } from "../base/radio-group"
import { Label } from "../base/label";
import { cn } from "../../util/tailwind";
import _ from "lodash";

type RadioOption = {
  label: string;
  value: string;
  subtitle?: string | React.ReactNode;
};
type RadioGroupProps = {
  orientation?: 'horizontal' | 'vertical';
  label?: string;
  options: RadioOption[];
  required?: boolean;
  onValueChange?(value: string): void;
} & UseControllerProps<any>;

const RadioGroupField: React.FC<RadioGroupProps> = ({
  control,
  label,
  options,
  required,
  orientation,
  name,
  rules,
  onValueChange,
}) => {

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, name, onBlur }, fieldState: { error } }) => (
        <div className="flex flex-col space-y-3">
          {label && (
            <Label htmlFor={name}>
              {label}{required ? <span className="text-danger-foreground"> *</span> : ''}
            </Label>
          )}
          {!!error && (
            <div className="text-sm text-destructive">{error.message}</div>
          )}
          <RadioGroup
            className={cn({
              'flex-row flex gap-3': orientation === 'horizontal',
            })}
            onValueChange={(value) => {
              onChange(value);
              if (onValueChange) {
                onValueChange(value);
              }
            }}
            value={value}
          >
            {options.map((option, index) => (
              <div key={option.value} className="flex items-start space-x-2">
                <RadioGroupItem data-testid={`radio-${name}-${_.kebabCase(option.value) || index}`} value={option.value} id={option.value} />
                <div className="pt-0.5 flex-1">
                  <Label htmlFor={option.value} className={cn(
                    "text-sm font-medium leading-none cursor-pointer text-text-body w-max",
                    {
                      "font-bold": value === option.value,
                    }
                  )}>
                    {option.label}
                  </Label>
                  {
                    option.subtitle && (
                      <p className="text-xs text-text-helper">{option.subtitle}</p>
                    )
                  }
                </div>
              </div>
            ))}
          </RadioGroup>
        </div>

      )}
    />

  );
};

export default RadioGroupField;
