import React from 'react'
import { MdChevronRight } from 'react-icons/md';
import { cn } from 'util/tailwind';

type Props = {
  label: string;
  description?: string | React.ReactNode;
  labelVariant?: 'danger' | 'default';
  onClick?: () => void;
  descriptionVariant?: 'helper' | 'default';
}
/**
 * List item for an action list
 * @param props 
 * @returns 
 */
const ActionListItem = (props: Props) => {
  const {
    label,
    description,
    descriptionVariant,
    labelVariant,
    onClick,
  } = props;
  return (
    <div
      className={cn('flex flex-row items-center justify-between flex-1 gap-2 py-2 pl-4 pr-2 transition-colors rounded-md sm:gap-4 sm:py-3 hover:border-transparent hover:bg-card-hover active:bg-card-hover', {
        'hover:cursor-pointer': !!onClick,
      })}
      {
      ...(onClick ? {
        onClick,
        role: 'button',
        tabIndex: 0
      } : {})
      }
    >
      <div className='flex flex-col flex-1 gap-1 w-[85%]'>
        <p className={cn('text-sm font-bold text-text-body', {
          'text-destructive': labelVariant === 'danger'
        })}>
          {label}
        </p>
        {typeof description === 'string' && (
          <p className={cn(`text-sm text-text-body`, {
            'text-text-helper text-xs': descriptionVariant === 'helper'
          })}>
            {description}
          </p>
        )}
        {typeof description !== 'string' && (
          description
        )}
      </div>
      {
        onClick && (
          <div>
            <MdChevronRight className='w-6 h-6 flex-0 text-text-helper' />
          </div>
        )
      }
    </div>
  )
}

export default ActionListItem