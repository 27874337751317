import React from 'react'
import { cn } from 'util/tailwind'

type Props = {
  children: React.ReactNode,
  noDivider?: boolean
}

const SectionContent = ({ children, noDivider }: Props) => {
  return (
    <div className={cn('mx-2 sm:mx-8', {
      'pb-6 border-b-1 border-border': !noDivider
    })}>
      {children}
    </div>
  )
}

export default SectionContent