import React from 'react'
import { RouteProps, useParams } from 'react-router-dom'
import _ from 'lodash';
import api from 'networking/api';
import { Student, Mcat, Product, Question, Service, SchoolService } from 'networking/models/family';
import { formatCentsPriceForDisplay } from '../../../util/string_util';
import { getSchoolLogo, getServiceImageDirectory, getSupplierImageSrc, kindoLogoUrl, productImageDirectory } from 'networking/constants';
import { format } from 'date-fns'
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react';
import orderUtils from 'networking/util/order';
import ProductDetail from '../dialog/ProductDetail';
import Flags from '../../../common_component/product/Flags';
import AddToCartButton from './AddToCartButton';
import { cn } from '../../../util/tailwind';
import { MdCameraAlt, MdInfoOutline, MdOutlineLocalShipping } from 'react-icons/md';
import AvailableDays from './AvailableDays';
import { useIntersectionObserver } from '../../../util/hooks/useIntersectionObserver';
import Image from '../../../common_component/Image';
import { useToast } from '../../../common_component/base/use-toast';
import { ScrollArea } from '../../../common_component/base/scroll-area';
import { Button } from '../../../common_component/base/button';
import { CartActionProps } from '../../../store/ShopStore';
import { b64decode } from '../../../util/data/base64_utf8_tools';
import { useMediaQuery } from '../../../util/hooks/useMediaQuery';
import analytics from '../../../util/googleAnalytics';
import { pathify } from "../../../util/util";
import { useNavigate } from 'react-router-dom';
import useSchoolServices from 'networking/hooks/useSchoolService';

export type MenuCategoryItemProps = {
  availableServiceDays: number[];
  questions?: {
    [key: string]: Question[]
  };
  stock?: number;
  studentsWithProducts: {
    [studentId: string]: number
  };
  category: Mcat;
  item: Product;
  fallbackSrcs?: string[];
  isServiceClosed: boolean;
  isEzlunchService?: boolean;
  currentService?: Service;
  schoolService?: SchoolService;
  showAvailableDays?: boolean;
  onRemove: (productId: string, student: Student) => Promise<any>;
  onPurchase: (item: Product, category: Mcat, student: Student, quantity?: number) => Promise<any>;
  students: Student[];
  selectedOrderDates: Date[];
}

const MenuCategoryItem = observer((props: MenuCategoryItemProps) => {
  const { currentService, schoolService, isEzlunchService, category, item, stock, availableServiceDays, showAvailableDays, fallbackSrcs, selectedOrderDates, isServiceClosed, students } = props;
  const { family, shop } = useStore();
  const navigate = useNavigate();
  const { toast, dismiss } = useToast();
  const studentsWithProducts = React.useMemo(() => {
    return students.reduce((all, student) => {
      const quantityForStudent = shop.getQuantityInCart({
        student: student,
        productId: item.productId,
        schoolId: family.currentSchool,
      });
      return {
        ...all,
        [student.student_id]: quantityForStudent,
      }
    }, {});
  }, [students, shop.temporaryCartItemCount]);
  const isServiceUndated = (currentService && orderUtils.isServiceUndated(currentService)) ?? false;
  const isMobile = useMediaQuery('(max-width: 640px)');

  const [detailDialogOpened, setDetailDialogOpened] = React.useState(false);

  const productThumbnailImageSrc = `${productImageDirectory}/${category.supplier_id}/thumbnail/${item.picture}`;
  const productImageSrc = `${productImageDirectory}/${category.supplier_id}/${item.picture}`;

  const hasLimitedStock = _.isNumber(stock);
  const isSoldOut = hasLimitedStock && stock === 0;
  const quantityForAllStudents = React.useMemo(() => students.reduce((acc, student) => {
    const quantity = studentsWithProducts[student.student_id];
    return acc + (quantity || 0);
  }, 0), [students, studentsWithProducts]);
  const hasExhaustedStock = hasLimitedStock && quantityForAllStudents >= stock;

  const hasLimitedAvailability = (item.avail_days || []).length > 0;
  const availableOnSomeSelectedDate = orderUtils.isAvailableForDelivery(item.avail_days, selectedOrderDates);
  const availableDays = (item.avail_days && item.avail_days.length > 0 && !availableServiceDays.every((d) => item.avail_days.includes(d))) ? item.avail_days : [];
  const addToCartDisabled = hasExhaustedStock || isServiceClosed || isSoldOut || (hasLimitedAvailability && !availableOnSomeSelectedDate);

  const handlePurchase = (listName: 'Item Detail' | 'Service') => async (item: Product, category: Mcat, student: Student, quantity: number = 1) => {
    if (currentService?.id) {
      try {
        // If product has specific available days, then make sure the selected dates are only those days
        const dates = isServiceUndated ? [] : shop.selectedOrderDates
          .filter((date) => (item.avail_days || []).length > 0 ? item.avail_days.some((day) => date.getDay() === day) : true)
          .sort((a, b) => a.getTime() - b.getTime());
        const datesExcluded = isServiceUndated ? [] : shop.selectedOrderDates
          .filter((date) => (item.avail_days || []).length > 0 ? item.avail_days.every((day) => date.getDay() !== day) : false)
          .sort((a, b) => a.getTime() - b.getTime());

        const productsToAdd: CartActionProps[] = dates.length > 0 ? dates.map((date) => ({
          deliveryDate: date,
          product: item,
          student,
          schoolId: family.currentSchool,
          serviceId: currentService.id,
          closing: currentService.closing,
          quantity,
          supplierId: category.supplier_id,
          isEzlunchService,
          listName,
        })) : [];
        if (productsToAdd.length === 0 && isServiceUndated) {
          productsToAdd.push({
            product: item,
            student,
            schoolId: family.currentSchool,
            serviceId: currentService.id,
            closing: currentService.closing,
            quantity,
            supplierId: category.supplier_id,
            isEzlunchService,
            listName,
          });
        }
        if (productsToAdd.length > 0) {
          productsToAdd.map((product) => {
            shop.addToCart(product);
          })
          if (datesExcluded.length > 0) {
            toast({
              title: `For ${student.first_name}`,
              contentClassName: 'w-full',
              duration: 8000,
              description: (
                <div className='flex flex-col w-full gap-2 mt-2'>
                  <div className='flex gap-3'>
                    <div>
                      <Image
                        className={cn('rounded-md object-scale-down w-[55px] md:h-[55px]')}
                        alt=""
                        fallbackSrcs={[
                          ...(item.picture ? [
                            productThumbnailImageSrc,
                            productImageSrc,
                          ] : []),
                          ...(isEzlunchService ? [] : [
                            `${getServiceImageDirectory({ schoolPath: schoolService?.path ?? '', serviceId: currentService?.id ?? '' })}${currentService?.icon}`,
                            getSchoolLogo({ schoolPath: schoolService?.path ?? '' }),
                            kindoLogoUrl,
                          ])
                        ]}
                      />
                    </div>
                    <div className='flex flex-col gap-1'>
                      <p className='text-xs font-semibold'>{item.product}</p>
                      <p className='text-xs'>
                        Quantity: {shop.getQuantityInCart({
                          productId: item.productId,
                          student,
                          schoolId: family.currentSchool,
                        })}
                      </p>
                      <p className='text-xs'>
                        Subtotal: {formatCentsPriceForDisplay(shop.getSubtotalForProductInCart({
                          productId: item.productId,
                          student,
                          schoolId: family.currentSchool,
                        }))}
                      </p>
                      {
                        !isServiceUndated && (
                          <div className='flex flex-col gap-3 pt-2'>
                            <div className='flex flex-col gap-1'>
                              <p className='flex items-center gap-1'>
                                <MdOutlineLocalShipping className='w-4 h-4' />
                                <p className='text-xs font-semibold'>
                                  Delivery {dates.length > 1 ? 'Dates' : 'Date'}:
                                </p>
                              </p>
                              <div className='flex flex-col gap-1 pl-5 text-xs'>
                                {
                                  dates.map((d) => (
                                    <p key={d.toISOString()}>&#x2022;&nbsp;{format(d, 'eeee dd MMMM')}</p>
                                  ))
                                }
                              </div>
                            </div>
                            {
                              datesExcluded.length > 0 && (
                                <div className='flex flex-col gap-1'>
                                  <p className='flex items-center gap-1'>
                                    <MdInfoOutline className='w-4 h-4' />
                                    <p className='text-xs font-semibold'>
                                      Not available on these dates:
                                    </p>
                                  </p>
                                  <div className='flex flex-col gap-1 pl-5 text-xs'>
                                    {
                                      datesExcluded.map((d) => (
                                        <p key={d.toISOString()}>&#x2022;&nbsp;{format(d, 'eeee dd MMMM')}</p>
                                      ))
                                    }
                                  </div>
                                </div>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                  <Button
                    className='w-full'
                    onClick={() => {
                      shop.isCommunityShop ? navigate('/app/prepare-orders', { replace: true }) : shop.setShowPrecheckout(true);
                      analytics.checkoutClick({
                        initiatedFrom: 'Toast',
                      });
                      dismiss();
                    }}
                  >
                    Checkout
                  </Button>
                </div>
              ),
            })
          }
        } else {
          toast({
            title: 'Unable to add to cart',
            description: 'Please select a valid delivery date.'
          })
        }

        return true;
      } catch (e) {
        console.log('e', e)
        toast({
          title: 'Failed to add to cart',
        })
        return false;
      }
    }
    return false;
  }

  const handleRemove = (listName: 'Item Detail' | 'Service') => async (productId: string, student: Student) => {
    if (currentService?.id) {
      const dates = isServiceUndated ? [new Date()] : shop.selectedOrderDates;
      if (dates.length > 0) {
        dates.forEach((date) => {
          shop.removeFromCart({
            deliveryDate: date,
            product: item,
            student,
            schoolId: family.currentSchool,
            serviceId: currentService.id,
            quantity: 1,
            listName,
          })
        });
      } else {
        shop.removeFromCart({
          product: item,
          student,
          schoolId: family.currentSchool,
          serviceId: currentService.id,
          quantity: 1,
          closing: currentService.closing,
          listName,
        })
      }
      dismiss();
      return true;
    }
    return false;
  };

  const handleViewItem = () => {
    setDetailDialogOpened(true);
    if (currentService) {
      analytics.viewItem({
        items: [{
          productId: item.productId,
          priceInCents: parseInt(item.price_in_cents, 10),
          productName: item.product,
          brandName: isEzlunchService ? 'ezlunch' : 'kindo',
          serviceId: currentService.id,
          supplierId: item.supplierId,
        }],
        listName: 'Service',
      })
    }
  }

  const imageRender = (
    <>
      <Image
        className={cn('w-auto h-auto md:h-fit max-h-[100px] rounded-md object-scale-down max-w-[85px] md:max-w-full md:max-h-[160px]', {
          'blur-sm': isSoldOut,
        })}
        fallbackSrcs={[
          ...(item.picture ? [
            ...(isMobile ? [productThumbnailImageSrc] : [productImageSrc])
          ] : []),
          ...(fallbackSrcs || []),
        ]}
        loading="lazy"
        alt={item.product}
        finalFallback={
          <div className='p-2 gap-1 h-[100%] min-h-[85px] w-[85px] md:h-[160px] flex items-center text-secondary flex-col md:gap-4 justify-center bg-background-light border-border-primary md:w-full rounded-md'>
            <MdCameraAlt className='w-[30px] h-[30px] md:w-[60px] md:h-[60px]' />
            <p className='text-xs text-center md:text-sm'>
              {isSoldOut ? '' : 'Image coming soon'}
            </p>
          </div>
        }
      />
      {isSoldOut && (
        <p className='top-[calc(50%-30px)] absolute flex items-center justify-center md:top-[calc(50%-45px)] w-full text-lg text-center '>
          <div className='flex items-center justify-center rounded-full p-2 md:w-[90px] md:h-[90px] bg-secondary text-secondary-foreground'>
            <p className='text-sm md:text-lg'>
              SOLD
              <br />
              OUT
            </p>
          </div>
        </p>
      )}
    </>
  )
  return (
    <>
      <ProductDetail
        availableServiceDays={availableServiceDays}
        open={detailDialogOpened}
        onOpenChange={setDetailDialogOpened}
        stock={stock}
        product={item}
        packProdUrl={currentService?.pack_prod_url}
        showAvailableDays={showAvailableDays}
        productImageSrc={productImageSrc}
        submitButton={
          <AddToCartButton
            disabled={addToCartDisabled}
            {...props}
            studentsWithProducts={studentsWithProducts}
            onPurchase={handlePurchase('Item Detail')}
            onRemove={handleRemove('Item Detail')}
          />
        }
      />
      <div
        key={item.permanent_id}
        className='flex flex-col gap-1 p-4 shadow-lg sm:shadow-none sm:rounded-sm border-x-0 sm:border-x-1 border-1 border-border hover:shadow-md'
      >
        <div className='relative flex flex-1 gap-3 md:flex-col'>
          <div className='relative flex self-start md:items-center md:justify-center md:w-full max-h-[100px] md:h-[160px] md:max-h-[160px]'>
            {imageRender}
          </div>

          {_.isNumber(stock) && stock > 0 && (
            <div className='absolute top-[-22px] sm:top-[-4px] right-0 px-2 border-2 rounded-full border-warning text-warning bg-card'>
              <p className='text-xs'>
                {stock > 0 ? `${stock} left` : 'Sold out'}
              </p>
            </div>
          )}
          <div
            className='flex flex-col flex-1 gap-3 cursor-pointer'
            onClick={handleViewItem}
          >
            <div className='flex flex-col flex-1 text-sm text-primary'>
              <p className='mb-1'>
                {item.product}
              </p>
              {item.summary && (
                <ScrollArea className='max-h-[41px] mb-2'>
                  <p className='flex-1 text-xs text-left text-pale-foreground [&_a]:text-primary [&_a]:underline' dangerouslySetInnerHTML={{
                    __html: b64decode(item.summary)
                  }} />
                </ScrollArea>
              )}
              <div className='flex items-end justify-between flex-1 gap-1 mb-1'>
                <p className='text-lg font-bold text-card-foreground'>{formatCentsPriceForDisplay(parseInt(item.price_in_cents, 10))}</p>
                {
                  item.flags && (
                    <Flags flags={item.flags} />
                  )
                }
              </div>

              {
                showAvailableDays && availableDays.length > 0 && !shop.currentFilters.availableOnly && (
                  <div className='mt-3 mb-3'>
                    <AvailableDays
                      weekDaysOnly={!availableServiceDays.includes(6) && !availableServiceDays.includes(7)}
                      availableDays={availableDays}
                    />
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <AddToCartButton
          disabled={addToCartDisabled}
          {...props}
          studentsWithProducts={studentsWithProducts}
          onPurchase={handlePurchase('Service')}
          onRemove={handleRemove('Service')}
        />
      </div>
    </>
  )
})
const ScrollPoint = observer(({ category }: { category: Mcat }) => {
  const { shop } = useStore();
  const scrollToRef = React.useRef<HTMLParagraphElement>(null);
  const entry = useIntersectionObserver(scrollToRef, {});
  const isVisible = !!entry?.isIntersecting;

  React.useEffect(() => {
    if (shop.scrollToCategoryId === category.id) {
      scrollToRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'end' });
      shop.setScrollToCategoryId('');
    }
  }, [category.id, shop.scrollToCategoryId]);

  React.useEffect(() => {
    if (isVisible && category.id) {
      shop.setScrollToActiveId(category.id);
    }
  }, [category.id, isVisible]);

  return (
    <div ref={scrollToRef} />
  )
})

type MenuCategoryProps = {
  category: Mcat;
  isLoading: boolean;
  items: Product[];
  withDietaryRequirements?: boolean;
} & RouteProps;
const MenuCategory = observer((props: MenuCategoryProps) => {
  const { category, isLoading, items, withDietaryRequirements } = props;
  const { data: familyData } = api.family.useFamily();
  const { brandName, serviceId } = useParams();
  const { family: { currentSchool }, shop } = useStore();
  const { data: productInfoData, isLoading: isLoadingProductInfo } = api.order.useProductInformation({
    service_id: serviceId ?? '',
    school_id: currentSchool,
  });

  const isCommunityShop = shop.isCommunityShop;
  const pathifiedSchoolId = pathify(currentSchool)
  const { data: schoolData } = api.misc.useSchoolData(pathifiedSchoolId);

  const isEzlunchService = brandName === 'ezlunch';

  const {
    schoolService,
    currentService,
  } = useSchoolServices({
    schoolId: currentSchool,
    serviceId,
  })
  const studentsAtCurrentSchool = isCommunityShop ? [
    {
      member_id: null,
      member_type: "family",
      student_id: "FAMILY",
      room: null,
      address: null,
      first_name: "Guest",
    }
  ] : familyData?.students.filter((s) => s.member_type !== 1 && (s.school !== null ? s.school === currentSchool : s)) ?? [];
  const availableDates = orderUtils.getAvailableDates(currentService);

  const isServiceUndated = (currentService && orderUtils.isServiceUndated(currentService)) ?? false;
  const isServiceClosed = availableDates.length === 0;

  const availableServiceDays = React.useMemo(() => {
    if (!currentService) {
      return [];
    }
    return Object.keys(currentService.schedule.delivery_days).reduce((acc, day) => {
      if (currentService.schedule?.delivery_days[day]) {
        return [
          ...acc,
          parseInt(day, 10)
        ]
      }
      return acc;
    }, []);
  }, [currentService]);
  const showAvailableDays = React.useMemo(() => {
    return _.some(items, (item) => (item.avail_days || []).length > 0) || availableServiceDays.length < 7;
  }, [items, availableServiceDays]);

  const filteredItems = React.useMemo(() => {
    if (items && items.length > 0) {
      const { minPriceInCents, maxPriceInCents, dietaryRequirements, searchText, inStockOnly, availableOnly } = shop.currentFilters;

      return items.filter((item) => {
        let result = true;
        // Filter by min price
        result = result && (minPriceInCents > 0 ? parseInt(item.price_in_cents, 10) >= minPriceInCents : true);
        // Filter by max price
        result = result && (maxPriceInCents > 0 ? parseInt(item.price_in_cents, 10) <= maxPriceInCents : true);

        // Filter by dietary requirements
        result = result && (dietaryRequirements.length > 0 && withDietaryRequirements ? dietaryRequirements.every((flag) => (item.flags || '').split(' ').includes(flag)) : true);

        // Filter by in stock
        result = result && (inStockOnly && _.has(productInfoData?.product_stock, item.permanent_id) ? (productInfoData?.product_stock[item.permanent_id] ?? 0) > 0 : true);

        // Filter by available only
        result = result && (availableOnly && !isServiceUndated && shop.selectedOrderDates.length > 0 && (item.avail_days || []).length > 0 ? orderUtils.isAvailableForDeliveryForAllDates(item.avail_days, shop.selectedOrderDates) : true);
        // Filter by search text
        result = result && ((searchText || '').length > 0 ? item.product.toLowerCase().indexOf((searchText || '').toLowerCase()) > -1 : true);
        return result;
      });
    }
    return [];
  }, [items, shop.selectedOrderDates, shop.currentFilters.searchText, shop.currentFilters.minPriceInCents, shop.currentFilters.maxPriceInCents, shop.currentFilters.dietaryRequirements, shop.currentFilters.availableOnly, shop.currentFilters.inStockOnly, brandName, isServiceUndated]);

  React.useEffect(() => {
    if (!isLoading) {
      shop.menuCategoryInfos.set(category.id, {
        name: category.name,
        count: items.length ?? 0,
        filteredCount: filteredItems.length,
      });
    }
  }, [category.id, category.name, items.length, filteredItems, isLoading, shop.menuCategoryInfos])

  const orderedItems = React.useMemo(() => {
    if (filteredItems && filteredItems.length > 0) {
      const order = shop.orderBy?.split(':')[0] ?? '';
      const direction = shop.orderBy?.split(':')[1] ?? '';
      if (order === 'default' || !order) {
        return filteredItems;
      };
      return filteredItems.slice().sort((a, b) => {
        if (order === 'price') {
          if (direction === 'desc')
            return parseInt(b.price_in_cents, 10) > parseInt(a.price_in_cents, 10) ? 1 : -1;
          else
            return parseInt(a.price_in_cents, 10) > parseInt(b.price_in_cents, 10) ? 1 : -1;
        }
        if (order === 'name') {
          if (direction === 'desc')
            return b.product.localeCompare(a.product, 'en', { sensitivity: 'base' })
          else
            return a.product.localeCompare(b.product, 'en', { sensitivity: 'base' })
        }
        return 0;
      });
    }
    return [];
  }, [filteredItems, shop.orderBy]);

  if (!isLoading && (filteredItems.length === 0 || items.length === 0)) {
    return null;
  }
  return (
    <div key={category.id} className='pt-2 md:pt-6'>
      {
        filteredItems.length > 0 && (
          <p
            className='mb-2 px-2 sm:px-0 sm:mb-4 md:mb-6 lg:h-[34px] items-start flex gap-4'
          >
            <span className='flex-1 text-lg text-primary font-museo sm:flex-none'>
              {category.name}
            </span>
            <span className='pt-1 text-sm text-text-body'>
              {`${filteredItems.length} items`}
            </span>
          </p>
        )
      }
      {
        !isLoading && (
          <ScrollPoint category={category} />
        )
      }
      <div className='grid grid-cols-1 gap-4 tablet:grid-cols-menu-2 lg:grid-cols-menu-3 2xl:grid-cols-menu-4 3xl:grid-cols-menu-5 4xl:grid-cols-menu-6 5xl:grid-cols-menu-7 6xl:grid-cols-menu-7 7xl:grid-cols-menu-8 8xl:grid-cols-menu-9'>
        {
          (orderedItems || []).map((item) => (
            <MenuCategoryItem
              currentService={currentService}
              schoolService={schoolService}
              availableServiceDays={availableServiceDays}
              students={studentsAtCurrentSchool}
              key={item.permanent_id || item.productId}
              category={category}
              item={item}
              fallbackSrcs={[
                ...(isEzlunchService ? [] : [
                  `${getSupplierImageSrc({ supplierId: category.supplier_id ?? '' })}`,
                  `${getServiceImageDirectory({ schoolPath: schoolService?.path ?? '', serviceId: currentService?.id ?? '' })}${currentService?.icon}`,
                  getSchoolLogo({ schoolPath: schoolService?.path ?? '' }),
                  kindoLogoUrl,
                ])
              ]}
              isEzlunchService={isEzlunchService}
              isServiceClosed={isServiceClosed}
              showAvailableDays={showAvailableDays}
              questions={productInfoData?.product_questions}
              stock={productInfoData?.product_stock[item.permanent_id]}
              selectedOrderDates={shop.selectedOrderDates}
            />
          ))
        }

      </div>
    </div>
  )
})

export default MenuCategory