import React from 'react'

import * as Sentry from '@sentry/react';
import {
  Navigate,
  createBrowserRouter
} from "react-router-dom";
import CheckoutPage from './checkout/CheckoutPage';
import ServiceShopPage from './service_shop/ServiceShopPage';
import EzlunchServices from './service_shop/EzlunchServices';
import ServiceMenu from './service_shop/ServiceMenu';
import ShopHome from './service_shop/ShopHome';
import TopUpPage from './topup/TopUpPage';
import PrepareOrdersPage from './checkout/PrepareOrdersPage';
import MyDetailsPage from './my_details/MyDetailsPage';
import RootServices from './root_services';
import CommunityShop from './service_shop/CommunityShop';
import CompletionPage from './completion/CompletionPage';
import CommunityShopHome from './service_shop/CommunityShopHome';
import ContinueLoadingPage from './completion/ContinueLoadingPage';
import MyOrdersPage from './my_orders/MyOrdersPage';
import AdminPage from './admin/AdminPage';
import AdminHomePage from './admin/Home';
import PayableStatusPage from './admin/PayableStatusPage';
import GroupsPage from './admin/GroupsPage';
import PayableApplyPage from './admin/PayableApplyPage';
import PayableEditPage from './admin/PayableEditPage';
import PayableMaintenancePage from './admin/MaintenancePage';
import ProtoPayableListPage from './admin/PayableListPage';
import PayableRollOverPage from './admin/PayableRollOverPage';
import PayableFormPage from './admin/PayableFormPage';
import PosPage from './admin/PosPage';
import TaxaccEditorPage from './taxacc_editor/TaxaccEditorPage';
import OnlineEftposPage from './topup/OnlineEftposPage';
import PendingOrdersPage from './my_orders/PendingOrdersPage';
import CalendarPage from './calendar/CalendarPage';
import OrderStatusPage from './order_status/OrderStatusPage';
import TopUpLoadingPage from './topup/TopUpLoadingPage';
import LoginPage from './login/LoginPage';
import LoginPageRedirect from './login/LoginPageRedirect';
import ResetPasswordPage from './reset_password/ResetPasswordPage';
import ProfferPage from './proffer/ProfferPage';
import StudentPage from './my_details/StudentPage';
import ConnectPage from './connect/ConnectPage';
import SupplierRegistrationForm from './registration/SupplierRegistrationForm';
import { PayableRollOverTable } from './admin/PayableRollOverPage/PayableRollOverTable';
import { RuleRollOverTable } from './admin/PayableRollOverPage/RuleRollOverTable';

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const RootRoutes = sentryCreateBrowserRouter([
  {
    path: '/app/*',
    element: <RootServices />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'shop/*',
        element: <ServiceShopPage />,
        children: [
          {
            index: true,
            element: <ShopHome />
          },
          {
            path: ':brandName',
            element: <EzlunchServices />,
          },
          {
            path: ':brandName/:serviceId',
            element: <ServiceMenu />,
          }
        ]
      },
      {
        path: 'community-shop/:schoolId',
        element: <CommunityShop />,
        children: [
          {
            index: true,
            element: <CommunityShopHome />
          },
          {
            path: ':brandName/:serviceId',
            element: <ServiceMenu />,
          }
        ]
      },
      {
        path: 'checkout',
        element: <CheckoutPage />,
      },
      {
        path: 'prepare-orders',
        element: <PrepareOrdersPage />,
      },
      {
        path: 'wallet',
        element: <TopUpPage />,
      },
      {
        path: 'my-details',
        element: <MyDetailsPage />,
      },
      {
        path: 'my-details/student/:studentId',
        element: <StudentPage />,
      },
      {
        path: 'tax-rebate-agent',
        element: <TaxaccEditorPage />,
      },
      {
        path: 'completion',
        element: <CompletionPage />,
      },
      {
        path: 'completion-loading',
        element: <ContinueLoadingPage />,
      },
      {
        path: 'my-orders',
        element: <MyOrdersPage />,
      },
      {
        path: 'pending-orders',
        element: <PendingOrdersPage />,
      },
      {
        path: 'calendar',
        element: <CalendarPage />,
      },
      {
        path: 'order-status',
        element: <OrderStatusPage />,
      },
      {
        path: 'connect',
        element: <ConnectPage />,
      },
      {
        path: 'admin/*',
        element: <AdminPage />,
        children: [
          {
            index: true,
            element: <AdminHomePage />
          },
          {
            path: 'payable-status',
            element: <PayableStatusPage />
          },
          {
            path: 'payable-apply',
            element: <PayableApplyPage />
          },
          {
            path: 'payable-apply/:protoPayableId',
            element: <PayableEditPage />
          },
          {
            path: 'payable-maintenance',
            element: <PayableMaintenancePage />
          },
          {
            path: 'groups',
            element: <GroupsPage />
          },
          {
            path: 'point-of-sale',
            element: <PosPage />
          },
          {
            path: 'payables',
            element: <ProtoPayableListPage />
          },
          {
            path: 'payables/create',
            element: <PayableFormPage />
          },
          {
            path: 'payables/:permanentId',
            element: <PayableFormPage />
          },
          {
            path: 'rollover',
            element: <PayableRollOverPage />,
            children: [
              {
                path: 'payables',
                element: <PayableRollOverTable />
              },

              {
                path: 'rules',
                element: <RuleRollOverTable />
              },

            ]
          },
        ]
      },
      {
        path: 'online-eftpos/:topupId?/:paymentId?',
        element: <OnlineEftposPage />,
      },
      {
        path: 'topup-loading/:topupUrl?',
        element: <TopUpLoadingPage />,
      },
      {
        path: '*',
        element: <Navigate to="/app/shop" />,
      },
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        path: 'login-loading',
        element: <LoginPageRedirect />,
      },
      {
        path: 'reset-password',
        element: <ResetPasswordPage />,
      },
      {
        path: 'proffer/:profferId',
        element: <ProfferPage />,
      },
      {
        path: 'registration/supplier',
        element: <SupplierRegistrationForm />,
      },
    ]
  },
  {
    path: '*',
    element: <Navigate to="/app/shop" />,
  },
])


export default RootRoutes;