import React from 'react';
import { ColumnDef } from "@tanstack/react-table"
import { Checkbox } from '../../../common_component/base/checkbox';
import { MdArrowUpward } from 'react-icons/md';
import { Button } from '../../../common_component/base/button';
import { cn } from '../../../util/tailwind';
import { AdminPayable } from 'networking/models/admin';
import InfoTooltip from 'components/tooltip/InfoTooltip';
import { tryConvertDollarStringToCents, formatCentsPriceForDisplay } from 'util/string_util';
// This type is used to define the shape of our data.
// You can use a Zod schema here if you want.
export type RowItem = {
  id: string;
  name: string;
  firstName: string;
  lastName: string;
  protoPayableId?: string;
  room: string;
  item: string;
  applied: string;
  requested: string;
  rule: string;
  paid: string;
  unpaid: string;
  payable: AdminPayable;
  hidden: string;
  exempt: string;
  studentId: string;
  payableType: string;
}

const SortableHeader = ({ column, title, infoTooltip }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className='px-0 text-card-foreground'
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
      {
        infoTooltip && (
          <InfoTooltip
            side="bottom"
            className='pl-1'
            messages={[
              <p className='font-sans text-left'>
                {infoTooltip}
              </p>
            ]}
          />
        )
      }
    </Button>
  )
};

const calculateSumAndPercentage = (table, key) => {
  const paidSum = _.sum(table.getFilteredRowModel().flatRows.map(row =>
    tryConvertDollarStringToCents(row.original.paid.toString())
  ));
  const unpaidSum = _.sum(table.getFilteredRowModel().flatRows.map(row =>
    tryConvertDollarStringToCents(row.original.unpaid.toString())
  ));
  const totalSum = paidSum + unpaidSum;
  const keySum = key === "paid" ? paidSum : unpaidSum;
  const percentage = totalSum > 0 ? (keySum / totalSum) * 100 : 0;

  return { keySum, percentage };
};

export const columns: ColumnDef<RowItem>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        indeterminate={!table.getIsAllRowsSelected()}
        checked={table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()}
        onCheckedChange={(value) => {
          if (value === 'indeterminate') {
            return;
          }
          table.toggleAllRowsSelected(value)
        }}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={row.getToggleSelectedHandler()}
        aria-label="Select row"
      />
    ),
    maxSize: 48,
    size: 48,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "id",
    header: ({ column }) => (
      <SortableHeader column={column} title="id" />
    ),
    cell: ({ cell }) => (
      <p className='max-w-[37px] overflow-hidden'>{cell.getValue()}</p>
    ),
    maxSize: 50,
    size: 50,
    enableHiding: false,
  },
  {
    accessorKey: "protoPayableId",
    header: ({ column }) => (
      <SortableHeader column={column} title="Proto" />
    ),
    cell: ({ cell }) => (
      <p className='max-w-[37px] overflow-hidden'>{cell.getValue()}</p>
    ),
    maxSize: 50,
    size: 50,
    enableHiding: false,
  },
  {
    accessorKey: "firstName",
    header: ({ column }) => (
      <SortableHeader column={column} title="First Name" />
    ),
    enableHiding: false,
  },
  {
    accessorKey: "lastName",
    header: ({ column }) => (
      <SortableHeader column={column} title="Last Name" />
    ),
    enableHiding: false,
    cell: ({ cell }) => (
      <p className='whitespace-nowrap'>
        {cell.getValue()}
      </p>
    ),
  },
  {
    accessorKey: "room",
    header: ({ column }) => (
      <SortableHeader column={column} title="Room" />
    ),
  },
  {
    accessorKey: "item",
    header: ({ column }) => (
      <SortableHeader column={column} title="Item" />
    ),
    cell: ({ cell }) => (
      <p className='whitespace-nowrap'>
        {cell.getValue()}
      </p>
    ),
    enableHiding: false,
  },
  {
    accessorKey: "applied",
    header: ({ column }) => (
      <SortableHeader column={column} title="Applied" />
    ),
    sortingFn: 'dateSorting',
  },
  {
    accessorKey: "requested",
    header: ({ column }) => (
      <SortableHeader column={column} title="Requested" infoTooltip={'Date a payment request email was last sent which included this payable.'} />
    ),
    sortingFn: 'dateSorting',
  },
  {
    accessorKey: "rule",
    header: ({ column }) => (
      <SortableHeader column={column} title="Rule" infoTooltip="A rule is created when a payable is applied to a room, group, year, or the entire school. (Not when applied to individual students.)" />
    ),
  },
  {
    accessorKey: "hidden",
    header: ({ column }) => (
      <SortableHeader column={column} title="Hidden" />
    ),
    enableHiding: false,
  },
  {
    accessorKey: "exempt",
    header: ({ column }) => (
      <SortableHeader column={column} title="Exempt" />
    ),
    enableHiding: false,
  },
  {
    accessorKey: "paid",
    header: ({ column, table }) => {
      const { keySum, percentage } = calculateSumAndPercentage(table, "paid");

      return (
        <SortableHeader column={column}
          title={
            <div className='text-left'>
              <p>Paid ({percentage.toFixed(2)}%)</p>
              <p>{formatCentsPriceForDisplay(keySum)}</p>
            </div>
          }
        />
      );
    },
    enableHiding: false,
  },
  {
    accessorKey: "unpaid",
    header: ({ column, table }) => {
      const { keySum, percentage } = calculateSumAndPercentage(table, "unpaid");

      return (
        <SortableHeader column={column}
          title={
            <div className='text-left'>
              <p>Unpaid ({percentage.toFixed(2)}%)</p>
              <p>{formatCentsPriceForDisplay(keySum)}</p>
            </div>
          }
        />
      );
    },
    enableHiding: false,
  },
  {
    accessorKey: "comments",
    header: ({ column }) => (
      <SortableHeader column={column} title="Comments" />
    ),
  },
]
