import React from 'react'
import { Button } from '../../base/button';
import { formatCentsPriceForDisplay } from '../../../util/string_util';
import { MdAdd, MdRemove } from 'react-icons/md';
import api from 'networking/api';
import _ from 'lodash';
import { getSchoolLogo, getServiceImageDirectory, kindoLogoUrl, productImageDirectory } from 'networking/constants';
import { format } from 'date-fns';
import { useStore } from '../../../store/store';
import Image from '../../../common_component/Image';
import { observer } from 'mobx-react';
import { ScrollArea } from '../../base/scroll-area';
import KindoWell from '../../../common_component/KindoWell';
import analytics from '../../../util/googleAnalytics';
import { useNavigate } from 'react-router-dom';

type Props = {
}
const MiniCart = observer(({ }: Props) => {
  const store = useStore();
  const { data: familyData, isLoading: isLoadingFamilyData, mutate } = api.family.useFamily();
  const { data } = api.order.useOrderItems();
  // Order
  const numberOfOrders = data?.items.length ?? 0;
  const navigate = useNavigate();

  const temporaryCart = store.shop.temporaryCart;
  const temporaryCartPriceInCents = store.shop.temporaryCartPriceInCents;

  return (
    <>
      <div>
        <p className='px-4 pt-4 mb-2 text-primary font-museo'>
          Items in your Cart
        </p>
      </div>
      <ScrollArea>
        <div className='flex flex-col gap-4 px-4 text-card-foreground max-h-[50vh]'>
          {
            _.map(_.orderBy(Object.values(temporaryCart).slice(), [(s) => +s.addedAt], ['desc']), (student) => {

              return (
                <div key={`${student.studentId}-${student.schoolId}`}>
                  <p className='mb-3 text-sm font-bold text-secondary'>
                    {student.firstName}
                  </p>
                  <div className='flex flex-col gap-3'>
                    {
                      _.map(_.orderBy(Object.values(student.deliveries).slice(), [(s) => +s.addedAt], ['desc']), (delivery) => {
                        const schoolService = familyData?.school_services.find((s) => s.school_id === student.schoolId);
                        const currentService = schoolService?.services.find((s) => s.id === delivery.serviceId);

                        return (
                          <div key={`${student.schoolId}-${delivery.serviceId}-${delivery.deliveryDate}`} className='pb-3 border-b-1 border-border'>
                            {
                              delivery.deliveryDate && (
                                <p className='mb-2 text-xs'>
                                  Delivery: {format(new Date(delivery.deliveryDate), 'PPPP')}
                                </p>
                              )
                            }
                            <div className='flex flex-col gap-2'>
                              {
                                _.map(_.orderBy(Object.values(delivery.products).slice(), ['addedAt'], ['desc']), (product) => {

                                  const choicesAndOptions = [...(product.choice ? [product.choice] : []), ...product.options ?? []].join(', ') || '';
                                  return (
                                    <div key={product.productId} className='flex w-full gap-3 pb-2 border-b-1 border-border last:border-b-0'>
                                      {
                                        !product.payable && (
                                          <Image alt=''
                                            fallbackSrcs={[
                                              ...(product.picture ? [`${productImageDirectory}/${product.supplierId}/thumbnail/${product.picture}`] : []),
                                              ...(product.isEzlunchService ? [] : [
                                                schoolService && currentService ? `${getServiceImageDirectory({ schoolPath: schoolService?.path, serviceId: currentService?.id })}${currentService?.icon}` : '',
                                                `${getSchoolLogo({ schoolPath: schoolService?.path ?? '' })}`,
                                                kindoLogoUrl,
                                              ])
                                            ]}
                                            finalFallback={
                                              <div className='flex flex-col items-center justify-center h-[60px] w-[48px] p-1 rounded-md text-secondary bg-background-light border-border-primary'>
                                                <p className='text-xs text-center'>
                                                  Image coming soon
                                                </p>
                                              </div>
                                            }
                                            className="h-fit w-[48px] object-scale-down rounded-lg max-h-[60px]"
                                          />
                                        )
                                      }

                                      <div className='flex flex-col w-full'>
                                        <p className='px-1 mb-1 text-xs font-bold'>
                                          {product.name}
                                        </p>
                                        {
                                          choicesAndOptions && (
                                            <p className='px-1 text-xs text-pale-foreground'>
                                              {choicesAndOptions}
                                            </p>
                                          )
                                        }
                                        {/* {
                                          product.questions && (
                                            <div className='flex flex-col gap-1'>
                                              {
                                                _.map(product.questions, (question, questionKey) => (
                                                  <MyKindoTippy key={questionKey} tooltipContent={question.question} placement='left'>
                                                    <p key={questionKey} className='text-xs'>
                                                      {`Answer`}: {question.answer ?? '-'}
                                                    </p>
                                                  </MyKindoTippy>
                                                ))
                                              }
                                            </div>
                                          )
                                        } */}
                                        {
                                          !product.payable ? (
                                            <div className='flex justify-end px-1 py-0.5 gap-1 items-center rounded-sm hover:bg-slate-200'>
                                              <p className='text-xs'>Quantity:</p>
                                              <div className='flex items-center gap-1'>

                                                <Button className='w-5 h-5 p-0 rounded-full sm:w-3 sm:h-3' variant="outline"

                                                  onClick={() => {
                                                    store.shop.removeFromCart({
                                                      student: student.student,
                                                      product: product.product,
                                                      schoolId: student.schoolId,
                                                      serviceId: delivery.serviceId,
                                                      deliveryDate: delivery.deliveryDate,
                                                      quantity: 1,
                                                    })
                                                  }}
                                                >
                                                  <MdRemove />
                                                </Button>
                                                <p className='text-lg sm:text-sm'>
                                                  {product.quantity}
                                                </p>
                                                <Button className='w-5 h-5 p-0 rounded-full sm:w-3 sm:h-3' variant="outline"

                                                  onClick={() => {
                                                    store.shop.addToCart({
                                                      student: student.student,
                                                      product: product.product,
                                                      schoolId: student.schoolId,
                                                      serviceId: delivery.serviceId,
                                                      deliveryDate: delivery.deliveryDate,
                                                      quantity: 1,
                                                      closing: delivery.closing,
                                                      isEzlunchService: product.isEzlunchService,
                                                      supplierId: product.supplierId,
                                                      listName: 'Cart',
                                                    });
                                                  }}
                                                >
                                                  <MdAdd />
                                                </Button>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className='flex justify-end my-1'>
                                              <Button className='h-[20px] text-xs' variant="outline"
                                                onClick={() => {
                                                  store.shop.removeFromCart({
                                                    student: student.student,
                                                    product: product.product,
                                                    schoolId: student.schoolId,
                                                    serviceId: delivery.serviceId,
                                                    deliveryDate: delivery.deliveryDate,
                                                    quantity: 1,
                                                    listName: 'Cart',
                                                  })
                                                }}
                                              >
                                                Remove
                                              </Button>
                                            </div>
                                          )
                                        }
                                        <div className='flex justify-end px-1 text-xs'>
                                          <p>
                                            {formatCentsPriceForDisplay(product.flexiPriceInCents || (product.subtotal))}
                                          </p>
                                        </div>

                                      </div>
                                    </div>
                                  )
                                })
                              }
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
              )
            })
          }
        </div>
        {
          Object.keys(temporaryCart).length === 0 && (
            <p className='text-sm italic text-center text-plain-foreground'>
              No items in your cart
            </p>
          )
        }
      </ScrollArea>

      <div className='p-4'>
        {
          numberOfOrders > 0 && (
            <div className=''>

              <KindoWell
                className='p-2 text-xs'
                messages={[`You have ${numberOfOrders} order${numberOfOrders > 1 ? 's' : ''} waiting in checkout`]}
              />
            </div>
          )
        }
        <div className='grid justify-between grid-flow-col pt-4 text-sm text-gray-900'>
          <p className='text-xs font-bold'>
            Subtotal:
          </p>
          <p className='text-xs font-bold'>
            {formatCentsPriceForDisplay(temporaryCartPriceInCents)}
          </p>
        </div>
        <Button className='w-full mt-4'
          onClick={() => {
            if (store.shop.isAccountCreationRequired || (store.shop.isCommunityShop && store.shop.isAccountCreationRequired)) {
              store.family.setShowFlowJoinDialog(true)
            } else if (store.shop.isCommunityShop && !store.shop.isAccountCreationRequired) {
              navigate('/app/prepare-orders')
            } else {
              store.shop.setShowPrecheckout(true);
            }
            mutate();
            analytics.checkoutClick({
              initiatedFrom: 'Cart',
            });
          }}
        >
          Checkout
        </Button>
      </div>
    </>

  )
})

export default MiniCart