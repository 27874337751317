import React from 'react'

type Props = {
  number: string;
  title: string;
}

const NumberedTitle = (props: Props) => {
  const { number, title } = props;
  return (
    <div className='flex flex-row items-center gap-2'>
      <div className='flex items-center justify-center rounded-full bg-primary w-[24px] h-[24px]'>
        <p className='font-bold text-primary-foreground'>{number}</p>
      </div>
      <h5 className='text-lg font-bold font-museo'>{title}</h5>
      <span className='h-[1px] flex-1 bg-border ' />
    </div>
  )
}

export default NumberedTitle