import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { Button } from "../../../common_component/base/button";
import { Transaction, TransactionStudent, MenuSupplier } from 'networking/models/family'
import { formatCentsPriceForDisplay } from 'util/string_util';
import { DetailItem } from "../../../common_component/base/detail-item";

type Props = {
  transaction: Transaction;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const ActiveTransactionDialog = ({ transaction, open, onOpenChange }: Props) => {
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>View {transaction.homo_meta === 'hybrid' && 'payment or '}{transaction.transaction_subtype} details {transaction.is_cancelled && '(cancelled)'}</DialogTitle>
        </DialogHeader>
        <div className='flex flex-col gap-4 text-sm text-card-foreground'>
          {/* Purchase Transaction */}
          {transaction.transaction_subtype === 'purchase' &&
            <div className='grid grid-cols-2'>
              <div className='flex flex-col gap-3'>
                {/* Lunchbox */}
                {transaction.lunchbox &&
                  <>
                    {transaction.students.map((student: TransactionStudent) => (
                      <div className='flex flex-col gap-3' key={transaction.transaction_id + student.member_id}>
                        <DetailItem label={student.student} value={student.room && `${student.room}, ${student.school}`} />
                        <div className="flex flex-col">
                          <p className="text-sm font-semibold">Products</p>
                          <div>
                            {student.products.map((product: string) => (
                              <p key={product}>{product}</p>
                            ))}
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className='flex gap-1'>
                      <DetailItem label="Orders" value={transaction.lunchbox} />
                    </div>
                  </>
                }
                {/* Delivery Date */}
                {transaction.delivery_date &&
                  <DetailItem label="Delivery Date" value={transaction.delivery_date} />
                }
                {transaction.total_amount_in_cents ?
                  <div className='flex flex-col gap-1'>
                    <p className='font-semibold'>Amount paid: </p>
                    <div className='flex gap-1'>
                      <p>
                        {formatCentsPriceForDisplay(transaction.total_amount_in_cents)}
                      </p>
                      {transaction.supplier_discount_total_in_cents !== 0 &&
                        <p>
                          (incl. discount {formatCentsPriceForDisplay(transaction.supplier_discount_total_in_cents)})
                        </p>
                      }
                    </div>
                  </div>
                  : undefined}
                {transaction.total_refunds_in_cents ?
                  <DetailItem label="Total refunds" value={formatCentsPriceForDisplay(transaction.total_refunds_in_cents)} />
                  : undefined}
                {/* Transaction Status */}
                {transaction.status &&
                  <div>
                    <p className='font-semibold'>Status</p>
                    <div>
                      {Object.keys(transaction.status).map((item, i) => (
                        <p key={i}>{item}({transaction.status[`${item}`][i]})</p>
                      ))}
                    </div>
                  </div>
                }
                {transaction.suppliers &&
                  <div>
                    <p className='font-semibold'>Supplier Names</p>
                    <div>
                      {transaction.suppliers.map((supplier: MenuSupplier) => (
                        <p key={supplier.id}>{supplier.name}</p>
                      ))}
                    </div>
                  </div>
                }
                {transaction.purchase_id &&
                  <DetailItem label="Purchase ID" value={transaction.purchase_id} />
                }
                {transaction.cancelled_at &&
                  <DetailItem label="Cancelled" value={transaction.cancelled_at} />
                }
                {transaction.creator &&
                  <DetailItem label="Creator" value={transaction.creator} />
                }
              </div>
            </div>
          }
          {/* Topup Transaction */}
          {transaction.transaction_subtype === 'topup' &&
            <div className="flex flex-col gap-3">
              {transaction.topped_up_amount_in_cents !== 0 &&
                <DetailItem label="Top up amount" value={formatCentsPriceForDisplay(transaction.topped_up_amount_in_cents)} />
              }
              {transaction.topup_fee_amount_in_cents !== 0 &&
                <DetailItem label="Top up fee amount" value={formatCentsPriceForDisplay(transaction.topup_fee_amount_in_cents)} />
              }
              {transaction.posted_amount_in_cents !== 0 &&
                <DetailItem label="Posted amount" value={formatCentsPriceForDisplay(transaction.posted_amount_in_cents)} />
              }
              {transaction.requested_amount_in_cents !== 0 &&
                <DetailItem label="Requested amount" value={formatCentsPriceForDisplay(transaction.requested_amount_in_cents)} />
              }
              {transaction.method_description &&
                <DetailItem label="Method" value={transaction.method_description} />
              }
              {transaction.state &&
                <DetailItem label="State" value={transaction.state} />
              }
              {transaction.response_text &&
                <DetailItem label="Response" value={transaction.response_text} />
              }
              {transaction.topup_id &&
                <DetailItem label="Top up ID" value={transaction.topup_id} />
              }
            </div>
          }

          {/* Refund Transaction */}
          {transaction.transaction_subtype === 'refund' &&
            <div className="flex flex-col gap-3">
              {transaction.amount_in_cents !== 0 &&
                <DetailItem label="Amount" value={formatCentsPriceForDisplay(transaction.amount_in_cents)} />
              }
              {transaction.supplier_id &&
                <DetailItem label="Supplier ID" value={transaction.supplier_id} />
              }
              {transaction.comment &&
                <DetailItem label="Comment" value={transaction.comment} />
              }
              {transaction.purchase_id &&
                <DetailItem label="Purchase ID" value={transaction.purchase_id} />
              }
              {transaction.refund_id &&
                <DetailItem label="Refund ID" value={transaction.refund_id} />
              }

              {transaction.refund_detail &&
                <div>
                  {Object.keys(transaction.refund_detail).map((name, i) => (
                    transaction.refund_detail[`${name}`] && transaction.refund_detail[`${name}`].map((detail: any, index: number) => (
                      <div className="flex flex-col gap-3" key={index}>
                        <DetailItem label="Member" value={name} />
                        <DetailItem label="Product" value={detail.product} />
                        <DetailItem label="Amount" value={formatCentsPriceForDisplay(detail.amount)} />
                        <DetailItem label="Stock" value={`Adjusted: ${detail.stock.adjusted}, Requested: ${detail.stock.requested}`} />
                      </div>
                    ))
                  ))}
                </div>
              }
            </div>
          }

          {/* Settlement Transaction */}
          {transaction.transaction_subtype === 'settlement' &&
            <div className="flex flex-col gap-3">
              <DetailItem label="Amount" value={formatCentsPriceForDisplay(transaction.amount_in_cents)} />
              <DetailItem label="Comments" value={transaction.comments} />
              <DetailItem label="Fee" value={transaction.fee_in_cents ? formatCentsPriceForDisplay(transaction.fee_in_cents) : 'None'} />
            </div>
          }
        </div>
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ActiveTransactionDialog