import React from 'react'
import { useForm } from "react-hook-form";
import InputField from '../../common_component/form/InputField';
import { Button } from '../../common_component/base/button'
import TextField from '../../common_component/form/TextField';
import { useToast } from '../../common_component/base/use-toast';
import FeedbackBadImage from '../../assets/images/feedback-bad.png';
import FeedbackGoodImage from '../../assets/images/feedback-good.png';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../common_component/base/dialog";
import { useStore } from '../../store/store';
import api from 'networking/api';
import { observer } from 'mobx-react';

type FormData = {
  contact: string;
  message: string;
};

const FeedbackDialog = observer(() => {
  const store = useStore();
  const { toast } = useToast();
  const { data, isLoading } = api.family.useFamilySimple();

  const { control, handleSubmit, reset, formState: { isSubmitting } } = useForm<FormData>();

  const onSubmit = async (values: FormData) => {
    api.misc.sendFeedback(`${store.family.feedbackIcon ? store.family.feedbackIcon : ''}${values.message}`, values.contact)
      .then((res) => {
        if (res.ok) {
          store.family.setShowFeedbackDialog(false);
          toast({
            title: 'Feedback sent!'
          })
        }
      })
  };

  React.useEffect(() => {
    // initialize form when dialog is opened
    if (store.family.showFeedbackDialog && !isLoading) {
      reset({
        message: '',
        contact: data?.email
      })
    }
  }, [store.family.showFeedbackDialog, isLoading])

  return (
    <Dialog open={store.family.showFeedbackDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle className='text-lg font-semibold font-museo'>
            Feedback
          </DialogTitle>
          <DialogDescription>
            <div className='flex flex-col gap-2'>
              <p>
                Thanks for taking the time to send us feedback.
              </p>
              {!store.family.feedbackIcon && (
                <p>
                  To help us improve myKindo, we'd like to know more about your visit today.
                </p>
              )}
            </div>
          </DialogDescription>
        </DialogHeader>
        {!isLoading &&
          <form
            className='flex flex-col gap-6'
            onSubmit={handleSubmit(onSubmit)}
          >
            {store.family.feedbackIcon && (
              <div className='flex flex-row items-center gap-2'>
                <p className='pt-1 text-sm text-card-foreground'>Feedback</p>
                <img
                  alt={store.family.feedbackIcon}
                  className="object-contain w-5"
                  src={store.family.feedbackIcon === '[thumbsup]' ? FeedbackGoodImage : FeedbackBadImage}
                />
              </div>
            )}
            {!store.family.feedbackIcon && (
              <div>
                <TextField
                  name="message"
                  control={control}
                  rows={4}
                  label="Message"
                  rules={{ required: store.family.feedbackIcon ? false : 'Message is required' }}
                  autoFocus
                />
              </div>
            )}
            <div>
              <InputField
                label={
                  <div className='flex flex-col gap-1'>
                    <p>Email address</p>
                  </div>
                }
                control={control}
                name="contact"
              />
              <p className='pt-1 text-sm text-text-helper'>Optional: Email address where we can contact you if we have questions regarding this feedback.</p>
            </div>
            <DialogFooter className='flex flex-col-reverse w-full gap-y-3 sm:flex-col-reverse sm:space-x-0'>
              <Button
                size="lg"
                type='button'
                variant="ghost"
                className='text-base font-semibold font-museo'
                onClick={() => store.family.setShowFeedbackDialog(false)}
              >
                Cancel
              </Button>
              <Button
                size="lg"
                disabled={isSubmitting}
                type='submit'
                className='text-base font-semibold font-museo'
              >
                Submit
              </Button>
            </DialogFooter>
          </form>
        }
      </DialogContent>
    </Dialog >
  )
});

export default FeedbackDialog