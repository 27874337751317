import ContentDialog from 'components/dialog/ContentDialog';
import InputField from 'components/form/InputField';
import api from 'networking/api';
import { FamilyDetails } from 'networking/models/family';
import React from 'react'
import { useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { getPhoneDetails } from '../../../util/util';
import SelectField from 'components/form/SelectField';
import { Label } from 'components/base/label';
import { TaintedPattern } from 'util/tainted_regexp';
import { useToast } from 'components/base/use-toast';

type FieldType = 'email' | 'name' | 'phone'
type Props = {
  open: boolean;
  onClose: () => void;
  fields: FieldType[];
}

type FormData = {
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  phoneType: string;
  landlineCode: string;
}

export const landlineCodes = [
  {
    label: '09',
    value: '09',
  },
  {
    label: '07',
    value: '07',
  },
  {
    label: '06',
    value: '06',
  },
  {
    label: '04',
    value: '04',
  }, {
    label: '03',
    value: '03',
  },
]

const phoneTypes = [
  {
    label: 'Mobile',
    value: 'mob',
  },
  {
    label: 'Landline',
    value: 'landline',
  }
]

/**
 * This component is used to edit personal information
 * @param props 
 * @returns 
 */
const PersonalInfoDialog = (props: Props) => {
  const {
    open,
    onClose,
    fields
  } = props;

  const { toast } = useToast();
  const { data, isLoading, mutate } = api.family.useMyDetails();
  const { mutate: mutateFamily } = api.family.useFamily();

  const {
    phoneNumber, phoneCode
  } = getPhoneDetails(data?.contact_telephone || '');
  const { control, handleSubmit, reset, watch } = useForm<FormData>({
    defaultValues: {
      email: data?.email || '',
      firstName: data?.contact_name || '',
      lastName: data?.family_name || '',
      phone: phoneNumber || '',
      phoneType: phoneCode,
      landlineCode: phoneCode,
    }
  });
  const currentPhoneType = watch('phoneType');
  const { trigger, error, isMutating } = useSWRMutation('/family/save-details', (key, { arg }: { arg: { family: FamilyDetails } }) => {
    try {
      return api.family.saveFamilyDetails({
        family: arg.family,
        removeHomePageOption: false //true only for the accounts that are created by txt msg but we don't create account by txt anymore
      }).then(async (response) => {
        if (response.ok) {
          mutateFamily();
          mutate();
          toast({
            title: 'Successfully updated details',
          });
          onClose();
        } else {
          throw new Error('Something went wrong, please try again');
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })
  const onSave = async (data: FormData) => {
    const family: FamilyDetails = {
      contact_name: data.firstName,
      email: data.email,
      family_name: data.lastName,
      contact_telephone: `${data.phoneType === 'mob' ? 'mob' : data.landlineCode}-${data.phone}`
    }
    await trigger({ family });
  }

  const getTitle = () => {
    if (fields.length === 1) {
      return `Edit ${fields[0]}`
    }
    return 'Edit Personal Information'
  }

  React.useEffect(() => {
    // reset form when dialog is opened
    if (open) {
      reset({
        email: data?.email || '',
        firstName: data?.contact_name || '',
        lastName: data?.family_name || '',
        phone: phoneNumber || '',
        phoneType: phoneCode ? phoneCode === 'mob' ? 'mob' : 'landline' : 'mob',
        landlineCode: phoneCode || '09',
      })
    }
  }, [data, open, reset])
  return (
    <div>
      <ContentDialog
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        open={open}
        title={getTitle()}
        content={(
          <div className='flex flex-col gap-4'>
            {
              fields.includes('email') && (
                <InputField
                  control={control}
                  label='Email'
                  placeholder='Email'
                  name='email'
                  rules={{
                    required: 'Email is required',
                    pattern: {
                      value: TaintedPattern.email,
                      message: 'Invalid email address'
                    }
                  }}
                />
              )
            }
            {
              fields.includes('name') && (
                <>
                  <InputField
                    control={control}
                    label='First Name'
                    placeholder='First Name'
                    name='firstName'
                    rules={{
                      required: 'First name is required',
                      pattern: {
                        value: TaintedPattern.firstName,
                        message: 'Invalid name'
                      }
                    }}
                  />
                  <InputField
                    control={control}
                    label='Last Name'
                    placeholder='Last Name'
                    name='lastName'
                    rules={{
                      required: 'Last name is required',
                      pattern: {
                        value: TaintedPattern.lastName,
                        message: 'Invalid name'
                      }
                    }}
                  />
                </>
              )
            }
            {
              fields.includes('phone') && (
                <>
                  <SelectField

                    control={control}
                    label="Phone number type"
                    name="phoneType"
                    items={phoneTypes}
                  />
                  {currentPhoneType === 'mob' ? (
                    <>
                      <InputField
                        control={control}
                        placeholder='Mobile number'
                        name='phone'
                        label="Mobile number"
                        rules={{
                          required: 'Mobile number is required',
                          pattern: {
                            value: TaintedPattern.telephoneNumberOnly,
                            message: 'Invalid number',
                          },
                        }}
                      />
                    </>
                  ) : (
                    <div>
                      <Label>
                        Landline number
                      </Label>
                      <div className='flex flex-row items-center gap-1'>
                        <SelectField
                          control={control}
                          name="landlineCode"
                          items={landlineCodes}
                        />
                        <InputField
                          control={control}
                          placeholder='Phone'
                          name='phone'
                          rules={{
                            required: 'Mobile number is required',
                            pattern: {
                              value: TaintedPattern.telephoneNumberOnly,
                              message: 'Invalid number',
                            },
                          }}
                        />
                      </div>
                    </div>
                  )}

                </>
              )
            }
            {
              error && (
                <p className='text-destructive'>
                  {error.message}
                </p>
              )
            }
          </div>

        )}
        primaryActionLabel='Save'
        primaryActionProps={{
          type: 'submit',
          onClick: handleSubmit(onSave),
          disabled: isLoading || isMutating,
        }}
      />
    </div>
  )
}

export default PersonalInfoDialog