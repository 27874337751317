import React from 'react'
import { cn } from '../../util/tailwind';

type Props = {
  children: React.ReactNode;
  collapsed: boolean;
}

const Collapse = (props: Props) => {
  const { children, collapsed } = props;
  const [currentCollapsed, setCurrentCollapsed] = React.useState<boolean>(false);
  const [contentHeight, setContentHeight] = React.useState<number | undefined>(undefined);
  const contentRef = React.useRef<HTMLDivElement>(null);

  const setHeight = () => {
    if (!contentRef.current) return;
    setContentHeight(contentRef.current.scrollHeight);
  }
  React.useEffect(() => {
    if (contentRef.current) {
      setHeight();
    }
  }, [contentRef, children])

  React.useEffect(() => {
    setHeight(); // set height on mount before collapsing
    setCurrentCollapsed(collapsed);
  }, []);

  React.useEffect(() => {
    if (contentHeight !== undefined) {
      setCurrentCollapsed(collapsed);
    }
  }, [collapsed, contentHeight]);
  return (
    <div
      ref={contentRef}
      style={{
        height: currentCollapsed ? 0 : contentHeight,
      }}
      className={cn('overflow-hidden', {
        'transition-all duration-400 ': contentHeight !== undefined
      })}
    >
      {children}
    </div>
  )
}

export { Collapse }