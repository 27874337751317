import React from 'react'
import { observer } from 'mobx-react';
import { useStore } from 'store/store';
import { isTablet, isMobile, isIOS, osName } from 'react-device-detect';
import { googleStore, appStore } from 'networking/constants';
import KindoAppLogo from '../../../assets/images/kindo-app-logo.png';
import { Button } from "../../base/button";
import { MdClose } from 'react-icons/md';
import analytics from 'util/googleAnalytics';
/**
 * Shows download banner for mobile and tablet device
 */
const AppDownloadBanner = observer(() => {
  const store = useStore();

  React.useEffect(() => {
    // Check if date has passed one week then set to show banner again
    if (store.shop.hideDownloadBannerUntil && Date.now() > store.shop.hideDownloadBannerUntil) {
      store.shop.setShowDownloadAppBanner(true)
    }
  }, [store.shop.hideDownloadBannerUntil])

  return (
    (isMobile || isTablet) && store.shop.showDownloadAppBanner ?
      <div className='fixed top-0 z-50 flex justify-between w-full p-4 bg-white shadow-top start-0'>
        <div className='flex items-center justify-between w-full'>
          <div className='flex items-center gap-2'>
            <Button
              className='p-0'
              size='sm'
              variant="link"
              onClick={() => {
                const oneWeekFromNow = Date.now() + 7 * 24 * 60 * 60 * 1000;

                store.shop.setShowDownloadAppBanner(false)
                store.shop.setHideDownloadAppBannerUntil(oneWeekFromNow)
                // analytics to get how many users closed the banner
                analytics.triggerClick({
                  eventName: 'close_download_app_banner'
                })
              }}
            >
              <MdClose className='text-base text-primary' />
            </Button>
            <img
              alt='kindo-app-icon'
              className="object-contain w-9"
              src={KindoAppLogo}
            />
            <div className='pl-1'>
              <p className='font-semibold text-card-foreground'>myKindo</p>
              <p className='text-xs text-text-helper'>View on the {isIOS ? 'App Store' : 'Google Play Store'}</p>
            </div>
          </div>
          <Button
            className='p-0'
            size='sm'
            variant="link"
            onClick={() => {
              window.location.href = isIOS ? appStore : googleStore
              // analytics to get how many users clicked the banner and from which os
              analytics.triggerClick({
                eventName: 'download_app_banner',
                parameters: {
                  os_name: osName,
                }
              })
            }}
          >
            View
          </Button>
        </div>
      </div >
      : null
  )
});

export default AppDownloadBanner