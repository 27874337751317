import React from 'react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../common_component/base/tooltip';
import { MdInfoOutline } from 'react-icons/md';

type Props = {
  className?: string;
  tooltip?: string | React.ReactNode;
  messages?: string[] | React.ReactNode[];
  side?: 'left' | 'right' | 'top' | 'bottom';
}

const InfoTooltip = (props: Props) => {
  const { className, messages, tooltip, side } = props;
  return (
    <TooltipProvider delayDuration={150}>
      <Tooltip>
        <TooltipTrigger type="button">
          <MdInfoOutline className={`w-5 h-5 text-text-helper ${className}`} />
        </TooltipTrigger>
        <TooltipContent side={side} className='max-w-[400px]'>
          <div className='text-card-foreground'>
            {tooltip}
          </div>
          {
            messages && messages.length > 0 && (
              <div className='flex flex-col gap-1 py-2 text-card-foreground'>
                {
                  messages.map((message, index) => (
                    <div key={index} className='text-sm'>
                      {message}
                    </div>
                  ))
                }
              </div>
            )
          }
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>

  )
}

export default InfoTooltip