import React from 'react';
import { Controller, type UseControllerProps } from "react-hook-form";
import { Calendar } from '../base/calendar';
import { Button } from '../base/button';
import { Popover, PopoverTrigger, PopoverContent } from '../base/popover';
import clsx from 'clsx';
import { format } from 'date-fns';
import { MdCalendarToday } from 'react-icons/md';
import { Label } from '../base/label';
import { DayPicker } from 'react-day-picker';

// Removing duplicate prop conflict from InputProps since UseControllerProps already has it
interface Props extends UseControllerProps<any> {
  label?: string;
  transform?: {
    input: (value?: any) => any;
    output: (value?: Date) => any;
  }
  calendarProps?: React.ComponentProps<typeof DayPicker>;
}

const DatePickerField = ({
  name,
  control,
  rules,
  transform,
  label,
  calendarProps,
  ...props
}: Props) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ fieldState: { error }, field }) => {
      const value = transform ? transform.input(field.value) : field.value;
      return (
        <div className='flex flex-col space-y-3'>
          {label && (
            <Label htmlFor={name}>
              {label}{rules?.required ? <span className="text-danger-foreground"> *</span> : ''}
            </Label>
          )}
          <Popover>
            <PopoverTrigger asChild>
              <Button
                name="select-date"
                variant={"outline"}
                type="button"
                className={clsx(
                  "w-fit justify-start text-left font-normal",
                  !value && "text-muted-foreground"
                )}
              >
                <MdCalendarToday className="w-4 h-4 mr-2" />
                {value ? format(value, "dd/MM/yyyy") : <span>Pick a date</span>}
              </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
              <Calendar
                mode="single"
                selected={value}
                onSelect={(date) => field.onChange(transform ? transform.output(date) : date)}
                {...calendarProps}
              />
            </PopoverContent>
          </Popover>
          {!!error && (
            <div className="text-sm text-danger-foreground">{error.message}</div>
          )}
        </div>
      )
    }}
  />
)

export default DatePickerField;
