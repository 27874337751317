import React from 'react'
import { Button } from '../base/button';
import { MdAdminPanelSettings, MdCalendarMonth, MdLogout, MdOutlineNotifications, MdSchool, MdShop, MdSupervisedUserCircle } from 'react-icons/md';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import api from 'networking/api';
import _ from 'lodash';
import SchoolSwitcher from './components/SchoolSwitcher';
import { formatCentsPriceForDisplay } from '../../util/string_util';
import { baseApi, baseUrl, getSchoolLogo, platformEnv } from 'networking/constants';
import { useStore } from '../../store/store'
import { cn } from '../../util/tailwind';
import { observer } from 'mobx-react';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from "../../common_component/base/navigation-menu"
import { deleteCookie, isSchoolAdmin, isTgclEmail, pathify } from '../../util/util';
import { setErrorUserDetail } from '../../error/error_reporting';
import { Skeleton } from '../../common_component/base/skeleton';
import { TopLevelNavButton, SubNavProps, NavButtonProps } from './components/NavButton';
import CheckoutButton from './components/CheckoutButton';
import HelpButton from './components/HelpButton';
import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';
import ContentLayout from './components/ContentLayout';
import { isTablet, isMobile } from 'react-device-detect';
import useSchoolServices from 'networking/hooks/useSchoolService';
import useSWRMutation from 'swr/mutation';

type Props = {
  leftItems?: React.ReactElement;
  marginTop?: boolean;
  breadcrumbItems?: { label: string; to: string; active?: boolean; }[];
  children: React.ReactElement
};

const ShopLayout = observer(({ leftItems, children, breadcrumbItems, marginTop }: Props) => {
  const navigate = useNavigate();
  const { data, isLoading } = api.family.useFamily();
  const store = useStore();
  const { currentSchool } = store.family;
  const { data: payablesData } = api.family.useFamilyPayables(currentSchool);

  const [notificationAnimationTrigger, setNotificationAnimationTrigger] = React.useState(false);
  const { data: orderData } = api.order.useOrderItems();
  const matchesShop = !!useMatch('/app/shop/*');
  const location = useLocation();
  const [schoolSwitcherOpened, setSchoolSwitcherOpened] = React.useState(false);
  const orderCount = orderData?.items.length ?? 0;

  const setMobileNavOpened = (open: boolean) => store.family.setShowMobileNav(open);
  const setShowMobileAccountMenu = (open: boolean) => store.family.setShowMobileAccountMenu(open);

  const schoolIds = _.uniq([...(data?.school_services.map((s) => s.school_id) || []), ...(data?.roles.school_suppliers.map((s) => s.school_id) || [])]);
  const hasMultipleSchools = (schoolIds.length ?? 0) > 1;

  const {
    schoolService,
    feesAndDonationsService,
    isLoadingServices,
  } = useSchoolServices({
    schoolId: currentSchool,
  })
  const schoolServicesGroupedByBrandName = _.groupBy(schoolService?.services, (service) => service.brand_name);
  const currentBalance = formatCentsPriceForDisplay(data?.balance_in_cents || 0);

  const ezlunchServices = schoolServicesGroupedByBrandName['ezlunch'] || [];
  const kindoServices = schoolServicesGroupedByBrandName['kindo'] || [];
  const hasMoreThanOneEzlunchService = ezlunchServices.length > 1;
  const firstEzlunchService = ezlunchServices[0];

  const hasPayables = (payablesData?.students ?? []).some((student) => student.products.length > 0);

  // Permissions
  const isTgclAdmin = data?.tgcl === 'admin';
  const canSeeAdminPage = isSchoolAdmin(data?.school ?? '');
  const canSeeCustomerPage = isTgclAdmin;
  const canSeeConnectPage = (data?.manager && !!data?.supplier) || isTgclAdmin;

  const canSeeOrderStatusPage = !!data?.supplier || isTgclAdmin;

  const { trigger } = useSWRMutation('/logout', async (key, { }: {}) => {
    try {
      const response = await api.family.logout();
      if (response) {
        try {
          const res = await response.json();
          if (res.results) {
            store.family.setIsAuthenticated(false);
            deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
            navigate('/app/login');
          }
        } catch (e) {
          throw new Error('Something went wrong, please try again');
        }
      }
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  React.useEffect(() => {
    if (!isLoadingServices && !isLoading) {
      // No school set but has school, then set school to first school
      if (schoolIds.length > 0 && !schoolIds.includes(currentSchool) && !store.family.previewingPartner) {
        store.family.setCurrentSchool(schoolIds[0])
      }
    }
  }, [schoolService, isLoadingServices, isLoading, currentSchool]);

  React.useEffect(() => {
    if (data?.family_id && !isLoading) {
      // Sentry set user context
      setErrorUserDetail({
        userHandle: data?.handle,
        userId: data?.family_id,
      });
    }
  }, [data, isLoading])
  React.useEffect(() => {
    // Reset animation of notification bell when user navigates to a different page
    setNotificationAnimationTrigger(false);
    setTimeout(() => {
      setNotificationAnimationTrigger(true);
    }, 10)
  }, [location.pathname])

  const shopServiceItems = [
    {
      name: 'home',
      label: 'home',
      to: '/app/shop',
      badge: hasPayables && (
        <div className={cn('p-[2px] border-2 rounded-full animate-[bouncey_1s_ease-in-out_4] text-danger-foreground border-border-danger bg-danger', {
          'animate-none': !notificationAnimationTrigger,
        })}>
          <MdOutlineNotifications className='w-4 h-4' />
        </div>
      ),
      services: [feesAndDonationsService],
      onCloseSubNav: () => {
        setMobileNavOpened(false);
      }
    },
    // add filter to show shop only services if shop_front is null or if shop_fronts includes 'core'
    ...(ezlunchServices.filter((s) => s.shop_fronts === null || (s.shop_fronts && s.shop_fronts.includes('core'))) && ezlunchServices.length > 0 ? [{
      name: 'ezlunch',
      label: "ezlunch",
      to: hasMoreThanOneEzlunchService ? '/app/shop/ezlunch' : `/app/shop/ezlunch/${firstEzlunchService.id}`,
      activePath: '/app/shop/ezlunch/*',
      services: ezlunchServices,
      onCloseSubNav: () => {
        setMobileNavOpened(false);
      }
    }] : []),
    ...(kindoServices
      .filter((s) => s?.id !== 'fees_and_donations' && (s.shop_fronts === null || (s.shop_fronts && s.shop_fronts.includes('core'))))
      .map((service) => ({
        name: service.id,
        label: service.title,
        to: `/app/shop/kindo/${service?.id}`,
        activePath: `/app/shop/kindo/${service?.id}/*`,
        services: [service],
        onCloseSubNav: () => {
          setMobileNavOpened(false);
        }
      })))
  ] as SubNavProps[];

  const topNavItems = [
    {
      name: 'home',
      label: "home",
      to: '/app/shop',
      activePath: '/app/shop/*',
      hideForMobile: true,
      hidden: matchesShop,
      subNavItems: shopServiceItems,
      onClick: () => setMobileNavOpened(false),
    },
    {
      name: 'my-details',
      label: 'my details',
      to: `/app/my-details`,
      activePath: '/app/my-details',
      onClick: () => setMobileNavOpened(false),
    },
    {
      name: 'mykindo-wallet',
      label: 'myKindo wallet',
      to: `/app/wallet`,
      activePath: '/app/wallet',
      onClick: () => setMobileNavOpened(false),
    },
    {
      name: 'my-orders',
      label: 'my orders',
      url: `${baseUrl}my_orders.shtml`,
      onClick: () => setMobileNavOpened(false),
    },
  ] as NavButtonProps[];

  const dropdownItems = [
    {
      name: 'calendar',
      label: 'calendar',
      url: `/app/calendar`,
      icon: <MdCalendarMonth className="w-4 h-4" />,
    },
    {
      name: 'order-status',
      label: 'order status',
      to: `/app/order-status`,
      icon: <MdShop className='w-4 h-4' />,
      hidden: !canSeeOrderStatusPage,
    },
    {
      name: 'admin',
      label: "admin",
      to: `/app/admin`,
      icon: <MdAdminPanelSettings className='w-4 h-4' />,
      hidden: !canSeeAdminPage,
    },
    {
      label: 'connect',
      to: `/app/connect`,
      icon: <MdSupervisedUserCircle className='w-4 h-4' />,
      hidden: !canSeeConnectPage,
    },
    {
      name: 'customer',
      label: 'customer',
      url: `${baseUrl}customer.shtml?nodivert&school=${currentSchool}`,
      icon: <MdSupervisedUserCircle className='w-4 h-4' />,
      hidden: !canSeeCustomerPage,
    }
  ].filter(({ hidden }) => !hidden) as NavButtonProps[];

  const availableBalance = (
    <div className='flex flex-col self-center gap-0.25 px-2 xl:px-6 items-end'>
      <p className='flex items-center gap-1 text-xs text-secondary'>
        <span className='hidden xl:flex'>Available</span>
        <span>Balance:</span>
      </p>
      <p className='text-xs font-bold text-primary'>{isLoading ? <Skeleton className='w-[40px] h-4' /> : currentBalance}</p>
    </div>
  )

  const schoolSwitcher = (
    <SchoolSwitcher
      currentSchool={currentSchool}
      schools={schoolIds}
      students={data?.students}
      open={schoolSwitcherOpened}
      onOpenChange={(open) => setSchoolSwitcherOpened(open)}
      onSwitchSchool={(schoolId) => {
        navigate('/app/shop');
        store.family.setCurrentSchool(schoolId);
      }}
    />
  )

  if (store.family.isRenderingFromNativeApp) {
    return (
      <ContentLayout
        children={children}
        marginTop={marginTop}
      />
    );
  }
  return (
    <div key={currentSchool}>
      {/* Mobile header */}
      <MobileHeader
        schoolSwitcher={
          hasMultipleSchools && (
            <div>
              {schoolSwitcher}
            </div>
          )
        }
        schoolPath={schoolService?.path}
        signedInAs={data?.contact_name}
        onSignOut={() => {
          trigger();
        }}
        headerContent={
          <>
            {availableBalance}
            <CheckoutButton orderCount={orderCount} />
            <HelpButton />
          </>
        }
        navItems={topNavItems.concat(dropdownItems)}
        subNavItems={shopServiceItems}
        withAccountMenu

      />
      {/* Desktop header */}
      <DesktopHeader
        leftContent={
          <div className="hidden md:grid justify-items-end md:w-[200px] xl:w-[260px] 2xl:w-[350px]">
            <div>
              {
                hasMultipleSchools && (
                  <div className='max-w-[200px] flex flex-col gap-2 items-center py-1'>
                    {schoolSwitcher}
                  </div>
                )
              }
              {
                schoolService && matchesShop && (
                  <div className='max-w-[200px] flex flex-col gap-2 items-center pb-2 pt-1'>
                    <img className='h-[64px]' key={schoolService.school_id} src={getSchoolLogo({ schoolPath: schoolService?.path })} alt="" />
                  </div>
                )
              }
            </div>
          </div>
        }
        topNavContent={
          <>
            {
              schoolService && !matchesShop && (
                <>
                  <img className='h-[36px] self-center' key={schoolService.school_id} src={getSchoolLogo({ schoolPath: schoolService?.path })} alt="" />
                </>
              )
            }
            <div className='flex-1' />
            <div data-testid="section-top-navigation" className='flex items-center gap-2 justify-self-end'>
              {availableBalance}
              {
                topNavItems
                  .filter((item) => !item.hidden)
                  .map((item) => (
                    <TopLevelNavButton key={item.to || item.url} {...item} />
                  ))
              }
              <NavigationMenu data-testid="section-menu" className='self-start'>
                <NavigationMenuList>
                  <NavigationMenuItem data-testid="button-menu">
                    <NavigationMenuTrigger className='bg-transparent pt-4 h-[42px] rounded-tl-none rounded-tr-none hover:bg-pale focus:bg-pale focus:outline-none hover:text-primary focus:text-primary data-[active]:bg-primary-light/50 data-[state=open]:bg-primary-light/50'>
                      <span className='font-museo'>
                        <span className='text-primary'>my</span>
                        <span className='text-secondary'>kindo</span>
                      </span>
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <div className='w-[160px] text-card-foreground flex flex-col gap-1 py-2'>
                        {
                          dropdownItems.map((item) => (
                            <NavigationMenuLink
                              key={item.label}
                            >
                              <Button
                                name={_.kebabCase(item.label)}
                                className='w-full h-[30px] justify-start gap-2 font-museo'
                                variant="link"
                                onClick={() => {
                                  if (item.url) {
                                    window.location.href = item.url;
                                  }
                                  if (item.to) {
                                    navigate(item.to)
                                  }
                                }}
                              >
                                {item.icon}
                                {item.label}
                              </Button>
                            </NavigationMenuLink>
                          ))
                        }
                        {
                          hasMultipleSchools && (
                            <NavigationMenuLink>
                              <Button
                                name="switch-schools"
                                className='w-full h-[30px] justify-start gap-2 font-museo hidden sm:flex lg:hidden'
                                variant="link"
                                onClick={() => {
                                  setSchoolSwitcherOpened(true)
                                }}
                              >
                                <MdSchool className='w-4 h-4' />
                                switch schools
                              </Button>
                            </NavigationMenuLink>
                          )
                        }

                        <NavigationMenuLink>
                          <Button
                            name="logout"
                            className='w-full h-[30px] justify-start gap-2 font-museo'
                            variant="link"
                            onClick={() => {
                              trigger();
                            }}
                          >
                            <MdLogout className='w-4 h-4' />
                            logout
                          </Button>
                        </NavigationMenuLink>
                      </div>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
            </div>
            <div className='self-center pl-4 mr-2'>
              <CheckoutButton orderCount={orderCount} />
            </div>
            <HelpButton />
          </>
        }
        subNavItems={matchesShop ? shopServiceItems : undefined}
      />
      <div
        className={cn('', {
          [`mt-16`]: store.shop.showDownloadAppBanner && (isTablet || isMobile),
        })}
      >
        <ContentLayout
          children={children}
          breadcrumbItems={breadcrumbItems}
          leftItems={leftItems}
          marginTop={marginTop}
        />
      </div>
    </div>
  )
})

export default ShopLayout