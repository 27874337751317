import React from 'react'
import { useMatch, useParams, Link, useNavigate } from 'react-router-dom';
import api from 'networking/api';
import _ from 'lodash';
import { getSchoolLogo, platformEnv } from 'networking/constants';
import { observer } from 'mobx-react';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport
} from "../base/navigation-menu"
import HelpButton from './components/HelpButton';
import DesktopHeader from './components/DesktopHeader';
import ContentLayout from './components/ContentLayout';
import { deleteCookie, pathify } from "../../util/util";
import orderUtils from 'networking/util/order';
import { useStore } from '../../store/store'
import CheckoutButton from './components/CheckoutButton';
import { TopLevelNavOutlineButton, SubNavProps, NavButtonProps, DropdownNavButton } from './components/NavButton';
import MobileHeader from './components/MobileHeader';
import Footer from './components/Footer'
import SingleServiceHeader from './components/SingleServiceHeader'
import useSchoolServices from 'networking/hooks/useSchoolService';

type Props = {
  leftItems?: React.ReactElement;
  marginTop?: boolean;
  breadcrumbItems?: { label: string; to: string; active?: boolean; }[];
  children: React.ReactElement
};

const CommunityShopLayout = observer(({ leftItems, children, breadcrumbItems, marginTop }: Props) => {
  const store = useStore();
  const navigate = useNavigate();

  const { data: familyData, mutate } = api.family.useFamily(null, !store.family.isAuthenticated);
  const { schoolId } = useParams();
  const pathifiedSchoolId = schoolId ? pathify(schoolId) : pathify(store.family.currentSchool);

  const matchesCommunityShop = !!useMatch(`/app/community-shop/*`);

  const { schoolService, services, isLoadingServices } = useSchoolServices({
    schoolId: schoolId || store.family.currentSchool,
    communityShopsOnly: true,
    undatedServicesOnly: true,
  })
  const { data: orderData } = api.order.useOrderItems();
  const orderCount = orderData?.items.length ?? 0;
  const currentSchool = store.family.currentSchool;
  const setMobileNavOpened = (open: boolean) => store.family.setShowMobileNav(open);

  React.useEffect(() => {
    if (schoolId) {
      store.family.setCurrentSchool(schoolId)
    }

    if (matchesCommunityShop) {
      store.shop.setIsCommunityShop(matchesCommunityShop)
    }
  }, [matchesCommunityShop, schoolId, store.family, store.shop]);

  React.useEffect(() => {
    if (store.family.isAuthenticated) {
      mutate();
    }

  }, [store.family.isAuthenticated]);

  React.useEffect(() => {
    if (!isLoadingServices && schoolId && schoolService?.school_id !== decodeURI(schoolId)) {
      navigate(`/app/community-shop/${schoolService?.school_id}`, { replace: true });
    }
  }, [isLoadingServices, schoolService?.school_id, schoolId]);

  const shopServiceItems = [
    {
      label: "Home",
      to: `/app/community-shop/${store.family.currentSchool}`,
      activePath: `/app/community-shop/${encodeURI(store.family.currentSchool)}`,
      onCloseSubNav: () => {
        setMobileNavOpened(false);
      }
    },
    ...((services || [])
      .filter((s) => s?.id !== 'fees_and_donations')
      .map((service) => ({
        label: service.title,
        to: `/app/community-shop/${store.family.currentSchool}/${service?.brand_name}/${service?.id}`,
        activePath: `/app/community-shop/${encodeURI(store.family.currentSchool)}/${service?.brand_name}/${service?.id}/*`,
        services: [service],
        onCloseSubNav: () => {
          setMobileNavOpened(false);
        }
      })))
  ] as SubNavProps[];

  const topNavItems = [
    {
      label: "Home",
      to: `/app/community-shop/${store.family.currentSchool}`,
      activePath: `/app/community-shop/${encodeURI(store.family.currentSchool)}`,
      onClick: () => setMobileNavOpened(false),
    },
  ] as NavButtonProps[];

  const accountMenuItems = [

    {
      label: 'my orders',
      to: '/app/my-orders',
      onClick: () => setMobileNavOpened(false),
    },
  ] as NavButtonProps[];

  return (
    <div className='flex flex-col justify-between h-screen' key={currentSchool}>
      {/* Mobile header */}
      <MobileHeader
        headerContent={
          <>
            <CheckoutButton orderCount={orderCount} />
            <HelpButton />
          </>
        }
        schoolPath={pathifiedSchoolId}
        navItems={accountMenuItems}
        subNavItems={shopServiceItems}
        withAccountMenu={store.family.isAuthenticated}
        signedInAs={familyData?.contact_name}
        onSignOut={() => {
          deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
          store.family.setShowMobileAccountMenu(false);
          store.family.setIsAuthenticated(false);
        }}
      />
      {/* Desktop header */}
      <DesktopHeader
        leftContent={
          <div className="hidden md:flex justify-items-start md:w-[200px] xl:w-[260px] 2xl:w-[350px]">
            <div>
              {
                schoolService && (
                  <Link to={`/app/community-shop/${store.family.currentSchool}/`}>
                    <div className='flex flex-row items-center h-full pl-10'>
                      <img className='h-[36px]' src={getSchoolLogo({ schoolPath: schoolService?.path })} alt="" />
                    </div>
                  </Link>
                )
              }
            </div>
          </div>
        }

        topNavContent={
          <>
            <div className='flex-1' />
            <div className='flex items-center self-center gap-2 justify-self-end'>
              {((services || []).length > 1) &&
                topNavItems
                  .filter((item) => !item.hidden)
                  .map((item) => (
                    <TopLevelNavOutlineButton key={item.to || item.url} {...item} />
                  ))
              }
              <NavigationMenu className='self-start'>
                <NavigationMenuList>
                  <NavigationMenuItem>
                    <NavigationMenuTrigger
                      className='bg-white border-2 border-border-card font-museo'
                      onPointerMove={(event) => event.preventDefault()}
                      onPointerLeave={(event) => event.preventDefault()}
                    >
                      <p>All categories</p>
                    </NavigationMenuTrigger>
                    <NavigationMenuContent>
                      <div className='w-[200px] text-card-foreground flex flex-col gap-1 p-2'>
                        {
                          shopServiceItems.map((item) => (
                            <NavigationMenuLink
                              key={item.label}
                            >
                              <DropdownNavButton label={item.label} to={item.to} activePath={item.activePath} activeVariant='light' />
                            </NavigationMenuLink>
                          ))
                        }
                      </div>
                    </NavigationMenuContent>
                  </NavigationMenuItem>
                </NavigationMenuList>
              </NavigationMenu>
            </div>
            <div className='self-center pl-2 mr-2'>
              <CheckoutButton orderCount={orderCount} />
            </div>
            <HelpButton />
          </>
        }
        subNavItems={undefined}
      />
      {(!isLoadingServices && services && services.length === 1 && matchesCommunityShop) &&
        <SingleServiceHeader />
      }
      <ContentLayout
        children={children}
        breadcrumbItems={breadcrumbItems}
        leftItems={leftItems}
        marginTop={marginTop}
      />
      <Footer />
    </div>
  )
})

export default CommunityShopLayout