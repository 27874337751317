import React from 'react'
import Helmet from 'react-helmet'
import ShopLayout from '../../common_component/layout/ShopLayout'
import { baseUrl } from 'networking/constants'
type Props = {}
const TaxaccEditorPage = (props: Props) => {
  return (
    <div>
      <Helmet>
        <title>Rebate Tax Agent</title>
      </Helmet>
      <ShopLayout>
        <div className='overflow-hidden rounded-md'>
          <div className='h-[90vh] overflow-auto'>
            <iframe src={`${baseUrl}taxacc_editor_content.shtml`} width="100%" height="100%" frameBorder="0"></iframe>
          </div>
        </div>
      </ShopLayout>
    </div>
  )
}

export default TaxaccEditorPage