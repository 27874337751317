import React, { useState } from 'react';
import Helmet from 'react-helmet'
import { observer } from "mobx-react";
import ShopLayout from "../../common_component/layout/ShopLayout";
import TopUp from "../topup/TopUp";
import { Button } from '../../common_component/base/button'
import api from 'networking/api';
import { formatCentsPriceForDisplay } from '../../util/string_util';
import { Skeleton } from '../../common_component/base/skeleton'
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogCancel,
  AlertDialogFooter,
} from '../../common_component/base/alert-dialog'

const TopUpPage: React.FC = observer(() => {

  const { data, isLoading } = api.family.useFamily({
    has_billing_id: true,
    card_detail: true,
    get_students: true,
    saved_order_count: false,
    last_login: false,
    school_ids: false,
    supplier_menu: false,
    supplier_order: false,
    force_get_family: true,
    family_option: false,
    school_services: false,
    server_time: false,
    path: "/FAMILY"
  });

  const [shouldSkip, setShouldSkip] = useState(true);
  const { data: familyWithdrawal, isLoading: isFamilyWithdrawalLoading } = api.family.useFamilyWithdrawal(shouldSkip)

  return (
    <div>
      <Helmet>
        <title>Wallet</title>
      </Helmet>
      <ShopLayout>
        <div>
          <div className="flex flex-col gap-0 mt-0 sm:gap-4 sm:mt-8 sm:pb-6">
            <h2 className="pb-2 text-3xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
              myKindo wallet
            </h2>
            <div className="overflow-hidden border-2 sm:rounded-3xl border-border-primary">
              <div className="p-4 bg-white border-b-2">
                <div className='flex flex-row flex-wrap items-center justify-between mb-2'>
                  <p className='text-xl font-museo'>How would you like to top up?</p>
                  <div className='flex items-center gap-4'>
                    {
                      isLoading && (
                        <Skeleton className='w-full h-6 sm:w-[200px]' />
                      )
                    }
                    {
                      (!isLoading && data) && (
                        <p className='text-sm'>Current balance: <span className='text-warning'>{formatCentsPriceForDisplay(data?.balance_in_cents)}</span></p>
                      )
                    }
                    <AlertDialog>
                      <AlertDialogTrigger asChild>
                        <Button size="xs" variant="default" onClick={() => { setShouldSkip(false) }}>Collect refunds</Button>
                      </AlertDialogTrigger>
                      {(!isFamilyWithdrawalLoading && familyWithdrawal?.email_sent) &&
                        <AlertDialogContent>
                          <AlertDialogTitle>Check your email</AlertDialogTitle>
                          <p>
                            For your security, we have sent an email to {familyWithdrawal?.email_addr}.
                            It contains a link where you can complete your withdrawal.
                          </p>
                          <AlertDialogFooter>
                            <AlertDialogCancel asChild>
                              <Button variant='default'>Done</Button>
                            </AlertDialogCancel>
                          </AlertDialogFooter>
                        </AlertDialogContent>
                      }
                    </AlertDialog>
                  </div>
                </div>
                <p className='mb-6 text-sm'>Add funds to your account, your way. No minimum amounts.</p>
                <TopUp />
              </div>
            </div>
          </div>
        </div>
      </ShopLayout >
    </div>
  )
})

export default TopUpPage;