import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from "../../../common_component/base/button";
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { useForm } from 'react-hook-form';
import InputField from '../../../common_component/form/InputField';
import { formatCentsPriceForDisplay, tryConvertDollarStringToCents } from '../../../util/string_util';

type Props = {
  familyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  mutate: () => void;
}

type FormProps = {
  amount: string
  comment: string
}

const InstantTopupDialog = ({ familyId, open, onOpenChange, mutate }: Props) => {
  const { toast } = useToast();

  const { control, handleSubmit } = useForm<FormProps>({
    defaultValues: {}
  });

  const { trigger, isMutating, error } = useSWRMutation('/customer/instant-topup', async (key, { arg }: { arg: FormProps }) => {
    try {
      const response = await api.customer.instantTopup({ targetFamilyId: familyId, comment: arg.comment, amount: tryConvertDollarStringToCents(arg.amount) });

      if (response) {
        onOpenChange(false);
        const res = await response.json();
        if (res.rtype === 'error') {
          toast({
            title: `${res.message}`,
            duration: 3000,
          });
        } else if (res.balance) {
          toast({
            title: `Topup successful! The new balance is ${formatCentsPriceForDisplay(res.balance)}.`,
            duration: 3000,
          });
          mutate()
        } else {
          toast({
            title: 'Something went wrong, please try again',
            duration: 3000,
          });
        }
      } else {
        throw new Error('Something went wrong, please try again');
      }
    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  const onSubmit = (data: FormProps) => {
    trigger(data);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Instant top up</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-3 pb-2'>
            <InputField
              label="Top up amount"
              control={control}
              name="amount"
              required
            />
            <InputField
              label="Comment"
              control={control}
              name="comment"
              required
            />
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
            <Button type="submit" disabled={isMutating}>Submit</Button>
          </DialogFooter>
        </form>
        {error && <div className="mt-2 text-sm text-destructive">{error}</div>}
      </DialogContent>
    </Dialog>
  )
}

export default InstantTopupDialog