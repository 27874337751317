import React from 'react'
import { useForm } from "react-hook-form";
import InputField from '../../common_component/form/InputField';
import { Button } from '../../common_component/base/button'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../common_component/base/dialog";
import { useStore } from '../../store/store';
import api from 'networking/api';
import { observer } from 'mobx-react';

type FormData = {
  email: string;
};

const ForgotPasswordDialog = observer(() => {
  const store = useStore();
  const [error, setError] = React.useState('');
  const { control, handleSubmit, formState: { isSubmitting } } = useForm<FormData>();

  const [resetResponse, setResetResponse] = React.useState();

  const handleReset = async (values: FormData) => {
    await api.family.resetPassword(values.email).then(function (response) {
      try {
        return response.json();
      } catch (e) {
        setError('Failed to reset password.');
      }
    }).then(data => {
      if (data && data.rtype !== 'error') {
        setResetResponse(data);
      } else {
        setError(data.message);
      }
    });
  };

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      store.family.setShowForgotPasswordDialog(open)
    }
  }

  return (
    <Dialog open={store.family.showForgotPasswordDialog} onOpenChange={(open) => handleOpenChange(open)}>
      <DialogContent hideCloseButton>
        <DialogHeader>
          <DialogTitle className='text-lg font-semibold font-museo'>
            Reset password
          </DialogTitle>
          {!resetResponse &&
            <DialogDescription>
              Please enter the email address you use to log in, and hit the Reset password button.
              We will email you a link where you can change your password.
            </DialogDescription>
          }
        </DialogHeader>
        {resetResponse ?
          <>
            <div className='flex flex-col gap-4 text-sm text-card-foreground'>
              <p>A password reset link has been mailed to <span className='font-semibold text-primary'>{resetResponse.email}</span></p>
              <p className='pt-4 font-semibold text-primary font-museo'>Can't find the email?</p>
              <p>Unfortunately, password reset mail is sometimes detected as spam. If your email has not arrived within a couple of minutes, try the following.</p>
              <div className='pl-9'>
                <ul className='list-disc'>
                  <li>Check trash, spam and clutter folders etc</li>
                  <li>Ensure that the email app and folder are where you receive mail for {resetResponse.email}</li>
                  <li>Try sending an email to donotreply_test@thegrowthcollective.co.nz and do another password reset. (You won't get a reply, but some spam filters will then allow inbound email from the address you mailed.)</li>
                  <li>
                    <Button
                      className='p-0 w-fit'
                      variant="linkUnderline"
                      onClick={() => {
                        window.open('https://support.mykindo.co.nz/', '_blank');
                      }}
                    >
                      Contact the helpdesk
                    </Button>
                  </li>
                </ul>
              </div>
            </div>

            <DialogFooter className='flex flex-col w-full sm:flex-col'>
              <Button
                type="button"
                className='text-base font-semibold font-museo'
                onClick={() => {
                  setResetResponse(undefined);
                  store.family.setShowFlowJoinDialog(false);
                  store.family.setShowForgotPasswordDialog(false);
                }}
              >
                Close
              </Button>

            </DialogFooter>
          </>
          :
          <form
            className='flex flex-col gap-6'
            onSubmit={handleSubmit(handleReset)}
          >
            <div>
              <InputField
                label={
                  <div className='flex flex-col gap-1'>
                    <p>Email address</p>
                  </div>
                }
                autoFocus
                control={control}
                rules={{
                  required: 'Email is required'
                }}
                name="email"
              />
            </div>
            {
              error &&
              <p className='text-sm font-semibold text-destructive'>
                {error}
              </p>
            }

            {!resetResponse &&
              <DialogFooter className='flex flex-col-reverse w-full gap-y-3 sm:flex-col-reverse sm:space-x-0'>
                <Button
                  size="lg"
                  variant='ghost'
                  onClick={() => store.family.setShowForgotPasswordDialog(false)}
                  className='text-base font-semibold font-museo'
                >
                  Cancel
                </Button>
                <Button
                  size="lg"
                  disabled={isSubmitting}
                  type='submit'
                  className='text-base font-semibold font-museo'
                >
                  Reset
                </Button>
              </DialogFooter>
            }
          </form>
        }
      </DialogContent>
    </Dialog>
  )
});

export default ForgotPasswordDialog