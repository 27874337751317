import React from 'react'
import { observer } from 'mobx-react';
import ContentLayout from './components/ContentLayout';
import Footer from './components/Footer'
import KindoLogo from 'assets/images/kindo-logo.png'
import Image from 'components/Image';

type Props = {
  leftItems?: React.ReactElement;
  marginTop?: boolean;
  breadcrumbItems?: { label: string; to: string; active?: boolean; }[];
  children: React.ReactElement
};

const RegistrationLayout = observer(({ leftItems, children, breadcrumbItems, marginTop }: Props) => {
  return (
    <div className='flex flex-col justify-between h-screen'>
      <div className='relative'>
        <div className='px-2 md:px-4 lg:pr-[40px] xl:pr-[150px] md:pl-4 lg:pl-[80px] xl:pl-[130px] 2xl:pl-[230px] mt-3.5 h-12.5'>
          <div className='mx-2 px-14 sm:max-w-[700px] sm:m-auto'>
            <Image
              className='max-w-[120px] relative'
              alt='kindo logo'
              src={KindoLogo}
            />
          </div>
        </div>
        <ContentLayout
          children={children}
          breadcrumbItems={breadcrumbItems}
          leftItems={leftItems}
          noMargin
        />
      </div>
      <Footer />
    </div>
  )
})

export default RegistrationLayout