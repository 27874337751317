import React from 'react'
import { useStore } from '../../store/store'
import { observer } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import order from 'networking/order';
import { format, isValid, sub } from 'date-fns';
import ShopLayout from '../../common_component/layout/ShopLayout';
import CommunityShopLayout from '../../common_component/layout/CommunityShopLayout';
import { Spinner } from '../../common_component/base/spinner';
import { useToast } from '../../common_component/base/use-toast';
import { TemporaryCartDelivery, TemporaryCartProductItem } from '../../store/ShopStore';
import _ from 'lodash';
import analytics from '../../util/googleAnalytics';
type Props = {}

const PrepareOrdersPage = observer((props: Props) => {
  const store = useStore();
  const navigate = useNavigate();
  const { mutate } = order.useOrderItems();
  const { toast } = useToast();

  const goToCheckout = () => {
    navigate('/app/checkout', { replace: true });
  }
  const isOrdersOpenedForDelivery = (delivery: TemporaryCartDelivery) => {
    const isUndated = !delivery.closing || (delivery.closing.day === 0 && delivery.closing.hour === 23 && delivery.closing.minute === 59);
    if (!isUndated && delivery.deliveryDate) {
      // Check if delivery is closed
      const closingDate = sub(new Date(delivery.deliveryDate), { days: delivery?.closing?.day || 0 });
      closingDate.setHours(delivery?.closing?.hour || 0);
      closingDate.setMinutes(delivery?.closing?.minute || 0);
      return (new Date() < closingDate);
    }
    return true;
  }
  const isValidDelivery = (delivery: TemporaryCartDelivery) => {
    // Must not be expired if dated
    // Must have at least 1 product
    let result = false;
    result = delivery.products && Object.keys(delivery.products).length > 0;

    result = result && isOrdersOpenedForDelivery(delivery);
    return result;
  }
  const createOrders = async () => {
    const allStudents = Object.values(store.shop.temporaryCart);
    const allExpiredDeliveries = allStudents.flatMap((student) => {
      return Object.values(student.deliveries)
        .filter((delivery) => !isOrdersOpenedForDelivery(delivery));
    })
    const allRequests = allStudents.flatMap((student) => {
      const allDeliveries = Object.values(student.deliveries);
      return allDeliveries
        .filter((delivery) => isValidDelivery(delivery))
        .map((delivery) => {
          const allProducts = Object.values(delivery.products);
          const deliveryDate = delivery.deliveryDate || new Date();
          const date = deliveryDate ? new Date(deliveryDate) : new Date();
          const deliveryDateFormatted = format(isValid(date) ? date : new Date(), 'yyyy-MM-dd');
          return order.addOrder({
            closing: delivery.closing ?? {
              day: 0,
              hour: 23,
              minute: 59,
            },
            rtype: 'order_detail',
            creator: store.shop.isCommunityShop ? 'community_shop.shtml' : 'q2.shtml',
            delivery_date: deliveryDateFormatted,
            school_id: student.schoolId,
            service_id: delivery.serviceId,
            per_lunchbox_fee: 0,
            order_fee: 0,
            students: [
              {
                member_id: student.memberId,
                student_id: student.studentId,
                member_type: store.shop.isCommunityShop ? student.memberType : undefined,
                room: student.room,
                address: student.address,
                firstName: student.firstName,
                lastName: student.lastName,
                products: allProducts.map((product) => {
                  return {
                    productId: product.productId,
                    permanent_id: product.permanentId,
                    quantity: product.quantity,
                    quoted_unit_price_in_cents: product.priceInCents,
                    product: product.name,
                    gst: product.gst || 'GST',
                    meta: product.payable ? 'hybrid' : 'product',
                    included_option_count: product.includedOptionCount,
                    choice: product.choice,
                    options: product.options ? product.options
                      .sort((a, b) =>
                        // We want to preserve the original order of the options
                        (product.product.opt_names ?? []).indexOf(a) - (product.product.opt_names ?? []).indexOf(b)
                      ) : undefined,
                    ...((product.options?.length ?? 0) > 0 ? { quoted_product_option_price_in_cents: product.optionPriceInCents } : {}),
                    ...(product.questions?.length ? { questions: product.questions } : {}),
                    ...(product.payable ? { payable: product.payable } : {}),
                    ...(product.flexiPriceInCents ? { flexi_price_nominated_in_cents: product.flexiPriceInCents } : {}),
                  }
                })
              }
            ]
          })
        });
    });

    const allResponses = await Promise.all(allRequests);
    const someFailed = allResponses.some((r) => !r.ok) || allExpiredDeliveries.length > 0;
    const someSuccessful = allResponses.some((response) => response.status === 200)
    // Some requests failed, loop through to see which ones
    const productsGrouped = allStudents.flatMap((student) => {
      const allDeliveries = Object.values(student.deliveries);
      return allDeliveries
        .filter((delivery) => isValidDelivery(delivery))
        .map((delivery) => {
          const allProducts = Object.values(delivery.products);
          return allProducts;
        });
    });
    const failedProducts = allResponses.reduce<string[]>((result, response, index) => {
      const products = productsGrouped[index];
      if (!response.ok) {
        return [
          ...result,
          ...products.map((p) => p.name),
        ]
      } else {
        analytics.addOrder({
          itemCount: products.length,
          listName: 'Prepare Order',
          orderPriceInCents: _.sumBy(products, 'priceInCents'),
        });
      }
      return result;
    }, []);
    const successfulProducts = allResponses.reduce<TemporaryCartProductItem[]>((result, response, index) => {
      const products = productsGrouped[index];
      if (response.ok) {
        return [
          ...result,
          ...products,
        ]
      }
      return result;
    }, []);
    if (successfulProducts.length > 0) {
      analytics.beginCheckout({
        items: successfulProducts.map((product) => ({
          productId: product.productId,
          productName: product.name,
          brandName: product.isEzlunchService ? 'ezlunch' : 'kindo',
          serviceId: product.serviceId,
          supplierId: product.supplierId,
          quantity: product.quantity,
          choice: product.choice,
          options: product.options,
          priceInCents: product.priceInCentsWithOptions,
        })),
        listName: 'Prepare Order',
      })
    }

    if (someFailed) {
      toast({
        title: someSuccessful ? 'Some products were not processed' : 'Unable to process your order',
        description: (
          <div className='flex flex-col gap-0.5'>
            {
              failedProducts.length > 0 && (
                <p className='text-xs font-semibold'>These items were unable to be processed:</p>
              )
            }
            {
              failedProducts.map((product) => (
                <p key={product} className='text-xs'>
                  &#x2022;&nbsp;{product}
                </p>
              ))
            }
            {
              allExpiredDeliveries.length > 0 && (
                <p className='text-xs font-semibold'>These items are unable to be processed as orders have closed. We have removed the items from your cart:</p>
              )
            }
            {
              allExpiredDeliveries.map((delivery) => (
                <>
                  {Object.values(delivery.products).map((product) => (
                    <p className='text-xs' key={`${delivery.cartKey}-${product.productId}`}>
                      &#x2022;&nbsp;{product.name}
                    </p>
                  ))}
                </>
              ))
            }
            <div className='pt-3 text-xs italic'>
              <p>Your cart is now cleared, please try again.</p>
              <p>We apologise for any inconvenience this has caused.</p>
            </div>
          </div>
        ),
        duration: 10000,
      });
    }
    if (someSuccessful) {
      mutate(); // Refresh the order items
      goToCheckout();
    } else {
      if (store.shop.isCommunityShop) {
        navigate(`/app/community-shop/${store.family.currentSchool}`);
      } else {
        navigate('/app/shop');
      }
    }
    store.shop.clearTempoaryCart();
  }

  React.useEffect(() => {
    if (store.shop.temporaryCartItemCount > 0) {
      createOrders();
    } else {
      goToCheckout();
    }
  }, [])

  const content = (
    <div className='flex flex-col items-center justify-center h-full gap-5 mt-12'>
      <Spinner />
      <p className='text-xl font-museo'>Preparing your order...</p>
    </div>
  )

  return (
    store.shop.isCommunityShop ?
      <CommunityShopLayout marginTop>
        {content}
      </CommunityShopLayout>
      :
      <ShopLayout marginTop>
        {content}
      </ShopLayout>
  )
})

export default PrepareOrdersPage