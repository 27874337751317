import React from 'react'
import Breadcrumbs from './Breadcrumbs';
import { cn } from '../../../util/tailwind';

type Props = {
  children: React.ReactNode;
  leftItems?: React.ReactNode;
  breadcrumbItems?: Array<{
    label: string;
    to: string;
    active?: boolean;
  }>;
  marginTop?: boolean;
}

const CompactLayout = (props: Props) => {
  const { children, leftItems, breadcrumbItems, marginTop } = props
  return (
    <div className="flex">
      <div className='hidden sm:block'>
        {leftItems}
      </div>
      <div className={cn("px-2 pt-3.5 md:px-4 m-[0_auto] max-w-[1260px] w-full", {
        'mt-[120px]': marginTop
      })}>
        {
          breadcrumbItems && breadcrumbItems.length > 0 && (
            <div>
              <Breadcrumbs
                items={breadcrumbItems}
              />
            </div>
          )
        }
        {children}
      </div>
      <div className='hidden sm:block' />
    </div>
  )
}

export default CompactLayout