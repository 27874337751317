import useSWR, { useSWRConfig } from 'swr';
import { baseApi, baseUrl } from './constants';
import { UserStatus, InviteStatus } from './models/shop';
import { KindoNetworkError } from './models/common';

const shop = {
  // Queries (starts with "use")
  useUserStatus: (email?: string) => {
    const params = new URLSearchParams({
      email: email ?? ''
    }).toString();
    const data = useSWR<UserStatus, KindoNetworkError>(email ? `${baseApi}/shop/user/email?${params}` : null);
    return data;
  },

  useShopStatus: (school: string) => {
    const data = useSWR<UserStatus, KindoNetworkError>(`${baseApi}/shop/pre/${school}/status`);
    return data;
  },

  useInvitationStatus: (email: string, school: string, sendInvite: boolean) => {
    const params = new URLSearchParams({
      email: email ?? '',
      shop_id: school ?? ''
    }).toString();
    const data = useSWR<InviteStatus, KindoNetworkError>(sendInvite && email && school ? `${baseApi}/shop/user/invitation?${params}` : null);
    return data;
  },


  // Mutations

}

export default shop;