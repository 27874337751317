import React from "react";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import _ from "lodash";
import api from "networking/api";

import { useStore } from "../../../store/store";
import { Card, CardContent, CardHeader } from "../../../common_component/base/card";
import ComboBoxField from "../../../common_component/form/ComboBoxField";
import { Button } from "../../../common_component/base/button";
import { ProtoPayableTable } from "./ProtoPayableTable";
import { useNavigate } from "react-router-dom";
import { MdArrowBack, MdSearch } from "react-icons/md";
import { isAnyAdministrator, isTgclAdmin } from "util/permissions";
import InputField from "components/form/InputField";
import { PayableStatus, PayableStatusLabels } from "networking/models/payable";

export type FilterData = {
  searchText: string;
  year: string;
  status: string;
  paymentStatus: string;
  category: string;
}

const YEARS = Array.from({ length: 8 }, (_, i) => {
  const year = (new Date().getFullYear() + 1 - i).toString();
  return { label: year, value: year };
});
const ProtoPayableListPage = observer(() => {
  const store = useStore();
  const { control, getValues, watch, reset, handleSubmit } = useForm<FilterData>({
    defaultValues: {
      searchText: '',
    },
  });
  const navigate = useNavigate();
  const [currentFilters, setCurrentFilters] = React.useState<FilterData>({ searchText: '', year: '', status: '', paymentStatus: '' });
  const currentSchool = store.admin.currentSchool;
  const { data: payableCategoriesData, isLoading: isLoadingPayableCategories } = api.admin.usePayableCategories(currentSchool);
  const { data: familyData, isLoading } = api.admin.useFamily();
  const isAnyAdmin = isAnyAdministrator(familyData);
  const isAdmin = isTgclAdmin(familyData);

  const onSubmitFilters = (data: FilterData) => {
    setCurrentFilters(data);
  }
  React.useEffect(() => {
    if (!isLoading && familyData && !isAnyAdmin) {
      // Not an admin, shouldn't be here
      navigate('/app/shop');
    }
  }, [isLoading, familyData, isAnyAdmin]);

  return (
    <div>
      <Helmet>
        <title>Payable Catalogue</title>
      </Helmet>
      <div className="flex flex-col max-w-[1140px] gap-4 m-auto">
        <div className="relative flex flex-col gap-4 pt-10">
          <div className="flex flex-row items-center justify-between px-6">

            <div className="flex flex-row items-center gap-2">
              <Button
                variant={"icon"}
                onClick={() => {
                  navigate('/app/admin');
                }}
                size="default"
              >
                <MdArrowBack className="w-6 h-6 text-primary" />
              </Button>
              <h5 className="text-3xl font-bold text-primary font-museo">
                Payable Catalogue
              </h5>
            </div>
            <Button size="sm" onClick={() => {
              navigate('/app/admin/payables/create')
            }}>
              Create payable
            </Button>
          </div>
          <Card className="rounded-2xl">
            <CardHeader className="pb-0 sm:pb-0">
              <div className="z-20 flex flex-col gap-6">
                <form onSubmit={handleSubmit(onSubmitFilters)}>
                  <div className="flex flex-row flex-wrap gap-4">
                    <div className="flex-1 basis-[140px]">
                      <InputField
                        startAdornment={<MdSearch />}
                        name="searchText"
                        label="Search"
                        control={control}
                        adornmentVariants={{
                          size: 'md',
                          variant: 'plain'
                        }}
                        placeholder="Enter payable name or GL code"
                      />
                    </div>

                    <ComboBoxField
                      label="Category"
                      name="category"
                      minWidth={150}
                      isLoading={isLoadingPayableCategories}
                      control={control}
                      placeholder="All categories"
                      options={[
                        { label: 'All categories', value: '' },
                        ...payableCategoriesData?.pcats?.map((category) => ({
                          label: category,
                          value: category,
                        })) || []
                      ]}
                    />
                    <ComboBoxField
                      label="Year"
                      name="year"
                      minWidth={150}
                      control={control}
                      placeholder="All years"
                      options={[
                        { label: 'All years', value: '' },
                        ...YEARS,
                      ]}
                    />
                    <ComboBoxField
                      label="Status"
                      name="status"
                      minWidth={150}
                      control={control}
                      placeholder="All statuses"
                      options={[
                        { label: 'All statuses', value: '' },
                        { label: PayableStatusLabels[PayableStatus.DRAFT], value: PayableStatus.DRAFT },
                        { label: PayableStatusLabels[PayableStatus.ACTIVE], value: PayableStatus.ACTIVE },
                        { label: PayableStatusLabels[PayableStatus.INACTIVE], value: PayableStatus.INACTIVE },
                      ]}
                    />
                    <ComboBoxField
                      label="Payment request"
                      name="paymentStatus"
                      minWidth={150}
                      control={control}
                      placeholder="All statuses"
                      options={[
                        { label: 'All statuses', value: '' },
                        { label: 'No requests', value: 'none' },
                        { label: 'Awaiting payment', value: 'outstanding' },
                        { label: 'Fully paid', value: 'paid' },
                      ]}
                    />
                  </div>
                  <button className="hidden" type="submit">Submit</button>
                </form>
                <div className="flex flex-row items-end justify-end gap-2">
                  {
                    Object.values(currentFilters).some((value) => value) && (
                      <Button className="self-end" variant="ghost" size="sm" onClick={() => {
                        reset();
                        setCurrentFilters({ searchText: '', year: '', status: '', paymentStatus: '' });
                      }}>
                        Clear filters
                      </Button>
                    )
                  }
                  <Button size="sm" variant="secondary" className="self-end" onClick={() => {
                    setCurrentFilters(getValues());
                  }}>
                    Search
                  </Button>
                </div>
              </div>

            </CardHeader>
            <CardContent className="z-10 pb-0 sm:px-0 sm:pb-0">
              <ProtoPayableTable
                school={currentSchool}
                filters={currentFilters}
                showIdColumn={isAdmin}
                showProtoPayableIdColumn={isAdmin}
              />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
})

export default ProtoPayableListPage;
