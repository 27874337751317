import * as React from "react"

import { cn } from "../../util/tailwind"
import { VariantProps, cva } from "class-variance-authority"
import { Label } from "./label";
import useForwardRef from "util/hooks/useFowardRef";

const inputVariants = cva(
  "flex min-h-[80px] w-full rounded-md border border-border-input focus-visible:border-primary bg-card px-3 py-2 text-sm ring-offset-background placeholder:text-disabled focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
  {
    variants: {
      border: {
        default: "",
        error: "border-destructive focus-visible:border-destructive focus-visible:ring-destructive",
      }
    },
    defaultVariants: {
    },
  }
)

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement>, VariantProps<typeof inputVariants> {
  error?: string;
  label?: string;
  helperText?: string;
  autoResize?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, border, error, label, helperText, autoResize, ...props }, ref) => {
    const [titleHeight, setTitleHeight] = React.useState(0);
    const textAreaRef = useForwardRef<HTMLTextAreaElement>(ref);
    React.useEffect(() => {
      // Set the height of the textarea to the scrollHeight
      if (textAreaRef.current !== null && autoResize) {
        setTitleHeight(textAreaRef.current.scrollHeight + 2);
      }
    }, [textAreaRef, textAreaRef.current?.value])
    return (

      <div className="flex flex-col">
        {label &&
          <Label
            htmlFor={props.name}
            className="mb-2"
          >
            {label}{props.required ? <span className="text-danger-foreground"> *</span> : ''}

            {
              helperText && (
                <span className="mb-1 ml-2 text-xs font-normal text-text-helper">
                  {helperText}
                </span>
              )
            }
          </Label>
        }

        <textarea
          style={{
            ...(autoResize ? {
              height: titleHeight && autoResize ? `${titleHeight}px` : 'auto',
              resize: 'none',
            } : {})
          }}
          data-testid={`input-${props.name}`}
          className={cn(inputVariants({ className, border: error ? 'error' : 'default' }), {
          })}
          ref={textAreaRef}
          {...props}
        />
        {error && (
          <p className="mb-1 text-sm font-semibold text-destructive">
            {error}
          </p>
        )}
      </div>
    )
  }
)
Textarea.displayName = "Textarea"

export { Textarea }
