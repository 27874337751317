import React from 'react';
import { useDropzone } from 'react-dropzone';
import { Button } from 'components/base/button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from 'components/base/dialog';
import { MdOutlineUploadFile } from 'react-icons/md';

interface FileUploadDialogProps {
  callback: (files: File[]) => Promise<boolean>;
  hideCallback: () => void;
  accept: string[];
  children: React.ReactNode;
}

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const FileUploadDialog: React.FC<FileUploadDialogProps> = ({ callback, hideCallback, accept, children }) => {
  const {
    getRootProps,
    getInputProps,
    isDragAccept,
  } = useDropzone({
    accept,
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        const success = await callback(acceptedFiles);
        if (success) {
          hideCallback();
        }
      }
    },
  });

  const style = React.useMemo(() => ({
    ...baseStyle,
    ...(isDragAccept ? { borderColor: '#00aabd' } : {}),
  }), [
    isDragAccept,
  ]);

  return (
    <Dialog open onOpenChange={hideCallback}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Upload a logo</DialogTitle>
        </DialogHeader>
        <p className='text-sm text-card-foreground'>Choose and upload a file to use as your logo.</p>
        <div
          {...getRootProps({ style })}
          className="flex flex-col items-center px-6 py-10 border rounded-lg cursor-pointer"
        >
          <input {...getInputProps()} />
          <div className='py-6 text-4xl'><MdOutlineUploadFile className='w-12 h-12' /></div>
          <p className="text-sm text-card-foreground">
            Drag and drop your image here, or <span className='underline cursor-pointer text-primary'>choose a file</span> to upload.
          </p>
          {children}
        </div>
        <DialogFooter>
          <Button type="button" onClick={hideCallback}>
            Cancel
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default FileUploadDialog;
