import api from '../api';
import { useEffect, useState } from 'react'

/**
 * A hook to poll for a deferred request and stops polling when it has results
 * @param taskId the task id to fetch
 * @returns 
 * data - the data returned from the deferred request
 * isLoading - whether the request is still polling
 * 
 * Usage:
 * const { data, isLoading, error } = useDeferredTask<DeferredTopupResponse>(taskId);
 */
const useDeferredTask = <T>(taskId?: string) => {
  const [hasResults, setHasResults] = useState(false);
  const { data: deferredData, error } = api.admin.useDeferredTask<T>(taskId, hasResults ? 0 : 5000);
  useEffect(() => {
    if (deferredData?.has_run) {
      setHasResults(true);
    }
  }, [deferredData?.has_run]);

  useEffect(() => {
    // If task id changes, reset hasResults
    if (taskId && hasResults) {
      setHasResults(false);
    }
  }, [taskId]);

  return {
    data: deferredData,
    isLoading: taskId && !hasResults,
    error,
  };
}

export default useDeferredTask;