import React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table"
import { useVirtualizer } from '@tanstack/react-virtual'
import { Button } from "../../common_component/base/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../common_component/base/table";
import { Input } from '../../common_component/base/input';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose
} from "../../common_component/base/dialog";
import { Checkbox } from '../../common_component/base/checkbox';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../common_component/base/select"
import api from 'networking/api';
import { useStore } from '../../store/store'
import { baseUrl } from 'networking/constants';
import { useToast } from '../../common_component/base/use-toast';
import { MdFilterAlt, MdSearch } from 'react-icons/md'
import useSWRMutation from 'swr/mutation'
import { OrderDetails, OrderDownload } from 'networking/models/supplier';
import { Spinner } from '../../common_component/base/spinner';
import { DateRange } from "react-day-picker"
import { format } from 'date-fns';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  isLoading?: boolean;
  toggleAllStatus: React.ReactNode,
  searchOrders: React.ReactNode,
  mcatProductDict: {},
  mutateOrderData: () => void,
  resetSelectedStatus: () => void,
  selectedStatuses: {},
  dates: DateRange | undefined
}

type Filter = {
  id: string;
  value: any;
};

type DownloadFormat = {
  label: string;
  file_format?: string;
  spec_name?: string;
  rtype?: string;
}

const DOWNLOAD_OPTIONS = [
  {
    label: 'Download Excel Workbook',
    file_format: 'XLSX',
    spec_name: 'detail'
  },
  {
    label: 'Download Simple Excel Workbook',
    file_format: 'XLSX',
    spec_name: 'simple'
  },
  {
    label: 'Download CSV File',
    file_format: 'CSV',
    spec_name: 'detail'
  },
  {
    label: 'Download Simple CSV File',
    file_format: 'CSV',
    spec_name: 'simple'
  },
  {
    label: 'Download Simple CSV File (address)',
    file_format: 'CSV',
    spec_name: 'whg'
  },
  {
    label: 'School Purchase Report',
    spec_name: 'school_purchase_report'
  },
  {
    label: 'Print to A4 labels (8 per page)',
    file_format: 'label_pdf',
    spec_name: 'print'
  },
  {
    label: 'Print per item label (33 per page, A4 pdf)',
    file_format: 'label_pdf_sop_3x11',
    spec_name: 'print'
  },
  {
    label: 'Print to A4 (1 per page)',
    rtype: 'label_pdf_1_per_page'
  },
  {
    label: 'Print to A4 (all details)',
    rtype: 'print_list_all_details',
  },
  {
    label: 'Print screen',
    spec_name: 'print_screen'
  },
  {
    label: 'Print to DYMO Label Writer',
    file_format: 'label_dymo',
    spec_name: 'print'
  },
  {
    label: 'Print to PDF Label Printer',
    file_format: 'label_pdf_named_tsc',
    spec_name: 'print'
  },
  {
    label: 'Label List',
    file_format: 'label_list',
    spec_name: 'print'
  },
  {
    label: 'Production List',
    file_format: 'production_list',
    spec_name: 'print'
  },
  {
    label: 'Production List Extended',
    file_format: 'production_list_extended',
    spec_name: 'print'
  },
]

export function OrderStatusTable<TData, TValue>({
  data,
  columns,
  isLoading,
  toggleAllStatus,
  searchOrders,
  mcatProductDict,
  mutateOrderData,
  resetSelectedStatus,
  selectedStatuses,
  dates
}: DataTableProps<TData, TValue>) {
  const [rowSelection, setRowSelection] = React.useState({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFacetedUniqueValues: getFacetedUniqueValues(),
    sortDescFirst: false,
    filterFns: {
      'multiSelect': (row, id, filterValue) => {
        const rowValue = row.original[id];
        return columnFilters.some((filter) => filter.value === rowValue);
      }
    },
    state: {
      rowSelection,
      sorting,
      globalFilter,
      columnFilters,
    },
    initialState: {
      columnVisibility: {
        'student_id': false,
        'contact_phone': false,
      }
    },
  })

  const { toast } = useToast();
  const { rows } = table.getRowModel()
  const [selectedFilters, setSelectedFilters] = React.useState<Filter[]>([]);
  const [selectedDownloadFormat, setSelectedDownloadFormat] = React.useState<DownloadFormat>();

  const store = useStore();
  const { currentPartner } = store.family;

  const { trigger: triggerSaveOrders, isMutating: isSavingOrders } = useSWRMutation(`/supplier/${currentPartner?.value}/orders`, (url, { arg }: { arg: { supplier: string, before: OrderDetails, after: OrderDetails } }) => {
    return api.supplier.saveOrders({
      supplier: arg.supplier,
      before: arg.before,
      after: arg.after
    })
  })

  const { trigger: triggerDownloadOrders, isMutating: isDownloadingOrders } = useSWRMutation(`/supplier/${currentPartner?.value}/download_orders`, (url, { arg }: { arg: { supplier: string, data: OrderDownload } }) => {
    return api.supplier.downloadOrders({
      supplier: arg.supplier,
      data: arg.data
    })
  })

  const selectedRows = table.getSelectedRowModel().flatRows?.map((row) => row.original);
  const beforeData = table.getSelectedRowModel().flatRows?.map((row) => row.original.before);

  const transformedData = selectedRows.reduce((result, obj) => {
    const { purchase_id, student_name_in_dict, index } = obj;

    if (!result[purchase_id]) {
      result[purchase_id] = {};
    }

    if (!result[purchase_id][student_name_in_dict]) {
      result[purchase_id][student_name_in_dict] = [index.toString()];
    } else {
      result[purchase_id][student_name_in_dict].push(index.toString());
    }

    return result;
  }, {});

  const dumpJsonAndOpenSupplierPage = (task: string) => {
    const filterPurObj = JSON.stringify(transformedData);
    const pageName = task === 'label_dymo' ? 'label_print' : 'supplier';
    const url = `${baseUrl}${pageName}.shtml?supplier=${currentPartner?.value}&task=${task}&filter=true`;
    const supplierPage = window.open(url, '_blank');

    let windowpage = {};
    windowpage['filter_pur_obj'] = filterPurObj;
    windowpage['_report_end_date'] = (dates?.to && format(dates.to, 'yyyy-MM-dd')) || (dates?.from && format(dates.from, 'yyyy-MM-dd'));
    windowpage['_report_start_date'] = (dates?.from && format(dates.from, 'yyyy-MM-dd')) || '';
    windowpage['_report_status_list'] = selectedStatuses;
    window._page = windowpage;

    return supplierPage;
  };

  const isFiltering = columnFilters.length > 0 || globalFilter !== '';

  const handleClearFilters = () => {
    setSelectedFilters([]);
    setColumnFilters([]);
    setGlobalFilter('');
  }

  const getHeaderColumnLabel = React.useCallback((columnId: string) => {
    switch (columnId) {
      case 'mcat':
        return 'category';
      case 'student_room':
        return 'room';
      default: return columnId;
    }
  }, [])

  React.useEffect(() => {
    // Setting selected based on filters - default will be all selected
    if (table.getFilteredRowModel().rows.length !== 0) {
      const selectedRows = table.getFilteredRowModel().rows.reduce((row, selected) => {
        row[selected.index] = true;
        return row;
      }, {});

      setRowSelection(selectedRows)
    }
  }, [table.getFilteredRowModel().rows.length])

  return (
    <>
      <div className='flex flex-wrap justify-between gap-8 py-6 sm:flex-nowrap'>
        <div className='flex-1'>
          {searchOrders}
        </div>
        <div className='flex items-start justify-end gap-4'>
          <div className='flex flex-col sm:basis-[250px] basis-[60%] '>
            <p className='pb-2'>Keyword search</p>
            <Input
              placeholder='Search'
              value={globalFilter}
              className='h-9'
              onChange={(e) => setGlobalFilter(e.target.value)}
              endAdornment={<MdSearch className="w-4 h-4" />}
            />
          </div>
        </div>
      </div>
      {data && data.length !== 0 && !isLoading &&
        <>
          <hr />
          <div className='flex flex-col'>
            <div className='flex flex-wrap items-center justify-between gap-6 py-6'>
              <div className='flex gap-6'>
                <div className='flex sm:flex-nowrap flex-wrap items-center gap-2 w-[37rem]'>
                  <p className='w-[36%] text-sm'>Download selected orders</p>
                  <Select
                    onValueChange={(selectedValue) => {
                      setSelectedDownloadFormat(selectedValue)
                    }}
                  >
                    <SelectTrigger className='w-[64%]'>
                      <SelectValue placeholder="Select download format" />
                    </SelectTrigger>
                    <SelectContent className='h-[250px]'>
                      <SelectGroup id="download">
                        {DOWNLOAD_OPTIONS.map((list) => (
                          <SelectItem key={list.label} value={list}>
                            {list.label}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                  <Button size="sm" className='font-semibold font-museo'
                    disabled={selectedRows.length === 0 || isDownloadingOrders}
                    onClick={() => {
                      if (typeof selectedDownloadFormat === 'object' && selectedDownloadFormat !== null) {
                        if (selectedDownloadFormat.spec_name === 'print') {
                          dumpJsonAndOpenSupplierPage(selectedDownloadFormat.file_format)
                        } else if (selectedDownloadFormat.spec_name === 'print_screen') {
                          window.print()
                        } else {
                          const requestData = {
                            purchase_object: transformedData,
                            rtype: selectedDownloadFormat.rtype ? selectedDownloadFormat.rtype : 'download_status_csv',
                            spec_name: selectedDownloadFormat.spec_name || '',
                          };

                          if (selectedDownloadFormat.file_format !== null && selectedDownloadFormat.file_format !== undefined) {
                            requestData.file_format = selectedDownloadFormat.file_format;
                          }
                          triggerDownloadOrders({ supplier: currentPartner!.value, data: requestData })
                            .then((res) => {
                              if (res.ok) {
                                return res.json();
                              } else {
                                toast({ title: 'Unable to retrieve selected orders.' })
                              }
                            })
                            .then((data) => {
                              if (data.filename) {
                                window.open(`${baseUrl}cache/` + data.filename, '_blank')
                              }
                            })
                        }
                      }
                    }}
                  >Download</Button>
                </div>
              </div>
              <div className='flex items-center gap-2'>

                {
                  isFiltering && (
                    <Button
                      size="sm"
                      className='p-0 mr-3 whitespace-nowrap'
                      variant="linkUnderline"
                      onClick={handleClearFilters}>
                      Clear Filters
                    </Button>
                  )
                }
                {toggleAllStatus}
                <Button
                  className='font-semibold font-museo'
                  disabled={selectedRows.length === 0 || isSavingOrders}
                  onClick={() => triggerSaveOrders({ supplier: currentPartner!.value, before: beforeData, after: selectedRows })
                    .then((res) => {
                      if (res.ok) {
                        mutateOrderData();
                        resetSelectedStatus();
                        setRowSelection({})
                        return res.json();
                      } else {
                        toast({ title: 'Unable to save changes' })
                      }
                    })
                    .then((data) => {
                      toast({
                        title: `${data.updated === 0 ? 'No records were updated.' : `Updated ${data.updated} record(s).`}`,
                        duration: 8000,
                      })
                    })}
                  size='sm'
                >
                  Save
                </Button>
              </div>
            </div>
            <div className="border-t min-h-[150px] overflow-auto text-sm">
              <div>
                <Table className='text-sm table-fixed'>
                  <TableHeader
                    className='sticky top-0 table-header-group bg-gray-50 border-b-1 border-border'>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableHead
                              key={header.id}
                              variant="light"
                              className='text-sm font-semibold font-museo'
                              style={{
                                width: header.index === (headerGroup.headers.length - 1) ? 140 : header.index === 1 ? 105 : header.index === 0 ? 40 : "auto",
                                paddingRight: header.index === (headerGroup.headers.length - 1) ? 0 : 'inherit'
                              }}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              {(header.column.id === 'member' ||
                                header.column.id === 'student_room' ||
                                header.column.id === 'mcat' ||
                                header.column.id === 'product') &&
                                header.column.getCanFilter() &&
                                <Dialog>
                                  <DialogTrigger asChild>
                                    <Button variant="outline" className='h-auto p-1 ml-1 text-xs' size='xs'><MdFilterAlt /></Button>
                                  </DialogTrigger>
                                  <DialogContent className='sm:max-w-[400px]'>
                                    <DialogHeader>
                                      <DialogTitle className='mb-4 text-lg font-semibold font-museo'>
                                        Filter by {getHeaderColumnLabel(header.column.id)}
                                      </DialogTitle>
                                    </DialogHeader>
                                    {header.column.getFacetedUniqueValues().size >= 5 && (
                                      <div className='mb-4'>
                                        <Checkbox
                                          checked={selectedFilters.filter(item => item.id === header.column.id).length === header.column.getFacetedUniqueValues().size}
                                          label={selectedFilters.filter(item => item.id === header.column.id).length === header.column.getFacetedUniqueValues().size ? 'Deselect all' : 'Select all'}
                                          name="selectAll"
                                          onCheckedChange={(isChecked) => {
                                            if (isChecked) {
                                              const allUniqueValues = Array.from(header.column.getFacetedUniqueValues().keys()).map(value => ({ id: header.column.id, value }));
                                              setSelectedFilters(prevFilters => {
                                                const newFilters = [...prevFilters.filter(item => item.id !== header.column.id), ...allUniqueValues];
                                                return newFilters;
                                              });
                                            } else {
                                              setSelectedFilters(prevFilters => {
                                                const newFilters = prevFilters.filter(item => item.id !== header.column.id);
                                                return newFilters;
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                    {header.column.id === 'product' && mcatProductDict ?
                                      Object.keys(mcatProductDict)
                                        .map((key) => (
                                          <div key={key}>
                                            <p className='mb-4'>{key}</p>

                                            {Object(mcatProductDict)[key].map((value: string) => (
                                              <div key={key} className='mb-4'>
                                                <Checkbox
                                                  checked={selectedFilters.some((filter) => filter.value === value)}
                                                  label={value}
                                                  name={value}
                                                  onCheckedChange={(isChecked) => {
                                                    if (isChecked) {
                                                      setSelectedFilters((prevFilters) => [
                                                        ...prevFilters,
                                                        { id: header.column.id, value: value },
                                                      ]);
                                                    } else {
                                                      setSelectedFilters((prevFilters) =>
                                                        prevFilters.filter((filter) => filter.value !== value)
                                                      );
                                                    }
                                                  }}
                                                />
                                              </div>
                                            ))}
                                          </div>
                                        ))
                                      :
                                      Array.from(header.column.getFacetedUniqueValues().keys()).sort().map((value) => (
                                        <div key={value}>
                                          <Checkbox
                                            checked={selectedFilters.some((filter) => filter.value === value)}
                                            defaultChecked={true}
                                            label={value}
                                            name={value}
                                            onCheckedChange={(isChecked) => {
                                              if (isChecked) {
                                                setSelectedFilters((prevFilters) => [
                                                  ...prevFilters,
                                                  { id: header.column.id, value: value },
                                                ]);
                                              } else {
                                                setSelectedFilters((prevFilters) =>
                                                  prevFilters.filter((filter) => filter.value !== value)
                                                );
                                              }
                                            }}
                                          />
                                        </div>
                                      ))
                                    }

                                    <DialogFooter>
                                      <div className='flex items-center gap-3'>
                                        <DialogClose>
                                          <Button variant="outline">Cancel</Button>
                                        </DialogClose>
                                        <DialogTrigger>
                                          <Button onClick={() => setColumnFilters(selectedFilters)}>Filter</Button>
                                        </DialogTrigger>
                                      </div>
                                    </DialogFooter>
                                  </DialogContent>
                                </Dialog>
                              }
                            </TableHead>
                          )
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody className='table-row-group'>
                    {rows.map((row, index) => {
                      return (
                        <TableRow
                          variant="stripe"
                          key={row.id}
                          className='table-row border-none'
                          data-state={row.getIsSelected() && "selected"}
                        >
                          {row.getVisibleCells().map((cell) => (
                            <TableCell className='table-cell' key={cell.id}>
                              {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </TableCell>
                          ))}
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </>
      }
      {
        isLoading && (
          <div className='flex items-center justify-center w-full py-6'>
            <Spinner size="md" />
          </div>
        )
      }
    </>
  )
}
