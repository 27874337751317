import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import { Button } from '../../common_component/base/button'
import { MdInfoOutline } from 'react-icons/md'
import api from 'networking/api'
import _ from 'lodash'
import { getServiceImageDirectory, productImageDirectory } from 'networking/constants'
import { Service } from 'networking/models/family'
import useSchoolServices from 'networking/hooks/useSchoolService'
import { useStore } from '../../store/store'
import orderUtils from 'networking/util/order'
import { format } from 'date-fns'
import { useMediaQuery } from '../../util/hooks/useMediaQuery'
import { MyKindoTippy } from '../../common_component/tooltip/MyKindoTippy'
import { Skeleton } from '../../common_component/base/skeleton'
import { cn } from '../../util/tailwind'

export const MenuCategoryPicture = ({ service, maxItems = 3 }: { service: Service; maxItems?: number }) => {
  const { data } = api.order.useFullMenu(service);
  const items = data?.items.sort((a, b) => a.promote - b.promote);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const { data: productImageData, isLoading: isLoadingProductImages } = api.order.useProductImages(service.id, items, maxItems);

  return <>
    {
      _.times(isMobile ? 2 : maxItems).map((index) => {
        const item = productImageData?.[index];
        return (
          <>
            {
              (isLoadingProductImages || !item) && (
                <Skeleton key={`skeleton-${index}`} className={cn('w-full h-[150px] sm:h-[180px] rounded-none', {
                  'rounded-tr-lg rounded-br-lg': index === (isMobile ? 2 : maxItems) - 1,
                  'rounded-tl-lg rounded-bl-lg': index === 0,
                })} />
              )
            }
            {
              item && (
                <img
                  key={item.permanent_id}
                  src={`${productImageDirectory}/${item.supplierId}/${item.picture}`}
                  className={cn("w-full h-full max-h-[150px] sm:max-h-[180px] object-cover first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg", {
                    'hidden': isLoadingProductImages
                  })}
                  alt={''}
                />
              )
            }
          </>
        )
      })
    }
  </>
}
const EzlunchServices = () => {
  const { family: { currentSchool } } = useStore();
  const { schoolService, ezlunchServices } = useSchoolServices({
    schoolId: currentSchool,
  })

  React.useEffect(() => {
    // Scroll to top of page on mount
    window.scrollTo({ top: 0 });
  }, []);
  if (!ezlunchServices) {
    return null;
  }
  return (
    <>
      <Helmet>
        <title>ezlunch</title>
      </Helmet>
      <div className="flex flex-col gap-2 sm:gap-4 sm:mt-2">
        <h2 className="text-4xl font-semibold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
          ezlunch
        </h2>
        <p className='text-card-foreground'>
          Browse our ezlunch food menu’s and place your lunch orders. Each menu offers a variety of healthy food for your children to enjoy. Each menu has their own ordering cut-off times and availability.
        </p>
        <div className='flex flex-col gap-2 mb-10 sm:gap-4 lg:gap-6 text'>
          {
            ezlunchServices.map((ezlunchService) => {
              const closingInfo = orderUtils.getClosingInfo(ezlunchService?.closing);
              const isOpenedForOrders = orderUtils.isOpenedForOrders(ezlunchService);
              const nextOpeningDate = isOpenedForOrders ? null : orderUtils.getNextOpeningDate(ezlunchService);
              return (
                <Link key={ezlunchService.id} to={ezlunchService.id}>
                  <div className='overflow-hidden rounded-xl hover:cursor-pointer hover:shadow-md'>
                    <div className='flex flex-col gap-4 px-6 py-4 bg-card text-card-foreground'>
                      <div className='flex flex-wrap items-center gap-3'>
                        <img
                          src={`${getServiceImageDirectory({ schoolPath: schoolService?.path ?? '', serviceId: ezlunchService.id })}${ezlunchService.icon}`}
                          alt=''
                          className='object-cover h-[40px]'
                        />
                        <p className='flex-1 text-lg sm:text-2xl text-primary font-museo whitespace-nowrap sm:whitespace-normal'>
                          {ezlunchService.title}
                        </p>
                        <div className='px-3 py-2 text-xs rounded-lg bg-info'>
                          {orderUtils.getAvailabilityInfo(ezlunchService)}
                        </div>
                      </div>
                      <div className='grid grid-flow-col grid-cols-2 sm:grid-cols-3'>
                        <MenuCategoryPicture service={ezlunchService} key={ezlunchService.id} />
                      </div>

                    </div>
                    <div className='flex flex-col gap-4 px-6 py-4 sm:items-center sm:justify-end sm:flex-row bg-info'>

                      <div className='mr-auto text-xs text-card-foreground'>
                        {
                          isOpenedForOrders && (
                            <div>
                              Available for ordering now
                            </div>
                          )
                        }
                        {
                          nextOpeningDate && (
                            <p>
                              {`Order from ${format(nextOpeningDate, 'PPPP')}`}
                            </p>
                          )
                        }
                      </div>
                      <p className='flex items-start justify-start gap-2 text-xs sm:items-center text-card-foreground'>
                        <MyKindoTippy tooltipContent="For order cancellations, go to my orders page.">
                          <div>
                            <MdInfoOutline className='w-4 h-4' />
                          </div>
                        </MyKindoTippy>
                        Orders or cancellations close {closingInfo}
                      </p>
                      <Button className='w-full whitespace-nowrap sm:w-auto'>
                        See full menu
                      </Button>
                    </div>
                  </div>
                </Link>
              )
            })
          }
        </div>
      </div>
    </>
  )
}

export default EzlunchServices
