import React from 'react'
import { useLocation } from 'react-router-dom';
import { IN_PRODUCTION } from "../../util/util";
import { useStore } from '../../store/store';
import api from 'networking/api';
import { observer } from 'mobx-react';

const GoogleAnalytics = observer(() => {
  const { data, isLoading } = api.family.useFamilySimple();
  const store = useStore();
  const location = useLocation();
  React.useEffect(() => {
    if (location.pathname && IN_PRODUCTION) {
      if (window.gtag) {
        // Send page view event
        window.gtag('event', 'page_view');
      }
    }
  }, [location.pathname]);

  React.useEffect(() => {
    if (!isLoading && data) {
      gtag('set', 'user_id', `${data.handle}`);
    }
  }, [isLoading]);

  React.useEffect(() => {
    if (store.family.currentSchool) {
      gtag('set', 'user_properties', {
        'school': store.family.currentSchool,
      })
    }
  }, [store.family.currentSchool]);
  return null;
});

export default GoogleAnalytics