import React from 'react'
import Helmet from 'react-helmet'
import { useForm } from "react-hook-form";
import InputField from '../../common_component/form/InputField';
import { Button } from '../../common_component/base/button'
import api from 'networking/api';
import { useNavigate } from 'react-router-dom';
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import { useSearchParams } from "react-router-dom";
import useSWRMutation from 'swr/mutation';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../common_component/base/alert-dialog';
import { TaintedPattern } from "../../util/tainted_regexp";
import { setCookie, getCookie, deleteCookie } from 'util/util';
import { platformEnv } from 'networking/constants';
import { useStore } from '../../store/store';
import { isChrome, isIOS } from 'react-device-detect';
import PasswordRequirements from 'components/PasswordRequirements';
import { Spinner } from 'components/base/spinner';

type FormData = {
  email: string;
  password: string;
};

const ResetPasswordPage = () => {
  const store = useStore();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const proffer = params.get('proffer')
  const [error, setError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const [passwordChanged, setPasswordChanged] = React.useState(false);
  const [isProfferInvalid, setIsProfferInvalid] = React.useState(false);
  const [minimumPasswordLength, setMinimumPasswordLength] = React.useState(8);
  const [sessionId, setSessionId] = React.useState('');

  const { control, handleSubmit, watch, formState: { isSubmitting } } = useForm<FormData>();
  const password = watch('password');
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword)
  };

  const handleChangePassword = async (values: FormData) => {
    trigger({
      password: values.password,
    });
  }

  const handleLogin = () => {
    if (sessionId) {
      navigate('/app/login-loading', { replace: true });
      window.location.reload();
    } else {
      navigate('/app/login')
    }
  };

  const { trigger, isMutating } = useSWRMutation('/set-password', (key, { arg }: { arg: { password: string; } }) => {
    try {
      if (!proffer) return;
      return api.family.setPassword({
        password: arg.password,
        profferId: proffer,
      })
        .then((res) => {
          if (res) {
            return res.json();
          }
        })
        .then(async (response) => {
          if (response.rtype === 'session') {
            setPasswordChanged(true)
            setSessionId(response.session_id)
            setCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`, response.session_id, 7, '/shop', '.kindo.co.nz');
            store.family.setIsAuthenticated(true);
          } else if (response.rtype === 'error') {
            setError(response.message);
          } else {
            setError('Something went wrong, please try again');
          }
        });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })

  const { trigger: triggerCheckToken, isMutating: isTriggerCheckTokenMutating } = useSWRMutation('/check-proffer-id', (key, { arg }: {
    arg: {
      token: string;
    }
  }) => {
    return api.family.checkResetPasswordToken(arg.token)
      .then(async (responseData) => {
        const data = await responseData.json().catch(() => {
          return {};
        }) as {
          name?: string;
          message?: string;
          // validation criteria: v1 or v2, v1 is 8 characters, v2 is 14 characters. The idea is to make it easier to change the password policy in the future
          validation_criteria?: 'v1' | 'v2';
        };
        if (data.name === 'SessionClosedException') {
          deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
          window.location.reload();
        }
        if (responseData.ok) {
          setIsProfferInvalid(false);
          if (data.validation_criteria === 'v2') {
            setMinimumPasswordLength(14);
          } else {
            setMinimumPasswordLength(8);
          }
        } else {
          if (data.name === 'ProfferExpiredException') {
            setError('Reset link has expired')
            setIsProfferInvalid(true);
          } else if (data.name === 'ProfferResolvedException') {
            setError('Password reset link already resolved')
            setIsProfferInvalid(true);
          } else {
            setIsProfferInvalid(true);
            setError(data.message || '')
          }
        }
      })
  });

  const sessionCookie = getCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);

  React.useEffect(() => {
    if (sessionCookie) {
      deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
    }
  }, [sessionCookie])

  React.useEffect(() => {
    if (proffer) {
      triggerCheckToken({
        token: proffer,
      });
    }
  }, []);

  React.useEffect(() => {
    if (isChrome && isIOS) {
      // set 2 seconds timeout to make time for zoho to load if using chrome on ios
      setTimeout(() => {
        window.location.href = `mykindo://auth/set-password/${proffer}`;
      }, 2000);
    } else {
      window.location.href = `mykindo://auth/set-password/${proffer}`
    }
  }, []);

  return (
    <div className='p-2 sm:p-4'>
      <Helmet>
        <title>Reset password</title>
      </Helmet>
      <div className='pt-6 pb-0 text-center sm:text-left sm:pt-0'>
        <span className='text-2xl font-museo'>
          <span className='text-primary'>my</span>
          <span className='text-secondary'>kindo</span>
        </span>
      </div>
      <div className='flex flex-col items-center justify-center pt-10'>
        {isProfferInvalid && !isTriggerCheckTokenMutating ?
          <div className='flex flex-col justify-center gap-6 px-4 sm:px-10 py-6 sm:py-10 w-full sm:w-[45%] md:w-[55%] lg:w-[32%] bg-white rounded-3xl'>
            <p className='text-xl font-semibold font-museo'>{error}</p>
            <p className='text-sm text-card-foreground'>Please request a new reset link or login.</p>
            <div className='flex flex-col gap-2'>
              <Button
                size="lg"
                type="button"
                className='font-semibold font-museo'
                onClick={() => navigate('/app/login')}
              >
                Return to login
              </Button>
            </div>
          </div>
          :
          <div className='flex flex-col justify-center gap-6 px-4 sm:px-10 py-6 sm:py-10 w-full sm:w-[45%] md:w-[55%] lg:w-[32%] bg-white rounded-3xl'>
            <p className='text-xl font-semibold font-museo'>Set your new password</p>
            {
              isTriggerCheckTokenMutating ? (
                <div className='flex items-center justify-center py-4'>
                  <Spinner />
                </div>
              ) : (
                <form
                  className='flex flex-col gap-6'
                  onSubmit={handleSubmit(handleChangePassword)}
                >
                  <div>
                    <InputField
                      label="Password"
                      control={control}
                      autoComplete='off'
                      type={showPassword ? "text" : "password"}
                      rules={{
                        required: 'Enter a password',
                        minLength: {
                          value: minimumPasswordLength,
                          message: `Password must be at least ${minimumPasswordLength} characters long`
                        },
                        validate: {
                          hasNumber: (value) => TaintedPattern.numbersRegex.test(value) || 'Password must contain at least one number',
                          hasLowercase: (value) => TaintedPattern.lowercaseRegex.test(value) || 'Password must contain at least one lowercase letter',
                          hasUppercase: (value) => TaintedPattern.uppercaseRegex.test(value) || 'Password must contain at least one uppercase letter',
                          hasSpecialCharacter: (value) => TaintedPattern.specialCharactersRegex.test(value) || 'Password must contain at least one of these !@#$%^&* special characters',
                        }
                      }}
                      endAdornment={
                        <button type="button" className="" onClick={togglePasswordVisibility}>
                          {
                            showPassword ? (
                              <MdVisibility className="w-4 h-4 text-primary" />
                            ) : (
                              <MdVisibilityOff className="w-4 h-4 text-primary" />
                            )
                          }
                        </button>
                      }
                      name="password"
                    />
                    <PasswordRequirements minLength={minimumPasswordLength} password={password} />
                  </div>

                  <div>
                    <InputField
                      label="Confirm Password"
                      control={control}
                      autoComplete='off'
                      type={showConfirmPassword ? "text" : "password"}
                      rules={{
                        required: 'This field is required',
                        validate: value => value === password || 'Passwords do not match'
                      }}
                      endAdornment={
                        <button type="button" className="" onClick={toggleConfirmPasswordVisibility}>
                          {
                            showConfirmPassword ? (
                              <MdVisibility className="w-4 h-4 text-primary" />
                            ) : (
                              <MdVisibilityOff className="w-4 h-4 text-primary" />
                            )
                          }
                        </button>
                      }
                      name="confirmPassword"
                    />
                  </div>
                  {
                    error &&
                    <p className='text-sm font-semibold text-destructive'>
                      {error}
                    </p>
                  }
                  <Button
                    disabled={isSubmitting}
                    size="lg"
                    type="submit"
                    className='font-semibold font-museo'
                  >
                    Save password
                  </Button>
                </form>
              )
            }
          </div>
        }
      </div>

      <AlertDialog open={passwordChanged}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Password has been changed!</AlertDialogTitle>
            <AlertDialogDescription>
              <p>
                {sessionId ? 'Please click continue to login.' : 'Please login to use your new password.'}
              </p>
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogAction
              onClick={handleLogin}>
              Continue
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default ResetPasswordPage
