import React from 'react'
import Breadcrumbs from './Breadcrumbs';
import { cn } from '../../../util/tailwind';

type Props = {
  children: React.ReactNode;
  leftItems?: React.ReactNode;
  breadcrumbItems?: Array<{
    label: string;
    to: string;
    active?: boolean;
  }>;
  marginTop?: boolean;
  noMargin?: boolean;
}

const ContentLayout = (props: Props) => {
  const { children, leftItems, breadcrumbItems, marginTop, noMargin } = props
  return (

    <div className="grid grid-cols-1 sm:grid-cols-[1fr_minmax(340px,3000px)]">
      <div className='hidden sm:block'>
        {leftItems}
      </div>
      <div className={cn("px-2 mt-3.5 md:px-4 lg:pr-[40px] xl:pr-[150px]", {
        'md:pl-4 lg:pl-[80px] xl:pl-[130px] 2xl:pl-[230px]': !leftItems,
        'mt-[120px]': marginTop,
        'mt-0': noMargin
      })}>
        {
          breadcrumbItems && breadcrumbItems.length > 0 && (
            <div>
              <Breadcrumbs
                items={breadcrumbItems}
              />
            </div>
          )
        }
        {children}
      </div>
    </div>
  )
}

export default ContentLayout