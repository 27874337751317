import { observable, computed, action, makeObservable } from "mobx";
import { persist } from 'mobx-persist'
export default class FamilyStore {
  constructor() {
    makeObservable(this)
  }
  @observable showNoStudentDialog: boolean = false;
  @persist @observable currentSchool: string = '';
  @persist('object') @observable currentPartner: { label: string; value: string; } | null = null;
  @persist @observable currentTab: string = '';
  @observable showLoginDialog: boolean = false;
  @observable showTermsDialog: boolean = false;
  @observable showMobileNav: boolean = false;
  @observable showFlowJoinDialog: boolean = false;
  @observable showForgotPasswordDialog: boolean = false;
  @observable showMobileAccountMenu: boolean = false;
  @persist @observable isAuthenticated: boolean = false;
  @observable showFeedbackDialog: boolean = false;
  @observable feedbackIcon?: string = '';
  @persist @observable isRenderingFromNativeApp: boolean = false;
  @observable isZohoVisible: boolean = false;
  @persist @observable previewingPartner: boolean = false;

  @action
  setCurrentSchool(school: string) {
    this.currentSchool = school;
  }

  @action
  setCurrentPartner(partner: { label: string; value: string; }) {
    this.currentPartner = partner;
  }

  @action
  setCurrentOrderStatusTab(tab: string) {
    this.currentTab = tab;
  }

  @action
  setShowLoginDialog(show: boolean) {
    this.showLoginDialog = show;
  }

  @action
  setNoStudentDialog(open: boolean) {
    this.showNoStudentDialog = open;
  }

  @action
  setShowMobileNav(open: boolean) {
    this.showMobileNav = open;
  }

  @action
  setShowMobileAccountMenu(open: boolean) {
    this.showMobileAccountMenu = open;
  }
  @action
  setShowTermsDialog(open: boolean) {
    this.showTermsDialog = open;
  }

  @action
  setShowFlowJoinDialog(open: boolean) {
    this.showFlowJoinDialog = open;
  }

  @action
  setShowForgotPasswordDialog(open: boolean) {
    this.showForgotPasswordDialog = open;
  }

  @action
  setIsAuthenticated(authenticated: boolean) {
    this.isAuthenticated = authenticated;
  }

  @action
  setShowFeedbackDialog(open: boolean, thumbIcon?: string) {
    this.showFeedbackDialog = open;
    this.feedbackIcon = thumbIcon;
  }

  @action
  setIsRenderingFromNativeApp(isRenderingFromNativeApp: boolean) {
    this.isRenderingFromNativeApp = isRenderingFromNativeApp;
  }

  @action
  setIsZohoVisible(isVisible: boolean) {
    this.isZohoVisible = isVisible;
  }

  @action
  setPreviewingPartner(previewing: boolean) {
    this.previewingPartner = previewing;
  }
}