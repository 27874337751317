import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from "../../../common_component/base/button";
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { useForm } from 'react-hook-form';
import TextField from '../../../common_component/form/TextField';

type Props = {
  familyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  mutate: () => void;
  mutateFamily: () => void;
  isFamilyDisabled: boolean;
}

type FormProps = {
  comment: string
}

const DisableReEnableDialog = ({ familyId, open, onOpenChange, mutate, mutateFamily, isFamilyDisabled }: Props) => {
  const { toast } = useToast();

  const { control, handleSubmit } = useForm<FormProps>({
    defaultValues: {}
  });

  const { trigger, isMutating, error } = useSWRMutation('/customer/disable-re-enable', async (key, { arg }: { arg: FormProps }) => {
    try {
      const response = await api.customer.disableReEnableFamily({ targetFamilyId: familyId, comment: arg.comment, isDisabled: !isFamilyDisabled });

      if (response) {
        onOpenChange(false);
        const res = await response.json();
        if (res.rtype === 'error') {
          toast({
            title: `${res.message}`,
            duration: 3000,
          });
        } else if (res.success) {
          toast({
            title: `Family has been ${isFamilyDisabled ? 're-enabled' : 'disabled'}.`,
            duration: 3000,
          });
          mutate();
          mutateFamily();
        } else {
          toast({
            title: 'Something went wrong, please try again',
            duration: 3000,
          });
        }
      } else {
        throw new Error('Something went wrong, please try again');
      }
    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  const onSubmit = (data: FormProps) => {
    trigger(data);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{isFamilyDisabled ? 'Re-enable' : 'Disable'} family</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-3 pb-2'>
            <TextField
              name="comment"
              required
              control={control}
              rows={6}
              label={`Please supply a message describing why family is being ${isFamilyDisabled ? 're-enabled' : 'disabled'}`}
              rules={{
                required: 'Message is required.',
              }}
            />
          </div>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
            <Button type="submit" disabled={isMutating}>Submit</Button>
          </DialogFooter>
        </form>
        {error && <div className="mt-2 text-sm text-destructive">{error}</div>}
      </DialogContent>
    </Dialog>
  )
}

export default DisableReEnableDialog