import React from 'react';
import { Column, ColumnDef } from "@tanstack/react-table"
import { MdArrowUpward } from 'react-icons/md';
import { Button } from '../../../common_component/base/button';
import { cn } from '../../../util/tailwind';
import InfoTooltip from 'components/tooltip/InfoTooltip';
import { PayableStatus } from 'networking/models/payable';

export interface RowItem {
  permanentId: string;
  name: string;
  price: string;
  year: string;
  status: PayableStatus;
  applied: number;
  paymentStatus: string;
  protoPayableId?: string;
  payableCategory?: string;
  gst: string;
  glCode: string;
  isDonation: boolean;
  description: string;
}
const SortableHeader = ({ column, title, infoTooltip }: { column: Column<RowItem>; title: string; infoTooltip?: string; }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className={cn('px-0 text-card-foreground')}
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
      {
        infoTooltip && (
          <InfoTooltip
            side="bottom"
            className='pl-1'
            messages={[
              <p className='font-sans text-left'>
                {infoTooltip}
              </p>
            ]}
          />
        )
      }
    </Button>
  )
};

export const columns: ColumnDef<RowItem>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <SortableHeader column={column} title="Payable" />
    ),
    maxSize: 300,
    size: 300,
    cell: ({ row, cell }) => (
      <div>
        <p className='text-card-foreground'>{cell.getValue()}</p>
        <div className='text-xs text-text-helper max-h-[18px] overflow-hidden' dangerouslySetInnerHTML={{ __html: row.original.description }} />
      </div>
    ),
  },
  {
    accessorKey: "price",
    header: ({ column }) => (
      <SortableHeader column={column} title="Price" />
    ),
    meta: {
      textAlign: 'right',
    },
    size: 120,
    cell: ({ row, cell }) => (
      <div>
        <p className='text-card-foreground'>{cell.getValue()}</p>
        <p className='text-xs text-text-helper '>
          {row.original.gst === 'GST exempt' ? 'GST exempt' : `incl. GST (15%)`}
        </p>
      </div>
    ),
  },
  {
    accessorKey: "glCode",
    header: ({ column }) => (
      <SortableHeader column={column} title="GL Code" />
    ),
    meta: {
      textAlign: 'right',
      columnTitle: 'GL Code',
    },
    maxSize: 80,
    size: 80,
  },
  {
    accessorKey: "isDonation",
    header: ({ column }) => (
      <SortableHeader column={column} title="Receipt Type" />
    ),
    meta: {
      columnTitle: 'Receipt Type',
    },
    cell: ({ cell }) => (
      <p className='text-card-foreground'>{cell.getValue() ? 'Donation' : 'Standard'}</p>
    ),
    size: 140,
  },
  {
    accessorKey: "payableCategory",
    header: ({ column }) => (
      <SortableHeader column={column} title="Category" />
    ),
    meta: {
      columnTitle: 'Category',
    },
  },
]
