import useSWR from 'swr';
import { baseApi } from './constants';
import { KindoNetworkError } from './models/common';
import { ProfferRequest, ProfferResponse } from './models/proffer';
import fetcher from './util/fetcher';

const proffer = {
  // Queries (starts with "use")
  useProfferDetails: (profferId?: string) => {
    return useSWR<ProfferRequest, KindoNetworkError>(profferId ? `${baseApi}/proffer/reques?&proffer_id=${profferId}` : '');
  },

  // Mutations
  upliftAccount: async ({
    password,
    profferId,
  }: {
    password: string;
    profferId: string;
  }) => {
    return fetcher(`${baseApi}/proffer/uplift_account?proffer_id=${profferId}`, {
      method: 'POST',
      body: JSON.stringify({
        rtype: 'uplift_account',
        accept: true,
        user_pass: password,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return (await response.json()) as ProfferResponse;
      }
      return (await response.json()) as KindoNetworkError;
    });
  },

  withdraw: async ({
    profferId,
    withdrawAmountInDollars,
    bankBankNumber,
    bankBranchNumber,
    bankAccountNumber,
    bankSuffixNumber,
  }: {
    profferId: string;
    withdrawAmountInDollars: string;
    bankBankNumber: string;
    bankBranchNumber: string;
    bankAccountNumber: string;
    bankSuffixNumber: string;
  }) => {

    return fetcher(`${baseApi}/proffer/withdraw?proffer_id=${profferId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'withdrawal',
        amount_in_dollars: withdrawAmountInDollars,
        bank_account_bank_no: bankBankNumber,
        bank_account_branch_no: bankBranchNumber,
        bank_account_account_no: bankAccountNumber,
        bank_account_suffix_no: bankSuffixNumber,
      }),
    }).then(async (response) => {
      if (response.ok) {
        return (await response.json()) as ProfferResponse;
      }
      return (await response.json()) as KindoNetworkError;
    });
  },

  closeAccount: async ({
    profferId,
    schoolIds,
    bankBankNumber,
    bankBranchNumber,
    bankAccountNumber,
    bankSuffixNumber,
    disburse,
  }: {
    profferId: string;
    schoolIds: string[];
    bankBankNumber: string;
    bankBranchNumber: string;
    bankAccountNumber: string;
    bankSuffixNumber: string;
    disburse: string; // family or school
  }) => {
    return fetcher(`${baseApi}/proffer/close_account?proffer_id=${profferId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'close_account',
        disburse,
        ...(disburse === 'family' ? {
          bank_account_bank_no: bankBankNumber,
          bank_account_branch_no: bankBranchNumber,
          bank_account_account_no: bankAccountNumber,
          bank_account_suffix_no: bankSuffixNumber,
        } : {}),
        // Next line will add "School name": 'on' for each school Id
        ...schoolIds.reduce((acc, schoolId) => ({ ...acc, [schoolId]: 'on' }), {}),
      }),
    });
  },
}

export default proffer;