import { Family } from "networking/models/family";

// These are permissions that are used in the application to determine what the user can see or do
// A user may have multiple permissions. 
// For example, a user may be a service administrator and a school administrator

const isServiceAdministrator = (family?: Family) => {
  return family?.manager && !!family.supplier;
}

const isTgclAdmin = (family?: Family) => {
  return family?.tgcl === 'admin';
}

const isSchoolAdministrator = (family?: Family) => {
  return !!family?.school;
}

const isOrderAdministrator = (family?: Family) => {
  return family?.supplier_order.length! > 0;
}

const isMenuAdministrator = (family?: Family) => {
  return family?.supplier_menu.length! > 0;
}

const isAnyAdministrator = (family?: Family) => {
  return isServiceAdministrator(family) || isTgclAdmin(family) || isSchoolAdministrator(family) || isOrderAdministrator(family) || isMenuAdministrator(family);
}

const canSeeOrderStatus = (family?: Family) => {
  return isMenuAdministrator(family) || isOrderAdministrator(family) || isTgclAdmin(family) || isServiceAdministrator(family);
}

export {
  isServiceAdministrator,
  isTgclAdmin,
  isSchoolAdministrator,
  isOrderAdministrator,
  isMenuAdministrator,
  isAnyAdministrator,
  canSeeOrderStatus,
}