import React from 'react'
import {
  Table as TableUI,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../common_component/base/table"
type Props = {
  hideHeader?: boolean;
  className?: string;
  rowClassName?: string;
  columns: {
    title: string;
    dataKey: string;
    className?: string;
    cellClassName?: string; // Applied to the cells of this column
    render?: (row: any) => React.ReactNode;
  }[];
  rows: any[];
}

const Table = ({ className, rowClassName, hideHeader, columns, rows }: Props) => {
  return (
    <TableUI className={className}>
      {
        !hideHeader && (
          <TableHeader>
            <TableRow>
              {
                columns.map((column) => (
                  <TableHead key={column.dataKey} className={column.className} >{column.title}</TableHead>
                ))
              }
            </TableRow>
          </TableHeader>
        )
      }
      <TableBody>
        {
          rows.map((row) => {
            return (
              <TableRow key={row.id} className={rowClassName}>
                {
                  columns.map((column) => (
                    <TableCell key={column.dataKey} className={column.cellClassName}>
                      {column.render ? column.render(row) : row[column.dataKey]}
                    </TableCell>
                  ))
                }
              </TableRow>
            )
          })
        }
      </TableBody>
    </TableUI>
  )
}

export default Table