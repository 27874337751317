import React from 'react'

type Props = {
  title: string
}

const SectionSubTitle = ({ title }: Props) => {
  return (
    <p className='px-6 text-sm sm:px-12 text-text-helper'>
      {title}
    </p>
  )
}

export default SectionSubTitle