import React from 'react'
import Helmet from 'react-helmet'
import CommunityShopLayout from '../../common_component/layout/CommunityShopLayout'
import WelcomeImageDefault from '../../assets/images/community-shop-welcome-image.png';
import Image from '../../common_component/Image';

const MyOrdersPage = () => {
  return (
    <div>
      <Helmet>
        <title>My Orders</title>
      </Helmet>
      <CommunityShopLayout>
        <div className='h-[70vh] flex flex-col text-center items-center gap-6 overflow-auto'>
          <Image
            className='object-contain h-[195px] w-full px-4 sm:px-20 pointer-events-none'
            alt=''
            src={`${WelcomeImageDefault}`}
          />
          <p className='font-museo text-xl'>My orders page coming soon..</p>
          <p className='font-museo text-lg'>If you have any queries about your order, please contact our helpdesk.</p>
        </div>
      </CommunityShopLayout>
    </div>
  )
}

export default MyOrdersPage