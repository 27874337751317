import { observable, computed, action, makeObservable } from "mobx";
import { persist } from 'mobx-persist'
export default class AdminStore {
  constructor() {
    makeObservable(this)
  }
  @persist @observable currentSchool: string = '';

  @action
  setCurrentSchool(school: string) {
    this.currentSchool = school;
  }
}