import { create } from "mobx-persist";
import FamilyStore from "./FamilyStore";
import ShopStore from "./ShopStore";
import AdminStore from "./AdminStore";

export interface RootStore {
  family: FamilyStore;
  shop: ShopStore;
  admin: AdminStore;
}
export const createStores = async () => {
  const familyStore = new FamilyStore();
  const shopStore = new ShopStore();
  const adminStore = new AdminStore();
  try {
    const hydrate = create();
    // Only hydrate if there are values that were persisted
    await hydrate("familyStore", familyStore);
    await hydrate("shopStore", shopStore);
    await hydrate("adminStore", adminStore);
  } catch (e) {
    console.log("Error hydrating stores", e);
  }

  return {
    family: familyStore,
    shop: shopStore,
    admin: adminStore,
  } as RootStore;
}
