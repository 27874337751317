import React from 'react'
import { useForm } from 'react-hook-form';

import { KindoNetworkError } from 'error/server_error';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../common_component/base/dialog";
import InputField from '../../../common_component/form/InputField';
import { Button } from '../../../common_component/base/button';
import api from 'networking/api';
import { useToast } from '../../../common_component/base/use-toast';
import CheckboxField from '../../../common_component/form/CheckboxField';
import TextField from '../../../common_component/form/TextField';
import KindoWell from '../../../common_component/KindoWell';
import _ from 'lodash';

type FormData = {
  groupId?: string;
  bulkImport?: boolean;
  importText?: string;
  previewImport?: boolean;
}
type Props = {
  schoolId: string;
  groupId?: string;
  open: boolean;
  onGroupCreated: (groupId: string) => void;
  onOpenChange: (open: boolean) => void;
}

const GroupFormDialog = (props: Props) => {
  const {
    schoolId,
    groupId,
    open,
    onOpenChange,
    onGroupCreated,
  } = props;

  const { toast } = useToast();
  const { control, handleSubmit, reset, watch, formState: { isSubmitting } } = useForm<FormData>({
    defaultValues: {
      groupId
    },
  });

  const [importResult, setImportResult] = React.useState<{
    error: boolean;
    messages: string[];
  }>({
    error: false,
    messages: [],
  });

  const getRows = (value?: string) => {
    return (value || '')?.split('\n').map(line => line.trim()).filter(line => line.length > 0).map((line) => line.split(',').slice(0, 2)) ?? [];
  }
  const onImport = (preview: boolean) => async (values: FormData) => {
    const rows = getRows(values.importText);
    setImportResult({
      error: false,
      messages: [],
    });
    if (values.groupId) {

      const cols = ['student_id'];
      if (rows.some(row => row.length > 1)) {
        cols.push('first_name');
      }
      const response = await api.admin.createGroupBulkImport({
        groupId: values.groupId,
        schoolId: schoolId,
        rows,
        cols,
        preview,
      })
      if (response.rtype === 'error') {
        setImportResult({
          error: true,
          messages: [response.message]
        });
      } else if (response.rtype === 'roll_group_ref') {
        setImportResult({
          messages: response.transcript,
          error: false,
        });
        if (!preview) {
          toast({
            title: `Group has been created`,
            duration: 6000,
            description: (
              <KindoWell
                messages={response.transcript}
              />
            )
          });
          onGroupCreated(values.groupId);
          onOpenChange(false);
        }
      }
    }
  };

  const onSubmit = async (values: FormData) => {
    if (values.bulkImport) {
      onImport(false)(values);
    } else {
      if (values.groupId) {
        const response = await api.admin.createGroup({ schoolId, groupName: values.groupId });
        if (response.ok) {
          toast({
            title: `Group has been ${groupId ? 'updated' : 'created'}`,
            duration: 6000,
          });
          onGroupCreated(values.groupId);
          onOpenChange(false);
        } else {
          const error = await response.json() as KindoNetworkError;
          toast({
            variant: 'destructive',
            title: `Failed to ${groupId ? 'update' : 'create'} group`,
            ...(error?.name === 'EntityConflictException' ? {
              description: 'Group name already exists'
            } : {}),
          });
        }
      }

    }
  };

  React.useEffect(() => {
    // initialize form when dialog is opened
    if (open) {
      reset({
        groupId,
      })
    }
  }, [open])

  const bulkImport = watch('bulkImport');

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {groupId ? 'Edit Group' : 'New Group'}
          </DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4 pb-1 text-card-foreground'>
            <InputField
              name="groupId"
              control={control}
              label="Group Name"
              rules={{
                required: 'Group name is required',
                validate: {
                  usesSupportedCharacters: (value: string) => {
                    // Supported characters are alphabets, numbers, and - and space
                    if (!value.match(/^[a-zA-Z0-9- ]+$/)) {
                      return 'Group name can only contain alphabets, numbers, and -';
                    }
                  }
                }
              }}
            />

            <CheckboxField
              control={control}
              name="bulkImport"
              label="Import students"
            />

            {
              bulkImport && (
                <>
                  <TextField
                    control={control}
                    name="importText"
                    label="Paste existing student IDs separated by new line"
                    rows={4}
                    rules={{
                      validate: {
                        hasValidRows: (value: string) => {
                          const rows = getRows(value);
                          const hasRows = rows.length > 0 && rows.every(row => row.length <= 2);
                          if (!hasRows) {
                            return 'Please include at least one student ID'
                          }
                          const hasInconsistentRows = rows.some(row => row.length > 1) && rows.some(row => row.length === 1);
                          if (hasInconsistentRows) {
                            return 'Please include either all or no student first names'
                          }

                          const ids = rows.map(row => row[0]);
                          const duplicates = ids.filter((id, index) => ids.indexOf(id) !== index);
                          if (duplicates.length > 0) {
                            return `Please remove duplicate student IDs: ${_.uniq(duplicates).join(', ')}`
                          }
                        },
                      }
                    }}
                  />
                  <p className='text-sm text-text-helper'>
                    Optionally include student's preferred first name separated by comma. Example:
                    <br />
                    1234, John
                    <br />
                    5678, Alice
                  </p>
                </>
              )
            }
            {
              importResult.messages && importResult.messages.length > 0 && (
                <KindoWell
                  messages={importResult.messages}
                  variant={importResult.error ? "danger" : "info"}
                />
              )
            }

          </div>
          <DialogFooter>
            <div className='flex items-center gap-3'>
              {
                bulkImport && (
                  <Button type="button" variant="outline" onClick={() => handleSubmit(onImport(true))()}>Preview</Button>

                )
              }
              <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
              <Button type="submit" disabled={isSubmitting}>{groupId ? 'Update' : 'Create'}</Button>
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default GroupFormDialog