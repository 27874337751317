import React, {useCallback, useEffect, useRef, useState} from "react";
import Tippy from "@tippyjs/react";
import {Placement, Instance as TippyInstance} from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light-border.css'
import {preserveRef, RefType} from "../../util/ref";

export const MY_KINDO_TIPPY_DEFAULTS = {
    theme: 'light-border',
}

interface TippyTitleProps {
    tooltipContent: React.ReactNode
    children: React.ReactElement

    interactive?: boolean
    placement?: Placement

    enabled?: boolean

    onClick?: (evt: MouseEvent) => void
}
export const MyKindoTippy: React.FC<TippyTitleProps> = ({tooltipContent, children, onClick, interactive = true, placement = 'top', enabled = true}) => {
    const [tippyInstance, setTippyInstance] = useState<TippyInstance | null>(null)
    const [childFocused, setChildFocused] = useState<boolean>(false)
    const childRef = useRef<HTMLElement | null>(null)

    const onChildFocusCallback = useCallback(() => {
        setChildFocused(true)
    }, [])

    const onChildBlurCallback = useCallback(() => {
        setChildFocused(false)
    }, [])

    const childRefCallback = useCallback((node: HTMLElement) => {
        // Adapted from: https://github.com/atomiks/tippyjs-react/blob/d5ee338c7e096ada3c8765d948bd54e38b3f8da0/src/Tippy.js#L220
        // Saves the node as the target of childRef, then passes it on to the caller's ref.
        childRef.current = node
        preserveRef((children as unknown as {ref: RefType}).ref, node)
    }, [childRef, children])

    useEffect(() => {
        // Bind the focus and blur event handlers to the new element and update the state to reflect the current
        // focus state of the new element.

        const el = childRef.current
        if (el !== null) {
            if (window.document.activeElement === el) {
                onChildFocusCallback()
            }
            else {
                onChildBlurCallback()
            }

            el.addEventListener('focus', onChildFocusCallback)
            el.addEventListener('blur', onChildBlurCallback)

            return () => {
                el.removeEventListener('focus', onChildFocusCallback)
                el.removeEventListener('blur', onChildBlurCallback)
            }
        }
    }, [childRef, onChildFocusCallback, onChildBlurCallback])

    useEffect(() => {
        // Update the tippy instance's visibility depending on its focus and enabled status
        if (tippyInstance !== null) {
            if (!enabled) {
                tippyInstance.hide()
            }
            else {
                if (childFocused) {
                    tippyInstance.show()
                }
                else {
                    tippyInstance.hide()
                }
            }
        }
    }, [childFocused, tippyInstance, enabled])

    return (
        <Tippy
            content={tooltipContent}
            interactive={interactive}
            placement={placement}
            trigger={(enabled) ? 'focus mouseenter' : 'manual'}
            onCreate={setTippyInstance}
            {...MY_KINDO_TIPPY_DEFAULTS}
        >
          {(onClick) ? 
            React.cloneElement(children, {onClick: onClick})
          : 
            React.cloneElement(children, {
              ref: childRefCallback
            })
          }
        </Tippy>
    )
}