import React from 'react';
import { Transaction, TransactionStudent, MenuSupplier } from 'networking/models/family'
import { cn } from "util/tailwind"
import { formatCentsPriceForDisplay } from 'util/string_util';
import { Button } from 'components/base/button'
import KindoTooltip from 'components/tooltip/Tooltip';
import SendConfirmationDialog from './dialog/SendConfirmationDialog';
import RefundPurchaseDialog from './dialog/RefundPurchaseDialog';
import ReOrderPurchaseDialog from './dialog/ReOrderPurchaseDialog';
import { MdNavigateBefore, MdNavigateNext } from "react-icons/md";
import orderUtils from 'networking/util/order';
import { getDonationReceiptsUrl } from 'networking/constants';
import CancelTransactionDialog from './dialog/CancelTransactionDialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../common_component/base/table";
import { Popover, PopoverTrigger, PopoverContent, PopoverArrow } from "../../common_component/base/popover";
import ActiveTransactionDialog from './dialog/ActiveTransactionDialog';
import { MdExpandMore } from "react-icons/md"
import { useContentDialog } from "../../common_component/dialog/RootContentDialog";

interface TransactionRecordsProps {
  transactionList: Transaction[];
  familyId: string;
  pageCount: number;
  activePage: number;
  isTgclUser: boolean;
  onPageChange: () => void;
  mutate: () => void;
}

const TransactionRecords: React.FC<TransactionRecordsProps> = ({ transactionList, familyId, pageCount, activePage, isTgclUser, onPageChange, mutate }) => {
  const [purchaseId, setPurchaseId] = React.useState('');

  const [showSendConfirmation, setShowSendConfirmation] = React.useState<boolean>(false)
  const [showRefundPurchase, setShowRefundPurchase] = React.useState<boolean>(false)
  const [showReOrderPurchase, setShowReOrderPurchase] = React.useState<boolean>(false)
  const [showCancelTransaction, setShowCancelTransaction] = React.useState<boolean>(false)
  const [activeTransactionId, setActiveTransactionId] = React.useState('');
  const [activeTransaction, setActiveTransaction] = React.useState<Transaction>();
  const [showActiveTransaction, setShowActiveTransaction] = React.useState<boolean>(false)
  const { showDialog, closeDialog } = useContentDialog();

  const handlePopoverOpenChange = (transactionId: string, open: boolean) => {
    if (open) {
      setActiveTransactionId(transactionId);
    } else {
      setActiveTransactionId('');
    }
  };

  const showDonationReceipts = (tId: string) => {
    showDialog({
      title: 'Your donation receipt will open and load in a new tab',
      content: <p className="text-card-foreground">This may take a while. To continue working while your donation receipt loads, keep that tab open and check back later. If your donation receipt fails to load refresh the tab, or close the tab and try again.</p>,
      primaryActionLabel: 'Download receipt',
      secondaryActionLabel: `Don't download`,
      primaryActionProps: {
        onClick: () => {
          window.open(getDonationReceiptsUrl({ purchaseId: tId }))
          closeDialog();
        }
      },
    })
  }

  const onPageClick = (page) => {
    onPageChange(page);
  };

  const splitDateTime = (dateTime) => {
    const [date, time] = dateTime.split(' ');
    return (
      <div className='flex flex-col'>
        <p>{date}</p>
        <p>{time}</p>
      </div>
    );
  };



  return (
    <div className="mb-6">
      <Table>
        <TableHeader className="font-bold font-museo">
          <TableRow>
            <TableHead>Date Created</TableHead>
            <TableHead>Delivery Date</TableHead>
            <TableHead>Details</TableHead>
            <TableHead>Amount</TableHead>
            <TableHead>Actions</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {transactionList.map((transaction: Transaction) => (
            <TableRow
              className='cursor-pointer text-card-foreground'
              key={transaction.transaction_id}
            >
              <TableCell className='align-top'>{splitDateTime(transaction.transaction_datetime)}</TableCell>
              <TableCell className='align-top'>{transaction.delivery_date ? transaction.delivery_date : '-'}</TableCell>
              <TableCell className='max-w-xs align-top'>
                <div className='flex flex-col gap-4'>
                  <div
                    className={cn("capitalize font-semibold text-primary", {
                      'text-success': transaction.transaction_subtype === 'topup',
                      'text-destructive': transaction.is_cancelled || transaction.transaction_subtype === 'refund',
                      'text-card-foreground': transaction.homo_meta === 'hybrid' || transaction.transaction_type === 'settlement'
                    })}
                  >
                    {transaction.homo_meta === 'hybrid' ? 'Payment / Purchase' : transaction.transaction_subtype}
                    {transaction.is_cancelled ? ' Cancelled' : undefined}
                  </div>
                  {/* Purchase Transaction */}
                  {transaction.transaction_subtype === 'purchase' &&
                    <div
                      className={cn("", {
                        'text-gray-400': transaction.is_cancelled,
                      })}
                    >
                      <div className='flex flex-col gap-4'>
                        <div>
                          {/* Lunchbox */}
                          {transaction.lunchbox &&
                            <>
                              {transaction.students.map((student: TransactionStudent) => (
                                <div className='flex flex-col gap-2' key={transaction.transaction_id + student.member_id}>
                                  <div>
                                    <p className='font-semibold'>{student.student}</p>
                                    <p>{student.room && `${student.room}, `}{student.school}</p>
                                  </div>
                                  <div>
                                    {student.products.map((product: string) => (
                                      <p key={product}>{product}</p>
                                    ))}
                                  </div>
                                </div>
                              ))}
                              <div className='flex gap-1 pt-4'>
                                <p className='font-semibold'>Orders:</p>
                                <p>{transaction.lunchbox}</p>
                              </div>
                            </>
                          }
                        </div>
                        {/* Transaction Status */}
                        {transaction.status &&
                          <div>
                            <p className='font-semibold'>Status</p>
                            <div>
                              {Object.keys(transaction.status).map((item, i) => (
                                <p key={i}>{item}({transaction.status[`${item}`][i]})</p>
                              ))}
                            </div>
                          </div>
                        }
                        {transaction.suppliers &&
                          <div>
                            <p className='font-semibold'>Supplier Names</p>
                            <div>
                              {transaction.suppliers.map((supplier: MenuSupplier) => (
                                <p key={supplier.id}>{supplier.name}</p>
                              ))}
                            </div>
                          </div>
                        }
                        {/* Purchase ID */}
                        {transaction.purchase_id &&
                          <div className='flex flex-col'>
                            <p className='font-semibold'>Purchase ID</p>
                            <p>{transaction.purchase_id}</p>
                          </div>
                        }
                        {/* Cancelled Datetime */}
                        {transaction.cancelled_at &&
                          <div className='flex flex-col'>
                            <p className='font-semibold'>Cancelled</p>
                            <p>{transaction.cancelled_at}</p>
                          </div>
                        }
                        {/* Refund Amount */}
                        {transaction.total_refunds_in_cents ?
                          <div className='flex gap-1'>
                            <p className='font-semibold text-destructive'>Total refunds:</p>
                            <p>{formatCentsPriceForDisplay(transaction.total_refunds_in_cents)}</p>
                          </div>
                          :
                          ''
                        }
                      </div>
                    </div>
                  }
                  {/* Topup Transaction */}
                  {transaction.transaction_subtype === 'topup' &&
                    <div>
                      {transaction.method_description && <p>Method: {transaction.method_description}</p>}
                      {transaction.response_text && <p>Response: {transaction.response_text}</p>}
                    </div>
                  }

                  {/* Refund Transaction */}
                  {transaction.transaction_subtype === 'refund' &&
                    <div>
                      {/* {transaction.amount_in_cents !== 0 && <p>Amount: {formatCentsPriceForDisplay(transaction.amount_in_cents)}</p>} */}
                      {transaction.supplier_id && <p>Supplier ID: {transaction.supplier_id}</p>}
                      {transaction.comment &&
                        <div className='flex flex-col gap-1 pt-2'>
                          <p>Comment:</p>
                          <p className='break-words whitespace-break-spaces'>{transaction.comment}</p>
                        </div>
                      }
                    </div>
                  }

                  {/* Settlement Transaction */}
                  {transaction.transaction_subtype === 'settlement' &&
                    <div>
                      {transaction.comments && <p>Comment(s): {transaction.comments}</p>}
                      {transaction.fee_in_cents !== 0 && <p>Fee: {transaction.fee_in_cents ? formatCentsPriceForDisplay(transaction.fee_in_cents) : 'None'}</p>}
                    </div>
                  }
                </div>
              </TableCell>
              <TableCell className='align-top'>
                {/* Transaction: Purchase Amount Paid */}
                {transaction.total_amount_in_cents ? formatCentsPriceForDisplay(transaction.total_amount_in_cents) : ''}
                {/* Refund amount */}
                {transaction.transaction_subtype === 'refund' && transaction.amount_in_cents ? formatCentsPriceForDisplay(transaction.amount_in_cents) : ''}
                {/* Top up amount */}
                {transaction.method_description === 'Bank Transfer' ? formatCentsPriceForDisplay(transaction.posted_amount_in_cents) : (transaction.topped_up_amount_in_cents && transaction.method_description !== 'Bank Transfer' ? formatCentsPriceForDisplay(transaction.topped_up_amount_in_cents) : '')}
                {/* Settlement */}
                {transaction.amount_in_cents && transaction.transaction_subtype !== 'refund' ? formatCentsPriceForDisplay(transaction.amount_in_cents) : ''}
              </TableCell>
              <TableCell className='align-top'>
                <div className='flex flex-col gap-2 w-36'>
                  <Button size="xs" type="button" onClick={() => { setActiveTransaction(transaction); setShowActiveTransaction(true); }}>View details</Button>
                  {transaction.is_cancelled || transaction.transaction_subtype === 'topup' || transaction.transaction_subtype === 'refund' ?
                    transaction.transaction_subtype === 'purchase' && transaction.homo_meta !== 'hybrid' && isTgclUser &&
                    <Button size="xs" type="button" onClick={() => { setPurchaseId(transaction.purchase_id); setShowReOrderPurchase(true); }}>Re-order</Button>
                    :
                    <Popover
                      key={transaction.transaction_id}
                      open={activeTransactionId === transaction.transaction_id}
                      onOpenChange={(open) => handlePopoverOpenChange(transaction.transaction_id, open)}
                    >
                      <PopoverTrigger>
                        <Button size="xs" className="flex items-center w-full gap-2" variant="outline" type="button" name="more-actions">
                          <div>
                            More actions
                          </div>
                          <MdExpandMore />
                        </Button>
                      </PopoverTrigger>
                      <PopoverContent align="center" className="z-10 flex flex-col w-full gap-2 p-2 bg-white border-none shadow-lg">
                        {transaction.transaction_subtype === 'purchase' && !transaction.is_cancelled &&
                          <div className='flex flex-col gap-2'>
                            {transaction.closing === undefined || !orderUtils.isStaleHelpDesk(transaction.delivery_date, transaction.closing) ?
                              <KindoTooltip
                                side="top"
                                messages={[
                                  ...(transaction.closing === undefined ? ['It may be too early for a refund (we do not have closing for the purchase to confirm)'] : []),
                                  ...(!orderUtils.isStaleHelpDesk(transaction.delivery_date, transaction.closing) ? ['Cannot refund purchase: Too early to refund.'] : [])
                                ]}
                              >
                                <Button size="xs" variant='plain' className='opacity-50' type="button">
                                  Refund
                                </Button>
                              </KindoTooltip>
                              :
                              <Button
                                size="xs"
                                type="button"
                                variant='plain'
                                onClick={() => {
                                  setPurchaseId(transaction.purchase_id);
                                  setShowRefundPurchase(true)
                                }}
                              >
                                Refund
                              </Button>
                            }

                            {orderUtils.isStaleHelpDesk(transaction.delivery_date, transaction.closing) ?
                              <KindoTooltip
                                side="top"
                                messages={['Cannot cancel purchase: Too late to cancel.']}
                              >
                                <Button size="xs" type="button" className='opacity-50' variant='plain'>
                                  Cancel
                                </Button>
                              </KindoTooltip>
                              :
                              <Button
                                size="xs"
                                type="button"
                                variant='plain'
                                onClick={() => {
                                  setPurchaseId(transaction.purchase_id);
                                  setShowCancelTransaction(true)
                                }}
                              >
                                Cancel
                              </Button>
                            }
                          </div>
                        }
                        {(transaction.transaction_subtype === 'purchase' || transaction.homo_meta === 'hybrid') && !transaction.is_cancelled &&
                          <Button size="xs" type="button" variant='plain' onClick={() => { setPurchaseId(transaction.purchase_id); setShowSendConfirmation(true) }}>Send confirmation</Button>
                        }

                        {transaction.transaction_subtype === 'purchase' && !transaction.is_cancelled &&
                          <Button size="xs" type="button" variant='plain' onClick={() => {
                            showDonationReceipts(transaction.purchase_id)
                          }}>Donation receipts</Button>
                        }
                        {transaction.transaction_subtype === 'purchase' && isTgclUser &&
                          <Button size="xs" type="button" variant='plain' onClick={() => { setPurchaseId(transaction.purchase_id); setShowReOrderPurchase(true); }}>Re-order</Button>
                        }
                        <PopoverArrow className='fill-white' />
                      </PopoverContent>
                    </Popover>
                  }
                </div>
              </TableCell>
              {/* Modals */}
              {(transaction.purchase_id === purchaseId) &&
                <SendConfirmationDialog
                  open={showSendConfirmation}
                  onOpenChange={(open) => setShowSendConfirmation(open)}
                  purchaseId={purchaseId}
                  familyId={familyId}
                />
              }
              {(transaction.purchase_id === purchaseId) &&
                <RefundPurchaseDialog
                  open={showRefundPurchase}
                  onOpenChange={(open) => setShowRefundPurchase(open)}
                  purchaseId={purchaseId}
                  familyId={familyId}
                  students={transaction.students}
                  mutate={mutate}
                  isTgclUser={isTgclUser}
                  lunchboxFee={transaction.per_lunchbox_fee}
                />
              }
              {(transaction.purchase_id === purchaseId) &&
                <ReOrderPurchaseDialog
                  open={showReOrderPurchase}
                  onOpenChange={(open) => setShowReOrderPurchase(open)}
                  purchaseId={purchaseId}
                  mutate={mutate}
                />
              }
              {(transaction.purchase_id === purchaseId) &&
                <CancelTransactionDialog
                  open={showCancelTransaction}
                  onOpenChange={(open) => setShowCancelTransaction(open)}
                  purchaseId={purchaseId}
                  familyId={familyId}
                  mutate={mutate}
                />
              }
            </TableRow>

          ))}
        </TableBody>
      </Table>
      {activeTransaction &&
        <ActiveTransactionDialog
          open={showActiveTransaction}
          onOpenChange={(open) => setShowActiveTransaction(open)}
          transaction={activeTransaction}
        />
      }
      {pageCount !== 0 &&
        <div className='flex items-center justify-center pt-4'>
          <Button type='button' variant='iconPrimary' name={`button-back-${activePage - 1}`} disabled={Number(activePage) === 1} onClick={() => onPageClick(activePage - 1)}>
            <MdNavigateBefore className='text-base' />
          </Button>
          {Array.from({ length: pageCount }).map((_, index) => {
            const page = index + 1;
            return (
              <Button
                key={page}
                type='button'
                size='xs'
                variant={activePage === page ? 'linkUnderline' : 'ghost'}
                onClick={() => onPageClick(page)}
              >
                {page}
              </Button>
            );
          })}
          <Button type='button' variant='iconPrimary' name={`button-next-${activePage + 1}`} disabled={Number(activePage) === pageCount} onClick={() => onPageClick(activePage + 1)}>
            <MdNavigateNext className='text-base' />
          </Button>
        </div>
      }
    </div>
  );
}

export default TransactionRecords;
