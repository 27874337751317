import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader
} from "../../common_component/base/dialog";
import api from 'networking/api';
import { Button } from '../../common_component/base/button'
import SelectField from '../../common_component/form/SelectField';
import { useForm } from 'react-hook-form';

type FormData = {
  school: string;
};

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  email: string;
  seedSchools?: any;
}

const HandshakeDialog = observer(({ open, onOpenChange, email, seedSchools }: Props) => {
  const { control, handleSubmit, watch } = useForm<FormData>({
    defaultValues: {
      school: seedSchools.length === 1 ? seedSchools[0] : ''
    },
  });

  const { data: shopData } = api.shop.useShopStatus(watch('school'));
  const [sendInvite, setSendInvite] = useState(false);
  const [school, setSchool] = useState('');

  const { data: invitationStatusData } = api.shop.useInvitationStatus(
    email,
    school,
    sendInvite
  )

  const handleInvite = (values: FormData) => {
    setSchool(values.school)
    setSendInvite(true)
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent hideCloseButton>
        <DialogHeader>
          <p className='text-lg text-primary font-museo'>
            {invitationStatusData?.sent ? 'Please check email' : 'Found your details connected to the below schools'}
          </p>
          {!invitationStatusData?.sent &&
            <p className='text-sm text-card-foreground'>Please select a school to continue, you will be able to connect with multiple schools once setup is complete.</p>
          }
        </DialogHeader>
        {invitationStatusData?.sent ?
          <>
            <div className='flex flex-col gap-6 text-sm text-card-foreground'>
              <p>We've sent an email to <strong>{email}</strong> from thegrowthcollective.co.nz</p>
              <p>Click the link in that email to complete Kindo account creation.</p>
            </div>
            <div className='pt-4 text-sm'>
              <p className='font-semibold'>Can't find email?</p>
              <p className='text-card-foreground'>Check Spam, Junk, and Clutter folders, or <Button variant='linkUnderline' className='px-0' onClick={() => setSendInvite(false)}>try again</Button>.</p>
            </div>
            <DialogFooter className='flex flex-col w-full sm:flex-col'>
              <Button
                variant="ghost"
                type="button"
                className='text-base font-semibold font-museo'
                onClick={() => onOpenChange(false)}
              >
                Close
              </Button>
            </DialogFooter>
          </>
          :
          <form
            className='flex flex-col'
            onSubmit={handleSubmit(handleInvite)}
          >
            <div className='pb-4'>
              <SelectField
                label={`Please select a school:`}
                control={control}
                items={(seedSchools || []).map((school) => ({
                  label: school,
                  value: school,
                }))}
                position='item-aligned'
                rules={{
                  required: `School is required`
                }}
                name="school"
              />
            </div>
            {(shopData?.status === 'valid') &&
              <>
                <div className='flex flex-col gap-6 text-sm text-card-foreground'>
                  <p>For your security we will now send you an email at <strong>{email}</strong> to confirm your identity.</p>
                  <p>Please click Continue to proceed.</p>
                </div>
              </>
            }
            <DialogFooter className='flex flex-col w-full sm:flex-col'>
              <Button
                type="submit"
                variant="default"
                className='text-base font-semibold font-museo'
              >
                Continue
              </Button>
              <Button
                variant="ghost"
                type="button"
                className='text-base font-semibold font-museo'
                onClick={() => onOpenChange(false)}
              >
                Close
              </Button>
            </DialogFooter>
          </form>
        }
      </DialogContent>
    </Dialog>
  )
});

export default HandshakeDialog