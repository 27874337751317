import React from 'react'
import Helmet from 'react-helmet'
import KindoWell from '../../common_component/KindoWell'
import { RouteProps, useParams } from 'react-router-dom'
import { Button } from '../../common_component/base/button';
import { Popover, PopoverTrigger, PopoverContent } from '../../common_component/base/popover';
import { MdCalendarMonth, MdCalendarViewWeek, MdOutlineCalendarMonth, MdOutlineTimer, MdSearch } from 'react-icons/md';
import _ from 'lodash';
import api from 'networking/api';

import MenuCategory from './MenuCategory';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import OrderDatePicker from './OrderDatePicker';
import { add, format, parseISO } from 'date-fns';
import orderUtils from 'networking/util/order';
import ProductFilters from './dialog/ProductFilters';
import { OrderBy } from 'store/ShopStore';
import { Input } from '../../common_component/base/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../common_component/base/select"
import { getDeliveryInfoUrl, getServiceImageDirectory } from 'networking/constants';
import Image from '../../common_component/Image';
import { Chip } from '../../common_component/base/chip';
import { SchoolService, Service } from 'networking/models/family';
import analytics from '../../util/googleAnalytics';
import useCountdown from '../../util/hooks/useCountdown';
import { pathify } from "../../util/util";
import useSchoolServices from 'networking/hooks/useSchoolService';

type Props = {} & RouteProps;

const orderByOptions = [
  {
    label: "Price Low-High",
    value: 'price:asc',
  },
  {
    label: "Price High-Low",
    value: 'price:desc',
  },
  {
    label: "Name A-Z",
    value: 'name:asc',
  },
  {
    label: "Name Z-A",
    value: 'name:desc',
  },
];

const ServiceTitle = observer(({ currentService, schoolService }: { currentService?: Service; schoolService?: SchoolService }) => {
  const { shop } = useStore();
  const serviceImageSrc = `${getServiceImageDirectory({ schoolPath: schoolService?.path ?? '', serviceId: currentService?.id ?? '' })}${currentService?.icon}`;
  const isDatedService = !orderUtils.isServiceUndated(currentService);
  const selectedDates = shop.selectedOrderDates.slice().sort((a, b,) => a.getTime() - b.getTime());
  const nextClosingDate = add(selectedDates[0], {});
  nextClosingDate.setHours(currentService?.closing.hour ?? 0);
  nextClosingDate.setMinutes(currentService?.closing.minute ?? 0);
  nextClosingDate.setDate(nextClosingDate.getDate() - (currentService?.closing.day ?? 0));

  const {
    diffSeconds,
    label,
  } = useCountdown({
    intervalMs: 1000,
    targetDate: nextClosingDate,
  });
  return (
    <div>
      <div className='flex items-end gap-2 pb-2'>
        <h2 data-testid="label-service-title" className="text-2xl font-semibold tracking-tight transition-colors md:text-3xl lg:text-4xl scroll-m-20 first:mt-0 font-museo">
          {currentService?.title}
        </h2>
        {
          currentService?.delivery_info && schoolService?.path && (
            <Button variant="link" size="xs" onClick={() => {
              window.open(getDeliveryInfoUrl({ schoolPath: schoolService.path, serviceId: currentService.id }), '_blank')
            }}>
              more info
            </Button>
          )
        }
        <div className='ml-auto'>
          <Image
            key={currentService?.id}
            src={serviceImageSrc}
            className='h-[32px] sm:h-[36px] md:h-[40px]' alt=''
            classNameOnLoad='border-2 rounded-lg border-border-info'
          />
        </div>
      </div>
      {
        isDatedService
        && nextClosingDate
        && diffSeconds <= 3600 // 60 minutes or less
        && (
          <KindoWell
            variant={diffSeconds > 0 ? "info" : "danger"}
            className='mt-1.5'
            icon={<MdOutlineTimer />}
            messages={[
              ...(diffSeconds > 0 ? [
                `Orders closing in ${label}${selectedDates.length > 1 ? ` for ${format(selectedDates[0], 'cccc dd MMM')}` : ''}`,
              ] : [
                `Orders have closed for ${format(selectedDates[0] ?? new Date(), 'cccc dd MMM')}`
              ])
            ]}
          />

        )
      }
    </div>
  )
});

const FilterState = observer(({ currentService }: { currentService?: Service }) => {
  const { shop } = useStore();

  const totalCount = (currentService?.mcats ?? []).reduce((sum, category) => {
    return sum + (shop.menuCategoryInfos.get(category.id)?.filteredCount ?? 0);
  }, 0);

  const showAvailableOnly = shop.currentFilters.availableOnly;
  const inStockOnly = shop.currentFilters.inStockOnly;
  return (
    <>
      {(showAvailableOnly || inStockOnly) && (
        <div className='flex items-center gap-2 px-2 pt-2 pb-2 sm:px-0 lg:pt-6'>
          {
            showAvailableOnly && (
              <Chip
                text="Show only items for selected days"
                onDelete={() => {
                  shop.setCurrentFilters({
                    ...shop.currentFilters,
                    availableOnly: false
                  })
                }}
              />
            )
          }     {
            inStockOnly && (
              <Chip
                text="Has available stock"
                onDelete={() => {
                  shop.setCurrentFilters({
                    ...shop.currentFilters,
                    inStockOnly: false
                  })
                }}
              />
            )
          }
        </div>
      )
      }
      {shop.isFiltering && (!!shop.currentFilters.searchText || totalCount === 0) && (
        <div className='flex flex-col px-2 pt-3 gap-4 sm:px-0 md:pt-6 lg:min-h-[34px]'>
          {
            shop.currentFilters.searchText && (
              <p className='text-md font-museo text-primary'>
                Results for '{shop.currentFilters.searchText}'
              </p>
            )
          }
          {totalCount === 0 && (
            <p className='pb-4'>No items found, try another filter</p>
          )}
        </div>
      )}
    </>
  )
});

const ServiceMenu = ((props: Props) => {
  const { brandName, serviceId, schoolId } = useParams();
  const store = useStore();
  const isCommunityShop = store.shop.isCommunityShop;

  const { schoolService, currentService } = useSchoolServices({
    schoolId: isCommunityShop && schoolId ? schoolId : store.family.currentSchool,
    serviceId,
  })

  const { data: menuData, isLoading: isLoadingMenu } = api.order.useFullMenu(currentService);

  const anyItemHasDietaryFlags = React.useMemo(() => {
    return menuData?.items.some((item) => (item.flags || '').trim().length > 0);
  }, [menuData?.items])
  const withDietaryRequirements = brandName === 'ezlunch' || anyItemHasDietaryFlags;
  const isServiceUndated = orderUtils.isServiceUndated(currentService);
  return (
    <>
      <Helmet>
        {
          currentService && (
            <title>{currentService.title}</title>
          )
        }
      </Helmet>
      <div className="flex flex-col gap-2 pb-8 md:gap-3 xl:gap-4 sm:mt-2">
        <ServiceTitle
          currentService={currentService}
          schoolService={schoolService}
        />
        <div className="overflow-hidden rounded-lg border-border-info border-1">
          <ServiceFilters
            currentService={currentService}
          />
          <div className='px-0 sm:pb-4 sm:px-4 md:px-6 bg-card text-card-foreground md:pb-6 min-h-[60vh]'>
            {currentService && !isLoadingMenu && (
              <FilterState currentService={currentService} />
            )}
            {currentService?.mcats.map((category) => {
              const items = menuData?.items.filter((item) => item.menuCategoryId === category.id)
              return (
                <MenuCategory
                  key={category.id}
                  category={category}
                  isLoading={isLoadingMenu}
                  withDietaryRequirements={withDietaryRequirements}
                  items={items || []}
                />
              )
            })}
          </div>
          <ProductFilters
            withDietaryRequirements={withDietaryRequirements}
            withAvailableOnly={!isServiceUndated}
          />
        </div>
      </div>
    </>
  )
})

export default ServiceMenu

const ServiceFilters = observer(({ currentService }: { currentService?: Service; }) => {
  const { shop, family } = useStore();
  const { selectedOrderDates, isFiltering } = shop;
  const availableDates = orderUtils.getAvailableDates(currentService);
  const isServiceClosed = availableDates.length === 0;

  const datedService = currentService && !orderUtils.isServiceUndated(currentService);

  const renderDatePicker = () => (
    currentService && availableDates.length > 1 && (
      <OrderDatePicker service={currentService}>
        <Button name="change-date" variant="linkUnderline" size="sm" className='px-0 font-bold whitespace-nowrap sm:px-2'>
          Change dates
          <MdOutlineCalendarMonth className='ml-2 text-lg' />
        </Button>
      </OrderDatePicker>
    )
  );
  const renderSelectedDates = () => (
    selectedOrderDates.slice().sort((a, b) => {
      return a.getTime() - b.getTime();
    }).map((date) => (
      <div
        key={+date}
        className='relative min-w-[44px] flex flex-col items-center justify-center px-1 py-1 rounded-lg text-foreground border-primary border-1 bg-plain'
      >
        <span className='absolute top-[-4px] left-[20%] h-[8px] w-[3px] rounded-lg bg-primary' />
        <span className='absolute top-[-4px] right-[20%] h-[8px] w-[3px] rounded-lg bg-primary' />
        <span className='leading-[10px] text-[10px]'>{format(date, 'ccc').toUpperCase()}</span>
        <span className='whitespace-nowrap leading-[18px]'>{format(date, 'dd')}</span>
        <span className='text-[8px] leading-[8px] whitespace-nowrap'>{format(date, 'MMM')}</span>
      </div>
    ))
  )


  React.useEffect(() => {
    if (availableDates.length > 0) {
      shop.setSelectedOrderDates([parseISO(availableDates[0])]);
    } else {
      shop.setSelectedOrderDates([]);
    }
    shop.clearFilters();
    shop.setCurrentFilters({
      ...shop.currentFilters,
      availableOnly: !!datedService,
    });
  }, [currentService]);

  React.useEffect(() => {
    if (datedService && currentService.id && !family.showMobileNav) {
      shop.setShowDatePicker(true);
    }
  }, [currentService?.id, datedService]);
  return (
    <div className='relative flex flex-col'>
      {datedService && (
        <div className="flex items-center gap-3 p-2 sm:p-4 flex-nowrap bg-background-light">
          <div className='flex items-center gap-4'>
            {!isServiceClosed && (
              <h4 className="text-sm font-bold sm:text-xl md:text-xl whitespace-nowrap font-museo">
                Ordering for
              </h4>
            )}
            <Popover>
              <PopoverTrigger asChild>

                <div
                  className='cursor-pointer relative flex items-center gap-2 max-w-[22vw] sm:max-w-[210px] overflow-hidden py-1 max-h-[400px]'
                >
                  {selectedOrderDates.length > 0 && renderSelectedDates()}
                  {selectedOrderDates.length >= 3 && (
                    <div className="absolute inset-0 cursor-pointer bg-gradient-to-r from-background-light/0 via-background-light/10 via-background-light/15 to-background-light/90"></div>
                  )}
                </div>
              </PopoverTrigger>
              <PopoverContent className='w-auto' align="start" side="bottom" sideOffset={-50}>
                <div className='flex flex-col gap-3 p-2'>
                  <div>
                    <p className='text-sm text-card-foreground'>Selected Dates</p>
                  </div>
                  <div className='flex flex-col gap-1 sm:flex-row'>
                    <div className='flex max-w-[300px] w-full flex-wrap gap-y-2 gap-x-1'>
                      {renderSelectedDates()}
                    </div>
                    {renderDatePicker()}
                  </div>
                </div>
              </PopoverContent>
            </Popover>
          </div>
          <div className='flex justify-end flex-1 sm:flex-none'>
            {renderDatePicker()}
          </div>
        </div>
      )}
      <div className='relative'>
        <div className='flex flex-col gap-2 px-2 py-2 sm:pt-4 sm:gap-4 sm:flex-row bg-card lg:pt-6 sm:px-4 md:px-6 lg:absolute lg:right-0'>
          <div className='w-full sm:w-auto'>
            <Input
              name="searchText"
              className='sm:max-w-[256px]'
              placeholder='Search for an item'
              value={shop.currentFilters.searchText}
              endAdornment={<MdSearch className="w-4 h-4" />}
              onChange={(e) => {
                shop.setSearchText(e.target.value);
              }} />
          </div>
          <div className='flex justify-end flex-1 gap-4 lg:flex-none'>
            <Select
              value={shop.orderBy}
              onValueChange={(value) => {
                shop.setOrderBy(value as OrderBy);
                analytics.triggerClick({
                  eventName: 'order_by',
                  parameters: {
                    order_by: value,
                  }
                })
              }}
            >
              <SelectTrigger name="sort-by" hideIcon variant="linkUnderline" className="w-auto px-0 font-bold whitespace-nowrap sm:px-2">
                <SelectValue placeholder="Sort by" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup id="orderBy">
                  <SelectItem value="default" textValue='Sort by' disabled={!shop.orderBy}>
                    {shop.orderBy && shop.orderBy !== 'default' ? 'Default' : 'Sort by'}
                  </SelectItem>
                  {orderByOptions.map((item) => (
                    <SelectItem key={item.value} value={item.value}>
                      {item.label}
                    </SelectItem>
                  ))}
                </SelectGroup>
              </SelectContent>
            </Select>
            <Button
              className='whitespace-nowrap h-[34px] font-bold px-0 sm:px-2'
              variant="linkUnderline"
              size="sm"
              onClick={() => {
                shop.setShowFilters(true);
                analytics.triggerClick({ eventName: 'filter_click' })
              }}
            >
              Filter by
            </Button>
          </div>
        </div>

      </div>
    </div>
  )
});