import api from 'networking/api';
import { Link } from 'react-router-dom'
import React from 'react'
import { useStore } from '../../../store/store';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../../../common_component/base/card'
import { MenuCategoryPicture } from '../EzlunchServices'
import { cn } from '../../../util/tailwind'
import { Button } from '../../../common_component/base/button';
import useSchoolServices from 'networking/hooks/useSchoolService';

type Props = {}

const Ezlunch = (props: Props) => {
  const [ezlunchIndex, setEzlunchIndex] = React.useState(-1);
  const { family: { currentSchool } } = useStore();

  const {
    ezlunchServices,
    isLoadingServices,
  } = useSchoolServices({
    schoolId: currentSchool,
  })

  const goNextEzlunch = () => {
    setEzlunchIndex((index) => {
      const cyclingIndex = (index + 1 >= ezlunchServices.length) ? 0 : index + 1;
      return cyclingIndex;
    });
  }
  React.useEffect(() => {
    if (!isLoadingServices && ezlunchServices.length > 0) {
      setEzlunchIndex(0);
      const interval = setInterval(() => {
        goNextEzlunch();
      }, 6000);

      return () => {
        clearInterval(interval);
      }
    }
  }, [isLoadingServices]);
  return (
    <div>
      {ezlunchServices.length > 0 && ezlunchServices[ezlunchIndex] && (
        <Card className='border-none rounded-lg sm:rounded-3xl'>
          <CardHeader className='flex flex-col justify-between pb-4 sm:flex-row sm:pb-6 sm:pt-10'>
            <div className='flex flex-col gap-2 md:gap-11 md:flex-row'>
              <CardTitle className='text-2xl font-normal font-museo text-primary'>ezlunch</CardTitle>
              <div className='flex flex-col flex-wrap items-center w-full gap-2 sm:w-auto text-ellipsis whitespace-nowrap sm:flex-row'>
                {
                  ezlunchServices.length > 1 && ezlunchServices.map((service, index) => (
                    <Button
                      asChild
                      size="sm"
                      variant="outline"
                      className={cn('w-full border-primary/20 transition-all sm:w-auto', {
                        'border-primary': index === ezlunchIndex,
                      })}
                    >
                      <Link to={`/app/shop/ezlunch/${service.id}`}>
                        {service.title}
                      </Link>
                    </Button>
                  ))
                }
                <Button size="sm" className='w-full sm:hidden' asChild>
                  <Link to={ezlunchServices.length > 1 ? `/app/shop/ezlunch` : `/app/shop/ezlunch/${ezlunchServices[0].id}`}>
                    {
                      ezlunchServices.length > 1 ? 'See all menus' : 'See full menu'
                    }
                  </Link>
                </Button>
              </div>
            </div>
          </CardHeader>
          <Link to={ezlunchServices.length > 1 ? `/app/shop/ezlunch/${ezlunchServices[ezlunchIndex].id}` : `/app/shop/ezlunch/${ezlunchServices[0].id}`}>
            <CardContent>
              <div className='grid grid-flow-col relative h-[150px] sm:h-[180px]'>
                {
                  ezlunchServices.map((service, index) => {
                    const isActive = index === ezlunchIndex;
                    return (
                      <div key={service.id} className={cn('w-full grid grid-flow-col grid-cols-2 sm:grid-cols-4 transition-opacity absolute left-0 right-0 top-0 bottom-0 opacity-0', {
                        'opacity-100 ease-in-out duration-1000': isActive,
                        'ease-in-out duration-1000': !isActive
                      })}>
                        <MenuCategoryPicture key={service.id} service={service} maxItems={4} />
                      </div>
                    )
                  })
                }
              </div>
            </CardContent>
          </Link>
          <CardFooter className='justify-end hidden sm:flex'>
            <Button asChild>
              <Link to={ezlunchServices.length > 1 ? `/app/shop/ezlunch` : `/app/shop/ezlunch/${ezlunchServices[0].id}`}>
                {
                  ezlunchServices.length > 1 ? 'See all menus' : 'See full menu'
                }
              </Link>
            </Button>
          </CardFooter>
        </Card>
      )
      }
    </div>
  )
}

export default Ezlunch