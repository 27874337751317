import React from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { Button } from 'components/base/button';
import InputField from 'components/form/InputField';
import { MdOpenInNew, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { TaintedPattern } from 'util/tainted_regexp';
import CheckboxField from 'components/form/CheckboxField';
import { privacyTerms } from 'networking/constants';
import PasswordRequirements from 'components/PasswordRequirements';
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import ProfferResult from '../ProfferResult';

type Props = {
  email: string;
  profferId: string;
  schoolIds: string[];
}

type FormProps = {
  password: string;
  agreeToTerms: boolean;
};
const Uplift = (props: Props) => {
  const { email, profferId, schoolIds } = props;
  const navigate = useNavigate();
  const [isSuccessful, setIsSuccessful] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const { control, handleSubmit, watch } = useForm<FormProps>({
    defaultValues: {
    }
  });
  const password = watch('password');

  const { trigger, isMutating, error } = useSWRMutation('/proffer/uplift-account', (key, { arg }: { arg: FormProps }) => {
    try {
      return api.proffer.upliftAccount({
        password: arg.password,
        profferId: profferId,
      }).then(async (response) => {
        // Check if response is profferResponse 
        if (response.success) {
          setIsSuccessful(true);
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })

  const onSubmit = async (data: FormProps) => {
    if (data.agreeToTerms) {
      await trigger({
        ...data,
      })
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  };

  if (isSuccessful) {
    return (
      <ProfferResult
        title="Account transfer complete"
        description='You may now login to your Kindo account using your email and password.'
        cta={{
          label: 'Login',
          onClick: () => {
            navigate('/app/login');
          }
        }}
      />
    )
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
      <p className='text-lg font-bold text-primary font-museo'>
        {schoolIds[0] || 'Your school'} wants to transfer ownership of your Kindo account to you.
      </p>
      <p className='text-sm text-text-body'>
        Enter the password you’ll use when logging in to complete Kindo account ownership transfer.
      </p>

      <div>

        <p className='text-sm text-text-body'>
          Email: {email}
        </p>
      </div>

      <div className='w-full sm:w-[350px]'>
        <InputField
          className=''
          label="Password"
          control={control}
          autoComplete='off'
          type={showPassword ? "text" : "password"}
          rules={{
            required: 'Enter a password',
            minLength: {
              value: 8,
              message: 'Password must be at least 8 characters long'
            },
            validate: {
              hasNumber: (value) => TaintedPattern.numbersRegex.test(value) || 'Password must contain at least one number',
              hasLowercase: (value) => TaintedPattern.lowercaseRegex.test(value) || 'Password must contain at least one lowercase letter',
              hasUppercase: (value) => TaintedPattern.uppercaseRegex.test(value) || 'Password must contain at least one uppercase letter',
              hasSpecialCharacter: (value) => TaintedPattern.specialCharactersRegex.test(value) || 'Password must contain at least one of these !@#$%^&* special characters',
            }
          }}
          endAdornment={
            <button type="button" className="" onClick={togglePasswordVisibility}>
              {
                showPassword ? (
                  <MdVisibility className="w-4 h-4 text-primary" />
                ) : (
                  <MdVisibilityOff className="w-4 h-4 text-primary" />
                )
              }
            </button>
          }
          name="password"
        />

        <PasswordRequirements
          password={password}
        />
      </div>

      <div className='flex flex-row items-center gap-1'>
        <CheckboxField
          control={control}
          name="agreeToTerms"
          label={(
            <span>
              I agree to the <Link to={privacyTerms} target="_blank">
                <Button
                  className='px-0'
                  variant="linkUnderline"
                  type="button"
                  size="xs"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  Terms and Conditions <MdOpenInNew className='w-4 h-4 ml-1' />
                </Button>
              </Link>
            </span>
          )}
          rules={{
            required: 'You must agree to the terms and conditions',
          }}
        />

      </div>
      <div className='flex flex-col gap-4 pt-2'>
        {
          error && (
            <p className='text-sm text-destructive'>
              Something went wrong, please try again
            </p>
          )
        }
        <Button
          className='w-full md:w-[160px]'
          type="submit"
          disabled={isMutating}
        >
          Complete transfer
        </Button>
      </div>
    </form>
  )
}

export default Uplift