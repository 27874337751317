import React from 'react';
import { Controller, useWatch, type UseControllerProps } from 'react-hook-form';
import { Input } from '../base/input';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../base/select";
import { Label } from '../base/label';
import { TaintedPattern } from 'util/tainted_regexp';

export const landlineCodes = [
  { label: '09', value: '09-' },
  { label: '07', value: '07-' },
  { label: '06', value: '06-' },
  { label: '04', value: '04-' },
  { label: '03', value: '03-' },
];

interface PhoneInputFieldProps extends UseControllerProps<any> {
  inputPlaceholder?: string;
  selectPlaceholder?: string;
  selectRules?: any;
  setValue: (name: string, value: any) => void;  // Accept setValue as a prop
  getValues: () => void;  // Accept setValue as a prop
}

const PhoneInputField: React.FC<PhoneInputFieldProps> = ({
  name,
  control,
  inputPlaceholder,
  selectPlaceholder,
  selectRules,
  setValue,  // Destructure setValue here
  getValues,
  ...props
}) => {
  const selectedType = useWatch({
    control,
    name: `${name}_type`,
    defaultValue: 'mobile',
  });

  return (
    <div className='flex flex-col gap-1'>
      <Label>
        Contact phone type
      </Label>
      <Controller
        name={`${name}_type`}
        control={control}
        defaultValue="mobile"
        render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
          <div className='mb-2'>
            <Select
              value={value}
              onValueChange={(value) => onChange(value)}
            >
              <SelectTrigger onBlur={onBlur}>
                <SelectValue placeholder="Select type" />
              </SelectTrigger>
              <SelectContent>
                <SelectGroup>
                  <SelectItem value="mobile">Mobile</SelectItem>
                  <SelectItem value="landline">Landline</SelectItem>
                </SelectGroup>
              </SelectContent>
            </Select>
          </div>
        )}
      />
      <Label>
        <span className='capitalize'>{selectedType}</span> number
      </Label>
      <div className='grid items-end w-full grid-cols-3 gap-2'>
        {selectedType === 'landline' && (
          <Controller
            name={`${name}_code`}
            control={control}
            rules={selectRules}
            render={({ field: { value, onChange, name, onBlur }, fieldState: { error } }) => (
              <div>
                {error && (
                  <p className="mb-1 text-sm font-semibold text-destructive">
                    {error.message}
                  </p>
                )}
                <Select
                  value={value}
                  onValueChange={onChange}
                >
                  <SelectTrigger variant={error ? 'error' : ''} onBlur={onBlur}>
                    <SelectValue placeholder={selectPlaceholder} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup id={name}>
                      {landlineCodes.map((item) => (
                        <SelectItem key={item.value} value={item.value}>
                          {item.label}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            )}
          />
        )}

        <div className={selectedType === 'landline' ? 'col-span-2' : 'col-span-3'}>
          <Controller
            name={`${name}_number`}
            control={control}
            rules={{
              required: 'Contact number is required',
              pattern: {
                value: TaintedPattern.telephoneNumberOnly,
                message: 'Invalid contact number',
              },
            }}
            render={({ field: { value, onChange, onBlur }, fieldState: { error } }) => (
              <div>
                <Input
                  {...props}
                  placeholder={inputPlaceholder}
                  value={value}
                  label=" "
                  onChange={(e) => {
                    let finalValue = e.target.value;
                    const code = getValues(`${name}_code`);

                    if (selectedType === 'landline') {
                      finalValue = `${code}${finalValue}`;
                    } else {
                      finalValue = `mob-${finalValue}`;
                    }

                    // Set the combined value in the main field
                    setValue(name, finalValue);
                    onChange(e); // Trigger input change event
                  }}
                  onBlur={onBlur}
                  error={error?.message}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PhoneInputField;
