import React from 'react'
import { MdCheck } from 'react-icons/md';

import KindoWell from "../../../common_component/KindoWell";
import { Spinner } from '../../../common_component/base/spinner';
import useDeferredTask from 'networking/hooks/useDeferredTask';
import { BindPayableRef } from 'networking/models/admin';

export type PayableApplyTaskProps = {
  isPreview?: boolean;
  deferredTaskId: string;
  inProgressMessages: string[] | React.ReactNode[];
  successMessages: string[];
  errorMessages: string[];
  onDismiss?: () => void;
}

const PayableApplyTask = (props: PayableApplyTaskProps) => {
  const { isPreview, deferredTaskId, inProgressMessages, successMessages, errorMessages, onDismiss } = props;
  const { data: deferredTaskData, isLoading, error } = useDeferredTask<BindPayableRef>(deferredTaskId);

  const hasError = !!error || (!isLoading && deferredTaskData?.results?.rtype === 'exception');

  const taskError = deferredTaskData?.results?.rtype === 'exception' ? deferredTaskData?.results.message || deferredTaskData.results.public_message : null; // More detailed error message
  const getKnownError = () => {
    if (deferredTaskData?.results?.rtype === 'exception') {
      switch (deferredTaskData?.results?.class) {
        case 'BindPayableExistsException': {
          return 'A binding already exists';
        }
      }
    }
  }
  const knownError = getKnownError();
  return (
    <>
      {
        !hasError && deferredTaskData && (
          <KindoWell
            messages={[
              ...(deferredTaskData.has_run ? [
                ...successMessages,
                ...(deferredTaskData.results?.rtype === 'bind_payable_ref' && deferredTaskData.results.apply_result ? [
                  `${deferredTaskData.results.apply_result.preview ? 'It would be applied to ' : 'It is applied to '} ${deferredTaskData.results.apply_result?.student_count} students`,
                ] : []),
              ] : inProgressMessages),
            ]}
            variant={deferredTaskData.has_run && !isPreview ? 'success' : 'info'}
            icon={deferredTaskData.has_run ? <MdCheck /> : <Spinner size="xs" />}
            {...(!isLoading && onDismiss ? {
              action: {
                label: 'Dismiss',
                onClick: onDismiss,
              }
            } : {})}
          />
        )
      }
      {
        hasError && (
          <KindoWell
            messages={[
              ...errorMessages,
              ...(taskError ? [taskError] : []),
              ...(knownError ? [knownError] : []),
            ]}
            variant="danger"
            {
            ...(onDismiss ? {
              action: {
                label: 'Dismiss',
                onClick: onDismiss,
              }
            } : {})
            }
          />
        )
      }
    </>
  )
}

export default PayableApplyTask