export const TaintedPattern = {
  dollarDecimal: /^[0-9]+(\.[0-9]{1,2})?$/,

  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  telephoneNumber: /^[0-9 /\\-mob]{0,20}$|^$/,
  telephoneNumberOnly: /^[0-9 ]{0,15}$/,
  telephoneNumberExt: /^[0-9 ]{0,8}$/,
  contactName: /^[a-zA-Z' _()\\.\\-]{1,41}$|^$/,

  firstName: /^[A-Za-z'\-_ .]{1,255}$/,
  lastName: /^[A-Za-z'\-_ .]{1,255}$/,

  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,

  closedAccountEmail: /^CLOSED_.*@(thegrowthcollective|tgcl).co.nz$/,

  jsonExtensionRegex: /\.json$/,

  // Password
  numbersRegex: /\d/,
  lowercaseRegex: /[a-z]/,
  uppercaseRegex: /[A-Z]/,
  specialCharactersRegex: /[!@#$%^&*]/,
  minLengthRegex: /.{8,}/,

  // Bank account
  bankAccountRegex: /^\d{2}-\d{4}-\d{7}-(\d{3}|\d{2})$/,
};
