import React from 'react'
import Helmet from 'react-helmet'
import { useForm } from "react-hook-form";
import InputField from '../../common_component/form/InputField';
import { Button } from '../../common_component/base/button'
import LoginImage from '../../assets/images/login-image.png';
import LoginImageMobile from '../../assets/images/login-image-mobile.png'
import Image from '../../common_component/Image';
import { useStore } from '../../store/store';
import api from 'networking/api';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MdVisibility, MdVisibilityOff } from "react-icons/md"
import AppDownloadBanner from '../../common_component/layout/components/AppDownloadBanner';
import { cn } from 'util/tailwind';
import { isTablet, isMobile } from 'react-device-detect';
import { observer } from 'mobx-react';

type FormData = {
  email: string;
  password: string;
};

const LoginPage = observer(() => {
  const store = useStore();
  const navigate = useNavigate();
  const location = useLocation();
  const isRenderingFromNativeApp = store.family.isRenderingFromNativeApp
  const [error, setError] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false)
  const [params] = useSearchParams();

  const { control, handleSubmit, formState: { isSubmitting } } = useForm<FormData>({
    defaultValues: {
      email: params.get('email') || '',
      password: params.get('rnd') || ''
    },
  });

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword)
  };

  const handleLogin = async (values: FormData) => {
    const response = await api.family.login({
      email: values.email,
      password: values.password
    });
    if (response.ok) {
      try {
        const body = await response.text();
        const invalidUserNameOrPassword = body.includes('Email address was not found or password was incorrect');
        const attemptsExceeded = body.includes('maximum number of login attempts has been exceeded');
        if (invalidUserNameOrPassword) {
          setError('Invalid email or password');
        } else if (attemptsExceeded) {
          setError('Maximum number of login attempts has been exceeded. Please reset your password or wait 1 day');
        } else {
          store.family.setIsAuthenticated(true);
          goToLoginLoading();
        }
      } catch (e) {
        setError('An unexpected error occurred');
      }
    } else {
      setError('Invalid email or password');
    }
  }

  const goToLoginLoading = () => {
    navigate(`/app/login-loading${location.search}`, { replace: true });
  }

  React.useEffect(() => {
    // Check if user is already authenticated
    if (store.family.isAuthenticated) {
      goToLoginLoading();
    }
  }, [store.family.isAuthenticated]);

  React.useEffect(() => {
    // Check if register param is true, then open dialog. This will be triggered from app
    if (isRenderingFromNativeApp) {
      store.family.setShowFlowJoinDialog(true);
    }
  }, [isRenderingFromNativeApp]);

  React.useEffect(() => {
    // Don't need to show login dialog if already on login page
    if (store.family.showLoginDialog) {
      store.family.setShowLoginDialog(false);
    }
  }, [store.family.showLoginDialog]);

  return (
    <div className='p-2 sm:p-4'>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <AppDownloadBanner />
      <div
        className={cn('pt-6 pb-0 text-center sm:text-left sm:pt-0', {
          [`mt-20`]: store.shop.showDownloadAppBanner && (isTablet || isMobile),
        })}
      >
        <span className='text-2xl font-museo'>
          <span className='text-primary'>my</span>
          <span className='text-secondary'>kindo</span>
        </span>
      </div>
      <div className='flex flex-col items-center justify-center'>
        <div className='w-full sm:w-[60%] lg:w-[35%] relative top-2 hidden sm:block'>
          <Image
            className='object-contain'
            src={LoginImage}
            alt="login"
          />
        </div>
        <div className='flex justify-center w-60 sm:hidden'>
          <Image
            className='object-contain'
            src={LoginImageMobile}
            alt="login"
          />
        </div>
        <div className='flex flex-col justify-center gap-6 px-4 sm:px-10 py-6 sm:py-10 w-full sm:w-[45%] md:w-[55%] lg:w-[32%] bg-white rounded-3xl'>
          <div>
            <p className='text-xl font-semibold font-museo'>Sign in to mykindo</p>
            <p className='text-sm text-card-foreground font-museo'>Get School Stuff Sorted</p>
          </div>
          <form
            className='flex flex-col gap-6'
            onSubmit={handleSubmit(handleLogin)}
          >
            <InputField
              label="Email address"
              autoFocus
              autoComplete='off'
              control={control}
              rules={{
                required: 'This field is required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: 'Please enter a valid email address',
                },
              }}
              name="email"
            />

            <div>
              <InputField
                label="Password"
                control={control}
                autoComplete='off'
                type={showPassword ? "text" : "password"}
                rules={{
                  required: 'This field is required'
                }}
                endAdornment={
                  <button type="button" className="" onClick={togglePasswordVisibility}>
                    {
                      showPassword ? (
                        <MdVisibility className="w-4 h-4 text-primary" />
                      ) : (
                        <MdVisibilityOff className="w-4 h-4 text-primary" />
                      )
                    }
                  </button>
                }
                name="password"
              />
            </div>
            {
              error &&
              <p className='text-sm font-semibold text-destructive'>
                {error}
              </p>
            }
            <div className='flex flex-col gap-2'>
              <Button
                disabled={isSubmitting}
                size="lg"
                type="submit"
                className='font-semibold font-museo'
              >
                Sign in
              </Button>
              <Button
                type="button"
                size="lg"
                variant="linkUnderline"
                onClick={() => {
                  store.family.setShowForgotPasswordDialog(true);
                }}
              >
                Forgot your password?
              </Button>
            </div>
          </form>
        </div>
        <div className='flex flex-col items-center gap-0 pt-4 text-sm sm:flex-row sm:gap-2'>
          <p className='text-card-foreground'>Don't have an account?</p>
          <Button
            type="button"
            size="lg"
            variant="linkUnderline"
            className='h-6 p-0 sm:h-auto'
            onClick={() => {
              store.family.setShowFlowJoinDialog(true)
            }}
          >
            Sign up for free
          </Button>
        </div>
      </div>
    </div>

  )
})

export default LoginPage