import React from 'react'
import { useParams } from 'react-router-dom'
import api from 'networking/api';
import { observer } from 'mobx-react';
import Image from '../../Image';
import { pathify } from "../../../util/util";
import WelcomeImageDesktop from '../../../assets/images/welcome-img-desktop.png';
import WelcomeImageMobile from '../../../assets/images/welcome-img-mobile.png';
import WelcomeImageDefault from '../../../assets/images/hero-image.png'
import { Skeleton } from '../../../common_component/base/skeleton'

const SingleServiceHeader = observer(() => {
  const { schoolId } = useParams();
  const pathifiedSchoolId = pathify(schoolId!)
  const { data: schoolData } = api.misc.useSchoolData(pathifiedSchoolId);
  const { data: welcomeImageUrl } = api.family.useSwi(schoolData?.path, false);
  const { data: schoolContactDetail, isLoading: isLoadingSchoolContact } = api.school.useSchoolContactDetail(schoolId!);

  return (
    <div className='grid grid-cols-1 sm:grid-cols-3 text-center sm:text-left gap-2 bg-background py-6 px-4 lg:pr-[40px] xl:pr-[150px] md:pl-4 lg:pl-[80px] xl:pl-[130px] 2xl:pl-[230px]'>
      <div className='flex flex-col col-span-2 gap-4 py-4'>
        <p className='text-xl font-semibold font-museo'>
          {schoolId} Community Shop
        </p>
        <div className='flex items-center justify-center py-4 sm:hidden'>
          {welcomeImageUrl ?
            <>
              <div className='absolute w-full px-4 top-50'>
                <Image
                  className='object-cover w-full pointer-events-none'
                  alt=''
                  src={WelcomeImageMobile}
                />
              </div>
              <Image
                className='object-cover z-10 h-[145px] pointer-events-none w-[236px] rounded-lg border-4 border-white bg-white shadow-left-center'
                alt=''
                src={`${welcomeImageUrl}`}
              />
            </>
            :
            <Image
              className='object-cover pointer-events-none h-[170px] w-[240px] rounded-3xl'
              alt=''
              src={`${WelcomeImageDefault}`}
            />
          }
        </div>
        {
          isLoadingSchoolContact ?
            <Skeleton className='w-1/2 h-1/2' />
            :
            <p className='text-sm text-card-foreground'>Supporting <a className={schoolContactDetail?.website_url ? 'text-primary underline font-semibold' : 'text-card-foreground pointer-events-none'} href={schoolContactDetail?.website_url}>{schoolId}</a></p>
        }
      </div>
      <div className='items-center justify-end hidden sm:flex'>
        {welcomeImageUrl ?
          <>
            <div className='absolute top-50 w-[29%] px-4'>
              <Image
                className='object-cover w-full pointer-events-none'
                alt=''
                src={WelcomeImageDesktop}
              />
            </div>
            <Image
              className='object-cover mr-24 z-10 h-[145px] pointer-events-none w-[236px] rounded-lg border-4 border-white bg-white shadow-left-center'
              alt=''
              src={`${welcomeImageUrl}`}
            />
          </>
          :
          <Image
            key={WelcomeImageDefault}
            className='object-contain pointer-events-none h-[180px] w-full'
            alt=''
            src={`${WelcomeImageDefault}`}
          />
        }
      </div>
    </div>
  )
});



export default SingleServiceHeader
