import { DeferableResponse, MutationResponse } from "./common";
import { Question } from "./order";

export interface ListPayablesResponse {
  rtype: string;
  products: ProductWithPayable[];
  default_product_type: string;
  product_questions: {
    [permanentId: string]: Question[];
  };
  supplier_reftype_meta?: string;
  school_supplier_payable_protocol: string;
}
export interface CreatePayableRequest {
  before: ProductWithPayable[],
  after: ProductWithPayable[],
  product_question_before: { [permanentId: string]: Question[] },
  product_question_after: { [permanentId: string]: Question[] },
}

export interface CreatePayableResponse {
  deleted: Array<string | {
    status: string;
    message: string;
  }>;
  edited: string[];
  inserted: string[];
  schools_published: string[];
  supplier_products: {
    categories: string[];
    default_product_type: string;
    product_questions: { [permanentId: string]: Question[] };
    products: ProductWithPayable[];
    rtype: 'supplier_products';
    school_supplier_payable_protocol: string;
    supplier_reftype_meta?: string;
  };
  unchanged: string[];
}

export enum PayableStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DRAFT = 'draft',
}

export const PayableStatusLabels = {
  [PayableStatus.ACTIVE]: 'Current',
  [PayableStatus.INACTIVE]: 'Historic',
  [PayableStatus.DRAFT]: 'Draft',
}
export interface ProductWithPayable {
  product_id: string;
  current: boolean;
  supplier: string;
  product: string;
  menu_category: string;
  price_in_cents: number;
  detail: string;
  gluten_free: boolean;
  dairy_free: boolean;
  vegetarian: boolean;
  halal: boolean;
  consignment: string;
  menu_priority: number;
  option_price_in_cents: number;
  included_option_count: number;
  gst: string;
  remarks1: string;
  remarks2?: string;
  picture?: string;
  permanent_id: string;
  delivery_address: string;
  member_id: boolean;
  detail_confirmation: boolean;
  supplier_ref?: string;
  supplier_ref_type?: string;
  flexi_price_enabled: boolean;
  summary?: string;
  description: string;
  is_donation: boolean;
  item_count: number;
  promote: number;
  status: PayableStatus;
  pcat?: string;
  max_instances?: number;
  is_voluntary?: boolean;
  year?: string;
  rollover?: 'yes' | 'no';
  rollover_from?: string;
  label?: string;
  proto_payable_id?: string;
}
export interface RolloverRulesData {
  bind_payable_id: string;
  comment: string | null;
  enabled: boolean;
  fee_type: string | null;
  group_identifier: string;
  metagroup: string;
  payable_by: string | null;
  proto_payable_id: string;
  proto_payable_name: string;
  school_id: string;
  pcat: string;
}

export interface RollOverRuleRequest {
  school_id: string;
  rtype: string;
  proto_payable_id: string;
  metagroup: string;
  group_identifier: string;
  policy: string;
  defer: string;
  rollover: string;
}
export interface RollOverRuleResult {
  rtype: 'bind_payable_ref';
  bind_payable_id: string;
  apply_result: ApplyResult;
  status: boolean;
  message: string;
}
export interface RollOverRuleResponse extends MutationResponse<DeferableResponse | RollOverRuleResult> { }
export interface ApplyResult {
  rtype: string;
  student_count: number;
  preview: boolean;
}