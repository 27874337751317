import React from 'react'

import {
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "../../common_component/base/table"
import { MdWallet } from 'react-icons/md'
import { formatCentsPriceForDisplay } from 'util/string_util';

type Props = {
  kindoWalletBalance: number;
  withdrawableAmount: number;
  withdrawalFee?: number;
  availableForWithdrawal?: number;
}

const WalletBalance = (props: Props) => {
  const { kindoWalletBalance, withdrawableAmount, withdrawalFee, availableForWithdrawal } = props;
  return (
    <div className='border-2 rounded-lg border-primary-light w-full sm:w-[400px] overflow-hidden pb-2'>
      <div className='flex flex-row items-center justify-between px-4 py-3 overflow-hidden bg-background-light'>
        <div className='flex flex-row items-center gap-2'>
          <MdWallet className='w-6 h-6 text-primary' />
          <p className='text-sm text-primary font-museo'>
            Kindo Wallet balance:
          </p>
        </div>
        <p className='text-sm font-museo text-primary'>
          {formatCentsPriceForDisplay(kindoWalletBalance)}
        </p>
      </div>
      <div className='pt-2'>
        <Table className='w-full'>
          <TableBody>
            <TableRow className='border-b-0 hover:bg-transparent'>
              <TableCell className='py-1 text-left text-text-body'>
                Withdrawable amount
              </TableCell>
              <TableCell className='py-1 text-right text-text-body'>
                {formatCentsPriceForDisplay(withdrawableAmount)}
              </TableCell>
            </TableRow>
            {
              withdrawalFee && withdrawalFee > 0 ? (
                <TableRow className='hover:bg-transparent'>
                  <TableCell className='py-1 text-left text-text-body'>
                    Withdrawal fee
                  </TableCell>
                  <TableCell className='py-1 text-right text-text-body'>
                    -{formatCentsPriceForDisplay(withdrawalFee)}
                  </TableCell>
                </TableRow>
              ) : undefined
            }
          </TableBody>
        </Table>

      </div>
    </div>
  )
}

export default WalletBalance