import useSWR, { useSWRConfig } from 'swr';
import { baseApi, baseUrl } from './constants';
import { SchoolContactDetail, Schools } from './models/school';
import { KindoNetworkError } from './models/common';

const school = {
  // Queries (starts with "use")
  useSchoolContactDetail: (school: string) => {
    const data = useSWR<SchoolContactDetail, KindoNetworkError>(`${baseApi}/school/${school}/contact_detail`);
    return data;
  },

  useSchools: () => {
    const data = useSWR<Schools[], KindoNetworkError>(`${baseApi}/schools`);
    return data;
  },

  useSchoolsDetails: () => {
    const params = new URLSearchParams({
      client: "family_detail_editor",
      room_status: "true",
    }).toString();
    const data = useSWR<Schools[], KindoNetworkError>(`${baseApi}/schools?${params}`);
    return data;
  },

  // Mutations

}

export default school;