import React from 'react'
import Helmet from 'react-helmet'
import { baseUrl } from 'networking/constants'
import { useStore } from '../../../store/store'
import Breadcrumbs from '../../../common_component/layout/components/Breadcrumbs'
import { rootBreadcrumbItems } from '../AdminPage'

type Props = {}
const PosPage = (props: Props) => {
  const store = useStore();
  const currentSchool = store.admin.currentSchool;
  return (
    <div>
      <Helmet>
        <title>Point of Sale</title>
      </Helmet>
      <div className='pb-4'>
        <Breadcrumbs
          items={[
            ...(rootBreadcrumbItems),
            { label: 'Point of Sale', to: '/app/admin/point-of-sale', active: true },
          ]}
        />
        <p className='text-sm'>
          {currentSchool}
        </p>
      </div>
      <div className='overflow-hidden rounded-md'>
        <div className='min-h-90vh'>
          <iframe
            src={`${baseUrl}student_pos_content.shtml?school=${currentSchool}`}
            frameBorder="0"
            width="100%"
            height={"1050px"}
          />
        </div>
      </div>
    </div>
  )
}

export default PosPage