import React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { unstable_usePrompt as usePrompt, useNavigate, useParams, Link } from "react-router-dom";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import _, { } from "lodash";

import { useStore } from "../../../store/store";
import api from "networking/api";
import { Card, CardContent } from "../../../common_component/base/card";
import ComboBoxField from "../../../common_component/form/ComboBoxField";
import { Button } from "../../../common_component/base/button";
import InputField from "../../../common_component/form/InputField";
import { useToast } from "../../../common_component/base/use-toast";
import { SortableContext } from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import {
  restrictToVerticalAxis,
  restrictToParentElement
} from '@dnd-kit/modifiers'

import EditorField from "components/form/EditorField";
import TextField from "components/form/TextField";
import FormSection from "components/layout/formSection/FormSection";
import CheckboxField from "components/form/CheckboxField";
import SelectField from "components/form/SelectField";
import FormSectionContent from "components/layout/formSection/FormSectionContent";
import { MdAdd, MdArrowBack, MdAttachMoney, MdDelete, MdDragHandle, MdOpenInNew } from "react-icons/md";
import CreateableComboBoxField from "components/form/CreateableComboBoxField";
import { Question } from "networking/models/order";
import SortableItem from "./SortableItem";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "components/base/table";
import useAdminPayables from "networking/hooks/useAdminPayables";
import { PayableStatus } from "networking/models/payable";
import PayableCategoryFormDialog from "../PayableEditPage/PayableCategoryDialog";
import { b64encode, b64decode } from "util/data/base64_utf8_tools";
import SwitchField from "components/form/SwitchField";
import { Spinner } from "components/base/spinner";
import { useBanner } from "components/Banner";
import { mutate } from "swr";
import { useContentDialog } from "components/dialog/RootContentDialog";
import { isTgclAdmin } from "util/permissions";
type FormData = {
  protoPayableId?: string;
  year: string;
  maxInstances: string;
  pcat: string; // payable category name
  name: string;
  label: string;
  description?: string;
  remarks1: string;
  remarks2: string;
  gst: string;
  price: string;
  is_voluntary: boolean;
  flexi_price_enabled: boolean;
  is_donation: boolean;
  detail: string;
  questions: Question[];
  product_id: string;
}

const currentYear = new Date().getFullYear().toString();
const YEARS = Array.from({ length: 8 }, (_, i) => {
  const year = (new Date().getFullYear() + 1 - i).toString();
  return { label: year, value: year };
});
const MAX_INSTANCES_OPTIONS = [
  {
    label: 'Unlimited',
    value: '',
  },
  ...(Array.from({ length: 10 }, (_, i) => i + 1)).map((i) => ({
    label: i.toString(),
    value: i.toString(),
  })),
];
/**
 * Page for editing and creating proto payables.
 */
const PayableFormPage = observer(() => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const store = useStore();
  const { permanentId } = useParams();
  const { showBanner } = useBanner();
  const [showPayableCategoryForm, setShowPayableCategoryForm] = React.useState(false);
  const [newPayableCategoryName, setNewPayableCategoryName] = React.useState('');

  const modifiers = [restrictToVerticalAxis, restrictToParentElement]
  const currentSchool = store.admin.currentSchool;
  const { showDialog, closeDialog } = useContentDialog()

  const { data: payablesData,
    isLoading,
    payable,
    payableQuestions,
    supplierId,
    getNewPermanentId,
    refreshPayables,
    createAndUpdatePayables,
  } = useAdminPayables({
    schoolId: currentSchool,
    payablePermanentId: permanentId,
  });
  const isExistingProduct = !!permanentId;

  const { control, watch, reset, setValue, handleSubmit, formState: { isSubmitting, errors, isDirty }, setError } = useForm<FormData>({
    defaultValues: {
      gst: 'GST',
      flexi_price_enabled: true,
      year: currentYear,
      product_id: '',
      is_donation: false,
      maxInstances: '1',
      is_voluntary: true,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { fields: questions, append: appendQuestion, remove: removeQuestion, move: moveQuestion } = useFieldArray({
    control,
    name: 'questions',
  });
  usePrompt({
    when: isDirty,
    message: 'You have unsaved changes. Are you sure you want to leave?',
  })

  const formValues = watch();
  const { data: payableCategoriesData, isLoading: isLoadingPayableCategories, mutate: refetchPayableCategories, isValidating: isValidatingPayableCategories } = api.admin.usePayableCategories(currentSchool);

  const onSubmit = (status: PayableStatus, skipDonationCheck = false) => async (values: FormData) => {
    const nameSuggestsDonation = values.label.toLowerCase().includes('donation') || values.label.toLowerCase().includes('contribution');
    const isTaxExempt = values.gst === 'GST exempt';
    const isDonation = values.is_donation;
    if (
      !skipDonationCheck
      && (
        ((nameSuggestsDonation || isDonation) && !isTaxExempt)
        || (!isDonation && isTaxExempt && nameSuggestsDonation)
      )
    ) {
      showDialog({
        title: 'Is this a donation?',
        description: 'Payables that are donations should include a donation receipt and have a tax exempt price. Go back to edit these settings, or save anyway.',
        secondaryActionProps: {
          onClick: () => {
            handleSubmit(onSubmit(status, true))(); // Submit again but Skip donation check
            closeDialog();
          }
        },
        secondaryActionLabel: 'Save anyway',
        primaryActionLabel: 'Go back to edit',
        primaryActionProps: {
          onClick: () => {
            closeDialog();
          }
        }
      });
      return;
    }
    const permanentId = isExistingProduct ? payable?.permanent_id : await getNewPermanentId.trigger();
    if (permanentId) {
      const payablesBefore = isExistingProduct ? [
        {
          // Payable without the proto_payable_id object
          ...payable,
          proto_payable_id: undefined,
        }
      ] : [];
      const questionsBefore = isExistingProduct ? {
        [permanentId]: payableQuestions || [],
      } : {};
      const questionsAfter = {
        [permanentId]: values.questions,
      }
      const trimmedLabel = values.label.trim();
      const payablesAfter = [
        {
          menu_category: 'Kindo Payables',
          menu_priority: isExistingProduct ? payable?.menu_priority : ((payablesData?.products?.[0]?.menu_priority || 1) - 1) || 0,
          is_donation: values.is_donation,
          consignment: "Cold",
          current: true,
          dairy_free: false,
          delivery_address: "room",
          description: values.description ? b64encode(values.description) : "",
          detail: values.detail?.trim(),
          detail_confirmation: false,
          flexi_price_enabled: values.flexi_price_enabled,
          gluten_free: false,
          gst: values.gst,
          halal: false,
          included_option_count: 0,
          member_id: false,
          option_price_in_cents: 0,
          permanent_id: permanentId,
          price_in_cents: parseInt(values.price) * 100,
          product: `${values.year} ${trimmedLabel}`,
          label: trimmedLabel,
          product_id: payable?.product_id || '',
          remarks1: values.remarks1?.trim(),
          remarks2: values.remarks2?.trim(),
          summary: undefined,
          supplier: supplierId,
          supplier_ref: undefined,
          supplier_ref_type: undefined,
          vegetarian: false,
          item_count: 1,
          promote: 5,
          picture: '',
          pcat: values.pcat || null,
          max_instances: parseInt(values.maxInstances) || null,
          is_voluntary: values.is_voluntary,
          year: parseInt(values.year),
          proto_payable_id: payable?.proto_payable_id,
          status,
        },
      ]

      await createAndUpdatePayables.trigger({
        data: {
          after: payablesAfter,
          before: payablesBefore,
          product_question_after: questionsAfter,
          product_question_before: questionsBefore,
        }
      }).then(async (response) => {
        if (response && !response.error) {
          await refreshPayables.trigger();
          reset();
          navigate('/app/admin/payables');
          if (status === PayableStatus.ACTIVE) {
            // Refetch protopayable list data
            mutate((key: string) => {
              if (key?.indexOf('%2Froll%2Fpayable%2Fprototype') > -1) {
                return true;
              }
              return false;
            })
            showBanner({
              description: `'${values.year} ${values.label}' has been saved and published.`,
              maxBanner: 1,
              actions:
                <Button
                  variant="linkUnderline"
                  className="px-1 text-white"
                  onClick={() => {
                    navigate(`/app/admin/payable-apply?permanentId=${permanentId}`);
                  }}
                >
                  Apply to students.
                </Button>
            });
          } else {
            toast({
              description: `'${values.year} ${values.label}' has been saved as a draft.`,
              duration: 5000,
              variant: 'success'
            })
          }
        } else {
          toast({
            variant: 'destructive',
            title: `Failed to save payable`,
            ...(response?.error?.name === 'ProductExistsException' ? {
              description: 'Payable label already exists'
            } : {}),
          });
          if (response?.error?.name === 'ProductExistsException') {
            setError('label', {
              type: 'manual',
              message: 'Payable label already exists',
            });
          }
        }
      });
    }
  }


  React.useEffect(() => {
    if (isExistingProduct && !isLoading && payable) {
      reset({
        year: `${payable.year}` || currentYear,
        name: payable.product,
        pcat: payable?.pcat,
        product_id: payable.product_id,
        description: payable.description ? b64decode(payable.description) : '',
        remarks1: payable.remarks1,
        remarks2: payable.remarks2,
        gst: payable.gst,
        price: (payable.price_in_cents / 100).toString(),
        is_voluntary: payable.is_voluntary,
        flexi_price_enabled: payable.flexi_price_enabled,
        is_donation: payable.is_donation,
        detail: payable.detail,
        questions: payableQuestions,
        maxInstances: payable?.max_instances?.toString() || '',
        label: payable.label,
      })
    }
  }, [reset, isExistingProduct, isLoading]);

  const isSavingDisabled = isSubmitting || isLoading || (isExistingProduct && !payable?.product_id);
  return (
    <div>
      <Helmet>
        <title>{isExistingProduct ? 'Edit' : 'Create'} Payable</title>
      </Helmet>

      <PayableCategoryFormDialog
        open={showPayableCategoryForm}
        onOpenChange={(open) => setShowPayableCategoryForm(open)}
        schoolId={currentSchool}
        pcat={newPayableCategoryName}
        isNew
        onCreated={(pcat) => {
          setValue('pcat', pcat);
          setNewPayableCategoryName('');
          setShowPayableCategoryForm(false);
          refetchPayableCategories();
        }}
      />
      <div className="flex flex-col max-w-[1140px] m-auto py-6">

        {/* <div>
          <Breadcrumbs
            items={[
              ...(rootBreadcrumbItems),
              { label: 'Payables', to: '/app/admin/payables' },
              { label: 'Create Payable', to: `/app/admin/payables/create`, active: true },
            ]}
          />
          <p className="text-sm">
            {currentSchool}
          </p>
        </div> */}

        <div className="flex flex-row items-center justify-between px-6 top-[54px] py-4 mb-1 sticky bg-background z-20 max-w-[1160px] -m-[10px]">
          <div className="flex flex-row items-center gap-2">
            <Button
              variant={"icon"}
              onClick={() => {
                navigate(-1);
              }}
              size="default"
            >
              <MdArrowBack className="w-6 h-6 text-primary" />
            </Button>
            <h5 className="text-3xl font-bold text-primary font-museo">
              {isExistingProduct ? 'Edit' : 'Create'} Payable
            </h5>
          </div>
          <div className="flex items-center gap-2">
            {
              (!isExistingProduct || payable?.status === PayableStatus.DRAFT) && (
                <Button disabled={isSavingDisabled} size="sm" variant={"secondary"} onClick={() => {
                  handleSubmit(onSubmit(PayableStatus.DRAFT))();
                }}>
                  Save draft
                </Button>
              )
            }
            <Button disabled={isSavingDisabled} size="sm" onClick={() => {
              handleSubmit(onSubmit(PayableStatus.ACTIVE))();
            }}>
              Save {(payable?.status !== PayableStatus.INACTIVE) ? `and publish` : 'changes'}
            </Button>
          </div>
        </div>

        <div className="flex flex-col gap-2">
          <Card className="transition-shadow shadow-md hover:shadow-xl rounded-3xl">
            <CardContent className="flex flex-col sm:p-10">
              {
                isLoading ? (
                  <div className='flex flex-col items-center justify-center gap-2'>
                    <Spinner />
                    <p>
                      Loading
                    </p>
                  </div>
                ) : (
                  <>
                    <FormSection
                      title="General"
                      description={"Manage general information for this payable."}
                    >
                      <FormSectionContent>
                        <div className="flex flex-col">
                          <p className="text-sm text-text-body">
                            Payable Name
                          </p>
                          <div className="flex flex-row items-start flex-1 gap-0">
                            <SelectField
                              name="year"
                              className="border-r-0 rounded-r-none focus:border-r-1"
                              control={control}
                              label=" "
                              items={YEARS}
                              disabled={isExistingProduct && payable?.status !== PayableStatus.DRAFT}
                            />
                            <div className="flex-1">
                              <InputField
                                name="label"
                                label=" "
                                className="rounded-l-none"
                                rules={{
                                  required: 'Enter a label',
                                  maxLength: {
                                    value: 70,
                                    message: '70 characters max',
                                  },
                                  validate: {
                                    isValidCharacters: (value) => {
                                      // Only allow alphanumeric chars, spaces and %!&)(-,/:_@#'$?+."
                                      return /^[a-zA-Z0-9\s%!&)(\-,/:_@#'$?+."]+$/.test(value) || 'Only use letters (a-z), numbers (0-9), spaces, and the following symbols % ! & ) ( - , / : _ @ # \' $ ? + . "';
                                    }
                                  }
                                }}
                                control={control}
                                placeholder="Enter a label"
                              />
                            </div>
                          </div>
                          {
                            !errors.label && (
                              <p className="mt-1 text-xs text-text-helper">
                                Preview: {formValues.year} {formValues.label}
                              </p>
                            )
                          }
                        </div>
                        <CreateableComboBoxField
                          label="Payable Category"
                          formatCreateLabel={(inputValue) => `Add "${inputValue}" as a new category`}
                          onCreateOption={(inputValue) => {
                            setNewPayableCategoryName(inputValue);
                            setShowPayableCategoryForm(true);
                          }}
                          allowCreateWhileLoading={false}
                          helperText="Admin users can only view payables in categories they are assigned access to."
                          name="pcat"
                          className="max-w-[315px]"
                          isLoading={isLoadingPayableCategories || isValidatingPayableCategories}
                          control={control}
                          options={[
                            ...(payableCategoriesData?.pcats.map((pcat) => ({ label: pcat, value: pcat })) ?? [])
                          ]}
                        />
                        <EditorField
                          name="description"
                          label="Description"
                          control={control}
                          rules={{
                            validate: {
                              maxLength: (value) => {
                                // Remove HTML tags before counting characters
                                const chars = value?.replace(/<[^>]*>?/gm, '') || '';
                                if (chars.length > 10000) {
                                  return '10000 characters max';
                                }
                                return true;
                              }
                            }
                          }}
                          placeholder="Tell the caregiver about this payable..."
                        />
                      </FormSectionContent>
                    </FormSection>
                    <FormSection
                      title="Accounting"
                    >
                      <FormSectionContent>
                        <div
                          className="max-w-[315px]"
                        >
                          <InputField
                            name="remarks1"
                            label="GL Code"
                            control={control}
                            rules={{
                              required: 'Enter a GL code',
                              maxLength: {
                                value: 1024,
                                message: '1024 characters max',
                              },
                              validate: {
                                isValidCharacters: (value) => {
                                  // Only allow alphanumeric chars, spaces and %!&)(-,/:_@#'$?+.
                                  return /^[a-zA-Z0-9\s%!&)(\-,/:_@#'$?+.]+$/.test(value) || 'Only use letters (a-z), numbers (0-9), spaces, and the following symbols % ! & ) ( - , / : _ @ # \' $ ? + .';
                                }
                              }
                            }}
                            placeholder="Enter GL code"
                          />
                        </div>
                        <InputField
                          name="remarks2"
                          label="Additional detail"
                          control={control}
                          rules={{
                            maxLength: {
                              value: 1024,
                              message: '1024 characters max',
                            },
                            validate: {
                              isValidCharacters: (value) => {
                                // Only allow alphanumeric chars, spaces and %!&)(-,/:_@#'$?+.
                                return /^[a-zA-Z0-9\s%!&)(\-,/:_@#'$?+.]+$/.test(value) || 'Only use letters (a-z), numbers (0-9), spaces, and the following symbols % ! & ) ( - , / : _ @ # \' $ ? + .';
                              }
                            }
                          }}
                          placeholder="Enter additional details"
                          helperText="This will appear on the detailed accounting report only and will not be visible to the caregiver."
                        />
                      </FormSectionContent>
                    </FormSection>
                    <FormSection
                      title="Tax & Price"
                    >
                      <FormSectionContent>
                        <div className="flex flex-row items-start gap-4">
                          <div className="flex-1">
                            <InputField
                              name="price"
                              startAdornment={<MdAttachMoney />}
                              label="Total price"
                              adornmentVariants={{
                                size: 'sm',
                                variant: 'plain',
                              }}
                              control={control}
                              rules={{
                                required: 'Enter a total price',
                                validate: {
                                  isNumber: (value) => {
                                    return !isNaN(value) || 'Use numbers only';
                                  },
                                  isPositive: (value) => {
                                    return value > 0 || 'Must be greater than 0';
                                  },
                                  isTwoDecimalPlacesOrLess: (value) => {
                                    return /^\d+(\.\d{1,2})?$/.test(value) || '2 decimal places max';
                                  }
                                }
                              }}
                            />
                          </div>
                          <div className="flex-1">
                            <ComboBoxField
                              label="Tax"
                              name="gst"
                              control={control}
                              options={[
                                {
                                  label: 'Tax exempt',
                                  value: 'GST exempt',
                                },
                                {
                                  label: 'Includes GST (15%)',
                                  value: 'GST',
                                }
                              ]}
                            />
                          </div>
                        </div>
                      </FormSectionContent>
                    </FormSection>

                    <FormSection
                      title="Receipts"
                      description={
                        <span>
                          Manage the type and content of receipts for this payable.&nbsp;
                          <Link target="_blank" to={"https://support.kindo.co.nz/portal/en/kb/articles/donation-receipts-for-payable-items"}>
                            <span className="underline">
                              Learn about updating signatory details.
                            </span>
                            <MdOpenInNew className="inline-block w-4 h-4 ml-1" />
                          </Link>
                        </span>
                      }
                    >
                      <FormSectionContent>
                        <CheckboxField
                          name="is_donation"
                          control={control}
                          label="Include donation receipt"
                          helperText="Caregivers will automatically receive their donation receipt attached to the payment confirmation email. Don't issue a second receipt manually."
                        />
                        <TextField
                          autoResize
                          name="detail"
                          label="Receipt Note"
                          control={control}
                          rules={{
                            maxLength: {
                              value: 1024,
                              message: '1024 characters max',
                            }
                          }}
                          helperText="This will appear on the caregiver's receipt."
                        />
                      </FormSectionContent>
                    </FormSection>
                    <FormSection
                      title="Caregiver Payment"
                      description="Manage how caregivers see and make payments."
                    >
                      <FormSectionContent>
                        <CheckboxField
                          name="is_voluntary"
                          control={control}
                          label="Payment is voluntary"
                          helperText="This setting will control new features coming soon." // TODO: Add link
                        />
                        <CheckboxField
                          name="flexi_price_enabled"
                          control={control}
                          label="Part pay allowed"
                          helperText="Caregivers can pay in parts towards the total cost."
                        />
                      </FormSectionContent>
                    </FormSection>

                    <FormSection
                      title="Application Limit"
                      description={
                        <span>
                          Manage how many times this payable can be applied.&nbsp;
                          <Link target="_blank" to={"https://support.kindo.co.nz/portal/en/kb/articles/using-max-instance"}>
                            <span className="underline">
                              Learn more about maximum applications.
                            </span>
                            <MdOpenInNew className="inline-block w-4 h-4 ml-1" />
                          </Link>
                        </span>
                      }
                    >
                      <FormSectionContent>
                        <SelectField
                          className="max-w-[315px]"
                          name="maxInstances"
                          label="Limit per student"
                          control={control}
                          items={MAX_INSTANCES_OPTIONS}
                        />
                      </FormSectionContent>
                    </FormSection>

                    <FormSection
                      title="Questions"
                      description={
                        <span>
                          Create a form to collect information related to this payable.
                          <br />
                          <br />
                          If payable payments are made through the point of sale, questions will not be asked. Placeholder answers are entered for compulsory questions and should be updated later.&nbsp;
                          <Link target="_blank" to={"https://support.kindo.co.nz/portal/en/kb/articles/adding-questions-for-kindo-payables"}>
                            <span className="underline">
                              Learn more about forms.
                              <MdOpenInNew className="inline-block w-4 h-4 ml-1" />
                            </span>
                          </Link>
                        </span>
                      }
                    >
                      <FormSectionContent>
                        <div >
                          <DndContext
                            modifiers={modifiers}
                            onDragEnd={(event) => {
                              const { active, over } = event
                              if (over && active.id !== over?.id) {
                                const activeIndex = active.data.current?.sortable?.index
                                const overIndex = over.data.current?.sortable?.index
                                if (activeIndex !== undefined && overIndex !== undefined) {
                                  moveQuestion(activeIndex, overIndex)
                                }
                              }
                            }}
                          >
                            <SortableContext items={questions}>
                              <Table>
                                <TableHeader>
                                  <TableRow className="font-semibold font-museo">
                                    <TableHead className="p-2 align-top" />
                                    <TableHead className="p-2 align-top">
                                      Question ID
                                    </TableHead>
                                    <TableHead className="p-2 align-top whitespace-nowrap">Type</TableHead>
                                    <TableHead className="p-2 align-top">Question</TableHead>
                                    <TableHead className="p-2 align-top">Required</TableHead>
                                    <TableHead className="p-2" />
                                  </TableRow>
                                </TableHeader>
                                <TableBody>

                                  {questions.map((field, index) => {
                                    return (
                                      <React.Fragment key={field.id}>
                                        <SortableItem id={field.id}>
                                          {({ attributes, listeners }) => (
                                            <>
                                              <TableCell
                                                width={40}
                                                {...attributes}
                                                {...listeners}
                                                className="px-2"
                                              >

                                                <MdDragHandle className="w-6 h-6 text-primary" />
                                              </TableCell>
                                              <TableCell className="px-2" width={100}>
                                                <InputField
                                                  className="min-w-[100px]"
                                                  name={`questions[${index}].question_id`}
                                                  control={control}
                                                  placeholder="Enter ID"
                                                  rules={{
                                                    required: 'Enter a question ID',
                                                    maxLength: {
                                                      message: '50 characters max',
                                                      value: 50,
                                                    },
                                                    validate: {
                                                      isValidCharacters: (value) => {
                                                        // Only allow alphanumeric chars, spaces and -/'
                                                        return /^[a-zA-Z0-9\s\-\/\']+$/.test(value) || 'Only letters, numbers, spaces, and symbols - / \'';
                                                      }
                                                    }
                                                  }}
                                                  label=" "
                                                />
                                              </TableCell>
                                              <TableCell className="px-2" width={60}>
                                                <SelectField
                                                  className="w-[100px]"
                                                  name={`questions[${index}].answer_type`}
                                                  control={control}
                                                  items={[
                                                    {
                                                      label: 'Text',
                                                      value: 'text',
                                                    },
                                                    {
                                                      label: 'Yes/No',
                                                      value: 'yes_no',
                                                    },
                                                    {
                                                      label: 'Date',
                                                      value: 'date',
                                                    },
                                                    {
                                                      label: 'Agree',
                                                      value: 'agree',
                                                    },
                                                  ]}
                                                />
                                              </TableCell>
                                              <TableCell className="px-2" width={'100%'}>
                                                <TextField
                                                  rows={1}
                                                  autoResize
                                                  className="min-h-0"
                                                  name={`questions[${index}].question`}
                                                  control={control}
                                                  placeholder="Enter question"
                                                  defaultValue={field.question}
                                                  rules={{
                                                    required: 'Enter a question',
                                                    maxLength: {
                                                      message: '2000 characters max',
                                                      value: 2000,
                                                    },
                                                    validate: {
                                                      isValidCharacters: (value) => {
                                                        // Only allow alphanumeric chars, spaces and &@,.:_'*$(/?)!+-
                                                        return /^[a-zA-Z0-9\s&@,.:_'*$(/?)!+-]+$/.test(value) || 'Only use letters (a-z), numbers (0-9), spaces, and the following symbols & @ , . : _ \' * $ ( / ? ) ! + -';
                                                      }
                                                    }
                                                  }}
                                                />
                                              </TableCell>
                                              <TableCell className="px-2" width={40}>
                                                <div className="flex justify-center">
                                                  <SwitchField
                                                    name={`questions[${index}].required`}
                                                    control={control}
                                                  />
                                                </div>
                                              </TableCell>
                                              <TableCell className="px-2" width={40}>
                                                <Button variant="icon" onClick={() => {
                                                  removeQuestion(index)
                                                }}>
                                                  <MdDelete className="w-6 h-6" />
                                                </Button>
                                              </TableCell>
                                            </>
                                          )}
                                        </SortableItem>
                                      </React.Fragment>
                                    )
                                  })}
                                </TableBody>
                              </Table>
                            </SortableContext>
                          </DndContext>

                          <Button
                            className="mt-4 text-primary"
                            variant="ghost"
                            onClick={() => {
                              appendQuestion({
                                question_id: '',
                                question: '',
                                answer_type: 'text',
                                required: false,
                              })
                            }}
                          >
                            <MdAdd className="w-4 h-4 mr-2" /> Add Question
                          </Button>
                        </div>
                      </FormSectionContent>
                    </FormSection>
                  </>
                )
              }
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
})

export default PayableFormPage;
