import React from 'react'
import { useLocation } from 'react-router-dom';
import api from 'networking/api';
import { init as initCommandbar } from 'commandbar';
import { isMenuAdministrator, isOrderAdministrator, isSchoolAdministrator, isServiceAdministrator, isTgclAdmin } from 'util/permissions';

initCommandbar('2b0daeae');
const Commandbar = () => {
  const [initialized, setInitialized] = React.useState(false);
  const location = useLocation();
  const shouldEnableCommandbar = [
    '/app/admin',
    '/app/connect',
    '/app/order-status',
  ].some((path) => location.pathname.startsWith(path))
  const { data } = api.family.useFamily(undefined, !shouldEnableCommandbar);
  React.useEffect(() => {
    if (shouldEnableCommandbar) {
      // Enable for admin pages and order status page
      if (data?.family_id && !initialized) {
        // Initialize command bar with family_id if not already initialized
        window.CommandBar.boot(data.family_id, {
          first_name: data.contact_name,
          is_service_administrator: isServiceAdministrator(data),
          is_menu_administrator: isMenuAdministrator(data),
          is_order_administrator: isOrderAdministrator(data),
          is_school_administrator: isSchoolAdministrator(data),
          is_tgcl_admin: isTgclAdmin(data),
        }).then(() => {
          setInitialized(true);
        });
      }


    } else {
      // Hide commandbar when navigated to unsupported page
      window.CommandBar.shutdown();
      setInitialized(false);
    }
  }, [data?.family_id, shouldEnableCommandbar]);

  React.useEffect(() => {
    // Shutdown command bar when component unmounts
    return () => {
      window.CommandBar.shutdown();
    }
  }, [])
  return null;
};

export default Commandbar