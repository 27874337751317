import React from 'react'
import { Button } from '../../common_component/base/button'
import { useForm } from "react-hook-form";
import RadioGroup from '../../common_component/form/RadioGroup';
import TopUpInfo from './TopUpInfo';
import InputField from '../../common_component/form/InputField';
import api from 'networking/api';
import { tryConvertDollarStringToCents, formatCentsPriceForDisplay } from '../../util/string_util';
import WindcaveLogo from '../../assets/images/windcave-logo.png';
import CardTypeLogos from '../../assets/images/card-type-logos.png';
import { baseUrl } from 'networking/constants'
import analytics from '../../util/googleAnalytics';
import { useStore } from '../../store/store';
import KindoWell from '../../common_component/KindoWell'

type FormData = {
  amount: string;
  internet_banking_opt: false,
  options: string,
  topup_method: "credit_card"
};

type Props = {
  totalPrice?: number;
  orderIds?: any;
  onPurchase?: () => void;
  onError?: (error: any) => void;
};

const CardForm = ({ totalPrice, orderIds, onPurchase, onError }: Props) => {
  const store = useStore();
  const isCommunityShop = store.shop.isCommunityShop;

  const { data, isLoading } = api.family.useFamily({
    has_billing_id: true,
    card_detail: true,
    get_students: true,
    saved_order_count: false,
    last_login: false,
    school_ids: false,
    supplier_menu: false,
    supplier_order: false,
    force_get_family: true,
    family_option: false,
    school_services: false,
    server_time: false,
    path: "/FAMILY"
  });

  const { control, handleSubmit, setValue } = useForm<FormData>({
    defaultValues: {
      amount: isCommunityShop ? formatCentsPriceForDisplay(totalPrice, { dollarSign: false }) : '',
      options: `${data?.card_detail ? 'use_saved_card' : 'dont_save_card'}`
    }
  });

  const cardOptions = [
    {
      label: `${data?.card_detail ? "Use another card (save card details)" : "Save card details"}`,
      value: 'save_new_card',
    },
    {
      label: `${data?.card_detail ? "Use another card (do not save card details)" : "Do not save card details"}`,
      value: 'dont_save_card',
    }]

  if (data?.card_detail) {
    cardOptions.push({
      label: 'Use saved card',
      value: 'use_saved_card',
    })
  }

  const onSubmitTopUp = (values: FormData) => {
    const totalAmount = tryConvertDollarStringToCents(values.amount) as number;
    const serviceFee = 50;
    const surcharge = totalAmount * 0.025;
    const totalAfterSurcharge = surcharge + totalAmount

    const totalAmountWithFees = totalAfterSurcharge + serviceFee;

    let data = {
      'amount_in_cents': Math.round(totalAmountWithFees),
      'internet_banking_opt': false,
      'options': {
        save_card: values.options
      },
      'purchase_detail': {
        orders_too_late_fox_bx: [],
        saved_order_ids: orderIds,
        voucher_token: null
      },
      'topup_method': "credit_card"
    }

    api.family.topup(data, !!orderIds)
      .then((res) => {
        if (res) {
          return res.json();
        }
      }).then((responseData) => {
        if (responseData.rtype === 'error') {
          if (onError) {
            onError(responseData);
          } else {
            alert(responseData.message)
          }
        } else if (responseData.url) {
          analytics.placeOrder({ priceInCents: totalAmountWithFees, initiatedFrom: 'Card' })
          if (onPurchase) {
            onPurchase();
          }
          alert('You are now leaving the Kindo site to complete the topup process  -after which you will be returned to Kindo.')
          window.location.href = responseData.url
        } else {
          alert('OOPS. A topup URL was not generated. This is probably due to a communication failure between our website and our payment provider.\n\nNo funds have been transferred.\n\nPlease try again.')
        }
      })
  }

  const { data: motdData } = api.partner.useMessageOfTheDay('KINDO_ALL', 'card');

  return (
    <form onSubmit={handleSubmit(onSubmitTopUp)}>
      {
        motdData?.motds && motdData?.motds.length > 0 && motdData?.motds.sort((a, b) => a.priority - b.priority).map((motd) => (
          <KindoWell
            variant={motd.info_type}
            key={`${motd.info_type + '_' + motd.priority}`}
            className='mb-2'
            messages={[motd.text]}
          />
        ))
      }
      <div className='flex flex-col gap-6'>
        <div className='grid grid-cols-1 gap-10 md:grid-cols-2 space-between'>
          <div className='flex flex-col gap-1 md:max-w-[280px]'>
            <InputField
              label={isCommunityShop ? 'Amount' : 'Top up amount'}
              required
              control={control}
              name="amount"
              rules={{
                pattern: {
                  value: /^[0-9]+(\.[0-9]+)?$/,
                  message: 'Please enter a valid amount',
                },
                min: {
                  value: formatCentsPriceForDisplay(totalPrice, { dollarSign: false }) || '0',
                  message: `Minimum amount of ${formatCentsPriceForDisplay(totalPrice)} is required`,
                },
              }}
              disabled={isCommunityShop}
            />
            {(totalPrice && !isCommunityShop) &&
              <p
                data-testid='button-topup-full-amount'
                className='text-sm cursor-pointer hover:underline text-secondary'
                onClick={() => setValue('amount', formatCentsPriceForDisplay(totalPrice, { dollarSign: false }))}
              >
                Top up full amount {formatCentsPriceForDisplay(totalPrice)}
              </p>
            }
            <div className='pt-4'>
              <RadioGroup
                control={control}
                name='options'
                label="Please select your preference"
                required
                defaultValue={data?.card_detail ? 'use_saved_card' : 'dont_save_card'}
                options={cardOptions}
              />
              {data?.card_detail ?
                <div className='flex flex-col gap-2 pl-9'>
                  <div className='flex'>
                    <p className='w-1/2 text-xs'>Card number:</p>
                    <p className='w-1/2 text-xs text-card-foreground'>{data.card_detail.CardNumber}</p>
                  </div>
                  <div className='flex'>
                    <p className='w-1/2 text-xs'>Card name:</p>
                    <p className='w-1/2 text-xs text-card-foreground'>{data.card_detail.CardHolderName}</p>
                  </div>
                  <div className='flex'>
                    <p className='w-1/2 text-xs'>Expiry date:</p>
                    <p className='w-1/2 text-xs text-card-foreground'>{data.card_detail.DateExpiry.substring(0, 2)} / {data.card_detail.DateExpiry.substring(2)}</p>
                  </div>
                  <div className='flex'>
                    <p className='w-1/2 text-xs'>Card type:</p>
                    <img style={{ width: '30px' }} src={`${baseUrl}fd/img/icon/credit_card/${data.card_detail.CardName.toLowerCase()}.png`} alt={data.card_detail.CardName} />
                  </div>
                </div>
                :
                undefined
              }
            </div>
          </div>
          <TopUpInfo />
        </div>

        <div className='flex flex-row flex-wrap justify-between gap-4'>
          <div className='flex flex-row flex-wrap items-center gap-4'>
            <img src={WindcaveLogo} alt="Windcave logo" className='h-12' />
            <img src={CardTypeLogos} alt="Card type logos" className='h-4' />
          </div>
          <Button size='sm' className='font-museo'>
            {orderIds ? 'Complete payment' : 'Complete top up'}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default CardForm