import React from 'react'
import { cn } from 'util/tailwind';
import FormSectionSubtitle from './FormSectionSubtitle';

type Props = {
  children: React.ReactNode;
  title?: string;
  description?: string;
}

const FormSectionContent = (props: Props) => {
  const { title, description, children } = props;
  return (
    <div className={
      cn('flex flex-col gap-3 last:border-0 border-b-1 border-border pb-6', {
        'pt-8': !title
      })
    }>
      {title && (
        <FormSectionSubtitle title={title} description={description} />
      )}
      <div className='flex flex-col gap-6'>

        {children}
      </div>
    </div>
  )
}

export default FormSectionContent