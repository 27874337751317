import React from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { VariantProps, cva } from "class-variance-authority"
import { cn } from "../../util/tailwind"
import useSchoolServices from 'networking/hooks/useSchoolService';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import { Mcat } from 'networking/models/family';
import { Skeleton } from '../../common_component/base/skeleton';
import { ScrollArea } from '../../common_component/base/scroll-area';
import analytics from '../../util/googleAnalytics';

const sideMenuButtonVariants = cva(
  " cursor-pointer px-3 py-2",
  {
    variants: {
      variant: {
        default: "bg-primary-lightest hover:bg-primary-lightest/80",
        active: 'bg-primary-dark hover:bg-primary-dark/90 text-primary-foreground',
      }
    },
    defaultVariants: {
      variant: 'default',
    }
  }
)
interface SideMenuButtonProps extends VariantProps<typeof sideMenuButtonVariants> {
  label: string;
  menuCategories: Mcat[];
}
const SideMenuButton = observer(({ label, variant, to, menuCategories }: SideMenuButtonProps) => {
  const navigate = useNavigate();
  const { shop } = useStore();
  const { menuCategoryInfos, scrollToActiveId } = shop;
  return (
    <div>
      <div className={cn(sideMenuButtonVariants({ variant }))}
        onClick={() => {
          navigate(to);
          analytics.triggerClick({ eventName: 'side_service_click' });
        }}
      >
        <p className='text-sm font-museo'>
          {label}
        </p>
      </div>
      {
        variant === 'active' && (
          <ScrollArea>
            <div className='flex flex-col gap-1 py-1 max-h-[400px] '>
              {
                menuCategories.map((menuCategory) => {
                  const info = menuCategoryInfos.get(menuCategory.id);
                  return (
                    <div
                      key={menuCategory.id}
                      className={cn('py-2 pl-6 pr-2 text-sm cursor-pointer bg-white/50 hover:bg-white/80 text-primary font-museo',
                        {
                          'bg-white hover:bg-white text-primary': scrollToActiveId === menuCategory.id,
                        })
                      }
                      onClick={() => {
                        shop.setScrollToCategoryId(menuCategory.id);
                        analytics.triggerClick({ eventName: 'side_menu_category_click' });
                      }}
                    >
                      {menuCategory.name} ({info?.filteredCount ?? 0})
                    </div>
                  )
                })
              }
            </div>
          </ScrollArea>
        )
      }
    </div>
  )
})

const SideMenu = () => {
  const { family: { currentSchool } } = useStore();;

  const { serviceId, brandName } = useParams();

  const { schoolService, isLoadingServices } = useSchoolServices({
    schoolId: currentSchool,
  })

  return (
    <div
      className='w-full flex justify-end mt-[64px] p-4 sticky top-[150px] lg:pl-[20px] xl:pl-[70px] 2xl:pl-[170px]'
    >
      <div className='max-w-[200px] w-full min-w-[200px]'>
        {brandName === 'ezlunch' && (
          <>
            <p className='mb-4 text-lg font-bold font-museo'>ezlunch menus</p>
            {
              (!schoolService || isLoadingServices) && (
                <div className='flex flex-col gap-2'>
                  <Skeleton className='w-[90%] h-5 bg-background-light' />
                  <Skeleton className='w-[75%] h-5 bg-background-light' />
                  <Skeleton className='w-[95%] h-5 bg-background-light' />
                  <Skeleton className='w-[85%] h-5 bg-background-light' />
                </div>
              )
            }
            <div className='flex flex-col gap-1'>
              {
                schoolService?.services
                  .filter((s) => s.brand_name === brandName)
                  .map((service) => (
                    <SideMenuButton
                      key={service.id}
                      menuCategories={service.mcats}
                      label={service.title}
                      to={`/app/shop/${brandName}/${service.id}`}
                      variant={service.id === serviceId ? 'active' : 'default'}
                    />
                  ))
              }
            </div>
          </>
        )}
        {
          brandName !== 'ezlunch' && (
            <>
              <p className='mb-4 text-lg font-bold font-museo'>Menu</p>
              <div className='flex flex-col gap-1'>
                {
                  schoolService?.services
                    .filter((s) => s.id === serviceId)
                    .map((service) => (
                      <SideMenuButton
                        key={service.id}
                        label={service.title}
                        menuCategories={service.mcats}
                        to={`/app/shop/${brandName}/${service.id}`}
                        variant={service.id === serviceId ? 'active' : 'default'}
                      />
                    ))
                }
              </div>
            </>
          )
        }
      </div>
    </div>
  )
}

export default SideMenu