import React from 'react'
import { MdChevronLeft, MdClose, MdMenu, MdOutlineAccountCircle } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react'
import { Button } from '../../../common_component/base/button'
import { ScrollArea } from '../../../common_component/base/scroll-area'
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTitle } from '../../../common_component/base/sheet'
import { useStore } from '../../../store/store'
import { MobileNavButton, MobileServiceNavButton, NavButtonProps, SubNavProps } from './NavButton'
import { cn } from '../../../util/tailwind'
import { getSchoolLogo } from 'networking/constants'
import AppDownloadBanner from './AppDownloadBanner';
import { isTablet, isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import GlobalBanner from './GlobalBanner'

type Props = {
  headerContent: React.ReactNode;
  schoolPath?: string;
  schoolSwitcher?: React.ReactNode;
  navItems?: NavButtonProps[];
  subNavItems?: SubNavProps[];
  withAccountMenu?: boolean;
  signedInAs?: string | null;
  onSignOut?: () => void;
}

const MobileHeader = observer((props: Props) => {
  const store = useStore();
  const location = useLocation();

  const showMobileNav = store.family.showMobileNav;
  const homeUrl = store.shop.isCommunityShop ? `/app/community-shop/${store.family.currentSchool}/` : '/app/shop';
  const setMobileNavOpened = (open: boolean) => store.family.setShowMobileNav(open);
  const { schoolPath, signedInAs, schoolSwitcher, navItems, subNavItems, headerContent, withAccountMenu, onSignOut } = props;
  const [showMobileNavDelayed, setShowMobileNavDelayed] = React.useState(store.family.showMobileNav);
  const setShowMobileAccountMenu = (open: boolean) => store.family.setShowMobileAccountMenu(open);
  const isAdminPage = location.pathname.startsWith('/app/admin');
  const isStaging = process.env.DEPLOY_ENV === 'staging';

  React.useEffect(() => {
    setTimeout(() => {
      setShowMobileNavDelayed(store.family.showMobileNav);
    }, 400)
  }, [store.family.showMobileNav]);

  return (
    <>
      {!isAdminPage && <AppDownloadBanner />}
      {
        store.family.previewingPartner && (
          <GlobalBanner text='You are in Preview mode' />
        )
      }
      {
        isStaging && !store.family.previewingPartner && (
          <GlobalBanner text='You are in a test environment' />
        )
      }
      <header
        data-testid="section-mobile-header"
        className={cn('sticky top-0 z-30 grid items-center w-full grid-flow-col p-3 bg-white shadow-sm sm:hidden sm:invisible', {
          [`top-16`]: store.shop.showDownloadAppBanner && (isTablet || isMobile) && !isAdminPage,
        })}
      >
        <div className='flex items-center gap-2'>
          <Button name="menu" className='px-2 mr-2' variant="ghost" size="sm" onClick={() => setMobileNavOpened(true)}>
            <MdMenu className='w-6 h-6' />
          </Button>
          <Sheet open={showMobileNav} onOpenChange={(open) => setMobileNavOpened(open)}>
            <SheetContent data-testid="section-side-navigation" className={cn('p-0 w-[calc(100vw_-_54px)]', {
              'data-[state=open]:duration-0': showMobileNavDelayed,
            })} side="left">
              <SheetClose data-testid="button-close-side-navigation" className={cn('absolute top-6 -right-11 transition-opacity duration-100 opacity-100', {
                'opacity-0': !store.family.showMobileNav
              })}>
                <MdClose className='w-8 h-8 text-primary-foreground' />
              </SheetClose>
              <div className='flex flex-col h-[100%]'>
                <SheetHeader>
                  <SheetTitle>

                    <div className='flex items-center h-[80px] border-b-1 border-primary-light'>
                      <div className='flex items-center flex-1 w-full py-4 pl-4'>
                        {
                          schoolPath && <img alt="" key={schoolPath} className='max-h-[48px] h-fit' src={getSchoolLogo({ schoolPath: schoolPath })} />
                        }
                      </div>
                      {
                        schoolSwitcher && (
                          <div className='pr-4 '>
                            {schoolSwitcher}
                          </div>
                        )
                      }
                      {
                        withAccountMenu && (
                          <div className='flex items-center justify-center h-full px-5 border-l-1 border-primary-light'>
                            <Button
                              name="account-menu"
                              variant="icon"
                              onClick={() => {
                                setShowMobileAccountMenu(true);
                              }}
                            >
                              <MdOutlineAccountCircle className='w-8 h-8 text-primary' />
                            </Button>
                          </div>
                        )
                      }
                    </div>
                  </SheetTitle>
                </SheetHeader>

                <ScrollArea className={cn('max-h-[calc(100vh_-_80px)] overflow-auto relative flex flex-col flex-1 h-full')}>
                  <div className={cn('bg-background-light h-full px-2 absolute top-0 left-0 right-0 transition-all ease-in-out duration-350 flex flex-col', {
                    'translate-x-[0%] opacity-100': store.family.showMobileAccountMenu,
                    '-translate-x-[100%] opacity-0': !store.family.showMobileAccountMenu,
                  })}>
                    <Button
                      name="back"
                      className='h-[68px] justify-start p-0 gap-2 font-museo'
                      variant="ghost"
                      onClick={() => {
                        setShowMobileAccountMenu(false);
                      }}
                    >
                      <MdChevronLeft className='w-6 h-6' />
                      Back
                    </Button>
                    {

                      (navItems || [])
                        .filter((nav) => !nav.hideForMobile)
                        .map((item) => {
                          return (
                            <MobileNavButton key={item.to || item.url} {...item} />
                          )
                        })
                    }
                  </div>
                  <div className={cn('absolute top-0 px-2 left-0 right-0 flex flex-col pt-[68px] pb-12 transition-all ease-in-out duration-350', {
                    '-translate-x-[100%] opacity-0': store.family.showMobileAccountMenu,
                    'translate-x-[0%] opacity-100': !store.family.showMobileAccountMenu,
                  })}>
                    {subNavItems?.map((item) => (
                      <MobileServiceNavButton key={item.to} {...item} />
                    ))}
                  </div>
                </ScrollArea>

                {
                  withAccountMenu && (

                    <div className={cn('px-2 absolute bottom-0 left-0 right-0 transition-all ease-in-out duration-350 flex flex-col', {
                      'translate-x-[0%] opacity-100': store.family.showMobileAccountMenu,
                      '-translate-x-[100%] opacity-0': !store.family.showMobileAccountMenu,
                    })}>

                      <div className='flex flex-col gap-4 pt-2 pb-4 bg-background-light'>
                        <p className='px-4 text-xs text-center text-card-foreground'>
                          Signed in as {signedInAs}
                        </p>
                        <Button
                          name="sign-out"
                          size="sm"
                          variant="outline"
                          className='flex self-center gap-2 px-4 bg-transparent'
                          onClick={() => {
                            if (onSignOut) {
                              onSignOut();
                            }
                          }}
                        >
                          Sign out
                        </Button>
                      </div>
                    </div>
                  )
                }

              </div>
            </SheetContent>
          </Sheet>
          <Link data-testid="button-mykindo" to={homeUrl}>
            <span className='font-semibold font-museo'>
              <span className='text-primary'>my</span>
              <span className='text-secondary'>kindo</span>
            </span>
          </Link>
        </div>
        <div className='flex items-center justify-end gap-3 sm:invisible'>
          {headerContent}
        </div>
      </header>
    </>
  )
})

export default MobileHeader