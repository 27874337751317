import React from 'react'
import { Outlet } from 'react-router-dom'
import GoogleAnalytics from './GoogleAnalytics'
import Precheckout from './Precheckout'
import UrlRedirectHandler from './UrlRedirectHandler'
import LoginDialog from './LoginDialog'
import NoStudentReminder from './NoStudentReminder'
import TermsAndConditionsDialog from './TermsAndConditions'
import FlowJoinDialog from './FlowJoinDialog'
import ForgotPasswordDialog from './ForgotPasswordDialog'
import FeedbackDialog from './FeedbackDialog'
import AppDeepLinking from './AppDeepLinking'
import Zoho from './Zoho'
import Commandbar from './Commandbar'
import MaintenanceDialog from './MaintenanceDialog'
/**
 * This contains all the services that need to be on the root of the app
 * ie. they need to be on every page but does not render anything unless it's a modal
 */
const RootServices = () => {
  return (
    <>
      <AppDeepLinking />
      <GoogleAnalytics />
      <Precheckout />
      <UrlRedirectHandler />
      <NoStudentReminder />
      <Outlet />
      <LoginDialog />
      <TermsAndConditionsDialog />
      <FlowJoinDialog />
      <ForgotPasswordDialog />
      <Commandbar />
      <FeedbackDialog />
      <MaintenanceDialog />
      <Zoho />
    </>
  )
}

export default RootServices