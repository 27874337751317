import React from 'react';
import { ColumnDef } from "@tanstack/react-table"
import { MdArrowUpward } from 'react-icons/md';
import { Button } from '../../../common_component/base/button';
import { cn } from '../../../util/tailwind';

// This type is used to define the shape of our table data.
export type RowItem = {
  // id: string;
  studentIdExt: string;
  name?: string;
  room?: string;
  yearLevel?: string;
}

const SortableHeader = ({ column, title }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className='px-0 font-bold text-card-foreground'
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
    </Button>
  )
};
export const columns: ColumnDef<RowItem>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <SortableHeader column={column} title="Name" />
    ),
  },
  {
    accessorKey: "room",
    header: ({ column }) => (
      <SortableHeader column={column} title="Room" />
    ),
  },
  {
    accessorKey: "yearLevel",
    header: ({ column }) => (
      <SortableHeader column={column} title="Year Level" />
    ),
  },
]
