import React from 'react';
import { Controller, type UseControllerProps } from "react-hook-form";
import { Input, InputProps } from '../base/input';

// Removing duplicate prop conflict from InputProps since UseControllerProps already has it
interface Props extends UseControllerProps<any>, Omit<InputProps, 'name' | 'defaultValue'> {
}

const InputField = React.forwardRef<
  InputProps,
  Props
>(({
  name,
  control,
  rules,
  onChange: handleChange,
  ...props
}, ref) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ fieldState: { error }, field: { onChange, ...field } }) => (
      <Input
        error={error?.message}
        {...field}
        {...props}
        onChange={(event) => {
          const newValue = event.target.value;

          onChange(newValue);
          if (handleChange) handleChange(event);
        }}
      />
    )}
  />
))

export default InputField;