import React from 'react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../common_component/base/tooltip';

type Props = {
  tooltip?: string | React.ReactNode;
  messages?: string[] | React.ReactNode[];
  children: React.ReactNode;
  side?: 'left' | 'right' | 'top' | 'bottom';
}

const KindoTooltip = (props: Props) => {
  const { children, tooltip, messages, side } = props;
  return (
    <TooltipProvider delayDuration={150}>
      <Tooltip>
        <TooltipTrigger type="button" asChild>
          {children}
        </TooltipTrigger>
        <TooltipContent side={side}>
          <div className='text-card-foreground'>
            {tooltip}
          </div>
          {
            messages && messages.length > 0 && (
              <div className='py-2 text-card-foreground'>
                {
                  messages.map((message, index) => (
                    <div key={index} className='text-sm'>
                      {message}
                    </div>
                  ))
                }
              </div>
            )
          }
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>

  )
}

export default KindoTooltip