import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog';
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from '../../../common_component/base/button';
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { TransactionStudent } from 'networking/models/family';
import { useForm } from 'react-hook-form';
import CheckboxField from "../../../common_component/form/CheckboxField";
import InputField from '../../../common_component/form/InputField';
import SelectField from '../../../common_component/form/SelectField';
import { formatCentsPriceForDisplay } from '../../../util/string_util';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common_component/base/table";
import { RefundPurchase, RefundDetail } from 'networking/models/customer';

type Props = {
  purchaseId: string;
  students: TransactionStudent[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  familyId: string;
  mutate: () => void;
  isTgclUser: boolean;
  lunchboxFee: number;
};

type FormProps = {
  late_closing: boolean;
  refund_comment: string;
  refund_pid: string;
  rtype: string;
  targetFamilyId: string;
  refund_detail: RefundDetail;
  total_refund_amount: number;
  students: TransactionStudent[];
  convenience_fee: number;
}

const RefundPurchaseDialog = ({ purchaseId, students, open, familyId, onOpenChange, mutate, isTgclUser, lunchboxFee }: Props) => {
  const { toast } = useToast();
  const [error, setError] = React.useState('');
  const { control, handleSubmit, reset } = useForm<FormProps>({
    defaultValues: {
      late_closing: true,
      refund_comment: '',
      refund_pid: purchaseId,
      rtype: 'refund_purchase',
      targetFamilyId: familyId,
      refund_detail: {},
      total_refund_amount: 0,
      students: students
    }
  });

  const resetForm = () => {
    reset({
      refund_comment: '',
    })
  };

  const { trigger, isMutating } = useSWRMutation('/customer/refund', async (key, { arg }: { arg: RefundPurchase }) => {
    try {
      const response = await api.customer.refundPurchase(arg);

      if (response) {
        const res = await response.json();
        if (res.refund_detail) {
          onOpenChange(false);

          const details = Object.entries(res.refund_detail).reduce((acc, [key, value]) => {
            return `${acc}\n${key} : ${value.refunded_amount} cents`;
          }, '');

          toast({
            title: `Refunded purchase. ${res.refunded_total} cents (detailed below) was returned to the family account.`,
            description: details,
            duration: 3000,
          });
          mutate();
          resetForm();
        } else if (res.rtype === "error") {
          setError(
            res.name === 'CannotRefundHybridsException'
              ? res.name
              : `Could not refund the purchase: ${res.message}`
          );
        } else {
          setError('Something went wrong. Please try again.');
        }
      } else {
        setError('Something went wrong. Please try again.');
      }
    } catch (e) {
      setError('Something went wrong, please try again.');
    }
  });

  const transformData = (data: FormProps): RefundPurchase => {
    const refundDetail: RefundDetail = {};

    data.students.forEach((student) => {
      student.products_details.forEach((detail) => {
        if (detail.selected) {
          const supplierId = detail.supplier_id || 'unknown_supplier';
          if (!refundDetail[supplierId]) {
            refundDetail[supplierId] = {
              request_amount: 0,
              detail: {}
            };
          }

          const supplierDetail = refundDetail[supplierId];
          if (!supplierDetail.detail[student.student]) {
            supplierDetail.detail[student.student] = [];
          }

          const productDetail = {
            product_id: detail.product_id,
            product_index: detail.product_index,
            product: detail.product,
            quantity: detail.quantity,
            amount: parseInt((detail.modified_amount * 100) || detail.total_value, 10),
            stock: {
              requested: parseInt(detail.modified_stock || 0, 10),
              adjusted: 0
            }
          };

          supplierDetail.detail[student.student].push(productDetail);
          supplierDetail.request_amount += productDetail.amount;
        }
      });

      // Add convenience fee if checked for this student
      if (data.convenience_fee) {
        const ezlunchFee = {
          product_id: null,
          product_index: null,
          product: "convenience fee",
          quantity: null,
          amount: lunchboxFee,
          stock: {
            requested: 0,
            adjusted: 0
          }
        };

        if (!refundDetail.ezlunch) {
          refundDetail.ezlunch = {
            request_amount: 0,
            detail: {}
          };
        }

        if (!refundDetail.ezlunch.detail[student.student]) {
          refundDetail.ezlunch.detail[student.student] = [];
        }

        refundDetail.ezlunch.detail[student.student].push(ezlunchFee);
        refundDetail.ezlunch.request_amount += lunchboxFee;
      }
    });

    // Calculate total refund amount
    const totalRefundAmount = Object.values(refundDetail).reduce(
      (sum: number, supplier) => sum + supplier.request_amount,
      0
    );

    // Return the transformed data
    return {
      rtype: 'refund_purchase',
      targetFamilyId: data.targetFamilyId,
      refund_pid: data.refund_pid,
      refund_comment: data.refund_comment,
      refund_detail: refundDetail,
      total_refund_amount: totalRefundAmount,
      late_closing: data.late_closing
    };
  };


  const validateForm = (data: FormProps) => {
    let hasError = false;
    let errorMessages: string[] = [];

    // Check if at least one checkbox is selected
    const isAnyCheckboxSelected = data?.students?.some((student) =>
      student.products_details.some((detail: any) => detail.selected)
    );

    if (!isAnyCheckboxSelected) {
      errorMessages.push('At least one item must be selected.');
      hasError = true;
    }

    // Validate refund amount and stock
    data.students.forEach((student) => {
      student.products_details.forEach((detail: any) => {
        if (detail.selected) {
          const productAmount = parseInt(detail.modified_amount || detail.total_value, 10) * 100; // Convert to cents
          const productQuantity = parseInt(detail.modified_stock || 0, 10);

          if (productAmount > (detail.total_value * 100)) {
            errorMessages.push(`The refund amount for ${detail.product} exceeds the item total value.`);
            hasError = true;
          }

          if (productQuantity > detail.quantity) {
            errorMessages.push(`The stock amount for ${detail.product} exceeds the quantity purchased.`);
            hasError = true;
          }
        }
      });
    });

    if (hasError) {
      setError(errorMessages.join(' ')); // Concatenate all error messages
      return false;
    }

    setError('');
    return true;
  };

  const onSubmit = (data: FormProps) => {
    if (validateForm(data)) {
      const refundData = transformData(data);
      trigger(refundData);
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='w-auto sm:min-w-[50vw]'>
        <DialogHeader>
          <DialogTitle>Refund purchase</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="space-y-4">
            {students && students.map((student, studentIndex) => (
              <div key={student.student + studentIndex}>
                <p className="mb-2 font-semibold text-card-foreground">{student.student}</p>
                <Table>
                  <TableHeader className="font-bold font-museo">
                    <TableRow>
                      <TableHead>Product</TableHead>
                      <TableHead>Amount</TableHead>
                      <TableHead className='w-8'>Stock</TableHead>
                      <TableHead className='w-[180px]'>Supplier</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    {student.products_details.map((detail, detailIndex) => (
                      <TableRow
                        className='text-card-foreground'
                        key={detail.product + detail.product_index}
                      >
                        <TableCell>
                          <CheckboxField
                            control={control}
                            name={`students[${studentIndex}].products_details[${detailIndex}].selected`}
                            label={detail.product}
                          />
                        </TableCell>
                        <TableCell className='max-w-[15px]'>
                          <InputField
                            control={control}
                            name={`students[${studentIndex}].products_details[${detailIndex}].modified_amount`}
                            defaultValue={formatCentsPriceForDisplay(detail.total_value, { dollarSign: false })}
                          />
                        </TableCell>
                        <TableCell className='w-8'>
                          <InputField
                            control={control}
                            name={`students[${studentIndex}].products_details[${detailIndex}].modified_stock`}
                            defaultValue="0"
                          />
                        </TableCell>
                        <TableCell className='min-w-[300px]'>
                          <SelectField
                            control={control}
                            name={`students[${studentIndex}].products_details[${detailIndex}].supplier_id`}
                            items={[
                              {
                                label: detail.supplier_name,
                                value: detail.supplier_id,
                              },
                              {
                                label: 'ezlunch',
                                value: 'ezlunch',
                              },
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                    {isTgclUser &&
                      <TableRow className='text-card-foreground'>
                        <TableCell>
                          <CheckboxField
                            control={control}
                            name='convenience_fee'
                            label={`Convenience fee (${formatCentsPriceForDisplay(lunchboxFee)})`}
                          />
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            ))}
            <hr />
            <InputField
              control={control}
              name="refund_comment"
              label="Comment"
              required
            />
            {error && <div className="mt-2 text-sm text-destructive">
              {error === 'CannotRefundHybridsException' ?
                <p>Purchase contains 'hybrid' products (products linked to school payables). Purchases containing hybrids cannot be refunded here. Payable refunds can be done via the <Button
                  variant="linkUnderline"
                  size="xs"
                  onClick={() => { window.open(`/app/admin/payable-status?helphub_id=512502&helphub_query=`) }}
                  name='payable-status-help'
                  className='p-0'
                >
                  apply payable
                </Button> page.
                </p>
                :
                error
              }
            </div>}
            <DialogFooter>
              <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
              <Button type="submit" disabled={isMutating}>Submit</Button>
            </DialogFooter>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default RefundPurchaseDialog;