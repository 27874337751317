import useSWR, { useSWRConfig } from 'swr';
import { baseApi, baseUrl } from './constants';
import { KindoNetworkError } from './models/common';
import { FamilyDevices, FamilyNotifications } from './models/notification';
import useSWRInfinite from 'swr/infinite';
import fetcher from './util/fetcher';

const notifications = {
  // Queries (starts with "use")
  useFamilyNotifications: ({
    limit = 10,
    offset = 0,
  } = {}) => {
    return useSWRInfinite<FamilyNotifications, KindoNetworkError>((index) => {
      return `${baseApi}/family_notifications?limit=${limit}&offset=${index * limit}`;
    }, (url) => {
      return fetcher(url).then((res) => res.json());
    });
  },
  useNotificationCount: () => {
    const queryString = new URLSearchParams({
      limit: '1',
    }).toString();
    return useSWR<FamilyNotifications, KindoNetworkError>(`${baseApi}/family_notifications?${queryString}`);
  },

  useUserDevices: () => {
    return useSWR<FamilyDevices, KindoNetworkError>(`${baseApi}/family_device`);
  },

  // Mutations
  markNotificationAsRead: (notificationId: string) => {
    return fetcher(`${baseApi}/family_notifications/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'family_notifications',
        action: 'read',
        notification_ids: [notificationId],
      }),
    });
  },

  deleteNotification: (notificationId: string) => {
    return fetcher(`${baseApi}/family_notifications/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'family_notifications',
        action: 'remove',
        notification_ids: [notificationId],
      }),
    });
  },

  markAllNotificationsAsRead: () => {
    return fetcher(`${baseApi}/family_notifications/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'family_notifications',
        action: 'read',
        mark_all: true,
      }),
    });
  },
  deleteAllNotifications: () => {
    return fetcher(`${baseApi}/family_notifications/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'family_notifications',
        action: 'remove',
        mark_all: true,
      }),
    });
  },


  registerUserDevice: ({
    deviceUniqueId,
    expoToken,
    deviceId,
    deviceOs,
    allowPushNotifications,
    deviceName,
    deviceType,
    pushToken,
  }: {
    deviceUniqueId: string,
    deviceId: string,
    deviceOs: string,
    allowPushNotifications: boolean,
    expoToken?: string,
    deviceName?: string,
    deviceType?: string,
    pushToken?: string,
  }) => {
    return fetcher(`${baseApi}/family_device`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rtype: 'family_devices',
        device_unique_id: deviceUniqueId,
        expo_token: expoToken,
        device_id: deviceId,
        ...(deviceOs ? { device_os: deviceOs } : {}),
        ...(deviceName ? { device_name: deviceName } : {}),
        ...(deviceType ? { device_type: deviceType } : {}),
        allow_push_notification: allowPushNotifications,
        push_token: pushToken,
      }),
    });
  }
}

export default notifications;