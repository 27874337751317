import React from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { baseUrl } from 'networking/constants';
import api from 'networking/api';
import { Spinner } from '../../common_component/base/spinner';
import { useStore } from 'store/store';

const LoginPageRedirect = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const location = useLocation();
  const page = params.get('page');
  const shopParam = params.get('shop');
  const serviceParam = params.get('service');
  const store = useStore();

  const { mutate } = api.family.useFamilySimple();

  const goToShopPage = () => {
    window.location.href = '/app/shop';
  }

  const goToOrderStatusPage = () => {
    navigate('/app/order-status', { replace: true });
  }

  const goToLoginPage = () => {
    store.family.setIsAuthenticated(false);
    navigate(`/app/login${window.location.search}`, { replace: true });
  }

  const goToAdminPage = () => {
    navigate('/app/admin', { replace: true });
  }

  const goToConnectPage = () => {
    navigate('/app/connect', { replace: true });
  }

  console.log('window.location', window.location)
  const checkFamily = async () => {
    try {
      const data = await mutate();
      if (data) {
        if (page && page === 'myorders') {
          window.location.href = `${baseUrl}my_orders.shtml`
        } else if (shopParam && serviceParam) {
          navigate(`/app/shop${location.search}`, { replace: true });
        } else if (data.home_page) {
          if (data.home_page.startsWith('/app')) {
            navigate(data.home_page);
          } else if (data.home_page.startsWith('/shop')) {
            window.location.href = `${data.home_page}`
          } else {
            window.location.href = `${baseUrl}${data.home_page}`
          }
        } else if (data.supplier === 'multiple') {
          goToOrderStatusPage();
        } else if (data.manager) {
          goToConnectPage();
        } else if (data.school) {
          goToAdminPage();
        } else {
          goToShopPage();
        }
      } else {
        goToLoginPage();
      }
    } catch (error) {
      goToLoginPage();
    }
  };

  React.useEffect(() => {
    checkFamily();
  }, []);

  return (
    <div className='flex flex-col items-center justify-center h-[70vh] gap-5 mt-12'>
      <Spinner />
      <p className='text-xl font-museo'>Logging in...</p>
    </div>
  )
}

export default LoginPageRedirect;
