import React from 'react';
import api from 'networking/api';
import { MenuSupplier } from 'networking/models/family';
// Dialogs
import QuickMessageDialog from './dialog/QuickMessageDialog';
import InstantTopupDialog from './dialog/InstantTopupDialog';
import PushPopPasswordDialog from './dialog/PushPopPasswordDialog';
import DisableReEnableDialog from './dialog/DisableReEnableDialog';
import ResetPasswordDialog from './dialog/ResetPasswordDialog';
import InvoiceFACLDialog from './dialog/InvoiceFACLDialog';

type Props = {
  familyId: string;
  mutate: () => void;
  pushPass?: string;
  familySupplier: MenuSupplier[];
  // checking if family has TGCL email
  isTgclEmail: boolean;
};

type DialogTypes = 'QuickMessageDialog' | 'PushPopPasswordDialog' | 'InstantTopupDialog' | 'DisableReEnableDialog' | 'ResetPasswordDialog' | 'InvoiceFACLDialog' | '';


type HelpdeskCommandItemProps = {
  title: string;
  description: React.ReactNode | string;
  onClick: () => void;
};

const HelpdeskCommandItem = ({ title, description, onClick }: HelpdeskCommandItemProps) => (
  <div
    className='flex flex-col gap-2 p-4 transition-colors rounded-lg cursor-pointer border-border border-1 hover:bg-primary-lightest'
    onClick={onClick}
  >
    <p>{title}</p>
    <p className='text-xs'>{description}</p>
  </div>
);

const HelpdeskCommands = ({ familyId, mutate, pushPass, isTgclEmail, familySupplier }: Props) => {
  const { data: familyStatus, mutate: mutateFamilyStatus } = api.family.useFamilyStatus({ familyId })

  const [activeDialog, setActiveDialog] = React.useState<DialogTypes>('');

  const openDialog = (dialogType: DialogTypes) => {
    setActiveDialog(dialogType);
  };

  return (
    <div className='grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3'>
      <HelpdeskCommandItem
        title="Quick message"
        description="Send a templated or composed text message to the account holder's mobile."
        onClick={() => openDialog('QuickMessageDialog')}
      />
      {!isTgclEmail &&
        <HelpdeskCommandItem
          title={`${pushPass ? 'Pop' : 'Push'} password`}
          description={
            pushPass ?
              <>
                <p className='text-destructive'>Password is pushed, please pop password!</p>
                <p>Releases the password hold, allowing the user to log in as normal.</p>
              </>
              :
              <p>Generates a temporary password to allow logging in as a user. This will block the user from logging in themselves.</p>

          }
          onClick={() => openDialog('PushPopPasswordDialog')}
        />
      }
      <HelpdeskCommandItem
        title="Instant topup"
        description="Tops up the users account with the specified funds on behalf of kindo."
        onClick={() => openDialog('InstantTopupDialog')}
      />
      <HelpdeskCommandItem
        title={`${familyStatus?.is_disabled ? 'Re-enable' : 'Disable'} family`}
        description={`${familyStatus?.is_disabled ? 'This will enabled a previously disabled account, meaning the account can be accessed by the user again.' : 'This will disable the account, meaning the account can no longer be accessed.'}`}
        onClick={() => openDialog('DisableReEnableDialog')}
      />
      <HelpdeskCommandItem
        title="Reset password"
        description="Sends the account holder a password reset email, including the reset link"
        onClick={() => openDialog('ResetPasswordDialog')}
      />
      <HelpdeskCommandItem
        title="Invoice FACL"
        description="Grant or Revoke this permission.  If invoice FACL is set, the accounting email (including invoice and csv's) is emailed to a user for the specified supplier."
        onClick={() => openDialog('InvoiceFACLDialog')}
      />

      {activeDialog === 'QuickMessageDialog' && (
        <QuickMessageDialog
          open={activeDialog === 'QuickMessageDialog'}
          onOpenChange={() => setActiveDialog('')}
          familyId={familyId}
        />
      )}

      {activeDialog === 'InstantTopupDialog' && (
        <InstantTopupDialog
          open={activeDialog === 'InstantTopupDialog'}
          onOpenChange={() => setActiveDialog('')}
          familyId={familyId}
          mutate={mutate}
        />
      )}

      {activeDialog === 'PushPopPasswordDialog' && (
        <PushPopPasswordDialog
          open={activeDialog === 'PushPopPasswordDialog'}
          onOpenChange={() => setActiveDialog('')}
          familyId={familyId}
          mutate={mutate}
          pushPass={pushPass}
        />
      )}

      {activeDialog === 'DisableReEnableDialog' && familyStatus && (
        <DisableReEnableDialog
          open={activeDialog === 'DisableReEnableDialog'}
          onOpenChange={() => setActiveDialog('')}
          familyId={familyId}
          mutate={mutateFamilyStatus}
          mutateFamily={mutate}
          isFamilyDisabled={familyStatus.is_disabled}
        />
      )}

      {activeDialog === 'ResetPasswordDialog' && familyStatus && (
        <ResetPasswordDialog
          open={activeDialog === 'ResetPasswordDialog'}
          onOpenChange={() => setActiveDialog('')}
          familyEmail={familyStatus.email}
          mutate={mutateFamilyStatus}
        />
      )}

      {activeDialog === 'InvoiceFACLDialog' && familyStatus && (
        <InvoiceFACLDialog
          open={activeDialog === 'InvoiceFACLDialog'}
          onOpenChange={() => setActiveDialog('')}
          familyId={familyId}
          familySupplier={familySupplier}
        />
      )}

    </div>
  );
};

export default HelpdeskCommands;
