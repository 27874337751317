import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"
import { VariantProps, cva } from "class-variance-authority"
import { cn } from "../../util/tailwind"

const tabVariants = cva(
  "inline-flex h-10 items-center rounded-md p-1",
  {
    variants: {
      variant: {
        default: "justify-start bg-background-light text-primary",
        primary: "justify-center bg-primary text-white",
        underline: "justify-start text-text-helper gap-5",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

const TabsVariantContext = React.createContext<"default" | "primary" | "underline">("default")

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
  & VariantProps<typeof tabVariants>
>(({ className, variant = "default", ...props }, ref) => (
  <TabsVariantContext.Provider value={variant ?? "default"}>
    <TabsPrimitive.List
      ref={ref}
      className={cn(tabVariants({ variant }), className)}
      {...props}
    />
  </TabsVariantContext.Provider>
))


const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const variant = React.useContext(TabsVariantContext)

  const triggerClasses = variant === "underline"
    ? "data-[state=active]:text-primary border-b-2 border-transparent data-[state=active]:border-primary "
    : "rounded-sm px-3 data-[state=active]:bg-white data-[state=active]:text-foreground data-[state=active]:shadow-sm"
  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(triggerClasses, className, "inline-flex items-center justify-center whitespace-nowrap py-1.5 text-sm font-semibold font-museo ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50")}
      {...props}
    />
  )
})
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2",
      className
    )}
    {...props}
  />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
