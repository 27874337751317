import React from 'react'
import { MdClose } from 'react-icons/md';
import { Button } from './button';
import { cn } from 'util/tailwind';

type Props = {
  text: string;
  onDelete?: () => void;
}

const Chip = (props: Props) => {
  const { text, onDelete } = props;
  return (
    <div className='flex items-center overflow-hidden rounded-md bg-background'>
      <span className={cn('py-2 pl-2 text-sm', {
        'pr-2': !onDelete,
      })}>
        {text}
      </span>
      {
        onDelete && (
          <Button name="chip-delete" className='w-[30px] h-[30px] p-2 rounded-none hover:bg-background' variant="icon" size="xs" onClick={onDelete}>
            <MdClose className='w-4 h-4' />
          </Button>
        )
      }
    </div>
  )
}

export { Chip }