import React from 'react'
import { useForm } from 'react-hook-form';
import { MdAttachMoney, MdInfoOutline, MdOpenInNew } from 'react-icons/md';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../common_component/base/dialog";
import InputField from '../../../common_component/form/InputField';
import { Button } from '../../../common_component/base/button';
import api from 'networking/api';
import { useStore } from 'store/store';
import { useToast } from 'components/base/use-toast';
import { AdminPayable } from 'networking/models/admin';
import { format } from 'date-fns';
import { Link } from 'react-router-dom';
import RadioGroupField from 'components/form/RadioGroup';
import { MyKindoTippy } from 'components/tooltip/MyKindoTippy';

export enum ActionType {
  UNAPPLY = 'unapply',
  EXEMPT = 'apply',
  HIDE = 'hide',
  UNHIDE = 'unhide',
  RETIRE = 'retire',
  REFUND = 'refund',
  CHANGE_START_AMOUNT = 'change_start_amount',
  SAVE_GROUP = 'save_group',
  PREPAID = 'prepaid',
  NONE = '',
}

enum ActionReason {
  CORRECT_ERROR = 'correct_error',
  OTHER = 'other',
  APPLIED_IN_ERROR = 'applied_in_error',
  STUDENT_EXEMPT = 'student_exempt',
  STUDENT_LEFT = 'student_left',
  END_OF_YEAR_ROLLOVER = 'end_of_year_rollover',
  REFUND = 'refund',
}
export const actions = [
  {
    label: 'Select action',
    description: '',
    value: ActionType.NONE,
  },
  {
    label: 'Change Charged Amount',
    description: 'Change the charged amount for up to 10 student records.',
    infoText: 'Note: charged amount cannot be less than what has already been paid.  If needed, please contact the helpdesk for a partial refund before reducing the charged amount.',
    value: ActionType.CHANGE_START_AMOUNT,
  },
  {
    label: 'Hide',
    description: (
      <div>
        <p>
          Prevents payables from being shown on caregivers accounts or included in payment request emails.
        </p>
        <p>
          Also hides from POS and Kindo Payables reports.  Visible only when selecting the ‘include hidden’ option on Payable Status
        </p>
      </div>
    ),
    value: ActionType.HIDE,
  },
  {
    label: 'Unhide',
    description: (
      <div>
        <p>
          Restore previously hidden payables so they are shown on caregivers accounts and included in payment request emails.         </p>
        <p>
          These will also now show on POS and Kindo Payables reports.
        </p>
      </div>
    ),
    value: ActionType.UNHIDE,
  },
  {
    label: 'Unapply',
    description: 'Remove payables applied in error.  No record of these payables will be kept.  Please note this action cannot be used if payments have been made.  A refund will need to be action prior to unapplying if done in error.',
    value: ActionType.UNAPPLY,
  },
  {
    label: 'Exempt',
    description: 'Removes payables that were created by applying a payable to a room, group, year or the entire school. Note that payables applied directly to a student cannot be converted to Exemptions, but will be unapplied.',
    infoText: 'Please note this action cannot be used if payments have been made. A refund will need to be action prior to making student exempt.',
    value: ActionType.EXEMPT,
  },
  {
    label: 'Retire',
    description: (
      <p>
        Remove payables <strong>permanently</strong> including those which have been part or fully paid. This action is commonly used as students leave or during the end of year rollover/write off. We recommend exporting prior to completing this action.
        <Link to="https://support.kindo.co.nz/portal/en/kb/articles/using-actions-to-retire-a-payable" target="_blank">
          <Button
            className=''
            variant="linkUnderline"
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            Find out more <MdOpenInNew className='w-4 h-4 ml-1' />
          </Button>
        </Link>
      </p>
    ),
    value: ActionType.RETIRE,
  },
  {
    label: 'Refund',
    description: 'Refund all payments made against selected student payables and return funds to the caregiver’s myKindo account.  Please contact the helpdesk if you require a partial refund.',
    value: ActionType.REFUND,
  },
  {
    label: 'Pre-paid Enrolment',
    description: 'For donations and other items pre-paid during enrolment evenings.  This will mean these payments will be included in the Classic Student Payables Review report.',
    value: ActionType.PREPAID,
  },
  {
    label: 'Save Group',
    description: 'Create a kindo group with the selected students as members.',
    infoText: 'We recommend including the year and a clear description. Note: The group name must be unique.',
    value: ActionType.SAVE_GROUP,
  },
]
type FormData = {
  actionType?: ActionType;
  reason: ActionReason;
  comment: string;
  preview?: boolean;
  groupName: string;
  amount: number;
}
type Props = {
  payables: AdminPayable[];
  actionType?: ActionType;
  open: boolean;
  userName?: string;
  onOpenChange: (open: boolean) => void;
  onActionSuccess?: (props: {
    actionType: ActionType;
    removedBindingIds?: string[];
    groupName?: string;
  }) => void;
}

const ActionsDialog = (props: Props) => {
  const {
    payables,
    open,
    onOpenChange,
    actionType,
    onActionSuccess,
    userName,
  } = props;
  const store = useStore();
  const { toast } = useToast();
  const [showAllStudents, setShowAllStudents] = React.useState(false);
  const { control, handleSubmit, reset, setError, formState: { isSubmitting, errors } } = useForm<FormData>({
    defaultValues: {
      actionType,
    },
  });

  const hideCommentsForActions = [
    ActionType.SAVE_GROUP,
    ActionType.UNAPPLY,
  ] as ActionType[];

  const hasErrors = (values?: FormData) => {
    switch (actionType) {
      case ActionType.PREPAID:
        // Cannot set to paid for payables that have already been paid or part paid.
        if (payables.some((payable) => payable.charged_amount_in_cents_excl + payable.charged_gst_in_cents - payable.current_amount_in_cents_incl)) {
          setError('root', {
            message: 'Cannot set to paid for payables that have already been part or fully paid.',
          });
          return true;
        }
        break;
      case ActionType.CHANGE_START_AMOUNT:
        // Cannot change charged amount for more than 10 payables at once.
        if (payables.length > 10) {
          setError('root', {
            message: 'Cannot change charged amount for more than 10 payables at once.',
          });
          return true;
        }
        // Cannot change amount to less than what has already been paid.
        if (values) {
          const amountInCents = Math.round(values.amount * 100);
          if (payables.some((payable) => {
            const paidAmount = payable.charged_amount_in_cents_excl + payable.charged_gst_in_cents - payable.current_amount_in_cents_incl;
            return amountInCents < paidAmount;
          })) {
            setError('amount', {
              message: 'Cannot change charged amount to less than what has already been paid.',
            });
            return true;
          }
        }

        break;
    }
    return false;
  }
  const onSubmit = async (values: FormData) => {
    const commonParams = {
      schoolId: store.admin.currentSchool,
      preview: values.preview,
    }
    const hasError = hasErrors(values);
    if (hasError) {
      return false;
    }
    const actionLabel = actions.find((a) => a.value === values.actionType)?.label;
    const commentPrefix = `${actionLabel} by ${userName} at ${format(new Date(), 'yyyy-MM-dd')}`
    switch (values.actionType) {
      case ActionType.PREPAID:
        return api.admin.prepaidPayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          comment: `${commentPrefix}: ${values.comment}`,
        }).then((response) => {
          if (response.data?.success) {
            onOpenChange(false);
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.PREPAID,
                removedBindingIds: response.data?.bound_ids || [], // student payable ids
              })
            }
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              description: `${payables.length} payables successfully paid. To view paid payables, check 'Paid'.`,
              duration: 10000,
            })
          } else {
            toast({
              title: 'Failed',
              description: `Failed to prepay selected payables`,
              duration: 10000,
            })
          }
        })
      case ActionType.EXEMPT:
        return api.admin.unapplyPayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          comment: `${commentPrefix}: ${values.comment}`,
          reason: ActionReason.STUDENT_EXEMPT,
          replaceWithExemptIfBind: true,
        }).then((response) => {
          if (response.data?.success) {
            onOpenChange(false);
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.EXEMPT,
                removedBindingIds: response.data?.bound_ids || [], // student payable ids
              })
            }
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              description: `${response.data?.total_unapplied} payables successfully exempted. To view exempt payables, check 'Include Exempt' option under advanced filters.`,
              duration: 10000,
            })
          } else {
            toast({
              title: 'Failed',
              description: `${response.data?.public_message}`,
              duration: 10000,
            })
          }
        })
      case ActionType.UNAPPLY:
        return api.admin.unapplyPayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          comment: `${commentPrefix}: ${values.reason}`,
          reason: values.reason,
          replaceWithExemptIfBind: false,
        }).then((response) => {
          if (response.data?.success) {
            onOpenChange(false);
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              description: `${response.data?.total_unapplied} payables successfully unapplied`,
              duration: 10000,
            })
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.UNAPPLY,
                removedBindingIds: response.data?.bound_ids || [], // student payable ids
              })
            }
          } else {
            toast({
              title: 'Failed',
              description: `${response.data?.public_message}`,
              duration: 10000,
            })
          }
        })
      case ActionType.HIDE:
        return api.admin.updateVisibilityPayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          visible: false,
          comment: `${commentPrefix}: ${values.comment}`,
        }).then((response) => {
          if (response.data?.success) {
            onOpenChange(false);
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              description: `To view hidden payables, check 'Include Hidden' option under advanced filters.`,
              duration: 10000,
            })
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.HIDE,
              })
            }
          } else {
            toast({
              title: 'Failed',
              description: `Failed to hide payables`,
              duration: 10000,
            })
          }
        })
      case ActionType.UNHIDE:
        return api.admin.updateVisibilityPayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          visible: true,
          comment: `${commentPrefix}: ${values.comment}`,
        }).then((response) => {
          if (response.data?.success) {
            onOpenChange(false);
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              duration: 10000,
            })
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.HIDE,
              })
            }
          } else {
            toast({
              title: 'Failed',
              description: `Failed to unhide payables`,
              duration: 10000,
            })
          }
        })
      case ActionType.RETIRE:
        return api.admin.retirePayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          comment: `${commentPrefix}: ${values.comment}`,
          reason: ActionReason.STUDENT_LEFT,
        }).then((response) => {
          if (response.data?.success) {
            onOpenChange(false);
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              description: `${response.data?.affected_count} payables successfully retired`,
              duration: 10000,
            })
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.RETIRE,
              })
            }
          } else {
            toast({
              title: 'Failed',
              description: `Failed to retire payables`,
              duration: 10000,
            })
          }
        })
      case ActionType.CHANGE_START_AMOUNT:
        const amountInCents = Math.round(values.amount * 100);
        return api.admin.updateAmountPayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          comment: `${commentPrefix}: ${values.comment}`,
          newAmountInCents: amountInCents,
        }).then((response) => {
          if (response.data) {
            onOpenChange(false);
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              description: `Succesfully changed charged amount`,
              duration: 10000,
            })
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.CHANGE_START_AMOUNT,
              })
            }
          } else {
            toast({
              title: 'Failed',
              description: `Failed to change charged amount`,
              duration: 10000,
            })
          }
        });
      case ActionType.REFUND:
        return api.admin.refundPayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          comment: `${commentPrefix}: ${values.comment}`,
        }).then((response) => {
          if (!response.data?.errors?.length) {
            onOpenChange(!!values.preview);
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              description: `Succesfully refunded ${response.data?.successful_refunds.length} payments. ${response.data?.unsuccessful_refunds?.length! > 0 ? `${response.data?.unsuccessful_refunds.length} payments failed to refund` : ''}`,
              duration: 10000,
            })
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.REFUND,
              })
            }
          } else {
            toast({
              title: 'Failed',
              description: `Failed to refund selected payables`,
              duration: 10000,
            })
          }
        })
      case ActionType.SAVE_GROUP:
        return api.admin.groupPayables({
          ...commonParams,
          payableIds: payables.map((p) => p.student_payable_id!),
          groupId: values.groupName,
        }).then((response) => {
          if (response.data?.success) {
            onOpenChange(!!values.preview);
            toast({
              title: `Success ${values.preview ? '(Preview)' : ''}`,
              description: `Succesfully created new group '${values.groupName}' with ${response.data.group_members_added_count} members`,
              duration: 10000,
            })
            if (onActionSuccess && !values.preview) {
              onActionSuccess({
                actionType: ActionType.CHANGE_START_AMOUNT,
              })
            }
          } else {
            toast({
              title: 'Failed to create group',
              description: `${response.data?.public_message}`,
              duration: 10000,
            })
          }
        })
    }
  };

  const getTitle = () => {
    switch (actionType) {
      case ActionType.EXEMPT:
        return 'Exempt Payable';
      case ActionType.UNAPPLY:
        return 'Unapply Payable';
      case ActionType.HIDE:
        return 'Hide Payable';
      case ActionType.UNHIDE:
        return 'Unhide Payable';
      case ActionType.RETIRE:
        return 'Retire Payable';
      case ActionType.REFUND:
        return 'Refund Payable';
      case ActionType.CHANGE_START_AMOUNT:
        return 'Change Payable Charged Amount';
      case ActionType.SAVE_GROUP:
        return 'Save Payables as Group';
      case ActionType.PREPAID:
        return 'Set to paid';
      default: return '';
    }
  }
  const getDescription = () => {
    const action = actions.find((a) => a.value === actionType);
    return (
      <DialogDescription className='flex items-center gap-3'>
        {
          action?.infoText && (
            <MyKindoTippy
              tooltipContent={action?.infoText}
            >
              <div>
                <MdInfoOutline className='w-5 h-5 text-text-helper' />
              </div>
            </MyKindoTippy>
          )
        }
        {action?.description || ''}
      </DialogDescription>
    )
  }

  const reasons = React.useMemo(() => {
    switch (actionType) {
      case ActionType.UNAPPLY:
        return [
          {
            label: 'Applied in Error (rules will be disabled)',
            value: ActionReason.APPLIED_IN_ERROR,
          },
          {
            label: 'Other (rules left enabled)',
            value: ActionReason.OTHER,
          },
        ];
    }
  }, [actionType]);

  React.useEffect(() => {
    if (open) {
      reset({
        actionType,
        ...(reasons ? { reason: reasons[0].value } : {}),
      })
    }
  }, [open])
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {getTitle()}
          </DialogTitle>
          {getDescription()}
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4 text-card-foreground'>
            <p>
              {getTitle()} for {payables?.length ?? 0} selected {payables.length === 1 ? 'record' : 'records'}?
            </p>
            <div className='flex flex-col gap-2 pb-4 border-b-1 border-border'>
              <div className='max-h-[200px] overflow-auto pl-4 flex-col gap-1'>
                {
                  payables.slice(0, showAllStudents ? undefined : 5).map((payable) => (
                    <p key={payable.payable_id} className='text-sm list-disc list-item'>
                      {payable.student_preferred_first_name || payable.student_first_name} {payable.student_preferred_last_name || payable.student_last_name}
                    </p>
                  ))
                }
              </div>
              {
                payables.slice(5).length > 0 && (
                  <p className='pl-4 text-sm underline cursor-pointer text-secondary' onClick={() => setShowAllStudents(!showAllStudents)}>
                    {
                      showAllStudents ? 'Show less' : `and ${payables.slice(5).length} more...`
                    }
                  </p>
                )
              }
            </div>
            <div className='flex flex-col gap-3'>
              {
                reasons && (
                  <RadioGroupField
                    control={control}
                    name="reason"
                    options={reasons}
                    label="Reason"
                  />
                )
              }
              {
                actionType && !hideCommentsForActions.includes(actionType) && (
                  <InputField
                    name="comment"
                    control={control}
                    label="Comment"
                    required
                  />
                )
              }
              {
                actionType === ActionType.SAVE_GROUP && (
                  <InputField
                    name="groupName"
                    control={control}
                    label="Group name"
                    required
                  />
                )
              }
              {
                actionType === ActionType.CHANGE_START_AMOUNT && (
                  <InputField
                    control={control}
                    name="amount"
                    label="Amount"
                    step="0.01"
                    min="0"
                    startAdornment={<MdAttachMoney />}
                    type="number"
                  />
                )
              }
              {
                errors.root && (
                  <p className='text-base text-destructive'>
                    {errors.root.message}
                  </p>
                )
              }
            </div>
          </div>
          <DialogFooter>
            <div className='flex-1' />
            <Button variant="outline" onClick={() => onOpenChange(false)} type="button">Cancel</Button>
            <Button disabled={isSubmitting} type="submit">Confirm</Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ActionsDialog