import React from 'react'
import { useForm } from "react-hook-form";
import { Button } from '../../common_component/base/button'

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../common_component/base/dialog";
import { useStore } from '../../store/store';
import api from 'networking/api';
import { observer } from 'mobx-react';
import { useMatch } from 'react-router-dom';
import { privacyTerms } from 'networking/constants';

const TermsAndConditionsDialog = observer(() => {
  const store = useStore();
  const isInCheckout = !!useMatch('/app/checkout');
  const isInWallet = !!useMatch('/app/wallet');
  const isInShop = !!useMatch('/app/shop');
  const { data, isLoading, mutate } = api.family.useFamilySimple();
  const { data: termsData } = api.family.useTermsAndConditions();
  const [error, setError] = React.useState('');
  const { handleSubmit, formState: { isSubmitting } } = useForm();

  const handleAcceptTerms = () => {
    if (termsData) {
      return api.family.acceptTerms(termsData).then(async (response) => {
        if (response.ok) {
          try {
            await mutate();
            store.family.setShowTermsDialog(false);
            return true;
          } catch (e) {
            setError('Failed to accept Terms & Conditions');
          }
        } else {
          setError('Failed to accept Terms & Conditions');
        }
        return false;
      })
    }
    return false;
  }
  React.useEffect(() => {
    // Determine whether to show terms dialog
    if (!isLoading && data && termsData && (isInCheckout || isInWallet || isInShop)) {
      // if we are in checkout or wallet page and version mismatch
      if (data.tc_required && data.tc !== data.tc_required) {
        store.family.setShowTermsDialog(true);
      }
    }
  }, [data, termsData, isLoading, isInCheckout, isInWallet, isInShop])

  return (
    <Dialog
      open={store.family.showTermsDialog}
      onOpenChange={(open) => {
        return false;
      }}
    >
      <DialogContent hideCloseButton onInteractOutside={(e) => e.preventDefault()}>
        <DialogHeader>
          <DialogTitle className='font-museo'>
            Updated Terms and Conditions
          </DialogTitle>
          <DialogDescription>
            We updated our <a className='underline text-primary' href={privacyTerms} target="_blank" rel='noreferrer'>Terms and Conditions and Privacy Policy</a> to include information on the process for accessing data. By clicking "Agree", you confirm that you have read and agree to our updated terms.
          </DialogDescription>
        </DialogHeader>
        <form
          className='flex flex-col gap-4'
          onSubmit={handleSubmit(handleAcceptTerms)}
        >
          {
            error &&
            <p className='text-xs text-danger-foreground'>
              {error}
            </p>
          }
          <div className='flex flex-col w-full pb-3'>
            <Button
              className='px-2'
              disabled={isSubmitting}
              size="lg"
              type="submit"
            >
              Agree
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  )
});

export default TermsAndConditionsDialog