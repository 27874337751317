import React from 'react';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
  getFacetedUniqueValues,
} from "@tanstack/react-table"
import { useVirtualizer } from '@tanstack/react-virtual'
import { Button } from "../../common_component/base/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../common_component/base/table";
import { MdFilterAlt, MdSearch } from 'react-icons/md'
import { Spinner } from '../../common_component/base/spinner';
import { RowItem } from './StockColumns';
import { Input } from '../../common_component/base/input';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose
} from "../../common_component/base/dialog";
import { Checkbox } from '../../common_component/base/checkbox';
import { cn } from '../../util/tailwind'
import Papa from 'papaparse';
import { format, formatDistanceToNow } from 'date-fns';
import { MdRefresh } from 'react-icons/md';
import { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider } from 'components/base/tooltip';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: RowItem[]
  isLoading?: boolean;
  dataLastUpdated?: Date;
  mutate: () => void;
}

type Filter = {
  id: string;
  value: any;
};

const COLUMN_EXPORT_ORDER = [
  'product',
  'mcat',
  'stock'
]

export function StocksTable<TData, TValue>({
  data,
  columns,
  isLoading,
  dataLastUpdated,
  mutate
}: DataTableProps<TData, TValue>) {
  const parentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = React.useState({})
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = React.useState('')
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])

  const table = useReactTable({
    data,
    columns,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnFiltersChange: setColumnFilters,
    getFacetedUniqueValues: getFacetedUniqueValues(),
    sortDescFirst: false,
    filterFns: {
      'multiSelect': (row, id, filterValue) => {
        const rowValue = row.original[id];
        return columnFilters.some((filter) => filter.value === rowValue);
      }
    },
    state: {
      rowSelection,
      sorting,
      globalFilter,
      columnFilters,
    },

  })

  const { rows } = table.getRowModel()

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 65,
    overscan: 5,
  })

  const virtualItems = rowVirtualizer.getVirtualItems();
  const [selectedFilters, setSelectedFilters] = React.useState<Filter[]>([]);

  const getColumnName = (key: string) => {
    switch (key) {
      case 'product':
        return 'Product';
      case 'mcat':
        return 'Category';
      case 'stock':
        return 'Stock';
      default:
        return '';
    }
  }
  const handleExport = () => {
    const visibleColumns = table.getVisibleFlatColumns();
    const orderedColumns = visibleColumns.reduce<string[]>((all, column) => {
      if (column.id && getColumnName(column.id)) {
        return [
          ...all,
          column.id,
        ]
      }
      return all;
    }, [])
      .sort((a, b) => {
        return COLUMN_EXPORT_ORDER.indexOf(a) - COLUMN_EXPORT_ORDER.indexOf(b);
      });

    // Preparing data for building csv
    const data = rows.reduce<string[][]>((allData, row) => {
      const rowData = orderedColumns.reduce<string[]>((all, columnKey) => {
        const value = row.original[columnKey as keyof RowItem] as string;
        return [
          ...all,
          value,
        ]
      }, []);;
      return [
        ...allData,
        rowData,
      ]
    }, []);
    const csvData = Papa.unparse({
      fields: orderedColumns.map((columnKey: string) => getColumnName(columnKey)),
      data
    });

    // Create a blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a hidden <a> element and click it to trigger the download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `product-stock-levels-${format(new Date(), 'dd-MM-yyyy')}.csv`);
    link.style.visibility = 'hidden';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }

  return (
    <>
      <div className='pt-4'>
        <p className='text-xl font-semibold font-museo text-primary'>Stock levels / inventory</p>
      </div>
      <div className='flex flex-wrap justify-between gap-8 py-6 sm:flex-nowrap'>
        <div className='flex flex-col sm:basis-[250px] basis-[60%]'>
          <p className='pb-2'>Keyword search</p>
          <Input
            placeholder='Search'
            value={globalFilter}
            className='h-9'
            onChange={(e) => setGlobalFilter(e.target.value)}
            endAdornment={<MdSearch className="w-4 h-4" />}
          />
        </div>
        <div className='flex items-end justify-end gap-4'>
          <Button disabled={isLoading} variant="outline" size="sm" onClick={handleExport}>
            Export
          </Button>
        </div>
      </div>
      {dataLastUpdated &&
        <div className='flex items-center justify-end gap-2 pb-2'>
          <p className='text-sm italic'>Stock data last updated <TooltipProvider delayDuration={150}>
            <Tooltip>
              <TooltipTrigger className='text-sm italic text-primary'>{formatDistanceToNow(new Date(dataLastUpdated), { includeSeconds: true })}</TooltipTrigger>
              <TooltipContent className='text-card-foreground'>{format(dataLastUpdated, 'EEE yyyy-MM-dd HH:mm:ss')}</TooltipContent>
            </Tooltip>
          </TooltipProvider> ago</p>
          <MdRefresh className='cursor-pointer text-primary' onClick={() => mutate()} />
        </div>
      }
      {data && data.length !== 0 && !isLoading &&
        <>
          <hr />
          <div className='flex flex-col'>
            <div ref={parentRef} className="text-sm border-t">
              <div style={{ height: `${rowVirtualizer.getTotalSize() + 60}px` }} className='min-h-[150px]'>
                <Table>
                  <TableHeader
                    className='sticky top-0 table-header-group bg-gray-50 border-b-1 border-border'
                  >
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id} >
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableHead key={header.id}
                              className={cn('font-bold font-museo', {
                                'text-right': header.column.id === 'stock',
                              })}
                              style={{
                                width: header.getSize() || undefined,
                              }}
                            >
                              {header.isPlaceholder
                                ? null
                                : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              {(header.column.id === 'mcat' ||
                                header.column.id === 'product') &&
                                header.column.getCanFilter() &&
                                <Dialog>
                                  <DialogTrigger asChild>
                                    <Button variant="outline" className='h-auto p-1 ml-2 text-xs' size='xs'><MdFilterAlt /></Button>
                                  </DialogTrigger>
                                  <DialogContent className='sm:max-w-[400px]'>
                                    <DialogHeader>
                                      <DialogTitle className='mb-4 text-lg font-semibold font-museo'>
                                        Filter by {getColumnName(header.column.id)}
                                      </DialogTitle>
                                    </DialogHeader>
                                    {header.column.getFacetedUniqueValues().size >= 5 && (
                                      <div className='mb-4'>
                                        <Checkbox
                                          checked={selectedFilters.filter(item => item.id === header.column.id).length === header.column.getFacetedUniqueValues().size}
                                          label={selectedFilters.filter(item => item.id === header.column.id).length === header.column.getFacetedUniqueValues().size ? 'Deselect all' : 'Select all'}
                                          name="selectAll"
                                          onCheckedChange={(isChecked) => {
                                            if (isChecked) {
                                              const allUniqueValues = Array.from(header.column.getFacetedUniqueValues().keys()).map(value => ({ id: header.column.id, value }));
                                              setSelectedFilters(prevFilters => {
                                                const newFilters = [...prevFilters.filter(item => item.id !== header.column.id), ...allUniqueValues];
                                                return newFilters;
                                              });
                                            } else {
                                              setSelectedFilters(prevFilters => {
                                                const newFilters = prevFilters.filter(item => item.id !== header.column.id);
                                                return newFilters;
                                              });
                                            }
                                          }}
                                        />
                                      </div>
                                    )}
                                    {Array.from(header.column.getFacetedUniqueValues().keys()).sort().map((value) => (
                                      <div key={value}>
                                        <Checkbox
                                          checked={selectedFilters.some((filter) => filter.value === value)}
                                          defaultChecked={true}
                                          label={value}
                                          name={value}
                                          onCheckedChange={(isChecked) => {
                                            if (isChecked) {
                                              setSelectedFilters((prevFilters) => [
                                                ...prevFilters,
                                                { id: header.column.id, value: value },
                                              ]);
                                            } else {
                                              setSelectedFilters((prevFilters) =>
                                                prevFilters.filter((filter) => filter.value !== value)
                                              );
                                            }
                                          }}
                                        />
                                      </div>
                                    ))}
                                    <DialogFooter>
                                      <div className='flex items-center gap-3'>
                                        <DialogClose>
                                          <Button variant="outline">Cancel</Button>
                                        </DialogClose>
                                        <DialogTrigger>
                                          <Button onClick={() => setColumnFilters(selectedFilters)}>Filter</Button>
                                        </DialogTrigger>
                                      </div>
                                    </DialogFooter>
                                  </DialogContent>
                                </Dialog>
                              }
                            </TableHead>
                          )
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>
                  <TableBody>
                    {
                      isLoading && (
                        <TableRow>
                          <TableCell colSpan={columns.length} className="h-24 text-center">
                            <div className='flex items-center justify-center gap-2'>
                              <Spinner size="xs" />
                              Loading please wait
                            </div>
                          </TableCell>
                        </TableRow>
                      )
                    }
                    {rows?.length ? (
                      virtualItems.map((virtualRow, index) => {
                        const row = rows[virtualRow.index];
                        return (
                          <TableRow
                            key={row.id}
                            style={{
                              height: `${virtualRow.size}px`,
                              transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                            }}
                            variant="stripe"
                            className='table-row border-none'
                            data-state={row.getIsSelected() && "selected"}
                          >
                            {row.getVisibleCells().map((cell) => (
                              <TableCell
                                className={cn('pt-1 pb-2.5 px-4', {
                                  'text-right': cell.column.id === 'stock',
                                  'text-destructive': cell.row.original.stock === 0 && cell.column.id === 'stock',
                                })}
                                key={cell.id}
                              >
                                {console.log(cell.row.original.stock)}
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                              </TableCell>
                            ))}
                          </TableRow>
                        )
                      })
                    ) : (
                      !isLoading &&
                      <TableRow>
                        <TableCell colSpan={columns.length} className="h-24 text-center">
                          No results.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </div>
          </div>
        </>
      }
      {
        isLoading && (
          <div className='flex items-center justify-center w-full py-6'>
            <Spinner size="md" />
          </div>
        )
      }
    </>
  )
}
