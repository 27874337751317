import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import _ from "lodash";
import { MdAdd } from "react-icons/md";
import { Helmet } from "react-helmet";

import { useStore } from "../../../store/store";
import api from "networking/api";

import Breadcrumbs from "../../../common_component/layout/components/Breadcrumbs";
import { Card, CardContent, CardHeader, CardTitle } from "../../../common_component/base/card";
import ComboBoxField from "../../../common_component/form/ComboBoxField";
import { Button } from "../../../common_component/base/button";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from "../../../common_component/base/use-toast";
import FormGroup from "../../../common_component/form/FormGroup";
import RadioGroupField from "../../../common_component/form/RadioGroup";
import CheckboxField from "../../../common_component/form/CheckboxField";

import { rootBreadcrumbItems } from "../AdminPage";
import { columns } from "./Columns";
import { GroupsTable } from "./GroupsTable";
import GroupFormDialog from "./GroupFormDialog";
import AddStudentsForm from "./AddStudentsForm";
type FormData = {
  smsGroups: string;
  groupId?: string;
  includeHidden: boolean;
  smsGroupCategory?: string;
}

const GroupsPage = observer(() => {
  const navigate = useNavigate();
  const store = useStore();
  const { toast } = useToast();
  const [params] = useSearchParams();
  const [studentIdToRemove, setStudentIdToRemove] = React.useState('');
  const [showGroupForm, setShowGroupForm] = React.useState(false);
  const { control, watch, setValue, handleSubmit, formState: { isSubmitting }, } = useForm<FormData>({
    defaultValues: {
      smsGroups: params.get('smsGroups') || 'no',
      groupId: params.get('groupId') || '',
      includeHidden: false,
    },
  });
  const filterValues = watch();
  const groupId = filterValues.groupId;
  const smsGroups = filterValues.smsGroups;
  const currentSchool = store.admin.currentSchool;
  const { data: smsGroupData, isLoading: isLoadingSmsGroups } = api.admin.useGroups(currentSchool, true)
  const { data: groupsData, isLoading: isLoadingGroups, mutate: refetchGroups } = api.admin.useGroups(currentSchool, filterValues.smsGroups === 'yes')
  const { data: studentData, isLoading: isLoadingStudents, isValidating: isValidatingStudents, mutate: refetchStudents } = api.admin.useStudentsInGroups(currentSchool, filterValues.groupId)
  const items = (studentData?.roll_group_students || []).map((student) => ({
    name: `${student.preferred_first_name} ${student.preferred_last_name}`,
    room: student.room && student.room !== 'UNKNOWN' ? student.room : 'Not set',
    studentIdExt: student.student_id_ext,
    yearLevel: student.year_level,
  }))
  const hasSmsGroups = (smsGroupData?.roll_adhoc_groups || []).length > 0;
  const smsGroupCategories = Object.keys(_.groupBy(smsGroupData?.roll_adhoc_groups, 'gcat'));

  const selectedGroup = (groupsData?.roll_adhoc_groups || []).find((group) => group.group_id === filterValues.groupId);
  const studentToRemove = (studentData?.roll_group_students || []).find((student) => student.student_id_ext === studentIdToRemove)
  const filteredGroups = React.useMemo(() => {
    return groupsData?.roll_adhoc_groups
      .filter((group) => filterValues.includeHidden || group.status === 'enabled')

      .filter((group) => filterValues.smsGroups === 'yes' && filterValues.smsGroupCategory ? group.gcat === filterValues.smsGroupCategory : true)
      .sort((a, b) => a.group_id.localeCompare(b.group_id, 'en', { sensitivity: 'base' }))
      ?? [];
  }, [filterValues.includeHidden, filterValues.smsGroups, filterValues.smsGroupCategory, groupsData?.roll_adhoc_groups]);
  const columnsWithAction = React.useMemo(() => {
    if (filterValues.smsGroups === 'no') {
      // Cannot remove students from sms groups   
      return columns.concat([
        {
          id: "actions",
          header: "Actions",
          cell: ({ row }) => (
            <Button className="p-0" size="xs" variant={"link"} onClick={() => {
              setStudentIdToRemove(row.original.studentIdExt)
            }}>Remove</Button>
          ),
          enableSorting: false,
          enableHiding: false,
        }
      ])
    }
    return columns;
  }, [filterValues.smsGroups]);

  const handleNewGroup = () => {
    setShowGroupForm(true);
  }
  const handleCloseStudentRemoveDialog = () => {
    setStudentIdToRemove('');
  }
  const handleRemoveStudent = async () => {
    if (studentIdToRemove && filterValues.groupId && currentSchool) {
      const response = await api.admin.removeStudentFromGroup({
        groupId: filterValues.groupId,
        schoolId: currentSchool,
        studentIdExt: studentIdToRemove,
      });
      if (response.ok) {
        toast({
          title: "Student removed from group",
          duration: 3000,
        });
        refetchStudents();
        handleCloseStudentRemoveDialog();
      } else {
        toast({
          title: "Failed to remove student from group",
          variant: 'destructive'
        });
      }
    }
  }

  const handleToggleStatus = async () => {
    if (filterValues.groupId && currentSchool) {
      const response = await api.admin.updateGroupStatus({
        groupId: filterValues.groupId,
        status: selectedGroup?.status === 'enabled' ? 'hidden' : 'enabled',
        schoolId: currentSchool,
      });

      if (response.ok) {
        await refetchGroups();
        toast({
          title: `Group is now ${selectedGroup?.status === 'enabled' ? 'hidden' : 'visible'}`,
          duration: 3000,
        });
      } else {
        toast({
          title: "Failed to update group status",
          variant: 'destructive'
        });
      }
    }
  }

  React.useEffect(() => {
    if (!isLoadingGroups) {
      const groupExists = filteredGroups.find((group) => group.group_id === filterValues.groupId);
      if (!groupExists) {
        setValue('groupId', undefined);
      }
    }
  }, [filterValues.smsGroups, isLoadingGroups, filteredGroups]);
  return (
    <div>
      <Helmet>
        <title>Groups</title>
      </Helmet>
      <Dialog open={!!studentIdToRemove} onOpenChange={() => {
        handleCloseStudentRemoveDialog()
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Remove student from Group?</DialogTitle>
          </DialogHeader>
          <div className='flex flex-col gap-4 text-text-body'>
            <p>Are you sure you want to remove <strong>{studentToRemove?.preferred_first_name} {studentToRemove?.preferred_last_name}</strong> from <strong>{filterValues.groupId}</strong>?</p>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => handleCloseStudentRemoveDialog()}>Cancel</Button>
            <Button disabled={isSubmitting} onClick={handleSubmit(handleRemoveStudent)}>
              Remove Student
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="flex flex-col gap-4">
        <div>
          <Breadcrumbs
            items={[
              ...(rootBreadcrumbItems),
              { label: 'Groups', to: '/app/admin/groups', active: true },
            ]}
          />
          <p className="text-sm">
            {currentSchool}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <Card className="transition-shadow shadow-md hover:shadow-xl rounded-3xl">
            <CardHeader className="relative z-20 flex flex-col gap-6 pb-2 sm:pb-2">
              <CardTitle>
                Groups
              </CardTitle>

              {
                !isLoadingSmsGroups && hasSmsGroups && (
                  <RadioGroupField
                    control={control}
                    name="smsGroups"
                    label="Show groups from"
                    options={[
                      {
                        label: 'Kindo',
                        value: 'no',
                      },
                      {
                        label: 'Student Management System',
                        value: 'yes',
                      }
                    ]}
                    orientation="horizontal"
                  />
                )
              }
              <FormGroup>

                {
                  filterValues.smsGroups === 'yes' && hasSmsGroups && (
                    <ComboBoxField
                      label="Category"
                      name="smsGroupCategory"
                      control={control}
                      minWidth={150}
                      isLoading={isLoadingGroups}
                      placeholder="Any category"
                      options={[
                        {
                          label: 'Any category',
                          value: '',
                        },
                        ...(
                          smsGroupCategories
                            .map((gCategory) => ({ label: gCategory, value: gCategory })) ?? [])
                      ]}
                      withSearchIcon
                    />
                  )
                }
                <ComboBoxField
                  label="Group"
                  name="groupId"
                  control={control}
                  isLoading={isLoadingGroups}
                  minWidth={250}
                  placeholder="Search and select group"
                  options={[
                    ...(filteredGroups
                      .map((group) => ({ label: `${group.group_id}${group.status === 'hidden' ? ' (Hidden)' : ''}`, value: group.group_id })) ?? [])
                  ]}
                  labelRight={
                    (
                      filterValues.smsGroups === 'no' && (
                        <>
                          <Button
                            className="p-0 h-[14px] text-sm"
                            size="sm"
                            variant="link"
                            onClick={handleNewGroup}
                          >
                            <MdAdd className="w-4 h-4" />
                            new group
                          </Button>
                          <GroupFormDialog
                            open={showGroupForm}
                            onOpenChange={setShowGroupForm}
                            schoolId={currentSchool}
                            onGroupCreated={(groupId) => {
                              refetchGroups();
                              setValue('groupId', groupId);
                            }}
                          />
                        </>
                      )
                    )
                  }
                  withSearchIcon
                />
                <div className="flex items-center gap-3 pt-6">
                  <CheckboxField
                    name="includeHidden"
                    control={control}
                    label="Show Hidden Groups"
                  />
                  {
                    groupId && selectedGroup?.status === 'enabled' && (
                      <Button variant="outline" size="sm" onClick={() => {
                        navigate(`/app/admin/payable-apply?applyTo=adhoc&groupId=${encodeURI(groupId)}&smsGroups=${filterValues.smsGroups}`)
                      }}>
                        Apply payable
                      </Button>
                    )
                  }

                  {selectedGroup && (
                    <Button
                      size="sm"
                      disabled={isSubmitting}
                      variant="outline"
                      onClick={handleSubmit(handleToggleStatus)}
                    >
                      {selectedGroup?.status === 'enabled' ? 'Hide' : 'Unhide'}
                    </Button>
                  )}
                </div>
              </FormGroup>
              {
                smsGroups === 'no' && groupId && (
                  <FormGroup>
                    <AddStudentsForm
                      groupId={groupId}
                      schoolId={currentSchool}
                      excludeStudentIds={items.map((item) => item.studentIdExt)}
                    />
                  </FormGroup>
                )
              }

            </CardHeader>
            <CardContent className="p-0 sm:p-0">

              <div className="mt-6 border-t-1 border-border">
                <GroupsTable
                  columns={columnsWithAction}
                  data={items || []}
                  smsGroups={filterValues.smsGroups}
                  isLoading={isLoadingStudents || isValidatingStudents}
                  schoolId={currentSchool}
                  groupId={filterValues.groupId}
                  onRefresh={refetchStudents}
                />
              </div>
            </CardContent>
          </Card>
        </div>

      </div>
    </div>
  )
})

export default GroupsPage;
