import React from 'react'

type Props = {
  title: string;
  description?: string | React.ReactNode;
  children: React.ReactNode;
}

const FormSection = (props: Props) => {
  const { title, description, children } = props;
  return (
    <div className='grid grid-cols-1 sm:grid-cols-[1fr_3fr] sm:gap-12 border-b-1 border-border pb-8 mb-8'>
      <div className='flex flex-col gap-2'>
        <h4 className='font-bold text-md font-museo text-text-body'>{title}</h4>
        {description && (
          <p className='text-sm text-text-helper'>
            {description}
          </p>
        )}
      </div>
      <div className='flex flex-col gap-6'>
        {children}
      </div>
    </div>
  )
}

export default FormSection