import React from 'react'
import { Button } from '../../../common_component/base/button'
import api from 'networking/api'
import { formatCentsPriceForDisplay } from '../../../util/string_util'
import Table from '../../../common_component/Table'
import { useForm } from 'react-hook-form';
import InputField from '../../../common_component/form/InputField'
import { MdAttachMoney, MdCheck, MdInfo } from 'react-icons/md'
import { useStore } from '../../../store/store'
import _ from 'lodash'
import { Product, Student } from 'networking/models/family'
import { format } from 'date-fns'
import ProductChoices from '../dialog/ProductChoices'
import { Skeleton } from '../../../common_component/base/skeleton'
import ProductDetail from '../dialog/ProductDetail'
import { useToast } from '../../../common_component/base/use-toast';
import { observer } from 'mobx-react'
import { cn } from '../../../util/tailwind'
import analytics from '../../../util/googleAnalytics'
import { ScrollArea } from '../../../common_component/base/scroll-area'
import useSchoolServices from 'networking/hooks/useSchoolService'

type Props = {
  showTitle?: boolean;
  withCard?: boolean;
  listName: 'Fees and Donations' | 'Precheckout';
}

const FeesAndDonations = observer((props: Props) => {
  const { showTitle, listName, withCard } = props;
  const [showProductChoices, setShowProductChoices] = React.useState(false);
  const [showProductDetail, setShowProductDetail] = React.useState(false);
  const { toast } = useToast();

  const { family: { currentSchool }, shop } = useStore();
  const { data: familyData, isLoading: isLoadingFamilyData } = api.family.useFamily();
  const { data, isLoading } = api.family.useFamilyPayables(currentSchool);
  const { data: orderData, isLoading: isLoadingOrder } = api.order.useOrderItems();

  const {
    feesAndDonationsService,
  } = useSchoolServices({
    schoolId: currentSchool,
  })
  const linkedStudentsForSchool = familyData?.students.filter((s) => s.school === currentSchool && s.external_id) ?? [];
  const { control, handleSubmit, setValue, getValues, watch, formState: { isSubmitting } } = useForm<{
    selectedProduct: Product;
    selectedStudent: Student;
    students: {
      [studentId: string]: {
        amounts: {
          [payableId: string]: string;
        }
      }
    }
  }>();
  const selectedProduct = watch('selectedProduct');
  const selectedStudent = watch('selectedStudent');

  React.useEffect(() => {
    if (!isLoading) {
      const defaultValues = {
        students: data?.students.reduce((acc, student) => {
          return {
            ...acc,
            [student.student_id]: {
              amounts: student.products.reduce((allProducts, product) => {
                if (product.payable) {
                  const cartProps = { schoolId: currentSchool, student, productId: product.productId, payableId: product.payable.payable_id }
                  const isInCart = shop.getQuantityInCart(cartProps) > 0;
                  if (isInCart) {
                    return {
                      ...allProducts,
                      [product.payable.payable_id]: (shop.getSubtotalForProductInCart(cartProps) / 100).toFixed(2),
                    }
                  }
                  const isInCheckout = isProductInCheckout(student.student_id, product);
                  if (isInCheckout) {
                    const priceInCheckout = orderData?.items.reduce((a, item) => {
                      return a + item.detail.students
                        .filter((s) => s.student_id === student.student_id)
                        .reduce((acc, student) => {
                          const p = student.products.find((p) => p.payable?.payable_id === product.payable?.payable_id);
                          if (p) {
                            return p.flexi_price_nominated_in_cents || p.quoted_unit_price_in_cents;
                          }
                          return acc;
                        }, 0)
                    }, 0)
                    if (priceInCheckout) {
                      return {
                        ...allProducts,
                        [product.payable.payable_id]: (priceInCheckout / 100).toFixed(2),
                      }
                    }
                  }
                  return {
                    ...allProducts,
                    [product.payable.payable_id]: parseFloat(`${(product.payable?.current_amount_in_cents_incl ?? 0) / 100}`).toFixed(2),
                  }
                }
                return allProducts;
              }, {}),
            }
          }
        }, {})
      }
      // Set default values after loading
      setValue('students', defaultValues.students)
    }
  }, [data?.students, isLoading]);

  const createOrder = async (productChoiceData) => {
    const values = getValues();
    const selectedProduct = values.selectedProduct;
    const selectedStudent = values.selectedStudent;

    if (!selectedProduct || !selectedStudent || !feesAndDonationsService || !selectedProduct.payable) {
      return Promise.resolve(false);
    }
    const enteredAmount = parseFloat(values['students'][selectedStudent.student_id]['amounts'][selectedProduct.payable.payable_id]);
    if (enteredAmount <= 0) {
      toast({
        title: 'Please enter an amount greater than 0',
      })
      return;
    }
    const maxAllowedAmount = parseFloat(`${(selectedProduct.payable?.current_amount_in_cents_incl ?? Infinity) / 100}`).toFixed(2);
    const amount = Math.min(maxAllowedAmount, enteredAmount);
    const payable = selectedProduct.payable;
    setValue(`students.${selectedStudent.student_id}.amounts.${selectedProduct.payable.payable_id}`, `${amount.toFixed(2)}`);

    shop.addToCart({
      product: {
        ...selectedProduct,
        ...(payable?.allow_part_payment ?
          { flexi_price_nominated_in_cents: Math.round(amount * 100) }  // convert to cents and only add if allow_part_payment, remove decimals (important)
          :
          { price_in_cents: payable?.current_amount_in_cents_incl }
        ),
        ...(productChoiceData.questions ? { questions: productChoiceData.questions } : {}),
        payable: {
          ...payable,
          charged_amount_in_cents_incl: payable?.current_amount_in_cents_incl,
          valid_at: format(new Date(), 'yyyy-MM-dd HH:mm:ss')
        },
      },
      student: selectedStudent,
      quantity: 1,
      schoolId: currentSchool,
      serviceId: 'fees_and_donations',
      listName,
    })
    setShowProductChoices(false);
    return Promise.resolve(true);
  }

  const isProductInCheckout = (studentId: string, product: Product) => {
    const isInCheckout = orderData?.items.reduce((inOrder, item) => {
      return inOrder || item.detail.students
        .filter((student) => student.student_id === studentId)
        .some((student) => student.products.some((p) => p.payable?.payable_id === product.payable?.payable_id))
    }, false)
    return isInCheckout;
  }

  const onSubmitQuestions = (values) => {
    return handleSubmit(() => createOrder(values))();
  }

  const handleRemoveItem = (studentId: string, payableId: string) => {
    const student = data?.students.find((s) => s.student_id === studentId);
    const product = student?.products.find((p) => p.payable?.payable_id === payableId);
    if (product && student) {
      shop.removeFromCart({
        product,
        student,
        schoolId: currentSchool,
        serviceId: 'fees_and_donations',
        quantity: 1,
      })
    }
  }

  const onAddSchoolItem = (studentId: string, payableId: string) => (values) => {
    const amount = values['students'][studentId]['amounts'][payableId];
    const student = data?.students.find((s) => s.student_id === studentId);
    const product = student?.products.find((p) => p.payable?.payable_id === payableId);

    if (!product || !student || !amount || !feesAndDonationsService) {
      return;
    }
    setValue('selectedProduct', product);
    setValue('selectedStudent', student);
    if (product.questions && product?.questions?.length > 0) {
      setShowProductChoices(true);
    } else {
      return handleSubmit(() => createOrder({}))();
    }
  }

  const isInCartOrInCheckout = (student: Student, product: Product) => {
    return shop.getQuantityInCart({
      student,
      productId: product.productId,
      payableId: product.payable?.payable_id,
      schoolId: currentSchool,
    }) > 0 || isProductInCheckout(student.student_id, product);
  }

  const getRows = (student: Student) => {
    return student.products
      .filter((p) => p.payable?.student_payable_type !== 'PREPAID')
      .sort((a, b) => a.product.localeCompare(b.product, 'en', { sensitivity: 'base' }))
      .map((product) => ({
        ...product,
        id: product.payable?.payable_id,
        payableId: product.payable?.payable_id,
        totalCost: `${formatCentsPriceForDisplay((product.payable?.charged_amount_in_cents_excl ?? 0) + (product.payable?.charged_gst_in_cents ?? 0))}`,
        currentAmount: formatCentsPriceForDisplay(product?.payable?.current_amount_in_cents_incl ?? 0),
        paid: `${formatCentsPriceForDisplay(((product.payable?.charged_amount_in_cents_excl ?? 0) + (product.payable?.charged_gst_in_cents ?? 0)) - (product?.payable?.current_amount_in_cents_incl ?? 0))}`,
        isInCart: isInCartOrInCheckout(student, product),
      }))
  };
  const handleOpenDetail = (student: Student, product: Product) => {
    setValue('selectedStudent', student);
    setValue('selectedProduct', product);
    setShowProductDetail(true);

    analytics.viewItem({
      items: [{
        productId: product.productId,
        priceInCents: parseInt(product.price_in_cents, 10),
        productName: product.product,
        brandName: 'kindo',
        serviceId: 'fees_and_donations',
        supplierId: product.supplierId,
      }],
      listName: listName,
    })
  };

  const isSelectedProductInCart = selectedProduct && selectedStudent ? isInCartOrInCheckout(selectedStudent, selectedProduct) : false;
  return (
    <>
      {
        selectedProduct && selectedStudent && (
          <ProductDetail
            open={showProductDetail}
            submitButton={(
              <Button
                disabled={isSelectedProductInCart}
                onClick={() => {
                  return handleSubmit(onAddSchoolItem(selectedStudent.student_id, selectedProduct.payable?.payable_id ?? ''))()
                }}
              >
                <span className='flex items-center gap-1'>
                  {isSelectedProductInCart ? 'Added to cart' : 'Add to cart'}
                  {isSelectedProductInCart && <MdCheck className='w-4 h-4' />}
                </span>
              </Button>
            )}
            onOpenChange={(open) => setShowProductDetail(open)}
            product={selectedProduct}
          />
        )
      }
      <ProductChoices
        item={selectedProduct || {}}
        open={showProductChoices}
        onOpenChange={(open) => setShowProductChoices(open)}
        defaultValues={{
          questions: selectedProduct?.questions.map((question) => ({
            ...question,
            answer: null,
          }))
        }}
        questions={selectedProduct?.questions ?? []}
        onSubmit={onSubmitQuestions}
      />
      {
        feesAndDonationsService && linkedStudentsForSchool?.length > 0 && (
          <div className={cn('flex flex-col gap-2', {
            'sm:gap-4 px-4 sm:px-6 py-6 rounded-lg sm:rounded-[24px] bg-card': withCard
          })}>
            {showTitle && (
              <p className='text-2xl font-museo'>
                {feesAndDonationsService?.title ?? (isLoadingFamilyData ? '' : 'Fees & Donations')}
              </p>
            )}
            <>
              {
                (isLoading || isLoadingFamilyData) && (
                  <div className='flex flex-col gap-2'>
                    <Skeleton className='w-full h-4 sm:w-[160px]' />
                    <Skeleton className='w-full h-4 sm:w-[180px]' />
                    <Skeleton className='w-full h-4 sm:w-[140px]' />
                  </div>
                )
              }
            </>
            {
              !isLoading && linkedStudentsForSchool
                .filter((s) => s.external_id) // only linked students have external_id
                .map((student) => {
                  const studentWithPayables = data?.students.find((s) => s.student_id === student.student_id);
                  return (
                    <div key={student.student_id}>
                      <p className='mb-2 text-xl text-card-foreground'>
                        {student.first_name}
                      </p>
                      {
                        !studentWithPayables && (
                          <p className='pb-4 text-sm text-card-foreground'>
                            There are no outstanding items for this student.
                          </p>
                        )
                      }
                      {
                        studentWithPayables && (
                          <div className='pb-2 sm:pb-0'>
                            <ScrollArea horizontal className='w-full'>
                              <Table
                                className='mb-2'
                                rowClassName='border-b-1 last:border-b-1 border-card bg-pale text-card-foreground hover:bg-pale last:pb-6'
                                hideHeader
                                rows={getRows(studentWithPayables)}
                                columns={[
                                  {
                                    dataKey: 'product',
                                    title: 'Product',
                                    cellClassName: 'px-3 py-1.5 rounded-tl-lg rounded-bl-lg w-[1%]',
                                    render: (row) => {
                                      return (
                                        <div className='flex items-start gap-2'>
                                          <div className=' w-[180px] sm:w-[250px]'>
                                            {row.description ? (
                                              <Button
                                                variant="link"
                                                className='justify-start h-auto p-0 text-left whitespace-normal text-card-foreground'
                                                onClick={() => handleOpenDetail(studentWithPayables, row)}
                                              >
                                                {row.product}
                                              </Button>
                                            ) : (
                                              <p className='whitespace-normal'>
                                                {
                                                  row.product
                                                }
                                              </p>
                                            )}
                                          </div>
                                          {
                                            row.description && (
                                              <Button
                                                className='w-4 h-4 p-0 mt-0.5 rounded-full bg-secondary'
                                                onClick={() => handleOpenDetail(studentWithPayables, row)}
                                              >
                                                <MdInfo
                                                  className='text-xl cursor-pointer text-primary-foreground'
                                                />
                                              </Button>
                                            )
                                          }
                                        </div>
                                      )
                                    }
                                  },
                                  {
                                    dataKey: 'empty',
                                    title: '',
                                    cellClassName: 'w-3',
                                  },
                                  {
                                    dataKey: 'total',
                                    title: 'Total',
                                    cellClassName: 'w-[1%] whitespace-nowrap px-0 py-1.5 text-pale-foreground text-left',
                                    render: () => <p>Total:</p>
                                  },
                                  {
                                    dataKey: 'totalCost',
                                    title: 'Total Cost',
                                    cellClassName: 'w-[1%] whitespace-nowrap pr-6 px-2 py-1.5 text-pale-foreground text-right',
                                  },
                                  {
                                    dataKey: 'empty',
                                    title: '',
                                    cellClassName: 'w-3 sm:w-6 md:w-8',
                                  },
                                  {
                                    dataKey: 'paid',
                                    title: 'Paid',
                                    cellClassName: 'w-[1%] whitespace-nowrap px-0 py-1.5 text-secondary text-left',
                                    render: () => <p>Paid:</p>
                                  },
                                  {
                                    dataKey: 'paid',
                                    title: 'Paid',
                                    cellClassName: 'w-[1%] whitespace-nowrap pr-2 px-2 py-1.5 text-secondary text-right',
                                  },
                                  {
                                    dataKey: 'empty',
                                    title: '',
                                    cellClassName: 'w-3 sm:w-6 md:w-8',
                                  },
                                  {
                                    dataKey: 'add',
                                    title: 'Add',
                                    cellClassName: 'p-0 w-[1%] shadow-left-center md:shadow-none  whitespace-nowrap bg-pale sticky right-0 rounded-tr-lg rounded-br-lg',
                                    render: (row) => (
                                      <div className='flex px-3 py-1.5 items-center shadow-left-center md:shadow-none gap-4 justify-end h-[100%]'>
                                        <InputField
                                          tabIndex={-1}
                                          autoFocus={false}
                                          disabled={row.isInCart || !row.payable.allow_part_payment}
                                          max={parseFloat(`${row.payable.current_amount_in_cents_incl / 100}`).toFixed(2)}
                                          className={cn('h-[30px] w-[85px] text-right', {
                                            'disabled:bg-transparent': !row.payable.allow_part_payment
                                          })}
                                          control={control}
                                          startAdornment={<MdAttachMoney />}
                                          type="number"
                                          name={`students.${studentWithPayables.student_id}.amounts.${row.payableId}`}
                                        />
                                        <Button
                                          disabled={isSubmitting || row.isInCart}
                                          variant={row.isInCart ? 'ghost' : 'default'}
                                          size="xs"
                                          className={cn('w-[42px] sm:w-[130px] h-[30px]', {
                                            'bg-background disabled:opacity-100 text-primary': row.isInCart,
                                          })}
                                          onClick={() => {
                                            if (!row.isInCart) {
                                              return handleSubmit(onAddSchoolItem(studentWithPayables.student_id, row.payableId))()
                                            }
                                          }}
                                        >
                                          <span className='items-center hidden gap-1 sm:flex'>
                                            {row.isInCart ? 'Added to cart' : 'Add to cart'}
                                            {row.isInCart && <MdCheck className='w-4 h-4' />}
                                          </span>
                                          <span className='block sm:hidden'>
                                            {row.isInCart ? <MdCheck className='w-4 h-4' /> : 'Add'}
                                          </span>
                                        </Button>
                                      </div>
                                    )
                                  }
                                ]}
                              />
                            </ScrollArea>
                          </div>
                        )
                      }
                    </div>
                  )
                })
            }
          </div>
        )
      }
    </>
  )
})

export default FeesAndDonations