import * as React from "react"
import { Slot } from "@radix-ui/react-slot"
import { VariantProps, cva } from "class-variance-authority"

import { cn } from "../../util/tailwind"
import _ from "lodash"

const buttonVariants = cva(
  "inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0 disabled:opacity-50 disabled:pointer-events-none ring-offset-background",
  {
    variants: {
      size: {
        xs: 'h-7 py-3 px-2 rounded-md',
        default: "h-10 py-2 px-4",
        sm: "h-9 px-3 rounded-md",
        lg: "h-11 px-8 rounded-md",
      },
      variant: {
        default: "bg-primary text-primary-foreground hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground hover:bg-destructive/90",
        outline:
          "border border-primary hover:bg-secondary bg-plain text-foreground hover:bg-secondary hover:text-secondary-foreground",
        secondary:
          "bg-secondary text-secondary-foreground hover:bg-secondary/80",
        plain: "bg-plain text-foreground hover:bg-secondary hover:text-secondary-foreground",
        accent: "bg-accent text-accent-foreground hover:bg-accent/90",
        ghost: "hover:bg-secondary hover:text-secondary-foreground",
        link: "underline-offset-4 hover:underline text-primary",
        linkUnderline: "underline-offset-4 underline text-primary",
        icon: "p-0 h-auto text-card-foreground/70 hover:text-card-foreground/100 bg-transparent hover:bg-gray-100 rounded-full",
        iconPrimary: "hover:bg-primary-light text-primary",
        iconWithBg: "p-1 bg-background-light h-auto text-primary hover:text-primary/80 hover:bg-background-light/90 rounded-full",
        list: "bg-card hover:bg-primary-light text-primary-foreground",
        light: "bg-primary-light hover:bg-card text-primary-foreground",
        text: "bg-transparent text-text-body focus-visible:ring-0",
        textPrimary: "bg-transparent text-primary focus-visible:ring-0",
        textSecondary: "bg-transparent text-secondary focus-visible:ring-0",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
  VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        data-testid={`button-${props.name || _.kebabCase(typeof props.children === "string" ? props.children?.toString() : "")}`}
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)
Button.displayName = "Button"

export { Button, buttonVariants }
