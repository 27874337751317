import React from 'react'
import { Button } from '../../base/button';
import { MdChevronRight } from 'react-icons/md';

import { Link } from 'react-router-dom';
type Props = {
  items: Array<{
    label: string;
    to: string;
    active?: boolean;
  }>
}
const Breadcrumbs = ({ items }: Props) => {
  if (!items || items.length === 0) return null;
  return (
    <div className="flex items-center gap-3 py-1">
      {
        items.map(({ label, to, active }, index) => (
          <>
            <span key={to} className='flex items-center'>
              <Link to={to}>
                <Button
                  size="sm"
                  variant="link"
                  className={`text-sm px-0 ${active ? 'font-bold' : ''}`}
                  key={to}
                >
                  {label}
                </Button>
              </Link>
            </span>
            {index !== items.length - 1 && (
              <span>
                <MdChevronRight />
              </span>
            )}
          </>
        ))
      }
    </div>
  )
}

export default Breadcrumbs