import React from "react";
import { useForm } from "react-hook-form";
import { unstable_usePrompt as usePrompt, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import _ from "lodash";

import { useStore } from "../../../store/store";
import api from "networking/api";
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from "../../../common_component/base/card";
import Breadcrumbs from "../../../common_component/layout/components/Breadcrumbs";
import ComboBoxField from "../../../common_component/form/ComboBoxField";
import { Button } from "../../../common_component/base/button";
import InputField from "../../../common_component/form/InputField";
import { useToast } from "../../../common_component/base/use-toast";
import { Skeleton } from "../../../common_component/base/skeleton";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import InfoTooltip from "../../../common_component/tooltip/InfoTooltip";
import { Label } from "../../../common_component/base/label";
import FormGroup from "../../../common_component/form/FormGroup";

import { rootBreadcrumbItems } from "../AdminPage";
import PayableCategoryFormDialog from "./PayableCategoryDialog";
import useAdminPayables from "networking/hooks/useAdminPayables";
import { PayableStatus } from "networking/models/payable";

type FormData = {
  maxInstances: number;
  pcat: string; // payable category name
}

const PayableEditPage = observer(() => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const store = useStore();
  const [pcatToRemove, setPcatToRemove] = React.useState('');
  const [pcatToEdit, setPcatToEdit] = React.useState('');
  const [showPayableCategoryForm, setShowPayableCategoryForm] = React.useState(false);
  const { protoPayableId } = useParams();
  const { control, watch, reset, setValue, handleSubmit, formState: { isSubmitting, errors, isDirty } } = useForm<FormData>({
    defaultValues: {
    },
  });
  usePrompt({
    when: isDirty,
    message: 'You have unsaved changes. Are you sure you want to leave?',
  })

  const formValues = watch();
  const currentSchool = store.admin.currentSchool;
  const { data: payableCategoriesData, isLoading: isLoadingPayableCategories, mutate: refetchPayableCategories, isValidating: isValidatingPayableCategories } = api.admin.usePayableCategories(currentSchool);
  const { data: protoPayablesData, isLoading: isLoadingProtoPayables, mutate } = api.admin.useProtoPayables(currentSchool, {
  });
  const protoPayable = protoPayablesData?.proto_payables.find((protoPayable) => protoPayable.proto_payable_id === protoPayableId);
  const permanentId = protoPayable?.product_permanent_id;

  const {
    payable,
    payableQuestions,
    mutate: refetchPayables,
    refreshPayables,
    createAndUpdatePayables,
  } = useAdminPayables({
    schoolId: currentSchool,
    payablePermanentId: permanentId,
  });

  const onSubmit = async (values: FormData) => {

    if (protoPayableId && payable) {
      const payablesBefore = [
        {
          ...payable,
          proto_payable_id: undefined,
          status: PayableStatus.ACTIVE,
        }
      ];

      const questionsBefore = {
        [permanentId]: payableQuestions || []
      }

      const payablesAfter = [
        {
          ...payable,
          status: PayableStatus.ACTIVE,
          pcat: values.pcat || null,
          max_instances: values.maxInstances || null,
        },
      ]

      await createAndUpdatePayables.trigger({
        data: {
          after: payablesAfter,
          before: payablesBefore,
          product_question_after: {},
          product_question_before: questionsBefore,
        }
      }).then(async (response) => {
        if (response && !response.error) {
          await refreshPayables.trigger();
          const data = await mutate();
          if (data?.proto_payables) {
            if (protoPayable) {
              reset({
                maxInstances: protoPayable.max_instances,
                pcat: protoPayable.pcat,
              })
            }
          }
          refetchPayables();

          toast({
            title: 'Payable updated successfully',
            duration: 3000,
          });
          navigate(-1);
        } else {
          toast({
            title: 'Payable failed to update',
            variant: "destructive",
            duration: 5000,
          })
        }
      });
    }
  }

  const handleAddPayableCategory = () => {
    setShowPayableCategoryForm(true);
    setPcatToEdit('');
  }

  const handleEditPayableCategory = () => {
    if (formValues.pcat) {
      setShowPayableCategoryForm(true);
      setPcatToEdit(formValues.pcat);
    }
  }

  const handleRemovePayableCategory = () => {
    if (formValues.pcat) {
      setPcatToRemove(formValues.pcat);
    }
  }

  const removePayableCategory = async () => {
    if (pcatToRemove) {
      const response = await api.admin.deletePayableCategory({
        schoolId: currentSchool,
        categoryName: pcatToRemove,
      });

      if (response.ok) {
        toast({
          title: 'Payable category removed successfully',
          duration: 3000,
        })
        handleClosePayableCategoryRemoveDialog();
        refetchPayableCategories();
        setValue('pcat', '');
      } else {
        toast({
          title: 'Payable category failed to remove',
          variant: "destructive",
          duration: 5000,
        })
      }
    }
  }
  const handleClosePayableCategoryRemoveDialog = () => {
    setPcatToRemove('')
  };

  React.useEffect(() => {
    if (!isLoadingProtoPayables) {
      if (protoPayable) {
        reset({
          maxInstances: protoPayable.max_instances,
          pcat: protoPayable.pcat,
        })
      } else {
        // protoPayable not found, go back to apply page
        navigate('/app/admin/payable-apply');
      }
    }
  }, [protoPayable, isLoadingProtoPayables]);

  return (
    <div>
      <Helmet>
        <title>Edit Payable</title>
      </Helmet>
      <Dialog open={!!pcatToRemove} onOpenChange={() => {
        handleClosePayableCategoryRemoveDialog();
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Remove Payable Category?</DialogTitle>
          </DialogHeader>
          <div className='flex flex-col gap-4'>
            <p>Are you sure you want to remove <strong>{pcatToRemove}</strong>?</p>
            <p>Any payables with this category will have their category cleared.</p>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => handleClosePayableCategoryRemoveDialog()}>Cancel</Button>
            <Button disabled={isSubmitting} onClick={handleSubmit(removePayableCategory)}>
              Remove
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <PayableCategoryFormDialog
        open={showPayableCategoryForm}
        onOpenChange={(open) => setShowPayableCategoryForm(open)}
        schoolId={currentSchool}
        pcat={pcatToEdit}
        isNew={!pcatToEdit}
        onCreated={(pcat) => {
          setValue('pcat', pcat);
          refetchPayableCategories();
        }}
      />
      <div className="flex flex-col gap-4">

        <div>
          <Breadcrumbs
            items={[
              ...(rootBreadcrumbItems),
              { label: 'Payable Apply', to: '/app/admin/payable-apply' },
              { label: 'Edit Payable', to: `/app/admin/payable-apply/${protoPayableId}`, active: true },
            ]}
          />
          <p className="text-sm">
            {currentSchool}
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <Card className="transition-shadow shadow-md hover:shadow-xl rounded-3xl">
            <form onSubmit={handleSubmit(onSubmit)}>
              <CardHeader className="flex flex-col gap-4">
                <CardTitle className="font-museo">
                  {isLoadingProtoPayables ? <Skeleton className="w-[180px] h-6" /> : `${protoPayable?.proto_payable_name}`}
                </CardTitle>
              </CardHeader>
              <CardContent className="flex flex-col gap-6">
                <FormGroup>
                  <ComboBoxField
                    label="Payable Category"
                    name="pcat"
                    isLoading={isLoadingPayableCategories || isLoadingProtoPayables || isValidatingPayableCategories}
                    control={control}
                    options={[
                      ...(payableCategoriesData?.pcats.map((pcat) => ({ label: pcat, value: pcat })) ?? [])
                    ]}
                  />
                  <div className="flex gap-2 pt-6">
                    <Button
                      variant="outline"
                      size="sm"
                      type="button"
                      onClick={handleAddPayableCategory}
                    >
                      Add
                    </Button>
                    <Button
                      disabled={!formValues.pcat}
                      variant="outline"
                      size="sm"
                      type="button"
                      onClick={handleEditPayableCategory}
                    >
                      Edit
                    </Button>
                    <Button
                      type="button"
                      disabled={!formValues.pcat}
                      variant="outline"
                      size="sm"
                      onClick={handleRemovePayableCategory}
                    >
                      Remove
                    </Button>
                  </div>
                </FormGroup>
                {/* Hide for now 24/11/2024 */}
                {/* <FormGroup>
                  <div className="grid grid-cols-12 gap-4">
                    <div className="flex items-end col-span-4">
                      <div className="flex flex-col gap-1">
                        <Label>
                          Max per Student
                        </Label>
                        <div className="flex items-end gap-2">

                          <InputField
                            className="w-[85px]"
                            control={control}
                            type="number"
                            min={0}
                            placeholder="unlimited"
                            name="maxInstances"
                          />

                          <InfoTooltip
                            side="right"
                            className="mb-2"
                            messages={[
                              'This setting controls the number of these payable items that may be applied to a single student.',
                              'For example: If a student would receive this item, due to several different rules, only 1 would be applied.',
                              'Changing this value does not affect any applications of the payable to students that exist already.',
                              'Leave empty to not restrict the number of these payables a student may have.'
                            ]}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </FormGroup> */}
              </CardContent>
              <CardFooter className="gap-4">
                <Button
                  variant="outline"
                  type="button"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
                <Button disabled={isSubmitting}>
                  Save
                </Button>
              </CardFooter>
            </form>
          </Card>
        </div>

      </div>
    </div>
  )
})

export default PayableEditPage;
