import React from 'react';
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from "../../common_component/base/toast"
import { useToast } from "../../common_component/base/use-toast"
import { cn } from '../../util/tailwind';

const DEFAULT_DURATION = 1500;
export function Toaster() {
  const { toasts } = useToast()

  return (
    <ToastProvider duration={DEFAULT_DURATION}>
      {toasts.map(function ({ id, title, description, action, className, contentClassName, ...props }) {
        return (
          <Toast key={id} {...props} className={cn('', className)}>
            <div className={cn("grid gap-1", contentClassName)}>
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && (
                <ToastDescription>{description}</ToastDescription>
              )}
            </div>
            {action}
            <ToastClose />
          </Toast>
        )
      })}
      <ToastViewport />
    </ToastProvider>
  )
}
