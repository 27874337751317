import React from 'react'
import { Button } from 'components/base/button';

type Props = {
  registrationType: 'supplier' | 'organisation';
  emailLogins: Array<{
    email: string;
    title: string;
  }>;
}

const SuccessfulRegistration = ({ emailLogins, registrationType }: Props) => {
  const firstEmailLogin = emailLogins[0]?.email;

  return (
    <div className='flex flex-col gap-4 px-2 sm:px-12'>
      <p className='text-xl font-semibold font-museo'>Registration Completed</p>
      <p className='text-sm text-card-foreground'>You have successfully registered as {registrationType === 'supplier' ? 'a supplier' : 'an organisation'}.</p>
      <p className='text-sm text-card-foreground'>Below are the login details:</p>
      {emailLogins.map(({ email, title }) => (
        <div key={title}>
          <p className='text-sm font-semibold'>{title}</p>
          <p className='text-sm text-card-foreground'>Login: {email}</p>
        </div>
      ))}
      {
        registrationType === 'organisation' && (
          <p className='text-sm text-card-foreground'>
            Now you can set up services for your organisation.
          </p>
        )
      }
      <p className='text-sm text-card-foreground'>
        If you have any questions, please contact us at <a className='underline' href="mailto:helpdesk@thegrowthcollective.co.nz">helpdesk@thegrowthcollective.co.nz</a>
      </p>
      <div>
        <Button
          type='button'
          name='supplier-get-started'
          onClick={() => {
            window.open(`https://${window.location.host}/app/login?email=${firstEmailLogin}`, '_blank')
          }}
        >
          Get started
        </Button>
      </div>
    </div>
  )
}

export default SuccessfulRegistration