import React from 'react'
import { useParams } from 'react-router-dom';

const TopUpLoadingPage = () => {
  const { topupUrl } = useParams();

  React.useEffect(() => {
    if (topupUrl) {
      const decodedUrl = atob(topupUrl)
      window.location.href = decodedUrl
    }
  }, [topupUrl]);

  return null;
}

export default TopUpLoadingPage;
