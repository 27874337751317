import React from 'react';
import FileUploadDialog from 'components/dialog/FileUploadDialog';
import { Button } from 'components/base/button';
import api from 'networking/api';
import useSWRMutation from 'swr/mutation';
import { observer } from 'mobx-react';
import { parseFileUploadResponse } from 'networking/util/util';
import { Spinner } from '../../common_component/base/spinner';
import { useToast } from "../../common_component/base/use-toast";

interface FileUploaderProps {
  onUploadSuccess: (response: any) => void;
}

const FileUploader: React.FC<FileUploaderProps> = observer(({ onUploadSuccess }) => {
  const [files, setFiles] = React.useState<File[]>([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const { toast } = useToast();

  const { trigger, error, isMutating } = useSWRMutation('/registration-upload-logo', async () => {
    if (files.length === 0) {
      throw new Error('No files selected.');
    }

    try {
      const response = await api.misc.uploadPicture(files[0]);
      if (response.ok) {
        const result = await response.text();
        const parsedResult = parseFileUploadResponse(result);
        onUploadSuccess(parsedResult);
        return result;
      } else {
        throw new Error('Something went wrong, please try again');
      }

    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }

  });

  const handleOpen = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);

  const onUploadLogoCallback = async (acceptedFiles: File[]): Promise<boolean> => {
    setFiles(acceptedFiles);

    try {
      await trigger();
      return true;
    } catch (error) {
      return false;
    }
  };

  React.useEffect(() => {
    if (error) {
      toast({
        title: "Something went wrong",
        description: error
      });
    }
  }, [error]);


  return (
    <>
      <Button type="button" onClick={handleOpen}>
        Upload your logo
      </Button>
      {isOpen && (
        <FileUploadDialog
          callback={onUploadLogoCallback}
          hideCallback={handleClose}
          accept={['image/jpeg']}
        >
          {isMutating ?
            <Spinner size="xs" />
            :
            <div className='pt-2 pb-6 text-sm text-center text-text-helper'>
              <p>JPEG files only, 5MB maximum size</p>
            </div>
          }
        </FileUploadDialog>
      )}
    </>
  );
});

export default FileUploader;
