import api from 'networking/api';
import { Link } from 'react-router-dom'
import React from 'react'
import { useStore } from '../../../store/store';
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../../../common_component/base/card'
import { cn } from '../../../util/tailwind'
import { Skeleton } from '../../../common_component/base/skeleton';
import { Button } from '../../../common_component/base/button';
import _ from 'lodash';
import Image from '../../../common_component/Image';
import { getSchoolLogo, getServiceImageDirectory, productImageDirectory } from 'networking/constants';
import HomeImage from 'assets/images/hero-image.png';
import useSchoolServices from 'networking/hooks/useSchoolService';

type Props = {
}

const SingleService = (props: Props) => {
  const { family: { currentSchool } } = useStore();
  const {
    schoolService,
    kindoServices,
    feesAndDonationsService,
    ezlunchServices,
  } = useSchoolServices({
    schoolId: currentSchool,
    coreShopsOnly: true,
  });
  const singleService = kindoServices?.concat(ezlunchServices ?? [])?.[0];
  const noEzlunchServices = ezlunchServices?.length === 0;
  const { data, isLoading: isLoadingMenu } = api.order.useFullMenu(singleService);
  const { data: welcomeImageUrl } = api.family.useSwi(schoolService?.path, HomeImage);

  const items = React.useMemo(() =>
    data?.items.sort((a, b) => a.promote - b.promote) ?? [],
    [data?.items]
  );

  const { data: productImageData, isLoading: isLoadingProductImages } = api.order.useProductImages(singleService?.id, items);


  const noImagesToRender = productImageData?.length === 0;
  const onlyOneImageOrLess = (productImageData?.length || 0) <= 1;;
  const onlyTwoImages = productImageData?.length === 2;

  const isLoading = isLoadingMenu || isLoadingProductImages;
  const shouldRenderWelcomeImage = !feesAndDonationsService && noEzlunchServices
  return (
    <div className={cn('grid relative', {
      'grid-cols-1': !onlyOneImageOrLess,
      'lg:grid-cols-2': onlyOneImageOrLess && shouldRenderWelcomeImage,
      'lg:grid-cols-3': onlyTwoImages && shouldRenderWelcomeImage,
    })}>
      {singleService && (
        <Card className={cn('flex flex-col border-none rounded-lg sm:rounded-3xl h-fit', {
          'lg:col-span-2': onlyTwoImages && shouldRenderWelcomeImage,
        })}>
          <CardHeader className='flex flex-row justify-between pb-2 sm:pb-2 sm:pt-10'>
            <div className='flex items-center gap-1'>
              <CardTitle className='text-2xl font-museo text-primary'>{singleService.title}</CardTitle>
            </div>
            <div className='flex items-center justify-center p-2 rounded-lg bg-card w-[40px] h-[40px] border-1 border-border'>
              <Image alt=''
                src={`${getServiceImageDirectory({ schoolPath: schoolService?.path ?? '', serviceId: singleService?.id })}${singleService?.icon}`}
                className="object-scale-down w-full h-fit"
              />
            </div>
          </CardHeader>
          <CardContent>
            <Link to={`/app/shop/${singleService.brand_name}/${singleService.id}`}>
              {
                isLoading && (
                  <Skeleton className='w-full h-full' />
                )
              }
              <div className='grid grid-cols-[repeat(2,minmax(140px,1fr))] gap-4 sm:grid-cols-4'>
                <>
                  {
                    !isLoading && _.times(4).map((index) => {
                      const item = productImageData?.[index];
                      if ((noImagesToRender || !item) && index >= 1) return null;
                      return (
                        <div
                          key={item ? item.permanent_id : index}
                          className='flex items-center justify-center p-4 w-full max-h-[171px] h-full border-1 border-border rounded-lg overflow-hidden'
                        >
                          <Image
                            src={item ? `${productImageDirectory}/${item.supplierId}/${item.picture}` : getSchoolLogo({ schoolPath: schoolService?.path ?? '' })}
                            className={"object-contain h-full w-auto max-w-[228px] fade-in-50 rounded-lg"}
                            alt=''
                            fallbackSrcs={[
                              getSchoolLogo({ schoolPath: schoolService?.path ?? '' }),
                            ]}
                          />
                        </div>
                      )
                    })
                  }
                </>
              </div>
            </Link>
          </CardContent>
          <CardFooter className='justify-end'>
            <Link to={`/app/shop/${singleService.brand_name}/${singleService.id}`}>
              <Button>Visit shop</Button>
            </Link>
          </CardFooter>
        </Card>
      )}

      {
        shouldRenderWelcomeImage && welcomeImageUrl && (
          <Image
            src={welcomeImageUrl}
            alt=''
            className={cn('hidden lg:block justify-self-center self-center pointer-events-none object-cover rounded-3xl h-[245px] w-auto z-10 max-w-[420px]', {
              'absolute -top-[216px] right-6': !onlyOneImageOrLess,
            })}
          />
        )
      }
    </div>
  )
}

export default SingleService