import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../common_component/base/dialog";
import useRemoteConfig from 'util/hooks/useRemoteConfig';
import { MdConstruction } from 'react-icons/md';


const MaintenanceDialog = () => {
  const { inMaintenanceMode } = useRemoteConfig();

  return (
    <Dialog open={inMaintenanceMode}>
      <DialogContent hideCloseButton>
        <DialogHeader className='flex flex-col items-center py-8'>
          <div className='p-4 rounded-full bg-warning text-warning-foreground'>
            <MdConstruction className='w-6 h-6 ' />
          </div>
          <DialogTitle className='text-lg font-semibold text-text-body font-museo'>
            We'll be back soon
          </DialogTitle>
          <DialogDescription className='text-base text-center text-text-body'>
            Kindo is currently offline while we perform scheduled maintenance. Thank you for your patience.
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
};

export default MaintenanceDialog