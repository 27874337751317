import React from 'react'
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const Zoho = observer(() => {
  const store = useStore();
  const location = useLocation();
  const [shouldLoadZoho, setShouldLoadZoho] = React.useState(false);

  React.useEffect(() => {
    const shouldDisableZoho = [
      '/app/admin',
      '/app/connect',
      '/app/order-status',
    ].some((path) => location.pathname.startsWith(path))
    if (shouldDisableZoho) {
      setShouldLoadZoho(false);
      if (window['$zoho'] && window['$zoho'].salesiq?.floatbutton?.visible) {
        // Hide the floating chat button if zoho is already loaded
        window['$zoho'].salesiq.floatbutton.visible("hide");
      }
    } else {
      setShouldLoadZoho(true);
      if (window['$zoho'] && window['$zoho'].salesiq?.floatbutton?.visible) {
        // Show the floating chat button if zoho is already loaded
        window['$zoho'].salesiq.floatbutton.visible("show");
      }
    }
  }, [location.pathname])

  React.useEffect(() => {
    if (!store.family.isRenderingFromNativeApp && shouldLoadZoho) {
      store.family.setIsZohoVisible(true);
    } else {
      store.family.setIsZohoVisible(false);
    }
  }, [shouldLoadZoho, store.family])

  if (!store.family.isRenderingFromNativeApp && shouldLoadZoho) {
    return (
      <Helmet>
        <script type="text/javascript" id="zsiqchat">
          {
            `var $zoho = $zoho || { }; $zoho.salesiq = $zoho.salesiq || {widgetcode: "siq02a0dac06404f8323a201a68b9215dbf035d56d7092b133095abceb3b7351a8b9a76924104746759e2ccccc1bdf9004b", values: { }, ready: function () { } }; var d = document; s = d.createElement("script"); s.type = "text/javascript"; s.id = "zsiqscript"; s.defer = true; s.src = "https://salesiq.zohopublic.com/widget"; t = d.getElementsByTagName("script")[0]; t.parentNode.insertBefore(s, t);`
          }
        </script>
      </Helmet>
    )
  }
  return null;
})

export default Zoho