import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from "../../../common_component/base/button";
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { useForm } from 'react-hook-form';
import InputField from '../../../common_component/form/InputField';
import { CopyToClipboard } from '../../../common_component/base/copy-to-clipboard';

type Props = {
  familyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  mutate: () => void;
  pushPass?: string;
}

type FormProps = {
  reason: string
}

const PushPopPasswordDialog = ({ familyId, open, onOpenChange, mutate, pushPass }: Props) => {
  const { toast } = useToast();

  const { control, handleSubmit } = useForm<FormProps>({
    defaultValues: {}
  });

  const [pushedData, setPushedData] = React.useState<{ p: string, email: string }>();


  const { trigger, isMutating, error } = useSWRMutation('/customer/push-pop-password', async (key, { arg }: { arg: FormProps }) => {
    try {
      const response = await api.customer.pushPopPassword({ targetFamilyId: familyId, reason: arg.reason, rtype: pushPass ? 'pop_pass' : 'push_pass' });

      if (response) {
        const res = await response.json();
        if (res.rtype === 'error') {
          toast({
            title: `${res.message}`,
            duration: 3000,
          });
        } else if (res.results === "ok") {
          onOpenChange(false);
          toast({
            title: `${'Popped password for the family.'}`,
            duration: 3000,
          });
          mutate();
        } else if (res.p) {
          setPushedData(res)
          mutate();
        } else {
          toast({
            title: 'Something went wrong, please try again',
            duration: 3000,
          });
        }
      } else {
        throw new Error('Something went wrong, please try again');
      }
    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  const onSubmit = (data: FormProps) => {
    trigger(data);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{pushPass && !pushedData ? 'Pop' : 'Push'} password</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          {pushedData ?
            <div className="flex flex-col gap-2 text-sm text-card-foreground">
              <p>Password is pushed, please do NOT forget to pop password!</p>
              <p>Copy and paste the link below to a new browser to login:</p>
              <div className='flex w-full gap-3'>
                <InputField
                  variant="sm"
                  disabled
                  control={control}
                  placeholder={`https://${window.location.host}/app/login?email=${pushedData.email}&rnd=${pushedData.p}`}
                  name="pushPassUrl"
                />
                <CopyToClipboard text={`https://${window.location.host}/app/login?email=${pushedData.email}&rnd=${pushedData.p}`}>
                  {({ copyToClipboard, isCopied }) => (
                    <Button
                      size="xs"
                      className="font-museo"
                      onClick={copyToClipboard}
                      type="button"
                    >
                      {isCopied ? 'Copied!' : 'Copy'}
                    </Button>
                  )}
                </CopyToClipboard>
              </div>
            </div>
            :
            pushPass ?
              <p className="text-card-foreground">Are you sure you want to pop password for this family?</p>
              :
              <div className='flex flex-col gap-3 pb-2 text-sm text-card-foreground'>
                <p>Please enter a number from the list below or a description of the issue that requires a push pass to fix.</p>
                <div>
                  <p>1. Place order for customer after closing</p>
                  <p>2. Change order for customer</p>
                  <p>3. Check/view services and items in a shop</p>
                  <p>4. Link/relink/fix student members</p>
                  <p>5. Assist school or supplier</p>
                </div>
                <p>Other: Please type a few words.</p>
                <InputField
                  label="Reason"
                  control={control}
                  name="reason"
                />
              </div>
          }
          {error && <div className="mt-2 text-sm text-destructive">{error}</div>}
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>{pushedData ? 'Close' : 'Cancel'}</Button>
            {!pushedData &&
              <Button type="submit" disabled={isMutating}>Submit</Button>
            }
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default PushPopPasswordDialog