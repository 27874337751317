import React from 'react';
import Helmet from 'react-helmet'
import ShopLayout from "../../common_component/layout/ShopLayout";
import { useNavigate } from 'react-router-dom';
import api from 'networking/api';
import { Spinner } from '../../common_component/base/spinner';
import { Button } from '../../common_component/base/button';
import { cn } from '../../util/tailwind';
import { ScrollArea } from '../../common_component/base/scroll-area';
import TodayViewDialog from './TodayViewDialog';
import { MdCalendarToday, MdOutlineCancel, MdOutlineShoppingCart } from "react-icons/md";
import { Checkbox } from '../../common_component/base/checkbox';
import orderUtils from 'networking/util/order';
import { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogTitle, AlertDialogFooter, AlertDialogAction, AlertDialogCancel } from '../../common_component/base/alert-dialog'
import { useToast } from '../../common_component/base/use-toast';
import analytics from '../../util/googleAnalytics';
import { format, sub, startOfToday } from 'date-fns';

const CalendarPage = () => {
  const { data, isLoading } = api.family.useCalendar()
  const { data: order, mutate } = api.order.useOrderItems();

  const itemsGroupedByDeliveryDate = React.useMemo(() => {
    return orderUtils.getOrderGroupedByDeliveryDate(order?.items);
  }, [order]);

  const navigate = useNavigate();
  const { toast } = useToast();

  const [showHistory, setShowHistory] = React.useState(true)
  const [showTodayView, setShowTodayView] = React.useState(false);
  const [selectedDay, setSelectedDay] = React.useState(null);
  const [showOrders, setShowOrders] = React.useState(true)
  const [showEvents, setShowEvents] = React.useState(false)

  const removeOrderAlertContent = (student) => (
    <AlertDialogContent>
      <AlertDialogTitle>Remove this item?</AlertDialogTitle>
      <div className='flex flex-col gap-4'>
        <p>Are you sure you want to remove your order?</p>
      </div>
      <AlertDialogFooter>
        <AlertDialogCancel className='font-semibold font-museo' onClick={(e) => { e.stopPropagation(); }}>Cancel</AlertDialogCancel>
        <Button className='font-semibold font-museo'
          onClick={(e) => {
            e.stopPropagation();
            api.order.removeOrder(student.orderId).then((res) => {
              if (res.ok) {
                mutate();
                toast({
                  title: 'Removed:',
                  description: (
                    <div className='mt-2 font-semibold text-warning'>
                      {student.products.map((product) => (
                        <p className='mt-2 font-semibold text-warning'>
                          {product.product}
                        </p>
                      ))}
                    </div>
                  )
                });
                analytics.removeOrder({
                  orderId: student.orderId,
                  itemCount: student.products.length,
                  listName: 'Calendar',
                  orderPriceInCents: student.totalPrice,
                })
              }
            })
          }}
        >Remove</Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  )

  const targetRef = React.useRef<HTMLParagraphElement>(null);
  const scrollToTable = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  React.useEffect(() => {
    if (!isLoading) {
      scrollToTable();
    }
  }, [isLoading]);


  return (
    <div>
      <Helmet>
        <title>Calendar</title>
      </Helmet>
      <ShopLayout>
        <div className='px-2 sm:px-24'>
          {isLoading ?
            <div className='flex flex-col items-center justify-center h-full gap-5 mt-12'>
              <Spinner size='md' />
              <p className='text-xl font-museo'>Loading calendar...</p>
            </div>
            :
            <div>
              <div>
                <div className="flex flex-col gap-0 mt-0 sm:gap-4 sm:mt-8 sm:pb-6">
                  <h2 className="pb-2 text-3xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
                    Calendar
                  </h2>
                  <div className="overflow-hidden border-2 sm:rounded-3xl border-border-primary">
                    <div className="p-4 bg-white border-b-2">
                      <div className='flex flex-row flex-wrap items-center justify-between mb-10'>
                        <p className='text-sm'>View orders, cart items, and service opening & closing information.</p>
                        <div className='items-center hidden gap-2 sm:flex'>
                          {/* <div className='flex items-center gap-2 mr-2'>
                            <p className='text-sm'>Show past dates</p>
                            <Switch
                              checked={showHistory}
                              name='history'
                              onCheckedChange={(val) => setShowHistory(val)}
                            />
                          </div> */}
                          <Checkbox
                            checked={showOrders}
                            name='orders'
                            label="Show orders"
                            onCheckedChange={() => setShowOrders(!showOrders)}
                          />
                          <Checkbox
                            checked={showEvents}
                            name='events'
                            label="Show events"
                            onCheckedChange={() => setShowEvents(!showEvents)}
                          />
                        </div>
                      </div>
                      <table className="w-full bg-white">
                        <thead className="sticky top-0 text-sm font-museo">
                          <tr>
                            <th scope="col" className="w-1/5">
                              <p className='block sm:hidden'>MON</p>
                              <p className='hidden sm:block'>MONDAY</p>
                            </th>
                            <th scope="col" className="w-1/5">
                              <p className='block sm:hidden'>TUES</p>
                              <p className='hidden sm:block'>TUESDAY</p>
                            </th>
                            <th scope="col" className="w-1/5">
                              <p className='block sm:hidden'>WED</p>
                              <p className='hidden sm:block'>WEDNESDAY</p>
                            </th>
                            <th scope="col" className="w-1/5">
                              <p className='block sm:hidden'>THURS</p>
                              <p className='hidden sm:block'>THURSDAY</p>
                            </th>
                            <th scope="col" className="w-1/5">
                              <p className='block sm:hidden'>FRI</p>
                              <p className='hidden sm:block'>FRIDAY</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {data?.weeks.map((week) => (
                            <tr>
                              {week.days.map((day) => (
                                <td key={day.dbd}
                                  ref={day.dbd === format(sub(startOfToday(), { weeks: 1 }), 'dd MMM') ? targetRef : null}
                                  onClick={() => {
                                    setSelectedDay(day);
                                    setShowTodayView(true);
                                  }}
                                  style={{ width: '20%' }}
                                  className={cn('p-1 cursor-pointer', {
                                    'hidden': day.status === 'history' && !showHistory
                                  })}>
                                  <div className='hidden sm:block'>
                                    <div className={cn('border rounded-sm border-border-light h-20 sm:h-44 w-12 sm:w-auto', {
                                      'bg-gray-100 block': day.status === 'history' || day.status === 'nodev'
                                    })}>
                                      <div className='flex flex-row-reverse items-center justify-between p-2 pb-1 bg-gradient-to-r from-white/0 via-background/20 to-background/80'>
                                        <p className='text-xs font-semibold font-museo'>
                                          {day.dbd}
                                        </p>
                                        <Button variant='default' size='xs' className='items-center hidden h-auto p-1 text-xs sm:flex'
                                          onClick={() => {
                                            setSelectedDay(day);
                                            setShowTodayView(true);
                                          }}>
                                          <MdCalendarToday />
                                        </Button>
                                      </div>
                                      <div className='px-2 text-xs text-card-foreground'>
                                        <div className='px-2 text-xs text-card-foreground'>
                                          <ScrollArea>
                                            <div className='pt-2 h-28'>
                                              {
                                                itemsGroupedByDeliveryDate[day.ymd]?.students?.map((student) => (
                                                  <>
                                                    <div className='py-1'>
                                                      <p className='font-semibold text-primary font-museo'>
                                                        {student.firstName} <span className='italic'>(Awaiting order{student.products.length === 1 ? '' : 's'} at checkout)</span>
                                                      </p>
                                                      <ul className='pl-4 list-disc'>
                                                        {student.products.map((product) => (
                                                          <li>
                                                            <div className='flex gap-2'>
                                                              {product.product}
                                                              <AlertDialog>
                                                                <AlertDialogTrigger asChild>
                                                                  <Button onClick={(e) => { e.stopPropagation(); }} variant='icon' size='xs'>
                                                                    <MdOutlineCancel className='text-destructive' />
                                                                  </Button>
                                                                </AlertDialogTrigger>
                                                                {removeOrderAlertContent(student)}
                                                              </AlertDialog>
                                                              <Button variant='icon' size='xs' onClick={() => navigate('/app/checkout')}>
                                                                <MdOutlineShoppingCart className='text-primary' />
                                                              </Button>
                                                            </div>
                                                          </li>
                                                        ))}
                                                      </ul>
                                                    </div >

                                                  </>
                                                ))
                                              }

                                              {showOrders && day.purchases.length !== 0 && day.purchases.map((purchase) => (
                                                purchase.detail.students.map((student) => (
                                                  <div className='py-1'>
                                                    <p className='font-semibold text-primary font-museo'>
                                                      {student.firstName}
                                                    </p>
                                                    <ul className='pl-4 list-disc'>
                                                      {student.products.map((product) => (
                                                        <li>{product.product}</li>
                                                      ))}
                                                    </ul>
                                                  </div>
                                                ))
                                              ))}

                                              {showEvents && day.events.length !== 0 &&
                                                <>
                                                  <p className='font-semibold text-primary font-museo'>
                                                    Events
                                                  </p>
                                                  <div className='pl-4'>
                                                    <ul className='list-disc'>
                                                      {day.events.map((event) => (
                                                        <li>{event.text}</li>
                                                      ))}
                                                    </ul>
                                                  </div>
                                                </>
                                              }
                                            </div>
                                          </ScrollArea >
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className={cn('border rounded-sm border-border-light block h-14 sm:hidden', {
                                    'bg-gray-100 block': day.status === 'history' || day.status === 'nodev'
                                  })}>
                                    <div className='text-center bg-gradient-to-r from-white/0 via-background/20 to-background/80'>
                                      <p className='text-xs font-semibold font-museo'>
                                        {day.dbd}
                                      </p>
                                    </div>
                                    <div className='p-1 text-center text-card-foreground'>
                                      {day.purchases.length !== 0 &&
                                        <p className='text-xs'>Orders</p>
                                      }
                                      {day.events.length !== 0 &&
                                        <p className='text-xs'>Events</p>
                                      }
                                    </div>
                                  </div>
                                </td>
                              ))}
                            </tr>
                          ))}
                          {selectedDay && (
                            <TodayViewDialog
                              open={showTodayView}
                              onOpenChange={(open) => {
                                setShowTodayView(open);
                                if (!open) {
                                  setSelectedDay(null);
                                }
                              }}
                              purchases={selectedDay.purchases}
                              events={selectedDay.events}
                              date={selectedDay.dbd}
                              dateYMD={selectedDay.ymd}
                            />
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div >
              </div >
            </div >
          }
        </div >
      </ShopLayout >
    </div >
  )
}

export default CalendarPage;