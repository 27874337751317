import React from 'react'
import FeesAndDonations from '../service_shop/FeesAndDonations';
import api from 'networking/api';
import { useStore } from '../../store/store'
import { useNavigate } from 'react-router-dom';
import { Button } from '../../common_component/base/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogTitle,
  DialogHeader,
} from "../../common_component/base/dialog"
import { observer } from 'mobx-react';
import { Product, Student } from 'networking/models/family';
import useSchoolServices from 'networking/hooks/useSchoolService';

type Props = {}

const Precheckout = observer((props: Props) => {
  const store = useStore();
  const { currentSchool } = store.family;
  const { completedPrecheckout, showPrecheckout } = store.shop;
  const { data: orderData, isLoading: isLoadingOrder } = api.order.useOrderItems();

  const {
    feesAndDonationsService,
    isLoadingServices,
  } = useSchoolServices({
    schoolId: currentSchool,
  })
  const navigate = useNavigate();
  const { data: payablesData, isLoading: isLoadingPayables } = api.family.useFamilyPayables(currentSchool);

  const goToCheckout = () => {
    navigate('/app/prepare-orders', { replace: true });
    store.shop.setShowPrecheckout(false);
  }


  const isProductInCheckout = (studentId: string, product: Product) => {
    const isInCheckout = orderData?.items.reduce((inOrder, item) => {
      return inOrder || item.detail.students
        .filter((student) => student.student_id === studentId)
        .some((student) => student.products.some((p) => p.productId === product.productId))
    }, false)
    return isInCheckout;
  }
  const isInCartOrInCheckout = (student: Student, product: Product) => {
    return store.shop.getQuantityInCart({
      student,
      productId: product.productId,
      payableId: product.payable?.payable_id,
      schoolId: currentSchool,
    }) > 0 || isProductInCheckout(student.student_id, product);
  }

  React.useEffect(() => {
    if (showPrecheckout && !isLoadingServices && !feesAndDonationsService) {
      // School doesn't have fees and donations service, go to checkout now
      goToCheckout();
    }
  }, [isLoadingServices, feesAndDonationsService, showPrecheckout]);

  React.useEffect(() => {
    if (showPrecheckout && !isLoadingPayables && payablesData) {
      const hasPayables = payablesData.students.some((student) => student.products.length > 0);
      if (!hasPayables) {
        // user doesn't have any students with payables, go to checkout
        goToCheckout();
      }
    }
  }, [isLoadingPayables, payablesData, showPrecheckout]);
  React.useEffect(() => {
    // If every student has all payables in their cart or in their checkout then go to checkout
    if (showPrecheckout && !isLoadingOrder && !isLoadingPayables && orderData && payablesData) {
      const isSomeInCart = payablesData.students.some((student) => student.products.some((p) => isInCartOrInCheckout(student, p)))
      if (isSomeInCart) {
        goToCheckout();
      }
    }
  }, [isLoadingPayables, payablesData, orderData, isLoadingOrder, showPrecheckout, isInCartOrInCheckout]);

  React.useEffect(() => {
    if (showPrecheckout && completedPrecheckout) {
      // already completed precheckout, go to checkout now
      // TODO: Uncomment this when ready
      // goToCheckout();
    }
  }, [completedPrecheckout, showPrecheckout]);

  return (
    <Dialog open={showPrecheckout}>
      <DialogContent className='sm:h-auto sm:max-w-[85vw]'>
        <DialogHeader>
          <DialogTitle className='flex items-center gap-2 text-xl sm:text-2xl md:text-4xl text-primary font-museo'>
            On your way to checkout...
          </DialogTitle>
        </DialogHeader>
        {
          !isLoadingServices && feesAndDonationsService && (
            <>
              <div className='flex flex-col gap-3.5 max-w-[100%] overflow-hidden'>
                <p className='text-lg font-museo'>
                  Reminder of payments requested by your school
                </p>
                <div>
                  <FeesAndDonations listName='Precheckout' />
                </div>
              </div>
            </>
          )
        }
        <DialogFooter className='sm:justify-between sm:w-full'>
          <Button variant="plain" size="lg" onClick={() => {
            store.shop.setShowPrecheckout(false);
          }}>
            Back to shopping
          </Button>
          <Button variant="default" size="lg" onClick={() => {
            store.shop.setPrecheckout(true);
            goToCheckout()
          }}>
            Checkout
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
})

export default Precheckout