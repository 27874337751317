import React from 'react'
import { Link } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { Button } from 'components/base/button';
import { MdOpenInNew } from 'react-icons/md';
import NumberedTitle from '../NumberedTitle';
import CheckboxField from 'components/form/CheckboxField';
import RadioGroupField from 'components/form/RadioGroup';
import WalletBalance from '../WalletBalance';
import { amlSupportLink } from 'networking/constants';
import { formatCentsPriceForDisplay } from 'util/string_util';
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import ProfferResult from '../ProfferResult';
import { ProfferResponse } from 'networking/models/proffer';
import BankAccountField from 'components/form/BankAccountField';

type Props = {
  profferId: string;
  schoolIds: string[];
  withdrawableAmount: number;
  accountBalance: number;
  withdrawalFee: number;
  email: string;
}

type FormProps = {
  bankAccountNumber: string;
  haveDownloadedInfo: boolean;
  withdrawAction: 'donate' | 'transfer' | 'withdrawFull';
  donateTo: {
    [key: number]: boolean;
  };
};

enum CloseAccountStep {
  DOWNLOAD_INFO,
  WITHDRAW_BALANCE,
}
const CloseAccount = (props: Props) => {
  const {
    profferId,
    schoolIds,
    withdrawableAmount,
    accountBalance,
    withdrawalFee,
    email,
  } = props;
  const nonWithdrawableBalance = accountBalance - withdrawableAmount;
  const availableForWithdrawal = withdrawableAmount - withdrawalFee;
  const withdrawableAfterFees = withdrawableAmount - withdrawalFee;

  const [currentStep, setCurrentStep] = React.useState(CloseAccountStep.DOWNLOAD_INFO);

  const { control, handleSubmit, watch, setError, clearErrors, formState: { errors } } = useForm<FormProps>({
    defaultValues: {
      donateTo: schoolIds.reduce((acc, schoolOrCharity, index) => {
        acc[index] = true;
        return acc;
      }, {}),
    },
    reValidateMode: 'onChange'
  });

  const currentWithdrawAction = watch('withdrawAction');
  const donateTo = watch('donateTo');

  const [isSuccessful, setIsSuccessful] = React.useState(false);

  const { trigger, isMutating, error } = useSWRMutation('/proffer/close-account', (key, { arg }: { arg: FormProps }) => {
    try {
      const [bankBankNumber, bankBranchNumber, bankAccountNumber, bankSuffixNumber] = (arg.bankAccountNumber || '').split('-');
      const schools = schoolIds.filter((_, index) => arg.donateTo[index]);
      const args = {
        profferId,
        bankBankNumber,
        bankAccountNumber,
        bankBranchNumber,
        bankSuffixNumber,
        schoolIds: schools,
        disburse: arg.withdrawAction === 'transfer' ? 'family' : 'school'
      }
      return api.proffer.closeAccount(args).then(async (response) => {
        if (response.ok) {
          const responseData = (await response.json()) as ProfferResponse;
          if (responseData.success) {
            setIsSuccessful(true);
          }
          return;
        }
        setError('root', {
          message: 'Something went wrong, please try again',
        });
      });
    }
    catch (e) {
      setError('root', {
        message: 'Something went wrong, please try again',
      });
      throw new Error('Something went wrong, please try again');
    }
  })
  React.useEffect(() => {
    clearErrors();
  }, [currentWithdrawAction, clearErrors])
  const onSubmit = async (data: FormProps) => {
    switch (currentStep) {
      case CloseAccountStep.DOWNLOAD_INFO:
        setCurrentStep(CloseAccountStep.WITHDRAW_BALANCE);
        break;
      case CloseAccountStep.WITHDRAW_BALANCE:
        if (!data.withdrawAction && accountBalance > 0) {
          setError('root', {
            message: 'Please select an option',
            type: 'required'
          })
          return;
        }
        if (data.withdrawAction === 'donate') {
          const anySelected = Object.values(data.donateTo).some((selected) => selected);
          if (!anySelected) {
            setError('donateTo', {
              message: 'Please select at least one school or charity',
              type: 'required'
            })
          }
        }
        // TODO: Handle form submission
        return await trigger({
          bankAccountNumber: data.bankAccountNumber,
          haveDownloadedInfo: data.haveDownloadedInfo,
          withdrawAction: data.withdrawAction,
          donateTo: data.donateTo,
        });
    }
  }

  const renderSchoolsAndCharities = () => {
    return (
      <>
        {
          (currentWithdrawAction === 'donate' || nonWithdrawableBalance > 0) && currentWithdrawAction !== 'withdrawFull' && !!currentWithdrawAction && (
            <div className='flex flex-col gap-2'>
              {
                schoolIds.map((schoolOrCharity, index) => (
                  <CheckboxField
                    key={schoolOrCharity}
                    control={control}
                    name={`donateTo.[${index}]`}
                    label={schoolOrCharity}
                    defaultValue={true}
                    rules={{
                      validate: {
                        atLeastOne: (value) => {
                          if (index === schoolIds.length - 1) {
                            const anySelected = Object.values(donateTo).some((selected) => selected);
                            return anySelected || 'Please select at least one school or charity';
                          }
                        }
                      }
                    }}
                  />
                ))
              }
              {
                errors.donateTo?.message && (
                  <p className='text-sm text-destructive'>
                    {errors.donateTo?.message}
                  </p>
                )
              }
            </div>
          )
        }
      </>
    )
  }
  const renderStep = () => {
    switch (currentStep) {
      case CloseAccountStep.DOWNLOAD_INFO:
        return (
          <>
            <NumberedTitle
              number="1"
              title='Are you sure you want to continue?'
            />
            <div className='flex flex-col gap-4 sm:px-8'>
              <p className='text-sm text-text-body'>
                When you close your Kindo account, all information related to your account will be deleted, and you will no longer be able to access it. This includes your order history and receipts. Download or save any information you might need in the future before closing your account. This action is permanent and cannot be undone.
              </p>
              <p className='text-sm text-text-body'>
                Kindo Account: {email}
              </p>
            </div>
          </>
        );
      case CloseAccountStep.WITHDRAW_BALANCE:
        return (
          <>
            <NumberedTitle
              number="2"
              title='Manage your Kindo Wallet balance'
            />
            <div className='sm:px-8'>

              <p className='pb-4 text-sm text-text-helper'>
                To comply with anti-money laundering regulations, not all topups can be withdrawn. Learn more about&nbsp;
                <Link to={amlSupportLink} target="_blank">
                  <Button
                    className='px-0'
                    variant="linkUnderline"
                    type="button"
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    AML <MdOpenInNew className='w-4 h-4 ml-1' />
                  </Button>
                </Link>.
              </p>
              <WalletBalance
                kindoWalletBalance={accountBalance}
                withdrawableAmount={withdrawableAmount}
                withdrawalFee={withdrawalFee}
                availableForWithdrawal={availableForWithdrawal}
              />

              {
                accountBalance <= 0 && withdrawableAfterFees <= 0 && (
                  <p className='pt-4 text-sm text-text-body'>
                    Your Kindo Wallet is empty and there is nothing for you to withdraw. Thank you for using Kindo.
                  </p>
                )
              }

              {
                accountBalance > 0 && (
                  <>
                    <p className='pt-6 pb-2 font-bold text-md text-text-body font-museo'>
                      What would you like to do with your Kindo Wallet balance?
                    </p>
                    <div className='flex flex-col gap-2'>
                      {
                        withdrawableAmount >= 0.01 && (
                          <>
                            <RadioGroupField
                              control={control}
                              name="withdrawAction"
                              options={[
                                {
                                  label: `Donate your Kindo Wallet balance (${formatCentsPriceForDisplay(accountBalance)}) to one or more school or charity`,
                                  value: 'donate',
                                },
                              ]}
                            />
                            {
                              currentWithdrawAction === 'donate' && (
                                <div className='pl-9 sm:pl-[52px]'>
                                  <p className='pb-2 text-sm text-card-foreground'>Donation amount: {formatCentsPriceForDisplay(accountBalance)}</p>
                                  <p className='pb-2 text-sm text-card-foreground'>Choose one or more schools or charities to receive a portion of your donation amount:</p>

                                  {renderSchoolsAndCharities()}
                                </div>
                              )
                            }
                            {
                              withdrawableAfterFees > 0 && (
                                <RadioGroupField
                                  control={control}
                                  name="withdrawAction"
                                  options={[
                                    {
                                      label: `Transfer the amount available for withdrawal (${formatCentsPriceForDisplay(availableForWithdrawal)}) to my bank account${nonWithdrawableBalance > 0 ? ` and donate the remaining (${formatCentsPriceForDisplay(nonWithdrawableBalance)}) to one or more school or charity` : ''}`,
                                      value: 'transfer',
                                    }
                                  ]}
                                />
                              )
                            }
                            {
                              currentWithdrawAction === 'transfer' && (
                                <div className='pl-9 sm:pl-[52px]'>
                                  <p className='pb-2 text-sm text-card-foreground'>Bank transfer amount: {formatCentsPriceForDisplay(availableForWithdrawal)}</p>
                                  <div
                                    className={'w-full sm:w-[270px]'}
                                  >
                                    <BankAccountField
                                      control={control}
                                      name="bankAccountNumber"
                                      label="Bank account number"
                                      className={'w-full'}
                                      placeholder='Enter bank account number'
                                      rules={{
                                        required: 'Enter bank account',
                                      }}
                                    />
                                  </div>

                                  {
                                    nonWithdrawableBalance > 0 && (
                                      <>

                                        <p className='pt-6 pb-2 text-sm text-card-foreground'>Donation amount: {formatCentsPriceForDisplay(nonWithdrawableBalance)}</p>
                                        <p className='pb-2 text-sm text-card-foreground'>Choose one or more schools or charities to receive a portion of your donation amount:</p>
                                        {renderSchoolsAndCharities()}
                                      </>
                                    )
                                  }
                                </div>
                              )
                            }
                          </>
                        )
                      }

                      {
                        nonWithdrawableBalance > 0 && (
                          <RadioGroupField
                            control={control}
                            name="withdrawAction"
                            options={[

                              {
                                label: `Request to transfer the full Kindo Wallet balance (${formatCentsPriceForDisplay(accountBalance)}) to my bank account`,
                                value: 'withdrawFull',
                              },
                            ]}
                          />
                        )
                      }

                      {
                        currentWithdrawAction === 'withdrawFull' && (
                          <div className='flex flex-col gap-2 pl-9'>

                            <p className='text-sm text-text-body'>
                              To proceed with this option&nbsp;
                              <a className='underline text-primary' href="mailto:hello@mykindo.co.nz?subject=Close Account - Full withdrawal&body=Hi,%0D%0A%0D%0AI would like to close my account and withdraw the full balance of my kindo wallet.">email the Helpdesk</a>
                              &nbsp;then close this window. Do not continue to close your account from this page.
                            </p>
                            <p className='text-sm text-text-body'>
                              This option has $5 processing fee and required you to complete customer due diligence as required by NZ Govt Anti Money Laundering (AML) regulation.
                            </p>
                          </div>
                        )
                      }
                    </div>
                  </>
                )
              }
            </div>

            {
              errors.root?.message && (
                <p className='pt-1 text-sm pl-9 text-destructive'>
                  {errors.root?.message}
                </p>
              )
            }
          </>
        )
    }
  }

  if (isSuccessful) {
    return (
      <ProfferResult
        title="Your account has been closed"
        description={currentWithdrawAction === 'donate' ? 'Your Kindo Wallet balance has been donated to the selected schools and charities. Thank you for using Kindo.' : `${availableForWithdrawal > 0 ? 'Please allow two working days for the funds to be transferred. ' : ''}Thank you for using Kindo.`}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex flex-col'>
        {
          renderStep()
        }
        <div className='flex flex-row gap-3 pt-6'>
          {
            currentStep === CloseAccountStep.WITHDRAW_BALANCE && (
              <Button
                className='md:flex-none md:w-[120px]'
                variant="outline"
                onClick={() => setCurrentStep(CloseAccountStep.DOWNLOAD_INFO)}
              >
                Back
              </Button>
            )
          }
          <Button
            disabled={isMutating || (currentWithdrawAction === 'withdrawFull' && currentStep === CloseAccountStep.WITHDRAW_BALANCE)}
            className='flex-1 md:flex-none md:w-[180px]'
            type="submit"
          >
            {currentStep === CloseAccountStep.DOWNLOAD_INFO ? 'Next' : 'Close Kindo Account'}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default CloseAccount