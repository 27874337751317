import React from 'react'
import { Card, CardContent, CardHeader, CardTitle } from "../../common_component/base/card";
import { Family } from 'networking/models/family'
import { ScrollArea } from '../../common_component/base/scroll-area';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../common_component/base/tabs";
import Notes from './Notes';
import TransactionRecords from './TransactionRecords';
import { formatCentsPriceForDisplay } from '../../util/string_util';
import { DetailItem } from "../../common_component/base/detail-item";
import { Button } from '../../common_component/base/button';
import SavedOrderList from './SavedOrderList';
import EmailsTable from './EmailsTable';
import HelpdeskCommands from './HelpdeskCommands';
import { isTgclEmail } from 'util/util';
import StudentsDialog from './dialog/StudentsDialog';
import { MdArrowBack } from 'react-icons/md';
import Breadcrumbs from '../../common_component/layout/components/Breadcrumbs';

type Props = {
  currentFamily: Family;
  currentPage: number;
  mutate: () => void;
  onPageChange: () => void;
  isTgclUser: boolean;
  onCloseFamily: () => void;
}

const FamilyDetails = (props: Props) => {
  const { currentFamily, currentPage, mutate, onPageChange, isTgclUser, onCloseFamily } = props;
  const [currentTab, setCurrentTab] = React.useState('transactions');
  const [viewStudents, setViewStudents] = React.useState(false);

  const transactionList = currentFamily.transaction_list;

  return (
    currentFamily &&
    <Card className="transition-shadow shadow-md hover:shadow-xl rounded-3xl">
      <CardHeader>
        <CardTitle>
          <div className='flex items-center gap-2 text-xl'>
            <Button
              type='button'
              variant="icon"
              className='text-primary'
              onClick={() => onCloseFamily()}
            >
              <MdArrowBack
                size={24}
              />
            </Button>
            {currentFamily.family_name} Family
          </div>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className='grid grid-cols-2 mb-4 divide-x-2'>
          <div className=''>
            <p className='pb-4 font-museo text-primary'>User Details</p>
            <div className='grid grid-cols-2 gap-8'>
              <DetailItem withGap label="Email" value={currentFamily.email} />
              <DetailItem withGap label="Name" value={currentFamily.contact_name} />
              <DetailItem withGap label="Contact phone" value={currentFamily.contact_telephone} />
              {isTgclUser &&
                <>
                  <DetailItem withGap label="Handle" value={currentFamily.handle} />
                  <DetailItem withGap label="Last login" value={currentFamily.last_login} />
                  <DetailItem withGap label="Balance" value={formatCentsPriceForDisplay(currentFamily.balance_in_cents)} />
                </>
              }
            </div>
          </div>
          <div className='pl-10'>
            <div className='flex flex-col pb-4'>
              <p className='font-museo text-primary'>Student / Member Details</p>
              <p className='text-xs text-text-helper'>Showing {currentFamily.students.length > 5 ? '5' : currentFamily.students.length} of {currentFamily.students.length} student{currentFamily.students.length !== 1 && 's'}</p>
            </div>
            <StudentsDialog students={currentFamily.students} open={viewStudents} onOpenChange={setViewStudents} />
            <ScrollArea className='h-auto max-h-[280px]'>
              {currentFamily.students.slice(0, 5).map((student) => (
                <div className='mb-4' key={`${student.student_id}-${student.school}`}>
                  <div className='flex flex-col gap-1'>
                    <p className='text-sm font-semibold'>{student.first_name} {student.last_name}</p>
                    <p className='text-xs'>{student.school} {student.room && `(${student.room})`}</p>
                  </div>
                </div>
              ))}
            </ScrollArea>
            {currentFamily.students.length > 5 &&
              <Button
                type='button'
                variant='outline'
                size='xs'
                onClick={() => { setViewStudents(true) }}
              >
                View all
              </Button>
            }
          </div>
        </div>
        <hr />
        <div className='mt-4'>
          <Tabs defaultValue={currentTab}>
            <TabsList className="w-full">
              <TabsTrigger className='px-10' value="transactions" onClick={() => setCurrentTab('transactions')}>Transactions</TabsTrigger>
              <TabsTrigger className='px-10' value="savedOrders" onClick={() => setCurrentTab('savedOrders')}>Saved Orders ({currentFamily?.saved_order_list?.length || 0})</TabsTrigger>
              {isTgclUser &&
                <>
                  <TabsTrigger className='px-10' value="notes" onClick={() => setCurrentTab('notes')}>Notes ({currentFamily?.notes?.tracks?.length || 0})</TabsTrigger>
                  <TabsTrigger className='px-10' value="helpdesk" onClick={() => setCurrentTab('helpdesk')}>Helpdesk Controls</TabsTrigger>
                  <TabsTrigger className='px-10' value="messages" onClick={() => setCurrentTab('messages')}>Emails</TabsTrigger>
                </>
              }
            </TabsList>
            <TabsContent value="transactions">
              <TransactionRecords
                transactionList={transactionList}
                pageCount={currentFamily.transaction_page_count}
                familyId={currentFamily.family_id}
                activePage={currentPage}
                isTgclUser={isTgclUser}
                onPageChange={onPageChange}
                mutate={mutate}
              />
            </TabsContent>
            <TabsContent value="savedOrders">
              <SavedOrderList
                savedOrders={currentFamily.saved_order_list}
                familyId={currentFamily.family_id}
                familyWallet={currentFamily.balance_in_cents}
                mutate={mutate}
              />
            </TabsContent>
            {isTgclUser &&
              <>
                <TabsContent value="notes">
                  <Notes notes={currentFamily.notes} familyId={currentFamily.family_id} mutate={mutate} />
                </TabsContent>
                <TabsContent value="helpdesk">
                  <HelpdeskCommands
                    familyId={currentFamily.family_id}
                    mutate={mutate}
                    pushPass={currentFamily.push_pass}
                    isTgclEmail={isTgclEmail(currentFamily.email)}
                    familySupplier={currentFamily.supplier_order}
                  />
                </TabsContent>
              </>
            }
            <TabsContent value="messages">
              <EmailsTable familyId={currentFamily.family_id} />
            </TabsContent>
          </Tabs>
        </div>
      </CardContent>
    </Card >
  )
}

export default FamilyDetails