import React from 'react';
import { useForm } from 'react-hook-form';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import useSWRMutation from 'swr/mutation';

import api from 'networking/api';
import { FamilyDetails } from 'networking/models/family';
import { TaintedPattern } from 'util/tainted_regexp';

import ContentDialog from 'components/dialog/ContentDialog';
import InputField from 'components/form/InputField';
import PasswordRequirements from 'components/PasswordRequirements';
import { useToast } from 'components/base/use-toast';
import { Spinner } from 'components/base/spinner';
import { isAnyAdministrator } from 'util/permissions';

type Props = {
  open: boolean;
  onClose: () => void;
}

type FormData = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

/**
 * This component is used to change password
 */
const ChangePasswordDialog = (props: Props) => {
  const {
    open,
    onClose,
  } = props;
  const [showCurrentPassword, setShowCurrentPassword] = React.useState(false);
  const [showNewPassword, setShowNewPassword] = React.useState(false);
  const [showConfirmNewPassword, setShowConfirmNewPassword] = React.useState(false);
  const { toast } = useToast();
  const { data, isLoading } = api.family.useFamily();
  const isAnyAdmin = isAnyAdministrator(data);

  const { control, handleSubmit, reset, watch, setError } = useForm<FormData>();
  const enteredPassword = watch('newPassword');
  const { trigger, error, isMutating } = useSWRMutation('/family/save-details', (key, { arg }: { arg: { family: FamilyDetails } }) => {
    try {
      return api.family.saveFamilyDetails({
        family: arg.family,
        removeHomePageOption: false //true only for the accounts that are created by txt msg but we don't create account by txt anymore
      }).then(async (response) => {
        if (response.ok) {
          toast({
            title: 'Successfully changed password',
          });
          onClose();
          reset();
        } else {
          const data = await response.json();
          if (data.name === 'InvalidParameterException' || data.name === 'PasswordException') {
            setError('oldPassword', {
              message: 'Current password is invalid',
            })
          } else {
            throw new Error('Something went wrong, please try again');
          }
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })
  const onSave = async (data: FormData) => {
    const family: FamilyDetails = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
    }
    if (data.newPassword !== data.confirmNewPassword) {
      throw new Error('Passwords do not match');
    }
    await trigger({ family });
  }

  const getTitle = () => {
    return 'Change Password'
  }
  return (
    <div>
      <ContentDialog
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        open={open}
        title={getTitle()}
        content={(
          <div className='flex flex-col gap-4'>
            {
              isLoading ? (
                <div className='flex items-center justify-center'>
                  <Spinner />
                </div>
              ) : (
                <>
                  <InputField
                    control={control}
                    label='Current password'
                    type={showCurrentPassword ? "text" : "password"}
                    endAdornment={
                      <button
                        type="button"
                        onClick={() => {
                          setShowCurrentPassword(!showCurrentPassword);
                        }}>
                        {
                          showCurrentPassword ? (
                            <MdVisibility className="w-4 h-4 text-primary" />
                          ) : (
                            <MdVisibilityOff className="w-4 h-4 text-primary" />
                          )
                        }
                      </button>
                    }
                    placeholder='Enter current password'
                    name='oldPassword'
                    rules={{
                      required: 'Current password is required',
                    }}
                  />
                  <InputField
                    control={control}
                    endAdornment={
                      <button
                        type="button"
                        onClick={() => {
                          setShowNewPassword(!showNewPassword);
                        }}>
                        {
                          showNewPassword ? (
                            <MdVisibility className="w-4 h-4 text-primary" />
                          ) : (
                            <MdVisibilityOff className="w-4 h-4 text-primary" />
                          )
                        }
                      </button>
                    }
                    label='New password'
                    type={showNewPassword ? "text" : "password"}
                    placeholder='Enter new password'
                    name='newPassword'
                    rules={{
                      required: 'Enter a new password',
                      minLength: {
                        value: isAnyAdmin ? 14 : 8,
                        message: `Password must be at least ${isAnyAdmin ? 14 : 8} characters long`
                      },
                      validate: {
                        hasNumber: (value) => TaintedPattern.numbersRegex.test(value) || 'Password must contain at least one number',
                        hasLowercase: (value) => TaintedPattern.lowercaseRegex.test(value) || 'Password must contain at least one lowercase letter',
                        hasUppercase: (value) => TaintedPattern.uppercaseRegex.test(value) || 'Password must contain at least one uppercase letter',
                        hasSpecialCharacter: (value) => TaintedPattern.specialCharactersRegex.test(value) || 'Password must contain at least one of these !@#$%^&* special characters',
                      }
                    }}
                  />
                  <PasswordRequirements password={enteredPassword} minLength={isAnyAdmin ? 14 : 8} />

                  <InputField
                    control={control}
                    endAdornment={
                      <button
                        type="button"
                        onClick={() => {
                          setShowConfirmNewPassword(!showConfirmNewPassword);
                        }}>
                        {
                          showConfirmNewPassword ? (
                            <MdVisibility className="w-4 h-4 text-primary" />
                          ) : (
                            <MdVisibilityOff className="w-4 h-4 text-primary" />
                          )
                        }
                      </button>
                    }
                    type={showConfirmNewPassword ? "text" : "password"}
                    label='Confirm new password'
                    placeholder='Re-enter new password'
                    name='confirmNewPassword'
                    rules={{
                      required: 'Enter new password again',
                      validate: (value) => value === enteredPassword || 'Passwords do not match'
                    }}
                  />

                  {
                    error && (
                      <p className='text-sm text-destructive'>
                        {error.message}
                      </p>
                    )
                  }
                </>
              )
            }
          </div>

        )}
        primaryActionLabel='Save'
        primaryActionProps={{
          type: 'submit',
          onClick: handleSubmit(onSave),
          disabled: isMutating || isLoading,
        }}
      />
    </div>
  )
}

export default ChangePasswordDialog