import React from 'react'
import { useMatch } from 'react-router-dom';
import { Button } from '../../common_component/base/button'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../common_component/base/accordion"
import CardForm from './CardForm';
import PoliForm from './PoliForm';
import BankForm from './BankForm';
import { MdHelpOutline } from "react-icons/md"
import AppleGooglePayLogos from '../../assets/images/apple-google-pay-logos.png';
import orderUtils from 'networking/util/order';
import api from 'networking/api';
import { useStore } from '../../store/store';
import OnlineEftposForm from './OnlineEftposForm';
import KindoWell from '../../common_component/KindoWell'
import useRemoteConfig from 'util/hooks/useRemoteConfig';

type Props = {
  totalPrice?: number
  orderIds?: any[]
  isOrderValidForBX?: boolean
  isAllMiddletonGrange?: boolean
  onPurchase?: () => void;
  onError?: (error: any) => void; // When an error occurs during purchase, this function will be called with the error object
};
const TopUp = ({ totalPrice, orderIds, isOrderValidForBX, isAllMiddletonGrange, onPurchase, onError }: Props) => {
  const store = useStore();
  const isCommunityShop = store.shop.isCommunityShop;
  const { getRemoteConfigValue } = useRemoteConfig();
  const TOPUP_AMOUNT_LIMIT = getRemoteConfigValue('online_eftpos_topup_amount_limit_in_cents')?.asNumber() || 25000;

  const { data, isLoading } = api.family.useFamily({
    has_billing_id: true,
    card_detail: true,
    get_students: true,
    saved_order_count: false,
    last_login: false,
    school_ids: true,
    supplier_menu: false,
    supplier_order: false,
    force_get_family: true,
    family_option: false,
    school_services: true,
    server_time: false,
    path: "/FAMILY"
  });

  const { data: motdData } = api.partner.useMessageOfTheDay('KINDO_ALL', 'topup');

  const isInWallet = !!useMatch('/app/wallet');
  const areAllSchoolsMiddletonGrange = orderUtils.areAllSchoolIdsMiddleton(data?.school_services.map(item => item.school_id));

  const [paymentOption, setPaymentOption] = React.useState<string | 'poli' | 'card' | 'bank' | 'eftpos'>('');

  const paymentMethods = [];

  paymentMethods.push({
    name: 'card',
    title: 'Credit or Debit card',
    description: '2.5% surcharge + $0.50 card service fee applies',
    ctaLabel: `${isCommunityShop ? 'Pay' : 'Top up'} with card`,
    footer: '',
    tooltip: <a href='https://support.mykindo.co.nz/portal/en/kb/articles/are-my-credit-card-and-bank-details-secure' target='_blank' rel="noreferrer"><MdHelpOutline /></a>,
    logo: <img src={AppleGooglePayLogos} alt="Card type logos" className='h-4 mr-5' />,
    renderForm: <CardForm totalPrice={totalPrice} orderIds={orderIds} onPurchase={onPurchase} onError={onError} />,
  });
  if (orderIds && !isAllMiddletonGrange) {
    paymentMethods.push({
      name: 'poli',
      title: 'Poli internet payment',
      description: '',
      ctaLabel: `${isCommunityShop ? 'Pay' : 'Top up'} with POLi`,
      footer: 'How does POLi work?',
      tooltip: <a href='https://www.polipayments.com/resources/faqs/' target='_blank' rel="noreferrer"><MdHelpOutline /></a>,
      logo: '',
      renderForm: <PoliForm totalPrice={totalPrice} orderIds={orderIds} onPurchase={onPurchase} onError={onError} />,
    });
  } else if (!orderIds && !areAllSchoolsMiddletonGrange) {
    paymentMethods.push({
      name: 'poli',
      title: 'Poli internet payment',
      description: '',
      ctaLabel: `${isCommunityShop ? 'Pay' : 'Top up'} with POLi`,
      footer: 'How does POLi work?',
      tooltip: <a href='https://www.polipayments.com/resources/faqs/' target='_blank' rel="noreferrer"><MdHelpOutline /></a>,
      logo: '',
      renderForm: <PoliForm totalPrice={totalPrice} orderIds={orderIds} onPurchase={onPurchase} onError={onError} />,
    });
  }

  if (!orderIds || (orderIds && totalPrice && totalPrice < TOPUP_AMOUNT_LIMIT)) {
    paymentMethods.push({
      name: 'eftpos',
      title: 'Online Eftpos',
      description: '',
      ctaLabel: `${isCommunityShop ? 'Pay' : 'Top up'} with Online Eftpos`,
      footer: '',
      tooltip: <a href='https://support.mykindo.co.nz/portal/en/kb/articles/paying-and-topping-up-using-online-eftpos' target='_blank' rel="noreferrer"><MdHelpOutline /></a>,
      logo: '',
      renderForm: (
        <OnlineEftposForm
          action={isCommunityShop ? 'pay' : 'topup'}
          totalPrice={totalPrice}
          orderIds={orderIds}
          onPurchase={onPurchase}
          onError={onError}
        />
      ),
    });
  }

  if ((isOrderValidForBX || isOrderValidForBX === undefined) && !isCommunityShop) {
    paymentMethods.push({
      name: 'bank',
      title: 'Online bank transfer',
      description: '',
      ctaLabel: 'Top up with bank transfer',
      footer: '',
      tooltip: '',
      logo: '',
      renderForm: <BankForm totalPrice={totalPrice} orderIds={orderIds} onPurchase={onPurchase} onError={onError} />,
    });
  }

  React.useEffect(() => {
    store.shop.setPaymentInitiatedFrom(isInWallet ? 'wallet' : 'checkout')
  }, [isInWallet]);

  return (
    <div className="grid grid-cols-1">
      {
        motdData?.motds && motdData?.motds.length > 0 && motdData?.motds.sort((a, b) => a.priority - b.priority).map((motd) => (
          <KindoWell
            variant={motd.info_type}
            key={`${motd.info_type + '_' + motd.priority}`}
            className='mb-2'
            messages={[motd.text]}
          />
        ))
      }
      <Accordion type="single" collapsible
        value={paymentOption}
        onValueChange={(value) => setPaymentOption(value)}
        className='flex flex-col gap-2 sm:gap-3'
      >
        {
          paymentMethods.map(({ name, title, description, ctaLabel, renderForm, tooltip, logo }, index) => (
            <AccordionItem key={name} value={name} className='px-4 border-[1px] data-[state=open]:border-2 border-border-card data-[state=open]:bg-card-active rounded-md'>
              <AccordionTrigger hideTrigger>
                <div key={name} className="flex flex-col flex-wrap items-baseline justify-between w-full sm:items-center sm:flex-row">
                  <div className='flex flex-row flex-wrap'>
                    <div className='flex flex-col text-left'>
                      <div className='flex items-center gap-2'>
                        <p className="text-lg font-museo">
                          {title}
                        </p>
                        <p className="text-lg font-museo">{tooltip}</p>
                      </div>
                      <p className="pb-2 text-xs text-card-foreground">
                        {description}
                      </p>
                    </div>
                    {
                      paymentOption !== name && (
                        <div className='hidden px-10 py-1 sm:block'>
                          {logo}
                        </div>
                      )
                    }
                  </div>

                  {
                    paymentOption !== name && (
                      <div className='flex flex-row flex-wrap'>
                        <Button size='sm' className='font-museo' variant="default">
                          {ctaLabel}
                        </Button>
                        <div className='block py-2 sm:hidden'>
                          {logo}
                        </div>
                      </div>
                    )
                  }
                </div>
              </AccordionTrigger>
              <AccordionContent className='p-1'>
                {renderForm}
              </AccordionContent>
            </AccordionItem>
          ))
        }
      </Accordion>
    </div>
  )
}

export default TopUp