import useSWR, { useSWRConfig } from 'swr';
import { baseApi, baseUrl } from './constants';
import { UserStatus, InviteStatus } from './models/shop';
import {
  SchoolMessagesOfTheDay,
} from './models/partner';
import { KindoNetworkError } from 'networking/models/common';

const partner = {
  // Queries (starts with "use")
  useMessageOfTheDay: (schoolId?: string, location?: string) => {
    const params = new URLSearchParams({
      partner_id: schoolId ?? '',
      ...(location ? { location: location } : {}),
    }).toString();
    const data = useSWR<SchoolMessagesOfTheDay, KindoNetworkError>(!schoolId ? null : `${baseApi}/partner/motd?${params}`);
    return data;
  },

  // Mutations

}

export default partner;