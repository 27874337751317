import React from "react";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { Helmet } from "react-helmet";
import _ from "lodash";
import api from "networking/api";

import { useStore } from "../../../store/store";
import { Card, CardContent, CardHeader } from "../../../common_component/base/card";
import ComboBoxField from "../../../common_component/form/ComboBoxField";
import { Button } from "../../../common_component/base/button";
import { Outlet, useNavigate, useOutletContext, useLocation } from "react-router-dom";
import { MdArrowBack, MdSearch } from "react-icons/md";
import { isAnyAdministrator, isTgclAdmin } from "util/permissions";
import InputField from "components/form/InputField";
import { Tabs, TabsList, TabsTrigger, } from "../../../common_component/base/tabs";

export type FilterData = {
  searchText: string;
  category: string;
}

interface RolloverOutletContext {
  school: string;
  // Filter data
  filters: {
    searchText?: string;
    category?: string;
  }
}
const PayableRollOverPage = observer(() => {
  const store = useStore();
  const { control, getValues, watch, reset, handleSubmit } = useForm<FilterData>({
    defaultValues: {
      searchText: '',
    },
  });
  const navigate = useNavigate();
  const location = useLocation();
  const [currentFilters, setCurrentFilters] = React.useState<FilterData>({ searchText: '', category: '' });
  const currentSchool = store.admin.currentSchool;
  const { data: payableCategoriesData, isLoading: isLoadingPayableCategories } = api.admin.usePayableCategories(currentSchool);
  const { data: familyData, isLoading } = api.admin.useFamily();
  const isAnyAdmin = isAnyAdministrator(familyData);
  const currentTab = location.pathname.split("/").pop();
  const onSubmitFilters = (data: FilterData) => {
    setCurrentFilters(data);
  }
  const clearSearchFilters = () => {
    reset();
    setCurrentFilters({ searchText: '', category: '' });
  }
  React.useEffect(() => {
    if (!isLoading && familyData && !isAnyAdmin) {
      // Not an admin, shouldn't be here
      navigate('/app/shop');
    }
  }, [isLoading, familyData, isAnyAdmin]);

  return (
    <div>
      <Helmet>
        <title>Payable Rollover</title>
      </Helmet>
      <div className="flex flex-col max-w-[1140px] gap-4 m-auto">
        <div className="relative flex flex-col pt-10">
          <div className="flex flex-row items-center justify-between px-6">

            <div className="flex flex-row items-center gap-2 pb-4">
              <Button
                variant={"icon"}
                onClick={() => {
                  navigate('/app/admin');
                }}
                size="default"
              >
                <MdArrowBack className="w-6 h-6 text-primary" />
              </Button>
              <h5 className="text-3xl font-bold font-museo text-primary">
                Payable Rollover
              </h5>
            </div>
          </div>
          <Tabs defaultValue={currentTab}>
            <TabsList variant="underline" className="pb-0 pl-6">
              <TabsTrigger value="payables" onClick={() => { navigate('/app/admin/rollover/payables'); clearSearchFilters(); }}>Payables</TabsTrigger>
              <TabsTrigger value="rules" onClick={() => { navigate('/app/admin/rollover/rules'); clearSearchFilters(); }}>Rules</TabsTrigger>
            </TabsList>
          </Tabs>
          <div className="w-full mb-6 bg-primary-lightest">
            <p className="p-6 text-sm text-card-foreground">
              {currentTab === 'payables' ?
                'Rolling over payables for use in the upcoming year. '
                :
                'Apply the previously used rules to your rolled over payables. Only apply a rule to a group, class, or year once students in it are finalised and showing correctly in Kindo. '
              }
            </p>
          </div>
          <Card className="rounded-2xl">
            <CardHeader className="sm:pb-[76px]">
              <div className="z-20 flex flex-col gap-6">
                <form onSubmit={handleSubmit(onSubmitFilters)}>
                  <div className="flex flex-row flex-wrap gap-4">
                    <div className="flex-1 basis-[140px]">
                      <InputField
                        startAdornment={<MdSearch />}
                        name="searchText"
                        label="Search for payables"
                        control={control}
                        adornmentVariants={{
                          size: 'md',
                          variant: 'plain',
                        }}
                        placeholder={currentTab === 'payables' ? 'Enter payable name or GL code' : 'Enter payable name, category or students'}
                      />
                    </div>
                    <ComboBoxField
                      label="Category"
                      name="category"
                      minWidth={150}
                      isLoading={isLoadingPayableCategories}
                      control={control}
                      placeholder="All categories"
                      options={[
                        { label: 'All categories', value: '' },
                        ...payableCategoriesData?.pcats?.map((category) => ({
                          label: category,
                          value: category,
                        })) || []
                      ]}
                    />
                    <Button size="sm" variant="secondary" className="self-end" onClick={() => {
                      setCurrentFilters(getValues());
                    }}>
                      Search
                    </Button>
                    {
                      Object.values(currentFilters).some((value) => value) && (
                        <Button className="self-end" variant="ghost" size="sm" onClick={() => {
                          clearSearchFilters();
                        }}>
                          Clear filters
                        </Button>
                      )
                    }
                  </div>
                  <button className="hidden" type="submit">Submit</button>
                </form>
              </div>

            </CardHeader>
            <CardContent className="z-10 pb-0 sm:px-0 sm:pb-0">
              <Outlet context={{
                school: currentSchool,
                filters: currentFilters,
              } satisfies RolloverOutletContext} />
            </CardContent>
          </Card>
        </div>
      </div>
    </div>
  )
});

export function useRolloverContext() {
  return useOutletContext<RolloverOutletContext>();
}

export default PayableRollOverPage;
