import React from 'react';
import { observer } from "mobx-react";
import { Button } from "../../common_component/base/button";
import { MdClose } from "react-icons/md";
import _ from 'lodash';
import orderUtils from 'networking/util/order';
import api from 'networking/api';
import { formatCentsPriceForDisplay } from '../../util/string_util';
import { useToast } from '../../common_component/base/use-toast';
import { baseUrl } from 'networking/constants';
import { Popover, PopoverTrigger, PopoverContent, PopoverClose } from '../../common_component/base/popover';
import analytics from '../../util/googleAnalytics';
import { ScrollArea } from '../../common_component/base/scroll-area';
import ProductChoices from '../service_shop/dialog/ProductChoices';
import { b64decode } from '../../util/data/base64_utf8_tools';
import { Product } from 'networking/models/family'

const CheckoutOffers = observer(() => {
  const { toast } = useToast();

  const { data, mutate } = api.order.useOrderItems();
  const { data: productData } = api.order.useProductOffers(data?.offers);
  const [showProductChoices, setShowProductChoices] = React.useState(false);
  const [chosenProduct, setChosenProduct] = React.useState<Product>({});
  const chosenProductDetail = chosenProduct.productStockAndQuestions?.product_questions[chosenProduct.permanent_id];

  return (
    <div>
      <div className="p-4 bg-background-light">
        <div className="flex flex-col gap-4">
          <p className="font-semibold text-md text-primary font-museo">Spread a little kindness</p>
          <ScrollArea horizontal>
            <div className="relative flex flex-row gap-8 overflow-y-auto flex-nowrap">
              {
                (productData || []).map((product: Product) => (
                  <div key={product.permanent_id} className="flex flex-col gap-2 min-w-[210px] w-[210px] bg-white rounded-xl p-4 pb-0">
                    <p className='text-sm text-card-foreground font-museo'>{product.product}</p>
                    <p className='text-sm font-semibold text-right'>{formatCentsPriceForDisplay(product.price_in_cents)}</p>
                    <img
                      src={`${baseUrl}upload/images/supplier/${product.supplierId}/pdf_logo/${product.supplierId}.jpg`}
                      alt={product.supplierId}
                      className="absolute object-cover ml-0 rounded-full h-9 w-9 mt-14"
                    />
                    {(!product.picture) ? <></> :
                      <img
                        className="object-cover h-[49px] bg-white rounded-lg"
                        alt={product.product}
                        src={`${baseUrl}upload/images/products/${product.supplierId}/${product.picture}`}
                      />
                    }
                    <Button
                      size='xs'
                      disabled={product.productStockAndQuestions?.product_stock[product.permanent_id] === 0 || orderUtils.getAvailableDates(product.service).length === 0}
                      onClick={() => {
                        if (product.productStockAndQuestions?.product_questions[product.permanent_id] && product.productStockAndQuestions?.product_questions[product.permanent_id]?.length > 0) {
                          setShowProductChoices(true);
                          setChosenProduct(product)
                        } else {
                          api.order.addOrder(orderUtils.getAddOfferInput({
                            product: {
                              ...product
                            }
                          })).then((res) => {
                            if (res.ok) {
                              mutate();
                              toast({
                                title: 'Added:',
                                description: (
                                  <p className='mt-2 font-semibold text-warning'>{product.product}</p>
                                )
                              })
                            }
                          });
                        }
                        analytics.addOrder({
                          itemCount: 1,
                          listName: 'Checkout',
                          orderPriceInCents: product.price_in_cents,
                        });
                      }}
                    >
                      {orderUtils.getAvailableDates(product.service).length === 0 ? 'Service is closed' : product.productStockAndQuestions?.product_stock[product.permanent_id] === 0 ? 'Sold out' : 'Add to order'}
                    </Button>
                    {
                      product.summary && (
                        <Popover>
                          <PopoverTrigger asChild>
                            <Button variant='link' size='sm' className='text-xs'>Learn about this donation</Button>
                          </PopoverTrigger>
                          <PopoverContent className='w-[210px] flex flex-col min-h-[185px] justify-between' side='top'>
                            <div className='text-xs text-card-foreground' dangerouslySetInnerHTML={{ __html: b64decode(product.summary) }} />
                            <PopoverClose>
                              <Button variant='link' size='sm' className='flex justify-center w-full' aria-label="Close">
                                <p className='flex items-center text-xs text-center text-primary'>
                                  <MdClose /> Close
                                </p>
                              </Button>
                            </PopoverClose>
                          </PopoverContent>
                        </Popover>
                      )
                    }
                  </div>
                ))
              }
            </div>
          </ScrollArea>
          {chosenProductDetail &&
            <ProductChoices
              item={chosenProduct || {}}
              open={showProductChoices}
              onOpenChange={(open) => setShowProductChoices(open)}
              defaultValues={{
                questions: chosenProductDetail.map((question) => ({
                  ...question,
                  answer: null,
                }))
              }}
              questions={chosenProductDetail ?? []}
              onSubmit={async (data) => {
                api.order.addOrder(orderUtils.getAddOfferInput({
                  product: {
                    ...chosenProduct,
                    ...data
                  }
                })).then((res) => {
                  if (res) {
                    return res.json();
                  }
                }).then((product) => {
                  setShowProductChoices(false)
                  mutate();
                  if (product.rtype === 'error') {
                    toast({
                      title: 'Something went wrong:',
                      description: (
                        <p className='mt-2 font-semibold text-warning'>{product.message}</p>
                      ),
                      duration: 3000,
                    })
                  } else {
                    toast({
                      title: 'Added:',
                      description: (
                        <p className='mt-2 font-semibold text-warning'>{chosenProduct.product}</p>
                      ),
                      duration: 3000,
                    })
                  }
                })
              }}
            />
          }
        </div>
      </div>
    </div>
  )
})

export default CheckoutOffers;