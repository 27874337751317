
import _ from 'lodash';

type EventItem = {
  productId: string;
  productName: string;
  priceInCents: number;
  brandName?: string;
  serviceId?: string;
  supplierId?: string;
  quantity?: number;
  options?: string[];
  choice?: string;
}

type CartEvent = {
  currency?: string;
  items: EventItem[];
  listName?: 'Cart' | 'Service' | 'Item Detail' | 'Precheckout' | 'Fees and Donations' | 'Spread a little kindness' | 'Orders' | 'Prepare Order' | 'Checkout';
}

type ItemEvent = {
  eventName: 'add_to_cart' | 'remove_from_cart' | 'view_item' | 'view_cart' | 'begin_checkout' | 'purchase';
} & CartEvent;

const triggerItemEvent = ({
  eventName,
  items = [],
  listName,
  currency = 'NZD',
}: ItemEvent) => {
  const value = _.sumBy(items, (item) => item.priceInCents) / 100;
  gtag('event', eventName, {
    currency,
    value,
    items: items.map((item) => ({
      item_id: item.productId,
      item_name: item.productName,
      item_list_id: _.snakeCase(listName),
      item_list_name: listName,
      item_brand: item.brandName,
      item_category: item.serviceId,
      item_category2: item.supplierId,
      price: item.priceInCents / 100,
      item_variant: item.choice,
    }))
  })
}
const addToCart = (props: CartEvent) => {
  triggerItemEvent({
    ...props,
    eventName: 'add_to_cart'
  })
}

const removeFromCart = (props: CartEvent) => {
  triggerItemEvent({
    ...props,
    eventName: 'remove_from_cart'
  })
}
const viewItem = (props: CartEvent) => {
  triggerItemEvent({
    ...props,
    eventName: 'view_item'
  });
};

const viewCart = (props: CartEvent) => {
  triggerItemEvent({
    ...props,
    eventName: 'view_cart'
  });
};
const beginCheckout = (props: CartEvent) => {
  triggerItemEvent({
    ...props,
    eventName: 'begin_checkout'
  });
};
type PurchaseEvent = {
  transactionId?: string;
  tax?: number;
  coupon?: string;
  value?: number;
} & CartEvent;

const purchase = ({
  transactionId,
  tax,
  coupon,
  items = [],
  listName,
  value,
  currency = 'NZD',
}: PurchaseEvent) => {
  gtag('event', 'purchase', {
    currency,
    transaction_id: transactionId,
    tax: tax ? tax / 100 : undefined,
    coupon,
    value: value ? value / 100 : 0,
    items: items.map((item) => ({
      item_id: item.productId,
      item_name: item.productName,
      item_list_id: _.snakeCase(listName),
      item_list_name: listName,
      item_brand: item.brandName,
      item_category: item.serviceId,
      item_category2: item.supplierId,
      price: item.priceInCents / 100,
      item_variant: item.choice,
    }))
  })
}

type OrderEvent = {
  orderId?: string;
  orderPriceInCents: number;
  itemCount: number;
  listName: 'Prepare Order' | 'Checkout' | 'Calendar';
}
const addOrder = ({ listName, orderPriceInCents, itemCount }: OrderEvent) => {
  gtag('event', 'add_order', {
    value: orderPriceInCents / 100,
    item_count: itemCount,
    item_list_id: _.snakeCase(listName),
    item_list_name: listName,
  })
}
const removeOrder = ({ listName, orderId, orderPriceInCents, itemCount }: OrderEvent) => {
  gtag('event', 'remove_order', {
    order_id: orderId,
    value: orderPriceInCents / 100,
    item_count: itemCount,
    item_list_id: _.snakeCase(listName),
    item_list_name: listName,
  })
}

type BeginCheckoutEvent = {
  initiatedFrom: 'Cart' | 'Toast';
}
const checkoutClick = ({ initiatedFrom }: BeginCheckoutEvent) => {
  gtag('event', 'checkout_click', {
    initiated_from: initiatedFrom,
  })
}
type PlaceOrderEvent = {
  initiatedFrom: 'Bank transfer' | 'Poli' | 'Card' | 'Complete order' | 'Online Eftpos';
  priceInCents: number;
}
const placeOrder = ({ initiatedFrom, priceInCents }: PlaceOrderEvent) => {
  gtag('event', 'place_order', {
    initiated_from: initiatedFrom,
    value: priceInCents / 100,
  })
}

type ClickEvent = {
  eventName: 'side_menu_category_click' | 'side_service_click' | 'clear_filter' | 'select_option' | 'select_choice' | 'apply_filter' | 'filter_click' | 'order_by' | 'date_picker_toggle' | 'select_date' | 'download_app_banner' | 'close_download_app_banner';
  parameters?: {};
}
const triggerClick = ({ eventName, parameters }: ClickEvent) => {
  gtag('event', eventName, parameters);
}

const analytics = {
  addToCart,
  removeFromCart,
  addOrder,
  removeOrder,
  checkoutClick,
  placeOrder,
  viewItem,
  viewCart,
  triggerClick,
  beginCheckout,
  purchase,
}

export default analytics;