import React from 'react'
import { cn } from '../util/tailwind';

type Props = {
  src?: string;
  alt: string;
  className?: string;
  fallbackSrcs?: string[];
  loading?: 'lazy' | 'eager';
  finalFallback?: React.ReactNode;
  classNameOnLoad?: string;
};

const Image = (props: Props) => {
  const { src, alt, className, fallbackSrcs, loading, finalFallback, classNameOnLoad } = props;
  const [pictureFailed, setPictureFailed] = React.useState(false);
  const [isLoadingImage, setIsLoadingImage] = React.useState(true);
  const [imageSrc, setImageSrc] = React.useState(src || fallbackSrcs?.[0] || '');
  const [fallbackSrcIndex, setFallbackSrcIndex] = React.useState(!src || src === fallbackSrcs?.[0] ? 1 : 0);
  return (
    <>
      {
        pictureFailed && !!finalFallback ? (
          finalFallback
        ) : (
          <img src={imageSrc} alt={alt} loading={loading}
            className={cn(className, {
              [`${classNameOnLoad}`]: !isLoadingImage,
            })}
            onLoad={() => setIsLoadingImage(false)}
            onError={() => {
              if (fallbackSrcs && fallbackSrcs.length > 0 && fallbackSrcIndex < fallbackSrcs.length) {
                setImageSrc(fallbackSrcs[fallbackSrcIndex]);
                setFallbackSrcIndex((prev) => prev + 1);
              } else {
                setPictureFailed(true);
              }
            }} />
        )
      }
    </>
  )
}

export default Image