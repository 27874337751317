import React from 'react'
import { NavigationMenu, NavigationMenuContent, NavigationMenuItem, NavigationMenuLink, NavigationMenuList, NavigationMenuTrigger } from '../../../common_component/base/navigation-menu';
import { baseApi } from 'networking/constants';
import { MdHelpOutline, MdOutlineManageSearch, MdFeedback } from 'react-icons/md';
import { Button } from '../../../common_component/base/button';
import { useStore } from '../../../store/store';

const HelpButton = () => {
  const store = useStore();

  return (
    <NavigationMenu data-testid="section-help-menu" className='self-center'>
      <NavigationMenuList>
        <NavigationMenuItem>
          <NavigationMenuTrigger hideArrow className='bg-transparent p-2 hover:bg-pale focus:bg-pale focus:outline-none hover:text-primary focus:text-primary data-[active]:bg-primary-light/50 data-[state=open]:bg-primary-light/50'>
            <Button
              name="help"
              className='items-center self-center justify-center w-6 h-6 p-0 bg-transparent rounded-full'
              variant="plain"
            >
              <MdHelpOutline className='w-5 h-5' />
            </Button>
          </NavigationMenuTrigger>
          <NavigationMenuContent>
            <div className='w-[270px] text-card-foreground flex flex-col gap-1 py-2'>
              <NavigationMenuLink>
                <Button
                  name="help-articles"
                  className='w-full h-[30px] justify-start gap-2 font-museo'
                  variant="link"
                  onClick={() => {
                    window.open('https://support.mykindo.co.nz/', '_blank');
                  }}
                >
                  <MdOutlineManageSearch className='w-4 h-4' />
                  search help articles
                </Button>
                {!store.shop.isCommunityShop &&
                  <Button
                    name="feedback"
                    className='w-full h-[30px] justify-start gap-2 font-museo'
                    variant="link"
                    onClick={() => store.family.setShowFeedbackDialog(true)}
                  >
                    <MdFeedback className='w-4 h-4' />
                    send feedback or suggestions
                  </Button>
                }
              </NavigationMenuLink>
            </div>
          </NavigationMenuContent>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  )
}

export default HelpButton