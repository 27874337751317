import React from 'react';
import { Controller, type UseControllerProps } from "react-hook-form";
import { Textarea, TextareaProps } from '../../common_component/base/textarea';

// Removing duplicate prop conflict from InputProps since UseControllerProps already has it
interface Props extends UseControllerProps<any>, Omit<TextareaProps, 'name' | 'defaultValue'> {
  label?: string;
  rows?: number;
}

const TextField = React.forwardRef<
  TextareaProps,
  Props
>(({
  name,
  control,
  rules,
  ...props
}, ref) => (
  <Controller
    name={name}
    control={control}
    rules={rules}
    render={({ fieldState: { error }, field }) => (
      <Textarea
        error={error?.message}
        {...field}
        {...props}
      />
    )}
  />
))

export default TextField;
