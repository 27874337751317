"use client"

import * as React from "react"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { DayPicker } from "react-day-picker"

import { cn } from "../../util/tailwind"
import { buttonVariants } from "./button"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 text-primary",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: cn({
          "text-sm font-medium text-card-foreground": props.captionLayout === 'buttons',
          "hidden": props.captionLayout === 'dropdown' || props.captionLayout === 'dropdown-buttons',
        }),
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "grid grid-cols-7 gap-2",
        head_cell:
          "text-muted-foreground rounded-full w-9 font-normal text-[0.8rem]",
        row: "grid grid-cols-7 w-full mt-2 gap-2",
        cell: "rounded-full text-center text-card-foreground text-sm p-0 relative [&:has([aria-selected])]:bg-secondary focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-bold aria-selected:opacity-100 rounded-full hover:bg-background hover:text-pale-foreground"
        ),
        day_selected:
          "bg-primary-lightest text-card-foreground hover:bg-primary-lightest/10 hover:text-card-foreground focus:bg-primary-lightest/80 focus:text-card-foreground",
        day_today: "border-secondary border-1 text-card-foreground",
        day_outside: "text-muted-foreground opacity-50 aria-selected:text-secondary-foreground/70",
        day_disabled: "text-muted-foreground opacity-20 font-normal",
        day_range_middle:
          "aria-selected:bg-secondary aria-selected:text-secondary-foreground",
        day_hidden: "invisible",
        vhidden: 'hidden',
        caption_dropdowns: 'flex items-center gap-2 text-card-foreground hover:cursor-pointer',
        dropdown: 'cursor-pointer',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <MdChevronLeft className="w-6 h-6 text-card-foreground" />,
        IconRight: ({ ...props }) => <MdChevronRight className="w-6 h-6 text-card-foreground" />,
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

export { Calendar }
