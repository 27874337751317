import React from 'react';
import { ColumnDef } from "@tanstack/react-table"
import { MdArrowUpward, MdChevronRight } from 'react-icons/md';
import { Button } from '../../../common_component/base/button';
import { cn } from '../../../util/tailwind';
import { StudentRowItem } from './ActionsTable/Columns';
import { PayableBinding, PayableRollBinding } from 'networking/models/admin';

// This type is used to define the shape of our table data.
export type RowItem = {
  id: string;
  payableName: string;
  groups?: string;
  proposedActions?: {
    remove: number;
    add: number;
  };
  allBindings: PayableRollBinding[];
  bindings?: {
    group: string;
    metagroup: string;
    binding: PayableBinding;
    studentsToAdd: StudentRowItem[];
    studentsToRemove: StudentRowItem[];
  }[];
}

const SortableHeader = ({ column, title }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className='px-0 font-bold text-card-foreground hover:text-card-foreground'
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
    </Button>
  )
};
export const columns: ColumnDef<RowItem>[] = [
  {
    id: "expand",
    header: "",
    size: 0,
    cell: ({ row, table }) => (
      row.original.proposedActions?.add! > 0 || row.original.proposedActions?.remove! > 0 ? (
        <Button className="p-0 text-inherit" size="xs" variant={"icon"}
          onClick={(e) => {
            e.stopPropagation();
            row.toggleExpanded();
          }}>
          <MdChevronRight className={cn('w-5 h-5 transition-transform', {
            'rotate-90': row.getIsExpanded(),
          })} />
        </Button>
      ) : null),
  },
  {
    maxSize: 600,
    size: 250,
    accessorKey: "payableName",
    header: ({ column }) => (
      <SortableHeader column={column} title="Payable" />
    ),
  },
  {
    accessorKey: "groups",
    maxSize: 300,
    size: 250,
    header: ({ column }) => (
      <SortableHeader column={column} title="Applied to" />
    ),
    cell: ({ row }) => {
      const isExpanded = row.getIsExpanded();
      if (!isExpanded) {
        return (
          <p>
            {row.original.groups}
          </p>
        )
      } else {
        return null;
      }
    }
  },
]
