import { Button } from 'components/base/button';
import React from 'react'

type Props = {
  title: string;
  description?: string;
  cta?: {
    label: string;
    onClick: () => void;
  }
}

const ProfferResult = (props: Props) => {
  const {
    title,
    description,
    cta
  } = props;
  return (
    <div className='flex flex-col items-center justify-center gap-2'>
      <p className='text-xl font-bold font-museo text-primary'>
        {title}
      </p>
      {
        description && (
          <p className='text-sm text-text-body'>
            {description}
          </p>
        )
      }
      {
        cta && (
          <div className='pt-6'>
            <Button
              type='button'
              onClick={cta.onClick}
            >
              {cta.label}
            </Button>
          </div>
        )
      }
    </div>
  )
}

export default ProfferResult