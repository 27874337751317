import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog';
import { Button } from '../../../common_component/base/button';
import api from 'networking/api';
import { Spinner } from '../../../common_component/base/spinner';
import { cn } from '../../../util/tailwind';
import { formatCentsPriceForDisplay } from '../../../util/string_util';
import { MdWallet } from "react-icons/md";

type Props = {
  onSetCurrentFamilyId: () => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const SavedForListDialog = ({ onSetCurrentFamilyId, open, onOpenChange }: Props) => {
  const { data: savedForList, isLoading: isSavedForListLoading } = api.customer.useSavedForList()

  const onFamilyClick = (id) => {
    onSetCurrentFamilyId(id);
    onOpenChange(false);
  };

  const parseOrderDetail = (orderDetail: string) => {
    const regex = /Family:\s*(.*?)\s*\(.*?\)\.\s*Email:\s*(.*?)\.\s*Phone:\s*(.*?)\.\s*\(balance cents=(\d+)\)\.\s*Order created:\s*(.*?)\s*\./;

    const match = orderDetail.match(regex);

    if (match) {
      return {
        family: match[1],
        email: match[2],
        phone: match[3],
        balance: parseInt(match[4], 10),
        created: match[5],
      };
    } else {
      return null;
    }
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Saved for list</DialogTitle>
        </DialogHeader>
        {isSavedForListLoading ?
          <div className='flex flex-col items-center justify-center h-full gap-5 py-10'>
            <Spinner size='md' />
            <p className='text-xl font-museo'>Loading...</p>
          </div>
          :
          <div>
            {savedForList?.saved_for_list.map((list: any, index: number) => (
              <>
                <Button
                  onClick={() => onFamilyClick(list.fid)} key={list.fid}
                  className='flex items-center justify-between gap-4 h-[120px] pr-1 pl-2 w-full'
                  variant="list"
                >
                  <div className="flex flex-1 items-center h-[120px] text-left overflow-hidden">
                    <div className='flex items-start justify-between'>
                      <div>
                        <p className='mb-2 text-sm font-semibold text-primary'>
                          {parseOrderDetail(list.order_detail)?.family}
                        </p>
                        <p className='text-card-foreground'>
                          {parseOrderDetail(list.order_detail)?.email}
                        </p>
                        <p className='text-card-foreground'>
                          {parseOrderDetail(list.order_detail)?.phone}
                        </p>
                        <div className='flex gap-1 mt-2.5'>
                          <p className='text-xs text-card-foreground'>
                            {parseOrderDetail(list.order_detail)?.created}
                          </p>
                          <p className='text-xs italic text-card-foreground'>(order created)</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='flex items-center gap-1 text-card-foreground'>
                    <MdWallet />
                    <p>
                      {formatCentsPriceForDisplay(parseOrderDetail(list.order_detail)?.balance)}
                    </p>
                  </div>
                </Button>
                <hr
                  className={cn('', {
                    'hidden': index === savedForList?.saved_for_list.length - 1
                  })}
                />
              </>
            ))}
            {savedForList?.saved_for_list.length === 0 && <p className='mt-2 mb-6 text-card-foreground'>No saved order(s) to display.</p>}
            <div className='mt-4 mb-2'>
              <hr />
            </div>
            <p className='text-xs text-card-foreground'>Elapsed {parseInt(savedForList?.elapsed_seconds, 10)} seconds</p>
            <DialogFooter>
              <Button type='button' onClick={() => onOpenChange(false)}>Close</Button>
            </DialogFooter>
          </div>
        }

      </DialogContent>
    </Dialog>
  );
};

export default SavedForListDialog;