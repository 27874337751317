import React from 'react';
import { cn } from "util/tailwind"

type DetailItemProps = {
  label: string;
  value: any;
  withGap?: boolean;
};

const DetailItem = (props: DetailItemProps) => {
  const { label, value, withGap = false } = props;
  return (
    <div
      className={cn("flex flex-col", {
        'gap-1': withGap,
      })}
    >
      <p className='text-sm font-semibold'>{label}</p>
      <p className='text-sm break-words whitespace-break-spaces'>{value}</p>
    </div>
  )
}

export { DetailItem }