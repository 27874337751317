import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from "../../../common_component/base/button";
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { formatCentsPriceForDisplay } from '../../../util/string_util';

type Props = {
  purchaseId: string;
  familyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  mutate: () => void;
}

const CancelTransactionDialog = ({ purchaseId, familyId, open, onOpenChange, mutate }: Props) => {
  const { toast } = useToast();
  const [error, setError] = React.useState('');

  const { trigger, isMutating, error: cancelError } = useSWRMutation('/customer/cancel-transaction', (key, { arg }: { arg: {} }) => {
    const data = {
      lateClosing: true,
      purchase_id: purchaseId,
      rtype: "cancel_order_from_helpdesk",
      targetFamilyId: familyId
    }
    try {
      return api.customer.cancelTransaction(data).then(async (response) => {
        if (response) {
          onOpenChange(false)
          try {
            const res = await response.json();
            if (res.email) {
              onOpenChange(false)
              toast({
                title: `The order was cancelled and ${formatCentsPriceForDisplay(res.amount_in_cents)} was added to the account balance.`,
                duration: 3000,
              })
              mutate();
            } else if (res.rtype === 'error') {
              toast({
                title: `${res.message.includes("Order is being processed") ? 'Unable to be cancelled as the item already has been moved into processing already' : res.message}`,
                duration: 3000,
              })
            } else {
              setError('Something went wrong. Please try again.');
            }
          } catch (e) {
            throw new Error('Something went wrong, please try again');
          }
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })

  React.useEffect(() => {
    if (cancelError) {
      setError(cancelError);
    }
  }, [cancelError]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Cancel order</DialogTitle>
        </DialogHeader>
        <p className="text-card-foreground">Are you sure you want to cancel this order?</p>
        <p className="text-card-foreground">The purchase price will be credited to the customer account.</p>
        {error &&
          <p className="pt-4 text-destructive">{error}</p>
        }
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
          <Button type="button" disabled={isMutating} onClick={trigger}>Yes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CancelTransactionDialog