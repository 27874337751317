import React from 'react'

type Props = {
  title: string
}

const SectionTitle = ({ title }: Props) => {
  return (
    <h5 className='px-6 text-xl font-bold sm:px-12 text-text-body font-museo'>
      {title}
    </h5>
  )
}

export default SectionTitle