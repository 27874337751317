import React from 'react'
import { Button } from '../../common_component/base/button'
import api from 'networking/api'
import _ from 'lodash'
import Helmet from 'react-helmet'
import MultipleServiceCommunity from './BrowseShops/MultipleServiceCommunity'
import { pathify } from "../../util/util";
import { useParams, useNavigate } from "react-router-dom";
import orderUtils from 'networking/util/order';
import Image from '../../common_component/Image';
import WelcomeImageDesktop from '../../assets/images/welcome-img-desktop.png';
import WelcomeImageMobile from '../../assets/images/welcome-img-mobile.png';
import WelcomeImageDefault from '../../assets/images/community-shop-welcome-image.png';

type Props = {}

const CommunityShopHome = (props: Props) => {
  const { schoolId } = useParams();
  const navigate = useNavigate();

  const pathifiedSchoolId = pathify(schoolId!)
  const { data: schoolData, isLoading } = api.misc.useSchoolData(pathifiedSchoolId);
  const { data: welcomeImageUrl } = api.family.useSwi(schoolData?.path, false);

  const kindoServices = schoolData?.services.filter((s) => s.shop_fronts?.includes('community') && orderUtils.isServiceUndated(s)) || [];

  React.useEffect(() => {
    if (kindoServices[0] && kindoServices.length <= 1) {
      navigate(`/app/community-shop/${schoolId}/${kindoServices[0].brand_name}/${kindoServices[0].id}`, { replace: true });
    }
  }, [schoolId, kindoServices]);

  return (
    <>
      <Helmet>
        <title>Community Shop Home</title>
      </Helmet>
      {!isLoading &&
        (
          kindoServices.length > 0 ?
            <div className='flex flex-col pb-8'>
              <div className='relative z-10'>
                <div className='flex flex-col items-center gap-6 sm:px-10 lg:px-40 px-0 pb-8'>
                  <div className='flex flex-col items-center text-center font-semibold mt-6 sm:mt-11 font-museo text-3xl sm:text-4xl'>
                    <p>
                      {schoolId}
                    </p>
                    <p>
                      Community Shop
                    </p>
                  </div>
                  <div className='pt-3'>
                    {welcomeImageUrl ?
                      <>
                        <div className='absolute z-[-1] top-[45%] md:top-[50%] lg:top-[55%] xl:top-[58%] left-1/2 w-[95%] md:w-[650px] lg:w-[750px] translate-x-[-50%] translate-y-[-50%]'>
                          <Image
                            className='hidden sm:block object-cover pointer-events-none w-full'
                            alt=''
                            src={WelcomeImageDesktop}
                          />
                          <Image
                            className='block sm:hidden object-cover pointer-events-none w-full'
                            alt=''
                            src={WelcomeImageMobile}
                          />
                        </div>
                        <Image
                          key={welcomeImageUrl}
                          className='object-cover h-[145px] md:h-[200px] lg:h-[245px] pointer-events-none w-[236px] md:w-[290px] lg:w-[360px] rounded-lg z-10 border-4 border-white bg-white shadow-left-center'
                          alt=''
                          src={`${welcomeImageUrl}`}
                        />
                      </>
                      :
                      <Image
                        className='object-cover h-[145px] sm:h-[245px] pointer-events-none w-full z-10'
                        alt=''
                        src={`${WelcomeImageDefault}`}
                      />
                    }
                  </div>
                  <p className='text-card-foreground text-center px-2 lg:px-24'>
                    We love to engage with our community, and you are so vital to helping our students thrive. Here you can participate in fundraisers and find items available for purchase. Thank you for supporting us!
                  </p>
                </div>
              </div>
              <div className='flex flex-col gap-4'>
                <MultipleServiceCommunity />
              </div>
              <div className='flex gap-1 self-center items-center font-museo text-sm pt-4 pb-6'>
                <p>Brought to you by</p>
                <Button variant="linkUnderline" className='p-0 font-semibold' onClick={() => {
                  window.open('https://kindo.co.nz/', '_blank')
                }}>
                  kindo
                </Button>
              </div>
            </div>
            :
            <div className='flex justify-center py-20 px-2'>
              <p className='font-museo text-lg'>There are no community shop services available at the moment.</p>
            </div>
        )
      }
    </>
  )
}

export default CommunityShopHome