import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../common_component/base/dialog";
import { Button } from '../../base/button';
import { Input } from '../../base/input';
import { Student } from 'networking/models/family';
import { useMediaQuery } from '../../../util/hooks/useMediaQuery';

type Props = {
  currentSchool?: string;
  schools: string[]; // Array of school ids
  students?: Student[];
  onSwitchSchool: (schoolId: string) => void;
  open: boolean;
  onOpenChange: (open: boolean) => void;
};

const SchoolSwitcher = ({ currentSchool, schools, students, open, onOpenChange, onSwitchSchool }: Props) => {
  const [searchText, setSearchText] = React.useState('');

  const isMobile = useMediaQuery('(max-width: 640px)');
  React.useEffect(() => {
    if (open) {
      setSearchText('');
    }
  }, [open]);

  const handleSwitchSchool = (schoolId: string) => {
    onOpenChange(false);
    onSwitchSchool(schoolId);
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button className='p-0 text-xs sm:text-sm font-museo' variant="link" size="sm">
          Switch schools
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Switch School</DialogTitle>
          <DialogDescription>
            Select a school to switch to
          </DialogDescription>
        </DialogHeader>
        <div>
          <Input
            autoFocus={!isMobile}
            tabIndex={isMobile ? -1 : 0}
            placeholder="Search for school"
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </div>
        <div className='overflow-y-scroll max-h-[calc(100vh-160px)] sm:max-h-[500px]'>
          {schools
            .filter((s) => s.toLowerCase().includes(searchText.toLowerCase()))
            .sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' }))
            .map((school) => (
              <div key={school} className="flex items-center justify-between p-4 border-b border-border">
                <div className='grid items-center justify-between grid-cols-[1fr_0fr] w-full'>
                  <div>
                    <p className="font-bold cursor-pointer text-md text-card-foreground" onClick={() => {
                      handleSwitchSchool(school);
                    }}>
                      {school}{currentSchool === school && ' (current)'}
                    </p>
                    <p className='flex gap-2'>
                      {students?.filter((s) => s.school === school)
                        .map((student) => (
                          <span key={student.student_id} className='px-2 text-xs rounded-lg border-secondary border-1'>{student.first_name} {student.last_name}</span>
                        ))
                      }
                    </p>
                  </div>
                  <div>
                    <Button
                      size="xs"
                      variant="outline"
                      onClick={() => {
                        handleSwitchSchool(school);
                      }}
                    >
                      Switch
                    </Button>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default SchoolSwitcher