import React, { useEffect, useState, useRef } from "react";
import { useForm } from "react-hook-form";
import { Button } from '../../../common_component/base/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../common_component/base/dialog";
import api from 'networking/api';
import { baseUrl, platformEnv } from 'networking/constants';
import { observer } from 'mobx-react';
import { TaxAccs, TaxAccDonees, TaxAccPostDataAssignments, CustomerReferralResponse } from "networking/models/family";
import { ImageButton } from "../../../common_component/ImageButton";
import { cn } from '../../../util/tailwind';
import { Checkbox } from '../../../common_component/base/checkbox';
import _ from 'lodash';
import { useToast } from '../../../common_component/base/use-toast';
import { useStore } from '../../../store/store';

type FormData = {
  donees?: any;
};

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  doneesAffinity: TaxAccDonees[] | undefined;
  sortedDoneesByAffinity: TaxAccs[];
  doneeList: TaxAccPostDataAssignments[] | undefined;
  doneesAssignments: any;
}

const TaxGiftingDialog = observer(({ open, onOpenChange, doneesAffinity, sortedDoneesByAffinity, doneeList, doneesAssignments }: Props) => {
  const { handleSubmit, setValue, watch, formState: { isSubmitting } } = useForm<FormData>();
  const [step, setStep] = useState(1);
  const [selectedProvider, setSelectedProvider] = useState<TaxAccs>();
  const [selectAllDonees, setSelectAllDonees] = useState(true);
  const [selectSendHistoric, setSelectSendHistoric] = useState(true);
  const [error, setError] = useState('');
  const [showSelectedHistoricDonees, setShowSelectedHistoricDonees] = useState(false);
  const [customerReferralResponse, setCustomerReferralResponse] = useState<CustomerReferralResponse>();
  const { toast } = useToast();
  const store = useStore();

  const hasFamilyJoinedProvider = () => {
    var joined = sortedDoneesByAffinity.find(taxacc => taxacc.taxacc_id === selectedProvider?.taxacc_id);

    if (!joined) {
      return false;
    } else {
      return !!joined.joined_family_id
    }
  }

  const getTaxaccExpectedUrl = (taxaccId: string, origin: string) => {
    if (taxaccId === 'sgen') {
      return origin === 'https://australia-southeast1-fundafuture-production.cloudfunctions.net' || origin === 'https://australia-southeast1-fundafuture-dev.cloudfunctions.net'
    } else if (taxaccId === 'txg') {
      return origin === 'https://portal.taxgift.co.nz' || origin === 'https://taxgift-ripple.alpha.streamlineapp.net'
    } else if (taxaccId === 'ypay') {
      return origin === `https://${platformEnv}.kindo.co.nz`
    }
  }

  const handleSelectAllDonees = () => {
    const updatedSelectAllDonees = !selectAllDonees;
    setSelectAllDonees(updatedSelectAllDonees);

    if (updatedSelectAllDonees && doneeList) {
      setSelectedDonees(doneeList);
    } else {
      setSelectedDonees([]);
    }
  };

  const [selectedDonees, setSelectedDonees] = useState<TaxAccPostDataAssignments[]>(doneeList ? doneeList : []);

  useEffect(() => {
    if (selectedProvider && doneeList) {
      const filteredDoneeList = doneeList.filter((donee) => donee.assigned_taxacc_id !== selectedProvider.taxacc_id);

      setSelectedDonees(
        filteredDoneeList.map((donee) => ({
          ...donee,
          taxacc_id: selectedProvider.taxacc_id,
        }))
      );
    }
  }, [selectedProvider, doneeList]);

  const handleNext = async () => {
    if (step === 1 && selectedProvider && doneeList) {
      const customerReferralPayload = {
        rtype: 'donee_taxacc_referral',
        affiliate_id: selectedProvider.taxacc_id,
        org_id: doneeList[0].donee_id
      }

      try {
        var res = await api.family.getCustomerReferral(customerReferralPayload)
        if (res.ok) {
          const referralResponse = await res.json()
          setCustomerReferralResponse(referralResponse)
          setStep(step + 1)
        }
      }
      catch (ex) {
        throw ex
      }
    }

    const assignments = selectedDonees.map((donee) => ({
      donee_id: donee.donee_id,
      family_id: donee.family_id,
      history: donee.history,
      taxacc_id: selectedProvider?.taxacc_id,
    }));

    if (step === 2 && selectedDonees.length !== 0 && selectedProvider) {
      setStep(step + 1)
      if (!hasFamilyJoinedProvider() && customerReferralResponse) {
        window.addEventListener("message", async (event) => {
          if (getTaxaccExpectedUrl(selectedProvider?.taxacc_id, event.origin)) {
            const message = btoa(event.data)
            const msgJson = JSON.parse(event.data)
            if (msgJson.affiliate_result === 'user_join' || msgJson.affiliate_result === 'user_select') {
              try {
                var joinResponse = await api.family.joinTaxacc(assignments, message)

                if (joinResponse.ok) {
                  toast({
                    title: 'Thank you for your generosity. You have successfully registered!',
                    duration: 10000,
                  });
                  store.shop.setIsTaxGifitingSuccess(true)
                  onOpenChange(false)
                }
              }
              catch (ex) {
                throw ex
              }
            } else if (msgJson.affiliate_result === 'user_decline' || msgJson.affiliate_result === 'affiliate_decline') {
              setError(msgJson.affiliate_comment_public)
            }
          }
          else {
            setError('Kindo:\nHmmm. origin ' + event.origin + " is unexpected. Affiliate handshaking cancelled.");
          }
        }, false);
      }
    }

    if (step === 3 && selectedProvider) {
      if (hasFamilyJoinedProvider()) {
        try {
          var assignmentsResponse = await api.family.setAssignments(assignments)
          if (assignmentsResponse.ok) {
            toast({
              title: 'Thank you for your generosity. You have successfully saved your selections!',
              duration: 10000,
            });
            store.shop.setIsTaxGifitingSuccess(true)
            onOpenChange(false)
          }
        }
        catch (ex) {
          throw ex
        }
      }
    }
  }

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1)
    } else {
      onOpenChange(false);
    }
    setError('')
  }

  const getStepTitle = () => {
    switch (step) {
      case 1:
        return 'Select your provider'
      case 2:
        return `Confirmation`;
      case 3:
        return `Register`;
      default:
        return '';
    }
  }

  const ProvidersStep = (
    <div className='flex flex-col gap-4'>
      {(doneesAffinity && doneesAffinity?.map((affinity) => (
        <p className='text-sm font-semibold' key={affinity.affinity_taxacc_id}>
          {affinity.donee_title} {affinity.affinity_text}
        </p>
      )))}
      {
        (sortedDoneesByAffinity?.filter(function (p) { return (p.taxacc_id !== 'null_taxacc') }).map((provider) => (
          <div className="flex justify-start gap-4" key={provider.taxacc_id}>
            <ImageButton
              key={provider.taxacc_id}
              alt={`image-${provider.taxacc_title}`}
              className={cn("object-contain p-6 text-center border-2 cursor-pointer rounded-3xl w-36 h-28 hover:shadow-right-center border-border-primary", {
                'border-border-card shadow-right-center bg-background-light': selectedProvider?.taxacc_id === provider.taxacc_id
              })}
              onClick={() => { setSelectedProvider(provider) }}
              src={baseUrl + provider.logo_url}
            >
            </ImageButton>
            <p className="flex flex-col justify-center gap-4 mt-0 text-sm item text-card-foreground">
              {provider.pitch ? provider.pitch : undefined}
              <br />
              {provider.info_url ?
                <a className='text-primary' href={provider.info_url} target="_blank" rel="noreferrer">More info.</a>
                : undefined}
            </p>
          </div>
        )))
      }
    </div>
  );

  const ConfirmationStep = (
    <div className='flex flex-col gap-2'>
      <p className='font-semibold font-museo text-text-helper'>This will apply to the following:</p>
      <div>
        <Checkbox
          checked={selectAllDonees}
          label='Select all'
          name='Select all'
          onCheckedChange={handleSelectAllDonees}
        />
      </div>
      {
        doneeList &&
        doneeList.map((donee) => (
          <div>
            <Checkbox
              checked={selectedDonees.some((selectedDonee) => selectedDonee.donee_id === donee.donee_id)}
              label={
                <div className="flex flex-row items-center gap-3">
                  <p>{donee.donee_title}</p>
                  {donee.assigned_taxacc_id && (<img alt={donee.assigned_taxacc_id} className="h-max-[26px] object-contain w-6" src={baseUrl + `upload/images/taxacc/${donee.assigned_taxacc_id}/${donee.assigned_taxacc_id}_logo.png`} />)}
                </div>
              }
              name={donee.donee_title}
              onCheckedChange={(value) => {
                if (value) {
                  setSelectedDonees([...selectedDonees, donee]);
                } else {
                  setSelectedDonees(selectedDonees.filter((selectedDonee) => selectedDonee.donee_id !== donee.donee_id));
                }
              }}
            />

          </div>
        ))
      }

      <p className='mt-4 font-semibold font-museo text-text-helper'>Claim past rebates?</p>
      <Checkbox
        checked={selectSendHistoric}
        label={`Send all donation receipts, for all donees, for the past 4 years to ${selectedProvider?.taxacc_title} (if not already sent to another provider or claimed yourself).`}
        name='send_historic'
        onCheckedChange={() => setSelectSendHistoric(!selectSendHistoric)}
      />
      {selectSendHistoric && (
        <Button
          variant='linkUnderline'
          className=''
          type='button'
          onClick={() => setShowSelectedHistoricDonees(true)}
        >
          Selected donee list
        </Button>
      )}
    </div>
  );

  const DoneesHistoricDialog = (
    <Dialog open={showSelectedHistoricDonees} onOpenChange={(open) => setShowSelectedHistoricDonees(open)}>
      <DialogContent>
        <DialogHeader>
          <p className='text-lg text-primary font-museo'>
            Selected donees
          </p>
        </DialogHeader>
        <div className='flex flex-col gap-1'>
          {selectedDonees &&
            selectedDonees.map((donee) => (
              <div key={donee.donee_id}>
                <Checkbox
                  checked={donee.history}
                  label={donee.donee_title}
                  name='donees'
                  onCheckedChange={(value) => {
                    setSelectedDonees((prevSelectedDonees) =>
                      prevSelectedDonees.map((prevDonee) =>
                        prevDonee.donee_id === donee.donee_id
                          ? { ...prevDonee, history: !!value }
                          : prevDonee
                      )
                    );
                  }}
                />
              </div>
            ))}
        </div>

        <DialogFooter className='flex flex-col w-full sm:flex-col'>
          <Button
            type="button"
            className='text-base font-semibold font-museo'
            onClick={() => setShowSelectedHistoricDonees(false)}
          >
            Done
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )

  const RegisterStep = (
    hasFamilyJoinedProvider() ?
      <p className="text-sm text-card-foreground">
        You have already registered with <span className="font-semibold text-primary">{selectedProvider?.taxacc_title}</span>. Once saved your Kindo Donation Receipts will be sent to <span className="font-semibold text-primary">{selectedProvider?.taxacc_title}</span> automatically. They will claim your tax rebates and gift them on your behalf.
      </p>
      :
      <iframe className="w-full h-[45vh]" title="providerIframe" name="providerIframe" src={customerReferralResponse?.affilliate_url} />
  )

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='w-auto sm:min-w-[45vw]'>
        <DialogHeader>
          <DialogTitle className='text-lg font-semibold font-museo'>
            {getStepTitle()}
          </DialogTitle>
          <DialogDescription>
            <p className='text-sm text-card-foreground'>
              {step === 1 &&
                `Click the logo to select a provider below.`
              }
              {step === 2 &&
                <>
                  You are selecting <span className='font-semibold text-primary'>{selectedProvider?.taxacc_title}</span> as your rebate tax agent for donations made through <span className='font-semibold text-primary'>myKindo</span>. Note: your appointment of <span className='font-semibold text-primary'>{selectedProvider?.taxacc_title}</span> will not affect other tax agent relationships.
                </>
              }
              {step === 3 && !hasFamilyJoinedProvider() &&
                `The ${selectedProvider?.taxacc_title} registration form is below. Once submitted your Kindo Donation Receipts will be sent to ${selectedProvider?.taxacc_title} automatically. They will claim your tax rebates and gift them on your behalf.`
              }
            </p>
          </DialogDescription>
        </DialogHeader>
        <form
          className='flex flex-col gap-2'
          onSubmit={handleSubmit(handleNext)}
        >
          <div>
            {sortedDoneesByAffinity &&
              (
                <div className=''>
                  {step === 1 && doneesAffinity && (ProvidersStep)}
                  {step === 2 && ConfirmationStep}
                  {step === 3 && RegisterStep}
                </div>
              )
            }
            {error &&
              <p className='pt-2 text-sm font-semibold text-destructive'>{error}</p>
            }
          </div>
          <DialogFooter className='flex flex-col w-full sm:flex-col'>
            {step === 3 && hasFamilyJoinedProvider() &&
              <Button
                type="submit"
                variant="default"
                disabled={
                  isSubmitting
                }
                className='text-base font-semibold font-museo'
              >
                Save & Continue
              </Button>
            }
            {(step === 1 || step === 2) &&
              <Button
                type="submit"
                variant="default"
                disabled={
                  (step === 1 && !selectedProvider) ||
                  (step === 2 && selectedDonees.length === 0) ||
                  isSubmitting
                }
                className='text-base font-semibold font-museo'
              >
                Next
              </Button>
            }
            <Button
              variant="ghost"
              type="button"
              className='text-base font-semibold font-museo'
              onClick={handleBack}
            >
              {step === 1 ? 'Cancel' : 'Back'}
            </Button>
          </DialogFooter>
        </form>
        {DoneesHistoricDialog}
      </DialogContent>
    </Dialog >
  )
});

export default TaxGiftingDialog