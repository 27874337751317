import React from 'react';

import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table"

import { Button } from "../../../common_component/base/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common_component/base/table";
import { Input } from '../../../common_component/base/input';
import { Spinner } from '../../../common_component/base/spinner';
import { RowItem } from './Columns';
import { Chip } from 'components/base/chip';
import { ActionsTable } from './ActionsTable';
import { columns as studentColumns } from './ActionsTable/Columns';
import { useForm } from 'react-hook-form';
import CheckboxField from 'components/form/CheckboxField';
import api from 'networking/api';
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from 'components/base/use-toast';
import { Checkbox } from 'components/base/checkbox';
import { MdCheckCircleOutline } from 'react-icons/md';
import InfoTooltip from 'components/tooltip/InfoTooltip';
import _ from 'lodash';

interface DataTableProps<TValue> {
  columns: ColumnDef<RowItem, TValue>[]
  data: RowItem[]
  schoolId: string;
  isLoading?: boolean;
  onRefresh?: () => void;
}

type FormData = {
  addPayablesSelections: {
    [payableId: string]: {
      [bindId: string]: boolean;
    };
  };
  removePayablesSelections: {
    [payableId: string]: {
      [bindId: string]: boolean;
    };
  };
  preview?: boolean;
  noRemoveWithPayment?: boolean;
}

export function MaintenanceTable<TValue>({
  columns,
  schoolId,
  data,
  isLoading,
  onRefresh,
}: DataTableProps<TValue>) {
  const { toast } = useToast();
  const [removeBindingsApplied, setRemoveBindingsApplied] = React.useState([] as string[]);
  const [addBindingsApplied, setAddBindingsApplied] = React.useState([] as string[]);
  const [includeBindingsWithNoActions, setIncludeBindingsWithNoActions] = React.useState(false);
  const { handleSubmit, control, setValue, formState: { isSubmitting, errors }, watch, setError, clearErrors, reset } = useForm<FormData>({
    defaultValues: {
      addPayablesSelections: {},
      removePayablesSelections: {},
      noRemoveWithPayment: true,
    }
  });
  const parentRef = React.useRef<HTMLDivElement>(null)
  const [rowSelection, setRowSelection] = React.useState({});
  const [applyActionsToPayableId, setApplyActionsToPayableId] = React.useState<string>();
  const [expandedRow, setExpandedRow] = React.useState<{ [key: string]: boolean }>({})
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      // default sorting
      id: 'payableName',
      desc: false,
    },
  ])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const table = useReactTable<RowItem>({
    data,
    columns,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    sortDescFirst: false,
    state: {
      rowSelection,
      sorting,
      globalFilter,
    }
  })

  const { rows } = table.getRowModel();
  const filteredRows = React.useMemo(() => rows.filter((row) => {
    return includeBindingsWithNoActions ?
      true :
      (row.original.proposedActions?.remove! > 0 || row.original.proposedActions?.add! > 0)
      && row.original.bindings?.some((binding) => {
        // If at least one binding has not been applied, then show the row
        return (binding.studentsToRemove.length > 0 && !removeBindingsApplied.includes(binding.binding.bind_payable_id))
          || (binding.studentsToAdd.length > 0 && !addBindingsApplied.includes(binding.binding.bind_payable_id));
      });
  }), [includeBindingsWithNoActions, rows, removeBindingsApplied, addBindingsApplied]);

  const onApplyActions = (payableId?: string) => {
    if (payableId) {
      setApplyActionsToPayableId(payableId);
    }
  }

  const applyActions = (values: FormData) => {
    const reapplyBindIds = Object.keys(values.addPayablesSelections[applyActionsToPayableId ?? ''] || {}).flatMap((bindId) => {
      return values.addPayablesSelections[applyActionsToPayableId ?? ''][bindId] ? [bindId] : [];
    })
    const clearBindIds = Object.keys(values.removePayablesSelections[applyActionsToPayableId ?? ''] || {}).flatMap((bindId) => {
      return values.removePayablesSelections[applyActionsToPayableId ?? ''][bindId] ? [bindId] : [];
    });
    return api.admin.applyMaintenanceActions({
      clearBindIds,
      reapplyBindIds,
      schoolId: schoolId,
      preview: values.preview,
      noRemoveWithPayment: values.noRemoveWithPayment,
    }).then((response) => {
      if (response.data) {
        const skippedCount = response.data.reapply_binds_result?.skipped_as_applied_instances_exceeded_count;
        const noRemoveCount = response.data.clear_binds_result?.no_remove_count;
        const removedCount = response.data.clear_binds_result?.cleared_count;
        const addedCount = response.data.reapply_binds_result?.reapplied_count;
        toast({
          title: `${values.preview ? '(PREVIEW) ' : ''}Successfully applied`,
          description: <>
            <p>
              {removedCount || 'No'} {removedCount === 1 ? 'payable' : 'payables'} removed.
            </p>
            <p>
              {addedCount || 'No'} {addedCount === 1 ? 'payable' : 'payables'} added.
            </p>
            <p>
              {skippedCount ? `${skippedCount} ${skippedCount === 1 ? 'payable' : 'payables'} skipped as applied instances would exceed maximum count.` : ''}
            </p>
            <p>
              {noRemoveCount ? `${noRemoveCount} ${noRemoveCount === 1 ? 'payable' : 'payables'} were not removed.` : ''}
            </p>
          </>,
          duration: 8000,
        });
        if (!values.preview) {
          if (removedCount) {
            // Add to applied list if any payables were removed so that it doesn't show up in the table
            setRemoveBindingsApplied((prev) => [...prev, ...clearBindIds]);
            setValue(`removePayablesSelections.${applyActionsToPayableId}`, {});
          }
          if (addedCount) {
            // Add to applied list if any payables were added so that it doesn't show up in the table
            setAddBindingsApplied((prev) => [...prev, ...reapplyBindIds]);
            setValue(`addPayablesSelections.${applyActionsToPayableId}`, {});
          }

          setApplyActionsToPayableId(undefined);
        }
      } else if (response.error) {
        toast({
          title: 'Failed to apply',
        })
      }
    })
  };

  const rowToAction = filteredRows.find((row) => row.original.id === applyActionsToPayableId);
  const rowRemoveActions = Object.keys(watch(`removePayablesSelections.${applyActionsToPayableId}`) || {}).filter((bindId) => watch(`removePayablesSelections.${applyActionsToPayableId}.${bindId}`));
  const rowAddActions = Object.keys(watch(`addPayablesSelections.${applyActionsToPayableId}`) || {}).filter((bindId) => watch(`addPayablesSelections.${applyActionsToPayableId}.${bindId}`));

  React.useEffect(() => {
    if (!isLoading && data) {
      // Reset form when payable changes or when data is loaded
      reset({
        noRemoveWithPayment: true,
        preview: false,
        addPayablesSelections: data.reduce((all, row) => {
          return {
            ...all,
            [row.id]: row.bindings?.reduce((result, binding) => {
              return {
                ...result,
                [binding.binding.bind_payable_id]: binding.studentsToAdd.length > 0,
              }
            }, {}),
          }
        }, {}),
        removePayablesSelections: data.reduce((all, row) => {
          return {
            ...all,
            [row.id]: row.bindings?.reduce((result, binding) => {
              return {
                ...result,
                [binding.binding.bind_payable_id]: binding.studentsToRemove.length > 0,
              }
            }, {}),
          }
        }, {}),
      })
    }
  }, [isLoading, data]);

  React.useEffect(() => {
    if (applyActionsToPayableId) {
      setValue('noRemoveWithPayment', true);
    }
  }, [applyActionsToPayableId])

  return (
    <div className='flex flex-col'>
      <Dialog open={!!applyActionsToPayableId} onOpenChange={() => {
        setApplyActionsToPayableId(undefined);
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Apply Actions - Review Required</DialogTitle>
          </DialogHeader>
          <div className='flex flex-col gap-4 text-card-foreground'>
            <div className='flex flex-col text-sm text-text-body-'>
              <p>
                Please review your actions before applying.
              </p>
            </div>
            <div>

              <div className="flex flex-col">
                <p className="text-sm font-museo text-text-helper">
                  Payable
                </p>
                <p className="font-bold">
                  {rowToAction?.original?.payableName}
                </p>
              </div>
            </div>

            <div className="flex flex-col gap-4">
              {
                rowAddActions.length > 0 && (
                  <div>
                    <p className="text-sm font-museo text-text-helper">
                      Add payable to:
                    </p>
                    <p className="font-bold">
                      {
                        rowAddActions.map((bindId) => {
                          const binding = rowToAction?.original.bindings?.find((binding) => binding.binding.bind_payable_id === bindId);
                          const studentsToAddCount = binding?.studentsToAdd.filter((student) => !student.exceededMaxInstance).length;
                          const studentsToSkipCount = binding?.studentsToAdd.filter((student) => !!student.exceededMaxInstance).length;
                          return (
                            <div key={bindId} className="flex flex-row items-center gap-2">
                              <p>
                                {binding?.metagroup === 'year_level' ? 'Year' : ''} {binding?.group}
                              </p>
                              <p className='text-sm font-normal text-text-body'>
                                {studentsToAddCount} {studentsToAddCount === 1 ? 'student' : 'students'}{studentsToSkipCount ? `- ${studentsToSkipCount} ${studentsToSkipCount === 1 ? 'student' : 'students'} will be skipped` : ''}
                              </p>
                            </div>
                          )
                        })
                      }
                    </p>
                  </div>
                )
              }
              {
                rowRemoveActions.length > 0 && (
                  <div>
                    <p className="text-sm font-museo text-text-helper">
                      Remove payable for:
                    </p>
                    <p className="font-bold">
                      {
                        (rowRemoveActions).map((bindId) => {
                          const binding = rowToAction?.original.bindings?.find((binding) => binding.binding.bind_payable_id === bindId);
                          return (
                            <div key={bindId} className="flex flex-row items-center gap-2">
                              <p>
                                {binding?.metagroup === 'year_level' ? 'Year' : ''} {binding?.group}
                              </p>
                              <p className='text-sm font-normal text-text-body'>
                                {binding?.studentsToRemove.length} {binding?.studentsToRemove.length === 1 ? 'student' : 'students'}
                              </p>
                            </div>
                          )
                        })
                      }
                    </p>
                  </div>
                )
              }
              {
                rowRemoveActions.length > 0 && (
                  <div className='flex items-center gap-2'>
                    <CheckboxField
                      control={control}
                      name="noRemoveWithPayment"
                      label="Leave part and fully paid payables for individual action"
                    />
                    <InfoTooltip
                      side="top"
                      messages={[
                        'We recommend leaving this option ticked.',
                        'If an item has been paid (in full or in part) and the student is no longer required to pay, you may wish to refund the caregiver.',
                        'You may also wish to leave all paid items for students who have left so they are still visible on your reporting.',
                        'Remember: Refunds can occur if the payment is still visible, and you can retire payables via the Payable Status page.',
                      ]}
                    />
                  </div>
                )
              }
            </div>
            {
              isSubmitting && (
                <div className='flex items-center justify-center flex-1 gap-3'>
                  <p>Please wait while we apply changes...</p>
                  <Spinner size="xs" />
                </div>
              )
            }
          </div>
          <DialogFooter className="flex flex-row sm:flex-row">
            <div className="flex items-center flex-1">
              <CheckboxField
                control={control}
                name="preview"
                label="Preview"
              />
            </div>
            <Button variant="outline" onClick={() => setApplyActionsToPayableId(undefined)}>Cancel</Button>
            <Button disabled={isSubmitting} onClick={handleSubmit(applyActions)}>
              {isSubmitting ? 'Applying...' : 'Apply'}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="flex items-center gap-3 p-4">
        {isLoading ? (
          null
        ) : (
          <>
            <p>
              {
                Object.keys(rowSelection).length > 0
                  ? `${Object.keys(rowSelection).length} items selected`
                  : `${_.sumBy(filteredRows, (row) => row.original.allBindings.length)} Payable Rules`
              }
            </p>
          </>
        )}
        <div className="flex-1" />
        <Checkbox
          name="includeBindingsWithNoActions"
          checked={includeBindingsWithNoActions}
          label="Show all rules"
          onCheckedChange={(checked) => {
            setIncludeBindingsWithNoActions(!!checked);
          }}
        />
        <Input
          placeholder='Search for payables'
          value={globalFilter}
          onChange={(e) => setGlobalFilter(e.target.value)}
        />
        {
          onRefresh && (
            <Button variant="outline" size="sm" disabled={isLoading} onClick={onRefresh}>
              Refresh
            </Button>
          )
        }
      </div>
      <div ref={parentRef} className="border-t min-h-[150px] overflow-auto">
        <div className='min-h-[150px]'>
          <Table className='table-fixed'>
            <TableHeader
              className='sticky top-0 z-20 table-header-group bg-gray-50 border-b-1 border-border'>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className=''>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id} className='font-bold font-museo'
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className='table-row-group'>
              {filteredRows?.length ? (
                filteredRows.map((row, index) => {
                  const rowData = row.original;
                  const addActionsCount = rowData?.proposedActions?.add || 0;
                  const removeActionsCount = rowData?.proposedActions?.remove || 0;
                  const hasActions = addActionsCount > 0 || removeActionsCount > 0;
                  const isExpanded = row.getIsExpanded();
                  const checkedAddPayables = (rowData.bindings || []).reduce((sum, binding) => {
                    const checked = watch(`addPayablesSelections.${rowData.id}.${binding.binding.bind_payable_id}`);

                    return sum + (checked ? 1 : 0);
                  }, 0);
                  const checkedRemovePayables = (rowData.bindings || []).reduce((sum, binding) => {
                    const checked = watch(`removePayablesSelections.${rowData.id}.${binding.binding.bind_payable_id}`);

                    return sum + (checked ? 1 : 0);
                  }, 0);
                  const totalAddPayables = (rowData.bindings || []).reduce((sum, binding) => {
                    return binding.studentsToAdd.length > 0 ? sum + 1 : sum;
                  }, 0);
                  const totalRemovePayables = (rowData.bindings || []).reduce((sum, binding) => {
                    return binding.studentsToRemove.length > 0 ? sum + 1 : sum;
                  }, 0);
                  const totalChecked = checkedAddPayables + checkedRemovePayables;
                  const allActionsChecked = totalChecked === (totalAddPayables + totalRemovePayables)
                  return (
                    <React.Fragment key={rowData.id}>
                      <TableRow
                        key={`${rowData.id}-row`}
                        className='table-row cursor-pointer data-[state=expanded]:bg-primary data-[state=expanded]:text-primary-foreground data-[state=expanded]:border-2 data-[state=expanded]:border-primary'
                        data-state={isExpanded && "expanded"}
                        onClick={() => {
                          if (hasActions) {
                            row.toggleExpanded();
                          }
                        }}
                      >
                        {row.getAllCells().map((cell) => (
                          <TableCell className='table-cell' key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                      {isExpanded && hasActions && (
                        <>
                          <TableRow
                            key={`${rowData.id}-expanded`}
                            className='border-2 last:border-2 border-primary bg-primary-lightest hover:bg-primary-lightest'
                            data-state={isExpanded && "expanded"}
                          >
                            <TableCell />
                            <TableCell colSpan={3}>
                              <div className='flex items-center gap-2 mb-2'>
                                <Checkbox
                                  name={`allActions-${rowData.id}`}
                                  checked={allActionsChecked}
                                  label={`Proposed actions:`}
                                  onCheckedChange={() => {
                                    // Toggle checked
                                    setValue(`addPayablesSelections.${rowData.id}`, rowData?.bindings?.reduce((result, binding) => {
                                      if (binding.studentsToAdd.length > 0) {
                                        return {
                                          ...result,
                                          [binding.binding.bind_payable_id]: !allActionsChecked,
                                        }
                                      }
                                      return result;
                                    }, {}) || {});
                                    setValue(`removePayablesSelections.${rowData.id}`, rowData?.bindings?.reduce((result, binding) => {
                                      if (binding.studentsToRemove.length > 0) {
                                        return {
                                          ...result,
                                          [binding.binding.bind_payable_id]: !allActionsChecked,
                                        }
                                      }
                                      return result;
                                    }, {}) || {});
                                    // HACK: Forces row to re-render
                                    row.toggleExpanded();
                                    row.toggleExpanded();
                                  }}
                                />
                              </div>
                              <div className='flex flex-col gap-5'>
                                {
                                  rowData.bindings?.filter((binding) => {
                                    return (binding.studentsToRemove.length > 0 && !removeBindingsApplied.includes(binding.binding.bind_payable_id))
                                      || (binding.studentsToAdd.length > 0 && !addBindingsApplied.includes(binding.binding.bind_payable_id));
                                  }).map((binding, index) => {
                                    const { studentsToAdd, studentsToRemove } = binding;
                                    const totalActions = studentsToAdd.length + studentsToRemove.length;
                                    const showAddStudents = expandedRow[`add-${binding.binding.bind_payable_id}`];
                                    const showRemoveStudents = expandedRow[`remove-${binding.binding.bind_payable_id}`];
                                    const hasStudentsToAdd = studentsToAdd.length > 0 && !addBindingsApplied.includes(binding.binding.bind_payable_id);
                                    const hasStudentsToRemove = studentsToRemove.length > 0 && !removeBindingsApplied.includes(binding.binding.bind_payable_id);
                                    return (
                                      <div className='flex flex-col gap-2' key={binding.binding.bind_payable_id}>
                                        <div>

                                          <p className='text-lg'>
                                            {binding.metagroup === 'year_level' ? 'Year ' : ''}{binding.group}
                                          </p>
                                        </div>
                                        <div className='flex flex-col gap-1 pl-4 ml-1 border-l-4 border-primary'>
                                          {
                                            errors.root?.message && (
                                              <p className='text-sm text-danger-foreground'>
                                                {errors.root.message}
                                              </p>
                                            )
                                          }
                                          <div className='flex flex-col gap-2'>
                                            {
                                              hasStudentsToAdd && (
                                                <div className='flex flex-col gap-2'>
                                                  <div className='flex items-center gap-2'>
                                                    <CheckboxField
                                                      control={control}
                                                      label={`Add payables (${studentsToAdd.length})`}
                                                      name={`addPayablesSelections.${rowData.id}.${binding.binding.bind_payable_id}`}
                                                    />
                                                    <Button
                                                      variant={"link"}
                                                      size="xs"
                                                      onClick={() => {
                                                        setExpandedRow((prev) => ({
                                                          ...prev,
                                                          [`add-${binding.binding.bind_payable_id}`]: !showAddStudents,
                                                        }));
                                                      }}
                                                    >
                                                      {showAddStudents ? 'Hide students' : 'Show students'}
                                                    </Button>
                                                  </div>
                                                  {
                                                    showAddStudents && (
                                                      <div className='pl-8'>
                                                        <ActionsTable
                                                          tableName="Add Payables"
                                                          columns={[
                                                            ...studentColumns,
                                                          ]}
                                                          data={binding.studentsToAdd}
                                                        />
                                                      </div>
                                                    )
                                                  }
                                                </div>
                                              )
                                            }

                                            {
                                              hasStudentsToRemove && (
                                                <div className='flex flex-col gap-2'>
                                                  <div className='flex gap-2'>
                                                    <CheckboxField
                                                      control={control}
                                                      label={`Remove payables (${studentsToRemove.length})`}
                                                      name={`removePayablesSelections.${rowData.id}.${binding.binding.bind_payable_id}`}
                                                    />
                                                    <Button
                                                      variant={"link"}
                                                      size="xs"
                                                      onClick={() => {
                                                        setExpandedRow((prev) => ({
                                                          ...prev,
                                                          [`remove-${binding.binding.bind_payable_id}`]: !showRemoveStudents,
                                                        }));
                                                      }}
                                                    >
                                                      {showRemoveStudents ? 'Hide students' : 'Show students'}
                                                    </Button>
                                                  </div>
                                                  {
                                                    showRemoveStudents && (
                                                      <div className='pl-8'>
                                                        <ActionsTable
                                                          tableName="Remove Payables"
                                                          columns={studentColumns}
                                                          data={binding.studentsToRemove}
                                                        />
                                                      </div>
                                                    )
                                                  }
                                                </div>
                                              )
                                            }
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  })
                                }
                              </div>
                            </TableCell>
                            <TableCell className='align-top'>
                              <div>
                                <Button
                                  disabled={isSubmitting}
                                  size="xs"
                                  onClick={() => {
                                    if (totalChecked === 0) {
                                      setError('root', {
                                        message: 'Please select at least one action to apply'
                                      })
                                    } else {
                                      clearErrors('root');
                                      onApplyActions(rowData.id);
                                    }
                                  }}
                                >
                                  Apply actions {totalChecked > 0 ? `(${totalChecked})` : ''}
                                </Button>
                              </div>
                            </TableCell>
                          </TableRow>

                        </>
                      )}
                    </React.Fragment>
                  )
                })
              ) : (
                !isLoading &&
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-lg text-center">
                    <div className='flex items-center justify-center gap-2'>
                      {globalFilter ? `No results for '${globalFilter}'` :
                        <>
                          <MdCheckCircleOutline
                            size={40}
                            className={'text-green-400'}
                          />
                          <p>
                            You're all up-to-date with proposed actions
                          </p>
                        </>
                      }
                    </div>
                  </TableCell>
                </TableRow>
              )}
              {
                isLoading ? (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      <div className='flex items-center justify-center gap-2'>

                        <Spinner size="xs" />
                        Please wait, page is loading.  This may take a few minutes
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow />
                )
              }
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
