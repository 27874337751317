import React from 'react'

import Select, { GroupBase } from "react-select";
import { Props } from "react-select/dist/declarations/src/Select";

const ComboBox = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
>(props: Props<Option, IsMulti, Group>) => {
  return (
    <Select
      {...props}
      classNames={{
        placeholder: () => 'text-sm',
        singleValue: () => 'text-sm',
        control: () => `h-[34px]`,
        indicatorsContainer: () => `h-[34px]`,
        menu: () => `text-sm font-sans`,
      }}
      styles={{
        ...props.styles,
        menu: (base) => ({
          ...base,
        }),
        valueContainer: (base) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
          margin: 0,
        }),
        input: (base) => ({
          ...base,
          height: 34,
          padding: 0,
          margin: 0,
        }),
      }}
      theme={(theme) => ({
        ...theme,
        borderRadius: 8,
        spacing: {
          baseUnit: 4,
          controlHeight: 34,
          menuGutter: 8,
        },
        colors: {
          ...theme.colors,
          primary: `hsl(var(--primary))`,
          primary25: `hsl(var(--primary-lightest))`,
          primary50: `hsl(var(--primary-light))`,
          primary75: `hsl(var(--primary-dark))`,
          danger: `hsl(var(--danger))`,
        }
      })}
    />
  );
}

export { ComboBox }