import React from 'react'
import { Product, Question, Student } from 'networking/models/family'
import { useFieldArray, useForm } from 'react-hook-form';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../common_component/base/dialog";

import RadioGroupField from '../../../common_component/form/RadioGroup';
import { Checkbox } from '../../../common_component/base/checkbox';
import { Button } from '../../../common_component/base/button';
import _ from 'lodash';
import { QuestionInput } from 'networking/models/order';
import InputField from '../../../common_component/form/InputField';
import DatePickerField from '../../../common_component/form/DatePickerField';
import { add, format } from 'date-fns';
import { formatCentsPriceForDisplay } from '../../../util/string_util';
import { observer } from 'mobx-react';
import { useStore } from '../../../store/store';
import api from 'networking/api';
import SelectField from '../../../common_component/form/SelectField';
import { Spinner } from '../../../common_component/base/spinner';
import analytics from '../../../util/googleAnalytics';
import { ScrollArea } from '../../../common_component/base/scroll-area';
import useDetectSticky from '../../../util/hooks/useDetectSticky';
import { cn } from '../../../util/tailwind';
import { Collapse } from '../../../common_component/base/collapse';
import KindoWell from '../../../common_component/KindoWell';
import { useIntersectionObserver } from '../../../util/hooks/useIntersectionObserver';
import CheckboxField from "components/form/CheckboxField";

type FormData = {
  id: string;
  choice?: string;
  questions?: QuestionInput[];
  options?: string[];
  room?: string;
}
type Props = {
  item: Product;
  open: boolean;
  questions?: Question[];
  defaultValues?: FormData;
  student?: Student;
  onSubmit: (data: FormData) => Promise<any>;
  onOpenChange: (open: boolean) => void;
}

const ProductChoices = observer(({ student, open, item, onSubmit, defaultValues, questions, onOpenChange }: Props) => {
  const store = useStore();
  const [isChoicesSticky, choicesTitleRef] = useDetectSticky()
  const [isOptionsSticky, optionsTitleRef] = useDetectSticky()
  const endOfContentRef = React.useRef<HTMLDivElement>(null);
  const endOfContent = useIntersectionObserver(endOfContentRef, {});
  const endOfOptionsRef = React.useRef<HTMLDivElement>(null);
  const endOfOptions = useIntersectionObserver(endOfOptionsRef, {});
  const scrollRef = React.useRef<HTMLDivElement>(null);

  const [scrollType, setScrollType] = React.useState<"auto" | "hover">("auto");

  const [shouldCollapseChoices, setShouldCollapseChoices] = React.useState(false);
  const itemChoiceNames = item?.choice_names ?? [];
  const itemOptNames = item?.opt_names ?? [];
  const showRoomPicker = item.delivery_address === 'room' && (student && !student.room) && !store.shop.isCommunityShop;
  const initialValues = {
    ...defaultValues,
    id: item.permanent_id
  }
  const { control, handleSubmit, watch, setValue, reset, getValues, formState: { isSubmitting, errors, isDirty } } = useForm<FormData>({
    defaultValues: {
      ...initialValues
    },
  });
  const { fields } = useFieldArray({
    control,
    name: 'questions',
  });
  React.useEffect(() => {
    if (open) {
      const values = getValues();
      if (!isDirty || values.id !== initialValues.id) {
        reset({
          ...initialValues,
        });
      }
    }
  }, [open])

  const { data, isLoading } = api.family.useFamilyRooms(showRoomPicker ? store.family.currentSchool : undefined);
  const selectedOptions = watch('options', []);
  const selectedChoice = watch('choice', '');
  const itemHasChoices = itemChoiceNames.length > 0;
  const itemHasOptions = itemOptNames.length > 0;
  const itemHasChoicesOrOptions = itemHasChoices || itemHasOptions;
  const itemHasQuestions = item.has_questions || (questions || []).length > 0;
  const [currentViewIndex, setCurrentViewIndex] = React.useState(0);

  const currentRunningTotal = React.useMemo(() => {
    let total = parseInt(item.price_in_cents, 10);
    if (selectedOptions && selectedOptions.length > 0 && item.option_price_in_cents > 0) {
      const extraOptions = selectedOptions.length - item.included_option_count;
      if (extraOptions > 0) {
        total += (extraOptions) * item.option_price_in_cents;
      }
    }
    return total;
  }, [selectedOptions]);

  const steps = [
    ...(itemHasChoicesOrOptions ? ['choice'] : []),
    ...(itemHasQuestions ? ['questions'] : []),
    ...(showRoomPicker ? ['rooms'] : []),
  ];
  const currentView = steps[currentViewIndex];

  const submit = (data: FormData) => {
    return onSubmit(data).then((success) => {
      if (success) {
        reset();
        setCurrentViewIndex(0);
      }
    });
  }

  const handleCancel = () => {
    if (currentViewIndex > 0) {
      setCurrentViewIndex((index) => index - 1);
    } else {
      onOpenChange(false);
    }
  }
  const handleConfirm = (data: FormData) => {
    const nextIndex = currentViewIndex + 1;
    if (steps[nextIndex]) {
      // Go next step
      setCurrentViewIndex((index) => index + 1);
    } else {
      const updatedQuestions = data.questions?.map((q) => {
        const answer = (q.answer_type === 'agree' && q.answer === true) ? 'Agree' : q.answer;
        return {
          answer_type: q.answer_type,
          question_id: q.question_id,
          required: q.required,
          question: q.question,
          answer: answer,
        };
      });

      const updatedData = {
        ...data,
        questions: updatedQuestions,
      };

      // Reached end of step, submit form
      submit(updatedData);
    }
  }
  const getTitle = () => {
    switch (currentView) {
      case 'choice':
        if (itemHasChoices && itemHasOptions) {
          return 'Select a choice and options from the lists below';
        }
        if (itemHasChoices) {
          return 'Select a choice';
        }
        if (itemHasOptions) {
          return 'Select options';
        }
        return '';
      case 'questions':
        return 'More information';
      case 'rooms':
        return 'Select room to deliver to';
      default: return '';
    }
  }

  const optionName = (item.opt_name ?? 'options').toLowerCase();

  const runningTotal = (
    currentRunningTotal > 0 && (
      <div className='self-center font-bold'>
        {formatCentsPriceForDisplay(currentRunningTotal)}
      </div>
    )
  )

  const freeOptions = item.included_option_count - (selectedOptions?.length || 0);
  const remainingOptions = (
    item.included_option_count > 0 && (
      <div className='self-center text-xs text-text-helper'>
        {freeOptions > 0 ? freeOptions : 0} free {optionName} remaining
      </div>
    )
  )


  React.useEffect(() => {
    if ((item?.opt_names || []).length > 0 && (item?.choice_names || []).length > 0 && !!selectedChoice) {
      setShouldCollapseChoices(true);
      if (scrollRef && scrollRef?.current) {
        scrollRef.current?.scrollTo({
          top: 0,
        })
      }
      setTimeout(() => {
        if (optionsTitleRef && optionsTitleRef?.current) {
          optionsTitleRef.current?.scrollIntoView({
            behavior: 'smooth',
          })
        }
      }, 800)
    }
  }, [selectedChoice]);

  React.useEffect(() => {
    // If user has not selected a choice and tries to submit
    // then scroll to top to see error message
    if (errors.choice?.message) {
      scrollRef.current?.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [errors.choice?.message, isSubmitting])

  React.useEffect(() => {
    // HACK: to recalculate scroll bar height after showing/hiding choices
    setScrollType('hover');
    setTimeout(() => {
      setScrollType('auto')
    }, 400)
  }, [shouldCollapseChoices]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent asChild className='h-[100%] sm:h-auto gap-0'>

        <form
          className='flex flex-col'
          onSubmit={handleSubmit(handleConfirm)}
        >
          <DialogHeader className='sticky top-0 space-y-2 bg-card'>
            <DialogTitle>
              {getTitle()}
            </DialogTitle>
            <DialogDescription className='text-text-helper'>
              For {item.product}
            </DialogDescription>
          </DialogHeader>
          <ScrollArea ref={scrollRef} thick type={scrollType}>
            <div className='flex-1 relative h-full max-h-[calc(100vh_-_380px)]'>
              {
                currentView === 'choice' && (
                  <div>
                    {
                      itemHasChoices && (
                        <div className={
                          cn('py-4', {
                            'border-b-1 border-border': shouldCollapseChoices,
                          })
                        }>
                          {
                            (itemHasOptions || errors.choice?.message) && (
                              <div
                                ref={choicesTitleRef}
                                className={
                                  cn('mb-1 py-2 transition-shadow font-semibold bg-card', {
                                    'sticky -top-[2px] shadow-bottom': isChoicesSticky && !shouldCollapseChoices,
                                  })
                                }
                              >
                                {
                                  errors.choice?.message && (
                                    <div className='pt-2 pr-6'>
                                      <KindoWell
                                        className='mb-4 border-0'
                                        variant={'danger'}
                                        messages={[
                                          <span className='text-sm font-normal text-text-body'>
                                            {errors.choice.message}
                                          </span>
                                        ]}
                                      />
                                    </div>
                                  )
                                }

                                {
                                  itemHasOptions &&
                                  <>
                                    {
                                      shouldCollapseChoices ? 'Your choice' : 'Select a choice'
                                    }
                                  </>
                                }
                              </div>
                            )
                          }
                          <Collapse collapsed={!shouldCollapseChoices}>
                            <div className='flex items-center justify-between pr-6'>
                              <p className='flex flex-col gap-1 text-card-foreground'>
                                <p>
                                  {selectedChoice}
                                </p>
                              </p>
                              <Button type="button" variant="outline" size="sm" onClick={() => setShouldCollapseChoices(false)}>Change</Button>
                            </div>
                          </Collapse>
                          <Collapse
                            collapsed={shouldCollapseChoices}
                          >
                            <RadioGroupField
                              control={control}
                              options={itemChoiceNames.map((choice) => ({
                                label: choice,
                                value: choice,
                              }))}
                              rules={{
                                required: 'Please select a choice'
                              }}
                              name="choice"
                              onValueChange={(value) => {
                                analytics.triggerClick({
                                  eventName: 'select_choice',
                                  parameters: {
                                    permanent_id: item.permanent_id,
                                    choice: value,
                                  }
                                })
                              }}
                            />
                          </Collapse>
                        </div>
                      )
                    }

                    {
                      itemOptNames.length > 0 && (
                        <div className='pb-6'>
                          <div className="flex flex-col gap-2 mt-4">
                            {
                              (itemHasChoices || item.option_price_in_cents > 0) && (
                                <div
                                  ref={optionsTitleRef}
                                  className={
                                    cn('flex flex-col py-2 transition-shadow gap-1 bg-card', {
                                      'sticky -top-[2px] shadow-bottom': isOptionsSticky,
                                    })
                                  }
                                >
                                  {
                                    itemHasChoices && (
                                      <div className="font-semibold">Select {optionName}</div>
                                    )
                                  }
                                  {
                                    item.option_price_in_cents > 0 && (
                                      <div className='pr-4 text-sm text-text-helper'>
                                        {
                                          item.included_option_count > 0 && (
                                            <span>
                                              <span>
                                                {item.included_option_count} {optionName}
                                              </span>
                                              &nbsp;included in the price.&nbsp;
                                            </span>
                                          )
                                        }
                                        <span>
                                          Options are&nbsp;
                                          <span>
                                            {formatCentsPriceForDisplay(item.option_price_in_cents)}
                                          </span>
                                          &nbsp;
                                          {item.included_option_count > 0 ? 'for each extra option' : 'each'}
                                        </span>
                                      </div>
                                    )
                                  }
                                </div>
                              )
                            }
                            <div className="flex flex-col gap-2">
                              {
                                itemOptNames.map((optName, index) => (
                                  <div>
                                    <Checkbox
                                      checked={selectedOptions?.includes(optName)}
                                      label={optName}
                                      name={optName}
                                      onCheckedChange={(value: boolean) => {
                                        setValue('options', _.xor(selectedOptions, [optName]));
                                        if (value) {
                                          analytics.triggerClick({
                                            eventName: 'select_option',
                                            parameters: {
                                              permanent_id: item.permanent_id,
                                              option: optName,
                                            }
                                          });
                                        }
                                      }}
                                    />
                                  </div>
                                ))
                              }
                              {/* <div ref={endOfOptionsRef} /> */}
                              {/* Scroll more indicator -- Uncomment if required */}
                              {/* <div
                                className={cn('sticky bottom-0', {
                                })}
                              >
                                <div className={cn('transition-all h-6 opacity-100 delay-200 translate-y-0 flex gap-1 items-center justify-end w-full py-1', {
                                  'opacity-0 translate-y-6 h-0': endOfOptions?.isIntersecting || !isOptionsSticky,

                                })}>
                                  <MdArrowDownward className='w-4 h-4' />
                                  <p className='text-xs font-museo'>Scroll down for more {optionName}</p>
                                </div>
                              </div> */}
                            </div>
                          </div>

                        </div>
                      )
                    }
                  </div>
                )
              }
              {
                currentView === 'questions' && (
                  <div className='pr-4'>
                    {
                      fields.map((question, index) => {
                        return (
                          <div className='flex flex-col gap-5 mt-4' key={question.id}>
                            {
                              question.answer_type === 'text' && (
                                <InputField
                                  control={control}
                                  name={`questions.${index}.answer`}
                                  required={question.required}
                                  label={question.question}
                                  rules={{
                                    ...(question.required ? { required: 'Please provide an answer for this question' } : {}),
                                    validate: {
                                      noUnsupportedCharacters: (value) => {
                                        if (value) {
                                          const charactersNotAllowed = ['`', '~', '#', '%', '^', '=', '<', '>', '”', ';', '[', ']', '{', '}', '|', '\\', '\''];
                                          const usedUnallowedCharacters = charactersNotAllowed.filter((char) => value.indexOf(char) > -1);
                                          if (usedUnallowedCharacters.length > 0) {
                                            return `The following characters are not allowed: ${usedUnallowedCharacters.join(' ')}`;
                                          }
                                        }
                                      },
                                    },
                                  }}
                                />
                              )
                            }
                            {
                              question.answer_type === 'yes_no' && (
                                <RadioGroupField
                                  control={control}
                                  label={question.question}
                                  options={
                                    [
                                      {
                                        label: 'Yes',
                                        value: 'Yes',
                                      },
                                      {
                                        label: 'No',
                                        value: 'No',
                                      },
                                    ]
                                  }
                                  name={`questions.${index}.answer`}
                                  required={question.required}
                                  rules={{
                                    ...(question.required ? { required: 'Please provide an answer for this question' } : {})
                                  }}
                                />
                              )
                            }
                            {
                              question.answer_type === 'date' && (
                                <DatePickerField
                                  transform={{
                                    input: (value) => value ? new Date(value) : null,
                                    output: (value) => {
                                      return value ? format(value, 'yyyy-MM-dd') : '';
                                    }
                                  }}
                                  calendarProps={{
                                    captionLayout: 'dropdown-buttons',
                                    fromDate: new Date('1900'),
                                    toDate: add(new Date(), { years: 10 }),
                                  }}
                                  control={control}
                                  label={question.question}
                                  name={`questions.${index}.answer`}
                                  rules={{
                                    ...(question.required ? { required: 'Please select a date for this question' } : {})
                                  }}
                                />
                              )
                            }
                            {
                              question.answer_type === 'agree' && (
                                <CheckboxField
                                  control={control}
                                  name={`questions.${index}.answer`}
                                  label={question.question}
                                  rules={{
                                    ...(question.required ? { required: 'Please tick the checkbox' } : {})
                                  }}
                                />
                              )
                            }
                          </div>
                        )
                      })
                    }
                  </div>
                )
              }
              {
                currentView === 'rooms' && (
                  <div className='pr-4'>
                    {
                      isLoading && (
                        <div className='flex justify-center py-2'>
                          <Spinner />
                        </div>
                      )
                    }
                    {
                      !isLoading && (data || []).length > 0 && (
                        <SelectField
                          label={`Room for ${student?.first_name}:`}
                          control={control}
                          items={(data || []).map((room) => ({
                            label: room,
                            value: room,
                          }))}
                          position='item-aligned'
                          rules={{
                            required: `Please select a room for ${student?.first_name}`
                          }}
                          name="room"
                        />
                      )
                    }
                    {
                      !isLoading && (data || []).length === 0 && (
                        <InputField
                          label={`Room for ${student?.first_name}`}
                          control={control}
                          rules={{
                            required: `Please select a room for ${student?.first_name}`
                          }}
                          name="room"
                        />
                      )
                    }
                  </div>
                )
              }
              <div ref={endOfContentRef} />
            </div>
          </ScrollArea>
          <DialogFooter
            className={cn('transition-shadow duration-400 z-10', {
              'shadow-top': !endOfContent?.isIntersecting
            })}
          >
            <div className='self-center flex-1 hidden sm:block'>
              {runningTotal}
              {remainingOptions}
            </div>
            <Button type="button" variant="outline" onClick={handleCancel}>
              {
                currentView === 'questions' && itemHasChoicesOrOptions ? 'Back' : 'Cancel'
              }
            </Button>
            <Button type='submit' disabled={isSubmitting || (currentView === 'rooms' && isLoading)}>
              {
                currentView === 'choice' && itemHasQuestions ? 'Next' : 'Add to Cart'
              }
            </Button>
            <div className='flex items-end justify-between sm:hidden'>
              {runningTotal}
              {remainingOptions}
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog >
  )
})

export default ProductChoices