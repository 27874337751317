import useSWR, { useSWRConfig } from 'swr';
import { baseApi, baseUrl } from './constants';
import { KindoNetworkError } from 'networking/models/common';
import { CreatePayableRequest, CreatePayableResponse, ListPayablesResponse, RolloverRulesData, RollOverRuleRequest, RollOverRuleResponse } from './models/payable';
import fetcher from './util/fetcher';

const payable = {
  // Queries (starts with "use")
  useAllPayables: ({
    schoolId,
    year,
  }: {
    schoolId?: string;
    year?: string;
  }) => {
    const params = new URLSearchParams({
      ...(year ?
        {
          rtype: 'payable_rollover',
          year: year,
        } : {}),
    }).toString();
    const data = useSWR<ListPayablesResponse, KindoNetworkError>(!schoolId ? null : `${baseApi}/supplier/${schoolId}/payables?${params}`, {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 60 * 10, // 10 mins
      revalidateOnReconnect: true,
      revalidateIfStale: false,
      focusThrottleInterval: 1000 * 60 * 5,
    });
    return data;
  },
  usePayableSupplierId: (schoolId?: string) => {
    const data = useSWR<{
      supplier_id: string;
    }, KindoNetworkError>(!schoolId ? null : `${baseApi}/supplier/${schoolId}/get_supplier_id`, {
      revalidateOnFocus: false,
      revalidateIfStale: false,
    });
    return data;
  },
  useAllRules: ({
    schoolId,
    year,
  }: {
    schoolId?: string;
    year?: string;
  }) => {
    const params = new URLSearchParams({
      ...(year ?
        {
          year: year,
        } : {}),
    }).toString();
    const data = useSWR<RolloverRulesData[], KindoNetworkError>(!schoolId ? null : `${baseApi}/supplier/${schoolId}/rules?${params}`, {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 60 * 10, // 10 mins
      revalidateOnReconnect: true,
      revalidateIfStale: false,
      focusThrottleInterval: 1000 * 60 * 5,
    });
    return data;
  },

  // Mutations
  /**
   * This is a very powerful function that can do many things with products.
   * It can:
   * - Create new products, single or multiple
   * - Edit existing products, single or multiple
   * - Delete products
   * Which means this can be used to save a new products, update an existing products, or delete a products, duplicate products,
   * change state of a products, etc.
   * NOTE: Once a product is created, to finalize payable creation you must set the status to 'active' and call the refreshPayables request.
   */
  createAndUpdatePayables: ({ schoolId, data, rtype = 'product_transaction' }: {
    schoolId: string;
    data: CreatePayableRequest;
    rtype: 'product_transaction' | 'product_delete' | 'product_rollover';
  }) => {
    return fetcher(`${baseApi}/supplier/${schoolId}/payables`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...data,
        publish: true,
        rtype,
      }),
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json() as CreatePayableResponse;
        return {
          data: data,
          error: undefined,
        }
      } else {
        const error = await response.json() as KindoNetworkError;
        return { data: undefined, error };
      }
    });
  },

  getNewPermanentId: (schoolId: string) => {
    return fetcher(`${baseApi}/supplier/${schoolId}/get_new_payable_permanent_id`).then(async (response) => {
      if (response.ok) {
        const data = await response.json() as {
          new_permanent_id: string;
        };
        return {
          data: data,
          error: undefined,
        }
      } else {
        const error = await response.json() as KindoNetworkError;
        return { data: undefined, error };
      }
    });
  },
  rollOverRule: (data: RollOverRuleRequest) => {
    return fetcher(`${baseApi}/roll/payable/bind`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    }).then(async (response) => {
      if (response.ok) {
        const data = await response.json();
        return {
          data: data,
          error: undefined,
        } as RollOverRuleResponse
      } else {
        const error = await response.json() as KindoNetworkError;
        return { data: undefined, error };
      }
    });
  },
}

export default payable;