import useSWR from 'swr';
import { baseApi, baseUrl } from './constants';
import {
  Family,
  FamilyPayables,
  TopupInput,
  FamilyWithdrawal,
  SchoolMessagesOfTheDay,
  TermsAndConditions,
  FamilyLevelMember,
  CustomerReferralPayload,
  Calendar,
  TaxAccPostDataPayload,
  FamilySeedResponse,
  FamilyDetails,
  FamilyCloseAccount,
  McatsAndServices,
  FamilyStatus,
} from './models/family';
import { KindoNetworkError } from './models/common';
import fetcher from './util/fetcher';

const family = {
  useFamilySimple: () => {
    const params = new URLSearchParams({
      has_billing_id: "false",
      card_detail: "false",
      get_students: "false",
      saved_order_count: "false",
      last_login: "false",
      school_ids: "false",
      supplier_menu: "false",
      supplier_order: "false",
      force_get_family: "false",
      school_services: "false",
      server_time: "false",
      home_page: "true",
    }).toString();
    const data = useSWR<Family, KindoNetworkError>(`${baseApi}/FAMILY?${params}`);
    return data;
  },
  useFamily: (dataParams?: any, skip: boolean = false) => {
    const params = new URLSearchParams({
      saved_order_count: "true",
      school_services: "true",
      server_time: "true",
    }).toString();
    const data = useSWR<Family, KindoNetworkError>(!skip && `${baseApi}/FAMILY?${dataParams ? new URLSearchParams(dataParams).toString() : params}`, {
      revalidateIfStale: false,
      shouldRetryOnError: true,
      errorRetryCount: 3,
      errorRetryInterval: 15000,
      revalidateOnFocus: true,
      revalidateOnMount: true,
      dedupingInterval: 900000, // 15 minutes - prevents multiple requests within this time frame
      focusThrottleInterval: 240000, // 4 minutes
      refreshInterval: 900000, // 15 minutes
    });
    return data;
  },
  useMyDetails: (skip: boolean = false) => {
    const params = new URLSearchParams({
      phones: "true",
      saved_order_count: "false",
      last_login: "false",
      school_services: "false",
      member_type: "false",
    }).toString();
    const data = useSWR<Family, KindoNetworkError>(!skip && `${baseApi}/FAMILY?${params}`);
    return data;
  },
  useCardDetails: () => {
    const params = new URLSearchParams({
      has_billing_id: "true",
      card_detail: "true",
      get_students: "true",
      saved_order_count: "false",
      last_login: "false",
      school_ids: "false",
      supplier_menu: "false",
      supplier_order: "false",
      force_get_family: "true",
      family_option: "false",
      school_services: "false",
      server_time: "false",
    }).toString();
    const data = useSWR<Family, KindoNetworkError>(`${baseApi}/FAMILY?${params}`);
    return data;
  },
  useFamilyPayables: (schoolDomain: string) => {
    const params = new URLSearchParams({
      domain: schoolDomain,
    }).toString();
    const data = useSWR<FamilyPayables, KindoNetworkError>(schoolDomain ? `${baseApi}/FAMILY/personals?${params}` : undefined);
    return data;
  },
  useFamilyWithdrawal: (skip: Boolean) => {
    const data = useSWR<FamilyWithdrawal, KindoNetworkError>(skip ? null : `${baseApi}/FAMILY/withdrawal`);
    return data;
  },
  useFamilyRooms: (schoolId?: string) => {
    const data = useSWR<string[], KindoNetworkError>(!schoolId ? null : `${baseApi}/school/${schoolId}/room_names`, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
    return data;
  },

  useSchoolMcatsServices: (schoolId?: string) => {
    const data = useSWR<McatsAndServices, KindoNetworkError>(schoolId ? `${baseApi}/school/${schoolId}/mcat_and_services` : null);
    return data;
  },

  useMessageOfTheDay: (schoolId?: string) => {
    const params = new URLSearchParams({
      partner_id: schoolId ?? ''
    }).toString();
    const data = useSWR<SchoolMessagesOfTheDay, KindoNetworkError>(!schoolId ? null : `${baseApi}/partner/motd?${params}`);
    return data;
  },

  useTermsAndConditions: () => {
    const data = useSWR<TermsAndConditions, KindoNetworkError>(`${baseApi}/service_requests/tc`);
    return data;
  },

  // Returns correct image url for school welcome image
  useSwi: (schoolPath?: string, defaultUrl?: string) => {
    return useSWR<string>(schoolPath, async (schoolPath: string) => {
      const welcomeImageUrl = `${baseUrl}upload/images/school/${schoolPath}/${schoolPath}_swi`;
      const swiSrcs = [
        welcomeImageUrl + '.jpg',
        welcomeImageUrl + '.jpeg',
        welcomeImageUrl + '.png',
        ...(defaultUrl ? [defaultUrl] : []),
      ];
      let currentIndex = 0;
      let imageSrc = swiSrcs[currentIndex];
      while (currentIndex < swiSrcs.length) {
        const response = await fetcher(imageSrc, {
          method: 'HEAD',
          cache: 'force-cache'
        });
        if (response.ok) {
          return imageSrc;
        }
        currentIndex++;
        imageSrc = swiSrcs[currentIndex];
      }
      return defaultUrl || '';
    }, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
  },

  useCalendar: () => {
    const data = useSWR<Calendar, KindoNetworkError>(`${baseApi}/FAMILY/calendar`);
    return data;
  },

  useSeed: ({ schoolId }: { schoolId: string }) => {
    const params = new URLSearchParams({
      school_id: schoolId,
    }).toString();
    const data = useSWR<FamilySeedResponse, KindoNetworkError>(`${baseApi}/FAMILY/seed?${params}`);
    return data;
  },

  useFamilyDetails: ({ familyId, start, end, discountSupplierId, maxNotes, supplierId }: { familyId: string, start: string, end: string, discountSupplierId: string, maxNotes: string, supplierId: string }) => {
    const params = new URLSearchParams({
      start: start,
      end: end,
      discount_supplier_id: discountSupplierId,
      ...(supplierId ? { supplier_id: supplierId } : {}),
      ...(maxNotes ? { max_notes: maxNotes } : {}),
    }).toString();
    const data = useSWR<Family, KindoNetworkError>(familyId && discountSupplierId ? `${baseApi}/family/${familyId}?${params}` : undefined, {
      revalidateIfStale: true,
      revalidateOnFocus: true,
    });
    return data;
  },

  useFamilyStatus: ({ familyId }: { familyId: string }) => {
    const data = useSWR<FamilyStatus, KindoNetworkError>(`${baseApi}/family/${familyId}/status`);
    return data;
  },

  // Mutations
  topup: (topup: TopupInput, isTDP?: boolean) => {
    return fetcher(`${baseApi}/FAMILY/${isTDP ? 'topup_deferred_purchases' : 'topups'}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(topup),
    })
  },

  updateStudentRoom: ({
    room,
    studentSchoolId
  }: { room: string; studentSchoolId: string; }) => {
    return fetcher(`${baseApi}/school/enrolments/${studentSchoolId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        room,
        student_school_id: studentSchoolId,
        rtype: 'enrolment',
      }),
    })
  },

  abandonBankTransferTopup: (isReturnOrdersToCheckout: boolean) => {
    return fetcher(`${baseApi}/FAMILY/topups/abandoned`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        release_saved_orders: isReturnOrdersToCheckout,
        topup_id: '__ALL__',
        rtype: 'abandoned_topup',
      }),
    })
  },

  // This login is used for web only
  login: ({ email, password }: {
    email: string;
    password: string;
  }) => {
    const data = new URLSearchParams(); // so that content type is application/x-www-form-urlencoded
    data.append('email', email)
    data.append('password', password)

    return fetcher(`${baseApi}/sessions`, {
      method: 'POST',
      body: data,
    })
  },

  // This login is used for web or mobile
  getLoginSession: ({ email, password }: {
    email: string;
    password: string;
  }) => {
    return fetcher(`${baseApi}/sessions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*',
      },
      credentials: 'include',
      body: JSON.stringify({
        email,
        password
      }),
    })
  },

  resetPassword: (email: string) => {
    return fetcher(`${baseApi}/service_requests/password_reset`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: email,
        rtype: "service_request",
        sr_type: "password_reset"
      }),
    })
  },

  /**
   * 
   * @password New password
   * @profferId Reset password token
   */
  setPassword: ({
    password, // New password
    profferId, // Reset password token
  }: {
    password: string;
    profferId: string;
  }) => {
    return fetcher(`${baseApi}/service_requests/proffered`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        proffer_id: profferId,
        val: password,
      }),
    })
  },

  acceptTerms: (terms: TermsAndConditions) => {
    return fetcher(`${baseApi}/service_requests/tc`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        accept: true,
        tc_spec_accepted: terms,
      }),
    })
  },

  createFamily: (familyData: FamilyLevelMember) => {
    return fetcher(`${baseApi}/family`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(familyData),
    })
  },

  getCustomerReferral: (data: CustomerReferralPayload) => {
    return fetcher(`${baseApi}/FAMILY/referrals`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },

  joinTaxacc: (assignments: any, message: string) => {
    let assignment = assignments.slice(0, 1)

    return fetcher(`${baseApi}/FAMILY/taxaccs/joins`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rtype: "taxacc_forward_join",
        creator: "completion",
        assignments: assignment,
        message: message
      }),
    })
  },

  setAssignments: (assignments: any) => {
    return fetcher(`${baseApi}/FAMILY/taxaccs/assignments`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        assignments: assignments
      }),
    })
  },

  declineTaxacc: (isPermanent: boolean, triggerDonees: any) => {
    return fetcher(`${baseApi}/FAMILY/taxacc/decline`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rtype: "family_taxacc_decline",
        permanent: isPermanent,
        donee_ids: triggerDonees
      }),
    })
  },

  // Seed student: Links a kindo student to a school record
  seedStudents: ({
    links,
    schoolId,
  }: {
    // The id field of 'student' in seed response.
    // Link to existing student or new student - for existing, the format is: tgcl::<student_id>
    links: string[][];
    schoolId: string;
  }) => {
    return fetcher(`${baseApi}/FAMILY/seed`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        links,
        school_id: schoolId,
        rtype: 'reconciliation',
      }),
    })
  },

  sendConfirmationEmail: (purchaseId: string) => {
    return fetcher(`${baseApi}/FAMILY/send_confirmation_email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        purchase_id: purchaseId,
        rtype: "send_confirmation_email_from_my_order"
      }),
    })
  },

  checkResetPasswordToken: (token: string) => {
    const params = new URLSearchParams({
      proffer_id: token,
    }).toString();
    return fetcher(`${baseApi}/service_requests/validate_proffer?${params}`)
  },

  saveFamilyDetails: ({ family, removeHomePageOption }: { family: FamilyDetails, removeHomePageOption: boolean }) => {
    const postData = {
      family: family,
      removeHomePageOption: removeHomePageOption,
    }
    return fetcher(`${baseApi}/FAMILY`, {
      method: 'POST',
      headers: {
        "Content-Type": "text/json"
      },
      body: JSON.stringify(postData),
    })
  },
  closeAccount: () => {
    return fetcher(`${baseApi}/FAMILY/closure`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
  logout: () => {
    return fetcher(`${baseApi}/FAMILY/logout`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  },
}

export default family;