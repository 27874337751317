import * as React from "react"
import { VariantProps, cva } from "class-variance-authority"
import { Label } from './label';
import { cn } from "../../util/tailwind"
import { MdError } from "react-icons/md"

const inputVariants = cva(
  "flex w-full rounded-lg border border-border-input bg-input text-sm ring-offset-background file:border-0 file:bg-input file:text-sm focus-visible:border-primary file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring focus-visible:ring-offset-0  disabled:bg-muted disabled:text-muted-foreground disabled:border-gray-400 placeholder:text-gray-400",
  {
    variants: {
      variant: {
        sm: "h-[28px] px-2 py-1",
        default: 'h-[34px] px-3 py-2',
      },
      border: {
        default: "",
        error: "border-destructive focus-visible:border-destructive focus-visible:ring-destructive",
        success: "border-success focus-visible:border-success focus-visible:ring-success",
        warning: "border-warning focus-visible:border-warning focus-visible:ring-warning"
      },
    },
    defaultVariants: {
      border: 'default',
      variant: "default",
    },
  }
);

const adornmentVariants = cva(
  "absolute top-0 bottom-0 left-0 flex items-center justify-center rounded-tl-lg rounded-bl-lg",
  {
    variants: {
      size: {
        lg: "w-9",
        md: "w-7",
        sm: "w-5",
      },
      variant: {
        plain: "text-text-body ml-1",
        primaryFilled: "bg-primary text-primary-foreground",
        primary: "text-primary ml-1",
        secondaryFilled: "bg-secondary text-secondary-foreground",
        secondary: "text-secondary ml-1",
      },
    },
    defaultVariants: {
      size: "sm",
      variant: "primaryFilled",
    },
  }
)
export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  label?: string | React.ReactNode;
  error?: string | React.ReactNode;
  warning?: string | React.ReactNode;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  adornmentVariants: VariantProps<typeof adornmentVariants>;
  helperText?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ adornmentVariants: aVariants = {}, border, className, variant, label, error, warning, startAdornment, endAdornment, disabled, helperText, ...props }, ref) => {
    const hasEndAdornment = !!endAdornment || !!error;
    const input = (
      <div className={cn({
        'flex items-center relative': !!startAdornment || hasEndAdornment,
      })}>
        {startAdornment && (
          <div className={cn(adornmentVariants(aVariants), {
            'bg-gray-400': disabled
          })}>
            {startAdornment}
          </div>
        )}
        <input
          data-testid={`input-${props.name}`}
          className={cn(inputVariants({ variant, className, border: border || (error ? 'error' : (warning ? 'warning' : 'default')) }), {
            'pl-6': !!startAdornment && (!aVariants.size || aVariants.size === 'sm'),
            'pl-8': !!startAdornment && aVariants.size === 'md',
            'pl-11': !!startAdornment && aVariants.size === 'lg',
            'pr-6': !!endAdornment,
          })}
          disabled={disabled}
          ref={ref}
          {...props}
        />
        {hasEndAdornment && (
          <div className="absolute right-3">
            <div className="flex items-center gap-1">
              {endAdornment}
              {
                error && (
                  <MdError className="w-4 h-4 text-destructive" />
                )
              }
            </div>
          </div>
        )}
      </div>
    )
    if (!label) return input;
    return (
      <div className="flex flex-col">
        {label &&
          <Label
            htmlFor={props.name}
            className="mb-1"
          >
            {label}{props.required ? <span className="text-danger-foreground"> *</span> : ''}

            {
              helperText && (
                <span className="mb-1 ml-2 text-xs font-normal text-text-helper">
                  {helperText}
                </span>
              )
            }
          </Label>
        }

        {input}

        {error && (
          <p className="mt-1 text-sm font-semibold text-destructive">
            {error}
          </p>
        )}
        {warning && (
          <p className="mt-1 text-sm font-semibold text-warning">
            {warning}
          </p>
        )}
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }