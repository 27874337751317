import React from 'react';
import { useLocation } from 'react-router-dom';

interface BannerOptions {
  title?: string;
  description?: string;
  actions?: React.ReactNode;
  maxBanner?: number;
}

interface Banner extends BannerOptions {
  id: number;
}

interface BannerContextProps {
  showBanner: (options: BannerOptions) => void;
  banners: Banner[];
}

const BannerContext = React.createContext<BannerContextProps | undefined>(undefined);

export const useBanner = (): BannerContextProps => {
  const context = React.useContext(BannerContext);
  if (!context) {
    throw new Error('useBanner must be used within a BannerProvider');
  }
  return context;
};

interface BannerProviderProps {
  children: React.ReactNode;
}

let nextId = 1;

export const BannerProvider: React.FC<BannerProviderProps> = ({ children }) => {
  const [banners, setBanners] = React.useState<Banner[]>([]);
  const location = useLocation();

  const showBanner = (options: BannerOptions) => {
    const id = nextId++;
    const { maxBanner, ...bannerData } = options;
    const newBanner: Banner = { id, ...bannerData };

    setBanners((prev) => {
      // If maxBanner is defined, limit the banners array; otherwise, allow unlimited banners
      const updatedBanners = [newBanner, ...prev];
      return maxBanner ? updatedBanners.slice(0, maxBanner) : updatedBanners;
    });
  };

  // Clear banners on route change
  React.useEffect(() => {
    setBanners([]);
  }, [location]);

  return (
    <BannerContext.Provider value={{ showBanner, banners }}>
      <BannerDisplay />
      {children}
    </BannerContext.Provider>
  );
};

/**
 * Banner Component
 *
 * This component provides a stackable, dismissible banner notification system
 * that displays messages across the app. New banners appear at the top and are
 * dismissed by clicking on the banner itself or navigating away from the page.
 *
 * Usage is similar to a toast notification but is designed for persistent,
 * prominent messages with optional actions.
 * 
 * Sample usage:
 * import { useBanner } from "components/Banner";
 * const { showBanner } = useBanner();
 * showBanner({
 *    description: 'Payable created.',
 *    actions: (
 *      <Button variant="linkUnderline" className="px-1 text-white" onClick={() => { console('action here') }}>Apply to students</Button>
 *    ),
 * }); 
 */

const BannerDisplay: React.FC = () => {
  const { banners } = useBanner();

  return (
    <div className="flex flex-col items-center w-full bg-background-success">
      {banners.map(({ id, title, description, actions }) => (
        <div
          key={id}
          className="flex flex-row items-center justify-center w-full gap-1 px-6 py-2 cursor-pointer sm:px-2"
        >
          <div>
            {title && <h2 className="text-lg font-semibold">{title}</h2>}
            {description && <p className="text-sm text-white">{description}</p>}
          </div>
          {actions && <div className='flex flex-row items-center gap-2'>{actions}</div>}
        </div>
      ))}
    </div>
  );
};
