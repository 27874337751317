import React from 'react';

import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table"
import { useVirtualizer } from '@tanstack/react-virtual'

import { Button } from "../../../common_component/base/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common_component/base/table";
import { Input } from '../../../common_component/base/input';
import { Spinner } from '../../../common_component/base/spinner';

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  groupId?: string;
  schoolId: string;
  smsGroups: string;
  isLoading?: boolean;
  onRefresh?: () => void;
}

export function GroupsTable<TData, TValue>({
  columns,
  groupId,
  schoolId,
  data,
  smsGroups,
  isLoading,
  onRefresh,
}: DataTableProps<TData, TValue>) {
  const parentRef = React.useRef<HTMLDivElement>(null)
  const [rowSelection, setRowSelection] = React.useState({})
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      // default sorting
      id: 'name',
      desc: false,
    },
  ])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const table = useReactTable({
    data,
    columns,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    sortDescFirst: false,
    state: {
      rowSelection,
      sorting,
      globalFilter,
    }
  })

  const { rows } = table.getRowModel()
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 61,
    overscan: 5,
  })
  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <div className='flex flex-col'>
      <div className="flex items-center gap-3 p-4">
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          <>
            <p>
              {Object.keys(rowSelection).length > 0 ? `${Object.keys(rowSelection).length} items selected` : `${rows.length} students`}
            </p>
          </>
        )}
        <div className="flex-1" />
        {
          groupId && (
            <>
              <Input
                placeholder='Search'
                value={globalFilter}
                onChange={(e) => setGlobalFilter(e.target.value)}
              />
              {
                onRefresh && (
                  <Button variant="outline" size="sm" disabled={isLoading} onClick={onRefresh}>
                    Refresh
                  </Button>
                )
              }
            </>
          )
        }
      </div>
      <div ref={parentRef} className="border-t min-h-[150px] overflow-auto" style={{
        maxHeight: 'calc(100vh - 450px)'
      }}>
        <div style={{ height: `${rowVirtualizer.getTotalSize() + 60}px` }} className='min-h-[150px]'>
          <Table className='table-fixed'>
            <TableHeader
              className='sticky top-0 z-10 table-header-group bg-gray-50 border-b-1 border-border'>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} className=''>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className='font-bold font-museo'>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className='table-row-group'>
              {
                isLoading && (
                  <TableRow>
                    <TableCell colSpan={columns.length} className="h-24 text-center">
                      Loading ...
                    </TableCell>
                  </TableRow>
                )
              }
              {rows?.length ? (
                virtualItems.map((virtualRow, index) => {
                  const row = rows[virtualRow.index];
                  return (
                    <TableRow
                      key={row.id}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                      }}
                      className='table-row border-none'
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell className='table-cell' key={cell.id}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })
              ) : (
                !isLoading &&
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    {groupId ? 'No results.' : 'Please select a group first'}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
