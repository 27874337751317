import React from 'react'
import { MdLogout, MdSchool, MdSupervisedUserCircle } from 'react-icons/md';
import api from 'networking/api';
import _ from 'lodash';
import SchoolSwitcher from './components/SchoolSwitcher';
import { baseApi, baseUrl, getSchoolLogo, platformEnv } from 'networking/constants';
import { useStore } from '../../store/store'
import { observer } from 'mobx-react';
import { deleteCookie, isSchoolAdmin } from '../../util/util';
import { setErrorUserDetail } from '../../error/error_reporting';
import { TopLevelNavButton, SubNavProps, NavButtonProps } from './components/NavButton';
import HelpButton from './components/HelpButton';
import DesktopHeader from './components/DesktopHeader';
import MobileHeader from './components/MobileHeader';
import CompactLayout from './components/CompactLayout';
import NavigationDropdown from './components/NavigationDropdown';
import { BannerProvider } from "components/Banner";
import { useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';

type Props = {
  leftItems?: React.ReactElement;
  marginTop?: boolean;
  breadcrumbItems?: { label: string; to: string; active?: boolean; }[];
  children: React.ReactElement
};
const AdminLayout = observer(({ leftItems, children, breadcrumbItems, marginTop }: Props) => {
  const { data, isLoading, mutate } = api.admin.useFamily();
  const store = useStore();
  const { currentSchool } = store.admin;
  const [schoolSwitcherOpened, setSchoolSwitcherOpened] = React.useState(false);

  const { trigger } = useSWRMutation('/logout', async (key, { }: {}) => {
    try {
      const response = await api.family.logout();
      if (response) {
        try {
          const res = await response.json();
          if (res.results) {
            store.family.setIsAuthenticated(false);
            deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
            navigate('/app/login');
          }
        } catch (e) {
          throw new Error('Something went wrong, please try again');
        }
      }
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  const navigate = useNavigate();
  const setMobileNavOpened = (open: boolean) => store.family.setShowMobileNav(open);

  const schoolService = data?.school_services.find((s) => s.school_id === currentSchool);
  const hasMultipleSchools = (data?.school_services.length ?? 0) > 1;

  // Permissions
  const isTgclAdmin = data?.tgcl === 'admin';
  const canSeeAdminPage = isSchoolAdmin(data?.school ?? '');
  const canSeeCustomerPage = isTgclAdmin;
  const canSeeConnectPage = (data?.manager && !!data?.supplier) || isTgclAdmin;

  React.useEffect(() => {
    if (store.family.isAuthenticated) {
      // Refresh family data when user is reauthenticated
      mutate();
    }
  }, [store.family.isAuthenticated]);

  React.useEffect(() => {
    if (!isLoading && data) {
      // set school to default school or first school
      if (data.default_school_id) {
        store.admin.setCurrentSchool(data.default_school_id)
      } else if (data.school_services.length > 0) {
        store.admin.setCurrentSchool(data.school_services[0].school_id)
      }

      if (data?.family_id) {
        // Sentry set user context
        setErrorUserDetail({
          userHandle: data?.handle,
          userId: data?.family_id,
        });
      }
    }
  }, [data, isLoading]);

  const topNavItems = [
    {
      label: "shop",
      to: '/app/shop',
      activePath: '/app/shop/*',
      hideForMobile: true,
      onClick: () => setMobileNavOpened(false),
    },
  ] as NavButtonProps[];

  const dropdownItems = [
    {
      label: 'connect',
      to: `/app/connect`,
      icon: <MdSupervisedUserCircle className='w-4 h-4' />,
      hidden: !canSeeConnectPage,
    },
    {
      label: 'customer',
      url: `${baseUrl}customer.shtml?nodivert&school=${currentSchool}`,
      icon: <MdSupervisedUserCircle className='w-4 h-4' />,
      hidden: !canSeeCustomerPage,
    },
    {
      className: 'hidden sm:flex lg:hidden',
      label: 'switch schools',
      icon: <MdSchool className='w-4 h-4' />,
      onClick: () => {
        setSchoolSwitcherOpened(true)
      },
      hidden: !hasMultipleSchools,
    },
    {
      label: 'logout',
      onClick: () => {
        trigger()
      },
      icon: <MdLogout className='w-4 h-4' />,
    },
  ].filter(({ hidden }) => !hidden) as NavButtonProps[];

  const adminItems = [
    {
      label: "admin home",
      to: `/app/admin`,
      hidden: false,
    },
    {
      label: 'payable status',
      to: `/app/admin/payable-status`,
    },
    {
      label: 'payable apply',
      to: `/app/admin/payable-apply`,
    },
    {
      label: 'point of sale',
      to: `/app/admin/point-of-sale`,
    },
    {
      label: 'groups',
      to: `/app/admin/groups`,
    },
  ].filter(({ hidden }) => !hidden) as NavButtonProps[];

  const handleCloseSubNav = () => {
    setMobileNavOpened(false);
  }

  const subNavItems = [
    {
      label: "admin home",
      to: `/app/admin`,
      onCloseSubNav: handleCloseSubNav,
    },
    {
      label: 'payable status',
      to: `/app/admin/payable-status`,
      onCloseSubNav: handleCloseSubNav,
    },
    {
      label: 'payable apply',
      to: `/app/admin/payable-apply`,
      onCloseSubNav: handleCloseSubNav,
    },
    {
      label: 'groups',
      to: `/app/admin/groups`,
      onCloseSubNav: handleCloseSubNav,
    },
    {
      label: 'shop',
      to: '/app/shop',
      onCloseSubNav: handleCloseSubNav,
    },
  ] as SubNavProps[];

  const schoolSwitcher = (
    <SchoolSwitcher
      currentSchool={currentSchool}
      schools={(data?.school_ids || [])}
      students={data?.students}
      open={schoolSwitcherOpened}
      onOpenChange={(open) => setSchoolSwitcherOpened(open)}
      onSwitchSchool={(schoolId) => {
        store.admin.setCurrentSchool(schoolId);
        api.admin.setDefaultSchool({ schoolId: schoolId });
      }}
    />
  )
  return (
    <div key={currentSchool}>
      {/* Mobile header */}
      <MobileHeader
        schoolPath={schoolService?.path}
        schoolSwitcher={
          <div>
            {schoolSwitcher}
          </div>
        }
        headerContent={
          <>
            <HelpButton />
          </>
        }
        navItems={topNavItems.concat(dropdownItems)}
        subNavItems={subNavItems}
        withAccountMenu
        signedInAs={data?.contact_name}
        onSignOut={() => {
          trigger();
        }}
      />
      {/* Desktop header */}
      <DesktopHeader
        leftContent={
          <div className="hidden md:grid justify-items-end md:w-[200px] xl:w-[260px] 2xl:w-[350px]">
            {
              hasMultipleSchools && (
                <div className='self-center'>
                  {schoolSwitcher}
                </div>
              )
            }
          </div>
        }
        topNavContent={
          <>
            {
              schoolService ? (
                <>
                  <img className='h-[36px] self-center' key={schoolService.school_id} src={getSchoolLogo({ schoolPath: schoolService?.path })} alt="" />
                </>
              ) : (
                !isLoading &&
                <p className='self-center text-sm'>{currentSchool}</p>
              )
            }
            <div className='flex-1' />
            <div className='flex items-center gap-2 justify-self-end'>
              {
                topNavItems
                  .filter((item) => !item.hidden)
                  .map((item) => (
                    <TopLevelNavButton key={item.to || item.url} {...item} />
                  ))
              }
              <NavigationDropdown
                label={"admin"}
                items={
                  adminItems
                }
              />
              <NavigationDropdown
                label={
                  <span className='font-museo'>
                    <span className='text-primary'>my</span>
                    <span className='text-secondary'>kindo</span>
                  </span>
                }
                items={
                  dropdownItems
                }
              />
            </div>
            <HelpButton />
          </>
        }
      />
      <BannerProvider>
        <CompactLayout
          children={children}
          breadcrumbItems={breadcrumbItems}
          leftItems={leftItems}
          marginTop={marginTop}
        />
      </BannerProvider>
    </div>
  )
})

export default AdminLayout