export enum ProfferType {
  UpliftAccount = 'uplift_account',
  CloseAccount = 'close_account',
  Withdrawal = 'withdrawal',
}
export type ProfferBase = {
  rtype: 'proffer_request';
  is_expired: boolean;
  is_resolved: boolean;
}
export type UpliftProfferRequest = {
  email: string;
  proffered: ProfferType.UpliftAccount;
  school_ids: string[];
};
export type WithdrawalProfferRequest = {
  withdrawable_balance_in_cents: number;
  email: string;
  total_wallet_balance_in_cents: number;
  fee_in_cents: number;
  proffered: ProfferType.Withdrawal;
};
export type CloseAccountProfferRequest = {
  amount: number;
  proffered: ProfferType.CloseAccount;
  schools_linked: string[];
  total_account_balance: number; // in cents
  withdrawable_balance_in_cents: number;
  withdrawal_fees: number; // Cents
  email: string;
};

export type ProfferRequest = ProfferBase & (
  UpliftProfferRequest
  | WithdrawalProfferRequest
  | CloseAccountProfferRequest
);

export type ProfferResponse = {
  success: boolean;
}