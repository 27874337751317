import React from 'react'

type Props = { children: React.ReactNode }

const FormGroup = ({ children }: Props) => {
  return (
    <div className="flex flex-wrap items-start gap-4 pb-6 last:border-b-0 last:pb-0 border-b-1 border-border">
      {children}
    </div>
  )
}
export default FormGroup