import React from 'react'
import { Link } from 'react-router-dom';
import { MdOpenInNew } from 'react-icons/md';
import { Helmet } from 'react-helmet';

import api from 'networking/api'
import { useStore } from '../../../store/store';
import { baseApi, baseUrl } from 'networking/constants';
import { Card, CardContent } from '../../../common_component/base/card';
import { Spinner } from '../../../common_component/base/spinner';
import { Button } from '../../../common_component/base/button';
import { useToast } from '../../../common_component/base/use-toast';
import InfoTooltip from '../../../common_component/tooltip/InfoTooltip';
import { isTgclAdmin } from 'util/permissions';

type Props = {}
type ItemProps = {
  title: string;
  description: string;
  to: string;
  reloadDocument?: boolean;
  messages?: string[] | React.ReactNode[];
  target?: '_blank' | '_self';
  hidden?: boolean;
}
const getPayables = (currentSchool: string) => ({
  'payable_catalogue': {
    title: 'Payable Catalogue',
    description: 'Create and manage payables',
    to: '/app/admin/payables',
    messages: [
      '• Create and edit payables',
      <Link to="https://support.kindo.co.nz/portal/en/kb/articles/payable-catalogue-overview" target="_blank">
        <Button
          className='px-0'
          variant="linkUnderline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          Knowledge base link <MdOpenInNew className='w-4 h-4 ml-1' />
        </Button>
      </Link>,
    ]
  },
  'payable_status': {
    title: 'Payable Status',
    description: 'Check and action current applied payables',
    to: '/app/admin/payable-status',
    messages: [
      '• View paid, part paid, and unpaid student payments',
      '• Export of payment status data',
      '• Actions e.g. hide, retire, unapply',
      '• Send email reminders',
      <Link to="https://support.kindo.co.nz/portal/en/kb/kindo-administrator-support/kindo-payables/payable-status" target="_blank">
        <Button
          className='px-0'
          variant="linkUnderline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          Knowledge base link <MdOpenInNew className='w-4 h-4 ml-1' />
        </Button>
      </Link>,
    ]
  },
  'payable_apply': {
    title: 'Payable Apply',
    description: 'Apply payables to students',
    to: '/app/admin/payable-apply',
    messages: [
      '• Apply payables to individual students, groups, rooms, year levels, all of school',
      '• Edit payable details – max applied and payable category',
      <Link to="https://support.kindo.co.nz/portal/en/kb/kindo-administrator-support/kindo-payables/payable-apply" target="_blank">
        <Button
          className='px-0'
          variant="linkUnderline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          Knowledge base link <MdOpenInNew className='w-4 h-4 ml-1' />
        </Button>
      </Link>,
    ]
  },
  'pos': {
    title: 'Point of Sale',
    description: 'Register payments made to school.',
    to: `/app/admin/point-of-sale?school=${currentSchool}`,
    messages: [
      '• Register payments made by card, cash, and bank transfer',
      '• Load credit topups',
      '• Create and release School Managed Accounts',
      '• Caregiver Statements',
      <Link to="https://support.kindo.co.nz/portal/en/kb/kindo-administrator-support/kindo-payables/pos" target="_blank">
        <Button
          className='px-0'
          variant="linkUnderline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          Knowledge base link <MdOpenInNew className='w-4 h-4 ml-1' />
        </Button>
      </Link>,
    ]
  },
  'regroup': {
    title: 'Groups Editor',
    description: 'Create and maintain groups',
    to: '/app/admin/groups',
    messages: [
      '• Kindo Custom Groups - create groups and edit student members',
      '• SMS Groups – View student members (currently for Kamar and Edge only)',
      '• Hide and Unhide groups',
      <Link to="https://support.kindo.co.nz/portal/en/kb/kindo-administrator-support/kindo-payables/custom-groups-editor" target="_blank">
        <Button
          className='px-0'
          variant="linkUnderline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          Knowledge base link <MdOpenInNew className='w-4 h-4 ml-1' />
        </Button>
      </Link>,
    ]
  },
  'payable_maintenance': {
    title: 'Payable Maintenance',
    description: 'Manage payables (based on rules) for new and leaving students.',
    to: '/app/admin/payable-maintenance',
    messages: [
      '• Rules are created when payable/s are applied to groups, rooms, year levels, all of school.',
      '• Apply Action - Apply existing payable rules to new enrolments',
      '• Remove Action - Remove payables applied via rules from students who have left',
      '• Disable rules that are no longer needed (option to hide unpaid and part-paid).',
      <Link to="https://support.kindo.co.nz/portal/en/kb/kindo-administrator-support/kindo-payables/payable-maintenance" target="_blank">
        <Button
          className='px-0'
          variant="linkUnderline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          Knowledge base link <MdOpenInNew className='w-4 h-4 ml-1' />
        </Button>
      </Link>,
    ]
  },
  'reporting': {
    title: 'Reporting',
    description: 'Reports and Data Exports.',
    to: `${baseApi}/content/reporting?school_id=${currentSchool}`,
    reloadDocument: true,
  },
  'roll_view': {
    title: 'Kindo (seed) Roll',
    description: 'Review roll data import from school SMS systems.',
    to: `${baseApi}/content/roll_view?school_id=${currentSchool}`,
    reloadDocument: true,
    messages: [
      '• View student and caregiver data received from your SMS.',
      '• Check which caregivers have set up kindo accounts.',
      '• Export seed data for futher filtering and sorting.',
      <Link to="https://support.kindo.co.nz/portal/en/kb/articles/seed-roll" target="_blank">
        <Button
          className='px-0'
          variant="linkUnderline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          Knowledge base link <MdOpenInNew className='w-4 h-4 ml-1' />
        </Button>
      </Link>,
    ]
  },
  'new_features': {
    title: 'New Features',
    description: 'Find out about the latest kindo features.',
    to: 'https://support.kindo.co.nz/portal/en/kb/kindo-administrator-support/resources/new-upcoming-features',
    reloadDocument: true,
    target: '_blank',
  },
  'full_fee_payer': {
    title: 'Foreign Fee Paying Students',
    description: 'Review Payables for Foreign Fee Paying/International Students.',
    to: `${baseApi}/content/full_fee_payer?school_id=${currentSchool}`,
    reloadDocument: true,
  },
  'xero': {
    title: 'Xero',
    description: 'Load school payment invoices in Xero.',
    to: `${baseApi}/content/olio?school_id=${currentSchool}&v=xero`,
    reloadDocument: true,
  },
  'payable_rollover': {
    title: 'Payable Rollover',
    description: 'Handle rollover of payables for next year',
    to: '/app/admin/rollover/payables',
    messages: [
      '• Handle rollover of payables for next year',
      <Link to="https://support.kindo.co.nz/portal/en/kb/articles/payable-rollover-overview" target="_blank">
        <Button
          className='px-0'
          variant="linkUnderline"
          size="xs"
          onClick={(e) => {
            e.stopPropagation();
          }}>
          Knowledge base link <MdOpenInNew className='w-4 h-4 ml-1' />
        </Button>
      </Link>,
    ]
  },
}) as Record<string, ItemProps>;

const getKindo = (currentSchool: string, canSeeConnectPage: boolean): Record<string, ItemProps> => {
  const items = {
    'order_status': {
      title: 'Order Status',
      description: 'Review and export reports, print labels.',
      to: `${baseUrl}order_status.shtml?school=${currentSchool}`,
      reloadDocument: true,
    },
    'connect': {
      title: 'Connect',
      description: 'View customer account history, refund items, resend receipts.',
      to: `/app/connect`,
      reloadDocument: true,
      hidden: !canSeeConnectPage,
    },
    'shop': {
      title: 'Shopping',
      description: 'Main shopping (and end user home) page.',
      reloadDocument: true,
      to: `/app/shop?shop=${currentSchool}`,
    },
  };

  return Object.fromEntries(
    Object.entries(items)
      .filter(([key, value]) => !value.hidden)
  );
};

const getHelpdesk = (currentSchool: string) => ({
  'upload': {
    title: 'Batch Processing',
    description: 'Upload files for batch processing (eg bulk refund). NOT school specific.',
    to: `${baseApi}/content/upload?school_id=${currentSchool}&workflow=batch`,
    reloadDocument: true,
  },
  'supplier_home': {
    title: 'Supplier Home Page',
    description: 'A work in progress.',
    to: `${baseApi}/content/upload?school_id=${currentSchool}&supplier_id=multiple`,
    reloadDocument: true,
  },
  'customer': {
    title: 'Customer',
    description: 'View customer account history, refund items, resend receipts.',
    to: `${baseUrl}customer.shtml?nodivert&school=${currentSchool}&`,
    reloadDocument: true,
  },
}) as Record<string, ItemProps>;

const SchoolItem = ({ title, description, to, reloadDocument, messages, target }: ItemProps) => {
  return (
    <Link
      className='flex flex-col gap-2 p-4 transition-colors rounded-lg border-border border-1 hover:bg-primary-lightest'
      to={to}
      reloadDocument={reloadDocument}
      target={target}
    >
      <div>
        <div className='flex items-center gap-2'>
          <p className='text-md'>{title}</p>
          {
            messages && (
              <InfoTooltip
                side="bottom"
                messages={messages}
              />
            )
          }
        </div>
        <p className='text-sm text-text-helper'>{description}</p>
      </div>
    </Link>
  )
};
const SchoolAdminHome = (props: Props) => {
  const store = useStore();
  const currentSchool = store.admin.currentSchool;
  const [refreshingPayables, setRefreshingPayables] = React.useState(false);
  const { data, isLoading } = api.admin.useCapabilitiesConfig(currentSchool);
  const { data: familyData, isLoading: isLoadingFamily } = api.family.useFamily();
  const canSeeAdminPage = isTgclAdmin(familyData);
  const canSeeConnectPage = (familyData?.manager && !!familyData?.supplier) || canSeeAdminPage;
  const payables = React.useMemo(() => getPayables(currentSchool), [currentSchool]);
  const kindo = React.useMemo(() => getKindo(currentSchool, canSeeConnectPage), [currentSchool, canSeeConnectPage]);
  const helpdesk = React.useMemo(() => getHelpdesk(currentSchool), [currentSchool]);
  const { toast } = useToast();
  const handleSwitchPartner = async () => {
    const response = await api.admin.getPartnerSession()
    if (response.data) {
      window.open(`${window.location.protocol}//${baseApi}/sessions/shared/offers/${response.data.session_id}`, '_blank')
    } else if (response.error) {
      toast({
        title: 'Failed to switch to partner',
        variant: 'destructive',
      })
    }
  }
  const handleSetHomePage = async () => {
    const response = await api.admin.setAdminHomePage({
      url: '/app/admin',
    })
    if (response.ok) {
      toast({
        title: 'Home page set successfully',
        description: 'Next time you login you will be redirected here',
      })
    } else {
      toast({
        title: 'Failed to set as home page',
        variant: 'destructive',
      })
    }
  }

  const renderSection = (title: string, items: Record<string, ItemProps>, capabilities: string[]) => {
    if (capabilities.length === 0) return null;
    const sectionKeys = Object.keys(items);
    return (
      <div>
        <p className='pb-3 text-xl font-museo'>{title}</p>
        <div className='grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3'>
          {sectionKeys
            .filter((key) => capabilities.includes(key))
            .map((key) => {
              const item = items[key];
              if (!item) return null;
              return (
                <SchoolItem
                  key={key}
                  title={item.title}
                  description={item.description}
                  reloadDocument={item.reloadDocument}
                  messages={item.messages}
                  to={item.to}
                  target={item.target}
                />
              )
            })}
        </div>
      </div>
    )
  }
  const buttons = (
    <div className='flex flex-col items-center gap-3 sm:flex-row'>
      {
        data?.current_home_page !== '/app/admin' && (
          <Button
            className='w-full sm:w-auto'
            variant="outline"
            size="xs"
            onClick={handleSetHomePage}
          >
            Set as home page
          </Button>
        )
      }
      <Button
        className='w-full sm:w-auto'
        variant="outline"
        size="xs"
        onClick={handleSwitchPartner}
      >
        Open partner
      </Button>
    </div>
  )
  return (
    <>
      <Helmet>
        <title>School Admin Home</title>
      </Helmet>
      <div className='flex flex-col pb-6'>
        <div className='pt-8 pb-4 sm:pb-6 '>
          <h2 className='text-3xl font-bold font-museo'>
            Kindo School Admin Home
          </h2>
          <p className='text-sm'>
            {currentSchool}
          </p>
        </div>
        <Card className='rounded-3xl'>
          <CardContent className='pt-4 sm:pt-6'>
            {
              isLoading || isLoadingFamily ? (
                <div className='flex items-center justify-center w-full py-6'>
                  <Spinner size="md" />
                </div>
              ) : (
                <div className='relative flex flex-col gap-6'>
                  <div className='sm:hidden'>
                    {buttons}
                  </div>
                  <div className='absolute top-0 right-0 hidden sm:flex'>
                    {
                      buttons
                    }
                  </div>
                  {
                    renderSection('Kindo Payables', payables, [...(data?.payables ?? [])])
                  }
                  {
                    renderSection('Shopping', kindo, data?.kindo_shop ?? [])
                  }
                  {
                    renderSection('Helpdesk', helpdesk, data?.helpdesk ?? [])
                  }
                </div>
              )
            }
          </CardContent>
        </Card>
      </div>
    </>
  )
}

export default SchoolAdminHome