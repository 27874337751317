import React from 'react';
import Helmet from 'react-helmet'
import ShopLayout from "../../common_component/layout/ShopLayout";
import { useSearchParams, useNavigate } from 'react-router-dom';
import api from 'networking/api';
import { Spinner } from '../../common_component/base/spinner';
import { Button } from '../../common_component/base/button'
import Image from '../../common_component/Image';
import DeskPersonImage from '../../assets/images/person-computer-desk.png'
import TopupDeferredPurchase from './TopupDeferredPurchase'
import PurePurchase from './PurePurchase';
import TaxGiftingDialog from './dialog/TaxGiftingDialog'
import { shuffleArray } from "../../util/util";
import { formatCentsPriceForDisplay } from '../../util/string_util'
import Feedback from './Feedback'
import CommunityShopLayout from "../../common_component/layout/CommunityShopLayout";
import { useStore } from '../../store/store';
import { TaintedPattern } from "../../util/tainted_regexp";
import CompletionCTA from './CompletionCTA'
import { cn } from "../../util/tailwind"
import useSWRMutation from 'swr/mutation';
import { baseUrl } from 'networking/constants';

type ParsedResultType = {
  taxacc_status_url?: string;
  context: string;
  has_errors?: boolean;
  state_text?: string;
  unflagged_text?: string;
  purchase_results?: any;
  amount_in_cents?: number;
  amount_in_cents_without_fee?: number;
  has_user_clicked_back?: boolean;
}
const CompletionPage = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const store = useStore();
  const isCommunityShop = store.shop.isCommunityShop;

  const [showTaxGiftingDialog, setShowTaxGiftingDialog] = React.useState(false);
  const [error, setError] = React.useState('');
  const [parsedResult, setParsedResults] = React.useState<ParsedResultType>();
  const [jsonResult, setJsonResult] = React.useState('');
  const { data: jsonData } = api.misc.useJsonResult(jsonResult)
  const { mutate: refetchFamily } = api.family.useFamily();

  const { trigger: triggerDeclineTaxacc, isMutating: isDecliningTaxacc } = useSWRMutation(`/FAMILY/taxacc/decline`, (url, { arg }: { arg: { isPermanent: boolean, triggerDonees: any } }) => {
    try {
      return api.family.declineTaxacc(arg.isPermanent, arg.triggerDonees);
    } catch (e) { }
  })
  React.useEffect(() => {
    const searchResults = params.get("result")
    if (!searchResults) {
      setError("Failed to load completion page result. Result URL parameter is missing.")
    }

    const searchContext = params.get("context")
    if (!searchContext) {
      setError("Failed to load completion page context. Context URL parameter is missing.")
    }
    if (searchResults && searchContext) {
      if (TaintedPattern.jsonExtensionRegex.test(searchResults)) {
        setJsonResult(searchResults)
      } else {
        const decodedState = searchResults && (atob(searchResults))
        const parsedResult = decodedState && (JSON.parse(decodedState)) as ParsedResultType;

        setParsedResults({
          ...parsedResult,
          context: searchContext,
        })
      }

      if (jsonData) {
        const parsedResult = jsonData as ParsedResultType;

        setParsedResults({
          ...parsedResult,
          context: searchContext,
        })
      }
    }
  }, [jsonData])

  const taxaccUrl = parsedResult?.purchase_results?.taxacc_status_url ?? parsedResult?.taxacc_status_url;

  const { data, isLoading } = api.misc.useTaxaccStatus(taxaccUrl)

  const showOfferTaxAcc = data?.donees && (
    data.donees.filter(
      function (d) {
        return ((d.assigned_taxacc_id != null && d.assigned_taxacc_id.length && d.disposition === 'trigger')) && (d.affinity_taxacc_id === "_taxacc_affinity_declined_")
      }
    ).length !== 0
    ||
    data.donees.filter(
      function (d) {
        return (d.assigned_taxacc_id == null && d.disposition === 'trigger') && (d.affinity_taxacc_id !== "_taxacc_affinity_declined_")
      }
    ).length !== 0
  )

  const isContextTDP = parsedResult?.context === 'tdp';
  const isContextCheckout = parsedResult?.context === 'checkout';
  const isContextTopup = parsedResult?.context === 'topup';
  const isContextPurePurchase = parsedResult?.context === 'pp';

  const hasResultError = parsedResult?.has_errors;
  const isPaymentStateSuccess = parsedResult?.state_text === "completed_successfully" || (isContextPurePurchase && !hasResultError);
  const doneesAffinity = (data?.donees ?? []).filter((data) => data.affinity_taxacc_id !== null && data.disposition === 'trigger');
  const providers = isLoading ? [] : shuffleArray(data?.taxaccs.filter((provider) => provider.taxacc_id !== 'null_taxacc') ?? []);

  React.useEffect(() => {
    if (isPaymentStateSuccess) {
      refetchFamily()
    }
  }, [isPaymentStateSuccess, refetchFamily])

  const sortedDoneesByAffinity = doneesAffinity &&
    (providers.sort((a, b) => {
      const hasAffinityA = doneesAffinity.some((donee) => donee.affinity_taxacc_id === a.taxacc_id);
      const hasAffinityB = doneesAffinity.some((donee) => donee.affinity_taxacc_id === b.taxacc_id);

      if (hasAffinityA && !hasAffinityB) {
        return -1;
      } else if (!hasAffinityA && hasAffinityB) {
        return 1;
      } else {
        return 0;
      }
    }))

  const doneeIDTrigger = (data?.donees || []).filter(function (d) {
    return d.disposition === 'trigger';
  }).map(function (d) {
    return d.donee_id;
  });

  const isTriggerDecline = doneeIDTrigger.length !== 0;

  const transformedDoneeList = data?.donees.map((donee) => ({
    donee_id: donee.donee_id,
    family_id: data.family_id,
    history: true,
    taxacc_id: '',
    donee_title: donee.donee_title,
    assigned_taxacc_id: donee.assigned_taxacc_id
  }));

  React.useEffect(() => {
    return () => {
      // showOfferTaxAcc && !store.shop.isTaxGiftingSucess added to only trigger decline if there is a tax gifting and will avoid getting triggered after first join
      if (isTriggerDecline && !isDecliningTaxacc && (showOfferTaxAcc && !store.shop.isTaxGiftingSucess)) {
        triggerDeclineTaxacc({
          isPermanent: false,
          triggerDonees: doneeIDTrigger
        })
      }
    }
  }, [isTriggerDecline, showOfferTaxAcc]);

  const CompletionPageContent = (
    <div>
      {isLoading ?
        <div className='flex items-center justify-center'>
          <Spinner />
        </div>
        :
        <div>
          <div className='relative z-10'>
            <div className='flex flex-col w-full lg:w-[52%] 2xl:w-[52%] 3xl:w-[63%] min-h-[auto] lg:min-h-[217px] justify-end pb-8'>
              <p className='text-3xl font-semibold sm:mt-11 sm:text-4xl text-primary font-museo'>
                {
                  isContextTopup
                    ? (isPaymentStateSuccess ? 'Thank you' : 'Oops!')
                    : (isPaymentStateSuccess && !error && !hasResultError
                      ? 'Your order has been processed'
                      : 'Oops!')
                }
              </p>
            </div>
            <Image
              className='hidden object-contain lg:block h-[245px] absolute right-1 w-full top-6 pointer-events-none max-w-[360px] rounded-3xl'
              alt='Desk Person Image'
              src={DeskPersonImage}
            />
          </div>

          <div className='flex items-center justify-center w-full pt-8 lg:hidden'>
            <Image
              className='h-[220px] max-w-[100%] rounded-lg object-cover'
              alt='Desk Person Image'
              src={DeskPersonImage}
            />
          </div>


          <div
            className={cn("flex flex-col bg-card mb-10 rounded-lg sm:rounded-[24px] shadow-right-center", {
              'pt-6': !showOfferTaxAcc
            })}
          >

            {(isPaymentStateSuccess && !isContextPurePurchase) &&
              <>
                {(isContextTDP || isContextCheckout) && parsedResult.purchase_results &&
                  <TopupDeferredPurchase result={parsedResult} />
                }

                {((isContextTopup) || (isContextTDP && !parsedResult.purchase_results)) &&
                  <div className='px-4 rounded-b-lg sm:px-6'>
                    <p className="text-lg font-semibold font-museo">Your topup was successful!</p>
                    <div className='flex items-center justify-between'>
                      <div className='text-sm'>
                        <p className='text-card-foreground'>An amount of <span className='font-semibold text-primary'>{formatCentsPriceForDisplay(parsedResult?.amount_in_cents_without_fee)}</span> has been added to your myKindo wallet.</p>
                      </div>
                    </div>
                  </div>
                }
              </>
            }

            {(isContextPurePurchase) &&
              <>
                <PurePurchase result={parsedResult} />
              </>
            }

            {(showOfferTaxAcc && !store.shop.isTaxGiftingSucess) &&
              <div className='px-4 py-6 text-center bg-background-light sm:px-6'>
                <p className="text-lg font-semibold font-museo">Amplify your generous donation</p>
                {doneesAffinity.length !== 0 &&
                  <div className='py-4'>
                    {doneesAffinity.map((affinity) => (
                      <p className='text-sm font-semibold text-card-foreground'>{affinity.donee_title} {affinity.affinity_text}</p>
                    ))}
                  </div>
                }
                <Button variant='default' className='mt-4 font-semibold font-museo' type='button' onClick={() => setShowTaxGiftingDialog(true)}>Regift your rebate</Button>
                <p className='pt-2 text-sm text-card-foreground'>This process takes just a couple of minutes of your time.</p>
                <div className='mt-10'>
                  {
                    (sortedDoneesByAffinity?.filter(function (p) { return (p.taxacc_id !== 'null_taxacc') }).map((provider) => (
                      <div className='flex flex-col items-center gap-1 sm:inline-flex sm:flex-row sm:mb-auto'>
                        <img
                          key={provider.taxacc_id}
                          alt={provider.taxacc_title}
                          className="object-contain h-6"
                          src={baseUrl + provider.logo_url}
                        />
                        <p className='text-sm text-card-foreground'>A digital service that automates the process of claiming back a charitable tax rebate.</p>
                        {provider.info_url &&
                          <a className='mb-4 text-sm sm:mb-0 text-primary' href={provider.info_url} target="_blank" rel="noreferrer">Find out more about {provider.taxacc_title}</a>
                        }
                      </div>
                    )))
                  }
                </div>
              </div>
            }

            {(!isPaymentStateSuccess || hasResultError) && !isContextPurePurchase &&
              <div className='px-4 rounded-b-lg sm:px-6'>
                <p className="text-lg font-semibold font-museo">{parsedResult?.has_user_clicked_back ? 'Something went wrong. No money transferred. No purchases made.' : 'Sorry, there was a problem processing your topup.'}</p>
                <div className='flex items-center justify-between'>
                  <div className='text-sm'>
                    <p className='text-card-foreground'>{parsedResult?.unflagged_text ? parsedResult.unflagged_text : `Payment gateway responded ${parsedResult?.state_text?.replace(/_/g, ' ')}.`}</p>
                  </div>
                </div>
              </div>
            }

            <div className='pb-4 pr-4 sm:pr-6 sm:pb-6'>
              <CompletionCTA isError={!isPaymentStateSuccess || hasResultError} context={parsedResult?.context} />
            </div>

            {(error) &&
              <div className='px-4 rounded-b-lg sm:px-6'>
                <p className="text-lg font-semibold font-museo">Something went wrong.</p>
                <div className='flex items-center justify-between'>
                  <div className='text-sm'>
                    <p className='text-card-foreground'>{error}</p>
                  </div>
                </div>
              </div>
            }

            {(parsedResult?.has_user_clicked_back) &&
              <div className='px-4 pt-2 text-sm rounded-b-lg sm:px-6 text-card-foreground'>
                <ul className='pl-4 list-disc'>
                  <li>If you were topping up while trying to purchase items in your cart, note that <strong>no items were purchased.</strong></li>
                  <li>No funds were received by us from the payment service.</li>
                  <li>If your records show a transfer to <strong>"The Growth Collective"</strong> please contact our <a className="underline text-primary" href="MAILTO:helpdesk@tgcl.co.nz?subject=Failed Topup">helpdesk</a> immediately.</li>
                </ul>
              </div>
            }
            {/* <Feedback showBlueBg={!showOfferTaxAcc || store.shop.isTaxGiftingSucess || !!error} /> */}
            <TaxGiftingDialog
              open={showTaxGiftingDialog}
              onOpenChange={(open) => setShowTaxGiftingDialog(open)}
              sortedDoneesByAffinity={sortedDoneesByAffinity}
              doneesAffinity={doneesAffinity}
              doneeList={transformedDoneeList}
              doneesAssignments={data?.assignments}
            />
          </div>
        </div>
      }
    </div >
  )

  return (
    <div>
      <Helmet titleTemplate=''>
        <title>
          {
            isContextTopup
              ? (isPaymentStateSuccess ? 'Thank you' : 'Oops!')
              : (isPaymentStateSuccess && !error && !hasResultError
                ? 'Your order has been processed'
                : 'Oops!')
          }
        </title>
      </Helmet>
      {isCommunityShop ?
        <CommunityShopLayout>
          {CompletionPageContent}
        </CommunityShopLayout>
        :
        <ShopLayout>
          {CompletionPageContent}
        </ShopLayout>
      }
    </div>
  )
}

export default CompletionPage;