import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form';
import { observer } from 'mobx-react';

import { useStore } from '../../store/store';
import api from 'networking/api';
import { cn } from '../../util/tailwind';
import { tryConvertDollarStringToCents } from '../../util/string_util';
import { parsePageParameters } from '../../util/page_param_helpers';

import ShopLayout from '../../common_component/layout/ShopLayout';
import {
  Card,
  CardContent,
} from '../../common_component/base/card'
import InputField from '../../common_component/form/InputField';
import { Spinner } from '../../common_component/base/spinner';
import { Button } from '../../common_component/base/button';
import { Helmet } from 'react-helmet';
import KindoWell from '../../common_component/KindoWell'

type Props = {}

type FormData = {
  amount: string;
}

type PaymentEvent = {
  event: 'payment_submitted' | 'payment_challenged' | 'payment_expired' | 'payment_id_invalid';
  data: {
    paymentMethod: 'oe';
  }
} | {
  event: 'payment_challenge_response';
  data: {
    paymentMethod: 'oe';
    status: "success" | "failure";
  }
} | {
  event: 'payment_submission_response';
  data: {
    paymentMethod: 'oe';
    status: "approved" | "declined";
  }
} | {
  event: 'payment_input';
  data: {
    paymentMethod: 'oe';
    valid: boolean;
  }
}

type PaymentErrors = {
  title: string;
  description: string;
  shouldReturnOrdersToCheckout?: boolean;
  canTryPayment?: boolean;
}

const OnlineEftposPage = observer((props: Props) => {
  const store = useStore();
  const { topupId, paymentId } = useParams();
  const params = parsePageParameters();
  const signature = params.get('signature') || '';
  const paymentDetails = params.get('paymentDetails') || '';
  const isMobileApp = store.family.isRenderingFromNativeApp;
  const { data: orderData, mutate: refetchOrders } = api.order.useOrderItems();
  const navigate = useNavigate();
  const { control, handleSubmit, formState: { isSubmitting } } = useForm<FormData>();
  const hasValidPaymentId = paymentId && paymentId !== 'undefined';
  const [canCancelPayment, setCanCancelPayment] = React.useState(true);
  const [isSubmittingPayment, setSubmittingPayment] = React.useState(false);
  const [canSubmitPayment, setCanSubmitPayment] = React.useState(true);
  const [currentEvent, setCurrentEvent] = React.useState('');
  const [paymentErrors, setPaymentErrors] = React.useState<PaymentErrors>();
  const [pluginLoaded, setPluginLoaded] = React.useState(false);

  const paymentInitiatedFrom = store.shop.paymentInitiatedFrom;
  const { data, error, isLoading: isVerifying } = api.order.useVerifyOnlineEftposResponse({
    signature,
    paymentDetails,
  });
  const backUrl = paymentInitiatedFrom === 'checkout' ? '/app/checkout' : '/app/wallet';

  const { data: motdData } = api.partner.useMessageOfTheDay('KINDO_ALL', 'eftpos');

  const handleEvent = (event) => {
    const message = event.data as PaymentEvent;
    const { event: paymentEventType, data } = message;
    if (paymentEventType === "payment_submitted") {
      setCurrentEvent(paymentEventType);
      // handle event here
      setSubmittingPayment(true);
    }

    if (paymentEventType === "payment_challenged") {
      setCurrentEvent(paymentEventType);
      // handle event here
      setCanSubmitPayment(false);
    }

    if (paymentEventType === "payment_challenge_response") {
      setCurrentEvent(paymentEventType);
      // handle event here
      setCanCancelPayment(false);
      if (data.status === 'failure') {
        setPaymentErrors({
          title: "Payment Failed",
          description: "Your payment has failed. No payment was made. Please retry submitting your payment.",
          canTryPayment: true,
          shouldReturnOrdersToCheckout: true,
        })
      }
    }

    if (paymentEventType === "payment_submission_response") {
      setCurrentEvent(paymentEventType);
      // handle event here
      setCanCancelPayment(false);
    }

    if (paymentEventType === "payment_input") {
      setCurrentEvent(paymentEventType);
      // handle event here
    }

    if (paymentEventType === "payment_expired") {
      setCurrentEvent(paymentEventType);
      // handle event here
      setCanCancelPayment(false);
      setPaymentErrors({
        title: "Payment Expired",
        description: "Your payment request has expired. No payment was made. Please retry submitting your payment.",
        canTryPayment: true,
      })
    }

    if (paymentEventType === "payment_id_invalid") {
      setCurrentEvent(paymentEventType);
      // handle event here
      setCanCancelPayment(false);
      setPaymentErrors({
        title: "Payment invalid",
        description: "Your payment request is invalid. No payment was made. Please retry submitting your payment.",
        canTryPayment: true,
      })
    }
  }

  const handleBack = () => {
    if (isMobileApp) {
      window.location.href = 'mykindo://checkout';
    } else {
      navigate(backUrl, { replace: true });
    }
  }

  const handleCancelPayment = async () => {
    if ((!paymentErrors || paymentErrors?.shouldReturnOrdersToCheckout) && topupId) {
      await api.order.cancelOnlineEftposPayment({ topupId });
      refetchOrders();
      handleBack();
    } else {
      handleBack();
    }
  }

  const handleRetryPayment = async () => {
    if (topupId) {
      await api.order.cancelOnlineEftposPayment({ topupId });
    }
    const data = await refetchOrders();
    const topupAmount = store.shop.topupAmount;
    const orderIds = data?.items.map((item) => item.order_id) ?? [];
    await handleCreatePayment(topupAmount, orderIds);
  }

  const handleCreatePayment = async (amountInCents: number, orderIds: string[]) => {
    let requestPayload = {
      'amount_in_cents': Math.round(amountInCents),
      'internet_banking_opt': false,
      'options': {
        save_card: "dont_save_card"
      },
      'purchase_detail': {
        orders_too_late_fox_bx: [],
        saved_order_ids: orderIds,
        voucher_token: null
      },
      'topup_method': "online_eftpos"
    }
    const response = await api.family.topup(requestPayload, !!orderIds && orderIds.length > 0);
    const { url, topup_id } = (await response.json()) || {};
    const parts = url.split('/');
    // Get the payment id at the end of the url
    const paymentId = parts[parts.length - 1];
    if (paymentId) {
      window.location.href = `/app/online-eftpos/${topup_id}/${paymentId}`;
    } else {
      alert('Could not get payment id')
    }
  }

  const handleSubmitPayment = () => {
    if (window.paymarkPlugin) {
      window.paymarkPlugin.submitPayment();
    }
  }

  const cancelButton = (
    <Button
      disabled={isSubmitting}
      variant="linkUnderline"
      size="sm"
      onClick={handleSubmit(handleCancelPayment)}
    >
      Cancel Payment
    </Button>
  )

  React.useEffect(() => {
    window.addEventListener("message", handleEvent);
    return () => {
      window.removeEventListener("message", handleEvent);
    }
  }, [])

  React.useEffect(() => {
    if (paymentId && paymentId === 'undefined') {
      // Paymark for some reason sends 'undefined' as the payment id so we remove it from the url
      navigate(`/app/online-eftpos${topupId ? `/${topupId}` : ''}${window.location.search}`, { replace: true });
    }
  }, [paymentId]);

  React.useEffect(() => {
    if (hasValidPaymentId && window.paymarkPlugin && pluginLoaded) {
      window.paymarkPlugin.initialise({
        redirect: true, /** specifying this would enable the plugin to redirect to merchant order summary page **/
        targetElementId: "oe-container", /*div id that holds the card or OE Elements*/
        paymentId, /*id returned after POSTing payment /payment */
        genericErrorMessage: "<unexpected error occured, please try again later>",
        storeCardMethod: "DO_NOT_STORE_CARD",
        onError: (e) => {
          /* callback when an error occurs*/
          if (e) {
            alert(e);
          }
        },
      });

      //render OE
      window.paymarkPlugin.renderOE();
    }
  }, [paymentId, hasValidPaymentId, window.paymarkPlugin, pluginLoaded]);

  React.useEffect(() => {
    if (!isVerifying && data) {
      if (data.finalized && data.completion_url) {
        window.location.href = data.completion_url
      }
    }
  }, [data, isVerifying]);

  React.useEffect(() => {
    const script = document.createElement('script');
    script.src = process.env.DEPLOY_ENV === 'production' ? 'https://clickcustompay.paymark.co.nz/paymarkPlugin.js' : 'https://clickcustompay.uat.paymark.nz/paymarkPlugin.js';
    document.body.appendChild(script);
    script.onload = () => {
      setPluginLoaded(true);
    }
  }, []);

  const renderOE = () => {
    return (
      <div className={cn({
        'px-2': isMobileApp
      })}>
        <Helmet>
          <title>Online Eftpos</title>
        </Helmet>

        <div className='flex flex-col gap-2 pb-3 mt-8 sm:gap-4 sm:items-end sm:pb-6 sm:flex-row'>
          <h2 className='text-3xl font-bold tracking-tight font-museo'>Pay with Online Eftpos</h2>
          <a href="https://support.mykindo.co.nz/portal/en/kb/articles/paying-and-topping-up-using-online-eftpos" target="_blank" rel="noreferrer" className='pb-1 text-sm text-secondary hover:underline'>learn more</a>
        </div>
        <Card>
          <CardContent className='pt-4 sm:pt-6'>
            {
              motdData?.motds && motdData?.motds.length > 0 && motdData?.motds.sort((a, b) => a.priority - b.priority).map((motd) => (
                <KindoWell
                  variant={motd.info_type}
                  key={`${motd.info_type + '_' + motd.priority}`}
                  className='mb-2'
                  messages={[motd.text]}
                />
              ))
            }
            {
              !paymentId && !data && !isVerifying && (
                // Note: This form is for testing purposes only
                <div>
                  <form onSubmit={handleSubmit((data) => {
                    const cents = tryConvertDollarStringToCents(data.amount) ?? 0;
                    const orderIds = orderData?.items.map((item) => item.order_id) ?? [];
                    handleCreatePayment(cents, orderIds);
                  })}>
                    <div className='mt-4'>
                      <InputField
                        control={control}
                        step="any"
                        name="amount"
                        label="Amount"
                        type="number"
                        rules={{
                          required: 'Enter an amount'
                        }}
                      />
                    </div>
                  </form>
                </div>
              )
            }
            {!paymentErrors && (
              <div
                id="oe-container"
                className={cn('', {
                  'h-full': hasValidPaymentId,
                  'min-h-[500px]': currentEvent === 'payment_challenged'
                })}
              />
            )}
            {
              paymentErrors && (
                <div className='flex flex-col w-full gap-4'>
                  <p className='text-sm text-text-body'>
                    {paymentErrors.description}
                  </p>
                  <div className='flex items-center gap-2 mt-4'>
                    {
                      paymentErrors.canTryPayment && store.shop.topupAmount > 0 && (
                        <Button
                          disabled={isSubmitting}
                          size="sm"
                          onClick={handleSubmit(handleRetryPayment)}
                        >
                          Retry Payment
                        </Button>
                      )
                    }
                    {cancelButton}
                  </div>
                </div>
              )
            }
            {
              !data && hasValidPaymentId && canCancelPayment && (
                <div className='flex flex-col items-center gap-2 mt-4 sm:mt-6 sm:flex-row'>
                  {
                    canSubmitPayment && (
                      <>
                        <Button
                          size="sm"
                          className='w-full sm:w-[130px]'
                          disabled={isSubmittingPayment || isSubmitting}
                          onClick={handleSubmit(handleSubmitPayment)}
                        >
                          Submit
                        </Button>

                        <Button
                          disabled={isSubmitting || isSubmittingPayment}
                          size="sm"
                          variant="linkUnderline"
                          onClick={handleSubmit(handleCancelPayment)}
                        >
                          Cancel Payment
                        </Button>
                      </>
                    )
                  }
                </div>
              )
            }
            {
              data && !data.finalized && (
                <div className='relative flex flex-col items-center justify-center w-full gap-4 py-4'>
                  <Spinner size="md" />
                  <p className='text-xl font-museo'>Processing...</p>
                </div>
              )
            }
          </CardContent>
        </Card>
      </div>
    )
  }
  return (
    <ShopLayout>
      {
        renderOE()
      }
    </ShopLayout>
  )
})

export default OnlineEftposPage