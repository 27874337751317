import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from "../../../common_component/base/button";
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { useForm } from 'react-hook-form';
import TextField from '../../../common_component/form/TextField';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../common_component/base/select"

type Props = {
  familyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

type FormProps = {
  message: string
}

const quickMessageTemplate = [
  {
    label: 'Compose',
    value: ''
  },
  {
    label: 'Past cut-off',
    value: 'Your lunch order was still in the cart, this has now gone past the cut-off and will NOT be delivered today. It has been removed. Ph 09869522 Kindo & ezlunch'
  },
  {
    label: 'Not processed',
    value: 'URGENT you have a lunch order in your cart that has not been processed correctly, please place this before the cut off. Ph 09869522 Kindo & ezlunch'
  }
]

const QuickMessageDialog = ({ familyId, open, onOpenChange }: Props) => {
  const { toast } = useToast();
  const [selectedTemplate, setSelectedTemplate] = React.useState('');

  const { control, setValue, handleSubmit } = useForm<FormProps>({
    defaultValues: {}
  });

  const { trigger, isMutating, error } = useSWRMutation('/customer/quick-message', async (key, { arg }: { arg: FormProps }) => {
    try {
      const response = await api.customer.sendQuickMessage({ targetFamilyId: familyId, message: arg.message });

      if (response) {
        onOpenChange(false);
        const res = await response.json();
        if (res.rtype === 'error') {
          toast({
            title: `${res.name}`,
            description: `${res.message}`,
            duration: 3000,
          });
        } else if (res.success) {
          toast({
            title: `Message sent to ${res.recipient_telno}`,
            duration: 3000,
          });
        } else {
          toast({
            title: `${res.public_message}`,
            duration: 3000,
          });
        }
      } else {
        throw new Error('Something went wrong, please try again');
      }
    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  const onSubmit = (data: FormProps) => {
    trigger(data);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Quick message</DialogTitle>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-3 pb-2'>
            <div className='flex flex-col gap-2'>
              <p className='text-sm text-card-foreground'>Template</p>
              <Select
                value={selectedTemplate}
                onValueChange={(value) => { setValue('message', value); setSelectedTemplate(value) }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup id="template">
                    {quickMessageTemplate.map((list) => (
                      <SelectItem key={list.label} value={list.value}>
                        {list.label}
                      </SelectItem>
                    ))}
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
            <TextField
              name="message"
              required
              control={control}
              rows={6}
              label="Message"
              maxLength={120}
              disabled={!!selectedTemplate}
              rules={{
                required: 'Message is required',
                validate: {
                  containsKindo: (value) => {
                    // Check if the message contains the word "Kindo" (case-insensitive)
                    const containsKindo = value.toLowerCase().includes('kindo');
                    return containsKindo || 'Message must contain the word "Kindo"';
                  }
                }
              }}
            />
          </div>
          <p className="text-sm text-text-helper">Note: Text message must contain 'Kindo' (case insensitive).</p>
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
            <Button type="submit" disabled={isMutating}>Send</Button>
          </DialogFooter>
        </form>
        {error && <div className="mt-2 text-sm text-destructive">{error}</div>}
      </DialogContent>
    </Dialog>
  )
}

export default QuickMessageDialog