import React from 'react';

import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table"

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "components/base/table";

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  tableName: string;
}

export function ActionsTable<TData, TValue>({
  columns,
  tableName,
  data,
}: DataTableProps<TData, TValue>) {
  const parentRef = React.useRef<HTMLDivElement>(null)
  const [rowSelection, setRowSelection] = React.useState({})
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      // default sorting
      id: 'studentName',
      desc: false,
    },
  ])
  const [globalFilter, setGlobalFilter] = React.useState('')

  const table = useReactTable({
    data,
    columns,
    globalFilterFn: 'includesString',
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    sortDescFirst: false,
    state: {
      rowSelection,
      sorting,
      globalFilter,
    }
  })

  const { rows } = table.getRowModel()

  return (
    <div className='flex flex-col overflow-hidden rounded-sm bg-card'>
      <div ref={parentRef} className="border-t overflow-auto max-h-[500px]">
        <div>
          <Table className='table-fixed'>
            <TableHeader
              className='sticky top-0 z-10 table-header-group bg-gray-50 border-b-1 border-border'>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead
                        key={header.id} className='h-10 font-bold font-museo'
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize()
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody className='table-row-group'>
              {rows?.length ? (
                rows.map((row, index) => {
                  return (
                    <React.Fragment key={row.id}>
                      <TableRow
                        className='table-row bg-card'
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getAllCells().map((cell) => (
                          <TableCell className='table-cell px-4 py-2 align-top' key={cell.id}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    </React.Fragment>
                  )
                })
              ) : null}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
