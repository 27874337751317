import React from 'react';
import { ColumnDef } from "@tanstack/react-table"
import { MdArrowUpward } from 'react-icons/md';
import { Button } from '../../common_component/base/button';
import { cn } from '../../util/tailwind';
import { Checkbox } from '../../common_component/base/checkbox';

// This type is used to define the shape of our table data.
export type RowItem = {
  // id: string;
  purchaseId: string;
  orderDate?: string;
  studentId?: string;
  mcat: [];
  status: string;
  rows: []
}

const SortableHeader = ({ column, title }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className='px-0 text-sm font-bold text-left text-card-foreground'
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
    </Button>
  )
};

export const columns: ColumnDef<RowItem>[] = [
  {
    accessorKey: "select_all_orders",
    id: "selectAllOrders",
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected()}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    size: 40,
  },
  {
    accessorKey: "order_date",
    header: ({ column }) => (
      <SortableHeader column={column} title="Order Date" />
    ),
  },
  {
    accessorKey: "member",
    header: ({ column }) => (
      <SortableHeader column={column} title="Member" />
    ),
    filterFn: 'multiSelect',
  },
  {
    accessorKey: "student_room",
    header: ({ column }) => (
      <SortableHeader column={column} title="Room" />
    ),
    filterFn: 'multiSelect',
  },
  {
    accessorKey: "student_id",
    header: ({ column }) => (
      <SortableHeader column={column} title="Student ID" />
    ),
  },
  {
    accessorKey: "mcat",
    header: ({ column }) => (
      <SortableHeader column={column} title="Category" />
    ),
    filterFn: 'multiSelect',
  },
  {
    accessorKey: "product",
    header: ({ column }) => (
      <SortableHeader column={column} title="Product" />
    ),
    filterFn: 'multiSelect',
  },
  {
    accessorKey: "choice",
    header: ({ column }) => (
      <SortableHeader column={column} title="Choice" />
    ),
  },
  {
    accessorKey: "options",
    header: ({ column }) => (
      <SortableHeader column={column} title="Options" />
    ),
  },
  {
    accessorKey: "quantity",
    header: ({ column }) => (
      <SortableHeader column={column} title="Qty" />
    ),
  },
  {
    accessorKey: "total",
    header: ({ column }) => (
      <SortableHeader column={column} title="Total" />
    ),
  },
  {
    accessorKey: "contact_name",
    header: ({ column }) => (
      <SortableHeader column={column} title="Account" />
    ),
  },
  {
    accessorKey: "contact_phone",
    header: ({ column }) => (
      <SortableHeader column={column} title="Phone" />
    ),
  },
]
