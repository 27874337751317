import React from 'react'
import ContentDialog from './ContentDialog';
import { ButtonProps } from 'components/base/button';

type RootContentDialogProps = {
  open?: boolean;
  content?: React.ReactNode;
  title?: string;
  description?: string;
  primaryActionLabel?: string;
  primaryActionProps?: ButtonProps;
  primaryActionDisabled?: boolean;
  secondaryActionLabel?: string;
  secondaryActionProps?: ButtonProps
  hideCloseButton?: boolean;
}

const RootContentDialogContext = React.createContext<{
  contentDialogProps: RootContentDialogProps;
  setContentDialogProps: React.Dispatch<React.SetStateAction<RootContentDialogProps>>;
} | null>(null);


const RootContentDialogProvider = ({ children }: { children: React.ReactElement }) => {
  const [dialogState, setDialogState] = React.useState<RootContentDialogProps>({
    open: false,
    content: null,
    title: '',
    description: '',
    primaryActionLabel: 'Save',
    primaryActionProps: undefined,
    secondaryActionLabel: 'Cancel',
    secondaryActionProps: undefined,
    hideCloseButton: false,
  });

  return (
    <RootContentDialogContext.Provider value={{
      contentDialogProps: dialogState,
      setContentDialogProps: setDialogState,
    }}>
      <ContentDialog
        {...dialogState}
        onOpenChange={(open) => setDialogState({ ...dialogState, open })}
      />
      {children}
    </RootContentDialogContext.Provider>
  )
}

/**
 * This component is the root content dialog, which can be easily used
 * to show a simple dialog.
 * Use the `useContentDialog` hook to show the dialog.
 * Example usage:
 * ```tsx
 * const { showDialog, closeDialog } = useContentDialog({
 *  title: 'Dialog Title',
 *  content: <div>Dialog Content</div>,
 *  primaryActionLabel: 'Save',
 *  primaryActionProps: {
 *  onClick: () => {
 *    // Save action
 *    closeDialog();
 *  }}
 * });
 * 
 * // Show the dialog
 * showDialog();
 * 
 * // Override the default props:
 * showDialog({
 *  title: 'New Dialog Title',
 * });
 * ```
 * You can have multiple instances of useContentDialog in the same component.
 * 
 * Limitations:
 * Only one dialog can be shown at a time, cannot nest multiple root dialog. 
 * If you need to show multiple dialogs,
 * you will need to render another dialog component separately.
 * eg using another ContentDialog component.
 */
export const useContentDialog = (props?: RootContentDialogProps) => {
  const {
    content,
    title,
    description,
    primaryActionLabel = 'Save',
    primaryActionProps,
    primaryActionDisabled,
    secondaryActionLabel = 'Cancel',
    secondaryActionProps,
    hideCloseButton,
  } = props ?? {};

  const rootDialogContext = React.useContext(RootContentDialogContext);
  if (!rootDialogContext) {
    throw new Error('useContentDialog must be used within a RootContentDialog.');
  }
  const { contentDialogProps, setContentDialogProps } = rootDialogContext;

  const showDialog = (props?: RootContentDialogProps) => {
    setContentDialogProps({
      open: true,
      content: props?.content ?? content,
      title: props?.title ?? title ?? '',
      description: props?.description ?? description,
      primaryActionLabel: props?.primaryActionLabel ?? primaryActionLabel,
      primaryActionProps: props?.primaryActionProps ?? primaryActionProps,
      secondaryActionLabel: props?.secondaryActionLabel ?? secondaryActionLabel,
      secondaryActionProps: props?.secondaryActionProps ?? secondaryActionProps,
      hideCloseButton: props?.hideCloseButton ?? hideCloseButton,
    });
  }

  const closeDialog = () => {
    setContentDialogProps({
      ...contentDialogProps,
      open: false,
    })
  }

  React.useEffect(() => {
    setContentDialogProps({
      ...contentDialogProps,
      primaryActionProps: {
        ...contentDialogProps.primaryActionProps,
        disabled: primaryActionDisabled,
      },
    });
  }, [primaryActionDisabled])

  return {
    showDialog,
    closeDialog,
  }
}
export default RootContentDialogProvider