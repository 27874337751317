import React from 'react'
import { Button } from '../../common_component/base/button'
import { useForm } from "react-hook-form";
import TopUpInfo from './TopUpInfo';
import InputField from '../../common_component/form/InputField';
import api from 'networking/api';
import { Skeleton } from '../../common_component/base/skeleton'
import { tryConvertDollarStringToCents, formatCentsPriceForDisplay } from '../../util/string_util';
import { Popover, PopoverTrigger, PopoverContent } from '../../common_component/base/popover';
import { Dialog, DialogContent, DialogHeader, DialogFooter } from "../../common_component/base/dialog"
import { useNavigate } from 'react-router-dom';
import analytics from '../../util/googleAnalytics';
import KindoWell from '../../common_component/KindoWell'

type FormData = {
  amount: string;
  registeredBilledPayee: string;
  kindoAccountDetails: string;
  particulars: string;
  paymentReference: number | null;
  topUpAmount: string;
};

type Props = {
  totalPrice?: number;
  orderIds?: any;
  onPurchase?: () => void;
  onError?: (error: any) => void;
};

const BankForm = ({ totalPrice, orderIds, onPurchase, onError }: Props) => {
  const navigate = useNavigate();

  const { data, isLoading } = api.family.useFamily({
    has_billing_id: true,
    card_detail: true,
    get_students: true,
    saved_order_count: false,
    last_login: false,
    school_ids: false,
    supplier_menu: false,
    supplier_order: false,
    force_get_family: true,
    family_option: false,
    school_services: false,
    server_time: false,
    path: "/FAMILY"
  });

  const { control, handleSubmit, setValue, watch } = useForm<FormData>({
    defaultValues: {
      kindoAccountDetails: '02-1257-0090149-000',
      registeredBilledPayee: 'Kindo',
      particulars: !isLoading && data ? data.email.replace(/@/g, '',).replace(/_/g, '').substring(0, 12) : '',
      paymentReference: data?.handle,
      topUpAmount: formatCentsPriceForDisplay(totalPrice ?? 0, { dollarSign: false })
    }
  });

  React.useEffect(() => {
    if (!isLoading && data) {
      setValue('particulars', data.email.replace(/@/g, '',).replace(/_/g, '').substring(0, 12))
      setValue('paymentReference', data.handle)
    }
  }, [data, isLoading]);

  const [showBankTransferDialog, setShowBankTransferDialog] = React.useState(false);

  const onSubmitTopUp = (values: FormData) => {
    let data = {
      'amount_in_cents': totalPrice,
      'internet_banking_opt': false,
      'options': {},
      'purchase_detail': {
        orders_too_late_fox_bx: [],
        saved_order_ids: orderIds,
        voucher_token: null
      },
      'topup_method': "bx"
    }

    api.family.topup(data, !!orderIds)
      .then((res) => {
        if (res) {
          return res.json();
        }
      }).then((responseData) => {
        if (responseData.rtype === 'error') {
          if (onError) {
            onError(responseData);
          } else {
            alert(responseData.message)
          }
        } else if (responseData.url) {
          setShowBankTransferDialog(true)
          analytics.placeOrder({ priceInCents: totalPrice ?? 0, initiatedFrom: 'Bank transfer' })
          if (onPurchase) {
            onPurchase();
          }
        } else {
          alert('OOPS. A topup URL was not generated. This is probably due to a communication failure between our website and our payment provider.\n\nNo funds have been transferred.\n\nPlease try again.')
        }
      })
  }

  const { data: motdData } = api.partner.useMessageOfTheDay('KINDO_ALL', 'bank');

  const [isCopied, setIsCopied] = React.useState({});

  const copyToClipboard = (e, field) => {
    e.preventDefault();
    navigator.clipboard.writeText(watch(field));

    setIsCopied((prevIsCopied) => ({
      ...prevIsCopied,
      [field]: true,
    }));

    setTimeout(() => {
      setIsCopied((prevIsCopied) => ({
        ...prevIsCopied,
        [field]: false,
      }));
    }, 2000);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitTopUp)}>
      {
        motdData?.motds && motdData?.motds.length > 0 && motdData?.motds.sort((a, b) => a.priority - b.priority).map((motd) => (
          <KindoWell
            variant={motd.info_type}
            key={`${motd.info_type + '_' + motd.priority}`}
            className='mb-2'
            messages={[motd.text]}
          />
        ))
      }
      {!isLoading &&
        <div className='flex flex-col gap-6'>
          <div className='grid grid-cols-1 gap-10 md:grid-cols-2 space-between'>
            {
              isLoading && (
                <Skeleton className='w-[240px] h-10' />
              )
            }
            <div className='flex flex-col gap-4 md:max-w-[280px]'>
              <p>To make an online bank transfer, please copy these details into your banking app.</p>

              {totalPrice ?
                <div className='flex items-end gap-3'>
                  <InputField
                    label="Top up amount needed"
                    control={control}
                    name="amount"
                    placeholder={formatCentsPriceForDisplay(totalPrice)}
                    disabled
                    rules={{
                      pattern: {
                        value: /^[0-9]+(\.[0-9]+)?$/,
                        message: 'Please enter a valid amount',
                      }
                    }}
                  />
                  <Popover open={isCopied['topUpAmount']} onOpenChange={(open) => setIsCopied((prevIsCopied) => ({
                    ...prevIsCopied,
                    'topUpAmount': open,
                  }))}>
                    <PopoverTrigger>
                      <Button size="xs" className='h-[34px]' onClick={(e) => copyToClipboard(e, 'topUpAmount')}>
                        Copy
                      </Button>
                    </PopoverTrigger>
                    <PopoverContent align="center" side="top" className='w-auto p-1'>
                      <p className='text-xs text-card-foreground'>Copied!</p>
                    </PopoverContent>
                  </Popover>
                </div>
                : undefined}
              <div className='flex items-end gap-3'>
                <InputField
                  variant="sm"
                  label="Registered billed payee"
                  disabled
                  control={control}
                  name="registeredBilledPayee"
                />
                <Popover open={isCopied['registeredBilledPayee']} onOpenChange={(open) => setIsCopied((prevIsCopied) => ({
                  ...prevIsCopied,
                  'particulars': open,
                }))}>
                  <PopoverTrigger>
                    <Button size="xs" onClick={(e) => copyToClipboard(e, 'registeredBilledPayee')}>
                      Copy
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent align="center" side="top" className='w-auto p-1'>
                    <p className='text-xs text-card-foreground'>Copied!</p>
                  </PopoverContent>
                </Popover>
              </div>

              <div className='flex items-end gap-3'>
                <InputField
                  variant="sm"
                  label="Kindo bank account"
                  disabled
                  control={control}
                  name="kindoAccountDetails"
                />
                <Popover open={isCopied['kindoAccountDetails']} onOpenChange={(open) => setIsCopied((prevIsCopied) => ({
                  ...prevIsCopied,
                  'kindoAccountDetails': open,
                }))}>
                  <PopoverTrigger>
                    <Button size="xs" onClick={(e) => copyToClipboard(e, 'kindoAccountDetails')}>
                      Copy
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent align="center" side="top" className='w-auto p-1'>
                    <p className='text-xs text-card-foreground'>Copied!</p>
                  </PopoverContent>
                </Popover>
              </div>

              <div className='flex items-end gap-3'>
                <InputField
                  variant="sm"
                  label="Particulars"
                  disabled
                  control={control}
                  name="particulars"
                />
                <Popover open={isCopied['particulars']} onOpenChange={(open) => setIsCopied((prevIsCopied) => ({
                  ...prevIsCopied,
                  'particulars': open,
                }))}>
                  <PopoverTrigger>
                    <Button size="xs" onClick={(e) => copyToClipboard(e, 'particulars')}>
                      Copy
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent align="center" side="top" className='w-auto p-1'>
                    <p className='text-xs text-card-foreground'>Copied!</p>
                  </PopoverContent>
                </Popover>
              </div>

              <div className='flex items-end gap-3'>
                <InputField
                  variant="sm"
                  label="Payment reference"
                  disabled
                  control={control}
                  name="paymentReference"
                />
                <Popover open={isCopied['paymentReference']} onOpenChange={(open) => setIsCopied((prevIsCopied) => ({
                  ...prevIsCopied,
                  'particulars': open,
                }))}>
                  <PopoverTrigger>
                    <Button size="xs" onClick={(e) => copyToClipboard(e, 'paymentReference')}>
                      Copy
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent align="center" side="top" className='w-auto p-1'>
                    <p className='text-xs text-card-foreground'>Copied!</p>
                  </PopoverContent>
                </Popover>
              </div>
            </div>
            <TopUpInfo showBankTransferWarning={true} />
          </div>

          {totalPrice ?
            <div className='flex justify-between gap-4'>
              <div></div>
              <Button size='sm' className='font-museo' variant="default" type='submit'>
                Top up with bank transfer
              </Button>
            </div>
            :
            undefined
          }

          <Dialog open={showBankTransferDialog} onOpenChange={(open) => setShowBankTransferDialog(open)}>
            <DialogContent hideCloseButton>
              <DialogHeader>
                <p className='text-lg font-semibold text-primary font-museo'>
                  Top up with bank transfer
                </p>
              </DialogHeader>
              <div className='flex flex-col gap-4 text-card-foreground'>
                <p>Your order will not be placed until we receive notification of your payment from our bank. Allow 1-2 working days.</p>
                <p>You will not receive a confirmation message now (you will receive one when your order is processed)</p>
                <p>Tukuna kia 2 nga ra mo te whakawhitinga putea ki te whakaoti</p>
                <p>银行转账需要2天才能完成</p>
                <p>은행 송금을 완료 하는 데 2 일이 소요 됩니다.</p>
                <p>2 jaj DaH Qay naQ chaw'</p>
              </div>
              <DialogFooter>
                <Button variant='default' className='font-semibold font-museo' onClick={() => navigate('/app/shop')}>Continue</Button>
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </div>
      }
    </form>
  )
}

export default BankForm