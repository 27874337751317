import React from 'react'
import { MdCheckCircle } from 'react-icons/md';
import { usePasswordChecker } from 'util/hooks/usePasswordChecker';
import { cn } from 'util/tailwind'

type Props = {
  password: string;
  minLength?: number;
}

const PasswordRequirements = (props: Props) => {
  const { password, minLength } = props;

  const minimumPasswordLength = minLength || 8;
  const {
    satisfiesMinLength,
    satisfiesNumbers,
    satisfiesLowercase,
    satisfiesUppercase,
    satisfiesSpecialCharacters
  } = usePasswordChecker(password, minimumPasswordLength);

  const satisfactionBullets = [
    {
      satisfies: satisfiesMinLength,
      text: `At least ${minimumPasswordLength} characters`
    },
    {
      satisfies: satisfiesNumbers,
      text: 'Numbers'
    },
    {
      satisfies: satisfiesLowercase,
      text: 'Lower case letters'
    },
    {
      satisfies: satisfiesUppercase,
      text: 'Upper case letters'
    },
    {
      satisfies: satisfiesSpecialCharacters,
      text: 'Any of these special characters !@#$%^&*'
    }
  ]
  return (
    <div className='flex flex-col gap-1 pt-1 pl-4 text-sm text-text-helper'>
      <p>Password must contain:</p>
      {
        satisfactionBullets.map((bullet, index) => (
          <div key={bullet.text} className='flex items-center gap-1'>
            <MdCheckCircle className={cn('h-5 w-5', {
              'text-muted': !bullet.satisfies,
              'text-flags-vegetarian': !!bullet.satisfies,
            })} />
            <p>{bullet.text}</p>
          </div>
        ))
      }
    </div>
  )
}

export default PasswordRequirements