import React from 'react'
import { useLocation } from 'react-router-dom';
import { Button } from '../../../common_component/base/button';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { SubNavButton, SubNavProps } from './NavButton';
import { cn } from '../../../util/tailwind';
import { useIntersectionObserver } from '../../../util/hooks/useIntersectionObserver';

type Props = {
  leftContent: React.ReactNode;
  topNavContent: React.ReactNode;
  subNavItems?: SubNavProps[];
}

const DesktopHeader = (props: Props) => {
  const { leftContent, topNavContent, subNavItems } = props;
  const location = useLocation();

  const subnavRef = React.useRef<HTMLDivElement>(null);
  const startOfSubnavRef = React.useRef<HTMLDivElement>(null);
  const endOfSubnavRef = React.useRef<HTMLDivElement>(null);
  const currentActiveSubNav = React.useRef<HTMLAnchorElement>(null);
  const [subnavOverflowing, setSubnavOverflowing] = React.useState(false);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }
  const startNavEntry = useIntersectionObserver(startOfSubnavRef, {});
  const isStartOfNavVisible = !!startNavEntry?.isIntersecting;
  const endNavEntry = useIntersectionObserver(endOfSubnavRef, {});
  const isEndOfNavVisible = !!endNavEntry?.isIntersecting;

  const isOverflowing = (element: HTMLDivElement) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }
  const scrollLeft = () => {
    if (subnavRef.current) {
      subnavRef.current.scrollBy({
        left: -150,
        behavior: 'smooth',
      })
    }
  }

  const scrollRight = () => {
    if (subnavRef.current) {
      subnavRef.current.scrollBy({
        left: 150,
        behavior: 'smooth',
      })
    }
  }
  const scrollActiveSubnavIntoView = () => {
    currentActiveSubNav.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }

  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
    return () => {
      window.removeEventListener("resize", handleResize, false)
    }
  }, []);
  React.useEffect(() => {
    if (subnavOverflowing && subNavItems && subNavItems.length > 0) {
      scrollActiveSubnavIntoView();
    }
  }, [location.pathname, subNavItems, currentActiveSubNav.current])
  React.useEffect(() => {
    const overflowing = subnavRef.current ? isOverflowing(subnavRef.current) : false;
    setSubnavOverflowing(overflowing);
    if (overflowing) {
      scrollActiveSubnavIntoView();
    }
  }, [subnavRef.current, subnavRef.current?.scrollWidth, subnavRef.current?.clientWidth, subnavRef.current?.scrollHeight, subnavRef.current?.clientHeight, dimensions.width])

  return (
    <header data-testid="section-desktop-header" className="sticky top-0 z-30 hidden w-full bg-white shadow-md sm:block">
      <div className="grid grid-cols-1 md:grid-cols-[1fr_minmax(400px,2000px)] sm:gap-4 md:gap-2 xl:gap-10">
        {leftContent}
        <div className="flex flex-col justify-between">
          <div className="flex items-start h-[54px] bg-gradient-to-r from-white/0 via-info/50 to-info/80 sm:px-1 lg:pr-[40px] xl:pr-[150px]">
            {topNavContent}
          </div>
          {
            subNavItems && (
              <div data-testid="section-tab-navigation" className='flex md:px-4 lg:pr-[40px] xl:pr-[150px] overflow-hidden'>
                {
                  subnavOverflowing && (
                    <Button
                      name="scroll-left"
                      className={cn('self-end h-[56px] transition-opacity opacity-100 rounded-none rounded-tl-md rounded-tr-md px-2 shadow-right', {
                        'opacity-0 pointer-events-none': isStartOfNavVisible,
                        'hidden': !subnavOverflowing,
                      })}
                      variant="ghost"
                      onClick={scrollLeft}
                    >
                      <MdChevronLeft className='text-primary w-[36px] h-[36px] pt-2' />
                    </Button>
                  )
                }

                <div ref={subnavRef} className={cn("flex relative items-end max-w-[1140px] mt-3 overflow-x-auto h-[64px] no-scrollbar", {
                })}>
                  <div ref={startOfSubnavRef} />
                  {
                    subNavItems.map((item) => (
                      <SubNavButton ref={currentActiveSubNav} key={item.to} {...item} />
                    ))
                  }

                  <div ref={endOfSubnavRef} className='after:content-[" "]' />
                </div>
                {
                  subnavOverflowing && (
                    <Button
                      name="scroll-right"
                      className={cn('self-end h-[56px] transition-opacity opacity-100 rounded-none rounded-tl-md rounded-tr-md px-2 shadow-left', {
                        'opacity-0 pointer-events-none': isEndOfNavVisible,
                        'hidden': !subnavOverflowing,
                      })}
                      variant="ghost"
                      onClick={scrollRight}
                    >
                      <MdChevronRight className='text-primary w-[36px] h-[36px] pt-2' />
                    </Button>
                  )
                }
              </div>
            )
          }
        </div>
      </div>
    </header>
  )
}

export default DesktopHeader