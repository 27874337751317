import React from 'react';
import ContentDialog from 'components/dialog/ContentDialog';
import InputField from 'components/form/InputField';
import api from 'networking/api';
import { FamilyDetails } from 'networking/models/family';
import { useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import ComboBoxField from 'components/form/ComboBoxField';
import _ from 'lodash';
import { useNavigate } from 'react-router-dom';
import { Button } from 'components/base/button';
import { useToast } from 'components/base/use-toast';

type FieldType = 'name' | 'allergy' | 'school'
type Props = {
  open: boolean;
  onClose: () => void;
  studentId?: string | 'NEW';
  studentSchoolId?: string; // Pass this value when editing an EXISTING school
  editableFields: FieldType[];
  viewStudentOnCreate?: boolean;
}

type FormData = {
  firstName: string;
  lastName: string;
  allergy: string;
  school: string;
  room?: string;
}

/**
 * This component is used to add/edit student
 */
const EditStudentDialog = (props: Props) => {
  const {
    open,
    onClose,
    studentId,
    studentSchoolId,
    editableFields,
    viewStudentOnCreate,
  } = props;
  const navigate = useNavigate();
  const { toast } = useToast();
  const [showDeleteSchoolDialog, setShowDeleteSchoolDialog] = React.useState(false);
  const { control, handleSubmit, reset, watch, setValue } = useForm<FormData>();

  const { mutate: mutateFamily } = api.family.useFamily();
  const { data, isLoading, mutate } = api.family.useMyDetails();
  const { data: schoolData, isLoading: isLoadingSchools } = api.school.useSchools();

  const currentSchool = watch('school');
  const currentRoom = watch('room');
  const { data: roomData, isLoading: isLoadingRooms } = api.family.useFamilyRooms(currentSchool);

  const studentSchools = data?.students?.filter(student => student.student_id === studentId) || []
  const student = studentSchools[0];
  const studentSchool = data?.students.find((student) => student.student_id === studentId && student.student_school_id === studentSchoolId);
  const schoolExists = schoolData?.find((s) => s.school_id === studentSchool?.school);
  const { trigger, error, isMutating } = useSWRMutation('/family/save-details', (key, { arg }: { arg: { family: FamilyDetails } }) => {
    try {
      return api.family.saveFamilyDetails({
        family: arg.family,
        removeHomePageOption: false //true only for the accounts that are created by txt msg but we don't create account by txt anymore
      }).then(async (response) => {
        if (response.ok) {
          await mutateFamily();
          const newData = await mutate();
          if (viewStudentOnCreate) {
            const responseData = await response.json();
            const studentDiff = _.xorBy(responseData.students, newData?.students, 'student_id');
            if (studentDiff.length > 0) {
              // New student was added, navigate to it
              navigate(`/app/my-details/student/${studentDiff[0].student_id}`);
            }
          }
          onClose();
          reset();
          setShowDeleteSchoolDialog(false);
          return true;
        } else {
          throw new Error('Something went wrong, please try again');
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })
  const onSave = async (values: FormData) => {
    const allStudentSchools = data?.students || [];
    const family: FamilyDetails = {
      // When saving, we must pass all students schools otherwise they will be deleted...
      students: [
        ...allStudentSchools
          .sort((a, b) => a.student_id.localeCompare(b.student_id))
          .map((student) => ({
            // These values don't change but we need to pass them
            address: student.address,
            student_school_id: student.student_school_id,
            student_id: student.student_id,
            member_id: student.member_id,
            member_type: student.member_type || 0,

            // These values below can be changed but only for the one we are editing
            ...(studentId === student.student_id ? {
              // Updating existing student
              first_name: values.firstName.trim(),
              last_name: values.lastName.trim(),
              allergy: values.allergy.trim(),
            } : {
              allergy: student.allergy,
              first_name: student.first_name,
              last_name: student.last_name,
            }),
            ...(studentSchoolId === student.student_school_id ? {
              // Updating existing school
              school: values.school,
              room: values.room,
            } : {
              school: student.school,
              room: student.room,
            }),
          })),
        ...(!studentSchoolId && editableFields.includes('school') ? [
          // Adding new school
          {
            address: '',
            first_name: values.firstName,
            last_name: values.lastName,
            allergy: values.allergy,
            school: values.school,
            room: values.room,
            member_type: 0,
            member_id: '',
          }
        ] : []),
      ],
    }
    const result = await trigger({ family });
    if (result) {
      if (studentId !== 'NEW') {
        toast({
          title: 'Successfully updated member',
        });
      } else {
        toast({
          title: 'Successfully added member',
        })
      }
    }
  }

  /**
   * Handle delete of membership to school/organisation
   */
  const onDeleteMembership = async (values: FormData) => {
    const allStudentSchools = data?.students || [];
    if (!studentSchoolId) {
      return;
    };
    const family: FamilyDetails = {
      // When saving, we must pass all students schools otherwise they will be deleted...
      students: [
        ...allStudentSchools
          .filter((s) => s.student_school_id !== studentSchoolId)
      ]
    }
    const result = await trigger({ family });
    if (result) {
      if (studentSchools.length > 1) {
        toast({
          title: 'Successfully deleted membership',
        });
      } else {
        toast({
          title: 'Successfully deleted member',
        });
      }
    }
  }
  const getTitle = () => {
    if (studentId === 'NEW') {
      return 'Add member (e.g. student)'
    }
    if (editableFields.includes('name')) {
      return 'Edit name'
    }
    if (editableFields.includes('allergy')) {
      return 'Edit allergies'
    }
    if (editableFields.includes('school')) {
      return studentSchoolId ? 'Edit membership' : 'Add membership'
    }
    return 'Edit member'
  }

  React.useEffect(() => {
    if (!isLoading && open) {
      reset({
        firstName: student?.first_name || '',
        lastName: student?.last_name || '',
        allergy: student?.allergy || '',
        school: studentSchool?.school,
        room: studentSchool?.room,
      });
    }
  }, [open, studentId, studentSchoolId, isLoading]);

  React.useEffect(() => {
    // If studentId is not found, redirect to my-details page
    if (studentId && !student) {
      navigate('/app/my-details', {
        replace: true,
      });
    }
  }, [student, studentId])

  React.useEffect(() => {
    // When school changes, reset room if it's not in the new school
    if (currentSchool && roomData && !isLoadingRooms && roomData.findIndex((r) => r === currentRoom) === -1) {
      setValue('room', '');
    }
  }, [currentSchool, setValue, roomData, isLoadingRooms, currentRoom])
  return (
    <div>
      <ContentDialog
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        open={open}
        title={getTitle()}
        content={(
          <div className='flex flex-col gap-4'>
            {
              editableFields.includes('name') && (
                <>
                  <InputField
                    control={control}
                    label='First name'
                    placeholder='Enter first name'
                    name='firstName'
                    rules={{
                      required: 'First name is required',
                    }}
                  />
                  <InputField
                    control={control}
                    label='Last name'
                    placeholder='Enter last name'
                    name='lastName'
                    rules={{
                      required: 'Last name is required',
                    }}
                  />
                </>
              )
            }
            {
              editableFields.includes('allergy') && (
                <InputField
                  control={control}
                  label='Allergies'
                  placeholder='Enter allergies'
                  name='allergy'
                />
              )
            }
            {
              editableFields.includes('school') && (
                <div className='flex flex-col gap-4'>
                  <ComboBoxField
                    menuPosition='fixed'
                    menuPlacement='bottom'
                    control={control}
                    label='School or organisation'
                    placeholder='Select school'
                    isLoading={isLoadingSchools}
                    name={`school`}
                    rules={{
                      required: 'School or organisation is required',
                    }}
                    options={([
                      ...(schoolData || []),
                      ...(schoolExists ? [] : [{ school_id: studentSchool?.school, school_name: studentSchool?.school }]),
                    ])
                      // filter out schools that are already assigned to the student
                      .filter((s) => s.school_id === studentSchool?.school ? true : studentSchools.findIndex((ss) => ss.school === s.school_id) === -1)
                      .map((s) => ({
                        label: s.school_id,
                        value: s.school_id
                      })) || []}
                  />
                  <ComboBoxField
                    menuPosition='fixed'
                    menuPlacement='bottom'
                    isLoading={isLoadingRooms}
                    noOptionsMessage={() => currentSchool ? 'No locations found' : 'Select a school or organisation first'}
                    control={control}
                    label='Location'
                    placeholder='Select location'
                    name={`room`}
                    options={roomData?.map((s) => ({
                      label: s,
                      value: s
                    })) || []}
                  />
                  {
                    studentId === 'NEW' && (
                      <p className='text-xs text-text-helper'>
                        You can add more schools and organisations after creating this member.
                      </p>
                    )
                  }
                </div>
              )
            }

            {
              editableFields.includes('school') && studentSchoolId && !studentSchool?.external_id && studentSchools.length > 1 && (
                <div>
                  <Button
                    variant="linkUnderline"
                    className='w-auto px-0 text-destructive'
                    type="button"
                    onClick={() => {
                      setShowDeleteSchoolDialog(true);
                    }}
                  >
                    Delete membership
                  </Button>
                </div>
              )
            }

            {
              error && (
                <p className='text-sm text-destructive'>
                  {error.message}
                </p>
              )
            }
          </div>

        )}
        primaryActionLabel='Save'
        primaryActionProps={{
          type: 'submit',
          onClick: handleSubmit(onSave),
          disabled: isLoading || isMutating,
        }}
      />

      <ContentDialog
        open={showDeleteSchoolDialog}
        onOpenChange={(open) => {
          setShowDeleteSchoolDialog(false);
        }}
        title={`You are about to delete ${student?.first_name}'s membership in "${studentSchool?.school}"`}
        content={(
          <div className='text-sm text-text-body'>
            <p>
              You will no longer be able to make payments to that school or organisation or buy items from their shop. Records of payments related to this school or organisation will still be accessible.
            </p>
            {
              studentSchools.length === 1 && (
                <>
                  <br />
                  <p>
                    Removing this membership will also delete the member. If you want to keep the member, please add them to another school or organisation before deleting this membership.
                  </p>
                </>
              )
            }
          </div>
        )}
        secondaryActionLabel="Don't delete"
        primaryActionLabel="Delete membership"
        primaryActionProps={{
          type: 'button',
          variant: 'destructive',
          onClick: handleSubmit(onDeleteMembership),
          disabled: isLoading || isMutating,
        }}
      />
    </div>
  )
}

export default EditStudentDialog