import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useForm } from 'react-hook-form';
import InputField from '../../common_component/form/InputField';
import api from 'networking/api';
import { formatCentsPriceForDisplay, tryConvertDollarStringToCents } from '../../util/string_util';
import TopUpInfo from './TopUpInfo';
import { Button } from '../../common_component/base/button';
import { useStore } from '../../store/store';
import OnlineEftposLogo from '../../assets/images/online-eftpos-logo.png';
import analytics from '../../util/googleAnalytics';
import { Helmet } from 'react-helmet';
import EftposBankTypeLogos from '../../assets/images/oe-bank-logos.png';
import KindoWell from '../../common_component/KindoWell'
import useRemoteConfig from 'util/hooks/useRemoteConfig';

type Props = {
  action: 'topup' | 'pay';
  totalPrice?: number;
  orderIds?: string[];
  onPurchase?: () => void;
  onError?: (error: any) => void;
}

type FormData = {
  amount: string;
}

const OnlineEftposForm = (props: Props) => {
  const store = useStore();
  const { action, totalPrice = 0, orderIds, onPurchase, onError } = props;
  const navigate = useNavigate();
  const { getRemoteConfigValue } = useRemoteConfig();
  const TOPUP_AMOUNT_LIMIT = getRemoteConfigValue('online_eftpos_topup_amount_limit_in_cents')?.asNumber() || 25000;
  const { control, setValue, handleSubmit } = useForm<FormData>({
    defaultValues: {
      ...(action === 'pay' ? {
        amount: formatCentsPriceForDisplay(totalPrice, { dollarSign: false })
      } : {}),
    }
  });
  const { data: motdData } = api.partner.useMessageOfTheDay('KINDO_ALL', 'eftpos');

  return (
    <div>
      <div>
        <Helmet>
          {/* Preload the plugin ahead of time */}
          <script
            src={process.env.DEPLOY_ENV === 'production' ? 'https://clickcustompay.paymark.co.nz/paymarkPlugin.js' : 'https://clickcustompay.uat.paymark.nz/paymarkPlugin.js'}
          />
        </Helmet>
        <form
          onSubmit={handleSubmit((data) => {
            const amountInCents = tryConvertDollarStringToCents(data.amount) ?? 0;
            let requestPayload = {
              'amount_in_cents': Math.round(amountInCents),
              'internet_banking_opt': false,
              'options': {
                save_card: "dont_save_card"
              },
              'purchase_detail': {
                orders_too_late_fox_bx: [],
                saved_order_ids: orderIds,
                voucher_token: null,
              },
              'topup_method': "online_eftpos"
            }
            api.family.topup(requestPayload, !!orderIds && orderIds.length > 0).then(async (response) => {
              const responseData = (await response.json()) || {};

              if (responseData.rtype === 'error') {
                if (onError) {
                  onError(responseData);
                } else {
                  alert(responseData.message)
                }
                return;
              }
              const { url, topup_id } = responseData;
              if (!url) {
                alert('Failed to initiate payment')
                return;
              }
              const parts = url.split('/');
              // Get the payment id at the end of the url
              const paymentId = parts[parts.length - 1];
              if (paymentId) {
                analytics.placeOrder({ priceInCents: totalPrice, initiatedFrom: 'Online Eftpos' })
                if (onPurchase) {
                  onPurchase();
                }
                store.shop.setTopupAmount(amountInCents);
                navigate(`/app/online-eftpos/${topup_id}/${paymentId}`);
              } else {
                alert('Could not get payment id')
              }
            });
          })}
        >
          {
            motdData?.motds && motdData?.motds.length > 0 && motdData?.motds.sort((a, b) => a.priority - b.priority).map((motd) => (
              <KindoWell
                variant={motd.info_type}
                key={`${motd.info_type + '_' + motd.priority}`}
                className='mb-2'
                messages={[motd.text]}
              />
            ))
          }
          <div className='flex flex-col gap-6'>
            <div className='grid grid-cols-1 gap-10 md:grid-cols-2 space-between'>
              <div className='flex flex-col gap-1 md:max-w-[280px]'>
                <InputField
                  label={action === 'pay' ? 'Amount' : 'Top up amount'}
                  required
                  control={control}
                  name="amount"
                  rules={{
                    pattern: {
                      value: /^[0-9]+(\.[0-9]+)?$/,
                      message: 'Please enter a valid amount',
                    },
                    min: {
                      value: formatCentsPriceForDisplay(totalPrice, { dollarSign: false }) || '0',
                      message: `Minimum amount of ${formatCentsPriceForDisplay(totalPrice)} is required`,
                    },
                    max: {
                      value: formatCentsPriceForDisplay(TOPUP_AMOUNT_LIMIT, { dollarSign: false }),
                      message: `Online Eftpos is only available for top ups under ${formatCentsPriceForDisplay(TOPUP_AMOUNT_LIMIT)}. Please use alternative method.`,
                    },
                  }}
                  disabled={action === 'pay'}
                />
                {(totalPrice > 0 && action === 'topup') &&
                  <p
                    data-testid='button-topup-full-amount'
                    className='text-sm cursor-pointer hover:underline text-secondary'
                    onClick={() => setValue('amount', formatCentsPriceForDisplay(totalPrice, { dollarSign: false }))}
                  >
                    Top up full amount {formatCentsPriceForDisplay(totalPrice)}
                  </p>
                }
              </div>
              {
                action === 'topup' && (
                  <TopUpInfo />
                )
              }
            </div>

            <div className='flex flex-col justify-between gap-4 sm:flex-row'>
              <div className='flex flex-row flex-wrap items-center gap-4'>
                <img src={OnlineEftposLogo} alt="" className='h-8' />
                <img src={EftposBankTypeLogos} alt="Eftpos bank logos" className='h-4' />
              </div>
              <div>
                <Button size='sm' className='font-museo' variant="default">
                  {action === 'pay' ? 'Complete payment' : 'Complete top up'}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default OnlineEftposForm