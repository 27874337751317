import { useEffect, useState } from 'react'
import { TaintedPattern } from 'util/tainted_regexp';
/**
 * Hook to determine if a password meets the requirements
 */
export const usePasswordChecker = function (password: string = '', minLength: number = 8) {
  const [satisfiesMinLength, setSatisfiesMinLength] = useState(false);
  const [satisfiesNumbers, setSatisfiesNumbers] = useState(false);
  const [satisfiesLowercase, setSatisfiesLowercase] = useState(false);
  const [satisfiesUppercase, setSatisfiesUppercase] = useState(false);
  const [satisfiesSpecialCharacters, setSatisfiesSpecialCharacters] = useState(false);
  const [satisfiesAllRequirements, setSatisfiesAllRequirements] = useState(false);

  useEffect(() => {
    setSatisfiesMinLength(password.length >= minLength);
    setSatisfiesNumbers(!!password.match(TaintedPattern.numbersRegex));
    setSatisfiesLowercase(!!password.match(TaintedPattern.lowercaseRegex));
    setSatisfiesUppercase(!!password.match(TaintedPattern.uppercaseRegex));
    setSatisfiesSpecialCharacters(!!password.match(TaintedPattern.specialCharactersRegex));

    setSatisfiesAllRequirements(satisfiesMinLength && satisfiesNumbers && satisfiesLowercase && satisfiesUppercase && satisfiesSpecialCharacters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [password]);

  return {
    satisfiesMinLength,
    satisfiesNumbers,
    satisfiesLowercase,
    satisfiesUppercase,
    satisfiesSpecialCharacters,
    satisfiesAllRequirements,
  };
}