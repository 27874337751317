import React from 'react'
import { MdInfoOutline } from 'react-icons/md'
import { useStore } from '../../store/store';

type Props = {
  showBankTransferWarning?: boolean;
};

const TopUpInfo = ({ showBankTransferWarning }: Props) => {
  const store = useStore();

  return (
    <>
      {
        !store.shop.isCommunityShop &&
        (
          <div>
            <div className='flex w-full p-4 rounded-lg gap-x-4 bg-info'>
              <MdInfoOutline className='hidden text-lg sm:block' />
              <div className='flex flex-col flex-1 gap-y-2 text-card-foreground'>
                <p>
                  Unused funds will remain in your kindo wallet for future use.
                </p>
                <div>
                  <p className='underline cursor-pointer text-primary'>
                    <a href='https://support.mykindo.co.nz/portal/en/kb/articles/account-balance-withdrawals' target='_blank' rel="noreferrer">What happens to my unspent balance if I close my account?</a>
                  </p>
                </div>

                {(showBankTransferWarning) &&
                  <>
                    <p className='font-semibold'>Important. Your 6-digit reference number must be present to enable us to top up your account.</p>
                    <p><span className='font-bold'>Please allow up to 2 working days for funds to show in your wallet.</span> You will receive a topup receipt when funds are received.</p>
                    <p>You will not be able to complete any purchases until your wallet has sufficient funds to make the purchase. If you would like the purchase to be automatically placed on receipt of funds, please follow instructions from the checkout and select "Bank Transfer (at your bank)".</p>
                  </>
                }
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}

export default TopUpInfo