import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from "../../../common_component/base/button";
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';

type Props = {
  purchaseId: string;
  familyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const SendConfirmationDialog = ({ purchaseId, familyId, open, onOpenChange }: Props) => {
  const { toast } = useToast();
  const [error, setError] = React.useState('');

  const { trigger, isMutating, error: sendError } = useSWRMutation('/customer/send-confirmation', (key, { arg }: { arg: {} }) => {
    try {
      return api.customer.sendConfirmation({
        targetFamilyId: familyId,
        purchaseId: purchaseId
      }).then(async (response) => {
        if (response.ok) {
          try {
            const res = await response.json();
            if (res.email) {
              onOpenChange(false)
              toast({
                title: 'Email has been sent!',
                duration: 3000,
              })
            } else {
              setError('Something went wrong. Please try again.');
            }
          } catch (e) {
            setError('Something went wrong. Please try again.');
          }
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })

  React.useEffect(() => {
    if (sendError) {
      setError(sendError);
    }
  }, [sendError]);

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Send confirmation</DialogTitle>
        </DialogHeader>
        <p className="text-card-foreground">Are you sure to send the confirmation email to the customer?</p>
        {error &&
          <p className="pt-4 text-destructive">{error}</p>
        }
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>No</Button>
          <Button type="button" disabled={isMutating} onClick={trigger}>Yes</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default SendConfirmationDialog