import React, { useState } from 'react';

type CopyToClipboardProps = {
  text: string;
  children: (props: { copyToClipboard: () => void; isCopied: boolean }) => React.ReactNode;
};

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text, children }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text);
      setIsCopied(true);
      // Reset isCopied after 2 seconds
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy text:', error);
    }
  };

  return <>{children({ copyToClipboard, isCopied })}</>;
};

export { CopyToClipboard };
