import useSWR from 'swr';
import { baseApi } from './constants';
import { Family } from './models/family';
import { KindoNetworkError } from './models/common';
import { AddStudentToGroupPreview, AllBindRulesResponse, BindPayableRef, BindPayableResponse, Caregiver, DeferredTaskResponse, GroupResponse, GroupStudentsResponse, MetaGroupResponse, PayableCategoriesResponse, PayableMaintainablesResponse, PayablesResponse, ProtoPayablesResponse, RollGroupStudent, RollMap, SchoolAdminCapabilitiesResponse, SchoolAdminConfigResponse, SearchResponse, StudentSearchResponse, TaskFailed } from './models/admin';
import { DeferableResponse } from 'networking/models/common';
import fetcher from './util/fetcher';

const admin = {
  // Gets list of schools for admin pages (to show correct schools inschool switcher)
  useFamily: (dataParams?: any) => {
    const params = new URLSearchParams({
      get_students: "false",
      school_services: "true",
      saved_order_count: "false",
      system_status: "false",
      school_ids: "true",
      supplier_menu: "false",
      supplier_order: "false",
    }).toString();
    const data = useSWR<Family, KindoNetworkError>(`${baseApi}/FAMILY?${dataParams ? new URLSearchParams(dataParams).toString() : params}`, {
      revalidateIfStale: false,
      shouldRetryOnError: true,
      errorRetryCount: 3,
      errorRetryInterval: 15000,
      revalidateOnFocus: false,
    });
    return data;
  },
  // Gets all payables for a school
  usePayables: (schoolId?: string, params?: any) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
      ...params,
    }).toString();

    const data = useSWR<PayablesResponse, KindoNetworkError>(schoolId ? `${baseApi}/roll/${schoolId}/payables?${queryString}` : undefined, {
      revalidateIfStale: false,
      shouldRetryOnError: true,
      errorRetryCount: 3,
      errorRetryInterval: 15000,
      revalidateOnFocus: false,
    });
    return data;
  },
  useProtoPayables: (schoolId?: string, params?: {
    allProtos?: boolean;
  }) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
      ...(params?.allProtos && { all_protos: 'true' }),
    }).toString();

    const data = useSWR<ProtoPayablesResponse, KindoNetworkError>(schoolId ? `${baseApi}/roll/payable/prototype?${queryString}` : undefined, {
      revalidateIfStale: true,
      revalidateOnFocus: true,
    });
    return data;
  },
  usePayableCategories: (schoolId?: string) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
    }).toString();

    const data = useSWR<PayableCategoriesResponse, KindoNetworkError>(schoolId ? `${baseApi}/roll/payable/pcats?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
    return data;
  },
  useGroups: (schoolId?: string, smsGroups?: boolean) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
      sms: smsGroups ? 'true' : 'false',
      custom: smsGroups ? 'false' : 'true',
    }).toString();

    const data = useSWR<GroupResponse, KindoNetworkError>(schoolId ? `${baseApi}/roll/groups/adhoc?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
    return data;
  },

  useMetaGroups: (schoolId?: string, metagroup?: 'room' | 'year_level' | 'suba') => {
    const queryString = new URLSearchParams({
      schema: 'kp',
      school_id: schoolId ?? '',
      // metagroup: metagroup || '',
    }).toString();

    const data = useSWR<MetaGroupResponse, KindoNetworkError>(schoolId && metagroup ? `${baseApi}/roll/metagroup/${metagroup}?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
    return data;
  },

  useStudentsInGroups: (schoolId?: string, groupId?: string) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
    }).toString();

    const data = useSWR<GroupStudentsResponse, KindoNetworkError>(schoolId && groupId ? `${baseApi}/roll/groups/adhoc/${groupId}/students?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
    return data;
  },
  useStudentGroupAddPreview: ({ schoolId, groupId, studentIdExt }: { schoolId: string, groupId: string, studentIdExt?: string }) => {
    return useSWR<AddStudentToGroupPreview, KindoNetworkError>(studentIdExt && [`${baseApi}/roll/groups/${groupId}/members`, studentIdExt], async ([url, studentIdExt]) => {
      const response = await fetcher(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          school_id: schoolId ?? '',
          is_delete: false,
          rtype: "adhocgroup_member",
          student_id_ext: studentIdExt ?? '',
          add_to_group_policy: "add_apply_preview",
        }),
      });
      if (response.ok) {
        return response.json();
      } else {
        const error = await response.json() as KindoNetworkError;
        throw error;
      }
    })
  },
  useStudentRollMap: (schoolId?: string) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
      left_student_policy: 'remove',
    }).toString();

    return useSWR<RollMap, KindoNetworkError>(schoolId ? `${baseApi}/roll/map?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    })
  },

  useStudentSearch: (schoolId?: string, searchQuery?: string) => {
    return useSWR<SearchResponse<RollGroupStudent>, KindoNetworkError>(schoolId && searchQuery && [`${baseApi}/roll/student/search`, schoolId, searchQuery], async ([url, schoolId, searchQuery]) => {
      const response = await fetcher(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          school_id: schoolId ?? '',
          rtype: "roll_search_request",
          search_text: searchQuery,
        }),
      });
      if (response.ok) {
        return response.json();
      } else {
        const error = await response.json() as KindoNetworkError;
        throw error;
      }
    }, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    })
  },
  useCaregiverSearchByStudentIdExt: (schoolId?: string, studentIdExt?: string) => {
    return useSWR<SearchResponse<Caregiver>, KindoNetworkError>(schoolId && studentIdExt && [`${baseApi}/roll/caregiver/search`, schoolId, studentIdExt], async ([url, schoolId, studentIdExt]) => {
      const response = await fetcher(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          school_id: schoolId ?? '',
          rtype: "roll_caregiver_search_request",
          search_text: `student_id ${studentIdExt}`,
        }),
      });
      if (response.ok) {
        return response.json();
      } else {
        const error = await response.json() as KindoNetworkError;
        throw error;
      }
    }, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    })
  },

  useMaintainables: (schoolId?: string) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
      defer: 'MAY_DEFER',
    }).toString();

    return useSWR<DeferableResponse | PayableMaintainablesResponse | DeferredTaskResponse<PayableMaintainablesResponse>, KindoNetworkError>(schoolId ? `${baseApi}/roll/payable/maintenance/maintainables?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    })
  },
  useDeferredTask: <T>(taskId?: string, refreshInterval = 5000) => {
    return useSWR<DeferredTaskResponse<T | TaskFailed>, KindoNetworkError>(taskId ? `${baseApi}/task/${taskId}` : undefined, {
      refreshInterval,
    })
  },

  useKpConfig: (schoolId?: string) => {
    const queryString = new URLSearchParams({
      schools: schoolId ?? '',
      realms: 'kp',
    }).toString();

    return useSWR<SchoolAdminConfigResponse, KindoNetworkError>(schoolId ? `${baseApi}/school_admin/conf?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    })
  },

  useCapabilitiesConfig: (schoolId?: string) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
    }).toString();

    return useSWR<SchoolAdminCapabilitiesResponse, KindoNetworkError>(schoolId ? `${baseApi}/school_admin/caps?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    })
  },

  useAllBindRules: (schoolId?: string) => {
    const queryString = new URLSearchParams({
      school_id: schoolId ?? '',
    }).toString();

    return useSWR<AllBindRulesResponse, KindoNetworkError>(schoolId ? `${baseApi}/roll/binds?${queryString}` : undefined, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
  },

  // Mutations
  addStudentToGroup: ({ schoolId, groupId, studentIdExt }: { schoolId: string, groupId: string, studentIdExt: string }) => {
    return fetcher(`${baseApi}/roll/groups/${groupId}/members`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        is_delete: false,
        rtype: "adhocgroup_member",
        student_id_ext: studentIdExt ?? '',
        add_to_group_policy: "add_apply_commit",
      }),
    });
  },

  removeStudentFromGroup: ({ schoolId, groupId, studentIdExt }: { schoolId: string, groupId: string, studentIdExt: string }) => {
    return fetcher(`${baseApi}/roll/groups/${groupId}/members`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        is_delete: true,
        rtype: "adhocgroup_member",
        student_id_ext: studentIdExt ?? '',
        remove_from_group_policy: "add_apply_commit",
      }),
    });
  },

  createGroup: ({ schoolId, groupName }: { schoolId: string, groupName: string }) => {
    return fetcher(`${baseApi}/roll/groups`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        adhocgroup_id: groupName,
        rtype: "adhocgroup",
      }),
    });
  },

  // Apply payable to student/room/group/school
  bindPayable: async (
    { schoolId, protoPayableId, groupIdentifier, metagroup, preview, enableAfter, comment, altStartAmount, feeType, defer }:
      { schoolId: string, protoPayableId: string; metagroup: string; groupIdentifier: string; preview?: boolean; enableAfter?: string; comment?: string; altStartAmount?: number; feeType?: string; defer?: string; }): Promise<BindPayableResponse> => {
    const response = await fetcher(`${baseApi}/roll/payable/bind`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        rtype: 'bind_payable',
        proto_payable_id: protoPayableId,
        metagroup,
        group_identifier: groupIdentifier,
        policy: preview ? 'add_apply_preview' : 'add_apply_commit',
        ...(feeType ? { fee_type: feeType } : {}),
        ...(enableAfter ? { enable_after: enableAfter } : {}),
        ...(comment ? { comment } : {}),
        ...(altStartAmount ? { start_amount: altStartAmount } : {}),
        defer: defer ?? 'MAY_DEFER', // Can be: NO_DEFER, MAY_DEFER, MUST_DEFER, TEST_DEFER. API DEFAULT: MAY_DEFER
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return { data } as BindPayableResponse;
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error } as BindPayableResponse;
    }
  },
  bindPayableToStudent: async (
    { schoolId, protoPayableId, studentId, preview, enableAfter, comment, altStartAmount, feeType, caregiverId, altName, allowWhenLeft: allowWhenLeft }:
      {
        schoolId: string,
        protoPayableId: string;
        studentId: string;
        preview?: boolean;
        enableAfter?: string;
        comment?: string;
        altStartAmount?: number;
        feeType?: string;
        caregiverId?: string;
        altName?: string;
        allowWhenLeft?: boolean;
      }
  ):
    Promise<BindPayableResponse> => {

    const response = await fetcher(`${baseApi}/roll/payable/student`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        rtype: 'student_payable',
        proto_payable_id: protoPayableId,
        student_id: studentId,
        policy: preview ? 'add_apply_preview' : 'add_apply_commit',
        ...(feeType ? { fee_type: feeType } : {}),
        ...(enableAfter ? { enable_after: enableAfter } : {}),
        ...(comment ? { comment } : {}),
        ...(altStartAmount ? { start_amount_in_cents: altStartAmount } : {}),
        ...(caregiverId ? { tagged_caregiver_id: caregiverId } : {}),
        ...(altName ? { alt_name: altName } : {}),
        ...(allowWhenLeft ? { allow_when_left: allowWhenLeft } : {}),
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return { data } as BindPayableResponse;
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error } as BindPayableResponse;
    }
  },

  createPayableCategory: ({ schoolId, categoryName }: { schoolId: string, categoryName: string }) => {
    return fetcher(`${baseApi}/roll/payable/pcats`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        pcat: categoryName,
        rtype: "pcat",
      }),
    });
  },

  deletePayableCategory: ({ schoolId, categoryName }: { schoolId: string, categoryName: string }) => {
    return fetcher(`${baseApi}/roll/payable/pcats`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        pcat: categoryName,
        rtype: "pcat",
        to_pcat: null, // This will delete it
      }),
    });
  },

  updatePayableCategory: ({ schoolId, categoryName, newCategoryName }: { schoolId: string, categoryName: string, newCategoryName: string; }) => {
    return fetcher(`${baseApi}/roll/payable/pcats`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        pcat: categoryName,
        rtype: "pcat",
        to_pcat: newCategoryName,
      }),
    });
  },
  updateProtoPayable: ({ schoolId, protoPayableId, pcat, maxInstances }:
    { schoolId: string; protoPayableId: string; pcat: string; maxInstances: number | null; }) => {
    return fetcher(`${baseApi}/roll/payable/prototype`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        pcat,
        max_instances: maxInstances,
        rtype: "proto_payable",
        proto_payable_id: protoPayableId,
      }),
    });
  },

  removeProtoPayable: ({ schoolId, protoPayableId }:
    { schoolId: string; protoPayableId: string; }) => {
    return fetcher(`${baseApi}/roll/payable/prototype`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        proto_payable_id: protoPayableId,
        rtype: "proto_payable",
        allow_apply: false,
      }),
    });
  },

  updateGroupStatus: ({ schoolId, groupId, status }:
    { schoolId: string; groupId: string; status: 'hidden' | 'enabled'; }) => {
    return fetcher(`${baseApi}/roll/groups/group`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        group_ids: [groupId],
        rtype: "roll_group",
        status,
      }),
    });
  },
  createGroupBulkImport: async ({ schoolId, groupId, preview, rows, cols }:
    {
      schoolId: string;
      groupId: string;
      preview?: boolean;
      rows: string[][];
      cols: string[];
    }) => {
    const response = await fetcher(`${baseApi}/roll/groups/populated`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        school_id: schoolId ?? '',
        group_id: groupId,
        rtype: "roll_group",
        preview: preview,
        member_data: {
          rtype: 'tabledef',
          cols,
          rows,
        }
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data as {
        rtype: 'roll_group_ref';
        preview: boolean;
        transcript: string[];
        group_id: string;
        school_id: string;
      };
    } else {
      const error = await response.json() as {
        rtype: 'error';
        message: string;
        name: string;
      };
      return error;
    }
  },
  setAdminHomePage: ({ url }:
    { url: string; }) => {
    return fetcher(`${baseApi}/school_admin/set_home`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'school_admin',
        relative_url: url,
      }),
    });
  },
  getPartnerSession: async () => {
    const response = await fetcher(`${baseApi}/school_admin/partner_session`,);
    if (response.ok) {
      const data = await response.json() as {
        rtype: string;
        session_id: string;
      };
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },
  setDefaultSchool: ({ schoolId }:
    { schoolId: string; }) => {
    return fetcher(`${baseApi}/school_admin/set_default_school`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'school_admin',
        default_school_id: schoolId,
      }),
    });
  },

  refreshPayables: async ({ schoolId, preview }: { schoolId: string; preview?: boolean; }) => {
    const response = await fetcher(`${baseApi}/roll/payable/prototype/refresh`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'payable_prototype_refresh',
        school_id: schoolId,
        create_from_products: true,
        preview,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        rtype: 'payable_prototype_refresh_result';
        refresh_errors: {
          message: string;
        }[];
        preview: boolean;
      };
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },

  /**
   * Apply maintenance actions to payables
   * @preview - if true, will not commit changes
   * @reapplyBindIds - list of bind_ids to apply to students
   * @clearBindIds - list of bind_ids to remove from students 
   */
  applyMaintenanceActions: async ({
    schoolId,
    preview,
    reapplyBindIds,
    clearBindIds,
    noRemoveWithPayment,
  }: {
    schoolId: string;
    preview?: boolean;
    noRemoveWithPayment?: boolean;
    reapplyBindIds: string[]; // ADD Payables
    clearBindIds: string[]; // REMOVE Payables
  }) => {
    const response = await fetcher(`${baseApi}/roll/payable/maintenance/operations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'payable_maintenance_operations',
        school_id: schoolId,
        ...(clearBindIds.length > 0 ? {
          clear_binds: {
            clear_bind_ids: clearBindIds,
            rtype: 'clear_binds_operation',
            // If no_remove_with_payment is missing then the default is the school specific one set in conf (is currently False for all but whangaparaoa, long bay, pine hill, gulf harbour)
            no_remove_with_payment: noRemoveWithPayment,
          },
        } : {}),
        reapply_bind_ids: reapplyBindIds,
        preview,
      }),
    });
    try {
      if (response.ok) {
        const data = await response.json() as {
          rtype: 'roll_maintenance_post_result';
          clear_binds_result?: {
            cleared_count: number;
            no_remove_count: number;
          };
          committing: string; // ROLLBACK or COMMIT
          reapply_binds_result?: {
            reapplied_count: number;
            skipped_as_applied_instances_exceeded_count: number;
          };
        };
        return { data };
      } else {
        const error = await response.json() as KindoNetworkError;
        return { error };
      }
    } catch (e) {
      return { error: { message: 'An unknown error occurred' } };
    }
  },

  /**
   * Disable rules for payables given bind_ids
   * @preview - if true, will not commit changes
   * @bindIds - list of bind_ids to disable
   * @hideUnpaid - if true, will hide unpaid payables
   * @hidePartPaid - if true, will hide part paid payables
   */
  disableMaintenanceRules: async ({ schoolId, preview, bindIds, hidePartPaid, hideUnpaid, noRemoveWithPayment }:
    { schoolId: string; preview?: boolean; bindIds: string[]; hideUnpaid?: boolean; hidePartPaid?: boolean; noRemoveWithPayment?: boolean; }
  ) => {
    const response = await fetcher(`${baseApi}/roll/payable/maintenance/operations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        rtype: 'payable_maintenance_operations',
        school_id: schoolId,
        disable_bind_ids: bindIds,
        on_bind_disable_no_payment_action: hideUnpaid ? 'hide' : 'noop',
        on_bind_disable_part_payment_action: hidePartPaid ? 'hide' : 'noop',
        preview,
      }),
    });
    try {
      if (response.ok) {
        const data = await response.json() as {
          rtype: 'roll_maintenance_post_result';
          committing: string; // ROLLBACK or COMMIT
          disable_binds_result: {
            binds_disabled_count: number;
            updated_no_payment_count: number;
            updated_part_payment_count: number;
          };
        };
        return { data };
      } else {
        const error = await response.json() as KindoNetworkError;
        return { error };
      }
    } catch (e) {
      return { error: { message: 'An unknown error occurred' } };
    }
  },

  /** 
   * PAYABLE STATUS ACTIONS
   */

  /**
   * Payment requests (sending payment requests via email)
   * @maxReturnMessages Max number of messages to return for previewing, ideally set to 0 if sending ie. not previewing
   */
  sendPaymentRequests: async ({
    schoolId,
    preview,
    maxReturnMessages = 0,
    payableIds,
    customMessage = '',
    subject,
    salutations,
  }: {
    customMessage?: string;
    schoolId: string;
    preview?: boolean;
    payableIds: string[];
    maxReturnMessages?: number;
    subject: string;
    salutations: string;
  }) => {
    const response = await fetcher(`${baseApi}/roll/student_payable/comms/payment_request`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        subject,
        insert: customMessage,
        payable_ids: payableIds,
        rtype: 'payment_request_message_request',
        school_id: schoolId,
        salutations: salutations,
        message_preview_only: preview,
        max_return_messages: maxReturnMessages || 0,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        rtype: 'payment_request_status';
        success: boolean;
        msg_preview_only: boolean;
        content_is_html: boolean;
        composed_count: number;
        messages: {
          body: string;
          nominal_payable_ids: string[];
          rtype: 'roll_comms_email_msg_data',
          url: string;
          from_addr?: string;
          to_addrs: string[];
          widen_payable_ids: string[];
          subject: string;
        }[];
      };
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }

  },

  /**
   * Unapply / Exempt
   */

  /**
   * Unapply or exempt payables from students
   * @replaceWithExemptIfBind For ad hoc (per student) applied payables this is ignored.
   * 
   * For payables created from a rule (the right and proper way to create payables)..
   * 
   * -if user is trying to undo a payable application screwup then this should be false
   * 
   * -if user is trying to exempt students (eg Susie mum is coach so she doesn't have to pay netball coaching fee that is applied via rule to all in netball team A) then pass true
   */
  unapplyPayables: async ({
    schoolId,
    preview,
    payableIds,
    reason,
    comment,
    replaceWithExemptIfBind = true,

  }: {
    schoolId: string;
    preview?: boolean;
    payableIds: string[];
    reason: string;
    comment?: string;
    replaceWithExemptIfBind?: boolean;
  }) => {
    const response = await fetcher(`${baseApi}/roll/student_payable/unapplied`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        payable_ids: payableIds,
        rtype: 'unapply_request',
        school_id: schoolId,
        preview: preview,
        reason,
        comment,
        replace_with_exempt_if_bind: replaceWithExemptIfBind,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        rtype: 'unapply_result';
        success: true;
        total_unapplied: number;
        bound_ids: string[]; // This is the student payable ids that were unbound
        payable_bind_ids_observed: string[];
        unbound_ids: string[];
      } | {
        rtype: 'unapply_result';
        success: false;
        private_message: string;
        public_message: string;
      };;
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },

  /**
   * Retire
   */
  retirePayables: async ({
    schoolId,
    preview,
    payableIds,
    reason,
    comment,
  }: {
    schoolId: string;
    preview?: boolean;
    payableIds: string[];
    reason: string;
    comment: string;
  }) => {
    const response = await fetcher(`${baseApi}/roll/student_payable/retired`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        payable_ids: payableIds,
        rtype: 'student_payable_retire_request',
        school_id: schoolId,
        preview: preview,
        reason,
        comment,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        rtype: 'student_payable_retirement_result';
        success: boolean;
        affected_count: number;
        payable_history_id: string;
      };
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },

  /**
   * update payable amount
   * This nasty thing allows the amount for a payable to be forced to a new amount  -even if it came from a bind.
      It leaves both Kindo and the school open to abuses by admins (either malicous or misguided)
      There is an audit trail, but we don't actually display it or report on it anyway afaik.
      It is sometimes called a 'tweak'
      You have been warned!
   */
  updateAmountPayables: async ({
    schoolId,
    preview,
    payableIds,
    comment,
    newAmountInCents
  }: {
    schoolId: string;
    preview?: boolean;
    payableIds: string[];
    comment: string;
    newAmountInCents: number;
  }) => {
    const response = await fetcher(`${baseApi}/roll/student_payable/mutation`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        payable_ids: payableIds,
        rtype: 'student_payable_mutation_request',
        school_id: schoolId,
        preview: preview,
        comment,
        new_charged_amount_in_cents: newAmountInCents,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        rtype: 'student_payable_mutations';
        student_payable_mutations: {
          original_alt_amount_maybe?: number;
          student_payable_id: string;
          mutated_alt_amount: {
            excl_cents: number;
            gst_cents: number;
          };
          rtype: "student_payable_mutation";
          field: string;
        }[];
      };
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },


  /**
   * refund payables
   * 
    Be afraid
    This is totally UNLIKE refunds initiated 'shop side', and is very complex behind the scenes ((with a menagerie of possible failure cases!)
    Keep in mind that.
    -refunding a single student payable may involve refunding multiple (part) payments.
    -there may already be existing partial (sub-item and sub-purchase) refunds and the refunding mechanism has to cope with this.
    -it is therefore possible for some refunds to succeed and others not.
    -refunds initiated from the kp end (as these are) involve a complex dance back and forth between kp and shop because they mist be processed on both sides, but strictly speaking either side is permitted to failure (this is a quasi-network operation)
    -the liklihood of problems scales with number of payables submitted! Cosider allowing one only (esp in short term and for school users)
   */
  refundPayables: async ({
    schoolId,
    preview,
    payableIds,
    comment,
  }: {
    schoolId: string;
    preview?: boolean;
    payableIds: string[];
    comment: string;
  }) => {
    const response = await fetcher(`${baseApi}/roll/student_payable/refunding`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        payable_ids: payableIds,
        rtype: 'payable_refunds_request',
        school_id: schoolId,
        preview: preview,
        comment,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        rtype: 'payable_refunds_result';
        errors: string[];
        dry_run_only: boolean;
        unsuccessful_refunds: {
          purchase_id: string;
          receipt_id: string;
          rtype: 'unsuccessful_refund';
        }[];
        successful_refunds: {
          purchase_id: string;
          receipt_id: string;
          rtype: 'successful_refund';
        }[];
        transcript: string[]; // log of what happened
      };
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },

  /**
   * This call is used to create a group from a selection of payables.
   * @groupId the name of the group to create
   */
  groupPayables: async ({
    schoolId,
    preview,
    payableIds,
    groupId,
  }: {
    schoolId: string;
    preview?: boolean;
    payableIds: string[];
    groupId: string;
  }) => {
    const response = await fetcher(`${baseApi}/roll/group/from_student_payables`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        payable_ids: payableIds,
        rtype: 'group_from_student_payables_request',
        school_id: schoolId,
        preview: preview,
        group_id: groupId,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        rtype: 'roll_group_from_student_payables_result';
        group_members_added_count: number;
        public_message: string;
        private_message: string;
        transcript: string[]; // log of what happened
        group_id: string;
        success: boolean;
      };
      // TODO: Handle error case (can error with 200 response)
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },

  /**
   * Hide/unhide
   * @groupId the name of the group to create
   */
  updateVisibilityPayables: async ({
    schoolId,
    preview,
    payableIds,
    visible,
    comment,
  }: {
    schoolId: string;
    preview?: boolean;
    payableIds: string[];
    visible: boolean;
    comment: string;
  }) => {
    const response = await fetcher(`${baseApi}/roll/student_payable/visibility`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        student_payable_ids: payableIds,
        rtype: 'student_payable_visibility',
        school_id: schoolId,
        preview: preview,
        visible,
        comment,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        rtype: 'roll_group_from_student_payables_result';
        group_members_added_count: number;
        transcript: string[]; // log of what happened
        group_id: string;
        success: boolean;
      };
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },

  /**
   * Prepaid payable
   */
  prepaidPayables: async ({
    schoolId,
    preview,
    payableIds,
    comment,
  }: {
    schoolId: string;
    preview?: boolean;
    payableIds: string[];
    comment: string;
  }) => {
    const response = await fetcher(`${baseApi}/roll/payable/prepaid`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        student_payable_ids: payableIds,
        rtype: 'student_payable_prepaid',
        school_id: schoolId,
        preview: preview,
        comment,
      }),
    });
    if (response.ok) {
      const data = await response.json() as {
        success: boolean;
      };
      return { data };
    } else {
      const error = await response.json() as KindoNetworkError;
      return { error };
    }
  },
}

export default admin;