import React from 'react';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  VisibilityState,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table"
import { useVirtualizer } from '@tanstack/react-virtual'
import { useForm } from 'react-hook-form';
import Papa from 'papaparse';
import { MdArchive, MdAssignmentAdd, MdChevronRight, MdContentCopy, MdDelete, MdEdit, MdMoreHoriz, MdPublish } from 'react-icons/md';

import { Button } from "../../../common_component/base/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common_component/base/table";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "components/base/dropdown-menu";
import { Spinner } from '../../../common_component/base/spinner';
import { ColumnToggle } from '../../../common_component/table/ColumnToggle';
import { columns, RowItem } from './Columns';
import { format, parse } from 'date-fns';
import SelectField from 'components/form/SelectField';
import useAdminPayables from 'networking/hooks/useAdminPayables';
import { useContentDialog } from 'components/dialog/RootContentDialog';
import { PayableStatus, PayableStatusLabels } from 'networking/models/payable';
import { useToast } from 'components/base/use-toast';
import { Link, useNavigate } from 'react-router-dom';
import { formatCentsPriceForDisplay } from 'util/string_util';

interface DataTableProps {
  school: string;
  showIdColumn?: boolean;
  showProtoPayableIdColumn?: boolean;
  // Filter data
  filters: {
    searchText?: string;
    year?: string;
    status?: string;
    paymentStatus?: string;
    category?: string;
  }
}

type FormData = {
  actionType?: ActionType;
}

const COLUMN_EXPORT_ORDER = [
  {
    columnId: 'name',
    columnName: 'Name',
  },
  {
    columnId: 'payableCategory',
    columnName: 'Category',
  },
  {
    columnId: 'price',
    columnName: 'Price',
  },
  {
    columnId: 'glCode',
    columnName: 'GL Code',
  },
  {
    columnId: 'status',
    columnName: 'Status',
    format: (status: PayableStatus) => {
      return PayableStatusLabels[status];
    }
  },
  {
    columnId: 'paymentStatus',
    columnName: 'Payments',
  },
];
enum ActionType {
  MARK_AS_CURRENT = 'mark_as_current',
  MARK_AS_HISTORIC = 'mark_as_historic',
  NONE = '',
}

export function ProtoPayableTable({
  school,
  filters,
  showIdColumn,
  showProtoPayableIdColumn,
}: DataTableProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const {
    data: payablesData,
    isLoading,
    isValidating,
    getPaymentStatus,
    isLoadingStudentPayables,
    studentPayableStats,
    updatePayableStates,
    error: payablesError,
    duplicatePayable,
    mutate: refetchPayablesData,
    refreshPayables,
    deletePayable
  } = useAdminPayables({
    schoolId: school,
  });

  const data = React.useMemo(() => {
    return (payablesData?.products?.filter((item) => {
      let result = true;
      if (item.status === null) {
        return false;
      }
      if (filters.paymentStatus) {
        if (filters.paymentStatus === 'none') {
          result = getPaymentStatus(item?.proto_payable_id) === 'No requests';
        } else if (filters.paymentStatus === 'outstanding') {
          result = getPaymentStatus(item?.proto_payable_id) === 'Awaiting payment';
        } else if (filters.paymentStatus === 'paid') {
          result = getPaymentStatus(item?.proto_payable_id) === 'Fully paid';
        }
      }
      if (filters.year) {
        result = result && item.year?.toString() === filters.year;
      }
      if (filters.status) {
        result = result && item.status === filters.status;
      }
      if (filters.category) {
        result = result && item.pcat === filters.category;
      }
      return result;
    }) || [
      ]).map((item) => ({
        permanentId: item.permanent_id || '',
        name: item.product || '',
        year: item.year || '',
        status: item.status || '',
        glCode: item.remarks1 || '',
        paymentStatus: isLoadingStudentPayables ? 'loading' : getPaymentStatus(item?.proto_payable_id),
        price: formatCentsPriceForDisplay(item.price_in_cents),
        payableCategory: item.pcat || '',
        label: item.label || '',
        protoPayableId: item?.proto_payable_id || '',
        productId: item.product_id || '',
      }))
  }, [payablesData?.products, getPaymentStatus, filters.paymentStatus, filters.year, filters.status, filters.category, isLoadingStudentPayables]);
  const handleDuplicatePayable = async (permanentId: string) => {
    const result = await duplicatePayable.trigger({
      permanentId
    });
    if (result) {
      toast({
        description: `"${result.year} ${result.newLabel}" has been created. It might take a while to appear.`,
        duration: 8000,
        variant: 'success',
      });
      await refreshPayables.trigger();
    } else {
      toast({
        title: 'Failed to duplicate payable',
        description: 'Please try again later',
        variant: 'destructive',
      });
    }
  }

  const handleApplyPayable = async (permanentId?: string) => {
    if (permanentId) {
      navigate(`/app/admin/payable-apply?permanentId=${permanentId}`);
    }
  }

  const { showDialog: showDeleteDialog } = useContentDialog({
    title: 'Delete Payable',
    primaryActionDisabled: deletePayable.isMutating,
  });

  const { showDialog: showBulkActionDialog, closeDialog } = useContentDialog({
    primaryActionDisabled: updatePayableStates.isMutating
  });

  const handleDeletePayable = (permanentId: string) => {
    const payable = payablesData?.products.find((item) => item.permanent_id === permanentId);
    showDeleteDialog({
      title: `You are about to delete "${payable?.product}".`,
      description: (
        <p>
          When you delete this payable, you will no longer be able to apply it to students and any payments already made by caregivers will no longer be eligible for a refund. Financial reports will be unaffected but this payable will no longer show in ‘Kindo Payable’ types of reports. This action cannot be undone.&nbsp;
          {/* 
          // TODO: Add link to article
          <Link to="/app/admin" target="_blank">
            <span className='underline'>
              Learn about deleted payables.
            </span>
          </Link> */}
        </p>
      ),
      secondaryActionLabel: "Don't delete",
      primaryActionLabel: 'Delete payable',
      primaryActionProps: {
        variant: 'destructive',
        onClick: async () => {
          const result = await deletePayable.trigger({
            permanentId,
          });
          if (result) {
            toast({
              description: `'${payable?.product}' has been deleted`,
              variant: 'success',
            });
            closeDialog();
            await refreshPayables.trigger();
          } else {
            toast({
              description: `Failed to delete payable '${payable?.product}'`,
              variant: 'destructive',
            });
          }
        }
      },
    })
  }

  const handlePublishPayable = async (permanentId: string) => {
    const payable = payablesData?.products.find((item) => item.permanent_id === permanentId);

    const response = await updatePayableStates.trigger({
      permanentIds: [permanentId],
      newState: PayableStatus.ACTIVE,
    });
    if (response) {
      const { error, data } = response;
      if (error) {
        toast({
          description: `Failed to ${payable?.status === PayableStatus.DRAFT ? 'publish payable' : 'mark payable as current'}`,
          variant: 'destructive',
        });
      } else {
        toast({
          description: `'${payable?.product}' has been ${payable?.status === PayableStatus.DRAFT ? 'published' : 'marked as current'}`,
          variant: 'success',
        });
        closeDialog();
        await refreshPayables.trigger();
      }
    }
  }

  const onMoveToHistoric = async (permanentIds: string[], isBulk = false) => {
    const response = await updatePayableStates.trigger({
      permanentIds: permanentIds,
      newState: PayableStatus.INACTIVE,
    });
    if (response) {
      const { error, data } = response;
      if (error) {
        toast({
          description: 'Failed to mark as historic',
          variant: 'destructive',
        });
      } else {
        if (!isBulk) {
          const payable = payablesData?.products.find((item) => item.permanent_id === permanentIds[0]);
          if (payable) {
            toast({
              description: `'${payable.product}' has been moved to historic`,
              variant: 'success',
            });
          }
        } else {
          toast({
            description: `The selected payables has been moved to historic`,
            variant: 'success',
          });
        }
        closeDialog();
      }
    }
  }
  const onMarkAsCurrent = async (permanentIds: string[]) => {
    const response = await updatePayableStates.trigger({
      permanentIds: permanentIds,
      newState: PayableStatus.ACTIVE,
    });
    if (response) {
      const { error, data } = response;
      if (error) {
        toast({
          description: 'Failed to mark as current',
          variant: 'destructive',
        });
      } else {
        toast({
          description: `The selected payables has been marked as current`,
          variant: 'success',
        });
        closeDialog();
      }
    }
  }


  const handleMoveToHistoricPayable = (permanentId: string) => {
    onMoveToHistoric([permanentId]);
  }

  const columnsWithActions = React.useMemo(() => {
    return columns.concat([
      {
        id: 'actions',
        maxSize: 50,
        cell: ({ row }) => (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button
                variant="textPrimary"

                size="xs"

              >
                Actions
                <MdChevronRight className='w-6 h-6 rotate-90' />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {
                row.original.status !== PayableStatus.INACTIVE && (
                  <DropdownMenuItem
                    onClick={() => {
                      navigate(`/app/admin/payables/${row.original.permanentId}`)
                    }}
                  >
                    <MdEdit />
                    <span>Edit</span>
                  </DropdownMenuItem>
                )
              }
              {
                (row.original.status === PayableStatus.DRAFT || row.original.status === PayableStatus.INACTIVE) && (
                  <DropdownMenuItem
                    disabled={!row.original.productId}
                    onClick={() => handlePublishPayable(row.original.permanentId)}
                  >
                    <MdPublish />
                    <span>
                      {row.original.status === PayableStatus.DRAFT ? 'Publish' : 'Mark as current'}
                    </span>
                  </DropdownMenuItem>
                )
              }
              <DropdownMenuItem
                onClick={() => {
                  handleDuplicatePayable(row.original.permanentId)
                }}
              >
                <MdContentCopy />
                <span>Duplicate</span>
              </DropdownMenuItem>
              {
                row.original.status === PayableStatus.ACTIVE && (
                  <DropdownMenuItem
                    disabled={!row.original.productId}
                    onClick={() => {
                      handleMoveToHistoricPayable(row.original.permanentId)
                    }}
                  >
                    <MdArchive />
                    <span>Mark as historic</span>
                  </DropdownMenuItem>
                )
              }
              {
                row.original.status === PayableStatus.ACTIVE && (
                  <DropdownMenuItem
                    onClick={() => {
                      handleApplyPayable(row.original.permanentId)
                    }}
                  >
                    <MdAssignmentAdd />
                    <span>Apply</span>
                  </DropdownMenuItem>
                )
              }
              {
                (
                  row.original.status === PayableStatus.DRAFT ||
                  row.original.paymentStatus === 'No requests'
                ) && (
                  <DropdownMenuItem
                    onClick={() => {
                      handleDeletePayable(row.original.permanentId)
                    }}
                  >
                    <MdDelete />
                    <span>Delete</span>
                  </DropdownMenuItem>
                )
              }
            </DropdownMenuContent>
          </DropdownMenu>
        ),
      },
    ]);
  }, [payablesData]);

  React.useEffect(() => {
    if (payablesError) {
      toast({
        title: 'Failed to fetch payables',
        description: payablesError.message,
        variant: 'destructive',
      });
    }
  }, [payablesError])

  const parentRef = React.useRef<HTMLDivElement>(null);
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({
    // Default visibility state
    permanentId: !!showIdColumn,
    protoPayableId: !!showProtoPayableIdColumn,
    // applied: false,
    // rule: false,
    // comments: false,
  })
  const [sorting, setSorting] = React.useState<SortingState>([
    {
      id: 'year',
      desc: true,
    },
    {
      id: 'label',
      desc: false
    },
  ])
  const { control, watch, setValue } = useForm<FormData>({
    defaultValues: {
      // actionType: ActionType.NONE,
    }
  });

  const table = useReactTable({
    data,
    columns: columnsWithActions,
    globalFilterFn: 'includesString',
    getCoreRowModel: getCoreRowModel(),
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      rowSelection,
      sorting,
      globalFilter: filters.searchText,
      columnVisibility,
    }
  })

  const actionType = watch('actionType');
  const { rows } = table.getRowModel()
  const { flatRows: selectedRows } = table.getSelectedRowModel()
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 65,
    overscan: 5,
  });

  const virtualItems = rowVirtualizer.getVirtualItems();

  const refreshData = () => {
    refetchPayablesData();
  }

  // Multi Selection variables
  const selectedPayables = React.useMemo(() => {
    return selectedRows.map((row) => row.original)
  }, [selectedRows]);
  const anyDraftSelected = selectedPayables.some((payable) => payable.status === PayableStatus.DRAFT);
  const anyInactiveSelected = selectedPayables.some((payable) => payable.status === PayableStatus.INACTIVE);
  const anyActiveSelected = selectedPayables.some((payable) => payable.status === PayableStatus.ACTIVE);

  const getColumnName = (key: string) => {
    const column = COLUMN_EXPORT_ORDER.find((c) => c.columnId === key);
    return column?.columnName || key;
  }
  const handleExport = () => {
    const visibleColumns = table.getVisibleFlatColumns().filter((column) => column.id && column.id !== 'select' && column.id !== 'actions');
    const orderedColumns = visibleColumns.reduce<string[]>((all, column) => {
      if (column.id && getColumnName(column.id)) {
        return [
          ...all,
          column.id,
        ]
      }
      return all;
    }, []) // Additional columns
      .sort((a, b) => {
        return COLUMN_EXPORT_ORDER.findIndex((c) => c.columnId === a) - COLUMN_EXPORT_ORDER.findIndex((c) => c.columnId === b);
      });

    // Preparing data for building csv
    const data = rows.reduce<string[][]>((allData, row) => {
      const rowData = orderedColumns.reduce<string[]>((all, columnKey) => {
        const format = COLUMN_EXPORT_ORDER.find((c) => c.columnId === columnKey)?.format;
        if (format) {
          const valueToFormat = row.original[columnKey as keyof RowItem] as PayableStatus;
          return [
            ...all,
            format(valueToFormat),
          ]
        }
        const value = row.original[columnKey as keyof RowItem] as string;
        return [
          ...all,
          value,
        ]
      }, []);;
      return [
        ...allData,
        rowData,
      ]
    }, []);
    const csvData = Papa.unparse({
      fields: orderedColumns.map((columnKey: string) => getColumnName(columnKey)),
      data
    });

    // Create a blob from the CSV data
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);

    // Create a hidden <a> element and click it to trigger the download
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `payables-${school}-${format(new Date(), 'dd-MM-yyyy')}.csv`);
    link.style.visibility = 'hidden';
    link.style.display = 'none';
    document.body.appendChild(link);
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }
  React.useEffect(() => {
    if (data) {
      // Original data changed, reset row selection
      setRowSelection({});
    }
  }, [data]);

  React.useEffect(() => {
    setColumnVisibility({
      ...columnVisibility,
      permanentId: !!showIdColumn,
      protoPayableId: !!showProtoPayableIdColumn,
    })
  }, [showProtoPayableIdColumn, showIdColumn]);

  React.useEffect(() => {
    switch (actionType) {
      case ActionType.MARK_AS_CURRENT:
        const selectedInactivePayables = selectedPayables.filter((payable) => payable.status === PayableStatus.INACTIVE);
        const selectedDraftPayables = selectedPayables.filter((payable) => payable.status === PayableStatus.DRAFT);
        showBulkActionDialog({
          title: `You are about to mark ${selectedPayables.length} payables as current`,
          description: `When marked as current, these payables can be applied to students. ${selectedDraftPayables.length > 0 ? `${selectedDraftPayables.length} payables will be ignored as they are in draft status.` : ''}`,
          primaryActionProps: {
            onClick: () => {
              onMarkAsCurrent(selectedInactivePayables.map((payable) => payable.permanentId));
            }
          },
          primaryActionLabel: 'Mark as current',
        });
        setValue('actionType', ActionType.NONE);
        break;
      case ActionType.MARK_AS_HISTORIC:
        const selectedActivePayables = selectedPayables.filter((payable) => payable.status === PayableStatus.ACTIVE);
        showBulkActionDialog({
          title: `You are about to mark ${selectedPayables.length} payables as historic.`,
          description: 'When marked as historic, you will no longer be able to apply them to students.',
          primaryActionProps: {
            onClick: () => {
              onMoveToHistoric(selectedActivePayables.map((payable) => payable.permanentId), true);
            }
          },
          primaryActionLabel: 'Mark as historic',
        });
        setValue('actionType', ActionType.NONE);
        break;
    }
  }, [actionType])
  return (
    <div className='flex flex-col text-card-foreground'>
      <div className="flex items-center gap-3 p-4">
        {
          Object.keys(rowSelection).length > 0 && (
            <SelectField
              placeholder="Choose an action..."
              control={control}
              name='actionType'
              items={[
                {
                  label: 'Choose an action...',
                  value: ActionType.NONE,
                },
                {
                  label: 'Mark as current',
                  value: ActionType.MARK_AS_CURRENT,
                  disabled: !anyDraftSelected && !anyInactiveSelected,
                },
                {
                  label: 'Mark as historic',
                  value: ActionType.MARK_AS_HISTORIC,
                  disabled: !anyActiveSelected,
                },
              ]}
            />
          )
        }
        <div className="flex-1" />
        {!isLoading && (
          <>
            {/* 
            REFRESH BUTTON, Uncomment if required
            <div className='flex items-center pr-4 border-border border-r-1'>
              <Button variant="icon" size="default" disabled={isValidating} onClick={refreshData}>
                <MdRefresh className={cn('w-5 h-5', {
                  'animate-spin': isValidating
                })} />
              </Button>
            </div> */}
            {/* {
              Object.keys(rowSelection).length > 0 && (
                <Button
                  variant="icon"
                  onClick={() => {
                    setRowSelection({});
                  }}
                >

                  <MdClose
                    size={24}
                  />
                </Button>
              )
            } */}
            <p className="text-sm">
              {`Displaying ${rows.length} items`}
              {/* {Object.keys(rowSelection).length > 0 ? `${Object.keys(rowSelection).length} items selected` : ``} */}
            </p>
          </>
        )}
        <ColumnToggle table={table} />
        <Button disabled={isLoading} variant="ghost" className='text-primary font-museo' size="sm" onClick={handleExport}>
          Export
        </Button>
      </div>
      <div ref={parentRef} className="border-t max-h-[calc(100vh_-_380px)] min-h-[400px] overflow-auto">
        <div style={{ height: `${rowVirtualizer.getTotalSize() + 60}px` }} className='min-h-[200px]'>
          <Table>
            <TableHeader
              className='sticky top-0 z-10 table-header-group bg-gray-50 border-b-1 border-border'
            >
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id} >
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className='font-museo'
                        style={{
                          width: header.getSize() || undefined,
                          textAlign: header.column.columnDef.meta?.textAlign,
                        }}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {
                isLoading && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1} className="h-24 text-center">
                      <div className='flex items-center justify-center gap-2'>
                        <Spinner size="xs" />
                        Loading please wait
                      </div>
                    </TableCell>
                  </TableRow>
                )
              }
              {rows?.length ? (
                virtualItems.map((virtualRow, index) => {
                  const row = rows[virtualRow.index];
                  return (
                    <TableRow
                      className='border-none'
                      key={row.id}
                      style={{
                        height: `${virtualRow.size}px`,
                        transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                      }}
                      data-state={row.getIsSelected() && "selected"}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <TableCell key={cell.id} align={cell.column.columnDef.meta?.textAlign}>
                          {flexRender(cell.column.columnDef.cell, cell.getContext())}
                        </TableCell>
                      ))}
                    </TableRow>
                  )
                })
              ) : (
                !isLoading &&
                <TableRow>
                  <TableCell colSpan={columns.length + 1} className="h-24 text-center">
                    {!payablesData?.products || payablesData?.products?.length === 0 ? (
                      'You have no payables. Create a new payable to get started.'
                    ) : (
                      'No results.'
                    )}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  )
}
