import useSWR, { useSWRConfig } from 'swr';
import { baseApi, baseUrl } from './constants';
import { ProductStockList, OrderPdfFile, Orders, OrderDetails, OrderDownload } from './models/supplier';
import { KindoNetworkError } from './models/common';
import fetcher from './util/fetcher';

const supplier = {
  // Queries (starts with "use")
  useProductStockList: ({ supplier, site }: { supplier?: string, site?: string }) => {
    // (supplier?: string) => {
    const params = new URLSearchParams({
      ...(site ? { site: site } : {}),
    }).toString();
    const data = useSWR<ProductStockList, KindoNetworkError>(!supplier ? null : `${baseApi}/supplier/${supplier}/product_stock_list?${params}`, {
      revalidateOnFocus: true,
      refreshInterval: 60000
    });
    return data;
  },

  useDailyReport: ({ isSubmit, date, supplier }: { isSubmit: boolean, date?: string, supplier?: string }) => {
    const params = new URLSearchParams({
      date: date ?? ''
    }).toString();
    const data = useSWR<OrderPdfFile, KindoNetworkError>(!isSubmit || !supplier ? null : `${baseApi}/supplier/${supplier}/daily_report?${params}`);
    return data;
  },

  useDownloadOrders: ({ date, supplier, pdfPrint }: { date: string, supplier?: string, pdfPrint?: string }) => {
    const params = new URLSearchParams({
      start_date: date,
      end_date: date,
      format: 'A4_pdf',
      full_details: pdfPrint === 'A4_pdf_full' ? 'true' : 'false'
    }).toString();
    const data = useSWR<OrderPdfFile, KindoNetworkError>(!pdfPrint || !supplier ? null : `${baseApi}/supplier/${supplier}/orders?${params}`);
    return data;
  },

  useOrders: ({ supplier, startDate, endDate, statusList, isDisplayOrder }: { supplier: string, startDate: string, endDate: string, statusList?: string, isDisplayOrder?: boolean }) => {
    const params = new URLSearchParams({
      start_date: startDate,
      end_date: endDate,
      status_list: statusList ?? '',
      non_orders: 'false'
    }).toString();
    const data = useSWR<Orders, KindoNetworkError>(!isDisplayOrder ? null : `${baseApi}/supplier/${supplier}/orders?${params}`);
    return data;
  },

  // Mutations
  downloadOrders: ({ supplier, data }: { supplier: string, data: OrderDownload }) => {
    return fetcher(`${baseApi}/supplier/${supplier}/download_orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
  saveOrders: ({ supplier, before, after }: { supplier: string, before: OrderDetails, after: OrderDetails }) => {
    const postData = {
      before: before,
      after: after,
      rtype: 'order_status_transaction'
    }
    return fetcher(`${baseApi}/supplier/${supplier}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'applicataion/json',
      },
      body: JSON.stringify(postData),
    })
  },
  // TRYING SAVE STOCK IF WILL WORK ON SHOP - UPDATE: ONLY WORKS ON PARTNER BUT LEAVING IT HERE FOR NOW
  saveStock: ({ supplier, id, stock, site }: { supplier: string, id: string, stock: number, site?: string }) => {
    const postData = {
      permanent_id: id,
      stock: stock,
      ...(site ? { site: site } : {}),
    }
    return fetcher(`${baseApi}/supplier/${supplier}/product_stock`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postData),
    })
  },
}

export default supplier;