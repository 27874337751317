import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import { Button } from "../../../common_component/base/button";
import { Student } from "networking/models/family";

type Props = {
  students: Student[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const StudentsDialog = ({ students, open, onOpenChange }: Props) => {

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Student / member details</DialogTitle>
        </DialogHeader>
        {students.map((student) => (
          <div className='text-card-foreground' key={`${student.student_id}-${student.school}`}>
            <div className='flex flex-col gap-1'>
              <p className='text-sm font-semibold'>{student.first_name} {student.last_name}</p>
              <p className='text-xs'>{student.school} {student.room && `(${student.room})`}</p>
            </div>
          </div>
        ))}
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default StudentsDialog