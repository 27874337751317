import React from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogFooter,
} from "../../common_component/base/dialog";
import { Checkbox } from '../../common_component/base/checkbox';
import { Button } from '../../common_component/base/button';
import { Calendar } from '../../common_component/base/calendar';
import { MdCalendarMonth, MdViewList } from 'react-icons/md';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import { Service } from 'networking/models/family';
import { format, parseISO } from 'date-fns';
import orderUtils from 'networking/util/order';
import analytics from '../../util/googleAnalytics';

type Props = {
  service: Service;
  onOpenChange?: (open: boolean) => void;
  children: React.ReactNode;
};

const OrderDatePicker = observer(({ service, children, onOpenChange }: Props) => {
  const store = useStore();
  const [error, setError] = React.useState(false);

  const availableDates = orderUtils.getAvailableDates(service).slice(0, 100);
  const selectedOrderDates = store.shop.selectedOrderDates;

  const isAllSelected = availableDates.length === selectedOrderDates.length;
  React.useEffect(() => {
    if (store.shop.showDatePicker) {
      setError(false); // Reset error when opened
    }
  }, [store.shop.showDatePicker]);

  const handleOpenChange = (open: boolean) => {
    if (!open) {
      if (selectedOrderDates.length === 0 && availableDates.length > 0) {
        setError(true);
      } else {
        setError(false);
        store.shop.setShowDatePicker(open)
      }

      analytics.triggerClick({
        eventName: 'select_date',
        parameters: {
          selected_count: (selectedOrderDates || []).length,
        }
      })
    }
  }
  return (
    <Dialog open={store.shop.showDatePicker} onOpenChange={(open) => {
      handleOpenChange(open);
    }}>
      <DialogTrigger asChild onClick={() => {
        store.shop.setShowDatePicker(true);
      }}>
        {children}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <div className='flex items-center gap-2'>
            <p className='flex-1 text-lg font-bold text-primary'>Pick your delivery dates</p>
            <div>
              <Button
                name="toggle-date-picker-view"
                variant="link"
                className='gap-1'
                size="xs"
                onClick={() => {
                  if (store.shop.datePickerView === 'calendar') {
                    store.shop.setDatePickerView('list');
                  } else {
                    store.shop.setDatePickerView('calendar');
                  }
                  analytics.triggerClick({
                    eventName: 'date_picker_toggle',
                    parameters: {
                      view_type: store.shop.datePickerView === 'calendar' ? 'list' : 'calendar'
                    }
                  })
                }}
              >
                {store.shop.datePickerView === 'calendar' ? 'List View' : 'Calendar View'}
                {store.shop.datePickerView === 'calendar' ? <MdViewList /> : <MdCalendarMonth />}
              </Button>
            </div>
          </div>
        </DialogHeader>
        <div className='flex flex-col w-full'>
          {
            store.shop.datePickerView === 'list' ? (
              <>
                <div>
                  <div
                    className='flex items-center justify-between px-4 py-2 border-b border-border'
                  >
                    <Checkbox
                      checked={isAllSelected}
                      name="selectAll"
                      label={'Select all'}
                      onCheckedChange={(e) => {
                        if (isAllSelected) {
                          store.shop.setSelectedOrderDates([]);
                        } else {
                          store.shop.setSelectedOrderDates(availableDates.map((d) => parseISO(d)));
                        }
                      }}
                    />
                  </div>
                  {
                    availableDates.map((date) => {
                      const isSelected = !!store.shop.selectedOrderDates.find((d) => format(d, 'yyyy-MM-dd') === date);
                      // Convert to iso date
                      const isoDate = parseISO(date);
                      const label = format(isoDate, 'iiii d MMMM yyyy');
                      return (
                        <div
                          key={date}
                          className='flex items-center justify-between px-4 py-2 border-b border-border'
                        >
                          <Checkbox
                            checked={isSelected}
                            name={label}
                            label={label}
                            onCheckedChange={(e) => {
                              if (isSelected) {
                                store.shop.setSelectedOrderDates(selectedOrderDates.filter((d) => format(d, 'yyyy-MM-dd') !== date));
                              } else {
                                store.shop.setSelectedOrderDates([...selectedOrderDates, parseISO(date)]);
                              }
                            }}
                          />
                        </div>
                      )
                    })
                  }
                </div>
              </>
            ) : (
              <div className='self-center min-h-[350px]'>
                <Calendar
                  showOutsideDays={false}
                  mode="multiple"
                  disabled={[
                    (date) => {
                      return !availableDates.includes(format(date, 'yyyy-MM-dd'));
                    }
                  ]}
                  selected={selectedOrderDates}
                  onSelect={(dates) => {
                    console.log('selected dates', dates)
                    store.shop.setSelectedOrderDates(dates || []);
                  }}

                  onDayClick={(day, modifier) => {
                    console.log('clicked on day', day)
                    console.log('modifier', modifier)
                  }}
                />
              </div>
            )
          }
          {
            error && (
              <p className='text-sm text-danger-foreground'>
                Please select a delivery date
              </p>
            )
          }

        </div>
        <DialogFooter>
          <Button onClick={() => {
            handleOpenChange(false);
          }}>Continue</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
})

export default OrderDatePicker