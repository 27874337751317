import React from 'react'
import { DAY_MAP } from 'networking/util/order';
import { cn } from '../../../util/tailwind';
import { MyKindoTippy } from '../../../common_component/tooltip/MyKindoTippy';

type Props = {
  // array of numbers from 1 to 7
  availableDays: number[];
  weekDaysOnly?: boolean;
}

const AvailableDays = ({
  availableDays = [],
  weekDaysOnly,
}: Props) => {
  if (availableDays.length === 0) return null;
  return (
    <div className='flex flex-wrap gap-1'>
      {
        Object.keys(DAY_MAP).slice(0, weekDaysOnly ? 5 : 7).map((day) => {
          const availableOnThisDay = availableDays.includes(Number(day)) || availableDays.length === 0;
          return (
            <MyKindoTippy
              key={day}
              tooltipContent={`${availableOnThisDay ? 'Available' : 'Unavailable'} on ${DAY_MAP[day]}s`}
            >
              <div
                className={cn('flex items-center font-bold bg-primary-lightest justify-center w-[20px] h-[20px] rounded-full border-1 border-primary-lightest text-card-foreground text-center text-xs', {
                  'text-card-foreground/60 bg-card': !availableOnThisDay,
                })}
              >
                {DAY_MAP[day].slice(0, 1)}
              </div>
            </MyKindoTippy>
          )
        })
      }
    </div>
  )
}

export default AvailableDays