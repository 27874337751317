import { isAfter, add } from 'date-fns';
import React from 'react';

type Props = {
  startDate?: Date;
  targetDate?: Date;
  intervalMs: number;
}
const useCountdown = (props: Props) => {
  const { intervalMs, targetDate, startDate } = props;
  const [date, setDate] = React.useState(startDate ?? new Date());
  const countdownRef = React.useRef<ReturnType<typeof setInterval> | null>();

  const stop = () => {
    if (countdownRef.current) {
      clearInterval(countdownRef.current);
    }
  }
  const start = () => {
    const interval = setInterval(() => {
      tick();
    }, intervalMs);

    countdownRef.current = interval;
  }
  const tick = () => {
    const diff = Math.floor((new Date().getTime() - date.getTime()) / 1000);
    const newDate = add(date, { seconds: diff });
    setDate(newDate);

    if (targetDate && isAfter(newDate, targetDate)) {
      // We counted past the target. Let's stop the timer
      stop();
      setDate(targetDate);
    }
  }
  React.useEffect(() => {
    if (targetDate) {
      start();
    }
    return () => {
      stop();
    }
  }, [targetDate])

  const diff = targetDate ? targetDate.getTime() - date.getTime() : 0;
  const diffSeconds = diff / 1000;
  const minutes = Math.floor(diff / (1000 * 60) % 60);
  const seconds = Math.floor(diffSeconds % 60);
  return {
    date,
    minutes,
    seconds,
    diffSeconds,
    label: `${`${minutes}`.length === 1 ? `0${minutes}` : minutes}:${`${seconds}`.length === 1 ? `0${seconds}` : seconds}`,
    start,
    stop,
  }
}

export default useCountdown;