import React, { useCallback } from 'react';
import Helmet from 'react-helmet'
import ShopLayout from "../../common_component/layout/ShopLayout";
import api from 'networking/api';
import { Spinner } from '../../common_component/base/spinner';
import { Button } from '../../common_component/base/button';
import { CopyToClipboard } from '../../common_component/base/copy-to-clipboard';
import { useForm } from 'react-hook-form';
import { Card, CardContent, CardHeader, CardTitle } from "../../common_component/base/card";
import InputField from '../../common_component/form/InputField';
import ComboBoxField from "../../common_component/form/ComboBoxField";
import { isTgclEmail } from '../../util/util';
import useSWRMutation from 'swr/mutation';
import { SearchResult } from 'networking/models/customer'
import { Family } from 'networking/models/family'
import ResultsTable from './ResultsTable';
import FamilyDetails from './FamilyDetails';
import SavedForListDialog from './dialog/SavedForListDialog';
import { useDebounce } from "util/hooks/useDebounce";

type FormData = {
  family: string,
  member: string,
  organisation: string,
  supplier: string
}

const ConnectPage = () => {
  // Logged in family details
  const { data, isLoading } = api.family.useFamily()
  const { data: lateClosingKey } = api.customer.useLateClosingKey()

  const { control, handleSubmit, setValue, reset, watch, formState: { isSubmitting } } = useForm<FormData>({
    defaultValues: {},
  });

  const [discountSupplier, setDiscountSupplier] = React.useState({ id: '', name: '' });
  const [error, setError] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<SearchResult[] | undefined>(undefined);
  const [currentFamilyId, setCurrentFamilyId] = React.useState('');
  const [currentFamily, setCurrentFamily] = React.useState<Family | undefined>(undefined);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [showSavedForList, setShowSavedForList] = React.useState(false);
  const isLoggedInAsTgcl = data && isTgclEmail(data.email);
  const [debouncedSearchValues, setDebouncedSearchValues] = React.useState({ family: '', member: '', organisation: '' });

  const onPageChange = useCallback((page) => {
    setCurrentPage(page);
  }, [])

  const onCloseFamily = useCallback(() => {
    setCurrentFamilyId('');
    setCurrentFamily(undefined);
    setCurrentPage(1);
  }, [])

  const onSearchFamily = (values: FormData) => {
    trigger(values);
    onCloseFamily();
  }

  const onSetCurrentFamilyId = useCallback((id) => {
    setCurrentFamilyId(id);
  }, [])

  const resetForm = () => {
    reset({
      family: '',
      member: '',
      organisation: isLoggedInAsTgcl ? '' : data?.school_ids[0],  // Reset only if isLoggedInAsTgcl is true
    });
  };

  // Searched family details
  const { data: familyDetails, isLoading: isLoadingFamilyDetails, mutate, error: searchFamilyError } = api.family.useFamilyDetails({
    familyId: currentFamilyId || '',
    start: (90 * currentPage).toString(),
    end: (90 * (currentPage - 1)).toString(),
    supplierId: isLoggedInAsTgcl ? '' : discountSupplier.id,
    discountSupplierId: discountSupplier.id,
    maxNotes: isLoggedInAsTgcl ? '10' : '',
  });

  React.useEffect(() => {
    if (searchFamilyError) {
      setError(searchFamilyError.message);
    }
  }, [searchFamilyError]);

  React.useEffect(() => {
    if (familyDetails) {
      setCurrentFamily(familyDetails);
    }
  }, [familyDetails]);

  React.useEffect(() => {
    if (data) {
      setDiscountSupplier(data.supplier_order[0])
      setValue('supplier', data.supplier_order[0].id)
      if (!isTgclEmail(data.email)) {
        setValue('organisation', data.school_ids[0])
        setDebouncedSearchValues({ ...debouncedSearchValues, organisation: data.school_ids[0] })
      }
    }
  }, [data]);

  // Debounce search
  const debouncedSearch = useDebounce(debouncedSearchValues, 400);

  React.useEffect(() => {
    const shouldSearch = data && isTgclEmail(data.email)
      ? (debouncedSearch.family || debouncedSearch.member || debouncedSearch.organisation)
      : ((debouncedSearch.family || debouncedSearch.member) && debouncedSearch.organisation);

    if (shouldSearch) {
      onSearchFamily(debouncedSearch);
    }
  }, [debouncedSearch, data]);


  const handleRowClick = (familyId: string) => {
    setCurrentFamilyId(familyId);
  };

  const { trigger, isMutating } = useSWRMutation('/search/family', (key, { arg }: { arg: FormData }) => {
    setError('')
    try {
      return api.customer.searchFamily({
        terms: {
          family: {
            // remove space for convenience when copy pasting email or handle
            value: arg.family.replace(/\s+/g, '') || '',
            select: true
          },
          school: {
            value: arg.organisation || '',
            select: false
          },
          student: {
            value: arg.member || '',
            select: false
          }
        }
      }).then(async (response) => {
        if (response.ok) {
          try {
            const body = await response.json();
            const filteredResults = body?.filter(result =>
              !(
                result.email.includes('@tgcl.co.nz') ||
                result.email.includes('@kindo.co.nz')
              )
            );

            const results = isLoggedInAsTgcl ? body : filteredResults;
            if (results.length === 1) {
              setCurrentFamilyId(results[0].family_id)
            } else {
              setSearchResults(results)
            }
          } catch (e) {
            setError('Something went wrong. Please try again.');
          }
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })

  return (
    <div>
      <Helmet>
        <title>Connect</title>
      </Helmet>
      <ShopLayout>
        <div>
          {isLoading ?
            <div className='flex flex-col items-center justify-center h-full gap-5 mt-12'>
              <Spinner size='md' />
              <p className='text-xl font-museo'>Loading...</p>
            </div>
            :
            <div>
              <div className="flex flex-col gap-0 mt-0 sm:gap-4 sm:mt-8 sm:pb-6">
                <div className='flex items-center justify-between'>
                  <h2 className="text-3xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
                    Connect
                  </h2>
                  {isLoggedInAsTgcl &&
                    <div className='flex flex-col items-end'>
                      <div className='flex items-center gap-2'>
                        <CopyToClipboard text={lateClosingKey?.late_closing_key}>
                          {({ copyToClipboard, isCopied }) => (
                            <Button
                              size="sm"
                              className="font-museo"
                              onClick={copyToClipboard}
                              variant="default"
                              type="button"
                              name="copy-late-closing-key"
                            >
                              {isCopied ? 'Copied!' : lateClosingKey?.late_closing_key}
                            </Button>
                          )}
                        </CopyToClipboard>
                        <Button size='sm' className='font-museo' onClick={() => { setShowSavedForList(true) }} variant="default" type='button'>
                          View saved for list
                        </Button>
                      </div>
                    </div>
                  }
                </div>
                <Card className="transition-shadow shadow-md hover:shadow-xl rounded-3xl">
                  <CardHeader>
                    <CardTitle className='text-xl'>
                      Family Finder
                    </CardTitle>
                  </CardHeader>
                  <CardContent>
                    <form onSubmit={handleSubmit(onSearchFamily)}>
                      <div className='flex gap-4 mb-4'>
                        <div className='w-full'>
                          <InputField
                            label="Caregiver last name or email"
                            placeholder="Enter caregiver last name or email"
                            control={control}
                            name="family"
                            data-1p-ignore
                            disabled={!isLoggedInAsTgcl && data?.school_ids?.length === 0}
                            onChange={(event) => setDebouncedSearchValues((prev) => ({
                              ...prev,
                              family: event.target.value
                            }))}
                          />
                        </div>
                        <div className='w-full'>
                          <InputField
                            label="Student/member name"
                            placeholder="Enter student/member name"
                            control={control}
                            name="member"
                            disabled={!isLoggedInAsTgcl && data?.school_ids?.length === 0}
                            onChange={(event) => setDebouncedSearchValues((prev) => ({
                              ...prev,
                              member: event.target.value
                            }))}
                          />
                        </div>
                        <div className='w-full'>
                          <ComboBoxField
                            label="School/organisation"
                            placeholder="Enter school/organisation"
                            name="organisation"
                            control={control}
                            isLoading={isLoading}
                            borderColor='primary'
                            isClearable={isLoggedInAsTgcl}
                            onChange={(event) => {
                              const newValue = event.value;
                              if (newValue) {
                                setDebouncedSearchValues((prev) => ({
                                  ...prev,
                                  organisation: newValue
                                }));
                              } else {
                                setDebouncedSearchValues((prev) => ({
                                  ...prev,
                                  organisation: ''
                                }));
                              }
                            }}
                            options={[
                              ...(data?.school_ids.map((school) => ({
                                value: school,
                                label: school
                              })) ?? [])
                            ]}
                          />
                        </div>
                      </div>
                      <div className='flex gap-2'>
                        <Button
                          size='sm'
                          variant="default"
                          type='submit'
                          disabled={!isLoggedInAsTgcl && data?.school_ids?.length === 0}
                        >
                          Search
                        </Button>
                        <Button size='sm' variant="outline" type='button' onClick={() => resetForm()}>
                          Clear filters
                        </Button>
                      </div>
                    </form>
                  </CardContent>
                </Card>
                {(isMutating || isLoadingFamilyDetails || isSubmitting) &&
                  <div className='flex flex-col items-center justify-center h-full gap-5 mt-12'>
                    <Spinner size='md' />
                    <p className='text-xl font-museo'>Loading...</p>
                  </div>
                }
                {!isLoadingFamilyDetails && currentFamily && data &&
                  <FamilyDetails
                    isTgclUser={isTgclEmail(data.email)}
                    mutate={mutate}
                    currentFamily={currentFamily}
                    currentPage={currentPage}
                    onPageChange={onPageChange}
                    onCloseFamily={onCloseFamily}
                  />
                }
                {!isMutating && !currentFamilyId &&
                  <ResultsTable
                    searchResults={
                      isLoggedInAsTgcl ?
                        searchResults
                        :
                        searchResults?.filter(result =>
                          !(
                            result.email.includes('@tgcl.co.nz') ||
                            result.email.includes('@kindo.co.nz')
                          )
                        )}
                    onRowClick={handleRowClick}
                    isLoading={isMutating}
                  />
                }
                {showSavedForList &&
                  <SavedForListDialog
                    onSetCurrentFamilyId={onSetCurrentFamilyId}
                    open={showSavedForList}
                    onOpenChange={(open) => setShowSavedForList(open)}
                  />
                }
                {error && <div className="mt-2 text-sm text-destructive">{error}</div>}
              </div>
            </div>
          }
        </div>
      </ShopLayout>
    </div>
  )
}

export default ConnectPage;