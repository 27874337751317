import React from 'react';
import ContentDialog from 'components/dialog/ContentDialog';
import api from 'networking/api';
import useSWRMutation from 'swr/mutation';
import { useNavigate } from 'react-router-dom';
import { useToast } from 'components/base/use-toast';

type Props = {
  open: boolean;
  onClose: () => void;
  studentId: string;
}

/**
 * This component is used to delete a student
 */
const DeleteStudentDialog = (props: Props) => {
  const {
    open,
    onClose,
    studentId,
  } = props;
  const navigate = useNavigate();
  const { toast } = useToast();
  const { data, isLoading, mutate } = api.family.useMyDetails();
  const { mutate: mutateFamily } = api.family.useFamily();

  const student = data?.students.find((student) => student.student_id === studentId);
  const { trigger, error, isMutating } = useSWRMutation('/family/save-details', (key, { arg }: { arg: {} }) => {
    try {
      if (data) {
        return api.family.saveFamilyDetails({
          family: {
            students: data.students.filter(student => student.student_id !== studentId)
              .map((student) => ({
                allergy: student.allergy,
                first_name: student.first_name,
                last_name: student.last_name,
                student_id: student.student_id,
                student_school_id: student.student_school_id,
                school: student.school,
                room: student.room,
                member_id: student.member_id,
                member_type: student.member_type,
                address: student.address,
              }))
          }
        }).then(async (response) => {
          if (response.ok) {
            await mutate();
            await mutateFamily();
            toast({
              title: 'Successfully deleted student',
            });
            onClose();
            navigate('/app/my-details', {
              replace: true,
            });
          } else {
            throw new Error('Something went wrong, please try again');
          }
        });
      }
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })
  const onConfirm = async () => {
    await trigger({});
  }

  const getTitle = () => {
    return `You are about to delete the member "${student?.first_name} ${student?.last_name}"`
  }
  return (
    <div>
      <ContentDialog
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        open={open}
        title={getTitle()}
        content={(
          <div className='flex flex-col gap-4'>
            <p className='text-sm text-text-body'>
              <p>
                This action is permanent and cannot be undone. The member will be removed from your Kindo account. Records of payments related to this member will still be accessible.
              </p>
            </p>
            {
              error && (
                <p className='text-sm text-destructive'>
                  {error.message}
                </p>
              )
            }
          </div>
        )}
        secondaryActionLabel="Don't delete"
        primaryActionLabel='Delete Member'
        primaryActionProps={{
          type: 'submit',
          variant: 'destructive',
          onClick: onConfirm,
          disabled: isMutating || isLoading,
        }}
      />
    </div>
  )
}

export default DeleteStudentDialog