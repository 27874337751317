import React from 'react';
import { ColumnDef } from "@tanstack/react-table"
import { MdArrowUpward } from 'react-icons/md';
import { Button } from 'components/base/button';
import { cn } from 'util/tailwind';
import { Checkbox } from 'components/base/checkbox';

// This type is used to define the shape of our table data.
export type StudentRowItem = {
  studentIdExt: string;
  studentName: string;
  studentRoom: string;
  reason: string;
  exceededMaxInstance?: boolean;
}

const SortableHeader = ({ column, title }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className='px-0 font-bold text-card-foreground'
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
    </Button>
  )
};
export const columns: ColumnDef<StudentRowItem>[] = [
  {
    accessorKey: "studentName",
    size: 80,
    maxSize: 150,
    header: ({ column }) => (
      <SortableHeader column={column} title="Student" />
    ),
  },
  {
    accessorKey: "studentRoom",
    size: 60,
    maxSize: 100,
    header: ({ column }) => (
      <SortableHeader column={column} title="Room" />
    ),
  },
  {
    accessorKey: "reason",
    size: 80,
    maxSize: 120,
    header: ({ column }) => (
      <SortableHeader column={column} title="Reason" />
    ),
  },
]
