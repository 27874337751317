import React from 'react';
import ContentDialog from 'components/dialog/ContentDialog';
import api from 'networking/api';
import useSWRMutation from 'swr/mutation';
import { useToast } from 'components/base/use-toast';

type Props = {
  open: boolean;
  onClose: () => void;
}

/**
 * This component is used to close account
 */
const CloseAccountDialog = (props: Props) => {
  const {
    open,
    onClose,
  } = props;
  const { toast } = useToast();
  const { trigger, error, isMutating } = useSWRMutation('/family/close-account', (key, { arg }: { arg: {} }) => {
    try {
      return api.family.closeAccount().then(async (response) => {
        if (response.ok) {
          onClose();
          toast({
            title: 'Successfully sent email',
            description: 'Please check your email to proceed with closing your account',
          })
        } else {
          throw new Error('Something went wrong, please try again');
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })
  const onConfirm = async () => {
    await trigger({});
  }

  const getTitle = () => {
    return 'Closing your Kindo Account'
  }
  return (
    <div>
      <ContentDialog
        onOpenChange={(open) => {
          if (!open) {
            onClose();
          }
        }}
        open={open}
        title={getTitle()}
        content={(
          <div className='flex flex-col gap-4'>
            <p className='text-sm text-text-body'>
              <p>
                We’ll send you an email containing a link to finalise your account closure. If you have remaining funds in your Wallet, you’ll be able to manage it then. When that step is completed, your account will be permanently closed.
              </p>
              <br />
              <p>
                When you close your Kindo account, all information will be deleted and you will no longer be able to access it. Download or save any information you might need in the future before closing your account.
              </p>
            </p>
            {
              error && (
                <p className='text-sm text-destructive'>
                  {error.message}
                </p>
              )
            }
          </div>

        )}
        secondaryActionLabel="Don't close"
        primaryActionLabel='Close Kindo Account'
        primaryActionProps={{
          type: 'submit',
          variant: 'destructive',
          onClick: onConfirm,
          disabled: isMutating,
        }}
      />
    </div>
  )
}

export default CloseAccountDialog