import React from 'react';
import { Column, ColumnDef } from "@tanstack/react-table"
import { MdArrowUpward } from 'react-icons/md';
import { Button } from '../../../common_component/base/button';
import { cn } from '../../../util/tailwind';
import InfoTooltip from 'components/tooltip/InfoTooltip';

export interface RowItem {
  name: string;
  category: string;
  feeType: string;
  group: string;
  protoPayableId: string;
  metaGroup: string;
  schoolId: string;
  bindPayableId: string;
}
const SortableHeader = ({ column, title, infoTooltip }: { column: Column<RowItem>; title: string; infoTooltip?: string; }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className={cn('px-0 text-card-foreground')}
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
      {
        infoTooltip && (
          <InfoTooltip
            side="bottom"
            className='pl-1'
            messages={[
              <p className='font-sans text-left'>
                {infoTooltip}
              </p>
            ]}
          />
        )
      }
    </Button>
  )
};

export const columns: ColumnDef<RowItem>[] = [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <SortableHeader column={column} title="Payable" />
    )
  },
  {
    accessorKey: "category",
    header: ({ column }) => (
      <SortableHeader column={column} title="Category" />
    ),
    cell: ({ cell }) => (
      <p className='text-card-foreground'>{cell.getValue() ? cell.getValue() : '-'}</p>
    )
  },
  {
    accessorKey: "group",
    header: ({ column }) => (
      <SortableHeader column={column} title="Students" />
    )
  },
]
