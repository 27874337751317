import React from 'react'
import { Control } from 'react-hook-form';
import InputField from 'components/form/InputField';
import { Label } from 'components/base/label';
import { Question } from 'networking/models/order';
import SelectField from 'components/form/SelectField';
import EditorField from 'components/form/EditorField';
import ComboBoxField from 'components/form/ComboBoxField';
import CheckboxField from 'components/form/CheckboxField';
import { MdAttachMoney } from 'react-icons/md';

type Props = {
  control: Control<PayableRolloverFormProps>;
  currentFormValues: PayableRolloverFormProps;
}

export type PayableRolloverFormProps = {
  protoPayableId?: string;
  year: string;
  maxInstances: string;
  pcat: string; // payable category name
  name: string;
  description?: string;
  remarks1: string;
  remarks2: string;
  tax: string;
  gst: string;
  price: string;
  is_voluntary: boolean;
  flexi_price_enabled: boolean;
  is_donation: boolean;
  detail: string;
  questions: Question[];
  product_id: string;
  label: string;
}
const YEARS = Array.from({ length: 8 }, (_, i) => {
  const year = (new Date().getFullYear() + 1 - i).toString();
  return { label: year, value: year };
});
const PayableRolloverForm = (props: Props) => {
  const { control, currentFormValues } = props;
  return (
    <div className='flex flex-col gap-4 text-text-body'>
      <div>
        <Label>
          Payable Name
        </Label>
        <div className='flex flex-row items-start'>
          <SelectField
            name="year"
            control={control}
            className="border-r-0 rounded-r-none focus:border-r-1"
            label=" "
            items={YEARS}
            disabled
          />
          <div className='flex-1'>
            <InputField
              className='w-full rounded-l-none'
              control={control}
              name="label"
              placeholder="Enter a label"
              label=" "
              rules={{
                required: 'Enter a label',
                maxLength: {
                  value: 70,
                  message: '70 characters max'
                },
                validate: {
                  isValidCharacters: (value) => {
                    // Only allow alphanumeric chars, spaces and %!&)(-,/:_@#'$?+."
                    return /^[a-zA-Z0-9\s%!&)(\-,/:_@#'$?+."]+$/.test(value) || 'Only use letters (a-z), numbers (0-9), spaces, and the following symbols % ! & ) ( - , / : _ @ # \' $ ? + . "';
                  }
                }
              }}
            />
          </div>
        </div>
        <p className='mt-1 text-xs text-text-helper'>
          Preview: {currentFormValues.year} {currentFormValues.label}
        </p>
      </div>


      <div className='z-10'>
        <EditorField
          name="description"
          label="Description"
          control={control}
          rules={{
            validate: {
              maxLength: (value) => {
                // Remove HTML tags before counting characters
                const chars = value?.replace(/<[^>]*>?/gm, '') || '';
                if (chars.length > 10000) {
                  return '10000 characters max';
                }
                return true;
              }
            }
          }}
          placeholder="Tell the caregiver about this payable..."
        />
      </div>

      <div className='flex flex-col gap-2 sm:flex-row'>
        <div className='w-full sm:w-[120px]'>
          <InputField
            name="remarks1"
            label="GL Code"
            control={control}
            rules={{
              required: 'Enter a GL code',
              maxLength: {
                value: 1024,
                message: '1024 characters max'
              },
              validate: {
                isValidCharacters: (value) => {
                  // Only allow alphanumeric chars, spaces and %!&)(-,/:_@#'$?+.
                  return /^[a-zA-Z0-9\s%!&)(\-,/:_@#'$?+.]+$/.test(value) || 'Only use letters (a-z), numbers (0-9), spaces, and the following symbols % ! & ) ( - , / : _ @ # \' $ ? + .';
                }
              }
            }}
            placeholder="Enter GL code"
          />
        </div>


        <div className='w-full sm:w-[90px]'>
          <InputField
            name="price"
            label="Total price"
            control={control}
            rules={{
              required: 'Enter a total price',
              validate: {
                isNumber: (value) => {
                  return !isNaN(value) || 'Use numbers only';
                },
                isPositive: (value) => {
                  return value > 0 || 'Price must be greater than 0';
                },
                isTwoDecimalPlacesOrLess: (value) => {
                  return /^\d+(\.\d{1,2})?$/.test(value) || '2 decimal places max';
                }
              }
            }}
            startAdornment={<MdAttachMoney />}
            adornmentVariants={{
              size: 'sm',
              variant: 'plain',
            }}
            type="number"
          />
        </div>
        <div className='flex-1'>
          <ComboBoxField
            label="Tax"
            name="gst"
            control={control}
            options={[
              {
                label: 'Tax exempt',
                value: 'GST exempt',
              },
              {
                label: 'Includes GST (15%)',
                value: 'GST',
              }
            ]}
          />
        </div>
      </div>

      <div className='flex flex-col gap-2'>
        <CheckboxField
          control={control}
          label="Include donation receipt"
          name="is_donation"
          helperText='Donation receipt will be included when payment confirmation is sent.'
        />

        <CheckboxField
          control={control}
          name="is_voluntary"
          label="Payment is voluntary"
          helperText="New features coming soon. Read about it here." // TODO: Add link
        />


        <CheckboxField
          control={control}
          name="flexi_price_enabled"
          label="Part pay allowed"
          helperText="Caregivers can pay in parts towards the total cost."
        />
      </div>
    </div>
  )
}

export default PayableRolloverForm