import api from 'networking/api';
import orderUtils from 'networking/util/order';
import { pathify } from 'networking/util/util';

/**
 * Utility hook to easily get information about services for a school
 */
const useSchoolServices = ({
  schoolId,
  serviceId,
  coreShopsOnly,
  communityShopsOnly,
  undatedServicesOnly,
}: {
  schoolId?: string;
  serviceId?: string;
  coreShopsOnly?: boolean;
  communityShopsOnly?: boolean;
  undatedServicesOnly?: boolean;
}) => {
  const { data: schoolServiceData, isLoading: isLoadingServices, mutate } = api.misc.useSchoolData(pathify(schoolId || ''));
  const feesAndDonationsService = schoolServiceData?.services?.find((s) => s.id === 'fees_and_donations')
  const currentService = schoolServiceData?.services?.find((s) => s.id === serviceId);
  const services = schoolServiceData?.services
    .filter((s) => s.current)
    .filter((s) => !coreShopsOnly || (s.shop_fronts === null || (s.shop_fronts && s.shop_fronts.includes('core'))))
    .filter((s) => !communityShopsOnly || (s.shop_fronts === null || (s.shop_fronts && s.shop_fronts.includes('community'))))
    .filter((s) => !undatedServicesOnly || orderUtils.isServiceUndated(s));


  const ezlunchServices = services?.filter((s) => s.brand_name === 'ezlunch') ?? [];
  const servicesNotFees = services?.filter((s) => s.id !== 'fees_and_donations');
  const kindoServices = servicesNotFees?.filter((service) => service.brand_name === 'kindo') ?? [];

  const getService = (serviceId: string) => {
    return services?.find((s) => s.id === serviceId);
  }
  return {
    schoolService: schoolServiceData,
    services,
    feesAndDonationsService,
    ezlunchServices,
    kindoServices,
    isLoadingServices,
    currentService,
    getService,
    refreshSchoolService: mutate,
  }
}

export default useSchoolServices;