import React from 'react'

import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "../../util/tailwind"
import { range } from 'lodash'

const spinnerVariants = cva(
  "h-[80px] w-[80px] relative",
  {
    variants: {
      size: {
        xs: 'h-[20px] w-[20px]',
        md: "h-[80px] w-[80px]",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
)
type Props = {
  className?: string;
} & VariantProps<typeof spinnerVariants>

const Spinner = ({ size = "md", className }: Props) => {
  return (
    <div
      className={cn(spinnerVariants({ size }), className)}
    >
      {
        range(12).map((i) => (
          <div key={i} className={cn({
            'after:content-[" "] after:block after:absolute after:top-[3px] after:left-[37px] after:w-[6px] after:h-[16px] after:rounded-lg after:bg-primary': size === 'md',
            'after:content-[" "] after:block after:absolute after:top-[1px] after:left-[8px] after:w-[2px] after:h-[4px] after:rounded-lg after:bg-primary': size === 'xs',
            'animate-spinner origin-[40px_40px]': size === 'md',
            'animate-spinner origin-[10px_10px]': size === 'xs',
            'delay-0 rotate-0': i === 0,
            'delay-100 rotate-[30deg]': i === 1,
            'delay-200 rotate-[60deg]': i === 2,
            'delay-300 rotate-[90deg]': i === 3,
            'delay-400 rotate-[120deg]': i === 4,
            'delay-500 rotate-[150deg]': i === 5,
            'delay-600 rotate-[180deg]': i === 6,
            'delay-700 rotate-[210deg]': i === 7,
            'delay-800 rotate-[240deg]': i === 8,
            'delay-900 rotate-[270deg]': i === 9,
            'delay-1000 rotate-[300deg]': i === 10,
            'delay-1100 rotate-[330deg] ': i === 11,
          })} />
        ))
      }
    </div>
  )
}

export { Spinner }