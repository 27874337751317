import React from 'react'
import { observer } from 'mobx-react';
import { Button } from '../../common_component/base/button'
import { useStore } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { baseApi, baseUrl } from 'networking/constants';
import { deleteCookie } from "../../util/util";
import { platformEnv } from 'networking/constants';
import api from 'networking/api';

type Props = {
  isError?: boolean;
  context?: string;
}

const CompletionCTA = observer(({ isError, context }: Props) => {
  const store = useStore();
  const navigate = useNavigate();

  const logout = () => {
    if (store.shop.isCommunityShop) {
      deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
      store.family.setIsAuthenticated(false);
      navigate(`/app/community-shop/${store.family.currentSchool}`)
    } else {
      window.location.href = `${baseApi}/logout`;
    }
  }

  return (
    <div className='flex flex-row-reverse gap-2 pt-6'>
      {context !== 'topup' && !store.family.isRenderingFromNativeApp &&
        <Button size='sm' variant='outline' onClick={logout}>
          Logout
        </Button>
      }
      {isError && context !== 'topup' && context !== 'pp' ?
        <Button size='sm' onClick={() => {
          navigate('/app/checkout');
        }}>
          Return to cart
        </Button>
        :
        <Button size='sm' onClick={() => {
          navigate('/app/completion-loading');
        }}>
          Return to shop
        </Button>
      }
    </div>
  )
});

export default CompletionCTA;
