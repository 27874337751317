import React from "react";
import { Popover, PopoverTrigger, PopoverContent } from "../../../common_component/base/popover";
import { observer } from "mobx-react";
import { MdOutlineShoppingCart } from "react-icons/md";
import { useStore } from "../../../store/store";
import analytics from "../../../util/googleAnalytics";
import { formatCentsPriceForDisplay } from "../../../util/string_util";
import { cn } from "../../../util/tailwind";
import MiniCart from "./MiniCart";
import { Button } from "../../../common_component/base/button";

type Props = {
  orderCount?: number;
}
const CheckoutButton = observer((props: Props) => {
  const { orderCount } = props;
  const store = useStore();
  const [cartOpened, setCartOpened] = React.useState(false);

  // Order
  const temporaryCartPriceInCents = store.shop.temporaryCartPriceInCents;
  const temporaryCartItemCount = store.shop.temporaryCartItemCount;
  const [initialItemCount, setInitialItemCount] = React.useState(temporaryCartItemCount);

  React.useEffect(() => {
    if (initialItemCount !== temporaryCartItemCount) {
      setTimeout(() => {
        setInitialItemCount(temporaryCartItemCount);
      }, 2000)
    }
  }, [temporaryCartItemCount])
  return (
    <Popover open={cartOpened} onOpenChange={(open) => {
      setCartOpened(open);
      if (open) {
        const allCartItems = store.shop.allCartItems;
        analytics.viewCart({
          items: allCartItems.map((item) => ({
            productId: item.productId,
            priceInCents: item.priceInCentsWithOptions,
            productName: item.name,
            optionCount: item.options?.length,
            quantity: item.quantity,
            brandName: item.isEzlunchService ? 'ezlunch' : 'kindo',
            supplierId: item.supplierId,
            choice: item.choice,
            options: item.options,
          })),
        })
      }
    }}>
      <PopoverTrigger>
        <div>
          <Button name="show-cart" className='relative'>
            {formatCentsPriceForDisplay(temporaryCartPriceInCents)} <MdOutlineShoppingCart className="ml-2 text-lg" />
            {
              temporaryCartItemCount && temporaryCartItemCount > 0 ? (
                <span className="absolute -top-1 -right-2">
                  <span className={cn("flex items-center justify-center w-5 h-5 text-xs text-white rounded-full bg-border-danger", {
                    'animate-[bouncey_0.5s_ease-in-out_infinite]': temporaryCartItemCount !== initialItemCount,
                    'animate-none': temporaryCartItemCount === initialItemCount,
                  })}>
                    {temporaryCartItemCount}
                  </span>
                </span>
              )
                : null
            }            {
              orderCount && orderCount > 0 ? (
                <span className="absolute -bottom-2 -right-2">
                  <span className="flex items-center justify-center w-5 h-5 text-xs text-white rounded-full bg-border-info">
                    {orderCount}
                  </span>
                </span>
              )
                : null
            }
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent align="end" className={"p-0 bg-white shadow-lg"}>
        <MiniCart />
      </PopoverContent>
    </Popover >
  )
});

export default CheckoutButton;