import React from 'react';
import { useForm } from 'react-hook-form';

import { Button } from '../../../common_component/base/button';
import ComboBoxField from '../../../common_component/form/ComboBoxField';
import api from 'networking/api';
import { useToast } from '../../../common_component/base/use-toast';

type FormData = {
  studentIdExt: string;
}
type Props = {
  schoolId: string;
  groupId: string;
  excludeStudentIds?: string[];
}

const AddStudentsDialog = (props: Props) => {
  const {
    schoolId,
    groupId,
    excludeStudentIds,
  } = props;

  const { toast } = useToast();
  const { control, handleSubmit, reset, watch, formState: { isSubmitting } } = useForm<FormData>({
    defaultValues: {
    },
  });

  const selectedStudentIdExt = watch('studentIdExt');

  const { data: studentsData, isLoading: isLoadingStudents } = api.admin.useStudentRollMap(schoolId);
  const { data: previewData, isLoading: isLoadingPreview, error, mutate: refetchPreview } = api.admin.useStudentGroupAddPreview({
    schoolId,
    groupId,
    studentIdExt: selectedStudentIdExt,
  })
  const { mutate: refetchStudents } = api.admin.useStudentsInGroups(schoolId, groupId)

  const onSubmit = async (values: FormData) => {
    if (values.studentIdExt) {
      const response = await api.admin.addStudentToGroup({
        schoolId,
        groupId,
        studentIdExt: values.studentIdExt,
      });
      if (response.ok) {
        toast({
          title: 'Student added to group',
          duration: 3000,
        });
        reset({
          studentIdExt: '',
        })
        refetchStudents();
      } else {
        toast({
          title: 'Failed to add student to group',
          duration: 3000,
        })
        refetchPreview();
      }
    }
  };


  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className='flex items-start gap-4'>
        <div className='flex flex-col gap-4 text-text-body'>
          <ComboBoxField
            control={control}
            isLoading={isLoadingStudents}
            name="studentIdExt"
            label="Student"
            placeholder="Select a student"
            options={(studentsData?.rollstuds || [])
              .filter((roll) => !excludeStudentIds?.includes(roll.student_id_ext))
              .map((roll) => ({
                label: `${roll.first_name} ${roll.last_name} (${roll.room && roll.room !== 'UNKNOWN' ? roll.room : 'Room not set'})`,
                value: roll.student_id_ext,
              }))}
          />
          {
            !error && previewData && previewData.applied_binds.length > 0 && (
              <div>
                <h3 className='text-sm font-bold font-museo'>Student will receive following payables:</h3>
                <div className='flex flex-col gap-1'>
                  {previewData.applied_binds.map((bind) => (
                    <p key={bind.bind_payable_id} className='text-sm'>
                      {bind.proto_payable_name}
                    </p>
                  ))}
                </div>
              </div>
            )
          }
        </div>
        <div className='mt-5'>
          <Button size="sm" type="submit" disabled={isSubmitting || !!error || !selectedStudentIdExt}>Add Student</Button>
        </div>
      </div>
      {
        error && (
          <div className='mt-3 text-sm'>
            Student already belongs to this group.
          </div>
        )
      }
    </form>
  )
}

export default AddStudentsDialog