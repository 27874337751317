import api from 'networking/api';
import { Link } from 'react-router-dom'
import React from 'react'
import { useStore } from '../../../store/store';
import { Card, CardContent, CardHeader, CardTitle, CardFooter } from '../../../common_component/base/card'
import { Button } from '../../../common_component/base/button';
import _ from 'lodash';
import Image from '../../../common_component/Image';
import { cn } from '../../../util/tailwind';
import DefaultServiceIcon from '../../../assets/images/default-service-icon.jpg';
import { Service } from 'networking/models/family';
import { pathify } from "../../../util/util";
import orderUtils from 'networking/util/order';
import { getServiceImageDirectory, productImageDirectory } from 'networking/constants';
import { useMediaQuery } from '../../../util/hooks/useMediaQuery'
import { Skeleton } from '../../../common_component/base/skeleton'

type Props = {}

export const MenuCategoryPicture = ({ service, maxItems = 2 }: { service: Service; maxItems?: number }) => {
  const { data } = api.order.useFullMenu(service);
  const items = (data?.items || []).sort((a, b) => a.promote - b.promote);
  const isMobile = useMediaQuery('(max-width: 640px)');
  const { data: productImageData, isLoading: isLoadingProductImages } = api.order.useProductImages(service.id, items, maxItems);

  return <>
    {
      _.times(isMobile ? 2 : maxItems).map((index) => {
        const item = productImageData?.[index];
        return (
          <>
            {
              (isLoadingProductImages) && (
                <Skeleton key={`skeleton-${index}`} className={cn('w-full h-[150px] sm:h-[180px] rounded-none', {
                  'rounded-tr-lg rounded-br-lg': index === (isMobile ? 2 : maxItems) - 1,
                  'rounded-tl-lg rounded-bl-lg': index === 0,
                })} />
              )
            }
            {
              item && (
                <div className='flex flex-col items-center col-auto gap-2 p-4 border-2 rounded-lg shadow-sm border-background-light'>
                  <div className=''>
                    <img
                      key={item.permanent_id}
                      src={`${productImageDirectory}/${item.supplierId}/${item.picture}`}
                      className={cn("w-full h-full max-h-[90px] sm:max-h-[120px] object-contain first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg", {
                        'hidden': isLoadingProductImages
                      })}
                      alt={''}
                    />
                    <p className='pt-2 text-center font-museo text-primary break-keep'>{item.product}</p>
                  </div>
                </div>
              )
            }
          </>
        )
      })
    }
  </>
}

const MultipleServiceCommunity = (props: Props) => {
  const { family: { currentSchool } } = useStore();
  const pathifiedSchoolId = currentSchool ? pathify(currentSchool) : ''
  const { data: schoolData } = api.misc.useSchoolData(pathifiedSchoolId);

  const services = (schoolData?.services || []).filter(
    (s) => s.shop_fronts?.includes('community') && orderUtils.isServiceUndated(s)
  )
  return (
    <div className='grid h-auto grid-cols-1 gap-6 sm:grid-cols-2 sm:h-full'>
      {services.map((service, index) => (
        <Card className='grid-flow-col border-none rounded-lg sm:rounded-3xl'>
          <CardHeader className='flex flex-row items-center justify-between'>
            <CardTitle className='text-2xl font-normal font-museo text-primary'>{service.title}</CardTitle>
            {schoolData &&
              <Image
                alt=''
                key={service?.icon}
                src={`${getServiceImageDirectory({ schoolPath: schoolData.path, serviceId: service?.id })}${service?.icon}`}
                className="h-[45px] w-[45px] p-2 border-2 rounded-lg border-background-light m-0"
                fallbackSrcs={[DefaultServiceIcon]}
              />
            }
          </CardHeader>
          <CardContent>
            <div className='grid h-full grid-cols-2 gap-4'>
              <MenuCategoryPicture key={service.id} service={service} maxItems={2} />
            </div>
          </CardContent>
          <CardFooter className='justify-end'>
            <Link to={`/app/community-shop/${currentSchool}/${service.brand_name}/${service.id}`}>
              <Button className='font-museo'>Visit shop</Button>
            </Link>
          </CardFooter>
        </Card>
      ))}
    </div>
  )
}

export default MultipleServiceCommunity