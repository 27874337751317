import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableHeader,
  TableRow,
} from "../../common_component/base/table";
import api from 'networking/api';
import { Spinner } from '../../common_component/base/spinner';
import { MessageDispatches } from 'networking/models/customer';
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../common_component/base/dialog'
import { Button } from "../../common_component/base/button";

type Props = {
  familyId: string
}

const EmailsTable: React.FC<Props> = ({ familyId }) => {
  const [dispatchId, setDispatchId] = React.useState('');
  const [showMessage, setShowMessage] = React.useState(false);

  const { data, isLoading } = api.customer.useMessages(familyId);
  const { data: message, isLoading: isMessageLoading } = api.customer.useMessageDispatch({ familyId, dispatchId });

  const handleRowClick = (id: string) => {
    setDispatchId(id);
    setShowMessage(true);
  };

  return (
    <div>
      {isLoading ?
        <div className='flex flex-col items-center justify-center h-full gap-5 my-12'>
          <Spinner size='md' />
          <p className='text-xl font-museo'>Loading...</p>
        </div>
        :
        data?.dispatches.length !== 0 ?
          <Table>
            <TableHeader className="font-bold font-museo">
              <TableRow>
                <TableHead>Sent at</TableHead>
                <TableHead>Email address</TableHead>
                <TableHead>Category</TableHead>
                <TableHead>Subject</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {
                data?.dispatches
                  .sort((a: MessageDispatches, b: MessageDispatches) => {
                    return new Date(b.sent_at).getTime() - new Date(a.sent_at).getTime();
                  })
                  .map((dispatch: MessageDispatches) => (
                    <TableRow
                      className='cursor-pointer text-card-foreground'
                      key={dispatch.msg_id}
                      onClick={() => handleRowClick(dispatch.dispatch_id)}
                    >
                      <TableCell>{dispatch.sent_at}</TableCell>
                      <TableCell>{dispatch.recipient_addr}</TableCell>
                      <TableCell>{dispatch.category}</TableCell>
                      <TableCell>{dispatch.subject}</TableCell>
                    </TableRow>
                  ))
              }
            </TableBody>
          </Table>
          :
          <TableRow className='text-card-foreground'>
            <TableCell className="h-24 text-center">
              No results.
            </TableCell>
          </TableRow>
      }
      <Dialog open={showMessage}>
        <DialogContent className='w-auto sm:min-w-[45vw]'>
          <DialogHeader>
            <DialogTitle>Email</DialogTitle>
          </DialogHeader>
          {isMessageLoading ?
            <div className='flex flex-col items-center justify-center h-full gap-5 my-12'>
              <Spinner size='md' />
              <p className='text-xl font-museo'>Loading...</p>
            </div>
            :
            message && <div className='text-card-foreground' dangerouslySetInnerHTML={{ __html: message.dispatches[0].body }} />
          }
          <DialogFooter>
            <Button type="button" variant="outline" onClick={() => setShowMessage(false)}>Close</Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default EmailsTable;
