import React from 'react';
import { Column, ColumnDef } from "@tanstack/react-table"
import { Checkbox } from '../../../common_component/base/checkbox';
import { MdArrowUpward } from 'react-icons/md';
import { Button } from '../../../common_component/base/button';
import { cn } from '../../../util/tailwind';
import InfoTooltip from 'components/tooltip/InfoTooltip';
import { PayableStatus, PayableStatusLabels } from 'networking/models/payable';
import { Skeleton } from 'components/base/skeleton';

export interface RowItem {
  permanentId: string;
  name: string;
  price: string;
  year: string;
  status: PayableStatus;
  applied: number;
  paymentStatus: string;
  protoPayableId?: string;
  payableCategory?: string;
  productId?: string;
}
const SortableHeader = ({ column, title, infoTooltip }: { column: Column<RowItem>; title: string; infoTooltip?: string; }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className={cn('px-0 text-card-foreground')}
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
      {
        infoTooltip && (
          <InfoTooltip
            side="bottom"
            className='pl-1'
            messages={[
              <p className='font-sans text-left'>
                {infoTooltip}
              </p>
            ]}
          />
        )
      }
    </Button>
  )
};

const getStatusLabel = (status: PayableStatus) => {
  switch (status) {
    case PayableStatus.ACTIVE:
    case PayableStatus.INACTIVE:
    case PayableStatus.DRAFT:
      return PayableStatusLabels[status];
    default:
      return 'Unknown';
  }
}
export const columns: ColumnDef<RowItem>[] = [
  {
    id: "select",
    header: ({ table }) => (
      <Checkbox
        indeterminate={!table.getIsAllRowsSelected()}
        checked={table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()}
        onCheckedChange={(value) => {
          if (value === 'indeterminate') {
            return;
          }
          table.toggleAllRowsSelected(value)
        }}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={row.getToggleSelectedHandler()}
        aria-label="Select row"
      />
    ),
    maxSize: 48,
    size: 48,
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: "permanentId",
    header: ({ column }) => (
      <SortableHeader column={column} title="id" />
    ),
    cell: ({ cell }) => (
      <p className='max-w-[37px] overflow-hidden'>{cell.getValue()}</p>
    ),
    maxSize: 50,
    size: 50,
    enableHiding: false,
  },
  {
    accessorKey: "protoPayableId",
    header: ({ column }) => (
      <SortableHeader column={column} title="Proto" />
    ),
    cell: ({ cell }) => (
      <p className='max-w-[37px] overflow-hidden'>{cell.getValue()}</p>
    ),
    maxSize: 50,
    size: 50,
    enableHiding: false,
  },
  {
    accessorKey: "year",
    header: ({ column }) => (
      <SortableHeader column={column} title="Year" />
    ),
    maxSize: 60,
  },
  {
    accessorKey: "label",
    header: ({ column }) => (
      <SortableHeader column={column} title="Label" />
    ),
    maxSize: 300,
    size: 300,
  },
  {
    accessorKey: "payableCategory",
    header: ({ column }) => (
      <SortableHeader column={column} title="Category" />
    ),
    meta: {
      columnTitle: 'Category',
    },
  },
  {
    accessorKey: "price",
    header: ({ column }) => (
      <SortableHeader column={column} title="Price" />
    ),
    meta: {
      textAlign: 'right',
    },
    maxSize: 40,
  },
  {
    accessorKey: "glCode",
    header: ({ column }) => (
      <SortableHeader column={column} title="GL Code" />
    ),
    meta: {
      textAlign: 'right',
      columnTitle: 'GL Code',
    },
    maxSize: 80,
    size: 80,
  },
  {
    accessorKey: "status",
    header: ({ column }) => (
      <SortableHeader column={column} title="Status" />
    ),
    cell: ({ row }) => (
      <span className={cn('text-sm', {
      })}>
        {getStatusLabel(row.original.status)}
      </span>
    ),
    maxSize: 40,
  },
  {
    accessorKey: "paymentStatus",
    header: ({ column }) => (
      <SortableHeader column={column} title="Payment request" />
    ),
    meta: {
      columnTitle: 'Payment request',
    },
    cell: ({ row }) => (
      <span className={cn('text-sm', {
      })}>
        {row.original.paymentStatus === 'loading' ? <Skeleton className='w-[56px] h-5' /> : row.original.paymentStatus}
      </span>
    ),
    maxSize: 100,
  },
]
