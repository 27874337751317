import React from 'react'
import { privacyTerms } from 'networking/constants';

const Footer = () => {
  return (
    <div className='w-full h-[80px] sm:h-[60px] py-4 bg-gradient-to-b from-white/10 via-white/80 to-white/90 z-20'>
      <div className='flex justify-center w-auto gap-8 pb-4 text-sm underline text-foreground sm:hidden'>
        <a href='https://support.mykindo.co.nz/' target='_blank' rel="noreferrer">Help Centre</a>
        <a href={privacyTerms} target='_blank' rel="noreferrer">Terms & Conditions</a>
      </div>
      <div className='flex items-center justify-between px-3 sm:px-10'>
        <div className='w-auto sm:w-1/3'>
          <span className='text-lg font-museo'>
            <span className='text-primary'>my</span>
            <span className='text-secondary'>kindo</span>
          </span>
        </div>
        <div className='hidden w-1/3 gap-8 text-sm underline text-foreground sm:flex'>
          <a href='https://support.mykindo.co.nz/' target='_blank' rel="noreferrer">Help Centre</a>
          <a href={privacyTerms} target='_blank' rel="noreferrer">Terms & Conditions</a>
        </div>
        <div>
          <p className='text-sm text-text-helper'>&copy; The Growth Collective Ltd 2010-{new Date().getFullYear()}</p>
        </div>
      </div>
    </div>
  )
}

export default Footer