import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../common_component/base/dialog";
import { Button } from "../../common_component/base/button";
import { Input } from '../../common_component/base/input';
import api from 'networking/api';
import { useDebounce } from '../../util/hooks/useDebounce';
import { MdAddLocation } from "react-icons/md";

export interface AddressSelectorResultsAddress {
  full_address: string
  id: number
}

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  orderId: string;
  isAddressAvailable: boolean;
};

const AddressSelector = ({ open, onOpenChange, orderId, isAddressAvailable }: Props) => {
  const [searchText, setSearchText] = React.useState('');

  const debouncedSearch = useDebounce(searchText, 1000);
  const { mutate } = api.order.useOrderItems();
  const { data, isLoading } = api.order.useAddressSelectorList(debouncedSearch);

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setSearchText(value);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline" size="xs">
          {isAddressAvailable ? 'Edit' : 'Add delivery address'}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Select Delivery Address</DialogTitle>
        </DialogHeader>
        <div>
          <Input
            placeholder="Delivery address"
            value={searchText}
            onChange={handleInputChange}
          />
        </div>
        <div>
          {(searchText.length < 5 && searchText.length !== 0) ? (
            <div>Please enter a few more characters to begin searching.</div>
          ) : undefined}

          {
            !isLoading && data && (
              data.street_addresses.map((address: any) => (
                <div
                  onClick={() => {
                    api.order.addOrderAddress(orderId, address).then((res) => {
                      if (res) {
                        mutate()
                        onOpenChange(false);
                      }
                    })
                  }}
                  className='flex justify-between hover:text-card-foreground hover:cursor-pointer' key={address.id}>
                  <p className='pb-2'>{address.full_address}</p>
                  <MdAddLocation />
                </div>
              )
              )
            )
          }
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default AddressSelector