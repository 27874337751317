import React from 'react';
import Helmet from 'react-helmet'
import { observer } from "mobx-react";
import ShopLayout from "../../common_component/layout/ShopLayout";
import CommunityShopLayout from "../../common_component/layout/CommunityShopLayout";
import { Button } from "../../common_component/base/button";
import { MdInfoOutline, MdWarning, MdCameraAlt } from "react-icons/md";
import _ from 'lodash';
import { useForm } from "react-hook-form";
import TopUp from "../topup/TopUp";
import InputField from "../../common_component/form/InputField";
import orderUtils from 'networking/util/order';
import api from 'networking/api';
import { formatCentsPriceForDisplay } from '../../util/string_util';
import AddressSelector from './AddressSelector';
import { useToast } from '../../common_component/base/use-toast';
import { productImageDirectory } from 'networking/constants';
import Image from '../../common_component/Image';
import { cn } from '../../util/tailwind';
import { Popover, PopoverTrigger, PopoverContent } from '../../common_component/base/popover';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../../common_component/base/accordion"
import { Dialog, DialogContent, DialogHeader, DialogFooter } from "../../common_component/base/dialog"
import { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogTitle, AlertDialogFooter, AlertDialogAction, AlertDialogCancel } from '../../common_component/base/alert-dialog'
import analytics from '../../util/googleAnalytics';
import { CheckoutItem, CheckoutProduct } from 'networking/models/order';
import { useStore } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import CartImage from '../../assets/images/cart.png';
import CheckoutOffers from './CheckoutOffers'
import KindoWell from '../../common_component/KindoWell';
import { MdOutlineNotifications } from "react-icons/md";
import { Skeleton } from '../../common_component/base/skeleton';

type FormData = {
  token: string;
};

const CheckoutPage: React.FC = observer(() => {
  const store = useStore();
  const navigate = useNavigate();

  const isCommunityShop = store.shop.isCommunityShop;

  const { control, handleSubmit, watch, formState: { isSubmitting } } = useForm({
    defaultValues: {
      token: '',
    },
    reValidateMode: 'onBlur',
  });

  const [voucherPrice, setVoucherPrice] = React.useState(null)
  const [voucherToken, setVoucherToken] = React.useState('')
  const [voucherError, setVoucherError] = React.useState('')
  const [staleOrderType, setStaleOrderType] = React.useState<'passed-cut-off' | 'not-found'>('passed-cut-off');

  const [isCompleteOrderLoading, setCompleteOrderLoading] = React.useState(false)

  const { toast } = useToast();

  const onSubmitVoucherCode = (values: FormData) => {
    let data = {
      token: values.token,
      rtype: 'voucher_query'
    }

    api.misc.addVoucher(data)
      .then((res) => {
        if (res) {
          return res.json();
        }
      }).then((voucher) => {
        if (voucher.rtype !== 'error') {
          setVoucherPrice(voucher.remaining_value_in_cents)
          setVoucherToken(voucher.token)
          toast({
            description: (
              <>
                <p className='font-semibold text-warning'>Voucher applied</p>
                <p className='mt-2 font-semibold'>Voucher discount: <span className='text-warning'>{formatCentsPriceForDisplay(voucher.remaining_value_in_cents)}</span></p>
              </>
            ),
            duration: 10000,
          })
        } else {
          if (voucher.name === 'InvalidParameterException') {
            setVoucherError('Enter a valid voucher code')
          } else {
            setVoucherError(voucher.message)
          }
        }
      })
  }

  const { data, isLoading, mutate } = api.order.useOrderItems();

  const orderIds = data?.items.map((order: { order_id: any; }) => order.order_id)

  const { data: familyData, isLoading: isLoadingFamily, mutate: mutateFamily } = api.family.useFamily();

  const subTotal = _.sumBy(data?.items, (item) => item.total_price_in_cents) - voucherPrice
  const totalPrice = subTotal > 0 ? subTotal : 0;
  const totalGST = _.sumBy(data?.items, (item) => item.total_gst_in_cents)

  const isBalanceNotEnough = familyData ? familyData?.balance_in_cents < totalPrice : true;

  const itemsGroupedByDeliveryDate = React.useMemo(() => {
    return orderUtils.getOrderGroupedByDeliveryDate(data?.items);
  }, [data]);

  const getAllProducts = () => {
    return data?.items.reduce<CheckoutProduct[]>((acc, order) => {
      return [
        ...acc,
        ...order.detail.students.flatMap((student) => (
          student.products
        ))
      ]
    }, []);
  }
  const onPurchase = () => {
    const allProducts = getAllProducts();
    if (allProducts) {
      analytics.purchase({
        transactionId: data?.items[0].order_id,
        coupon: voucherToken,
        listName: 'Checkout',
        value: totalPrice,
        items: allProducts.map((product) => ({
          productId: product.productId,
          productName: product.product,
          options: product.options,
          priceInCents: (product.payable && product.flexi_price_nominated_in_cents) || product.quoted_unit_price_in_cents,
          quantity: product.quantity,
          choice: product.choice,
        })),
        tax: totalGST,
      })
    }

    analytics.placeOrder({ initiatedFrom: 'Complete order', priceInCents: totalPrice });
  }

  const { data: productData, isLoading: isLoadingProductOffers } = api.order.useProductOffers(data?.offers);

  const hasDeliveryAddress = data?.items.some(item => item.delivery_address_type === "streetaddress");
  const itemRequiresDeliveryAddress = data?.items.find(item => item.delivery_address_type === "streetaddress");

  const [addressSelectorOpened, setAddressSelectorOpened] = React.useState(false);

  const isOrderValidForBX = orderUtils.getLateForBxOrders(data?.items).length === 0

  const [showStaleOrders, setShowStaleOrders] = React.useState(false);
  const [staleOrders, setStaleOrders] = React.useState<CheckoutItem[]>([]);

  const schoolIds = orderUtils.getOrderSchoolIds(data?.items);

  const isAllMiddletonGrange = orderUtils.areAllSchoolIdsMiddleton(schoolIds);

  React.useEffect(() => {
    // Figure out if any existing orders are stale (ie. past their delivery dates!)
    if (!isLoading && data && !isLoadingFamily) {
      const stale = data.items.filter((order) => {
        const schoolIdForOrder = order.detail.school_id;
        const serviceIdForOrder = order.detail.service_id;
        const service = familyData?.school_services.find((service) => service.school_id === schoolIdForOrder)?.services.find((service) => service.id === serviceIdForOrder);
        if (service) {
          return orderUtils.isOrderStale(order, service);
        };
        return false;
      })
      if (stale.length !== 0) {
        setStaleOrderType('passed-cut-off');
        setStaleOrders(stale);
        setShowStaleOrders(true);
      } else {
        setStaleOrders([]);
        setShowStaleOrders(false);
      }
    }
  }, [data, isLoading, isLoadingFamily]);


  const handleRemoveOrder = (student) => () => {
    api.order.removeOrder(student.orderId).then((res) => {
      if (res.ok) {
        mutate();
        toast({
          title: 'Removed:',
          description: (
            <div className='mt-2 font-semibold text-warning'>
              {student.products.map((product) => (
                <p className='mt-2 font-semibold text-warning'>
                  {product.product}
                </p>
              ))}
            </div>
          )
        });
        analytics.removeOrder({
          orderId: student.orderId,
          itemCount: student.products.length,
          listName: 'Checkout',
          orderPriceInCents: student.totalPrice,
        })
      }
    })
  }

  const removeAllStaleOrders = async () => {
    const requests = staleOrders.map((order) => (
      api.order.removeOrder(order.order_id)
    ))
    const responses = await Promise.allSettled(requests);
    if (_.some(responses, (r) => r.status === 'fulfilled')) {
      mutate();
      toast({
        title: 'Invalid orders removed!'
      })
    }
  }

  const removeOrderAlertContent = (student) => (
    <AlertDialogContent>
      <AlertDialogTitle>Remove this item?</AlertDialogTitle>
      <div className='flex flex-col gap-4'>
        <p>Are you sure you want to remove your order?</p>
      </div>
      <AlertDialogFooter>
        <AlertDialogCancel>Cancel</AlertDialogCancel>
        <AlertDialogAction onClick={handleRemoveOrder(student)}>
          Remove
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  )

  const handleTopupError = (error: any) => {
    if (error.name === 'EntityNotFoundException') {
      const entityId = error.id;
      const staleOrders = data?.items.filter((order) => {
        return order.detail.students.some((student) => student.products.some((product) => product.productId === entityId || product.payable?.payable_id === entityId));
      })
      if (staleOrders) {
        setStaleOrderType('not-found');
        setStaleOrders(staleOrders);
        setShowStaleOrders(true);
      }
    } else if (error.message) {
      alert(error.message)
    }
  }
  const renderStaleOrderTitle = () => {
    if (staleOrderType === 'passed-cut-off') {
      return 'Orders passed cut-off time'
    } else {
      return 'Item no longer exists'
    }
  }

  const renderStaleOrderDescription = () => {
    if (staleOrderType === 'passed-cut-off') {
      return 'These orders in your cart are now outside the cut-off time and can\'t be ordered. Please remove them to proceed.'
    } else {
      return 'The following orders have items that are no longer available. Please remove them from your cart.'
    }
  }

  const homeLink = isCommunityShop ? `/app/community-shop/${store.family.currentSchool}` : `/app/shop`

  const CheckoutPageContent = (
    <div>
      <div className="flex flex-col gap-0 mt-0 sm:gap-4 sm:mt-8 sm:pb-6">
        <h2 className="pb-2 text-3xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
          {(!isCommunityShop) && 'Top up &'} Checkout
        </h2>
        {isLoadingFamily ?
          <></>
          :
          <>
            {
              !!familyData?.saved_order_counts.offline_tdp &&
              <div>
                <KindoWell
                  className='mb-2 text-xs'
                  messages={[`You have ${familyData?.saved_order_counts.offline_tdp} order${(familyData?.saved_order_counts.offline_tdp) && 's'} awaiting Internet Banking transfer. If you wish to cancel, you still have time.`]}
                  icon={<MdOutlineNotifications />}
                  action={{
                    label: 'Review your orders',
                    onClick: () => {
                      navigate('/app/pending-orders');
                    }
                  }}
                />
              </div>
            }
          </>
        }
        <Dialog open={showStaleOrders}>
          <DialogContent hideCloseButton>
            <DialogHeader>
              <p className='text-lg text-primary font-museo'>
                {renderStaleOrderTitle()}
              </p>
            </DialogHeader>
            <p className='text-sm text-card-foreground'>
              {renderStaleOrderDescription()}
            </p>
            {staleOrders.map((order) => (
              order.detail.students.map((student) => (
                student.products.map((product) => (
                  <div className='flex items-center gap-1 mb-2'>
                    <p className='w-2/3 text-sm'>{product.product}</p>
                    <p className='w-1/3 text-sm text-card-foreground'>Quantity: {product.quantity}</p>
                  </div>
                ))
              ))
            ))}
            <DialogFooter>
              <Button disabled={isSubmitting} onClick={handleSubmit(removeAllStaleOrders)}>
                Remove all
              </Button>
            </DialogFooter>
          </DialogContent>
        </Dialog>
        <div className="overflow-hidden border-2 rounded-3xl border-border-primary">
          {/* Checkout offers - smaller screen */}
          {!isLoadingProductOffers && productData ?
            <div className='block sm:hidden'><CheckoutOffers /></div>
            : undefined
          }
          <div className="bg-white border-b-2">
            <div>
              <div className="items-center hidden grid-cols-1 gap-1 p-4 sm:gap-6 md:gap-8 sm:grid sm:grid-cols-7 bg-background">
                <div></div>
                <div className="sm:col-span-2"></div>
                <div></div>
                <div className='text-sm font-semibold text-center text-card-foreground'>Quantity</div>
                <div></div>
                <div className='text-sm font-semibold text-right text-card-foreground'>Item total</div>
              </div>
              <div>
                {Object.keys(itemsGroupedByDeliveryDate).map((deliveryDate) => (
                  <div key={deliveryDate}>
                    {deliveryDate ?
                      <div className="bg-gradient-to-l from-white/0 via-background/0 to-background-light/100 sm:to-background/100  py-3 w-full sm:w-57%] mb-0 sm:mb-2">
                        <p className="pl-2 text-base sm:pl-4 text-card-foreground sm:text-xl font-museo">{deliveryDate}</p>
                      </div>
                      :
                      <div className='hidden sm:block'></div>
                    }
                    {itemsGroupedByDeliveryDate[deliveryDate].students.map((student) => (
                      <>
                        <div className="grid grid-cols-1 gap-2 p-2 pb-4 sm:gap-6 md:gap-8 sm:grid-cols-7 sm:p-4">
                          <div className='flex flex-wrap justify-between'>
                            <div className='flex items-center h-[44px] gap-1 pb-2 sm:pb-0'>
                              <p className='text-sm font-museo'>{student.firstName}</p>
                              <Popover>
                                <PopoverTrigger asChild>
                                  <Button variant='link' size='sm' className='h-[16px] p-0 ml-2 text-sm'><MdInfoOutline /></Button>
                                </PopoverTrigger>
                                <PopoverContent align="start" side="right" className='w-[210px] min-h-[70px]'>
                                  <div className='text-sm font-bold text-primary font-museo'>{student.firstName}</div>
                                  <div className='text-xs text-card-foreground'>{(student.room) && `${student.room}, `}{student.schoolId}</div>
                                </PopoverContent>
                              </Popover>
                            </div>
                            <AlertDialog>
                              <AlertDialogTrigger asChild>
                                <Button variant='linkUnderline' size='xs' className='block px-0 sm:hidden'>
                                  Remove
                                </Button>
                              </AlertDialogTrigger>
                              {removeOrderAlertContent(student)}
                            </AlertDialog>
                          </div>
                          {student.products.map((product, index) => {
                            const productCatalog = data?.catalogue_products.find((cp) => cp.productId === product.productId);
                            return (
                              <>
                                {index === 0 ? '' : <div></div>}
                                <div className="flex gap-4 sm:col-span-2">
                                  {(!product.payable) &&
                                    <Image
                                      className={cn('h-[46px] w-[54px] object-cover rounded-md border border-border-primary')}
                                      alt={product.product}
                                      key={`${product.product}-${index}`}
                                      fallbackSrcs={[
                                        ...(productCatalog?.picture ? [`${productImageDirectory}/${productCatalog.supplier}/${productCatalog.picture}`] : []),
                                      ]}
                                      finalFallback={
                                        <div className='h-[46px] w-[54px] flex items-center text-secondary flex-col justify-center bg-background-light border-solid rounded-md'>
                                          <MdCameraAlt className='h-[46px] w-[54px] pt-2' />
                                        </div>
                                      }
                                    />}
                                  {product.option || product.choice || product.questions ?
                                    <Accordion key={product.productId} type="single" collapsible className={`text-primary w-[57%] sm:w-auto ${product.payable ? 'w-[78%]' : ''}`}>
                                      <AccordionItem value="item-1" className='border-none'>
                                        <AccordionTrigger className='flex items-center py-0 text-left h-11'>
                                          <div className="col-span-2 pr-2 text-sm">{product.product}</div>
                                        </AccordionTrigger>
                                        <AccordionContent className='px-4 pt-2 bg-background-light rounded-b-md'>
                                          <div className='grid grid-cols-2'>
                                            {product.options || product.choice ?
                                              <>
                                                <div className='pr-2 text-xs font-bold text-card-foreground'>Choices & Options</div>
                                                <div className='text-xs'>
                                                  {product.choice}
                                                  {product.options && product.choice ? ', ' : ''}
                                                  {product.options && product.options.map((option, index) => (
                                                    <React.Fragment key={index}>
                                                      {index > 0 && ", "}
                                                      {option}
                                                    </React.Fragment>
                                                  ))}
                                                </div>
                                              </>
                                              : undefined}
                                            {product.questions ?
                                              <>
                                                <div className='pr-2 text-xs font-bold text-card-foreground'>Form Answers</div>
                                                <div className='text-xs'>
                                                  {product.questions.map((question) => (
                                                    <div className='pb-2'>
                                                      <p className='pb-1 text-card-foreground'>{question.question}</p>
                                                      <p className='pb-1 font-bold'>{question.answer}</p>
                                                    </div>
                                                  ))}
                                                </div>
                                              </>
                                              : undefined}
                                          </div>
                                        </AccordionContent>
                                      </AccordionItem>
                                    </Accordion>
                                    :
                                    <div className={`flex items-center max-h-11 sm:max-h-auto col-span-1 text-sm text-card-foreground w-[57%] sm:w-auto ${product.payable ? 'w-[78%]' : ''}`}>{product.product}</div>
                                  }
                                  <p className='flex items-center ml-auto text-sm max-h-11 text-card-foreground sm:hidden'>{product.quantity}</p>
                                </div>
                                <div className='hidden sm:block'></div>
                                <div className='hidden pt-4 text-center sm:block'>
                                  <p className='text-sm text-card-foreground'>{product.quantity}</p>
                                </div>
                                {
                                  student.products.length === 1 ?
                                    <>
                                      <div className='hidden pt-1 sm:block'>
                                        <AlertDialog>
                                          <AlertDialogTrigger asChild>
                                            <Button className='hidden p-0 sm:block' variant='linkUnderline'>
                                              Remove
                                            </Button>
                                          </AlertDialogTrigger>
                                          {removeOrderAlertContent(student)}
                                        </AlertDialog>
                                      </div>
                                      <div>
                                        <p className='pt-0 text-sm text-right sm:pt-4 text-card-foreground'>{formatCentsPriceForDisplay(student.totalPrice)}</p>
                                        {(student.serviceFee !== 0) &&
                                          <div className='flex items-center justify-end gap-1'>
                                            <p className='flex items-center text-xs text-right'>Service fee included: {formatCentsPriceForDisplay(student.serviceFee)}</p>
                                          </div>
                                        }
                                      </div>
                                    </>
                                    :
                                    <div className='hidden sm:block'></div>
                                }
                                <div className='hidden sm:block'></div>
                              </>
                            )
                          })}
                        </div>
                        {
                          student.products.length > 1 ?
                            <div className="grid items-center grid-cols-1 pb-4 sm:gap-6 md:gap-8 sm:grid-cols-1 md:grid-cols-7 sm:p-4">
                              <div className="md:col-span-5"></div>
                              <div className='hidden sm:block md:w-[50%]'>
                                <AlertDialog>
                                  <AlertDialogTrigger asChild>
                                    <Button variant='linkUnderline' className='p-0' size='xs'>
                                      Remove
                                    </Button>
                                  </AlertDialogTrigger>
                                  {removeOrderAlertContent(student)}
                                </AlertDialog>
                              </div>
                              <div className='text-right w-full sm:w-[100%] pr-2 sm:pr-0'>
                                <p className='pr-0 text-sm text-card-foreground'>{formatCentsPriceForDisplay(student.totalPrice)}</p>
                                {(student.serviceFee !== 0) &&
                                  <div className='flex items-center justify-end gap-1 pt-2 sm:pt-1'>
                                    <p className='flex items-center text-xs text-right'>Service fee included: {formatCentsPriceForDisplay(student.serviceFee)}</p>
                                  </div>
                                }
                              </div>
                            </div>
                            :
                            undefined
                        }
                        <hr className="border-background-light" />
                      </>
                    ))}

                  </div>
                ))}
              </div>
            </div>
          </div>
          {(hasDeliveryAddress) &&
            <div className="grid items-center grid-cols-1 p-4 sm:grid-cols-2 bg-primary-muted">
              <div className="grid items-center grid-flow-col gap-4 md:auto-cols-max">
                <p className="inline-block">Delivery address:</p>
                <p className='text-sm text-black '>{itemRequiresDeliveryAddress?.detail.addr_txta}</p>
                <div className=''>
                  <AddressSelector open={addressSelectorOpened} onOpenChange={(open) => setAddressSelectorOpened(open)} orderId={itemRequiresDeliveryAddress?.order_id} isAddressAvailable={!!itemRequiresDeliveryAddress?.detail.addr_txta} />
                </div>
              </div>
            </div>
          }
          {/* Checkout offers - bigger screen */}
          {!isLoadingProductOffers && productData ?
            <div className='hidden sm:block'><CheckoutOffers /></div>
            : undefined
          }
          <div className="grid items-center grid-cols-1 md:grid-cols-2 bg-primary-muted">
            <form
              onSubmit={handleSubmit(onSubmitVoucherCode)}
            >
              <div className="items-start hidden grid-flow-col gap-2 p-4 sm:grid md:auto-cols-max">
                <InputField
                  variant="sm"
                  control={control}
                  name="token"
                  label="Voucher"
                  error={voucherError}
                  onBlur={() => {
                    setVoucherError('');
                  }}
                />
                <Button
                  className='mt-[22px]'
                  variant="outline"
                  type="submit"
                  size="xs"
                  disabled={!watch('token')}
                >
                  {voucherPrice !== null ?
                    'Applied'
                    :
                    'Apply'
                  }
                </Button>
              </div>
              <div className='block p-2 sm:hidden'>
                <Accordion type="single" collapsible className="w-auto text-primary">
                  <AccordionItem value="item-1" className='border-none'>
                    <AccordionTrigger className='flex items-center py-2 text-left'>
                      <div className="text-sm">Use a voucher</div>
                    </AccordionTrigger>
                    <AccordionContent>
                      <div className='flex items-start justify-between w-full gap-2'>
                        <div className='w-full pt-2'>
                          <InputField
                            control={control}
                            name="token"
                            label="Voucher"
                            className='h-9'
                            error={voucherError}
                            onBlur={() => {
                              setVoucherError('');
                            }}
                          />
                        </div>
                        <Button
                          variant="outline"
                          className='mt-[30px]'
                          type="submit"
                          size="sm"
                          disabled={!watch('token')}
                        >
                          {voucherPrice !== null ?
                            'Applied'
                            :
                            'Apply'
                          }
                        </Button>
                      </div>
                      {(voucherPrice !== null) &&
                        <KindoWell
                          variant="info"
                          className='mt-2'
                          messages={[`Discount of ${formatCentsPriceForDisplay(voucherPrice)} has been applied to your cart.`]}
                        />
                      }
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              </div>
            </form>
            <div className="hidden text-right sm:block">
              <p className='pr-4 text-xs underline text-card-foreground'>Including GST {formatCentsPriceForDisplay(totalGST)}</p>
              {(voucherPrice !== null) &&
                <p className='pt-1 pr-4 text-xs text-card-foreground'>Voucher discount: <span className='pl-2 font-bold text-warning'>-{formatCentsPriceForDisplay(voucherPrice)}</span></p>
              }
            </div>
          </div>

          <hr className="border-t border-white" />

          <div className="justify-end hidden px-4 py-2 sm:flex bg-primary-muted">
            <p className="text-card-foreground font-museo">Subtotal: </p><p className='pl-4 font-normal font-helvetica text-card-foreground'>{formatCentsPriceForDisplay(totalPrice)}</p>
          </div>

          <div className="block py-4 text-right sm:hidden">
            <div className='grid grid-cols-5 gap-3 px-4 pb-3 text-sm font-semibold text-card-foreground'>
              <p className='col-span-4'>Subtotal</p>
              <p>{formatCentsPriceForDisplay(totalPrice)}</p>
            </div>
            <hr className="border-t border-white" />
            <div className='grid grid-cols-5 gap-3 px-4 pt-4 pb-2 text-sm text-card-foreground'>
              <p className='col-span-4'>Including GST</p>
              <p>{formatCentsPriceForDisplay(totalGST)}</p>
            </div>
            {familyData &&
              <>
                <div className='grid grid-cols-5 gap-3 px-4 py-2 text-sm text-card-foreground'>
                  <p className='col-span-4'>myKindo wallet</p>
                  <p>{formatCentsPriceForDisplay(familyData.balance_in_cents)}</p>
                </div>
                <div className='grid grid-cols-5 gap-3 px-4 py-2 text-sm text-card-foreground'>
                  <p className='col-span-4'>myKindo wallet after order</p>
                  <p>{formatCentsPriceForDisplay(familyData.balance_in_cents - totalPrice)}</p>
                </div>
              </>
            }
            {(voucherPrice !== null) &&
              <>
                <div className='grid grid-cols-5 px-4 py-2 text-sm sm:hidden text-card-foreground'>
                  <p className='col-span-4'>Voucher discount</p>
                  <p>{formatCentsPriceForDisplay(voucherPrice)}</p>
                </div>
                <p className='hidden pt-1 text-sm text-card-foreground sm:block'>Voucher discount: <span className='pl-2 font-bold text-warning'>-{formatCentsPriceForDisplay(voucherPrice)}</span></p>
              </>
            }
          </div>
          {!isBalanceNotEnough && orderIds?.length !== 0 ?
            <div className="items-center p-4 pt-0 bg-primary-muted">
              <div className="flex items-center justify-end gap-4">
                <div className="w-full sm:ml-8 sm:w-[200px]">
                  <Button className="w-full"
                    disabled={isLoading || isLoadingFamily || (hasDeliveryAddress && !itemRequiresDeliveryAddress?.detail.addr_txta) || isCompleteOrderLoading}
                    onClick={() => {
                      setCompleteOrderLoading(true);
                      try {
                        api.order.placeOrder(voucherToken)
                          .then((res) => {
                            if (res) {
                              setCompleteOrderLoading(false);
                              return res.json();
                            }
                          })
                          .then((responseData) => {
                            if (responseData.rtype === 'error') {
                              setCompleteOrderLoading(false);
                              if (responseData.name) {
                                alert(responseData.message);
                              } else {
                                alert('Unable to complete order. Please try again later.');
                              }
                              return;
                            }
                            mutate();
                            if (responseData.completion_url) {
                              navigate(responseData.completion_url, { replace: true })
                              onPurchase();
                            }
                          })
                      } catch (ex) {
                        setCompleteOrderLoading(false);
                        alert('Unable to complete order. Please try again later.')
                      }
                    }}
                  >
                    Complete order
                  </Button>
                  {(hasDeliveryAddress && !itemRequiresDeliveryAddress?.detail.addr_txta) &&
                    <p className='flex items-center justify-end pt-2 text-xs text-warning'>Address is required.</p>
                  }
                </div>
              </div>
            </div>
            :
            undefined
          }

          {(isBalanceNotEnough && !isCommunityShop) &&
            <div className="hidden grid-cols-1 px-4 py-3 sm:grid bg-background-warning text-warning-foreground justify-items-end">
              <div className="flex items-center gap-2">
                <MdWarning className="w-8 h-8 text-warning" />
                <p className="text-card-foreground font-museo">
                  Your myKindo account balance is low:
                  {(hasDeliveryAddress && !itemRequiresDeliveryAddress?.detail.addr_txta) &&
                    <p className="text-sm text-card-foreground font-museo">
                      Please fill in the address to continue with top up.
                    </p>
                  }
                </p>
                <div className="ml-8">
                  {familyData &&
                    (<p className="font-bold text-card-foreground">{formatCentsPriceForDisplay(familyData.balance_in_cents)}</p>)
                  }
                </div>
              </div>
            </div>
          }
          <div className='flex justify-center w-full bg-white sm:hidden'>
            {/* border primary muted not working */}
            <div className="w-0 h-0 border-l-[15px] border-l-transparent border-t-[15px] border-[#d4edec] border-r-[15px] border-r-transparent">
            </div>
          </div>
          {(isBalanceNotEnough && !isLoadingFamily && data?.items) ?
            hasDeliveryAddress && !itemRequiresDeliveryAddress?.detail.addr_txta ? undefined
              :
              <div className="px-4 py-6 bg-white grid grid-rows-[fit-content_1fr] gap-4 border-b-2 border-border">
                <div>
                  <p className="text-xl font-museo">How would you like to {isCommunityShop ? 'pay' : 'top up and checkout'}?</p>
                </div>
                {familyData &&
                  (<TopUp totalPrice={totalPrice - familyData.balance_in_cents} orderIds={orderIds} isOrderValidForBX={isOrderValidForBX} isAllMiddletonGrange={isAllMiddletonGrange} onPurchase={onPurchase} onError={handleTopupError} />)
                }
              </div>
            : undefined
          }
        </div>
      </div>
    </div>
  );

  const CheckoutPageEmptyBag = (
    <div>
      <div className="flex flex-col gap-0 mt-0 sm:gap-4 sm:mt-8 sm:pb-6">
        <h2 className="pb-2 text-3xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
          {(!isCommunityShop) && 'Top up &'} Checkout
        </h2>
        <div className="overflow-hidden border-2 rounded-3xl border-border-primary">
          <div className="flex flex-col items-center gap-2 px-4 py-24 bg-white border-b-2">
            <Image
              className='object-cover w-[200px] pointer-events-none'
              alt=''
              src={CartImage}
            />
            <p className='pt-10 font-semibold font-museo'>Your shopping bag is empty</p>
            <Button variant='linkUnderline' onClick={() => navigate(homeLink)}>Continue shopping</Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div>
      <Helmet>
        <title>Checkout</title>
      </Helmet>
      {isCommunityShop ?
        <CommunityShopLayout
          breadcrumbItems={[
            { label: "Home", to: `/app/community-shop/${store.family.currentSchool}/` },
            { label: "Checkout", to: "/app/checkout", active: true },
          ]}
        >
          {isLoading ?
            <></>
            :
            <div>
              {orderIds?.length === 0 || !data ? CheckoutPageEmptyBag : CheckoutPageContent}
            </div>
          }
        </CommunityShopLayout>
        :
        <ShopLayout
          breadcrumbItems={[
            { label: "Home", to: "/app/shop" },
            { label: "Top up & Checkout", to: "/app/checkout", active: true },
          ]}
        >
          <div>
            {CheckoutPageContent}
          </div>
        </ShopLayout >
      }
    </div>
  )
})

export default CheckoutPage;