import React from 'react'
import Helmet from 'react-helmet'
import ShopLayout from '../../common_component/layout/ShopLayout'
import api from 'networking/api';
import { Spinner } from '../../common_component/base/spinner';
import ActionListItem from 'components/list/ActionListItem';
import _ from 'lodash';
import { format } from 'date-fns';
import PersonalInfoDialog from './dialogs/PersonalInfoDialog';
import { getPhoneDetails } from 'util/util';
import ChangePasswordDialog from './dialogs/ChangePasswordDialog';
import CloseAccountDialog from './dialogs/CloseAccountDialog';
import { useNavigate } from 'react-router-dom';
import SectionTitle from 'components/layout/section/SectionTitle';
import Section from 'components/layout/section/Section';
import SectionContent from 'components/layout/section/SectionContent';
import Container from 'components/layout/page/Container';
import { Button } from 'components/base/button';
import EditStudentDialog from './dialogs/EditStudentDialog';

type Props = {}

const MyDetailsPage = (props: Props) => {
  const navigate = useNavigate();
  const { data, isLoading } = api.family.useMyDetails();
  const studentsGroupedById = _.groupBy(data?.students.filter((student) => student.member_type === 0), 'student_id');
  const [showNameForm, setShowNameForm] = React.useState(false);
  const [showEmailForm, setShowEmailForm] = React.useState(false);
  const [showPhoneForm, setShowPhoneForm] = React.useState(false);
  const [showPasswordForm, setShowPasswordForm] = React.useState(false);
  const [showStudentForm, setShowStudentForm] = React.useState(false);
  const [showCloseAccountDialog, setShowCloseAccountDialog] = React.useState(false);
  const {
    phoneLabel,
    phoneNumber,
    phoneCode,
  } = getPhoneDetails(data?.contact_telephone || '');

  return (
    <div>
      <Helmet>
        <title>My Details</title>
      </Helmet>
      <PersonalInfoDialog
        open={showNameForm || showEmailForm || showPhoneForm}
        onClose={() => {
          setShowNameForm(false);
          setShowEmailForm(false);
          setShowPhoneForm(false);
        }}
        fields={
          showNameForm ? ['name'] :
            showEmailForm ? ['email'] :
              showPhoneForm ? ['phone'] : []
        }
      />
      <ChangePasswordDialog
        open={showPasswordForm}
        onClose={() => {
          setShowPasswordForm(false);
        }}
      />
      <CloseAccountDialog
        open={showCloseAccountDialog}
        onClose={() => {
          setShowCloseAccountDialog(false);
        }}
      />
      <EditStudentDialog
        open={showStudentForm}
        viewStudentOnCreate
        onClose={() => {
          setShowStudentForm(false);
        }}
        studentId='NEW'
        editableFields={['name', 'allergy', 'school']}
      />
      <ShopLayout>
        <Container title="My Details">
          {isLoading && !data ?
            <div className='flex items-center justify-center w-full py-6'>
              <Spinner size="md" />
            </div>
            :
            <div>
              <div className='flex flex-col gap-6'>
                <Section>
                  <SectionTitle title="Personal information" />
                  <SectionContent>
                    <ActionListItem
                      label="Name"
                      description={`${data?.contact_name} ${data?.family_name}`}
                      onClick={() => { setShowNameForm(true) }}
                    />
                    <ActionListItem
                      label="Email"
                      description={`${data?.email}`}
                      onClick={() => { setShowEmailForm(true) }}
                    />
                    <ActionListItem
                      label={phoneLabel}
                      description={`${phoneCode !== 'mob' ? `(${phoneCode}) ` : ''}${phoneNumber}`}
                      onClick={() => { setShowPhoneForm(true) }}
                    />
                  </SectionContent>
                </Section>
                <Section>
                  <SectionTitle title="Password" />
                  <SectionContent>
                    <ActionListItem
                      label="Change password"
                      description={`Last changed ${data?.last_password_update ? format(new Date(data?.last_password_update), 'dd MMM yyyy') : isLoading ? '' : 'Never'}`}
                      descriptionVariant='helper'
                      onClick={() => { setShowPasswordForm(true) }}
                    />
                  </SectionContent>
                </Section>
                <Section>
                  <SectionTitle title="Donation tax rebate" />
                  <SectionContent>
                    <ActionListItem
                      label="Manage rebate preferences"
                      description={`Choose to claim or re-donate your rebate and manage receipt settings`}
                      descriptionVariant='helper'
                      onClick={() => {
                        navigate('/app/tax-rebate-agent')
                      }}
                    />
                  </SectionContent>
                </Section>

                <Section>
                  <SectionTitle title="Members" />
                  <SectionContent>
                    {
                      Object.keys(studentsGroupedById).map((studentId) => {
                        const student = studentsGroupedById[studentId][0];
                        const studentSchools = studentsGroupedById[studentId].map((student) => `${student.school}${student.room ? ` (${student.room})` : ''}`) || []
                        const studentSchoolsMore = studentSchools.slice(1);
                        return (
                          <ActionListItem
                            key={studentId}
                            label={`${student.first_name} ${student.last_name}`}
                            description={(
                              <div className='flex flex-col text-xs text-text-body'>
                                <p className=''>
                                  {studentSchools.slice(0, 1).join(', ')}
                                  {
                                    studentSchoolsMore.length > 0 && (
                                      <span className='text-text-helper'>
                                        {` +${studentSchoolsMore.length} more`}
                                      </span>
                                    )
                                  }
                                </p>
                                <p>
                                  Allergies: {student.allergy || 'None'}
                                </p>
                              </div>
                            )}
                            onClick={() => {
                              navigate(`/app/my-details/student/${studentId}`)
                            }}
                          />
                        )
                      })
                    }

                    <div className='px-4 py-6'>
                      <Button
                        variant="outline"
                        type="button"
                        onClick={() => {
                          setShowStudentForm(true);
                        }}
                      >
                        Add member
                      </Button>
                    </div>
                  </SectionContent>
                </Section>

                <Section>
                  <SectionContent noDivider>
                    <ActionListItem
                      label="Close Kindo account"
                      labelVariant='danger'
                      description={`All stored information will be deleted and you will no longer be able to access your account`}
                      descriptionVariant='helper'
                      onClick={() => { setShowCloseAccountDialog(true) }}
                    />
                  </SectionContent>
                </Section>
              </div>
            </div>
          }
        </Container>
      </ShopLayout>
    </div>
  )
}

export default MyDetailsPage