import React from 'react';
import {
  SortingState,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table"
import { useVirtualizer } from '@tanstack/react-virtual'
import { Button } from "../../../common_component/base/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../common_component/base/table";
import { Spinner } from '../../../common_component/base/spinner';
import { columns, RowItem } from './RuleRollOverColumns';
import { useToast } from 'components/base/use-toast';
import { useRolloverContext } from '.';
import useRemoteConfig from 'util/hooks/useRemoteConfig';
import api from 'networking/api';
import { RolloverRulesData, RollOverRuleResult } from 'networking/models/payable';
import useSWRMutation from 'swr/mutation';
import { DeferableResponse } from 'networking/models/common';
import useDeferredTask from 'networking/hooks/useDeferredTask';


const DeferredProgressCell = (props: {
  taskId: string;
  isApplying: boolean;
  onDismiss?: () => void;
  onRetry?: () => void;
}) => {
  const { data, isLoading } = useDeferredTask<RollOverRuleResult>(props.taskId);

  const taskError = data?.results?.rtype === 'exception' ? data?.results.message || data.results.public_message : null; // More detailed error message
  const getKnownError = () => {
    if (data?.results?.rtype === 'exception') {
      switch (data?.results?.class) {
        case 'BindPayableExistsException': {
          return 'This rule already exists';
        }
      }
    }
  }
  const knownError = getKnownError();
  return (
    <div className="flex items-center justify-end">
      {
        isLoading || !data?.has_run ? (
          <div className='flex items-center gap-2'>
            <Spinner size="xs" />
            <p className="text-card-foreground">
              Applying rule...
            </p>
          </div>
        ) : (
          <>
            {
              data?.results?.rtype === 'bind_payable_ref' ? (
                <div>
                  <p className='text-bold'>
                    Created rule
                  </p>
                  <p className='text-text-helper'>
                    Successfully applied to {data.results?.apply_result.student_count} students.
                  </p>
                  <Button
                    size="xs"
                    variant="linkUnderline"
                    onClick={props.onDismiss}
                  >
                    Dismiss
                  </Button>
                </div>
              ) : (
                <div>
                  <p className='text-bold text-destructive'>
                    Failed to apply rule
                  </p>
                  <p className='text-text-helper'>
                    {taskError || knownError}
                  </p>
                  <div className='flex flex-row items-center justify-end gap-2'>
                    <Button
                      size="xs"
                      variant="linkUnderline"
                      disabled={props.isApplying}
                      onClick={props.onDismiss}
                    >
                      Dismiss
                    </Button>
                  </div>
                </div>
              )
            }
          </>
        )
      }
    </div>
  )
}
export function RuleRollOverTable() {
  const {
    school,
    filters,
  } = useRolloverContext();
  const { toast } = useToast();
  const { getRemoteConfigValue } = useRemoteConfig();
  const rollOverYear = getRemoteConfigValue('payable_rollover_year').asString();
  const rollOverNextYear = parseInt(rollOverYear, 10) + 1;

  const [deferredTasks, setDeferredTasks] = React.useState<Record<string, DeferableResponse>>({});
  const [applyingRules, setApplyingRules] = React.useState<Record<string, boolean>>({});
  const { data: rollOverRules, isLoading, error, mutate } = api.payable.useAllRules({
    schoolId: school,
    year: rollOverNextYear.toString()
  });
  const { data: configData } = api.admin.useKpConfig(school);
  const schoolConfig = configData?.school_confs.find((conf) => conf.school_id === school);
  const showFeeType = schoolConfig?.kp_conf.ff_sma;

  React.useEffect(() => {
    if (!isLoading) {
      mutate()
    }
  }, []);

  const data = React.useMemo(() => {
    if (!Array.isArray(rollOverRules)) {
      return [];
    }

    return rollOverRules
      .filter((rule: RolloverRulesData) => {
        if (filters.category) {
          return rule.pcat === filters.category;
        }
        return true;
      })
      .map((rule: RolloverRulesData) => ({
        name: rule.proto_payable_name,
        category: rule.pcat,
        feeType: rule.fee_type,
        group: rule.group_identifier,
        protoPayableId: rule.proto_payable_id,
        metaGroup: rule.metagroup,
        schoolId: rule.school_id,
        bindPayableId: rule.bind_payable_id
      }));
  }, [filters, rollOverRules]);


  const { trigger, isMutating } = useSWRMutation(`/payables/rule-rollover`, (url, { arg }: { arg: { payable: RowItem, rollOver: string } }) => {
    let postData = {
      "school_id": arg.payable.schoolId,
      "rtype": "bind_payable",
      "proto_payable_id": arg.payable.protoPayableId,
      "metagroup": arg.payable.metaGroup,
      "group_identifier": arg.payable.group,
      "defer": 'MUST_DEFER',
      "rollover": arg.rollOver,
      "bind_payable_id": arg.payable.bindPayableId,
      "policy": "add_apply_commit",
    }

    try {
      return api.payable.rollOverRule(postData);
    } catch (e) {

    }
  })

  const handleDismiss = (payable: RowItem) => {
    const updatedRullRolloverRules = rollOverRules?.filter((item: RolloverRulesData) => item.bind_payable_id !== payable.bindPayableId);
    setApplyingRules((prev) => ({
      ...prev,
      [payable.bindPayableId]: true
    }))
    trigger({
      payable: payable,
      rollOver: 'no',
    })
      .then((response) => {
        setApplyingRules((prev) => ({
          ...prev,
          [payable.bindPayableId]: false
        }))
        if (response?.data?.status) {
          mutate(updatedRullRolloverRules, {
            revalidate: false,
          });
          toast({
            description: `"${payable.name}" has been dismissed.`,
          });
        }
      })
      .catch(() => {
        setApplyingRules((prev) => ({
          ...prev,
          [payable.bindPayableId]: false
        }))
        mutate();
        toast({
          description: `"${payable.name}" has failed to be dismissed.`,
          variant: 'destructive',
        });
      });
  };

  const handleRollOver = (payable: RowItem) => {
    setApplyingRules((prev) => ({
      ...prev,
      [payable.bindPayableId]: true
    }))
    trigger({
      payable: payable,
      rollOver: 'yes',
    })
      .then((response) => {
        setApplyingRules((prev) => ({
          ...prev,
          [payable.bindPayableId]: false
        }))
        if (response?.data?.rtype === 'defer') {
          setDeferredTasks((prev) => ({
            ...prev,
            [payable.bindPayableId]: response.data as DeferableResponse,
          }));
        } else {
          if (response?.data?.apply_result.rtype === "apply_result") {
            const updatedRullRolloverRules = rollOverRules?.filter((item: RolloverRulesData) => item.bind_payable_id !== payable.bindPayableId);
            mutate(updatedRullRolloverRules, {
              revalidate: false,
            });
            toast({
              description: `Rule created for ${payable.metaGroup} and "${payable.name}" has been applied to ${response.data.apply_result.student_count} students.`,
            });
          }
        }
      })
      .catch(() => {
        setApplyingRules((prev) => ({
          ...prev,
          [payable.bindPayableId]: false
        }))
        mutate();
        toast({
          description: `"${payable.name}" has failed to rolled over.`,
          variant: 'destructive',
        });
      });
  };

  const getFeeType = (type: number | null) => {
    switch (type) {
      case null:
        return 'Any';
      case 0:
        return 'Domestic';
      case 1:
        return 'International';
      default:
        return 'Any';
    }
  }

  const columnsWithActions = React.useMemo(() => {
    return columns.concat([
      ...(showFeeType
        ? [{
          id: 'feeType',
          header: 'Fee type',
          size: 80,
          cell: ({ row }) => (
            <p className='text-card-foreground'>
              {getFeeType(row.original.feeType)}
            </p>
          ),
        },]
        : []),
      {
        id: 'actions',
        header: 'Actions',
        meta: {
          textAlign: 'right',
        },
        size: 160,
        cell: ({ row }) => {
          const deferredTaskId = deferredTasks[row.original.bindPayableId];
          if (deferredTaskId) {
            return (
              <DeferredProgressCell
                isApplying={applyingRules[row.original.bindPayableId]}
                taskId={deferredTaskId.task_id}
                onDismiss={() => {
                  const updatedRullRolloverRules = rollOverRules?.filter((item: RolloverRulesData) => item.bind_payable_id !== row.original.bindPayableId);
                  mutate(updatedRullRolloverRules, {
                    revalidate: false,
                  });
                }}
                onRetry={() => {
                  handleRollOver(row.original);
                }}
              />
            )
          } else {
            return (
              <div className='flex flex-row-reverse items-center gap-2'>
                <Button
                  size="xs"
                  className='whitespace-nowrap'
                  onClick={() => {
                    handleRollOver(row.original);
                  }}
                  disabled={applyingRules[row.original.bindPayableId]}
                >
                  Apply Rule
                </Button>
                <Button
                  size="xs"
                  className='whitespace-nowrap'
                  variant={'outline'}
                  onClick={() => {
                    handleDismiss(row.original);
                  }}
                  disabled={applyingRules[row.original.bindPayableId]}
                >
                  Dismiss
                </Button>
              </div>
            )
          }
        },
      },
    ]);
  }, [rollOverRules, showFeeType, isMutating, deferredTasks, applyingRules]);


  React.useEffect(() => {
    if (error) {
      toast({
        title: 'Failed to fetch payable rules',
        description: error.message,
        variant: 'destructive',
      });
    }
  }, [error])

  const parentRef = React.useRef<HTMLDivElement>(null);
  const [sorting, setSorting] = React.useState<SortingState>([])

  const table = useReactTable({
    data,
    columns: columnsWithActions,
    globalFilterFn: 'includesString',
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter: filters.searchText,
    }
  })

  const { rows } = table.getRowModel()
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 65,
    overscan: 5,
  })

  const virtualItems = rowVirtualizer.getVirtualItems();

  return (
    <>
      <div className='flex flex-col text-card-foreground'>
        <div className="flex items-center gap-3 p-4">
          <div className="flex-1" />
          {!isLoading && (
            <p className="text-sm">
              {`Displaying ${rows.length} items`}
            </p>
          )}
        </div>
        <div ref={parentRef} className="border-t max-h-[calc(100vh_-_380px)] min-h-[400px] overflow-auto">
          <div style={{ height: `${rowVirtualizer.getTotalSize() + 60}px` }} className='min-h-[200px]'>
            <Table>
              <TableHeader
                className='sticky top-0 z-10 table-header-group bg-gray-50 border-b-1 border-border'
              >
                {table.getHeaderGroups().map((headerGroup) => (
                  <TableRow key={headerGroup.id} >
                    {headerGroup.headers.map((header) => {
                      return (
                        <TableHead key={header.id} className='font-museo'
                          style={{
                            width: header.getSize() || undefined,
                            textAlign: header.column.columnDef.meta?.textAlign,
                          }}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        </TableHead>
                      )
                    })}
                  </TableRow>
                ))}
              </TableHeader>
              <TableBody>
                {
                  isLoading && (
                    <TableRow>
                      <TableCell colSpan={columns.length + 2} className="h-24 text-center">
                        <div className='flex items-center justify-center gap-2'>
                          <Spinner size="xs" />
                          Loading please wait
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                }
                {rows?.length ? (
                  virtualItems.map((virtualRow, index) => {
                    const row = rows[virtualRow.index];
                    return (
                      <TableRow
                        className='border-none'
                        key={row.id}
                        style={{
                          height: `${virtualRow.size}px`,
                          transform: `translateY(${virtualRow.start - index * virtualRow.size}px)`,
                        }}
                        data-state={row.getIsSelected() && "selected"}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <TableCell key={cell.id} align={cell.column.columnDef.meta?.textAlign}>
                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                          </TableCell>
                        ))}
                      </TableRow>
                    )
                  })
                ) : (
                  !isLoading &&
                  <TableRow>
                    <TableCell colSpan={columns.length + 2} className="h-24 text-center">
                      {!rollOverRules || rollOverRules.length === 0 ? (
                        'There are currently no rules to rollover.'
                      ) : (
                        'No results.'
                      )}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    </>
  )
}
