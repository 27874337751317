import React from 'react'
import { Helmet } from 'react-helmet';
import RegistrationLayout from 'components/layout/RegistrationLayout';
import SectionTitle from 'components/layout/section/SectionTitle';
import Section from 'components/layout/section/Section';
import SectionContent from 'components/layout/section/SectionContent';
import SectionSubTitle from 'components/layout/section/SectionSubTitle';
import { useForm } from 'react-hook-form';
import InputField from 'components/form/InputField';
import { Button } from 'components/base/button';
import RadioGroupField from "components/form/RadioGroup";
import PhoneInputField from 'components/form/PhoneInputField';
import { Checkbox } from "components/base/checkbox";
import FileUploader from "./FileUploader"
import { getTemporaryLogo } from 'networking/constants';
import { TaintedPattern } from 'util/tainted_regexp';
import { customerTerms } from 'networking/constants';
import { SupplierRegistration, FileUploadData } from 'networking/models/misc';
import api from 'networking/api';
import useSWRMutation from 'swr/mutation';
import { Spinner } from '../../common_component/base/spinner';
import RegistrationSuccess from './RegistrationSuccess';
import { getCookie, deleteCookie } from 'util/util';
import { platformEnv } from 'networking/constants';
import InfoTooltip from 'components/tooltip/InfoTooltip';
import { useSearchParams } from 'react-router-dom';
import BankAccountField from 'components/form/BankAccountField';
import CheckboxField from 'components/form/CheckboxField';

const SupplierRegistrationForm = () => {
  const [params] = useSearchParams();
  const invitedBy = params.get('invited_by');

  const sessionCookie = getCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);

  React.useEffect(() => {
    if (sessionCookie) {
      deleteCookie(`ezsyssession.${platformEnv}.tgcl.co.nz`);
    }
  }, [sessionCookie])

  const { control, handleSubmit, watch, setValue, getValues } = useForm<SupplierRegistration>({
    defaultValues: {
      use_existing_menu_account: "true",
      use_existing_order_account: "true",
      gst_registered: false,
      gst_number: "",
      invited_by: invitedBy ? invitedBy : "",
      product_type: "general",
      rtype: "supplier_registration",
      pic_name: ""
    }
  });

  const onSubmit = async (data: SupplierRegistration) => {
    if (isOrderAdminDetailSame) {
      data.order_admin_contact_name = data.menu_admin_contact_name;
      data.order_admin_email = data.menu_admin_email;
      data.order_admin_phone = data.menu_admin_phone;
    }

    if (isAccountsAdminDetailSame) {
      data.account_contact_name = data.menu_admin_contact_name;
      data.account_email = data.menu_admin_email;
      data.account_phone = data.menu_admin_phone;
    }

    if (!formError) {
      trigger(data);
    }
  }

  const [registrationSuccessResponse, setRegistrationSuccessResponse] = React.useState(null)
  const [isOrderAdminDetailSame, setOrderAdminDetailSame] = React.useState(true)
  const [isAccountsAdminDetailSame, setAccountsAdminDetailSame] = React.useState(true)
  const [formError, setFormError] = React.useState('');
  const [responseError, setResponseError] = React.useState('');

  const logoName = watch('pic_name')
  const handleUploadSuccess = (response: FileUploadData) => {
    setValue('pic_name', response.pic_name)
    setFormError('')
  };

  const { trigger, error, isMutating } = useSWRMutation('/supplier-registration', (key, { arg }: { arg: SupplierRegistration }) => {
    try {
      return api.misc.registerSupplier(arg)
        .then(async (response) => {
          if (error) {
            setFormError(error)
          } else if (response) {
            const jsonResponse = await response.json()
            setFormError('')

            if (jsonResponse.rtype === 'error') {
              setResponseError(jsonResponse.message)
            } else {
              setRegistrationSuccessResponse(jsonResponse)
            }
          } else {
            setResponseError('Something went wrong, please try again')
          }
          return response;
        });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }


  });

  return (
    <div>
      <Helmet>
        <title>Registration Form</title>
      </Helmet>
      <RegistrationLayout>
        <div className="mx-2 sm:max-w-[700px] sm:m-auto">
          <div className="flex flex-col gap-2 px-2 pb-4">
            <div className='py-6 mb-8 shadow-md sm:py-10 rounded-2xl bg-card'>
              {isMutating &&
                <Spinner size="xs" />
              }
              {registrationSuccessResponse ?
                <RegistrationSuccess
                  registrationType="supplier"
                  emailLogins={[
                    {
                      email: registrationSuccessResponse.menu_admin_email,
                      title: 'Menu Administrator (at Partner site)',
                    },
                    {
                      email: registrationSuccessResponse.order_admin_email,
                      title: 'Order Administrator (at Shop site)',
                    }
                  ]}
                />
                :
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='flex flex-col gap-6'>
                    {/* Supplier details */}
                    <Section>
                      <p className="px-6 text-3xl font-bold sm:px-12 text-primary font-museo">
                        Registration Form
                      </p>
                      <SectionContent>
                        <div className='flex flex-col max-w-xs gap-4 pt-2 pl-4'>
                          <InputField
                            name="legal_name"
                            control={control}
                            label="Legal name"
                            placeholder='Enter legal name'
                            rules={{
                              required: 'Legal name is required',
                            }}
                            endAdornment={
                              <InfoTooltip
                                side="top"
                                messages={['Legal name will be used on invoices. Trading name will be visible to users on their confirmation emails and any printed labels. Legal and trading names can be the same.']}
                              />
                            }
                          />
                          <InputField
                            name="trading_name"
                            control={control}
                            label="Trading name"
                            placeholder='XYZ School PTA'
                            rules={{
                              required: 'Trading name is required',
                            }}
                            endAdornment={
                              <InfoTooltip
                                side="top"
                                messages={['Trading name of company (or organisation & department) eg. XYZ School PTA']}
                              />
                            }
                          />
                          <InputField
                            name="supplier_address"
                            control={control}
                            label="Physical address"
                            placeholder='Enter physical address'
                            rules={{
                              required: 'Address is required',
                            }}
                          />
                          <BankAccountField
                            name="bank_number"
                            control={control}
                            label="Bank account number"
                            placeholder='Enter bank account number'
                            rules={{
                              required: 'Bank account number is required',
                              validate: {
                                isValidBankNumber: (value) => {
                                  const bankRegex = TaintedPattern.bankAccountRegex
                                  return bankRegex.test(value) || 'Please enter a valid bank account number'
                                }
                              }
                            }}
                          />
                          <RadioGroupField
                            control={control}
                            name="gst_registered"
                            label="GST registered"
                            options={[
                              {
                                label: 'Not registered',
                                value: false,
                              },
                              {
                                label: 'Registered',
                                value: true,
                              }
                            ]}
                            orientation="horizontal"
                          />
                          {watch('gst_registered') &&
                            <InputField
                              name="gst_number"
                              control={control}
                              label="GST number"
                              placeholder='Enter GST number'
                              rules={{
                                required: 'GST number is required',
                              }}
                            />
                          }
                          <RadioGroupField
                            control={control}
                            name="product_type"
                            label="Type"
                            options={[
                              {
                                label: 'General',
                                value: 'general',
                              },
                              {
                                label: 'Food',
                                value: 'food',
                              }
                            ]}
                            orientation="horizontal"
                          />
                          <div className='flex flex-col gap-1'>
                            <p className='text-sm text-card-foreground'>Logo</p>
                            <div className='flex gap-2'>
                              <FileUploader onUploadSuccess={handleUploadSuccess} />
                              {logoName && (
                                <div>
                                  <img className='w-12' src={getTemporaryLogo({ imageName: logoName })} alt='supplier logo' />
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </SectionContent>
                    </Section>
                    <p className="px-6 text-3xl font-bold sm:px-12 text-primary font-museo">
                      Key Contacts
                    </p>
                    {/* Store Content Manager */}
                    <Section>
                      <SectionTitle title="Store Content Manager" />
                      <SectionSubTitle title="This role provides the user with access to create, edit and delete items and menu categories for the organisation, including setting pricing, description information, etc." />
                      <SectionContent>
                        <div className='flex flex-col max-w-xs gap-4 pt-2 pl-4'>
                          <InputField
                            name="menu_admin_contact_name"
                            control={control}
                            label="Name"
                            placeholder='Enter store content manager name'
                            rules={{
                              required: 'Name is required',
                            }}
                          />
                          <InputField
                            name="menu_admin_email"
                            control={control}
                            label="Email"
                            placeholder='Enter email address'
                            rules={{
                              required: 'Email is required',
                              pattern: {
                                value: TaintedPattern.email,
                                message: 'Invalid email address',
                              },
                            }}
                          />
                          <PhoneInputField
                            name="menu_admin_phone"
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            inputPlaceholder='Enter contact number'
                            selectRules={{
                              required: 'Required',
                            }}
                          />
                        </div>
                      </SectionContent>
                    </Section>
                    {/* Order Administrator */}
                    <Section>
                      <SectionTitle title="Order Administrator" />
                      <SectionSubTitle title="This role provides the user with access to view orders, print orders, change order status online." />
                      <SectionContent>
                        <div className='flex flex-col max-w-xs gap-4 pt-2 pl-4'>
                          <Checkbox
                            name="orderAdminDetails"
                            onCheckedChange={(checked) => {
                              setOrderAdminDetailSame(checked)
                            }}
                            label="Same as content manager"
                            checked={isOrderAdminDetailSame}
                          />
                        </div>
                        {!isOrderAdminDetailSame &&
                          <div className='flex flex-col max-w-xs gap-4 pt-4 pl-4'>
                            <InputField
                              name="order_admin_contact_name"
                              control={control}
                              label="Name"
                              placeholder='Enter order administrator name'
                              rules={{
                                required: 'Name is required',
                              }}
                            />
                            <InputField
                              name="order_admin_email"
                              control={control}
                              label="Email"
                              placeholder='Enter email address'
                              rules={{
                                required: 'Email is required',
                                pattern: {
                                  value: TaintedPattern.email,
                                  message: 'Invalid email address',
                                },
                              }}
                            />
                            <PhoneInputField
                              name="order_admin_phone"
                              control={control}
                              setValue={setValue}
                              getValues={getValues}
                              inputPlaceholder='Enter contact number'
                              selectRules={{
                                required: 'Required',
                              }}
                            />
                          </div>
                        }
                      </SectionContent>
                    </Section>
                    {/* Accounts */}
                    <Section>
                      <SectionTitle title="Accounts" />
                      <SectionSubTitle title="This role will be sent the payment information and associated reports for the supplier." />
                      <SectionContent>
                        <div className='flex flex-col max-w-xs gap-4 pt-2 pl-4'>
                          <Checkbox
                            name="accountAdminDetails"
                            onCheckedChange={(checked) => {
                              setAccountsAdminDetailSame(checked)
                            }}
                            label="Same as content manager"
                            checked={isAccountsAdminDetailSame}
                          />
                        </div>
                        {!isAccountsAdminDetailSame &&
                          <div className='flex flex-col max-w-xs gap-4 pt-4 pl-4'>
                            <InputField
                              name="account_contact_name"
                              control={control}
                              label="Name"
                              placeholder='Enter accounts admin name'
                              rules={{
                                required: 'Name is required',
                              }}
                            />
                            <InputField
                              name="account_email"
                              control={control}
                              label="Email"
                              placeholder='Enter email address'
                              rules={{
                                required: 'Email is required',
                                pattern: {
                                  value: TaintedPattern.email,
                                  message: 'Invalid email address',
                                },
                              }}
                            />
                            <PhoneInputField
                              name="account_phone"
                              setValue={setValue}
                              getValues={getValues}
                              control={control}
                              inputPlaceholder='Enter contact number'
                              selectRules={{
                                required: 'Required',
                              }}
                            />
                          </div>
                        }
                      </SectionContent>
                    </Section>
                    {/* Customer enquiry details */}
                    <Section>
                      <SectionTitle title="Customer enquiry details" />
                      <SectionSubTitle title="Customer enquiry details will be provided on the email order confirmations and online help page." />
                      <SectionContent>
                        <div className='flex flex-col max-w-xs gap-4 pt-4 pl-4'>
                          <InputField
                            name="customer_contact_contact_name"
                            control={control}
                            label="Name"
                            placeholder="eg XYZ School"
                            rules={{
                              required: 'Name is required',
                            }}
                            endAdornment={
                              <InfoTooltip
                                side="top"
                                messages={['Choose a generic name and a shared email address.']}
                              />
                            }
                          />
                          <InputField
                            name="customer_contact_email"
                            control={control}
                            label="Email"
                            placeholder='Enter email address'
                            rules={{
                              required: 'Email is required',
                              pattern: {
                                value: TaintedPattern.email,
                                message: 'Invalid email address',
                              },
                            }}
                          />
                          <InputField
                            name="customer_contact_address"
                            control={control}
                            label="Address"
                            placeholder='Enter address'
                            rules={{
                              required: 'Address is required',
                            }}
                          />
                          <PhoneInputField
                            name="customer_contact_phone"
                            control={control}
                            setValue={setValue}
                            getValues={getValues}
                            inputPlaceholder='Enter contact number'
                            selectRules={{
                              required: 'Required',
                            }}
                          />
                        </div>
                      </SectionContent>
                    </Section>
                    {/* Terms and conditions */}
                    <Section>
                      <SectionContent>
                        <CheckboxField
                          control={control}
                          name="terms"
                          rules={{
                            required: 'Terms and conditions needs to be checked to proceed.',
                          }}
                          hideAsterisk
                          label={
                            <div className='flex flex-col gap-4'>
                              <p>
                                I confirm that I have the authority to act on behalf on my organisation and have read and agree with the <a href={customerTerms} className='text-primary' target="_blank" rel="noreferrer">Customer Terms of Service</a>.
                              </p>
                              <p>
                                We may amend any of the terms and conditions contained in this Agreement at any time and at our sole discretion. Any changes will be effective upon the posting of such changes on The TGCL Partner Site, and you are responsible for reviewing these locations and informing yourself of all applicable changes or notices. All notice of changes to the Agreement will be posted for at least 30 days.
                              </p>
                            </div>
                          }
                        />
                      </SectionContent>
                    </Section>
                    <div className='px-12'>
                      {formError &&
                        <p className='pb-4 text-sm text-destructive'>{formError}</p>
                      }
                      {(responseError) &&
                        <p className='pb-4 text-sm text-destructive'>{responseError}</p>
                      }
                      <Button type='submit'>Submit</Button>
                    </div>
                  </div>
                </form>
              }
            </div>
          </div>
        </div>
      </RegistrationLayout>
    </div>
  )
}

export default SupplierRegistrationForm