import api from 'networking/api';
import { Link } from 'react-router-dom'
import React from 'react'
import { useStore } from '../../../store/store';
import { getServiceImageDirectory } from 'networking/constants'
import { Card, CardContent, CardHeader, CardTitle } from '../../../common_component/base/card'
import { Button } from '../../../common_component/base/button';
import _ from 'lodash';
import Image from '../../../common_component/Image';
import { cn } from '../../../util/tailwind';
import DefaultServiceIcon from '../../../assets/images/default-service-icon.jpg';
import { Service } from 'networking/models/family';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import useSchoolServices from 'networking/hooks/useSchoolService';

type Props = {}
type ListItemProps = {
  service: Service;
  schoolPath: string;
  isLast: boolean;
}
const ListItem = (props: ListItemProps) => {
  const { service, schoolPath, isLast } = props;
  return (
    <Link to={`/app/shop/${service.brand_name}/${service.id}`}>
      <Button className='flex items-center justify-between gap-4 w-full h-[48px] pr-1 pl-2' variant="list">
        <div className={cn('flex flex-1 items-center h-[48px] text-left overflow-hidden border-b-1 border-primary-light', {
          'border-b-0 sm:border-b-1': isLast
        })}>
          <p className='overflow-hidden font-museo text-primary text-ellipsis whitespace-nowrap'>
            {service.title}
          </p>
        </div>
        <div className='flex items-center justify-center p-1 rounded-lg bg-card w-[40px] h-[40px] border-1 border-primary-light'>
          <Image alt=''
            src={`${getServiceImageDirectory({ schoolPath, serviceId: service?.id })}${service?.icon}`}
            className="object-scale-down w-full h-auto max-h-[36px]"
            fallbackSrcs={[DefaultServiceIcon]}
          />
        </div>
      </Button>
    </Link>
  )
}
const MultipleService = (props: Props) => {
  const { family: { currentSchool } } = useStore();

  const {
    schoolService,
    kindoServices,
  } = useSchoolServices({
    schoolId: currentSchool,
    coreShopsOnly: true,
  });
  const services = kindoServices;

  return (
    <div>
      <Card className='border-none rounded-lg sm:rounded-3xl'>
        <CardHeader className='flex flex-col justify-between pb-2 sm:flex-row sm:pb-2 sm:pt-10'>
          <div className='flex items-center gap-1'>
            <CardTitle className='text-2xl font-normal font-museo text-primary'>More to explore</CardTitle>
          </div>
        </CardHeader>
        <CardContent>
          <div className='hidden grid-cols-1 sm:grid sm:grid-cols-2 md:grid-cols-3 gap-x-4'>
            {services.map((service, index) => (
              <ListItem key={service.id} service={service} schoolPath={schoolService?.path ?? ''} isLast={index === services.length - 1} />
            ))}
          </div>
          <div className='block sm:hidden'>
            <Swiper
              pagination={{
                clickable: true,
              }}
              slidesPerView={1}
              modules={[Pagination]}
            >
              {/* Services grouped by 5 */}
              {
                _.chunk(services, 5).map((chunk, index) => (
                  <SwiperSlide style={{ height: 'auto' }} key={index}>
                    <div className='grid grid-cols-1 pb-8'>
                      {chunk.map((service, index) => (
                        <ListItem key={service.id} service={service} schoolPath={schoolService?.path ?? ''} isLast={index === chunk.length - 1} />
                      ))}
                    </div>
                  </SwiperSlide>
                ))
              }
            </Swiper>
          </div>
        </CardContent>

      </Card>
    </div>
  )
}

export default MultipleService