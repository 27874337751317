import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog';
import { useToast } from '../../../common_component/base/use-toast';
import { Button } from '../../../common_component/base/button';
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';

type Props = {
  orderId: string | null;
  action: string;
  targetFamilyId: string;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  mutate: () => void;
};

const ActionSavedOrderDialog = ({ orderId, action, targetFamilyId, open, onOpenChange, mutate }: Props) => {
  const { toast } = useToast();

  const errorToast = () => {
    toast({
      title: 'Something went wrong. Please try again.',
      duration: 3000,
    });
  }

  const { trigger: triggerPlaceOrder, isMutating: isPlaceOrderMutating, error: placeOrderError } = useSWRMutation('/customer/place-order', async (key) => {
    const data = {
      rtype: "push_saved_for",
      targetFamilyId: targetFamilyId,
      update_order: false,
      orderId: orderId
    }

    try {
      const response = await api.customer.placeSavedOrder(data);

      if (response) {
        onOpenChange(false);
        const res = await response.json();
        if (res.rtype === 'error') {
          toast({
            title: `${res.message}`,
            duration: 3000,
          });
        } else if (!res.has_errors) {
          toast({
            title: 'The order(s) has been placed.',
            duration: 3000,
          });
          mutate();
        } else {
          errorToast()
        }
      } else {
        errorToast()
      }
    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  const { trigger: triggerRemoveOrder, isMutating: isRemoveOrderMutating, error: removeOrderError } = useSWRMutation('/customer/remove-order', async (key) => {
    const data = {
      rtype: "remove_saved_order_from_helpdesk",
      targetFamilyId: targetFamilyId,
      status: "deleted",
      orderId: orderId
    }

    try {
      const response = await api.customer.removeSavedOrder({ data: data, orderId: orderId });

      if (response) {
        onOpenChange(false);
        const res = await response.json();
        if (res.rtype === 'error') {
          toast({
            title: `${res.message}`,
            duration: 3000,
          });
        } else {
          toast({
            title: 'The saved order has been deleted.',
            duration: 3000,
          });
          mutate();
        }
      } else {
        errorToast()
      }
    } catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  });

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{action === 'placeAll' ? 'Place all' : action} order(s)</DialogTitle>
        </DialogHeader>
        <div className='flex flex-col gap-5'>
          <p className='text-sm text-card-foreground'>Are you sure you want to {action === 'remove' ? "remove order?" : "place the order(s)?"}</p>
        </div>
        {(placeOrderError || removeOrderError) && <div className="mt-2 text-sm text-destructive">{placeOrderError || removeOrderError}</div>}
        <DialogFooter>
          <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
          {action === 'remove' ?
            <Button type="button" onClick={() => triggerRemoveOrder()} disabled={isRemoveOrderMutating}>Yes</Button>
            :
            <Button type="button" onClick={() => triggerPlaceOrder()} disabled={isPlaceOrderMutating}>Yes</Button>
          }
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default ActionSavedOrderDialog;