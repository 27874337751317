import React from 'react'
import { cn } from '../../util/tailwind';
import { MyKindoTippy } from '../../common_component/tooltip/MyKindoTippy';

type Props = {
  flags: string;
}

export const DIETARY_REQUIREMENTS = {
  'GF': 'Gluten Free',
  'DF': 'Dairy Free',
  'V': 'Vegetarian',
  'H': 'Halal',
}
const Flags = ({ flags }: Props) => {
  return (
    <div className='flex gap-1'>
      {flags.split(' ').map((flag, index) => (
        <MyKindoTippy key={flag} tooltipContent={DIETARY_REQUIREMENTS[flag] ?? ''}>
          <span title={DIETARY_REQUIREMENTS[flag] ?? ''} className={cn(
            'flex items-center justify-center p-1 h-5 w-5 text-sm font-semibold',
            {
              'text-flags-gluten-free': flag === 'GF',
              'text-flags-dairy-free': flag === 'DF',
              'text-flags-vegetarian': flag === 'V',
              'text-flags-halal': flag === 'H',
            }
          )}>
            {flag}
          </span>
        </MyKindoTippy>
      ))}
    </div>
  )
}

export default Flags