import React from 'react'
import { Button } from "../../common_component/base/button";
import { SavedOrders, SavedOrderStudents } from 'networking/models/family'
import { CopyToClipboard } from '../../common_component/base/copy-to-clipboard';
import { MdContentCopy } from "react-icons/md";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../common_component/base/table";
import { tryConvertDollarStringToCents, formatCentsPriceForDisplay } from "util/string_util";
import ActionSavedOrderDialog from './dialog/ActionSavedOrderDialog'

type Props = {
  savedOrders: SavedOrders[];
  familyId: string;
  mutate: () => void;
  familyWallet: number;
}

const SavedOrderList = ({ savedOrders, familyId, mutate, familyWallet }: Props) => {
  const [showActionDialog, setShowActionDialog] = React.useState<boolean>(false)
  const [orderId, setOrderId] = React.useState<string | null>(null)
  const [action, setAction] = React.useState('')

  const savedForOrdersTotal = savedOrders.reduce((sum, savedOrder) => {
    const value = tryConvertDollarStringToCents(savedOrder.total_value);
    if (value) {
      return sum + value; // Add the converted value (in cents) to the sum
    }
    return sum;
  }, 0);

  return (
    <>
      {
        !!savedOrders ?
          <div>
            {savedOrders.length !== 0 &&
              <div className='flex items-center justify-end gap-2 my-4'>
                <p className='text-xs'>Balance after placing order(s): {formatCentsPriceForDisplay(familyWallet - savedForOrdersTotal)}</p>
                <Button type='button' onClick={() => { setAction('placeAll'); setShowActionDialog(true) }} size='xs'>Place all saved orders</Button>
              </div>
            }
            <hr />
            <Table>
              <TableHeader className="font-bold font-museo">
                <TableRow>
                  <TableHead>Order created</TableHead>
                  <TableHead>Delivery date</TableHead>
                  <TableHead>Order details</TableHead>
                  <TableHead>Total</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {savedOrders.map((order: SavedOrders) => (
                  order.students.map((student: SavedOrderStudents) => (
                    <TableRow
                      className='cursor-pointer text-card-foreground'
                      key={order.order_id}
                    >
                      <TableCell>{order.created}</TableCell>
                      <TableCell>{order.delivery_date}</TableCell>
                      <TableCell>
                        <div>
                          <div className='flex items-center gap-2 pb-1'>
                            <p className='text-sm font-semibold '>{student.Student}</p>
                            <CopyToClipboard text={student.member_id}>
                              {({ copyToClipboard, isCopied }) => (
                                <Button
                                  size="xs"
                                  className='p-0 text-xs w-fit h-fit'
                                  onClick={copyToClipboard}
                                  variant="link"
                                  type="button"
                                >
                                  {isCopied ?
                                    'Copied!'
                                    :
                                    <div className='flex items-center gap-1'>
                                      (<p>Student ID</p>
                                      <MdContentCopy className='text-xs' />)
                                    </div>
                                  }
                                </Button>
                              )}
                            </CopyToClipboard>
                          </div>
                          <p className='text-sm'>{student.Room !== '---' && `${student.Room}, `}{student.School}</p>
                          <p className='text-sm'>{student.Products}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='flex flex-col gap-1'>
                          <p>Pending topup: {order.pending_topup ? order.pending_topup : '-'}</p>
                          <p>Total value: {order.total_value}</p>
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className='flex flex-col gap-2'>
                          <Button
                            type='button'
                            onClick={() => {
                              setAction('place');
                              setOrderId(order.order_id);
                              setShowActionDialog(true);
                            }}
                            size='xs'
                          >
                            Place order
                          </Button>
                          <Button
                            type='button'
                            onClick={() => {
                              setAction('remove');
                              setOrderId(order.order_id);
                              setShowActionDialog(true);
                            }}
                            size='xs'
                          >
                            Remove order
                          </Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ))}
              </TableBody>
            </Table>
            <ActionSavedOrderDialog
              open={showActionDialog}
              onOpenChange={(open) => setShowActionDialog(open)}
              orderId={orderId}
              action={action}
              targetFamilyId={familyId}
              mutate={mutate}
            />
          </div>
          :
          <div>
            <p>No saved order(s) to display.</p>
          </div>
      }
    </>
  )
}

export default SavedOrderList