import React from 'react';
import { ColumnDef } from "@tanstack/react-table"
import { MdArrowUpward } from 'react-icons/md';
import { Button } from '../../common_component/base/button';
import { cn } from '../../util/tailwind';

// This type is used to define the shape of our table data.
export type RowItem = {
  mcat: string;
  product: string;
  stock: number;
  adjusted: string;
}

const SortableHeader = ({ column, title }) => {
  const direction = column.getIsSorted();
  return (
    <Button
      variant="link"
      className='px-0 text-sm font-bold text-left text-card-foreground'
      size="xs"
      onClick={() => column.toggleSorting(direction === "asc")}
    >
      {title}
      {
        direction && (
          <MdArrowUpward className={cn("w-4 h-4 ml-2 transition-transform", {
            'rotate-180': direction !== "asc",
          })} />
        )
      }
    </Button>
  )
};

export const stockColumns: ColumnDef<RowItem>[] = [
  {
    accessorKey: "product",
    header: ({ column }) => (
      <SortableHeader column={column} title="Product" />
    ),
    filterFn: 'multiSelect',
    size: 260
  },
  {
    accessorKey: "mcat",
    header: ({ column }) => (
      <SortableHeader column={column} title="Category" />
    ),
    filterFn: 'multiSelect',
    size: 200
  },
  {
    accessorKey: "stock",
    header: ({ column }) => (
      <SortableHeader column={column} title="Stock levels" />
    ),
    size: 100
  },
]
