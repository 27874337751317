import React from 'react'
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { Button } from 'components/base/button';
import InputField from 'components/form/InputField';
import { MdAttachMoney, MdOpenInNew } from 'react-icons/md';
import NumberedTitle from '../NumberedTitle';
import WalletBalance from '../WalletBalance';
import { amlSupportLink } from 'networking/constants';
import api from 'networking/api';
import ProfferResult from '../ProfferResult';
import BankAccountField from 'components/form/BankAccountField';
import { formatCentsPriceForDisplay } from 'util/string_util';
import InfoTooltip from 'components/tooltip/InfoTooltip';

type Props = {
  walletBalanceInCents: number;
  withdrawalFeeInCents: number;
  availableForWithdrawalInCents: number;
  email: string;
  profferId: string;
}

type FormProps = {
  bankAccountNumber: string;
  amount: string;
};
const Withdrawal = (props: Props) => {
  const {
    walletBalanceInCents,
    availableForWithdrawalInCents,
    withdrawalFeeInCents,
    email,
    profferId,
  } = props;
  const [isSuccessful, setIsSuccessful] = React.useState(false);

  const { trigger, isMutating, error } = useSWRMutation('/proffer/withdraw', (key, { arg }: { arg: FormProps }) => {
    try {
      const [bankBankNumber, bankBranchNumber, bankAccountNumber, bankSuffixNumber] = arg.bankAccountNumber.split('-');
      return api.proffer.withdraw({
        profferId,
        withdrawAmountInDollars: parseFloat(arg.amount).toFixed(2),
        bankBankNumber,
        bankAccountNumber,
        bankBranchNumber,
        bankSuffixNumber,
      }).then(async (response) => {
        // Check if response is profferResponse 
        if (response.success) {
          setIsSuccessful(true);
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })
  const { control, handleSubmit, watch } = useForm<FormProps>({
    defaultValues: {
      amount: (availableForWithdrawalInCents / 100).toFixed(2),
    }
  });
  const currentWithdrawAmount = watch('amount') || '0';
  const balanceAfterWithdrawal = (formatCentsPriceForDisplay(walletBalanceInCents - (parseFloat(currentWithdrawAmount) * 100 + withdrawalFeeInCents)));
  const onSubmit = async (data: FormProps) => {
    await trigger({
      amount: data.amount,
      bankAccountNumber: data.bankAccountNumber
    })
  }

  if (isSuccessful) {
    return (
      <ProfferResult
        title="Withdraw complete"
        description='Please allow two working days for the funds to be transferred.'
      />
    )
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className='text-base text-text-body'>
        Complete this form to transfer funds from your Kindo account to a bank account.
      </p>
      <p className='pb-4 text-sm text-text-helper'>
        To comply with anti-money laundering regulations, not all topups can be withdrawn. Learn more about&nbsp;
        <Link to={amlSupportLink} target="_blank">
          <Button
            className='px-0'
            variant="linkUnderline"
            type="button"
            size="xs"
            onClick={(e) => {
              e.stopPropagation();
            }}>
            AML <MdOpenInNew className='w-4 h-4 ml-1' />
          </Button>
        </Link>.
      </p>
      <NumberedTitle
        number="1"
        title='Withdraw amount'
      />
      <div className='py-4 sm:pl-8'>
        <p className='pb-2 text-sm text-text-body'>
          Kindo account: {email}
        </p>
        <WalletBalance
          kindoWalletBalance={walletBalanceInCents}
          withdrawableAmount={availableForWithdrawalInCents}
          withdrawalFee={withdrawalFeeInCents}
          availableForWithdrawal={availableForWithdrawalInCents}
        />
        <div className='px-4 items-start grid grid-cols-[130px_1fr] gap-x-4 sm:gap-x-2 gap-y-1 sm:gap-y-2 w-full sm:w-[400px] py-3 border-2 border-primary rounded-md mt-4'>
          <p className='text-sm text-text-body'>
            Withdraw amount:
          </p>
          <p className='text-sm text-text-body'>
            Wallet balance after withdrawal:
          </p>
          <div className='flex'>
            <InputField
              label=" "
              control={control}
              name="amount"
              step={0.01}
              max={parseFloat(`${availableForWithdrawalInCents}`).toFixed(2)}
              className={'w-full sm:w-[85px] text-right'}
              startAdornment={<MdAttachMoney />}
              type="number"
              rules={{
                required: 'Enter an amount to withdraw',
                validate: {
                  positive: value => parseFloat(value) > 0 || 'Amount must be greater than 0',
                  max: value => parseFloat(value) <= parseFloat(`${availableForWithdrawalInCents / 100}`) || `Amount must be less than or equal to ${formatCentsPriceForDisplay(availableForWithdrawalInCents)}`,
                }
              }}
            />
          </div>
          <div className='flex items-center self-center gap-1'>

            <p className='text-base font-museo text-primary'>
              {balanceAfterWithdrawal}
            </p>
            <InfoTooltip
              side='right'
              messages={[
                `Calculated as:`,
                <div className='grid grid-cols-[1fr_0fr] gap-x-3 text-sm text-text-body'>
                  <p>
                    Wallet balance:
                  </p>
                  <p className='text-right'>
                    {formatCentsPriceForDisplay(walletBalanceInCents)}
                  </p>
                  <p>
                    Withdraw amount:
                  </p>
                  <p className='text-right'>
                    -{formatCentsPriceForDisplay(parseFloat(currentWithdrawAmount) * 100)}
                  </p>
                  <p>
                    Withdrawal fee:
                  </p>
                  <p className='text-right'>
                    -{formatCentsPriceForDisplay(withdrawalFeeInCents)}
                  </p>
                  <p className='pt-2'>
                    Wallet balance after withdrawal:
                  </p>
                  <p className='pt-2 font-bold text-right'>
                    {balanceAfterWithdrawal}
                  </p>
                </div>,
              ]}
            />
          </div>

        </div>
      </div>

      <div className='flex flex-col gap-4'>
        <NumberedTitle
          number="2"
          title='Bank account to transfer to'
        />
        <div className='sm:pl-8'>
          <div
            className={'w-full sm:w-[170px]'}
          >
            <BankAccountField
              control={control}
              name="bankAccountNumber"
              label="Bank account number"
              className={'w-full'}
              rules={{
                required: 'Enter bank account',
              }}
            />
          </div>
        </div>
        {
          error && (
            <p className='text-sm text-destructive'>
              Failed to withdraw funds. Please try again.
            </p>
          )
        }
        <Button
          className='w-full md:w-[120px]'
          type="submit"
          disabled={isMutating}
        >
          Withdraw
        </Button>
      </div>
    </form>
  )
}

export default Withdrawal