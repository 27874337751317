import { format } from "date-fns";
import _ from "lodash";
import { observable, computed, action, makeObservable } from "mobx";
import { persist } from "mobx-persist";
import { Payable, Product, ServiceClosing, Student } from "networking/models/family";
import { QuestionInput } from "networking/models/order";
import analytics from '../util/googleAnalytics';

export type DietaryRequirement = "V" | "GF" | "DF" | "H";
export type FilterData = {
  dietaryRequirements: DietaryRequirement[];
  searchText?: string;
  minPriceInCents: number;
  maxPriceInCents: number;
  inStockOnly: boolean;
  availableOnly: boolean; // Available to order
}

export type OrderBy = "name:asc" | "name:desc" | "price:asc" | "price:desc" | "default";

const defaultFilterData: FilterData = {
  dietaryRequirements: [],
  searchText: "",
  minPriceInCents: 0,
  maxPriceInCents: 0,
  inStockOnly: false,
  availableOnly: true,
};

type MenuCategroyInfo = {
  name: string;
  count: number;
  filteredCount: number;
}

export type TemporaryCartProductItem = {
  cartKey: string;
  productId: string;
  name: string;
  quantity: number;
  options?: string[];
  questions?: QuestionInput[];
  choice?: string;
  priceInCents: number;
  priceInCentsWithOptions: number;
  gst: string;
  payable?: Payable;
  flexiPriceInCents?: number;
  subtotal: number;
  picture?: string;
  supplierId?: string;
  isEzlunchService?: boolean;
  product: Product;
  permanentId: string;
  includedOptionCount: number;
  optionPriceInCents: number;
  addedAt: Date;
  serviceId?: string;
}
export type TemporaryCartDelivery = {
  cartKey: string;
  products: {
    [key: string]: TemporaryCartProductItem
  };
  deliveryDate?: Date;
  serviceId: string;
  closing?: ServiceClosing;
  addedAt: Date;
}
type TempoaryCartItem = {
  deliveries: {
    [key: string]: TemporaryCartDelivery
  };
  schoolId: string;
  studentId: string;
  memberType?: string | number;
  student: Student;
  room: string;
  memberId: string;
  address: string;
  firstName: string;
  lastName: string;
  addedAt: Date;
};

export type CartActionProps = {
  student: Student;
  product: Product;
  deliveryDate?: Date;
  quantity?: number;
  serviceId: string;
  schoolId: string;
  closing?: ServiceClosing;
  supplierId?: string;
  isEzlunchService?: boolean;
  listName?: 'Cart' | 'Service' | 'Item Detail' | 'Precheckout' | 'Fees and Donations' | 'Spread a little kindness';
};
export default class ShopStore {
  constructor() {
    makeObservable(this)
  }
  @observable showDatePicker: boolean = false;
  @persist @observable datePickerView: 'calendar' | 'list' = 'calendar';
  @observable selectedOrderDates: Date[] = [];
  @observable menuCategoryCounts = new Map<string, number>();
  @observable menuCategoryInfos = new Map<string, MenuCategroyInfo>();
  @observable orderBy?: OrderBy = undefined;
  @observable scrollToCategoryId?: string = undefined;
  @observable scrollToActiveId?: string = undefined;
  @persist @observable completedPrecheckout: boolean = false;
  @observable showPrecheckout: boolean = false;
  @persist('object') @observable temporaryCart: { [key: string]: TempoaryCartItem } = {};
  @persist @observable isCommunityShop: boolean = false;
  @observable isAccountCreationRequired: boolean = false;
  @observable isTaxGiftingSucess: boolean = false;
  @persist @observable paymentInitiatedFrom: 'wallet' | 'checkout' = 'checkout';
  @persist @observable topupAmount: number = 0; // In cents
  @persist @observable showDownloadAppBanner: boolean = true;
  @persist @observable hideDownloadBannerUntil?: number;

  getCartDeliveryKey({ deliveryDate, schoolId, serviceId }: CartActionProps) {
    return `${deliveryDate ? `${format(new Date(deliveryDate), 'yyyy-MM-dd')}-` : ''}${schoolId}-${serviceId}`;
  }
  getCartStudentKey({ student, schoolId }: { student: Student; schoolId: string }) {
    return `${student.student_id}-${schoolId}`;
  }
  getCartProductKey({ student, product }: CartActionProps) {
    return `${student.student_id}-${product.productId}-${product.payable ? `${product.payable.payable_id}-` : ''}${JSON.stringify(_.orderBy(product.questions, 'question_id'))}-${product.choice}-${JSON.stringify((product.options ?? []).sort())}`;
  }

  @action
  setIsCommunityShop(shop: boolean) {
    this.isCommunityShop = shop;
  }

  @action
  setIsAccountCreationRequired(isRequired: boolean) {
    this.isAccountCreationRequired = isRequired;
  }

  @action
  setIsTaxGifitingSuccess(success: boolean) {
    this.isTaxGiftingSucess = success;
  }

  @action
  addToCart(props: CartActionProps) {
    const productKey = this.getCartProductKey(props);
    const studentKey = this.getCartStudentKey(props);
    const deliveryKey = this.getCartDeliveryKey(props);
    const { listName, student, product, deliveryDate, quantity = 1, schoolId, serviceId, closing, supplierId, isEzlunchService } = props;

    const studentCart = this.temporaryCart[studentKey];

    const extraOptionsCount = (product.options?.length ?? 0) - (product.included_option_count ?? 0);
    const priceInCents = parseInt(product.price_in_cents);
    const priceInCentsWithOptions = extraOptionsCount <= 0 ? priceInCents : priceInCents + ((product.option_price_in_cents ?? 0) * extraOptionsCount);
    const newProduct = {
      cartKey: productKey,
      productId: product.productId,
      name: product.product,
      quantity: quantity,
      options: product.options,
      questions: product.questions,
      choice: product.choice,
      priceInCents: priceInCents,
      priceInCentsWithOptions,
      gst: product.gst,
      payable: product.payable,
      flexiPriceInCents: product.flexi_price_nominated_in_cents,
      subtotal: quantity * priceInCentsWithOptions,
      picture: product.picture,
      supplierId,
      isEzlunchService,
      product,
      permanentId: product.permanent_id,
      includedOptionCount: product.included_option_count,
      optionPriceInCents: product.option_price_in_cents,
      addedAt: new Date(),
      serviceId,
    }
    if (studentCart && studentCart.deliveries) {
      const existingDelivery = studentCart.deliveries[deliveryKey];
      const existingProduct = existingDelivery?.products[productKey];
      const newQuantity = existingProduct ? existingProduct.quantity + quantity : quantity;
      this.temporaryCart = {
        ...this.temporaryCart,
        [studentKey]: {
          ...this.temporaryCart[studentKey],
          deliveries: {
            ...this.temporaryCart[studentKey]?.deliveries,
            [deliveryKey]: {
              ...(existingDelivery ? existingDelivery : {
                addedAt: new Date(),
                cartKey: deliveryKey,
              }),
              products: {
                ...(existingDelivery?.products || {}),
                [productKey]: {
                  ...(existingProduct ? {
                    ...existingProduct,
                    quantity: newQuantity,
                    subtotal: newQuantity * existingProduct.priceInCentsWithOptions,
                  } : newProduct),
                },
              },
              deliveryDate,
              serviceId,
              closing,
            },
          },
        },
      };
    } else {
      this.temporaryCart = {
        ...this.temporaryCart,
        [studentKey]: {
          deliveries: {
            [deliveryKey]: {
              products: {
                [productKey]: newProduct,
              },
              cartKey: deliveryKey,
              deliveryDate,
              serviceId,
              closing,
              addedAt: new Date(),
            }
          },
          schoolId,
          room: student.room,
          studentId: student.student_id,
          memberId: student.member_id,
          memberType: student.member_type,
          address: student.address,
          firstName: student.first_name,
          lastName: student.last_name,
          student,
          addedAt: new Date(),
        }
      }
    };

    analytics.addToCart({
      items: [{
        productId: product.productId,
        productName: product.product,
        brandName: isEzlunchService ? 'ezlunch' : 'kindo',
        serviceId,
        supplierId: product.supplierId,
        quantity,
        choice: product.choice,
        options: product.options,
        priceInCents: priceInCentsWithOptions,
      }],
      listName,
    })
  }

  @action
  removeFromCart(props: CartActionProps) {
    const productKey = this.getCartProductKey(props);
    const studentKey = this.getCartStudentKey(props);
    const deliveryKey = this.getCartDeliveryKey(props);
    const { quantity = 1, listName, serviceId } = props;

    const studentCart = this.temporaryCart[studentKey];
    if (studentCart) {
      const allDeliveries = Object.values(studentCart.deliveries);
      let delivery = studentCart.deliveries[deliveryKey];
      let existingProductIndex = -1;
      if (delivery) {
        const allProducts = Object.values(delivery.products);
        existingProductIndex = allProducts.findIndex((p) => this.getCartProductKey({ ...props, product: p.product }) === productKey);
      }
      if (!delivery || existingProductIndex === -1) {
        // Couldn't find a delivery for this date or a product, search all deliveries for this product
        allDeliveries.forEach((d) => {
          const allProducts = Object.values(d.products);
          existingProductIndex = allProducts.findIndex((p) => p.productId === props.product.productId);
          if (existingProductIndex > -1) {
            delivery = d;
          }
        });
      }

      const allProducts = Object.values(delivery?.products ?? {});
      if (existingProductIndex !== -1) {
        const existingProduct = allProducts[existingProductIndex];
        const updatedProduct = {
          ...existingProduct,
          quantity: existingProduct.quantity - quantity,
          subtotal: (existingProduct.quantity - quantity) * existingProduct.priceInCentsWithOptions,
        };


        const updatedProducts = allProducts
          .slice(0, existingProductIndex)
          .concat(updatedProduct)
          .concat(allProducts.slice(existingProductIndex + 1))
          .filter((p) => p.quantity > 0);

        const updatedDelivery = {
          ...delivery,
          products: updatedProducts
            .reduce((acc, product) => {
              return {
                ...acc,
                [product.cartKey]: product
              };
            }, {}),
        };
        const updatedDeliveries = Object.keys(studentCart.deliveries).reduce((acc, key) => {
          const d = key === delivery.cartKey ? updatedDelivery : studentCart.deliveries[key];
          if (Object.keys(d.products).length > 0) {
            return {
              ...acc,
              [key]: d,
            }
          } return acc;
        }, {});

        if (Object.keys(updatedDeliveries).length > 0) {
          const updatedStudentCart = {
            ...studentCart,
            deliveries: updatedDeliveries,
          };
          this.temporaryCart = {
            ...this.temporaryCart,
            [studentKey]: updatedStudentCart,
          }
        } else {
          const { [studentKey]: _, ...newCart } = this.temporaryCart;
          this.temporaryCart = newCart;
        }

        analytics.removeFromCart({
          items: [{
            serviceId,
            supplierId: existingProduct.supplierId,
            productId: existingProduct.productId,
            brandName: existingProduct.isEzlunchService ? 'ezlunch' : 'kindo',
            productName: existingProduct.name,
            quantity,
            priceInCents: existingProduct.priceInCents,
            choice: existingProduct.choice,
            options: existingProduct.options,
          }],
          listName,
        })
      }
    }

  }

  @action
  clearTempoaryCart() {
    this.temporaryCart = {};
  }

  @computed
  get temporaryCartPriceInCents(): number {
    let total = 0;
    Object.values(this.temporaryCart).forEach(studentCart => {
      Object.values(studentCart.deliveries).forEach(delivery => {
        Object.values(delivery.products).forEach(product => {
          total += product.flexiPriceInCents || product.subtotal;
        });
      });
    });
    return total;
  }

  @computed
  get temporaryCartItemCount(): number {
    let quantity = 0;
    Object.values(this.temporaryCart).forEach(studentCart => {
      Object.values(studentCart.deliveries).forEach(delivery => {
        Object.values(delivery.products).forEach(product => {
          quantity += product.quantity;
        });
      });
    });
    return quantity;
  }

  @computed
  get allCartItems(): TemporaryCartProductItem[] {
    return Object.values(this.temporaryCart).flatMap(studentCart => {
      return Object.values(studentCart.deliveries).flatMap(delivery => {
        return Object.values(delivery.products);
      });
    });
  }


  /**
   * Gets the quantity of a product in the cart for a given student
   * @param props 
   * @returns 
   */
  getQuantityInCart(props: { student: Student; productId: string; schoolId: string; payableId?: string; }): number {
    const studentCartKey = this.getCartStudentKey({
      student: props.student,
      schoolId: props.schoolId,
    });
    const studentCart = this.temporaryCart[studentCartKey];
    if (studentCart) {
      const allDeliveries = Object.values(studentCart.deliveries);
      const quantities = allDeliveries.reduce((acc, delivery) => {
        const allProducts = Object.values(delivery.products);
        const productQuantities = allProducts.reduce((productAcc, product) => {
          if (product.productId === props.productId && props.payableId === product.payable?.payable_id) {
            productAcc += product.quantity;
          }
          return productAcc;
        }, 0);

        return acc + productQuantities;
      }, 0);
      return quantities;
    }

    return 0;
  }

  /**
   * Gets the subtotal of a product in the cart for a given student
   * @param props 
   * @returns 
   */
  getSubtotalForProductInCart(props: { student: Student; productId: string; schoolId: string; payableId?: string; }): number {
    const studentCartKey = this.getCartStudentKey({
      student: props.student,
      schoolId: props.schoolId,
    });
    const studentCart = this.temporaryCart[studentCartKey];
    if (studentCart) {
      const allDeliveries = Object.values(studentCart.deliveries);
      const subtotal = allDeliveries.reduce((acc, delivery) => {
        const allProducts = Object.values(delivery.products);
        const productSubtotals = allProducts.reduce((productAcc, product) => {
          if (product.productId === props.productId && product.payable?.payable_id === props.payableId) {
            productAcc += product.flexiPriceInCents || product.subtotal;
          }
          return productAcc;
        }, 0);

        return acc + productSubtotals;
      }, 0);
      return subtotal;
    }

    return 0;
  }

  @action
  setSelectedOrderDates(dates: Date[]) {
    this.selectedOrderDates = dates;
  }

  @observable showFilters: boolean = false;
  @observable currentFilters: FilterData = defaultFilterData;

  @action
  setShowDatePicker(show: boolean) {
    this.showDatePicker = show;
  }

  @action
  setCurrentFilters(filters: FilterData) {
    this.currentFilters = {
      ...this.currentFilters,
      ...filters,
    };
  }

  @action
  setShowFilters(show: boolean) {
    this.showFilters = show;
  }
  @action
  clearFilters() {
    this.currentFilters = defaultFilterData;
    this.orderBy = undefined;
  }

  @action
  setSearchText(searchText: string) {
    this.currentFilters.searchText = searchText;
  }
  @action
  setOrderBy(orderBy: OrderBy) {
    this.orderBy = orderBy;
  }

  @computed
  get isFiltering() {
    return this.currentFilters.dietaryRequirements.length > 0
      || this.currentFilters.searchText !== ""
      || this.currentFilters.minPriceInCents > 0
      || this.currentFilters.maxPriceInCents > 0
      || this.currentFilters.inStockOnly
      || this.currentFilters.availableOnly;
  }

  @action
  setScrollToCategoryId(categoryId: string) {
    this.scrollToCategoryId = categoryId;
  }
  @action
  setScrollToActiveId(id: string) {
    this.scrollToActiveId = id;
  }

  @action
  setPrecheckout(checkedOut: boolean) {
    this.completedPrecheckout = checkedOut;
  }

  @action
  setShowPrecheckout(show: boolean) {
    this.showPrecheckout = show;
  }

  @action
  setDatePickerView(view: 'calendar' | 'list') {
    this.datePickerView = view;
  }

  @action
  setPaymentInitiatedFrom(initiatedFrom: 'wallet' | 'checkout') {
    this.paymentInitiatedFrom = initiatedFrom;
  }

  @action
  setTopupAmount(amount: number) {
    this.topupAmount = amount;
  }

  @action
  setShowDownloadAppBanner(show: boolean) {
    this.showDownloadAppBanner = show;
  }

  @action
  setHideDownloadAppBannerUntil(duration: number) {
    this.hideDownloadBannerUntil = duration;
  }
}
