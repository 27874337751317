import useSWR, { useSWRConfig } from 'swr';
import { baseApi, baseUrl, productImageDirectory } from './constants';
import { Menu, Product, Service, SchoolService } from './models/family';
import { CheckoutOrder, OrderInput, ProductInformation, CheckoutDeliveryAddressList, CheckoutOffer, PaymentDetails, OnlineEftposVerifyResponse } from './models/order';
import { pathify } from "./util/util";
import { KindoNetworkError } from './models/common';
import fetcher from './util/fetcher';

const order = {
  // Queries (starts with "use")
  useOrderItems: () => {
    const params = new URLSearchParams({
      offers: "true",
    }).toString();
    const data = useSWR<CheckoutOrder, KindoNetworkError>(`${baseApi}/FAMILY/orders?${params}`);
    return data;
  },

  useAddOrder: () => {
    return useSWR(`${baseApi}/orders`);
  },

  useMenu: (url: string) => {
    return useSWR<Menu, KindoNetworkError>(`${baseUrl}${url}`);
  },
  usePackProds: (packProdUrl?: string) => {
    return useSWR<Menu, KindoNetworkError>(packProdUrl ? `${baseUrl}${packProdUrl}` : null);
  },

  useFullMenu: (service?: Service) => {
    const menuCategoryUrls = service?.mcats.map((mcat) => mcat.url);
    return useSWR<Menu, KindoNetworkError>(service ? [service.id, menuCategoryUrls] : null, async ([serviceId, menuCategoryUrls]) => {
      const requests = menuCategoryUrls.map((url: string) => fetcher(`${baseUrl}${url}`));
      const responses = await Promise.all(requests);
      const menuCategoryData = await Promise.all(responses.map((response) => {
        if (response.ok) {
          return response.json();
        }
      }));
      const allProducts = menuCategoryData.reduce((result, data, index) => {
        return [
          ...result,
          ...((data?.items ?? []).map((item: Product) => {
            const menuCat = service?.mcats.find((mcat) => mcat.url === menuCategoryUrls[index]);
            return {
              ...item,
              menuCategoryId: menuCat?.id,
              supplierId: menuCat?.supplier_id
            }
          })),
        ]
      }, []);
      return {
        items: allProducts,
      } as Menu;
    }, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
  },

  useProductInformation: ({
    school_id,
    service_id
  }: { school_id: string; service_id: string; }) => {
    const params = new URLSearchParams({
      school_id,
      service_id,
    }).toString();
    return useSWR<ProductInformation, KindoNetworkError>(`${baseApi}/FAMILY/product_stock_and_questions?${params}`);
  },

  useProductOffers: (offers: CheckoutOffer[]) => {
    const schoolIds = offers?.map((offer) => offer.school_id);

    return useSWR<Product[], KindoNetworkError>(offers ? schoolIds : null, async (schoolIds) => {
      const requests = schoolIds.map((id: string) => fetcher(`${baseUrl}json/${pathify(id)}/${pathify(id)}.json`));
      const responses = await Promise.all(requests);

      const schoolData = await Promise.all(responses.map(async (response) => {
        if (response.ok) {
          return response.json() as SchoolService;
        }
      }));

      const filteredProducts = await Promise.all(schoolData.map(async (school) => {
        if (!school) {
          return [];
        }
        const matchingOffers = offers.sort(function (a: any, b: any) {
          return a.is_default_offer - b.is_default_offer;
        }).filter(
          (offer) =>
            offer.school_id.trim().toLowerCase() === school.school_id.trim().toLowerCase()
        );

        if (matchingOffers.length > 0) {
          const matchingServiceIds = matchingOffers.flatMap(
            (offer) => offer.service_id
          );

          const matchingMcatIds = matchingOffers.flatMap(
            (offer) => offer.mcat_ids || []
          );

          const matchingPermIds = matchingOffers.flatMap(
            (offer) => offer.perm_ids || []
          );

          const matchingServices = school.services.filter((service) =>
            matchingServiceIds.includes(service.id)
          );

          const serviceProducts = await Promise.all(matchingServices.map(async (service) => {
            const mcats = service.mcats.filter((mcat) => matchingMcatIds.length !== 0 ? matchingMcatIds.includes(mcat.id) : true)

            const mcatRequest = mcats.flatMap((mcat: any) => fetcher(`${baseUrl}${mcat.url}`, {
              method: "GET",
              credentials: "same-origin"
            }));
            const mcatResponses = await Promise.all(mcatRequest);

            const stockRequest = matchingServices.flatMap((service: any) => fetcher(`${baseApi}/FAMILY/product_stock_and_questions?service_id=${service.id}&school_id=${school.school_id}`, {
              method: "GET",
              credentials: "same-origin"
            }));
            const stockRequestResponses = await Promise.all(stockRequest);

            const productPromises = mcatResponses.map((response) => response.json() as Menu); // Get an array of product promises
            const productsData = await Promise.all(productPromises); // Wait for all product promises to resolve
            const productStock = stockRequestResponses.map((response) => response.json());
            const productStockData = await Promise.all(productStock);

            const products = productsData.reduce<Product[]>((result, data, index) => {
              const mcat = mcats[index];
              const productObjs = data.items.map((prod: Product) => ({
                ...prod,
                schoolId: school.school_id,
                service: service,
                menuCategoryId: mcat.id,
                supplierId: mcat.supplier_id,
                productStockAndQuestions: productStockData.find(item => (item.product_questions[prod.permanent_id] !== undefined || item.product_stock[prod.permanent_id] !== undefined) ?? item)
              }));

              if (matchingPermIds === null) {
                return [
                  ...result,
                  ...productObjs,
                ];
              } else {
                return [
                  ...result,
                  ...productObjs.filter((product: any) => matchingPermIds.length !== 0 ? matchingPermIds.includes(product.permanentId) : true),
                ];
              }
            }, []);
            return products;
          }));
          return serviceProducts.flat();
        }
      }));

      const flattenedProducts = filteredProducts.flat();

      return flattenedProducts;
    }, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
  },

  useAddressSelectorList: (text: string) => {
    return useSWR<CheckoutDeliveryAddressList, KindoNetworkError>(text.length >= 5 ? `${baseApi}/addr/search?text=${encodeURIComponent(text)}` : undefined);
  },

  // Given a list of products, return a list of products that have images, uses service id as cache key
  useProductImages: (serviceId?: string, products?: Product[], maxProducts = 4) => {
    return useSWR<Product[]>(serviceId && products && [serviceId, products], async ([id, items]) => {
      let currentIndex = 0;
      let validProducts = [];
      while (validProducts.length < maxProducts && currentIndex < (items || []).length) {
        const currentItem = (items || [])[currentIndex];
        if (currentItem) {
          const response = await fetcher(`${productImageDirectory}/${currentItem.supplierId}/${currentItem.picture}`, {
            cache: 'force-cache'
          });
          if (response.ok) {
            if (validProducts.length < maxProducts) {
              validProducts.push(currentItem);
            }
          }
          currentIndex++;
        }
      }
      return validProducts;
    }, {
      revalidateIfStale: false,
      revalidateOnFocus: false,
    });
  },
  useVerifyOnlineEftposResponse: ({
    signature,
    paymentDetails,
  }: {
    signature?: string;
    paymentDetails?: string;
  }) => {
    const params = new URLSearchParams({
      signature: encodeURI(signature || ''),
      paymentDetails: paymentDetails || '',
    }).toString();
    return useSWR<OnlineEftposVerifyResponse, KindoNetworkError>(signature && paymentDetails && `${baseApi}/topups/paymark/verify_response?${params}`, {
      refreshInterval: 1500,
    });
  },

  // Mutations
  addOrder: (order: OrderInput) => {
    return fetcher(`${baseApi}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(order),
    })
  },
  removeOrder: (orderId: string) => {
    return fetcher(`${baseApi}/orders/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        status: 'deleted'
      }),
    })
  },
  addOrderAddress: (orderId: string, address: any) => {
    return fetcher(`${baseApi}/orders/${orderId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        delivery_address: address,
        rtype: 'delivery_address_ref'
      }),
    })
  },
  placeOrder: (voucherToken?: string) => {
    let data = {
      items: "__cart__",
      ...(voucherToken && { voucher_token: voucherToken })
    }
    return fetcher(`${baseApi}/orders/actioned/FAMILY?r=${Math.random().toString()}&is_old_checkout=false`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
  cancelOnlineEftposPayment: ({ topupId }:
    { topupId: string; }) => {
    return fetcher(`${baseApi}/topups/oe/cancelled`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify({
        topup_id: topupId,
      }),
    });
  },
  cancelOrder: (purcahseId: string) => {
    let data = {
      purchase_id: purcahseId,
      recycle: false
    }
    return fetcher(`${baseApi}/cancellations`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
  },
}

export default order;