import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter
} from "../../common_component/base/dialog";
import api from 'networking/api';
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../common_component/base/tabs"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../common_component/base/card"
import orderUtils from 'networking/util/order';
import { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogTitle, AlertDialogFooter, AlertDialogAction, AlertDialogCancel } from '../../common_component/base/alert-dialog'
import { useToast } from '../../common_component/base/use-toast';
import analytics from '../../util/googleAnalytics';
import { Button } from '../../common_component/base/button';
import { useNavigate } from 'react-router-dom';

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  purchases: any;
  events: any;
  date: string;
  dateYMD: string;
};

const TodayViewDialog = ({ open, onOpenChange, purchases, events, date, dateYMD }: Props) => {
  const { data: order, mutate } = api.order.useOrderItems();
  const { toast } = useToast();
  const navigate = useNavigate();

  const itemsGroupedByDeliveryDate = React.useMemo(() => {
    return orderUtils.getOrderGroupedByDeliveryDate(order?.items);
  }, [order]);

  const removeOrderAlertContent = (student) => (
    <AlertDialogContent>
      <AlertDialogTitle>Remove this item?</AlertDialogTitle>
      <div className='flex flex-col gap-4'>
        <p>Are you sure you want to remove your order?</p>
      </div>
      <AlertDialogFooter>
        <AlertDialogCancel className='font-semibold font-museo'>Cancel</AlertDialogCancel>
        <AlertDialogAction onClick={handleRemoveOrder(student)} className='font-semibold font-museo'>
          Remove
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  )

  const handleRemoveOrder = (student) => () => {
    api.order.removeOrder(student.orderId).then((res) => {
      if (res.ok) {
        mutate();
        toast({
          title: 'Removed:',
          description: (
            <div className='mt-2 font-semibold text-warning'>
              {student.products.map((product) => (
                <p className='mt-2 font-semibold text-warning'>
                  {product.product}
                </p>
              ))}
            </div>
          )
        });
        analytics.removeOrder({
          orderId: student.orderId,
          itemCount: student.products.length,
          listName: 'Calendar',
          orderPriceInCents: student.totalPrice,
        })
      }
    })
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className='w-auto sm:min-w-[45vw]'>
        <DialogHeader>
          <DialogTitle>{date}</DialogTitle>
        </DialogHeader>
        <div>
          <Tabs defaultValue={!itemsGroupedByDeliveryDate[dateYMD]?.students && purchases.length === 0 ? 'events' : 'orders'}>
            <TabsList variant="primary" className="grid w-full grid-cols-2">
              <TabsTrigger value="orders">Orders</TabsTrigger>
              <TabsTrigger value="events">Events</TabsTrigger>
            </TabsList>
            <TabsContent value="orders">
              <Card>
                <CardHeader>
                  <CardTitle>Orders</CardTitle>
                </CardHeader>
                <CardContent>
                  {
                    itemsGroupedByDeliveryDate[dateYMD]?.students?.map((student) => (
                      <>
                        <div className='pb-2'>
                          <p className='font-semibold text-primary font-museo'>
                            {student.firstName} <span className='italic'>(Awaiting order{student.products.length === 1 ? '' : 's'} at checkout)</span>
                          </p>
                          <ul className='pl-4 list-disc'>
                            {student.products.map((product) => (
                              <li>
                                <div className='flex items-center justify-between gap-2'>
                                  <div>
                                    {product.product}
                                  </div>
                                  <div className='flex items-center gap-2'>
                                    <AlertDialog>
                                      <AlertDialogTrigger asChild>
                                        <Button variant='linkUnderline' size='xs' className='text-xs text-destructive'>
                                          Remove
                                        </Button>
                                      </AlertDialogTrigger>
                                      {removeOrderAlertContent(student)}
                                    </AlertDialog>
                                    <Button variant='linkUnderline' size='xs' className='text-xs' onClick={() => navigate('/app/checkout')}>
                                      Go to checkout
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div >
                      </>
                    ))
                  }
                  {purchases.length !== 0 &&
                    purchases.map((purchase) => (
                      purchase.detail.students.map((student) => (
                        <div className='pb-2'>
                          <p className='font-semibold text-primary font-museo'>
                            {student.firstName}
                          </p>
                          <ul className='pl-4 list-disc'>
                            {student.products.map((product) => (
                              <li>{product.product}</li>
                            ))}
                          </ul>
                        </div>
                      ))
                    ))
                  }
                  {!itemsGroupedByDeliveryDate[dateYMD]?.students && purchases.length === 0 &&
                    <p>
                      There are no orders for today.
                    </p>
                  }
                </CardContent>
              </Card>
            </TabsContent>
            <TabsContent value="events">
              <Card>
                <CardHeader>
                  <CardTitle>Events</CardTitle>
                </CardHeader>
                <CardContent>
                  {events.length !== 0 ?
                    <div className='pl-4'>
                      <ul className='list-disc'>
                        {events.map((event) => (
                          <li>{event.text}</li>
                        ))}
                      </ul>
                    </div>
                    :
                    <p>
                      There are no events for today.
                    </p>
                  }
                </CardContent>
                <CardFooter>
                </CardFooter>
              </Card>
            </TabsContent>
          </Tabs>
        </div>
        <DialogFooter>
          <Button type="button" className='font-semibold font-museo' onClick={() => onOpenChange(false)}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default TodayViewDialog