import React from 'react'
import { useForm } from 'react-hook-form';

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../common_component/base/dialog";
import InputField from '../../../common_component/form/InputField';
import TextField from '../../../common_component/form/TextField';
import { Button } from '../../../common_component/base/button';
import { ScrollArea } from '../../../common_component/base/scroll-area';
import { cn } from '../../../util/tailwind';
import useSWRMutation from 'swr/mutation';
import api from 'networking/api';
import { useStore } from 'store/store';
import { AdminPayable } from 'networking/models/admin';
import { Spinner } from 'components/base/spinner';
import { useToast } from 'components/base/use-toast';
import { Chip } from 'components/base/chip';
import InfoTooltip from 'components/tooltip/InfoTooltip';
import CheckboxField from 'components/form/CheckboxField';

type FormData = {
  subject: string;
  body: string;
  preview?: boolean;
  salutations: string;
  lastName: boolean;
}
type Props = {
  payables: AdminPayable[];
  open: boolean;
  onOpenChange: (open: boolean) => void;
  onActionSuccess?: () => void;
}

const MAX_PREVIEW_COUNT = 20;
type EmailStep = 'compose' | 'preview' | 'review';
const ActionsDialog = (props: Props) => {
  const {
    payables,
    open,
    onOpenChange,
    onActionSuccess,
  } = props;
  const store = useStore();
  const { toast } = useToast();
  const currentSchool = store.admin.currentSchool;
  const [emailToPreviewIndex, setEmailToPreviewIndex] = React.useState<number>(0);
  const [emailStep, setEmailStep] = React.useState<EmailStep>('compose');
  const { control, handleSubmit, getValues, watch, setValue } = useForm<FormData>({
    defaultValues: {
      subject: 'Payment request for [STUDENT_FIRST_NAME] [STUDENT_LAST_NAME] at [SCHOOL_NAME]',
      body: 'The following are available for payment through myKindo:',
      salutations: 'Kia ora',
      lastName: false,
    },
    mode: 'onChange',
  });
  const { trigger, data: emailData, error, isMutating } = useSWRMutation('/paymentRequest', (key, { arg }: { arg: FormData }) => {
    try {
      return api.admin.sendPaymentRequests({
        subject: arg.subject,
        schoolId: currentSchool,
        preview: arg.preview,
        salutations: arg.salutations,
        customMessage: arg.body,
        maxReturnMessages: arg.preview ? MAX_PREVIEW_COUNT : 0,
        payableIds: payables.map((payable) => payable.student_payable_id!).filter((id) => !!id),
      }).then(async (response) => {
        return response;
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })
  const onSubmit = (values: FormData) => {
    if (emailStep !== 'review') {
      handleSubmit(onPreview('review'))();
    } else {
      trigger({
        ...values,
        preview: false,
      }).then((response) => {
        if (response?.error) {
          toast({
            title: 'Something went wrong, please try again',
            description: response.error.message,
            duration: 8000,
            variant: 'destructive',
          });
        }
        if (response?.data) {
          onOpenChange(false);
          toast({
            title: `Emails sent to ${response.data.composed_count} recipients`,
            duration: 8000,
          });
          if (onActionSuccess) {
            onActionSuccess();
          }
        }
      });
    }
  };

  const onPreview = (step: EmailStep) => () => {
    const values = getValues();
    if (emailStep === 'compose') {
      setEmailStep(step);
    } else {
      setEmailStep(step);
    }
    trigger({
      ...values,
      preview: true,
    });
  }

  const getTitle = () => {
    switch (emailStep) {
      case 'compose':
        return 'Edit Email';
      case 'preview':
        return 'Preview Email';
      case 'review':
        return 'Send Emails';
      default: return '';
    }
  }
  const getDescription = () => {
    switch (emailStep) {
      case 'compose':
        return 'Edit an Email to send to selected records to remind them about outstanding payables';
      case 'preview':
        return 'Preview Email before sending payment requests to selected records';
      case 'review':
        return 'Please review your action';
      default: return '';
    }
  }

  const emailToPreview = emailData?.data?.messages[emailToPreviewIndex];
  const showLastName = watch('lastName');
  const currentSubject = watch('subject');

  React.useEffect(() => {
    // Update the subject by replacing the placeholder
    const updatedSubject = showLastName
      ? currentSubject.replace('Payment request for [STUDENT_FIRST_NAME] at [SCHOOL_NAME]', 'Payment request for [STUDENT_FIRST_NAME] [STUDENT_LAST_NAME] at [SCHOOL_NAME]')
      : currentSubject.replace('Payment request for [STUDENT_FIRST_NAME] [STUDENT_LAST_NAME] at [SCHOOL_NAME]', 'Payment request for [STUDENT_FIRST_NAME] at [SCHOOL_NAME]');

    setValue('subject', updatedSubject);
  }, [showLastName, currentSubject, setValue]);

  React.useEffect(() => {
    // When the dialog is opened, reset the email state to compose
    if (open) {
      setEmailStep('compose');
    }
  }, [open])
  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className={cn({
        'min-w-[75vw]': emailStep === 'preview',
      })}>
        <DialogHeader className='z-10'>
          <DialogTitle>
            {getTitle()}
          </DialogTitle>
          <DialogDescription>
            {getDescription()}
          </DialogDescription>
        </DialogHeader>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='flex flex-col gap-4 text-card-foreground'>
            {
              emailStep === 'compose' && (
                <>
                  <div className='flex flex-col gap-3 pb-2'>
                    <InputField
                      name="subject"
                      control={control}
                      label="Subject"
                      required
                      rules={{
                        required: 'Subject is required',
                      }}
                    />
                    <CheckboxField
                      control={control}
                      label="Show student's full name"
                      name="lastName"
                    />
                    <div className='flex items-end gap-2'>
                      <InputField
                        name="salutations"
                        control={control}
                        label="Salutations"
                        required
                        rules={{
                          required: 'Salutations is required',
                          maxLength: {
                            value: 256,
                            message: 'Salutations must be less than 256 characters'
                          }
                        }}
                        endAdornment={<InfoTooltip tooltip="Customise how you would like to greet the caregiver" />}
                      />
                      <Chip
                        text="CAREGIVER_NAME"
                      />
                    </div>
                    <TextField
                      name="body"
                      required
                      control={control}
                      rows={4}
                      label="Message"
                      rules={{
                        required: 'Message is required',
                        validate: {
                          validCharacters: (value) => {
                            // Backend end supports: [A-Za-z0-9]+ \]\[\n + \.%!&\)\(\-,/:_@#'\$\?\+
                            const invalid = value.match(/[^A-Za-z0-9\]\[\n + \.%!&\)\(\-,/:_@#'\$\?\+]/g);
                            if (invalid) {
                              // Extract the invalid characters from the regex match and remove duplicates
                              const invalidCharacters = [...new Set(invalid)].join(' ');
                              return `The following characters are not allowed: ${invalidCharacters}`;
                            }
                          }
                        }
                      }}
                    />
                  </div>
                </>
              )
            }
            {
              emailStep === 'preview' && (
                <>
                  <div className='grid grid-cols-[250px_1fr] gap-6'>
                    <div className='flex flex-col gap-1'>
                      <p className='text-sm font-bold text-text-helper'>Select recipient to preview</p>
                      <ScrollArea>
                        <div className='flex flex-col max-h-[400px] border-r-1 border-border'>
                          {
                            emailData?.data?.messages.map((message, index) => (
                              <Button
                                className={cn('justify-start h-auto text-left rounded-none text-card-foreground border-b-1 border-border last:border-0', {
                                  'font-bold bg-border': index === emailToPreviewIndex,
                                })}
                                type="button"
                                variant="list"
                                key={message.url}
                                onClick={() => setEmailToPreviewIndex(index)}
                              >
                                {message.to_addrs[0]}
                              </Button>
                            ))
                          }

                          {
                            !isMutating && emailData?.data?.composed_count === 0 && (
                              <p className='text-sm'>
                                No emails to preview
                              </p>
                            )
                          }
                        </div>
                      </ScrollArea>
                      {
                        emailData?.data?.composed_count! > MAX_PREVIEW_COUNT && (
                          <p className='text-sm text-text-helper'>
                            Previewing {MAX_PREVIEW_COUNT} of {emailData?.data?.composed_count} recipients
                          </p>
                        )
                      }
                    </div>
                    <div className='flex w-full h-full'>
                      {
                        emailToPreview ? (
                          <div className='flex flex-col flex-1 gap-2'>
                            <div className='flex flex-row items-center gap-3 pl-3'>
                              <p className='text-sm text-text-helper'>To</p>
                              <p>
                                {emailToPreview.to_addrs.join(', ')}
                              </p>
                            </div>
                            <div className='flex flex-row items-center gap-3 pl-3'>
                              <p className='text-sm text-text-helper'>Subject</p>
                              <p>
                                {emailToPreview.subject}
                              </p>
                            </div>
                            {
                              emailData.data?.content_is_html ? (
                                <iframe
                                  srcDoc={emailToPreview.body}
                                  title="Email Preview"
                                  style={{
                                    width: '100%',
                                    height: '320px',
                                    border: 'none',
                                  }}
                                />
                              ) : (
                                <div className='flex-1 pl-3'>
                                  <p className='text-sm'>
                                    {emailToPreview.body.split('\n').map((line, index) => (
                                      <React.Fragment key={index}>
                                        {line}
                                        <br />
                                      </React.Fragment>
                                    ))}
                                  </p>
                                </div>
                              )
                            }
                          </div>
                        ) : (
                          <div className='flex items-center justify-center w-full h-full py-8'>
                            {
                              isMutating ? (
                                <Spinner />
                              ) : (
                                <p>
                                  Please select a record to preview
                                </p>
                              )
                            }
                          </div>
                        )
                      }
                    </div>
                  </div>
                </>
              )
            }
            {
              emailStep === 'review' && (
                <>
                  {
                    isMutating ? (
                      <div className='flex flex-col items-center justify-center flex-1 py-8'>
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        <div className='flex flex-col gap-2 pb-4 pl-4 max-h-[200px] overflow-auto border-b-1 border-border'>
                          {
                            emailData?.data?.composed_count === 0 ? (
                              <p>
                                No emails to send
                              </p>
                            ) : (
                              <p>
                                {getTitle()} to {emailData?.data?.composed_count} recipients?
                              </p>
                            )
                          }
                          {
                            emailData?.data?.messages.slice(0, 5).map((message) => (
                              <p key={message.url} className='text-sm list-disc list-item'>
                                {message.to_addrs.join(', ')}
                              </p>
                            ))
                          }
                          {
                            (emailData?.data?.composed_count! - 5) > 0 && (
                              <p className='text-sm'>
                                and {emailData?.data?.composed_count! - 5} more...
                              </p>
                            )
                          }
                        </div>
                        {
                          (error || emailData?.error) && (
                            <>
                              <p className='text-sm text-destructive'>
                                There was an error preparing the emails, please try again
                              </p>
                              <p className='text-sm text-destructive'>
                                {error?.message || emailData?.error?.message}
                              </p>
                            </>
                          )
                        }
                      </>
                    )
                  }

                </>
              )
            }
          </div>
          <DialogFooter className='justify-between'>
            <Button disabled={isMutating} type="button" variant="outline" onClick={() => {
              if (emailStep === 'compose') {
                handleSubmit(onPreview('preview'))();
              } else {
                setEmailStep('compose');
              }
            }}>
              {emailStep === 'compose' ? 'Preview' : 'Edit'}
            </Button>
            <div className='flex items-center gap-3'>
              <Button type="button" variant="outline" onClick={() => onOpenChange(false)}>Cancel</Button>
              <Button disabled={isMutating} type="submit">Send</Button>
            </div>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
}

export default ActionsDialog