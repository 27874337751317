import React from 'react'

import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../common_component/base/dialog";
import { Button } from '../../../common_component/base/button';
import { formatCentsPriceForDisplay } from '../../../util/string_util';
import _ from 'lodash';
import { useForm } from 'react-hook-form';
import { DietaryRequirement, FilterData } from '../../../store/ShopStore';
import SelectField from '../../../common_component/form/SelectField';
import { useStore } from '../../../store/store';
import { observer } from 'mobx-react';
import { DIETARY_REQUIREMENTS } from '../../../common_component/product/Flags';
import { MyKindoTippy } from '../../../common_component/tooltip/MyKindoTippy';
import { cn } from '../../../util/tailwind';
import CheckboxField from '../../../common_component/form/CheckboxField';
import analytics from '../../../util/googleAnalytics';

type Props = {
  maxPriceInCents?: number;
  priceInterval?: number;
  withDietaryRequirements?: boolean;
  withAvailableOnly?: boolean;
}

type FormData = {
  dietaryRequirements?: DietaryRequirement[];
  minPriceInCents?: string;
  maxPriceInCents?: string;
  inStockOnly: boolean;
  availableOnly: boolean;
}

const ProductDetail = observer(({
  withDietaryRequirements,
  maxPriceInCents = 5000,
  priceInterval = 500,
  withAvailableOnly,
}: Props) => {
  const { shop } = useStore();
  const { currentFilters, isFiltering, showFilters } = shop;
  const { handleSubmit, control, reset, watch, setValue } = useForm<FormData>();

  const handleReset = () => {
    reset({
      dietaryRequirements: [],
      minPriceInCents: '0',
      maxPriceInCents: '0',
      inStockOnly: false,
      availableOnly: true,
    });
  }
  React.useEffect(() => {
    if (showFilters) {
      reset({
        dietaryRequirements: currentFilters.dietaryRequirements,
        minPriceInCents: `${currentFilters.minPriceInCents}`,
        maxPriceInCents: `${currentFilters.maxPriceInCents}`,
        inStockOnly: currentFilters.inStockOnly,
        availableOnly: currentFilters.availableOnly,
      });
    }
  }, [showFilters]);
  const onOpenChange = (open: boolean) => {
    shop.setShowFilters(open);

    if (!open) {
      handleSubmit(onSubmit)();
    }
  }


  const onSubmit = (values: FormData) => {
    const maxPriceInCents = parseInt(values.maxPriceInCents ?? '0', 10);
    const minPriceInCents = parseInt(values.minPriceInCents ?? '0', 10);
    const dietaryRequirements = values.dietaryRequirements ?? [];
    shop.setCurrentFilters({
      dietaryRequirements,
      maxPriceInCents,
      minPriceInCents,
      inStockOnly: values.inStockOnly,
      availableOnly: values.availableOnly,
    });
    shop.setShowFilters(false);
    analytics.triggerClick({
      eventName: 'apply_filter',
      parameters: {
        dietary_requirements: dietaryRequirements.join(' '),
        max_price_in_cents: maxPriceInCents,
        min_price_in_cents: minPriceInCents,
        in_stock_only: values.inStockOnly,
      }
    });
  };

  const priceOptions = React.useMemo(() => {
    return _.range(0, maxPriceInCents, priceInterval).map((price) => ({
      label: price === 0 ? 'Any' : formatCentsPriceForDisplay(price),
      value: `${price}`,
    }));
  }, [maxPriceInCents, priceInterval]);

  const currentDietaryRequirements = watch('dietaryRequirements');
  return (
    <Dialog open={showFilters} onOpenChange={onOpenChange}>
      <DialogContent className='sm:max-w-[300px]'>
        <DialogHeader>
          <DialogTitle className='flex justify-between'>
            Filters
          </DialogTitle>
        </DialogHeader>
        <form
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='flex flex-col gap-4 pb-4'>
            <div className=''>
              <p>Price</p>
              <div className='flex items-center gap-4'>
                <SelectField
                  control={control}
                  name='minPriceInCents'
                  items={priceOptions}
                  label="from"
                  placeholder='Any'
                />
                <SelectField
                  control={control}
                  name='maxPriceInCents'
                  items={priceOptions}
                  label="to"
                  placeholder='Any'
                />
              </div>
            </div>
            {
              withDietaryRequirements && (
                <div>
                  <p>
                    Dietary requirements
                  </p>
                  <div className='flex items-center gap-2 mt-1'>
                    {Object.keys(DIETARY_REQUIREMENTS).map((key) => {
                      const label = DIETARY_REQUIREMENTS[key as DietaryRequirement];
                      const isSelected = currentDietaryRequirements?.includes(key as DietaryRequirement);
                      return (
                        <MyKindoTippy key={key} tooltipContent={label} placement='bottom'>
                          <Button
                            type="button"
                            className={cn('p-0 w-8 h-8 rounded-full text-sm bg-white border-1 border-border hover:bg-gray-100', {
                              'bg-flags-gluten-free text-white hover:bg-flags-gluten-free/80': key === 'GF' && isSelected,
                              'bg-flags-dairy-free text-white hover:bg-flags-dairy-free/80': key === 'DF' && isSelected,
                              'bg-flags-vegetarian text-white hover:bg-flags-vegetarian/80': key === 'V' && isSelected,
                              'bg-flags-halal text-white hover:bg-flags-halal/80': key === 'H' && isSelected,
                              'text-flags-gluten-free': key === 'GF' && !isSelected,
                              'text-flags-dairy-free': key === 'DF' && !isSelected,
                              'text-flags-vegetarian': key === 'V' && !isSelected,
                              'text-flags-halal': key === 'H' && !isSelected,
                            })}
                            onClick={(e) => {
                              e.stopPropagation();
                              setValue('dietaryRequirements', _.xor(currentDietaryRequirements, [key as DietaryRequirement]))
                            }}
                          >
                            {key}
                          </Button>
                        </MyKindoTippy>
                      )
                    })}
                  </div>
                </div>
              )
            }
            <CheckboxField
              control={control}
              name='inStockOnly'
              label="Has available stock"
            />
            {
              withAvailableOnly && (
                <CheckboxField
                  control={control}
                  name='availableOnly'
                  label="Show only items for selected days"
                />
              )
            }
          </div>

          <DialogFooter className='gap-2 pt-4 border-t-1 border-border'>
            <Button type="button" variant="link"
              onClick={() => {
                shop.clearFilters();
                handleReset();
                analytics.triggerClick({
                  eventName: 'clear_filter',
                });
              }}
            >
              Clear Filters
            </Button>
            <Button type="submit" variant="default">
              Apply
            </Button>
          </DialogFooter>
        </form>
      </DialogContent>
    </Dialog>
  )
});

export default ProductDetail