import React from "react";
import { format } from "date-fns";
import ShopLayout from "../../common_component/layout/ShopLayout";
import { Outlet, useMatch, useNavigate, useParams } from "react-router-dom";
import SideMenu from "./SideMenu";
import api from "networking/api";
import { useStore } from "../../store/store";
import { observer } from "mobx-react";
import orderUtils from "networking/util/order";
import KindoWell from "../../common_component/KindoWell";
import Breadcrumbs from "../../common_component/layout/components/Breadcrumbs";
import { Product } from "networking/models/family";
import { MdOutlineNotifications } from "react-icons/md";
import useSchoolServices from "networking/hooks/useSchoolService";

const ServiceShopPage: React.FC = observer(() => {
  const navigate = useNavigate();

  const { serviceId, brandName } = useParams();
  const matchesServicePath = !!useMatch('/app/shop/:brandName');
  const matchesEzlunchPath = !!useMatch('/app/shop/ezlunch/*');
  const matchesServiceMenuPath = !!useMatch('/app/shop/:brandName/:serviceId');
  const matchesRoot = !!useMatch('/app/shop');


  const { family: { currentSchool }, shop } = useStore();
  const { data: payablesData } = api.family.useFamilyPayables(currentSchool);

  const {
    currentService,
  } = useSchoolServices({
    schoolId: currentSchool,
    serviceId,
  })
  const showSideNav = (currentService?.mcats?.length ?? 0) > 1 || matchesEzlunchPath;
  const datedService = currentService && !orderUtils.isServiceUndated(currentService);

  const { selectedOrderDates } = shop;
  const availableDates = orderUtils.getAvailableDates(currentService);
  const isServiceClosed = availableDates.length === 0;
  const closingInfo = orderUtils.getClosingInfo(currentService?.closing);
  const previousClosingDate = orderUtils.getPreviousClosingDate(currentService);
  const nextOpeningDate = orderUtils.getNextOpeningDate(currentService);

  const serviceOpenStatuses = [
    ...(isServiceClosed && previousClosingDate ? [
      `Service closed on ${format(previousClosingDate, 'PPPP')}.`
    ] : []),
    ...(isServiceClosed && nextOpeningDate ? [
      `Service will reopen for ordering on ${format(nextOpeningDate, 'PPPP')}.`
    ] : [])
  ].join(' ');
  const infoMessages = [
    ...(closingInfo && selectedOrderDates.length > 0 && datedService ? [
      `Orders or cancellations close ${closingInfo}. ${currentService && datedService ? `${orderUtils.getAvailabilityInfo(currentService)}.` : ''}`
    ] : []),
    ...(serviceOpenStatuses ? [serviceOpenStatuses] : []),
  ];
  const payables = (payablesData?.students ?? []).reduce<Product[]>((acc, student) => {
    return [
      ...acc,
      ...student.products,
    ];
  }, []);

  const breadcrumbItems = !matchesRoot ? [
    { label: "Home", to: "/app/shop" },
    ...(matchesEzlunchPath ? [
      { label: 'ezlunch', to: `/app/shop/ezlunch`, active: matchesServicePath },
    ] : []),
    ...(currentService ? [
      { label: currentService.title, to: `/app/shop/${brandName}/${currentService.id}`, active: matchesServiceMenuPath },
    ] : []),
  ] : [];
  return (
    <div>
      <ShopLayout
        leftItems={showSideNav ? <SideMenu /> : undefined}
      >
        <>
          {
            !matchesRoot && payables.length > 0 && (
              <KindoWell
                className="mb-3.5"
                messages={[
                  `You have ${payables.length} school items requiring your attention.`
                ]}
                variant={"danger"}
                icon={<MdOutlineNotifications />}
                action={{
                  label: 'Review school items',
                  onClick: () => {
                    navigate('/app/shop');
                  }
                }}
              />
            )
          }
          {
            infoMessages.length > 0 && (
              <KindoWell
                className="mb-3.5"
                messages={infoMessages}
              />
            )
          }
          <Breadcrumbs items={breadcrumbItems} />
          <Outlet />
        </>
      </ShopLayout>
    </div>
  )
})

export default ServiceShopPage;
