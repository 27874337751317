import React from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Withdrawal from './proffers/Withdrawal';
import { Helmet } from 'react-helmet';
import CloseAccount from './proffers/CloseAccount';
import Uplift from './proffers/Uplift';
import api from 'networking/api';
import { ProfferType } from 'networking/models/proffer';
import ProfferResult from './ProfferResult';
import { Spinner } from 'components/base/spinner';
import ContentLayout from 'components/layout/components/ContentLayout';

type Props = {}

const ProfferPage = (props: Props) => {
  const { profferId } = useParams<{
    profferId: string
  }>();
  const { data, error, isLoading } = api.proffer.useProfferDetails(profferId);
  const navigate = useNavigate();
  const renderProffer = () => {
    if ((data && (data.is_expired || data.is_resolved))) {
      let expiredDescription = "Please request a new link";
      let cta = undefined;
      switch (data.proffered) {
        case ProfferType.UpliftAccount:
          expiredDescription = "Please request a new link from your school";
          break;
        case ProfferType.CloseAccount:
          expiredDescription = "Please request a new link from my details page";
          cta = {
            label: "Go to My Details",
            onClick: () => navigate("/app/my-details")
          }
          break;
        case ProfferType.Withdrawal:
          expiredDescription = "Please request a new link from wallet page";
          cta = {
            label: "Go to My Wallet",
            onClick: () => navigate("/app/wallet")
          }
          break;
      }
      return (
        <ProfferResult
          title="Link has either expired or has been used"
          description={expiredDescription}
          cta={cta}
        />
      )
    }
    if (error) {
      return (
        <ProfferResult
          title="Link is invalid"
          description="Please request a new link"
        />
      )
    }
    if (profferId) {
      if (data?.proffered === ProfferType.Withdrawal) {
        return (
          <Withdrawal
            availableForWithdrawalInCents={data.withdrawable_balance_in_cents || 0}
            walletBalanceInCents={data.total_wallet_balance_in_cents || 0}
            withdrawalFeeInCents={data.fee_in_cents || 0}
            email={data.email}
            profferId={profferId}
          />
        )
      }
      if (data?.proffered === ProfferType.CloseAccount) {
        return <CloseAccount
          profferId={profferId}
          accountBalance={data.total_account_balance}
          withdrawalFee={data.withdrawal_fees}
          withdrawableAmount={data.withdrawable_balance_in_cents}
          schoolIds={data.schools_linked}
          email={data.email}
        />
      }
      if (data?.proffered === ProfferType.UpliftAccount) {
        return (
          <Uplift
            email={data.email}
            profferId={profferId}
            schoolIds={data.school_ids || []}
          />
        );
      }
    }
    return null;
  }
  const getPageTitle = () => {
    if (data?.proffered === ProfferType.Withdrawal) {
      return 'Withdrawal'
    }
    if (data?.proffered === ProfferType.CloseAccount) {
      return 'Close Account'
    }
    if (data?.proffered === ProfferType.UpliftAccount) {
      return 'Welcome to Kindo'
    }
    return 'Proffer';
  }
  const getTitle = () => {
    if (data?.proffered === ProfferType.Withdrawal) {
      return 'Withdraw funds from Kindo Account'
    }
    if (data?.proffered === ProfferType.CloseAccount) {
      return 'You are about to close your Kindo account'
    }
    if (data?.proffered === ProfferType.UpliftAccount) {
      return 'Welcome to Kindo'
    }
    return null;
  }

  const getSubtitle = () => {
    if (profferId === 'withdrawal') {
      return 'Use this form to transfer funds from your Kindo account to a bank account.'
    }
    return null;
  }
  return (
    <div>
      <Helmet>
        <title>{getPageTitle()}</title>
      </Helmet>
      <ContentLayout>
        <div>
          <div className="flex flex-col gap-0 mt-0 sm:gap-4 sm:mt-8 sm:pb-6">
            <h2 className="text-3xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
              {getTitle()}
            </h2>
            <div className="p-4 overflow-hidden bg-white border-2 sm:rounded-3xl border-border-primary">

              {isLoading && (
                <div className='flex flex-col items-center justify-center gap-4'>
                  <Spinner />
                  <p className='text-base text-text-body'>Loading...</p>
                </div>
              )}
              {renderProffer()}
            </div>
          </div>
        </div>
      </ContentLayout>
    </div>
  )
}

export default ProfferPage