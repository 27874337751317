import React from 'react'
import { MdInfoOutline, MdOutlineReport } from 'react-icons/md'
import { VariantProps, cva } from "class-variance-authority"
import { cn } from "../util/tailwind"
import { Button } from './base/button'

const inputVariants = cva(
  "px-4 py-3 flex gap-x-4 w-full rounded-lg border-1 flex-col items-end sm:items-start sm:flex-row",
  {
    variants: {
      variant: {
        success: "bg-primary-light border-primary text-primary-foreground",
        danger: "bg-danger border-border-danger text-danger-foreground",
        info: 'bg-info border-border-info text-info-foreground',
      },
    },
    defaultVariants: {
      variant: "info",
    },
  }
)
export interface Props extends VariantProps<typeof inputVariants> {
  messages?: Array<string | React.ReactNode>;
  className?: string;
  children?: React.ReactNode;
  icon?: React.ReactElement;
  action?: {
    label: string;
    onClick: () => void;
  }
}

const KindoWell = ({ icon, action, children, messages, variant, className }: Props) => {
  return (
    <div
      className={cn(inputVariants({ variant, className }))}
    >
      <div className='flex self-start flex-1 gap-4'>
        {children}
        {
          messages && (
            <>

              {
                icon ?
                  React.cloneElement(icon, {
                    className: cn('w-5 h-5', {
                      'text-border-danger': variant === 'danger',
                      'text-primary': variant === 'success'
                    })
                  })
                  : (
                    <>
                      {
                        variant === 'danger' ?
                          <MdOutlineReport className='w-5 h-5 text-border-danger' /> :
                          <MdInfoOutline className='w-5 h-5' />
                      }
                    </>
                  )
              }
              <div className='flex flex-col justify-center flex-1 text-card-foreground'>
                {
                  messages.map((message) => (
                    message && typeof message === 'string' ?
                      <p key={message} className='text-sm'>
                        {message}
                      </p>
                      : message
                  ))
                }
              </div>
            </>
          )
        }
      </div>
      {action && (
        <Button
          className={cn('py-0 h-[20px] whitespace-nowrap', {
            'text-danger-foreground': variant === 'danger',
          })}
          variant="linkUnderline"
          size="xs"
          onClick={action.onClick}
        >
          {action.label}
        </Button>

      )}
    </div>
  )
}

export default KindoWell