import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs';

type Props = {
  title: string;
  children: React.ReactNode;
  breadcrumbItems?: {
    label: string;
    to: string;
    active?: boolean;
  }[];
}

const Container = ({ title, children, breadcrumbItems }: Props) => {
  return (
    <div className="mx-2 sm:max-w-[700px] sm:m-auto pt-6">
      <div className="flex flex-col gap-2 px-2 pb-4">
        {
          breadcrumbItems && (
            <Breadcrumbs
              items={breadcrumbItems}
            />
          )
        }
        <h2 className="text-3xl font-bold text-primary font-museo">
          {title}
        </h2>
      </div>
      <div className='py-6 mb-8 shadow-md sm:py-10 rounded-2xl bg-card'>
        {children}
      </div>
    </div>
  )
}

export default Container