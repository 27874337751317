import React from 'react'
import Helmet from 'react-helmet'
import ShopLayout from '../../common_component/layout/ShopLayout'
import orderUtils from 'networking/util/order';
import api from 'networking/api';
import { formatCentsPriceForDisplay } from '../../util/string_util';
import { Button } from "../../common_component/base/button";
import { useNavigate } from 'react-router-dom';

const PendingOrdersPage = () => {
  const navigate = useNavigate();

  const { data: familyData, mutate } = api.family.useFamily();

  const abandonItemsGroupedByDeliveryDate = React.useMemo(() => {
    return orderUtils.getOrderGroupedByDeliveryDate(familyData?.pending_bx_orders_list);
  }, [familyData?.pending_bx_orders_list]);

  return (
    <div>
      <Helmet>
        <title>Pending Orders</title>
      </Helmet>
      <ShopLayout
        breadcrumbItems={[
          { label: "Home", to: "/app/shop" },
          { label: "Checkout", to: "/app/checkout" },
          { label: "Your bank transfer orders", to: "/app/pending-orders", active: true },
        ]}>
        <div>
          <div className="flex flex-col gap-0 mt-0 sm:gap-4 sm:mt-8 sm:pb-6">
            {familyData?.saved_order_counts.offline_tdp ?
              <div className='flex flex-col gap-6 sm:flex-row'>
                <div>
                  <h2 className="pb-2 text-3xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
                    Your bank transfer orders
                  </h2>
                  <div className='flex flex-col max-w-3xl gap-4 text-sm bg-primary-muted text-card-foreground'>
                    <p className='text-sm text-card-foreground'>You have {familyData?.saved_order_counts.offline_tdp} order{(familyData?.saved_order_counts.offline_tdp) && 's'} awaiting internet banking transfer payment. For Internet banking transfers, please allow 1 - 2 working days for your funds to reach us.</p>
                    <p className='text-sm text-card-foreground'>If you have already made your internet banking transfer, and are happy to wait, nothing needs to be done.  Once we have received your payment, your order will be automatically processed and you will receive a confirmation email.</p>
                    <Button className='h-6 p-0 w-fit' variant='linkUnderline' onClick={() => navigate('/app/checkout')}>Go to checkout</Button>
                    <p className='text-xl font-semibold font-museo'>Want your order quicker?</p>
                    <p>If you'd like us to process your order quicker, cancelling your order and choosing another payment method will be the best option. By cancelling your order your order items will be returned back to checkout where you can then choose a different payment method.</p>
                    <p className='text-xl font-semibold font-museo'>Already made your bank transfer?</p>
                    <p>If you have already made your internet banking transfer and still want us to process your order quicker, cancelling your order and choosing another payment method is the best option. Any funds you've already transferred will arrive into your kindo wallet for future use.</p>
                  </div>
                  <div className='flex flex-col gap-2 py-4 my-6 sm:flex-row justify-normal sm:p-0'>
                    <Button variant='default' className='font-semibold font-museo'
                      onClick={() => {
                        api.family.abandonBankTransferTopup(false)
                          .then((res) => {
                            if (res.ok) {
                              mutate();
                              navigate('/app/checkout', { replace: true });
                            }
                          })
                      }}>Cancel order</Button>
                    <Button variant='outline' className='font-semibold font-museo'
                      onClick={() => {
                        api.family.abandonBankTransferTopup(true)
                          .then((res) => {
                            if (res.ok) {
                              return res.json();
                            }
                          })
                          .then((data) => {
                            if (data.released_order_count) {
                              mutate();
                              navigate('/app/checkout', { replace: true });
                            }
                          })
                      }}>Change payment</Button>
                  </div>
                </div>
                <div>
                  <div className="overflow-hidden border-2 rounded-3xl border-border-primary">
                    <div className="bg-white border-b-2">
                      <div>
                        <div className="items-center hidden grid-cols-3 gap-1 p-2 sm:grid sm:gap-6 md:gap-8 sm:grid-cols-5 sm:p-4 bg-background">
                          <div className='col-span-3 text-sm font-semibold text-card-foreground'>Pending orders</div>
                          <div className='text-sm font-semibold text-center text-card-foreground'>Quantity</div>
                          <div className='text-sm font-semibold text-right text-card-foreground'>Item total</div>
                        </div>
                        <div>
                          {abandonItemsGroupedByDeliveryDate && Object.keys(abandonItemsGroupedByDeliveryDate).map((deliveryDate) => (
                            <div key={deliveryDate}>
                              {deliveryDate &&
                                <div className="bg-gradient-to-l from-white/0 via-background/0 to-background/100 py-1 sm:py-3 w-full sm:w-57%] mb-0 sm:mb-2">
                                  <p className="pl-2 text-base sm:pl-4 text-card-foreground sm:text-xl font-museo">{deliveryDate}</p>
                                </div>
                              }
                              {abandonItemsGroupedByDeliveryDate[deliveryDate].students.map((student) => (
                                <>
                                  <div className="grid grid-cols-1 gap-1 p-2 sm:gap-6 md:gap-8 sm:grid-cols-5 sm:p-4">
                                    <div className='flex flex-wrap justify-between'>
                                      <div className='flex gap-1'>
                                        <p className='text-xs font-museo sm:text-sm text-primary'>{student.firstName}</p>
                                      </div>
                                    </div>
                                    {student.products.map((product, index) => {
                                      return (
                                        <>
                                          {index === 0 ? '' : <div></div>}
                                          <div className='flex justify-between gap-4 text-xs sm:justify-normal sm:col-span-2 sm:text-sm text-card-foreground'>
                                            <p className='hidden text-xs sm:text-sm text-card-foreground sm:block'>{product.product}</p>
                                            <p className='block text-xs sm:text-sm text-card-foreground sm:hidden'>{product.product} x{product.quantity}</p>
                                            <p className='block text-xs text-right sm:text-sm text-card-foreground sm:hidden'>{formatCentsPriceForDisplay(product.quoted_unit_price_in_cents)}</p>
                                          </div>
                                          <p className='hidden text-xs text-center sm:text-sm text-card-foreground sm:block'>{product.quantity}</p>
                                          <p className='hidden text-xs text-right sm:text-sm text-card-foreground sm:block'>{formatCentsPriceForDisplay(product.quoted_unit_price_in_cents)}</p>
                                        </>
                                      )
                                    })}
                                  </div>
                                  <hr className="border-background-light" />
                                </>
                              ))}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              :
              <div className="overflow-hidden border-2 rounded-3xl border-border-primary">
                <div className="p-2 bg-background">
                </div>
              </div>
            }
          </div>
        </div>
      </ShopLayout>
    </div>
  )
}

export default PendingOrdersPage