import useSWR, { useSWRConfig } from 'swr';
import { baseApi, baseUrl } from './constants';
import { Voucher, TaxaccStatus, SupplierRegistration } from './models/misc';
import { KindoNetworkError } from './models/common';
import { SchoolService } from './models/family';
import fetcher from './util/fetcher';

const misc = {
  // Queries (starts with "use")
  useSchoolData: (schoolId?: string) => {
    return useSWR<SchoolService, KindoNetworkError>(schoolId ? `${baseUrl}json/${schoolId}/${schoolId}.json` : undefined, {
      revalidateIfStale: false,
      shouldRetryOnError: true,
      dedupingInterval: 1000 * 60,
      errorRetryCount: 3,
      errorRetryInterval: 1000,
      revalidateOnFocus: false,
    });
  },

  useTaxaccStatus: (url?: string) => {
    return useSWR<TaxaccStatus, KindoNetworkError>(url ? `${baseUrl}${url}` : undefined);
  },

  useJsonResult: (url?: string) => {
    return useSWR<any, KindoNetworkError>(url ? `${baseUrl}cache/${url}` : undefined);
  },

  // Mutations
  addVoucher: (voucher: Voucher) => {
    return fetcher(`${baseApi}/voucher/query`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(voucher),
    })
  },

  sendFeedback: (message: string, contact: string) => {
    return fetcher(`${baseApi}/feedback`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: message,
        contact: contact
      })
    })
  },

  uploadPicture: (file: File) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("pic_name", file.name);
    formData.append("pic_type", file.type);

    return fetcher(`${baseApi}/registration/logo_upload`, {
      method: 'POST',
      body: formData,
    })
  },

  registerSupplier: (formData: SupplierRegistration) => {
    return fetcher(`${baseApi}/registration`, {
      method: 'POST',
      headers: {
        'Content-Type': 'text/json',
      },
      body: JSON.stringify(formData)
    })
  },

}

export default misc;