import React from 'react';
import api from 'networking/api';
import { CreatePayableRequest, PayableStatus, ProductWithPayable } from 'networking/models/payable';
import useSWRMutation from 'swr/mutation';
import _ from 'lodash';

type StudentPayableStats = {
  [protoPayableId: string]: {
    total: number;
    outstanding: number;
  }
};
/**
 * Utility hook to get admin payables for a school
 * @param schoolId - Get payables for a specific school
 * @param payablePermanentId - Get for a specific payable
 */
const useAdminPayables = ({
  schoolId,
  payablePermanentId,
  rolloverYear,
}: {
  schoolId?: string;
  payablePermanentId?: string;
  rolloverYear?: string;
}) => {
  const { data: payableData, isLoading, mutate, isValidating, error } = api.payable.useAllPayables({
    schoolId,
    year: rolloverYear,
  });
  const { data: suppplierIdData } = api.payable.usePayableSupplierId(schoolId);

  const { data: studentPayablesData, isLoading: isLoadingStudentPayables, error: studentPayablesError } = api.admin.usePayables(schoolId, {
    status: [
      'ANY', // Must have to load payables
      'exempt',
      'hidden',
    ],
  });
  const [studentPayableStats, setStudentPayableStats] = React.useState<StudentPayableStats>({});
  const payable = payableData?.products?.find((payable) => payable.permanent_id === payablePermanentId);
  const payableQuestions = payableData?.product_questions?.[payablePermanentId || ''];

  // Optimistic updaters
  // Optimistically insert or update payables
  const optimisticUpsertPayables = (payables: ProductWithPayable[]) => {
    const updatedProducts = payableData?.products.map((oldPayable) => {
      const newPayable = payables.find((p) => p.permanent_id === oldPayable.permanent_id);
      if (newPayable) {
        // Update the old payable with the new payable
        return {
          ...oldPayable,
          ...newPayable,
        };
      }
      return oldPayable;
    });
    const newProducts = payables.filter((p) => !updatedProducts?.find((oldPayable) => oldPayable.permanent_id === p.permanent_id));


    const optimisticData = {
      ...payableData,
      products: [
        ...newProducts,
        ...updatedProducts || [],
      ]
    };
    mutate(optimisticData);
  };

  const optimisticDeletePayable = (permanentId: string) => {
    const updatedProducts = payableData?.products.filter((oldPayable) => oldPayable.permanent_id !== permanentId);
    const optimisticData = {
      ...payableData,
      products: [
        ...updatedProducts || [],
      ]
    };
    mutate(optimisticData, {
      revalidate: false,
    });
  }
  // Utilities
  const getPayableByPermanentId = (permanentId: string) => {
    return payableData?.products.find((payable) => payable.permanent_id === permanentId);
  }
  const getPropsToSave = (payable: ProductWithPayable) => {
    return {
      menu_category: payable.menu_category,
      menu_priority: payable.menu_priority,
      is_donation: payable.is_donation,
      consignment: payable.consignment,
      current: payable.current,
      dairy_free: payable.dairy_free,
      delivery_address: payable.delivery_address,
      description: payable.description,
      detail: payable.detail,
      detail_confirmation: payable.detail_confirmation,
      flexi_price_enabled: payable.flexi_price_enabled,
      gluten_free: payable.gluten_free,
      gst: payable.gst,
      halal: payable.halal,
      included_option_count: payable.included_option_count,
      member_id: payable.member_id,
      option_price_in_cents: payable.option_price_in_cents,
      price_in_cents: payable.price_in_cents,
      remarks1: payable.remarks1,
      remarks2: payable.remarks2,
      summary: payable.summary,
      supplier: payable.supplier,
      supplier_ref: payable.supplier_ref,
      supplier_ref_type: payable.supplier_ref_type,
      vegetarian: payable.vegetarian,
      item_count: payable.item_count,
      promote: payable.promote,
      picture: payable.picture || '',
      permanent_id: payable.permanent_id,
      product: payable.product,
      label: payable.label,
      product_id: payable.product_id,
      status: payable.status,
      pcat: payable.pcat || null,
      max_instances: payable.max_instances || null,
      is_voluntary: payable.is_voluntary,
      year: payable.year,
      proto_payable_id: payable?.proto_payable_id,
      proto_payable: undefined,
    }
  }
  // PAYABLE ACTIONS
  const refreshPayables = useSWRMutation('/payable/refresh-payables', (key
  ) => {
    try {
      if (!schoolId) {
        return undefined;
      }
      return api.admin.refreshPayables({
        schoolId,
      }).then(async (response) => {
        return response.data?.refresh_errors.length === 0;
      });
    }
    catch (e) {
      return false;
    }
  });

  const getNewPermanentId = useSWRMutation('/payable/get-new-permanent-id', (key
  ) => {
    try {
      const supplierId = suppplierIdData?.supplier_id
      if (!supplierId) {
        return undefined;
      }
      return api.payable.getNewPermanentId(supplierId).then(async (response) => {
        const { data, error } = response;
        if (!error) {
          return data?.new_permanent_id;
        }
      });
    }
    catch (e) {
      return undefined;
    }
  });

  const duplicatePayable = useSWRMutation('/payables/duplicate', async (key,
    { arg }: {
      arg: {
        permanentId: string;
      }
    }) => {
    try {
      if (!schoolId) {
        return false;
      }
      const payableToDuplicate = payableData?.products.find((payable) => payable.permanent_id === arg.permanentId);
      if (!payableToDuplicate) {
        return false;
      }
      const payableQuestionsToDuplicate = payableData?.product_questions[arg.permanentId];

      const getNewLabel = () => {
        const name = `${payableToDuplicate.label}`;
        // Increment the number in the name if it already exists
        let i = 1;
        let suffix = ` (${i})`;
        // Limit the name to 75 characters
        let duplicateName = `${name.substring(0, 75 - suffix.length)}${suffix}`;
        while (payableData?.products.find((payable) => payable.label === duplicateName)) {
          i++;
          suffix = ` (${i})`;
          duplicateName = `${name.substring(0, 75 - suffix.length)}${suffix}`;
        }
        return duplicateName;
      }
      const newLabel = getNewLabel();
      const newPermanentId = await getNewPermanentId.trigger();
      if (newPermanentId) {
        const newPayable = {
          ...getPropsToSave(payableToDuplicate),
          // New fields
          label: newLabel,
          product: `${payableToDuplicate.year} ${newLabel}`,
          product_id: '',
          permanent_id: newPermanentId,
          status: PayableStatus.DRAFT,
          proto_payable_id: undefined,
        }
        return api.payable.createAndUpdatePayables({
          schoolId,
          data: {
            after: [
              newPayable,
            ],
            before: [],
            product_question_after: {
              [newPermanentId]: payableQuestionsToDuplicate || [],
            },
            product_question_before: {},
          }
        }).then((response) => {
          if (response.data) {
            const newProductId = response.data.inserted[0];
            if (newProductId && typeof newProductId === 'string') {
              optimisticUpsertPayables([{
                ...newPayable,
                product_id: newProductId,
              }]);
            }
            return {
              permanent_id: newPermanentId,
              newLabel: newLabel,
              year: payableToDuplicate.year,
              oldName: payableToDuplicate.product,
            }
          }
          return false;
        });
      }
      return false;
    }
    catch (e) {
      return false;
    }
  })

  const createAndUpdatePayables = useSWRMutation('/payable/create-and-update-payables', async (key, { arg }: { arg: { data: CreatePayableRequest } }) => {
    if (!schoolId) {
      return undefined;
    }
    const response = await api.payable.createAndUpdatePayables({
      schoolId,
      data: arg.data,
      rtype: 'product_transaction',
    });

    // optimistic update
    if (response.data) {
      // Update the payables with the new product ids
      const productsAfterWithProductId = arg.data.after
        .filter((payable) => !payable.product_id)
        .reduce<ProductWithPayable[]>((acc, payable, index) => {
          return [
            ...acc,
            {
              ...payable,
              product_id: response.data.inserted[index],
            }
          ]
        }, []);
      optimisticUpsertPayables(productsAfterWithProductId);
    }
    return response;
  });

  const rollOverPayables = useSWRMutation('/payable/create-and-update-payables', async (key, { arg }: { arg: { data: CreatePayableRequest } }) => {
    if (!schoolId) {
      return undefined;
    }
    const response = await api.payable.createAndUpdatePayables({
      schoolId,
      data: arg.data,
      rtype: 'product_rollover',
    });
    if (response.data) {
      optimisticDeletePayable(arg.data.before[0].permanent_id);
    }
    return response;
  });
  const updatePayableStates = useSWRMutation('/payable/update-payable-states', async (key, { arg }: { arg: { permanentIds: string[], newState: PayableStatus } }) => {
    if (!schoolId) {
      return undefined;
    }
    const oldPayables = payableData?.products
      .filter((payable) => arg.permanentIds.includes(payable.permanent_id))
      .map((payable) => ({
        ...getPropsToSave(payable),
        proto_payable_id: undefined,
      }));
    const newPayables = payableData?.products
      .filter((payable) => arg.permanentIds.includes(payable.permanent_id))
      .map((payable) => {
        return {
          ...getPropsToSave(payable),
          status: arg.newState,
        }
      });
    const response = await api.payable.createAndUpdatePayables({
      schoolId,
      data: {
        before: oldPayables || [],
        after: newPayables || [],
        product_question_before: {},
        product_question_after: {},
      },
    });
    if (response.data) {
      optimisticUpsertPayables(newPayables);
    }
    return response;
  });

  const deletePayable = useSWRMutation('/payables/delete', async (key,
    { arg }: {
      arg: {
        permanentId: string;
      }
    }) => {
    try {
      if (!schoolId) {
        return false;
      }
      const payableToDelete = payableData?.products.find((payable) => payable.permanent_id === arg.permanentId);
      if (!payableToDelete) {
        return false;
      }
      return api.payable.createAndUpdatePayables({
        schoolId,
        data: {
          after: [
          ],
          before: [
            {
              ...getPropsToSave(payableToDelete),
              proto_payable_id: payableToDelete.proto_payable_id,
            },
          ],
          product_question_after: {},
          product_question_before: {},
        },
        rtype: 'product_delete',
      }).then((response) => {
        if (response.data) {
          if (response.data.deleted.includes(payableToDelete.product_id)) {
            optimisticDeletePayable(arg.permanentId);
            return true;
          }
        }
        return false;
      });
    }
    catch (e) {
      return false;
    }
  });

  const getPaymentStatus = React.useCallback((protoPayableId?: string) => {
    if (!studentPayablesData && !isLoadingStudentPayables) {
      return 'No data available';
    }
    if (studentPayablesError) {
      return 'Failed to load data';
    }
    const stats = studentPayableStats[protoPayableId ?? ''];
    if (stats) {
      if (stats.total > 0) {
        if (stats.outstanding === 0) {
          return 'Fully paid';
        }
        if (stats.outstanding > 0) {
          return 'Awaiting payment';
        }
      } else {
        return 'No requests';
      }
    }
    return 'No requests';
  }, [studentPayableStats, studentPayablesData]);

  React.useEffect(() => {
    if (!isLoadingStudentPayables) {
      // Calculate stats for student payables
      const groupedByProtoId = _.groupBy(studentPayablesData?.payables, 'proto_payable_id');
      const stats = Object.keys(groupedByProtoId).reduce((acc, protoPayableId) => {
        const total = groupedByProtoId[protoPayableId].length;
        const outstanding = groupedByProtoId[protoPayableId].filter((payable) => payable.charged_amount_in_cents_excl > 0 && payable.current_amount_in_cents_incl > 0).length;
        return {
          ...acc,
          [protoPayableId]: {
            total,
            outstanding,
          }
        }
      }
        , {} as StudentPayableStats);
      setStudentPayableStats(stats);
    }
  }, [isLoadingStudentPayables]);

  return {
    data: payableData,
    isLoading,
    isValidating,
    error,
    supplierId: suppplierIdData?.supplier_id,
    mutate,

    // Utilities
    getPropsToSave,
    getPayableByPermanentId,

    // Student payables
    isLoadingStudentPayables,
    studentPayableStats,
    getPaymentStatus,

    // When a payable permanent id is provided
    payable,
    payableQuestions,

    // SWR mutation Actions
    getNewPermanentId,
    duplicatePayable,
    refreshPayables,
    createAndUpdatePayables,
    updatePayableStates,
    deletePayable,
    rollOverPayables,
  };
}

export default useAdminPayables;