import React from 'react'

type Props = {
  text: string;
}

const GlobalBanner = (props: Props) => {
  const { text } = props
  return (
    <div className='flex flex-row items-center justify-center w-full gap-3 px-6 py-4 sm:px-2 bg-accent'>
      <p className='text-sm text-accent-foreground'>
        {text}
      </p>
    </div>
  )
}

export default GlobalBanner