import React from 'react'
import Helmet from 'react-helmet'
import ShopLayout from '../../common_component/layout/ShopLayout'
import { ComboBox } from "../../common_component/base/combobox";
import api from 'networking/api';
import { useStore } from '../../store/store'
import { MdSearch, MdCalendarMonth, MdOutlineSettings } from 'react-icons/md';
import { Button } from "../../common_component/base/button";
import { baseApi, baseUrl } from 'networking/constants';
import { format, addDays } from 'date-fns';
import { Calendar } from '../../common_component/base/calendar';
import { cn } from "../../util/tailwind";
import { generateProductionListDates } from '../../util/util';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../common_component/base/select"
import { DateRange } from "react-day-picker"
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTrigger,
  DialogTitle,
  DialogFooter,
  DialogClose
} from "../../common_component/base/dialog";
import { OrderStatusTable } from './OrderStatusTable';
import { columns } from "./Columns";
import { formatCentsPriceForDisplay } from '../../util/string_util';
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../common_component/base/tabs";
import { Card, CardContent } from "../../common_component/base/card";
import { observer } from "mobx-react";
import { Checkbox } from '../../common_component/base/checkbox';
import { ColumnToggle } from '../../common_component/table/ColumnToggle';
import { useToast } from '../../common_component/base/use-toast';
import { StocksTable } from './StocksTable';
import { stockColumns } from './StockColumns';
import InputField from '../../common_component/form/InputField';
import { MdArrowForward } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import { canSeeOrderStatus } from 'util/permissions';
import { useNavigate } from 'react-router-dom';

const ORDER_STATUSES = [
  {
    label: "Pending",
    value: 'pending',
  },
  {
    label: "Processing",
    value: 'processing',
  },
  {
    label: 'Completed',
    value: 'completed',
  }
]

const LABELS = [
  {
    label: "Label List",
    value: 'label_list',
  },
  {
    label: 'Print to Labelwriter',
    value: 'label_dymo',
  },
  {
    label: 'Print to A4 Labels (8 per page)',
    value: 'label_pdf',
  },
  // hide for now - not needed for mvp
  // {
  //   label: 'Print to Labels (1 per page)',
  //   value: 'label_pdf_one_per_page',
  // },
  {
    label: 'Print per Item Labels (33 per page, A4 pdf)',
    value: 'label_pdf_sop_3x11',
  },
  {
    label: 'Print to A4 (1 per page)',
    value: 'A4_pdf_one',
  },
  {
    label: 'Print to A4 (all details)',
    value: 'A4_pdf_full',
  }
]

type FormData = {
  adjust: number;
}

const OrderStatusPage: React.FC = observer(() => {
  const { data: familyData, isLoading: isLoadingFamily, mutate: mutateFamily } = api.family.useFamily();
  const store = useStore();
  const navigate = useNavigate();

  const { control, handleSubmit, reset, watch, formState: { isSubmitting } } = useForm<FormData>({});

  const { currentPartner } = store.family;
  const { toast } = useToast();
  const { data: stockData, isLoading: isLoadingStockData, isValidating, mutate: mutateProductStocks } = api.supplier.useProductStockList({ supplier: currentPartner?.value, site: 'shop' });
  const canSeeOrderStatusPage = canSeeOrderStatus(familyData);
  const { trigger, isMutating } = useSWRMutation('/supplier/update-stock', (key, { arg }: { arg: { id: string, stock: number } }) => {
    try {
      return api.supplier.saveStock({
        supplier: store.family.currentPartner!.value,
        id: arg.id,
        stock: arg.stock,
        site: 'shop'
      }).then(async (response) => {
        if (response.ok) {
          try {
            const body = await response.json();
            if (body) {
              toast({
                title: `Stock has been updated to ${body.stock}.`,
                duration: 5000,
              })
              mutateProductStocks();
            }
          } catch (e) {
            throw new Error('Something went wrong, please try again');
          }
        }
      });
    }
    catch (e) {
      throw new Error('Something went wrong, please try again');
    }
  })

  const [date, setDate] = React.useState<Date | undefined>(new Date())
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [quickViewDate, setQuickViewDate] = React.useState(format(new Date(), 'yyyy-MM-dd'))
  const [selectedStatuses, setSelectedStatuses] = React.useState(['pending', 'processing', 'completed']);
  const [dates, setDates] = React.useState<DateRange | undefined>({
    from: addDays(new Date(), -6),
    to: new Date(),
  })
  const [pdfPrint, setPdfPrint] = React.useState('');
  const [showDatePicker, setShowDatePicker] = React.useState(false);
  const [isDisplayOrder, setIsDisplayOrder] = React.useState(false);
  const [labelType, setLabelType] = React.useState('');
  const [labelError, setLabelError] = React.useState('');
  const [selectedStatus, setSelectedStatus] = React.useState<'pending' | 'completed' | 'processing'>();
  const [orderDataTable, setOrderDataTable] = React.useState([]);
  const [stockDataLastUpdated, setStockDataLastUpdate] = React.useState<Date>();

  const formattedStatuses = selectedStatuses.map(status => status.charAt(0).toUpperCase() + status.slice(1));

  const { data: dailyReport, error: dailyReportError } = api.supplier.useDailyReport({
    'isSubmit': isSubmit,
    'date': date && format(date, 'yyyy-MM-dd'),
    'supplier': currentPartner?.value
  });
  const productionListDates = generateProductionListDates();
  const { data: labelPrint, isLoading: isLoadingQuickOrders, isValidating: isValidatingQuickOrders, mutate: mutateQuickOrders } = api.supplier.useDownloadOrders({
    'date': quickViewDate,
    'supplier': currentPartner?.value || '' || '',
    'pdfPrint': pdfPrint
  });
  const { data: orderData, isLoading: isLoadingOrderData, mutate: mutateOrderData, error } = api.supplier.useOrders({
    'supplier': currentPartner?.value || '',
    'startDate': (dates?.from && format(dates.from, 'yyyy-MM-dd')) || '',
    'endDate': (dates?.to && format(dates.to, 'yyyy-MM-dd')) || (dates?.from && !dates.to && format(dates.from, 'yyyy-MM-dd')) || '',
    'statusList': selectedStatuses.join(','),
    'isDisplayOrder': isDisplayOrder
  });

  const resetSelectedStatus = () => {
    setSelectedStatus('');
  };

  const isSupplierValid = familyData?.supplier_order.find(supplier => supplier.id === currentPartner?.value)

  React.useEffect(() => {
    if (!isValidating && !isLoadingStockData) {
      setStockDataLastUpdate(new Date())
    }
  }, [isValidating, isLoadingStockData])

  React.useEffect(() => {
    // Sets initial state of order status data table
    const initialOrderDataTable = (orderData?.orders || []).map((order) => ({
      ...order,
      member: `${order.student_name}`,
      total: formatCentsPriceForDisplay(order.value),
      options: order.options.join(', '),
      status: selectedStatus || order.status,
      before: order,
    }));

    setOrderDataTable(initialOrderDataTable);
  }, [orderData, selectedStatus])

  React.useEffect(() => {
    // Redirect to home page if user is not allowed to see order status page
    if (familyData && !canSeeOrderStatusPage) {
      navigate('/app/shop')
    }
  }, [canSeeOrderStatusPage, familyData])

  React.useEffect(() => {
    // shows view order data error
    if (error) {
      if (error.message) {
        toast({
          title: `${error.message}`,
          duration: 5000,
        })
      } else if (error?.name === 'ForbiddenException') {
        toast({
          title: 'Reporting period is too long.  Maximum length is one month.',
          duration: 5000,
        })
      } else {
        toast({
          title: 'Something went wrong. If problem persist, please contact our Helpdesk.',
          duration: 5000,
        })
      }
    }
  }, [error]);

  React.useEffect(() => {
    // shows download daily report error
    if (dailyReportError) {
      toast({
        title: `${dailyReportError.message}`,
        duration: 5000,
      })
    }
  }, [dailyReportError]);

  React.useEffect(() => {
    // Setting label error if there is otherwise it should open a backend page to print or view labels
    if (labelPrint && labelPrint.filename) {
      if (labelPrint.filename === 'no_order') {
        setLabelError('There is no order for the selected date(s).')
      }
      setIsDisplayOrder(false)
    }
  }, [labelPrint, isLoadingQuickOrders, isValidatingQuickOrders])

  React.useEffect(() => {
    // Opens a new tab to show daily report when filename is present
    if (dailyReport && dailyReport.filename) {
      window.open(`${baseUrl}cache/` + dailyReport.filename, '_blank')
    }
  }, [dailyReport])

  // Set supplier if empty
  React.useEffect(() => {
    if (!isLoadingFamily && familyData) {
      if (familyData.supplier_order.length > 0 && !currentPartner) {
        store.family.setCurrentPartner({ label: familyData?.supplier_order[0].name, value: familyData?.supplier_order[0].id })
      }
    }
  }, [familyData, isLoadingFamily, store.family, currentPartner])

  // Re set supplier if supplier is not valid
  React.useEffect(() => {
    if (!isSupplierValid && familyData && familyData?.supplier_order[0]) {
      store.family.setCurrentPartner({ label: familyData?.supplier_order[0].name, value: familyData?.supplier_order[0].id })
    }
  }, [familyData, isSupplierValid, store.family])


  const handleStatusClick = (status) => {
    setSelectedStatuses((prevStatuses) =>
      prevStatuses.includes(status)
        ? prevStatuses.filter((s) => s !== status)
        : [...prevStatuses, status]
    );
  };

  const handleStatusChange = (row, value) => {
    setOrderDataTable((prevOrders) => {
      const updatedOrders = prevOrders.map((order) => {
        if (order.purchase_id === row.original.purchase_id && order.index === row.original.index) {
          return { ...order, status: value };
        }
        return order;
      });
      return updatedOrders;
    });
  };

  const toggleAllStatus = () => {
    return (
      <div className='flex items-center gap-2 w-72'>
        <p className='w-1/2 text-sm'>Change order status</p>
        <Select value={selectedStatus} onValueChange={(value) => setSelectedStatus(value)}>
          <SelectTrigger className='w-1/2'>
            <SelectValue placeholder='Select status' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup id="orderStatus">
              {ORDER_STATUSES.map((statusOption) => (
                <SelectItem className='text-xs' key={statusOption.value} value={statusOption.value}>
                  {statusOption.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
    )
  }

  const searchOrders = () => {
    return (
      <div className="flex flex-row justify-between gap-6 bg-primary-muted">
        <div>
          <div className='flex flex-wrap items-end gap-4'>
            <div>
              <p className='pb-2'>Select a date range</p>
              <Dialog>
                <DialogTrigger asChild>
                  <Button
                    id="dates"
                    variant={"outline"}
                    size='sm'
                    className={cn(
                      "w-[250px] justify-start text-left font-normal",
                      !dates && "text-muted-foreground"
                    )}
                  >
                    <MdCalendarMonth className="w-4 h-4 mr-2" />
                    {dates?.from ? (
                      dates.to ? (
                        <>
                          {format(dates.from, "LLL dd, y")} -{" "}
                          {format(dates.to, "LLL dd, y")}
                        </>
                      ) : (
                        format(dates.from, "LLL dd, y")
                      )
                    ) : (
                      <span>Pick start and end date</span>
                    )}
                  </Button>
                </DialogTrigger>
                <DialogContent className='sm:max-w-[700px] sm:min-h-[430px]'>
                  <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={dates?.from}
                    selected={dates}
                    onSelect={setDates}
                    numberOfMonths={2}
                    onDayPointerEnter={() => setIsDisplayOrder(false)}
                  />

                  <DialogClose>
                    <Button
                      className='w-full mb-4 font-semibold font-museo'
                    >
                      Continue
                    </Button>
                  </DialogClose>
                </DialogContent>
              </Dialog>
            </div>
            <div>
              <p className='pb-2'>Order status</p>
              <Select>
                <SelectTrigger className='h-9'>
                  <SelectValue placeholder={formattedStatuses.join(', ')} />
                </SelectTrigger>
                <SelectContent>
                  {ORDER_STATUSES.map((status) => (
                    <div className='pb-1' key={status.value}>
                      <Checkbox
                        checked={selectedStatuses.includes(status.value)}
                        label={status.label}
                        name={status.label}
                        onCheckedChange={() => {
                          handleStatusClick(status.value)
                        }}
                      />
                    </div>
                  ))}
                </SelectContent>
              </Select>
            </div>
            {isDisplayOrder ?
              <Button
                className='font-semibold font-museo'
                size='sm'
                onClick={() => { setIsDisplayOrder(false); }}
              >
                Clear search
              </Button>
              :
              <Button
                className='font-semibold font-museo'
                size='sm'
                onClick={() => { setIsDisplayOrder(true); }}
              >
                View orders
              </Button>
            }

            <p className='py-2 text-sm'>{orderData?.orders && `${orderData?.orders.length} orders found`}</p>
          </div>
        </div>
      </div>
    )
  }

  const columnsWithAction = React.useMemo(() => {
    const status = {
      id: "status",
      header: ({ table }) => (
        <div className='flex items-center justify-between gap-4'>
          Status
          <ColumnToggle table={table} icon={<MdOutlineSettings className='w-4 h-4' />} />
        </div>
      ),
      cell: ({ row }) => (
        <Select
          key={row.original.purchase_id + row.original.index}
          onValueChange={(value) => handleStatusChange(row, value)}
          value={row.original.status}
        >
          <SelectTrigger className='text-xs'>
            <SelectValue placeholder='' />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup id="orderStatus">
              {ORDER_STATUSES.map((statusOption) => (
                <SelectItem className='text-xs' key={statusOption.value} value={statusOption.value}>
                  {statusOption.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      ),
      enableSorting: true,
      enableHiding: false,
    };

    return [...columns, status];
  }, []);

  const stockColumnsWithAction = React.useMemo(() => {
    const arrow = {
      id: "arrow",
      cell: () => (
        <MdArrowForward className='text-text-helper' />
      ),
      size: 40
    };
    const adjust = {
      id: "adjust",
      header: () => (
        <div className='flex items-center justify-between gap-4'>
          Adjust stock
        </div>
      ),
      cell: ({ row }) => (
        <div className='flex w-12 '>
          <InputField
            key={row.original.permanent_id}
            className='w-12 rounded-r-none h-7'
            name={`adjust-${row.original.permanent_id}`}
            control={control}
            type='number'
            value={row.getValue()}
            placeholder={row.original.stock}
          />
          <Button
            variant="outline"
            size='xs'
            className='items-center justify-center p-1 px-2 text-xs border-l-0 rounded-l-none bg-gray-50 h-7'
            disabled={isSubmitting || isMutating}
            onClick={() => {
              const stockValue = watch(`adjust-${row.original.permanent_id}`);
              trigger({
                id: row.original.permanent_id,
                stock: stockValue
              })
            }}
          >
            Save
          </Button>
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    };
    return [...stockColumns, arrow, adjust];
  }, []);
  return (
    <div>
      <Helmet>
        <title>Order Status</title>
      </Helmet>
      <ShopLayout
        breadcrumbItems={[
          { label: "Admin", to: "/app/admin" },
          { label: "Order Status and reporting", to: "/app/order-status", active: true },
        ]}
      >
        <div key={currentPartner?.value} className="flex flex-col gap-0 mt-0 sm:gap-4 sm:mt-8 sm:pb-6">
          <div className='flex flex-row flex-wrap items-center justify-between mb-2'>
            <h2 className="text-3xl font-bold tracking-tight transition-colors scroll-m-20 first:mt-0 font-museo">
              Order status and reporting
            </h2>
            <div className='flex gap-2'>
              <Button
                className='font-semibold font-museo'
                onClick={() => {
                  window.open(`${baseApi}/content/supplier_home`, '_blank')
                }}
              >
                Reporting
              </Button>
              <Button
                className='font-semibold font-museo'
                onClick={() => {
                  window.open(`${baseUrl}item_editor.shtml`, '_blank')
                }}
              >
                Login to item editor
              </Button>
            </div>
          </div>
          <div className="p-4 bg-white border-2 sm:rounded-3xl border-border-primary">
            <div>
              <Tabs defaultValue={store.family.currentTab ? store.family.currentTab : 'default'}>
                <div className='flex flex-wrap items-center justify-between gap-2'>
                  <TabsList variant="primary">
                    <TabsTrigger value="quick" onClick={() => store.family.setCurrentOrderStatusTab("quick")}>Quick view</TabsTrigger>
                    <TabsTrigger value="reports" onClick={() => store.family.setCurrentOrderStatusTab("reports")}>Reports</TabsTrigger>
                    <TabsTrigger value="stocks" onClick={() => store.family.setCurrentOrderStatusTab("stocks")}>Stocks levels</TabsTrigger>
                    <TabsTrigger value="status" onClick={() => store.family.setCurrentOrderStatusTab("status")}>Order status</TabsTrigger>
                  </TabsList>
                  <div className='flex flex-row items-center gap-2'>
                    <p className='text-card-foreground'>View {store.family.currentTab} for</p>
                    <ComboBox
                      className='min-w-[260px]'
                      name="partnerId"
                      isLoading={isLoadingFamily}
                      options={[
                        ...(familyData?.supplier_order
                          .map((supplier) => ({ label: supplier.name, value: supplier.id })) ?? [])
                      ]}
                      placeholder={
                        <div className="flex items-center gap-1">
                          <MdSearch className="w-5 h-5" />
                          <p>
                            Search and select partner
                          </p>
                        </div>
                      }
                      value={{ label: store.family.currentPartner?.label, value: store.family.currentPartner?.value }}
                      onChange={(val) => { store.family.setCurrentPartner(val); window.location.reload(); }}
                    />
                  </div>
                </div>
                <TabsContent value="default">
                  <Card>
                    <CardContent>
                      <div className='flex justify-center py-28'>
                        <p>Select a tab above to begin</p>
                      </div>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="quick">
                  <Card>
                    <CardContent>
                      <div className='grid grid-cols-3 pt-6 divide-x-2 min-h-[200px]'>
                        <div className='flex flex-col col-span-2 gap-6 py-6'>
                          <div>
                            <p>Select production date</p>
                            <div className='flex flex-col items-center gap-4 pt-2 sm:flex-row'>
                              <Select
                                onValueChange={(value) => {
                                  setQuickViewDate(value)
                                }}
                                value={format(new Date(quickViewDate), 'yyyy-MM-dd')}
                              >
                                <SelectTrigger className='h-auto text-left w-full sm:max-w-[320px]'>
                                  <SelectValue placeholder={'Select a date' || quickViewDate} />
                                </SelectTrigger>
                                <SelectContent className='max-h-[250px]'>
                                  <SelectGroup id="chooseDay">
                                    {productionListDates.map((date) => (
                                      <SelectItem key={format(date, 'yyyy-MM-dd')} value={format(new Date(date), 'yyyy-MM-dd')}>
                                        {format(date, 'EEEE d MMM')}
                                      </SelectItem>
                                    ))}
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                              <Button
                                className='w-full font-semibold font-museo sm:w-auto'
                                disabled={!quickViewDate}
                                onClick={() => window.open(`${baseUrl}supplier.shtml?supplier=${currentPartner?.value}&date=${format(new Date(quickViewDate), 'yyyy-MM-dd')}&task=production_list`, '_blank')}
                              >
                                View production list
                              </Button>
                            </div>
                          </div>
                          <div>
                            <p>Select a printable label type</p>
                            <div className='flex flex-col items-center gap-4 pt-2 sm:flex-row'>
                              <Select
                                onValueChange={(value) => {
                                  setLabelType(value)
                                  setLabelError('')
                                  if (value === 'A4_pdf_one' || value === 'A4_pdf_full') {
                                    setPdfPrint(value)
                                  }
                                }}
                                disabled={!quickViewDate}
                              >
                                <SelectTrigger className='h-auto text-left w-full sm:max-w-[320px]'>
                                  <SelectValue placeholder="Select a format" />
                                </SelectTrigger>
                                <SelectContent>
                                  <SelectGroup id="chooseDay">
                                    {LABELS.map((label) => (
                                      <SelectItem key={label.value} value={label.value}>
                                        {label.label}
                                      </SelectItem>
                                    ))}
                                  </SelectGroup>
                                </SelectContent>
                              </Select>
                              <Button
                                className='w-full font-semibold font-museo sm:w-auto'
                                disabled={!quickViewDate || isLoadingQuickOrders || isValidatingQuickOrders}
                                onClick={async () => {
                                  if (labelType === 'A4_pdf_one' || labelType === 'A4_pdf_full') {
                                    if (labelPrint && labelPrint.filename) {
                                      if (labelPrint.filename !== 'no_order') {
                                        window.open(`${baseUrl}cache/` + labelPrint.filename, '_blank')
                                      } else {
                                        // Refetch and open the file if there's an order
                                        setLabelError('');
                                        const data = await mutateQuickOrders();
                                        if (data && data?.filename !== 'no_order') {
                                          window.open(`${baseUrl}cache/` + data.filename, '_blank')
                                        }
                                      }
                                    }
                                  } else {
                                    window.open(`${baseUrl}${labelType === 'label_dymo' ? 'label_print' : 'supplier'}.shtml?supplier=${currentPartner?.value}&date=${format(new Date(quickViewDate), 'yyyy-MM-dd')}&task=${labelType}`, '_blank')
                                  }
                                }}
                              >
                                View and print
                              </Button>
                            </div>
                            {labelError && <p className='text-xs text-destructive'>{labelError}</p>}
                          </div>
                        </div>
                        <div className='p-6'>
                          <p className='pb-2'>View daily report</p>
                          <Dialog open={showDatePicker} onOpenChange={(open) => setShowDatePicker(open)}>
                            <DialogTrigger asChild>
                              <Button
                                className='font-semibold font-museo'
                                onClick={() => setIsSubmit(false)}
                                size='sm'
                              >
                                Download Daily Report
                              </Button>
                            </DialogTrigger>
                            <DialogContent className='sm:h-auto sm:max-w-[375px]'>
                              <DialogHeader>
                                <DialogTitle>Select a date</DialogTitle>
                              </DialogHeader>
                              <div className='self-center'>
                                <Calendar
                                  mode="single"
                                  selected={date}
                                  onSelect={setDate}
                                  initialFocus
                                />
                              </div>
                              <DialogFooter>
                                <Button
                                  className='w-full font-semibold font-museo'
                                  onClick={() => { setIsSubmit(true); setShowDatePicker(false); }}
                                  disabled={!date}
                                >
                                  Download Daily Report
                                </Button>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="reports">
                  <Card>
                    <CardContent>
                      <div className='grid grid-cols-2 pt-6 divide-x-2 min-h-[200px]'>
                        <div className='py-6'>
                          <p className='pb-6 font-museo'>View daily report</p>
                          <Dialog open={showDatePicker} onOpenChange={(open) => setShowDatePicker(open)}>
                            <DialogTrigger asChild>
                              <Button
                                className='font-semibold font-museo'
                                onClick={() => setIsSubmit(false)}
                                size='sm'
                              >
                                Download Daily Report
                              </Button>
                            </DialogTrigger>
                            <DialogContent className='sm:h-auto sm:max-w-[375px]'>
                              <DialogHeader>
                                <DialogTitle>Select a date</DialogTitle>
                              </DialogHeader>
                              <div className='self-center'>
                                <Calendar
                                  mode="single"
                                  selected={date}
                                  onSelect={setDate}
                                  initialFocus
                                />
                              </div>
                              <DialogFooter>
                                <Button
                                  className='w-full font-semibold font-museo'
                                  onClick={() => { setIsSubmit(true); setShowDatePicker(false); }}
                                  disabled={!date}
                                >
                                  Download Daily Report
                                </Button>
                              </DialogFooter>
                            </DialogContent>
                          </Dialog>
                        </div>
                      </div>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="stocks">
                  <Card>
                    <CardContent>
                      <div className="bg-white">
                        <StocksTable
                          columns={stockColumnsWithAction}
                          data={stockData?.stock_list || []}
                          dataLastUpdated={stockDataLastUpdated}
                          isLoading={isLoadingStockData}
                          mutate={mutateProductStocks}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </TabsContent>
                <TabsContent value="status">
                  <Card>
                    <CardContent>
                      <div className="bg-white">
                        <OrderStatusTable
                          columns={columnsWithAction}
                          data={orderDataTable || []}
                          isLoading={isLoadingOrderData}
                          toggleAllStatus={toggleAllStatus()}
                          searchOrders={searchOrders()}
                          mcatProductDict={orderData?.mcat_prod_dict || {}}
                          mutateOrderData={mutateOrderData}
                          resetSelectedStatus={resetSelectedStatus}
                          selectedStatuses={selectedStatuses}
                          dates={dates}
                        />
                      </div>
                    </CardContent>
                  </Card>
                </TabsContent>
              </Tabs>
            </div>
          </div>
        </div>
      </ShopLayout>
    </div>
  )
})

export default OrderStatusPage