import React from 'react'

type Props = {
  title: string;
  description?: string;
}

const FormSectionSubtitle = (props: Props) => {
  const { title, description } = props;
  return (
    <div className='flex flex-col gap-1'>
      <h4 className='text-xs font-bold text-primary'>{title}</h4>
      {description && (
        <p className='pb-3 text-xs text-text-helper'>
          {description}
        </p>
      )}
    </div>
  )
}

export default FormSectionSubtitle