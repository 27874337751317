import React from "react";

export type NodeType = HTMLElement
export type RefType = React.MutableRefObject<any> | ((el: NodeType) => void)

export const preserveRef = (ref: RefType | undefined | null, node: NodeType) => {
    if (ref) {
        if (typeof ref === 'function') {
            ref(node)
        }
        if (ref.hasOwnProperty('current')) {
            (ref as React.MutableRefObject<any>).current = node
        }
    }
}