import React from 'react';

// https://github.com/yairEO/react-hooks/blob/master/hooks/useDetectSticky.js
/**
 * detects when a (CSS) sticky element changes "sticky" state
 * @param {object} ref optional react ref. if not provided, a new one will be used instead.
 * @param {object} observerSettings Observer's settings object
*/

const useDetectSticky = (
  observerSettings = { threshold: [1] }
): [boolean, React.RefObject<HTMLDivElement>, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isSticky, setIsSticky] = React.useState(false)
  const ref = React.useRef<HTMLDivElement>(null)

  // mount 
  React.useEffect(() => {
    if (ref?.current) {
      const cachedRef = ref.current,
        observer = new IntersectionObserver(
          ([e]) => setIsSticky(e.intersectionRatio < 1),
          observerSettings
        )

      observer.observe(cachedRef)

      // unmount
      return () => {
        observer.unobserve(cachedRef)
      }
    }
  }, [])

  return [isSticky, ref, setIsSticky];
}

export default useDetectSticky;