import ContentDialog from 'components/dialog/ContentDialog';
import React from 'react'
import PayableRolloverForm, { PayableRolloverFormProps } from '../PayableRolloverForm';
import { useForm } from 'react-hook-form';
import useAdminPayables from 'networking/hooks/useAdminPayables';
import { b64decode, b64encode } from 'util/data/base64_utf8_tools';
import { useToast } from 'components/base/use-toast';
import { PayableStatus } from 'networking/models/payable';
import { mutate } from 'swr';
import useRemoteConfig from 'util/hooks/useRemoteConfig';
import { Link } from 'react-router-dom';
import { useContentDialog } from 'components/dialog/RootContentDialog';

type Props = {
  permanentId?: string;
  school: string;
  onOpenChange: (open: boolean) => void;
  open: boolean;
}


const PayableRollOverDialog = (props: Props) => {
  const { permanentId, school, open, onOpenChange } = props;
  const { control, handleSubmit, formState: { isSubmitting }, watch, reset, setError } = useForm<PayableRolloverFormProps>({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { toast } = useToast();
  const currentFormValues = watch();
  const { showDialog, closeDialog } = useContentDialog()

  const { getRemoteConfigValue } = useRemoteConfig();
  const rolloverYear = getRemoteConfigValue('payable_rollover_year').asNumber();
  const { payable, isLoading, payableQuestions, refreshPayables, getNewPermanentId, mutate: refetchPayables, rollOverPayables } = useAdminPayables({
    schoolId: school,
    payablePermanentId: permanentId,
    rolloverYear: rolloverYear.toString(),
  });
  const onSubmit = (skipDonationCheck = false) => async (values: PayableRolloverFormProps) => {
    const nameSuggestsDonation = values.label.toLowerCase().includes('donation') || values.label.toLowerCase().includes('contribution');
    const isTaxExempt = values.gst === 'GST exempt';
    const isDonation = values.is_donation;
    console.log('skipDonation ', skipDonationCheck, nameSuggestsDonation, isTaxExempt, isDonation)
    if (
      !skipDonationCheck
      && (
        ((nameSuggestsDonation || isDonation) && !isTaxExempt)
        || (!isDonation && isTaxExempt && nameSuggestsDonation)
      )
    ) {
      showDialog({
        title: 'Is this a donation?',
        description: 'Payables that are donations should include a donation receipt and have a tax exempt price. Go back to edit these settings, or save anyway.',
        secondaryActionProps: {
          onClick: () => {
            handleSubmit(onSubmit(true))(); // Submit again but Skip donation check
            closeDialog();
          }
        },
        secondaryActionLabel: 'Save anyway',
        primaryActionLabel: 'Go back to edit',
        primaryActionProps: {
          onClick: () => {
            closeDialog();
          }
        }
      });
      return;
    }
    const newPermanentId = await getNewPermanentId.trigger();
    if (newPermanentId) {
      const questionsAfter = {
        [newPermanentId]: values.questions,
      }
      const trimmedLabel = values.label.trim();
      const payablesAfter = [
        {
          // Fixed fields:
          menu_category: 'Kindo Payables',
          menu_priority: (payable?.menu_priority ?? 0) + 1,
          consignment: "Cold",
          current: true,
          dairy_free: false,
          delivery_address: "room",
          detail: values.detail?.trim(),
          detail_confirmation: false,
          gluten_free: false,
          halal: false,
          included_option_count: 0,
          member_id: false,
          option_price_in_cents: 0,
          summary: undefined,
          supplier: payable?.supplier,
          supplier_ref: undefined,
          supplier_ref_type: undefined,
          vegetarian: false,
          item_count: 1,
          promote: 5,
          picture: '',
          status: PayableStatus.ACTIVE,
          // Form fields:
          description: values.description ? b64encode(values.description) : "",
          flexi_price_enabled: values.flexi_price_enabled,
          pcat: values.pcat || null,
          max_instances: parseInt(values.maxInstances) || null,
          is_voluntary: values.is_voluntary,
          year: parseInt(values.year),
          is_donation: values.is_donation,
          price_in_cents: parseInt(values.price) * 100,
          product: `${values.year} ${trimmedLabel}`,
          remarks1: values.remarks1?.trim(),
          remarks2: values.remarks2?.trim(),
          gst: values.gst,
          label: trimmedLabel,

          // New fixed payable fields:
          product_id: '',
          permanent_id: newPermanentId,
          rollover_from: payable?.proto_payable_id, // old proto payable id
        },
        {
          ...payable,
          rollover: 'yes',
        }
      ]

      await rollOverPayables.trigger({
        data: {
          after: payablesAfter,
          before: [
            {
              ...payable,
              proto_payable_id: undefined,
              rollover: null,
            }
          ],
          product_question_after: questionsAfter,
          product_question_before: {},
        },

      }).then(async (response) => {
        if (response && !response.error) {
          await refreshPayables.trigger();
          mutate((key: string) => {
            // Refetch protopayable list data
            if (key?.indexOf('%2Froll%2Fpayable%2Fprototype') > -1) {
              return true;
            }
            return false;
          })
          toast({
            description: `'${values.year} ${values.label}' has been saved and published`,
            variant: 'success',
            duration: 5000,
          });
          refetchPayables();
          onOpenChange(false);
        } else {
          toast({
            variant: 'destructive',
            title: `Failed to create payable`,
            ...(response?.error?.name === 'ProductExistsException' ? {
              description: 'Payable name already exists'
            } : {}),
          });
          if (response?.error?.name === 'ProductExistsException') {
            setError('name', {
              type: 'manual',
              message: 'Payable name already exists',
            })
          }
        }
      });
    }
  }
  React.useEffect(() => {
    if (!isLoading && payable) {
      // Initialize form with payable data
      reset({
        name: payable.product,
        year: (rolloverYear + 1).toString(),
        pcat: payable?.pcat,
        product_id: payable.product_id,
        description: payable.description ? b64decode(payable.description) : '',
        remarks1: payable.remarks1,
        remarks2: payable.remarks2,
        gst: payable.gst,
        price: (payable.price_in_cents / 100).toString(),
        is_voluntary: payable.is_voluntary,
        flexi_price_enabled: payable.flexi_price_enabled,
        is_donation: payable.is_donation,
        detail: payable.detail,
        questions: payableQuestions,
        maxInstances: payable?.max_instances?.toString() || '',
        label: payable.label || '',
      })
    }
  }, [isLoading, payable, payableQuestions])
  return (
    <ContentDialog
      open={open}
      title={`Create this payable for the upcoming year`}
      description={
        <span>
          Update basic payable details. You can edit or add information to this payable later. The payable used to create this one will remain unchanged and can be marked as historic when you’re ready.&nbsp;
          <Link target="_blank" to="https://support.kindo.co.nz/portal/en/kb/articles/moving-a-kindo-payables-item-to-kindo-payables-historic">
            <span className="underline">
              Learn about historic payables.
            </span>
          </Link>
        </span>
      }
      onOpenChange={onOpenChange}
      content={
        <PayableRolloverForm control={control} currentFormValues={currentFormValues} />
      }
      primaryActionLabel='Save and publish'
      secondaryActionLabel='Cancel'
      primaryActionProps={{
        disabled: isSubmitting,
        onClick: handleSubmit(onSubmit())
      }}
    />
  )
}

export default PayableRollOverDialog