import React from 'react'
import { Controller, type UseControllerProps } from 'react-hook-form'
import { Switch } from '../base/switch';
import { Label } from '../../common_component/base/label';

type Props = {
  leftLabel?: string;
  rightLabel?: string;
} & UseControllerProps<any>;

const SwitchField: React.FC<Props> = ({
  name,
  control,
  rules,
  leftLabel,
  rightLabel,
}) => {
  return (
    <div>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange, name, onBlur } }) => (
          <div className='flex items-center gap-2'>
            {
              leftLabel && (
                <Label>
                  {leftLabel}
                </Label>
              )
            }
            <Switch
              checked={value}
              name={name}
              onBlur={onBlur}
              onCheckedChange={(value: boolean) => onChange(value)}
            />
            {
              (rightLabel) && (
                <Label>
                  {rightLabel}
                </Label>
              )
            }
          </div>
        )}
      />
    </div>
  )
}

export default SwitchField