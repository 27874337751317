import React from 'react'
import { observer } from 'mobx-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../common_component/base/alert-dialog';
import { useStore } from '../../store/store';
import api from 'networking/api';
import { useMatch, useNavigate } from 'react-router-dom';
import { Button } from 'components/base/button';
import { isAnyAdministrator } from 'util/permissions';

type Props = {}

const NoStudentReminder = observer((props: Props) => {
  const store = useStore();
  const navigate = useNavigate();
  const isInShop = !!useMatch('/app/shop/*');
  const { data, isLoading, error } = api.family.useFamily(undefined, !isInShop);
  const [hasShownOnce, setHasShownOnce] = React.useState(false);

  React.useEffect(() => {
    if (!isLoading && !hasShownOnce && isInShop && !error && !store.shop.isCommunityShop && !store.family.previewingPartner) {
      const isAnyAdmin = isAnyAdministrator(data)
      // if (schoolService) {
      //   // Currently an impossible case until we add community shop?
      //   const studentsForSchool = data?.students.filter((student) => student.school === store.family.currentSchool);
      //   if ((studentsForSchool?.length ?? 0) === 0) {
      //     store.family.setNoStudentDialog(true);
      //   }
      // }
      // If account has no students at all
      if ((data?.students.filter((s) => s.studno !== -1).length ?? 0) === 0 && !isAnyAdmin) {
        store.family.setNoStudentDialog(true);
        setHasShownOnce(true);
      }
    }
  }, [data?.students, store.family.currentSchool, isInShop, isLoading, error]);

  const handleGoToMyDetails = () => {
    navigate('/app/my-details');
    store.family.setNoStudentDialog(false);
  }
  return (
    <AlertDialog
      open={store.family.showNoStudentDialog}
      onOpenChange={() => {
        store.family.setNoStudentDialog(false);
      }}
    >
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>You don't have a student/member {(data?.students.length ?? 0) > 0 ? 'at this school' : 'on your account'}</AlertDialogTitle>
          <AlertDialogDescription>
            <p>
              To make a purchase or payment, add a member/student in My Details.
            </p>
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button variant="outline" onClick={() => store.family.setNoStudentDialog(false)}>
            Not now
          </Button>
          <AlertDialogAction onClick={handleGoToMyDetails}>Go to My Details</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>

  )
});

export default NoStudentReminder