import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { useStore } from '../../store/store';
import { observer } from 'mobx-react';
import useSchoolServices from 'networking/hooks/useSchoolService';


const UrlRedirectHandler = observer(() => {
  const location = useLocation();
  const store = useStore();
  const navigate = useNavigate();
  const { getService, isLoadingServices } = useSchoolServices({
    schoolId: store.family.currentSchool,
  });

  React.useEffect(() => {
    location.search.slice(1).split('&').forEach((param) => {
      const [key, value] = param.split('=');
      if (['shop', 'school', 'schoolId', 'school_id'].includes(key)) {
        // Set current school
        store.family.setCurrentSchool(decodeURI(value));
      }

      if (['partner_preview'].includes(key)) {
        // Set previewing partner to true
        store.family.setPreviewingPartner(true);
      }
    });
  }, []);

  React.useEffect(() => {
    if (!isLoadingServices && location.search) {
      location.search.slice(1).split('&').forEach((param) => {
        const [key, value] = param.split('=');

        if (key === 'service') {
          const serviceId = decodeURI(value);
          const currentService = getService(serviceId);
          if (currentService) {
            window.location.href = `/app/shop/${currentService.brand_name}/${decodeURI(value)}`;
          }
        }
      });
    }
  }, [location.search, isLoadingServices, store.family.currentSchool]);

  return null;
})

export default UrlRedirectHandler