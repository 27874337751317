import React from 'react';
import { Controller, type UseControllerProps } from "react-hook-form";
import { Input, InputProps } from '../base/input';
import { withMask } from 'use-mask-input';

// Removing duplicate prop conflict from InputProps since UseControllerProps already has it
interface Props extends UseControllerProps<any>, Omit<InputProps, 'name' | 'defaultValue'> {
}

const BankAccountField = React.forwardRef<
  InputProps,
  Props
>(({
  name,
  control,
  rules,
  ...props
}, ref) => (
  <Controller
    name={name}
    control={control}
    rules={{
      ...rules,
      validate: {
        ...(rules?.validate ? rules.validate : {}),
        isValidBankNumber: (value) => {
          const bankRegex = /^\d{2}-\d{4}-(\d{7}|\d{8})-(\d{3}|\d{2}|\d{4})$/
          return bankRegex.test(value) || 'Please enter a valid bank account number'
        }
      }
    }}
    render={({ fieldState: { error }, field }) => (
      <Input
        error={error?.message}
        {...field}
        {...props}
        ref={withMask('99-9999-9999999-99[9]')}
      />
    )}
  />
))

export default BankAccountField;
