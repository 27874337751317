import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../common_component/base/table";
import { SearchResult } from 'networking/models/customer';

type Props = {
  searchResults: SearchResult[] | undefined;
  onRowClick: (familyId: string) => void;
  isLoading: boolean;
}

const ResultsTable: React.FC<Props> = ({ searchResults, onRowClick, isLoading }) => {
  return (
    <div className="mb-6 shadow-md rounded-3xl bg-card">
      {searchResults && searchResults.length !== 0 &&
        <Table>
          <TableHeader className="font-bold font-museo">
            <TableRow>
              <TableHead>Family</TableHead>
              <TableHead>Members</TableHead>
              <TableHead>Email</TableHead>
              <TableHead>Handle</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {
              searchResults.map((result: SearchResult) => (
                <TableRow
                  className='cursor-pointer text-card-foreground'
                  key={result.handle}
                  onClick={() => onRowClick(result.family_id)}
                >
                  <TableCell>{result.family_name}</TableCell>
                  <TableCell>
                    {result.students.slice(0, 5).map((student, index) => (
                      <p key={student + '-' + index}>{student}</p>
                    ))}
                    {result.students.length > 5 && `+ ${result.students.length - 5} more..`}
                  </TableCell>
                  <TableCell>{result.email}</TableCell>
                  <TableCell>{result.handle}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      }
      {searchResults && searchResults.length === 0 &&
        <TableRow className='text-card-foreground'>
          <TableCell className="h-24 text-center">
            No results.
          </TableCell>
        </TableRow>
      }
    </div>
  );
}

export default ResultsTable;
