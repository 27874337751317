import React from 'react'
import { useStore } from '../../store/store'
import { useNavigate, useLocation } from 'react-router-dom';
import { baseUrl } from 'networking/constants';

const ContinueLoadingPage = () => {
  const store = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const goToShop = () => {
    window.location.href = `${baseUrl}q2.shtml`
  }

  const goToCommunityShop = () => {
    navigate(`/app/community-shop/${encodeURIComponent(store.family.currentSchool)}`, { replace: true });
  }

  const goToOldMyOrders = () => {
    window.location.href = `${baseUrl}my_orders.shtml`
  }

  const goToMyOrders = () => {
    navigate('/app/my-orders', { replace: true });
  }

  React.useEffect(() => {
    if (store.shop.isCommunityShop) {
      if (location.hash === '#myorders') {
        goToMyOrders();
      } else {
        goToCommunityShop();
      }
    } else {
      if (location.hash === '#myorders') {
        if (store.family.isRenderingFromNativeApp) {
          window.location.href = `mykindo://my-orders`
        } else {
          goToOldMyOrders();
        }
      } else {
        goToShop();
      }
    }
  }, [location.hash, store.family.isRenderingFromNativeApp, store.shop.isCommunityShop]);

  return null;
}

export default ContinueLoadingPage;
