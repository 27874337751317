import Breadcrumbs from "../../../common_component/layout/components/Breadcrumbs";
import React from "react";
import { rootBreadcrumbItems } from "../AdminPage";
import { observer } from "mobx-react";
import { useForm } from "react-hook-form";
import { format } from "date-fns";
import { Helmet } from "react-helmet";
import _ from "lodash";
import api from "networking/api";

import { formatCentsPriceForDisplay } from "../../../util/string_util";
import { useStore } from "../../../store/store";
import { Card, CardContent, CardHeader, CardTitle } from "../../../common_component/base/card";
import CheckboxField from "../../../common_component/form/CheckboxField";
import ComboBoxField from "../../../common_component/form/ComboBoxField";
import { Button } from "../../../common_component/base/button";
import FormGroup from "../../../common_component/form/FormGroup";
import { PayableTable } from "./PayableTable";
import { columns } from "./Columns";
import InfoTooltip from "components/tooltip/InfoTooltip";
import { Link } from "react-router-dom";
import { MdOpenInNew } from "react-icons/md";
import { isTgclAdmin } from "util/permissions";

type FormData = {
  payableCategory?: string;
  payableId?: string;
  payableIncludeHistoric?: boolean;
  application?: string;
  groupCategoryId?: string;
  groupId?: string;
  roomId?: string;
  yearId?: string;
  membership?: string;
  studentRoom?: string;
  studentYearLevel?: string;
  studentEnrolled?: string;
  statusIncludeHidden?: boolean;
  statusIncludeExempt?: boolean;
  paymentNone?: boolean;
  paymentPart?: boolean;
  paymentPaid?: boolean;
  includeHistoric?: boolean;
}

const PayableStatusPage = observer(() => {
  const store = useStore();
  const { control, getValues, watch, reset } = useForm<FormData>({
    defaultValues: {
      statusIncludeHidden: false,
      statusIncludeExempt: false,
      paymentNone: true,
      paymentPaid: false,
      paymentPart: true,
    },
  });
  const filterValues = watch();
  const currentSchool = store.admin.currentSchool;
  const { data: roomData, isLoading: isLoadingRooms } = api.admin.useMetaGroups(currentSchool, 'room');
  const { data: yearData, isLoading: isLoadingYears } = api.admin.useMetaGroups(currentSchool, 'year_level');
  const { data: groupData, isLoading: isLoadingGroups } = api.admin.useGroups(currentSchool);
  const { data: payableCategoriesData, isLoading: isLoadingPayableCategories } = api.admin.usePayableCategories(currentSchool);
  const { data: protoPayablesData, isLoading: isLoadingProtoPayables } = api.admin.useProtoPayables(currentSchool, {
    allProtos: filterValues.includeHistoric,
  });
  const { data: familyData } = api.admin.useFamily();
  const [showAdvancedFilters, setShowAdvancedFilters] = React.useState(false);
  const canSeeAdminPage = isTgclAdmin(familyData);

  const { data, isLoading: isLoadingPayables, mutate, isValidating } = api.admin.usePayables(currentSchool, {
    ...(filterValues.payableId ? { proto_payable_id: filterValues.payableId } : {}),
    ...(filterValues.application ? {
      metagroup: filterValues.application,
      ...(filterValues.application === 'adhoc' && filterValues.groupId ? {
        group_identifier: filterValues.groupId,
      } : {}),
    } : {}),
    ...(filterValues.application === 'school' ? {
      group_identifier: currentSchool,
    } : {}),
    ...(filterValues.application === 'room' && !!filterValues.roomId ? {
      group_identifier: filterValues.roomId,
    } : {}),
    ...(filterValues.application === 'year_level' && !!filterValues.yearId ? {
      group_identifier: filterValues.yearId,
    } : {}),
    ...(filterValues.membership ? { membership_group_id: filterValues.membership } : {}),
    status: [
      'ANY', // Must have to load payables
      ...(filterValues.statusIncludeHidden ? ['hidden'] : []), // Includes hidden payables
      ...(filterValues.statusIncludeExempt ? ['exempt'] : []), // Includes exempt payables
    ],
  });

  const sortedGroups = React.useMemo(() => {
    return (groupData?.roll_adhoc_groups || [])
      .sort((a, b) => a.group_id.localeCompare(b.group_id, 'en', { sensitivity: 'base' })) ?? [];
  }, [groupData?.roll_adhoc_groups]);

  const sortedFilteredGroups = React.useMemo(() => {
    return sortedGroups
      .filter((g) => filterValues.groupCategoryId ? g.gcat === filterValues.groupCategoryId : true);
  }, [filterValues.groupCategoryId, sortedGroups]);

  const sortedGroupCategories = _.uniq(groupData?.roll_adhoc_groups.map((g) => g.gcat) ?? []).sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' })) ?? [];
  const items = React.useMemo(() => {
    return (data?.payables || [])
      .filter((payable) => {
        // Client side filters
        let result = true;
        if (filterValues.payableCategory) {
          result = result && filterValues.payableCategory === payable.proto_pcat;
        }
        if (filterValues.studentYearLevel && filterValues.studentYearLevel !== 'ANY') {
          result = result && filterValues.studentYearLevel === payable.student_year_level;
        }

        if (filterValues.studentRoom === '<<NONE>>') {
          result = result && !payable.student_room
        } else if (filterValues.studentRoom && filterValues.studentRoom !== 'ANY') {
          result = result && filterValues.studentRoom === payable.student_room
        }

        const isExempt = payable.charged_amount_in_cents_excl === 0;
        const unpaidAmount = payable.current_amount_in_cents_incl; //(payable.charged_amount_in_cents_excl + payable.charged_gst_in_cents) - payable.current_amount_in_cents_incl;
        const chargedAmount = payable.charged_amount_in_cents_excl + payable.charged_gst_in_cents;
        const paidAmount = chargedAmount - unpaidAmount;
        // No payments
        if (!filterValues.paymentNone) {
          result = result && !((paidAmount === 0 && unpaidAmount > 0));
        } else {
          // Exclude prepaid unless exempt
          result = result && (payable.student_payable_type !== 'PREPAID' || isExempt);
        }
        // Part payments
        if (!filterValues.paymentPart) {
          result = result && !(unpaidAmount > 0 && paidAmount > 0);
        }
        if (!filterValues.paymentPaid) {
          result = result && !(unpaidAmount === 0 && !isExempt);
        } else {
          // Include prepaid
          result = result || (payable.student_payable_type === 'PREPAID');
        }

        // Enrolment status
        if (filterValues.studentEnrolled === 'leftOnly') {
          result = result && !!payable.student_left_date;
        }
        if (filterValues.studentEnrolled === 'currentOnly') {
          result = result && !payable.student_left_date;
        }
        return result;
      })
      .map((payable) => {
        const isExempt = payable.charged_amount_in_cents_excl === 0;
        return {
          id: payable.student_payable_id,
          protoPayableId: payable.proto_payable_id,
          payable: payable,
          name: `${payable.student_preferred_first_name || payable.student_first_name || 'Student Left'} ${payable.student_preferred_last_name || payable.student_last_name || ''}`,
          firstName: payable.student_preferred_first_name || payable.student_first_name || 'Student Left',
          lastName: payable.student_preferred_last_name || payable.student_last_name || '-',
          item: payable.proto_payable_name,
          room: payable.student_room || '-',
          applied: `${payable.created ? format(new Date(payable.created), 'PP') : ''}`,
          requested: `${payable.last_ask ? format(new Date(payable.last_ask), 'PP') : '-'}`,
          rule: payable.bind_payable_id ? 'Yes' : 'No',
          paid: `${isExempt ? '---' : `${formatCentsPriceForDisplay(payable.charged_amount_in_cents_excl + payable.charged_gst_in_cents - payable.current_amount_in_cents_incl)} ${payable.student_payable_type === 'PREPAID' ? '(PREPAID)' : ''}`}`,
          unpaid: `${isExempt ? '---' : `${payable.student_payable_type === 'PREPAID' ? '$0.00' : formatCentsPriceForDisplay(payable.current_amount_in_cents_incl)}`}`,
          hidden: payable.hide ? 'Hidden' : '',
          exempt: isExempt ? 'Exempt' : '',
          payableType: payable.student_payable_type,
          studentId: payable.student_id,
          comments: payable.comments?.join('\n\n ') ?? '',
        }
      });
  }, [
    data?.payables,
    // Frontend filters
    filterValues.payableCategory,
    filterValues.paymentNone,
    filterValues.paymentPaid,
    filterValues.paymentPart,
    filterValues.statusIncludeExempt,
    filterValues.statusIncludeHidden,
    filterValues.studentEnrolled,
    filterValues.studentRoom,
    filterValues.studentYearLevel
  ]);

  const sortedPayableCategories = React.useMemo(() => {
    return _.sortedUniq([
      ...(payableCategoriesData?.pcats || []),
      ...(protoPayablesData?.proto_payables.map((p) => p.pcat) ?? []),
    ].sort()) ?? [];
  }, [payableCategoriesData?.pcats, protoPayablesData?.proto_payables]);

  const sortedFilteredProtoPayables = React.useMemo(() => {
    return protoPayablesData?.proto_payables
      .filter((p) => filterValues.payableCategory ? p.pcat === filterValues.payableCategory : true)
      .sort((a, b) => (a.proto_payable_name || '').localeCompare(b.proto_payable_name || '', 'en', { sensitivity: 'base' }),) ?? [];
  }, [protoPayablesData?.proto_payables, filterValues.payableCategory]);

  const sortedRooms = React.useMemo(() => {
    return roomData?.groups.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' })) ?? [];
  }, [roomData?.groups]);

  const sortedYears = React.useMemo(() => {
    return yearData?.groups.sort((a, b) => a.localeCompare(b, 'en', { sensitivity: 'base' })) ?? [];
  }, [yearData?.groups]);

  return (
    <div>
      <Helmet>
        <title>Payable Status</title>
      </Helmet>
      <div className="flex flex-col gap-4">
        <div>
          <Breadcrumbs
            items={[
              ...(rootBreadcrumbItems),
              { label: 'Payable Status', to: '/app/admin/payable-status', active: true },
            ]}
          />
        </div>
        <div className="relative z-20 flex flex-col gap-2">
          <Card className="transition-shadow shadow-md hover:shadow-xl rounded-3xl">
            <CardHeader>
              <CardTitle>
                Payable Status
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="flex flex-col gap-6">
                <FormGroup>
                  <div className="flex flex-col min-w-[400px] flex-1 gap-3">
                    <p className="font-bold text-primary font-museo">
                      Payable
                    </p>
                    <div className="flex flex-row flex-wrap items-center gap-3">
                      <ComboBoxField
                        label="Category"
                        name="payableCategory"
                        control={control}
                        isLoading={isLoadingPayableCategories}
                        minWidth={150}
                        placeholder="Any"
                        options={[
                          { label: 'Any', value: '' },
                          ...(sortedPayableCategories.map((pcat) => ({ label: pcat, value: pcat })) ?? [])
                        ]}
                      />
                      <ComboBoxField
                        label="Payable"
                        name="payableId"
                        control={control}
                        isLoading={isLoadingProtoPayables}
                        minWidth={250}
                        placeholder="Any"
                        options={[
                          { label: 'Any', value: '' },
                          ...(sortedFilteredProtoPayables
                            .map((p) => ({ label: p.proto_payable_name, value: p.proto_payable_id })) ?? [])
                        ]}
                      />
                      <div className="flex gap-1 mt-5">
                        <CheckboxField
                          control={control}
                          name="includeHistoric"
                          label="All"
                        />
                        <InfoTooltip
                          messages={[
                            'When checked, payables that have been moved to Kindo Payables Historic will also be shown.',
                            <Link to="https://support.kindo.co.nz/portal/en/kb/articles/moving-a-kindo-payables-item-to-kindo-payables-historic" target="_blank">
                              <Button
                                className='px-0'
                                variant="linkUnderline"
                                size="xs"
                                onClick={(e) => {
                                  e.stopPropagation();
                                }}>
                                Find out more <MdOpenInNew className='w-4 h-4 ml-1' />
                              </Button>
                            </Link>
                          ]}
                        />
                      </div>
                      {
                        showAdvancedFilters && (
                          <div className="flex flex-row flex-wrap gap-3">
                            <ComboBoxField
                              label="Applied to"
                              name="application"
                              minWidth={150}
                              control={control}
                              placeholder="Any"
                              options={[
                                { label: 'Any', value: '' },
                                { label: 'Per student level', value: 'student' },
                                { label: 'Entire School', value: 'school' },
                                { label: 'Group', value: 'adhoc' },
                                { label: 'Room', value: 'room' },
                                { label: 'Year Level', value: 'year_level' },
                              ]}
                            />
                            {
                              filterValues.application === 'adhoc' && (
                                <>
                                  <ComboBoxField
                                    label="Group Category"
                                    name="groupCategoryId"
                                    control={control}
                                    isLoading={isLoadingGroups}
                                    placeholder="Any"
                                    minWidth={120}
                                    options={[
                                      { label: 'Any', value: '' },
                                      ...(sortedGroupCategories.map((g) => ({ label: g, value: g })) ?? [])
                                    ]}
                                  />
                                  <ComboBoxField
                                    label="Group"
                                    name="groupId"
                                    control={control}
                                    isLoading={isLoadingGroups}
                                    placeholder="Any"
                                    minWidth={200}
                                    options={[
                                      { label: 'Any', value: '' },
                                      ...(sortedFilteredGroups
                                        .map((g) => ({ label: g.group_id, value: g.group_id })) ?? [])
                                    ]}
                                  />

                                </>
                              )
                            }

                            {
                              filterValues.application === 'room' && (
                                <ComboBoxField
                                  label="Room"
                                  name="roomId"
                                  control={control}
                                  minWidth={150}
                                  isLoading={isLoadingRooms}
                                  placeholder="Any"
                                  options={[
                                    { label: 'Any', value: '' },
                                    ...(sortedRooms.map((g) => ({ label: g, value: g })) ?? []),
                                  ]}
                                />

                              )
                            }
                            {
                              filterValues.application === 'year_level' && (
                                <ComboBoxField
                                  label="Year"
                                  name="yearId"
                                  control={control}
                                  minWidth={150}
                                  isLoading={isLoadingRooms}
                                  placeholder="Any"
                                  options={[
                                    { label: 'Any', value: '' },
                                    ...(sortedYears.map((g) => ({ label: g, value: g })) ?? [])
                                  ]}
                                />

                              )
                            }
                          </div>
                        )
                      }

                    </div>
                  </div>

                  <div className="flex flex-col flex-1 gap-3 border-border">
                    <p className="font-bold text-primary font-museo">
                      Student
                    </p>
                    <div className="flex flex-row flex-wrap items-center gap-3">
                      <ComboBoxField
                        label="Room"
                        name="studentRoom"
                        control={control}
                        minWidth={200}
                        placeholder="Any"
                        isLoading={isLoadingRooms}
                        options={[
                          { label: 'Any', value: 'ANY' },
                          ...(sortedRooms.map((g) => ({ label: g, value: g })) ?? []),
                          { label: 'None', value: '<<NONE>>' },
                        ]}
                      />
                      <ComboBoxField
                        label="Year"
                        name="studentYearLevel"
                        control={control}
                        isLoading={isLoadingYears}
                        minWidth={150}
                        placeholder="Any"
                        options={[
                          { label: 'Any', value: 'ANY' },
                          ...(sortedYears.map((g) => ({ label: g, value: g })) ?? [])
                        ]}
                      />

                      <ComboBoxField
                        label="Enrolment status"
                        name="studentEnrolled"
                        control={control}
                        minWidth={150}
                        infoTooltipMessages={[
                          'This function is available for some SMS',
                        ]}
                        placeholder="Any"
                        options={[
                          { label: 'Any', value: '' },
                          { label: 'Current or Left', value: 'currentOrLeft' },
                          { label: 'Current only', value: 'currentOnly' },
                          { label: 'Left only', value: 'leftOnly' },
                        ]}
                      />
                      {
                        showAdvancedFilters && (
                          <ComboBoxField
                            label="Member of"
                            name="membership"
                            minWidth={200}
                            control={control}
                            placeholder="Any"
                            options={[
                              { label: 'Any', value: '' },
                              ...(sortedGroups.map((g) => ({ label: g.group_id, value: g.group_id })) ?? [])
                            ]}
                          />
                        )
                      }
                    </div>
                  </div>
                </FormGroup>
                <FormGroup>
                  <div className="flex flex-row flex-1 gap-3">
                    <CheckboxField
                      control={control}
                      name="paymentNone"
                      label="No payments"
                    />
                    <CheckboxField
                      control={control}
                      name="paymentPart"
                      label="Part payment"
                    />
                    <CheckboxField
                      control={control}
                      name="paymentPaid"
                      label="Paid"
                    />
                  </div>
                  <div className="flex justify-between flex-1 gap-4">
                    <div className="flex items-center gap-3">
                      {
                        showAdvancedFilters && (
                          <>
                            <CheckboxField
                              control={control}
                              name="statusIncludeHidden"
                              label="Include Hidden"
                            />
                            <CheckboxField
                              control={control}
                              name="statusIncludeExempt"
                              label="Include Exempt"
                            />
                          </>
                        )
                      }
                    </div>
                    <div className="flex gap-4">
                      <Button className="px-0" variant="link" size="xs" onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}>
                        {showAdvancedFilters ? 'Hide' : 'Show'} advanced filters
                      </Button>
                      <Button className="px-0" variant="link" size="xs" onClick={() => reset()}>
                        Clear filters
                      </Button>
                    </div>
                  </div>
                </FormGroup>
              </div>
            </CardContent>
          </Card>
        </div>

        <div>
          <div className="mb-6 shadow-md rounded-3xl bg-card">
            <PayableTable
              columns={columns}
              data={items}
              userName={`${familyData?.contact_name} ${familyData?.family_name}`}
              isLoading={isLoadingPayables || isValidating}
              onRefresh={mutate}
              school={currentSchool}
              showExemptColumn={filterValues.statusIncludeExempt}
              showHiddenColumn={filterValues.statusIncludeHidden}
              showIdColumn={canSeeAdminPage}
              showProtoPayableIdColumn={canSeeAdminPage}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default PayableStatusPage;
