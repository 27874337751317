import React from 'react';
import api from 'networking/api';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { Button } from 'components/base/button';
import ShopLayout from 'components/layout/ShopLayout';
import Container from 'components/layout/page/Container';
import { Spinner } from 'components/base/spinner';
import Section from 'components/layout/section/Section';
import SectionTitle from 'components/layout/section/SectionTitle';
import SectionContent from 'components/layout/section/SectionContent';
import ActionListItem from 'components/list/ActionListItem';

import EditStudentDialog from './dialogs/EditStudentDialog';
import DeleteStudentDialog from './dialogs/DeleteStudentDialog';

const StudentPage = () => {
  const { studentId } = useParams<{
    studentId: string;
  }>();
  const [showNameForm, setShowNameForm] = React.useState(false);
  const [showAllergyForm, setShowAllergyForm] = React.useState(false);
  const [showSchoolForm, setShowSchoolForm] = React.useState(false);
  const [schoolToEdit, setSchoolToEdit] = React.useState<string | undefined>(undefined);
  const [showDeleteStudentDialog, setShowDeleteStudentDialog] = React.useState(false);

  const { data, isLoading } = api.family.useMyDetails();

  const student = data?.students?.find(student => student.student_id === studentId);
  const studentSchools = data?.students?.filter(student => student.student_id === studentId)
  const isSMSStudent = data?.students?.some((s) => s.student_id === studentId && !!s.external_id)
  return (
    <div>
      <Helmet>
        <title>Manage Member</title>
      </Helmet>
      <EditStudentDialog
        open={showNameForm || showAllergyForm || showSchoolForm}
        onClose={() => {
          setShowNameForm(false);
          setShowAllergyForm(false);
          setShowSchoolForm(false);
        }}
        studentId={studentId}
        editableFields={
          showNameForm ? ['name'] :
            showAllergyForm ? ['allergy'] :
              showSchoolForm ? ['school'] : []
        }
        studentSchoolId={schoolToEdit}
      />
      <DeleteStudentDialog
        open={!!showDeleteStudentDialog}
        onClose={() => setShowDeleteStudentDialog(false)}
        studentId={studentId}
      />
      <ShopLayout>
        <Container
          title="Manage Member"
          breadcrumbItems={[
            {
              label: 'My Details',
              to: '/app/my-details',
            },
            {
              label: 'Manage Member',
              to: '',
              active: true,
            }
          ]}
        >
          {isLoading && !data ?
            <div className='flex items-center justify-center w-full py-6'>
              <Spinner size="md" />
            </div>
            :
            <div className='flex flex-col gap-6'>
              <Section>
                <SectionTitle title="Member information" />
                <SectionContent>
                  <ActionListItem
                    label="Name"
                    description={`${student?.first_name} ${student?.last_name}`}
                  />
                  <ActionListItem
                    label="Allergies"
                    description={`${student?.allergy || 'None'}`}
                    onClick={() => {
                      setShowAllergyForm(true);
                    }}
                  />
                </SectionContent>
              </Section>
              <Section>
                <SectionTitle title="Schools & organisations" />
                <SectionContent noDivider={isSMSStudent}>
                  {
                    (studentSchools || [])
                      .sort((a, b) => a.school.localeCompare(b.school, 'en', { sensitivity: 'base' }))
                      .map((studentSchool) => (
                        <ActionListItem
                          key={studentSchool.student_school_id}
                          label={studentSchool.school}
                          description={studentSchool.room}
                          descriptionVariant='helper'
                          onClick={() => {
                            setSchoolToEdit(studentSchool.student_school_id);
                            setShowSchoolForm(true);
                          }}
                        />
                      ))
                  }
                  <div className='px-4 pt-6'>
                    <Button
                      type='button'
                      variant="outline"
                      onClick={() => {
                        setShowSchoolForm(true);
                        setSchoolToEdit(undefined);
                      }}
                    >
                      Add membership
                    </Button>
                  </div>
                </SectionContent>
              </Section>

              {
                !isSMSStudent && (
                  <Section>
                    <SectionContent noDivider>
                      <ActionListItem
                        label="Delete member"
                        labelVariant='danger'
                        description={`Member will be removed from your account`}
                        descriptionVariant='helper'
                        onClick={() => {
                          setShowDeleteStudentDialog(true);
                        }}
                      />
                    </SectionContent>
                  </Section>
                )
              }
            </div>
          }
        </Container>
      </ShopLayout>
    </div>
  )
}

export default StudentPage