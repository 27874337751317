import React from 'react'
import { Button } from '../../common_component/base/button'
import KindoWell from '../../common_component/KindoWell'
import api from 'networking/api'
import { useStore } from '../../store/store'
import _ from 'lodash'
import { Skeleton } from '../../common_component/base/skeleton'
import FeesAndDonations from './FeesAndDonations'
import Helmet from 'react-helmet'
import Image from '../../common_component/Image';
import { baseUrl } from 'networking/constants'
import MultipleService from './BrowseShops/MultipleService'
import SingleService from './BrowseShops/SingleService'
import Ezlunch from './BrowseShops/Ezlunch'
import HomeImage from 'assets/images/hero-image.png';
import useSchoolServices from 'networking/hooks/useSchoolService'
type Props = {}

const ShopHome = (props: Props) => {
  const { family: { currentSchool }, shop } = useStore();
  const { data: familyData, isLoading: isLoadingFamilyData } = api.family.useFamily();
  const { data, isLoading } = api.family.useFamilyPayables(currentSchool);
  const {
    feesAndDonationsService,
    schoolService,
    kindoServices,
    ezlunchServices,
  } = useSchoolServices({
    schoolId: currentSchool,
  })
  const onlyOneKindoService = kindoServices.length === 1;

  const { data: motdDataSchool } = api.partner.useMessageOfTheDay(currentSchool);
  const messages = [
    ...(motdDataSchool?.motds && motdDataSchool.motds.length > 0 ?
      [`${motdDataSchool.motds[0].text}`] : []),
  ];
  const { data: motdData } = api.partner.useMessageOfTheDay('KINDO_ALL', 'home');
  const { data: welcomeImageUrl } = api.family.useSwi(schoolService?.path, HomeImage);

  shop.setIsCommunityShop(false);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <div className='flex flex-col gap-0 pb-8'>
        {
          motdData?.motds && motdData?.motds.length > 0 && motdData?.motds.sort((a, b) => a.priority - b.priority).map((motd) => (
            <KindoWell
              variant={motd.info_type}
              key={`${motd.info_type + '_' + motd.priority}`}
              className='mb-2'
              messages={[motd.text]}
            />
          ))
        }
        {
          messages.length > 0 && (
            <KindoWell
              className='mb-2'
              messages={messages}
            />
          )
        }
        {
          !isLoading && (data?.unlinked.length ?? 0) > 0 && (
            <KindoWell
              variant="danger"
              messages={[
                'We have detected one or more students that are not linked to school records. Completing setup will ensure you have visibility of all school payment requests.',
                ...(data?.unlinked || []).length > 0 ? [
                  <div className='flex flex-col gap-1 py-1'>
                    {
                      data?.unlinked.map((student) => (
                        <p key={student.student_id} className='text-sm font-bold'>
                          &#x2022; {student.first_name} {student.last_name}
                        </p>
                      ))
                    }
                  </div>,
                ] : [],
              ]}
              action={{
                label: 'Complete Setup',
                onClick: () => {
                  window.location.href = `${baseUrl}handshake.shtml?shop=${currentSchool}`;
                }
              }}
            />
          )
        }
        <div className='relative z-10'>
          <div className='flex flex-col  w-full lg:w-[52%] 2xl:w-[52%] 3xl:w-[63%] min-h-[auto] lg:min-h-[217px] md:mt-6'>
            <p data-testid="label-welcome-user" className='flex items-center gap-2 mt-6 mb-4 text-3xl sm:mt-11 sm:text-4xl md:text-5xl text-primary font-museo'>
              Welcome {familyData?.contact_name}

              {
                isLoadingFamilyData && (
                  <Skeleton className='w-[240px] h-10' />
                )
              }
            </p>

            <p className='text-card-foreground'>
              Simplify school life by accessing all your school payments, forms & shopping online.
            </p>
            <div className='mb-3.5'>
              <Button variant="link" className='p-0 font-museo' onClick={() => {
                window.open('https://support.mykindo.co.nz/', '_blank')
              }}>
                Learn more about Kindo
              </Button>
            </div>
          </div>
          {schoolService && (feesAndDonationsService || ezlunchServices.length > 0 || (!feesAndDonationsService && ezlunchServices.length === 0 && !onlyOneKindoService)) && (
            <Image
              key={welcomeImageUrl}
              className='hidden object-cover lg:block h-[245px] absolute right-6 w-full top-6 pointer-events-none max-w-[360px] rounded-3xl'
              alt=''
              src={`${welcomeImageUrl}`}
            />
          )}
        </div>
        <div className='flex flex-col gap-4'>

          <FeesAndDonations showTitle withCard listName='Fees and Donations' />
          {
            ezlunchServices.length > 0 && (
              <Ezlunch />
            )
          }
          {
            kindoServices.length > 0 && (
              <>
                {
                  onlyOneKindoService ? (
                    <SingleService />
                  ) : (
                    <MultipleService />
                  )
                }
              </>
            )
          }
          <div className='flex items-center justify-center w-full pt-8 lg:hidden'>
            {schoolService && (
              <Image
                key={welcomeImageUrl}
                className='h-[220px] max-w-[100%] rounded-lg object-cover'
                alt=''
                src={`${welcomeImageUrl}`}
              />
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default ShopHome