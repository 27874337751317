import family from './family';
import order from './order';
import misc from './misc';
import shop from './shop';
import school from './school';
import admin from './admin';
import partner from './partner';
import supplier from './supplier';
import notifications from './notifications';
import proffer from './proffer';
import customer from './customer';
import payable from './payable';

const api = {
  admin,
  family,
  order,
  misc,
  notifications,
  shop,
  school,
  partner,
  payable,
  proffer,
  supplier,
  customer
}

export default api;