import React from "react";
import { useMatch, Link } from "react-router-dom";
import { MdAdd, MdExpandMore, MdRemove } from "react-icons/md";
import { cn } from "../../../util/tailwind";
import { Service } from "networking/models/family";
import { Button } from "../../base/button";
import { useStore } from "../../../store/store";
import { observer } from "mobx-react";

export type SubNavProps = {
  name: string;
  to: string;
  label: string;
  activePath?: string;
  badge?: React.ReactNode;
  services?: Service[];
  onCloseSubNav: () => void;
}
const SubNavButton = React.forwardRef<HTMLAnchorElement, SubNavProps>(({ name, badge, label, to, activePath }: SubNavProps, ref) => {
  const active = useMatch(activePath || to || '');
  const words = (label || '').split(' ');
  return (
    <Link {...(active ? { ref } : {})} to={to}>
      <Button
        name={name}
        className="relative rounded-bl-none rounded-br-none whitespace-nowrap text-ellipsis font-museo"
        size="lg"
        variant={active ? 'default' : 'ghost'}
      >
        <span className={cn('', {
          'max-w-[120px] text-ellipsis whitespace-nowrap overflow-hidden': !active
        })}>
          {active ? label : words.slice(0, 3).join(' ')}{!active && words.length > 3 ? '...' : ''}
        </span>
        {
          badge && <span className='absolute -top-2 right-3'>
            {badge}
          </span>
        }
      </Button>
    </Link>
  )
});

export type DropdownNavButtonProps = {
  to: string;
  label: string;
  activePath?: string;
  activeVariant?: any;
}
const DropdownNavButton = (({ label, to, activePath, activeVariant }: DropdownNavButtonProps) => {
  const active = useMatch(activePath || to || '');
  const words = (label || '').split(' ');

  return (
    <Link to={to}>
      <Button
        className="justify-start w-full px-4 font-museo text-primary"
        size="lg"
        variant={active ? activeVariant ? activeVariant : 'linkUnderline' : 'list'}
      >
        <span className={cn('max-w-[200px] text-ellipsis whitespace-nowrap overflow-hidden', {
          'font-semibold': active
        })}>
          {active ? label : words.slice(0, 3).join(' ')}{!active && words.length > 3 ? '...' : ''}
        </span>
      </Button>
    </Link>
  )
});


const SideMenuService = observer(({ service, onCloseSubNav }: { service: Service, onCloseSubNav: () => void; }) => {
  const store = useStore();
  const servicePath = store.shop.isCommunityShop ? `/app/community-shop/${store.family.currentSchool}/${service.brand_name}/${service.id}` : `/app/shop/${service.brand_name}/${service.id}`;
  const active = !!useMatch(servicePath)
  const [expanded, setExpanded] = React.useState(active);
  React.useEffect(() => {
    if (!active) {
      setExpanded(false);
    }
  }, [active])
  return (
    <div>
      <Link data-testid={`button-${service.id}`} to={servicePath} onClick={() => {
        if (service.mcats.length <= 1) {
          onCloseSubNav();
        }
        if (service.mcats.length > 0) {
          setExpanded(!expanded);
        }
      }}>
        <div className={cn('pl-6 pr-4 py-2 text-sm justify-between flex bg-transparent min-h-[44px]', {
          'font-bold bg-background text-primary': active && expanded,
        })}>
          <span className="flex-1">
            {service.title}
          </span>
          {
            service.mcats.length > 1 && (
              <>
                {
                  expanded ? (
                    <MdRemove className={cn('w-4 h-4 self-start')} />
                  ) : (
                    <MdAdd className={cn('w-4 h-4 self-start')} />
                  )
                }
              </>
            )
          }
        </div>
      </Link>
      {
        expanded && service.mcats.length > 1 && (
          <div className='flex flex-col pb-4'>
            {
              (service?.mcats ?? []).map((cat) => (
                <p
                  key={cat.id}
                  data-testid={`button-${cat.id}`}
                  className={cn('flex text-sm items-center border-l-2 pl-4 border-primary-light ml-6 py-2 pr-3 h-[44px]', {
                    'font-bold text-primary border-primary': store.shop.scrollToActiveId === cat.id,
                  })}
                  onClick={() => {
                    store.shop.setScrollToCategoryId(cat.id);
                    onCloseSubNav();
                  }}
                >
                  {`${cat.name} (${store.shop.menuCategoryInfos.get(cat.id)?.filteredCount ?? 0})`}
                </p>
              ))
            }
          </div>
        )
      }
    </div>
  )
});

const MobileServiceNavButton = (({ name, badge, label, to, activePath, services, onCloseSubNav }: SubNavProps) => {
  const active = !!useMatch(activePath || to || '');
  const servicesWithMcats = services?.filter((service) => (service?.mcats ?? []).length > 0) ?? [];
  const servicesMoreThanOneMcats = services?.filter((service) => (service?.mcats ?? []).length > 1) ?? [];
  const [expanded, setExpanded] = React.useState(active);
  React.useEffect(() => {
    if (!active) {
      setExpanded(false);
    }
  }, [active])
  return (
    <>
      <Link to={to} onClick={(e) => {
        e.stopPropagation();
        if (servicesMoreThanOneMcats.length === 0) {
          onCloseSubNav();
        }
        if (servicesMoreThanOneMcats.length > 0) {
          setExpanded(!expanded);
        }
      }}>
        <Button
          name={name}
          className={cn("w-full h-[44px] text-lg pl-2 pr-3 rounded-md justify-between relative whitespace-nowrap text-ellipsis font-museo hover:no-underline", {
            'bg-background': active,
            'rounded-bl-none rounded-br-none': expanded && servicesMoreThanOneMcats.length > 0,
          })}
          size="lg"
          variant="link"
        >
          <span className={cn('text-left flex-1 overflow-hidden text-ellipsis whitespace-nowrap', {
          })}>
            {label}
          </span>
          {
            badge && <span className='absolute sm:-top-2 right-3'>
              {badge}
            </span>
          }
          {servicesMoreThanOneMcats && servicesMoreThanOneMcats.length > 0 && (
            <MdExpandMore
              className={cn('w-6 h-6 transition-transform', {
                'transform rotate-180': expanded
              })}
            />
          )}
        </Button>
      </Link>
      {
        expanded && servicesMoreThanOneMcats.length > 0 && (
          <div className={cn('rounded-bl-md rounded-br-md mb-2', {
            'bg-background': active
          })}>
            {
              servicesWithMcats
                .map((service) => (
                  <SideMenuService key={service.id} service={service} onCloseSubNav={onCloseSubNav} />
                ))
            }
          </div>
        )
      }
    </>
  )
});


export type NavButtonProps = {
  name: string;
  label: string;
  className?: string;
  to?: string; url?:
  string; activePath?: string;
  subNavItems: SubNavProps[];
  icon?: React.ReactNode;
  hidden?: boolean;
  hideForMobile?: boolean;
  onClick?: () => void;
}
const TopLevelNavButton = ({ name, className, label, activePath, to, url }: NavButtonProps) => {
  const active = useMatch(activePath || to || '');
  const button = (
    <Button
      name={name}
      className={cn('font-museo min-w-[90px] h-[44px] pt-4', {
        'font-bold rounded-tl-none rounded-tr-none ': active
      }, className)}
      variant={active ? "default" : "link"}
      {...(url ? {
        onClick: () => {
          window.location.href = url;
        }
      } : {})}
    >
      {label}
    </Button>
  )
  if (!to && url) {
    return button;
  }
  if (to) {
    return (
      <Link to={to}>
        {button}
      </Link>
    )
  }
  return null;
}

const TopLevelNavOutlineButton = ({ className, label, activePath, to, url }: NavButtonProps) => {
  const active = useMatch(activePath || to || '');
  const button = (
    <Button
      className={cn('font-museo', {
        'font-bold': active
      }, className)}
      variant={active ? "default" : "outline"}
      {...(url ? {
        onClick: () => {
          window.location.href = url;
        }
      } : {})}
    >
      {label}
    </Button>
  )
  if (!to && url) {
    return button;
  }
  if (to) {
    return (
      <Link to={to}>
        {button}
      </Link>
    )
  }
  return null;
}

const MobileNavButton = ({ name, label, activePath, to, url, subNavItems, onClick }: NavButtonProps) => {
  const active = useMatch(activePath || to || '');
  const [expanded, setExpanded] = React.useState(!!active);
  const button = (
    <div className='flex flex-col'>
      <Link to={to ?? ''}>
        <Button
          name={name}
          className={cn('font-museo w-full text-lg justify-between h-[44px] p-0 px-2 rounded-md', {
            'bg-background': active
          })}
          variant="link"
          onClick={() => {
            if (url) {
              window.location.href = url;
            }
            if (onClick) {
              onClick();
            }
            if (subNavItems && subNavItems.length > 0) {
              setExpanded(!expanded);
            }
          }}
        >
          <span className='flex-1 text-left'>

            {label}
          </span>
          {subNavItems && subNavItems.length > 0 && (

            <MdExpandMore className={cn('w-5 h-5 transition-transform', {
              'transform rotate-180': expanded
            })} />
          )}
        </Button>
      </Link>
      {
        subNavItems && subNavItems.length > 0 && expanded && (
          <div className='flex flex-col ml-6 border-y-1 border-border'>
            {
              subNavItems.map((item) => {
                return (
                  <MobileServiceNavButton key={item.label} {...item} />
                )
              })
            }
          </div>
        )
      }
    </div>
  )
  return button;
}

export {
  MobileServiceNavButton,
  MobileNavButton,
  TopLevelNavButton,
  SubNavButton,
  TopLevelNavOutlineButton,
  DropdownNavButton
}