import React from "react";
import CommunityShopLayout from "../../common_component/layout/CommunityShopLayout";
import { Outlet, useParams } from "react-router-dom";
import SideMenu from "./SideMenu";
import api from "networking/api";
import { observer } from "mobx-react";
import { pathify } from "../../util/util";

const CommunityShop: React.FC = observer(() => {
  const { serviceId, schoolId } = useParams();

  const pathifiedSchoolId = pathify(schoolId!)
  const { data: schoolData } = api.misc.useSchoolData(pathifiedSchoolId);

  const currentService = schoolData?.services.find((s) => s.id === serviceId);
  const showSideNav = (currentService?.mcats?.length ?? 0) > 1;

  return (
    <div>
      <CommunityShopLayout
        leftItems={showSideNav ? <SideMenu /> : undefined}
      >
        <>
          <Outlet />
        </>
      </CommunityShopLayout>
    </div>
  )
})

export default CommunityShop;
