import React from 'react';
import { Controller, type UseControllerProps } from "react-hook-form";
import { Label } from '../base/label';
import { ComboBox } from '../base/combobox';
import { GroupBase } from 'react-select';
import { Props } from "react-select/dist/declarations/src/Select";
import { MdSearch } from 'react-icons/md';
import InfoTooltip from 'components/tooltip/InfoTooltip';

export interface ComboBoxProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
> extends UseControllerProps<any>, Omit<Props<Option, IsMulti, Group>, 'name' | 'onChange'> {
  label?: string;
  labelRight?: React.ReactNode;
  infoTooltipMessages?: string[] | React.ReactNode[];
  withSearchIcon?: boolean;
  minWidth?: number;
  borderColor?: string;
  helperText?: string;
  onChange?: (value: any) => void; // Add onChange prop
}

const ComboBoxField = <Option, IsMulti extends boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: ComboBoxProps<Option, IsMulti, Group>
) => {
  const {
    label,
    labelRight,
    options,
    required,
    placeholder,
    name,
    control,
    isMulti,
    rules,
    isLoading,
    isDisabled,
    isClearable,
    infoTooltipMessages,
    withSearchIcon,
    minWidth,
    borderColor,
    helperText,
    onChange: handleChange // Destructure onChange prop
  } = props;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field: { value, onChange, name, onBlur, ref }, fieldState: { error } }) => (
        <div>
          {label && (
            <div className='flex items-end mb-1'>
              <Label htmlFor={name} className="flex-1">
                {label}{required ? <span className="text-danger-foreground"> *</span> : ''}
                {
                  helperText && (
                    <span className="mb-1 ml-2 text-xs font-normal text-text-helper">
                      {helperText}
                    </span>
                  )
                }
              </Label>
              {labelRight}
              {
                infoTooltipMessages && infoTooltipMessages.length > 0 && (
                  <InfoTooltip messages={infoTooltipMessages} />
                )
              }
            </div>
          )}
          <ComboBox
            {...props}
            ref={ref}
            styles={{
              control: (base) => ({
                ...base,
                ...(error?.message ? {
                  borderColor: 'hsl(var(--destructive))',
                } : {}),
                ...(minWidth ? {
                  minWidth: `${minWidth}px`,
                } : {}),
                ...(borderColor ? {
                  borderColor: `hsl(var(--${borderColor}))`,
                } : {
                  borderColor: `hsl(var(--border-input))`,
                }),
              }),
              placeholder: (base) => ({
                ...base,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }),
            }}
            required={required}
            isDisabled={isDisabled}
            isMulti={isMulti}
            options={options}
            placeholder={
              <div className="flex items-center gap-1">
                {withSearchIcon && (
                  <MdSearch className="w-5 h-5" />
                )}
                <p>
                  {placeholder}
                </p>
              </div>
            }
            name={name}
            isClearable={isClearable}
            isSearchable
            isLoading={isLoading}
            onBlur={onBlur}
            value={options.find(c => c.value === value) ?? null} // pass null to clear the value
            onChange={(val) => {
              onChange(val?.value);
              if (handleChange) handleChange(val);
            }}
          />
          {error?.message ? (
            <div className="mt-1 text-xs text-destructive">{error?.message}</div>
          ) : null}
        </div>
      )}
    />
  );
};

export default ComboBoxField;
