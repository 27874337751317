import React from "react";
import { useForm } from "react-hook-form";
import { unstable_usePrompt as usePrompt, useNavigate, useParams } from "react-router-dom";
import { observer } from "mobx-react";
import { Helmet } from "react-helmet";
import _ from "lodash";

import { useStore } from "../../../store/store";
import api from "networking/api";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../../common_component/base/card";
import Breadcrumbs from "../../../common_component/layout/components/Breadcrumbs";
import ComboBoxField from "../../../common_component/form/ComboBoxField";
import { Button } from "../../../common_component/base/button";
import InputField from "../../../common_component/form/InputField";
import { useToast } from "../../../common_component/base/use-toast";
import { Skeleton } from "../../../common_component/base/skeleton";
import { Dialog, DialogContent, DialogTitle, DialogFooter, DialogHeader } from '../../../common_component/base/dialog'
import InfoTooltip from "../../../common_component/tooltip/InfoTooltip";
import { Label } from "../../../common_component/base/label";
import FormGroup from "../../../common_component/form/FormGroup";

import { rootBreadcrumbItems } from "../AdminPage";
import { MaintenanceTable } from "./MaintenanceTable";
import { RowItem, columns } from "./Columns";
import { PayableBinding, PayableMaintainablesResponse, TaskFailed } from "networking/models/admin";
import CheckboxField from "components/form/CheckboxField";
import { Checkbox } from "components/base/checkbox";
import useDeferredTask from "networking/hooks/useDeferredTask";

type FormData = {
  bindIds: string[];
  preview: boolean;
  hidePartPaid: boolean;
  hideUnpaid: boolean;
}
const PayableMaintenancePage = observer(() => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const store = useStore();
  const defaultValues = {
    bindIds: [],
    preview: false,
    hidePartPaid: false,
    hideUnpaid: false,
  }
  const { control, watch, setValue, clearErrors, setError, formState: { isSubmitting, errors }, handleSubmit, reset } = useForm<FormData>({
    defaultValues,
  });
  const [disabledBindings, setDisabledBindings] = React.useState<string[]>([]);
  const [bindingsToDisable, setBindingsToDisable] = React.useState<PayableBinding[]>([]);
  const currentSchool = store.admin.currentSchool;

  const { data: deferredData, mutate: refetchMaintainables, isValidating } = api.admin.useMaintainables(currentSchool);
  const { data: allBindsData, isLoading: isLoadingAllBinds, mutate: refetchAllBinds } = api.admin.useAllBindRules(currentSchool);
  const { data, isLoading: isLoadingMaintainables } = useDeferredTask<PayableMaintainablesResponse>(deferredData?.rtype === 'defer' ? deferredData?.task_id : undefined) //api.admin.useDeferred<PayableMaintainablesResponse>(deferredData?.task_id);
  let deferredResults = undefined;
  if (deferredData?.rtype === 'payable_maintenance_maintainables') {
    deferredResults = deferredData;
  } else if (deferredData?.rtype === 'task') {
    deferredResults = deferredData.results as PayableMaintainablesResponse;
  } else if (data?.results && data?.results?.rtype === 'payable_maintenance_maintainables') {
    deferredResults = data.results as PayableMaintainablesResponse;
  }
  const isLoading = isLoadingMaintainables || isLoadingAllBinds;
  const bindIds = watch('bindIds') || [];
  const disableRules = (values: FormData) => {
    if (values.bindIds.length === 0) {

      setError('bindIds', {
        message: 'Please select at least one rule to remove',
      });
      return;
    } else {
      return api.admin.disableMaintenanceRules({
        schoolId: currentSchool,
        bindIds: values.bindIds,
        preview: values.preview,
        hidePartPaid: values.hidePartPaid,
        hideUnpaid: values.hideUnpaid,
      }).then((response) => {
        if (response.data) {
          if (!values.preview) {
            setBindingsToDisable([]);
            if (response.data.disable_binds_result.binds_disabled_count > 0) {
              setDisabledBindings((prev) => [...prev, ...values.bindIds]);
            }
          }
          const updatedPartPaymentCount = response.data.disable_binds_result.updated_part_payment_count;
          const updatedNoPaymentCount = response.data.disable_binds_result.updated_no_payment_count;
          toast({
            title: `Success ${values.preview ? '(PREVIEW)' : ''}`,
            description: <>
              <p>
                Disabled {response.data.disable_binds_result.binds_disabled_count} rule{response.data.disable_binds_result.binds_disabled_count === 1 ? '' : 's'}
              </p>
              {
                updatedPartPaymentCount > 0 && (
                  <p>
                    Updated {updatedPartPaymentCount} payable{updatedPartPaymentCount === 1 ? '' : 's'} with part payment
                  </p>
                )
              }
              {
                updatedNoPaymentCount > 0 && (
                  <p>
                    Updated {updatedNoPaymentCount} payable{updatedNoPaymentCount === 1 ? '' : 's'} with no payment
                  </p>
                )
              }
            </>,
            duration: 5000,
          });
        } else {
          toast({
            title: 'Error',
            description: 'Something went wrong',
            duration: 5000,
          });
        }
      });
    }
  }
  const handleDisableRule = (bindings: PayableBinding[]) => {
    setBindingsToDisable(bindings)
    reset({
      ...defaultValues,
      bindIds: bindings.length === 1 ? [bindings[0].bind_payable_id] : [],
    });
  }
  const columnsWithAction = React.useMemo(() => {
    return columns
      .concat([
        {
          accessorKey: "proposedActions",
          header: "Proposed actions",
          cell: ({ row, cell }) => {
            const cellValue = cell.getValue() as RowItem['proposedActions'];
            const isExpanded = row.getIsExpanded();
            if (!isExpanded) {
              return (
                <div className='flex items-center gap-2'>
                  {
                    (cellValue?.add ?? 0) > 0 && (
                      <Button
                        className="w-[85px] whitespace-nowrap bg-primary-light/90 hover:bg-primary-light border-1 text-primary border-primary-dark"
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          row.toggleExpanded();
                        }}
                      >
                        {cellValue?.add} Add
                      </Button>
                    )
                  }
                  {
                    (cellValue?.remove ?? 0) > 0 && (
                      <Button
                        className="w-[85px] whitespace-nowrap bg-danger/90 border-1 text-danger-foreground border-border-danger hover:bg-danger"
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          row.toggleExpanded();
                        }}
                      >
                        {cellValue?.remove} Remove
                      </Button>
                    )
                  }
                </div>
              )
            } else {
              return null;
            }
          },
        },
        {
          id: "actions",
          header: "Actions",
          cell: ({ row }) => (
            <Button
              size="xs"
              variant={"outline"}
              onClick={(e) => {
                e.stopPropagation();
                const allBindsGroupedByProtoPayableId = _.groupBy(allBindsData?.roll_binds, 'proto_payable_id');
                const rollBindings = allBindsGroupedByProtoPayableId[row.original.id] || [];
                if (rollBindings) {
                  handleDisableRule(rollBindings)
                }
              }}>
              Disable rule
            </Button>
          ),
          enableSorting: false,
          enableHiding: false,
        }
      ])
  }, [allBindsData?.roll_binds]);

  const getReasonLabel = (reason: string) => {
    switch (reason) {
      case 'bind_not_applicable':
        return 'No longer applicable';
      case 'unapplied_bind':
        return 'Eligible for payable'
      case 'student_left':
        return 'Student has left'
      default: return reason;
    }
  }

  const tableData = React.useMemo(() => {
    if (!isLoading) {
      const allBindsGroupedByProtoPayableId = _.groupBy(allBindsData?.roll_binds, 'proto_payable_id');
      const maintainablesGroupedByProtoPayableId = _.groupBy(deferredResults?.bind_payables_by_id, 'proto_payable_id');

      const actionsGroupedByBinding = _.groupBy(deferredResults?.payable_maintenance_actions, 'bind_payable_id');
      return Object.keys(allBindsGroupedByProtoPayableId || {}).map((protoPayableId) => {
        const rollBindings = allBindsGroupedByProtoPayableId[protoPayableId] || [];
        const rollBind = rollBindings[0];
        const bindings = maintainablesGroupedByProtoPayableId[protoPayableId] || [];
        const addActions = bindings.map((binding) =>
          actionsGroupedByBinding[binding.bind_payable_id]
            .filter((action) => action.rtype === 'AddStudentPayable')
            .filter((action) => {
              // Hide actions with max instance exceeded
              const studentMaxInstancePayables = deferredResults?.max_instance_payables_by_student_id[action.student_id_ext] || [];
              const isMaxed = studentMaxInstancePayables?.find((p) => p.proto_payable_id === binding.proto_payable_id);
              return !isMaxed;
            })
        );
        const totalToAdd = _.sum(addActions.map((bindings) => bindings.length)); // Sum of all add actions across all bindings for this payable

        const totalToRemove = _.sum(bindings.map((binding) => actionsGroupedByBinding[binding.bind_payable_id].filter((action) => action.rtype === 'RemoveStudentPayable').length));
        return {
          id: protoPayableId,
          payableName: rollBind.proto_payable_name,
          allBindings: rollBindings,
          groups: rollBindings
            .filter((binding) => !disabledBindings.includes(binding.bind_payable_id))
            .sort((a, b) => a.group_identifier.localeCompare(b.group_identifier, 'en', { sensitivity: 'base' }))
            .map((b) => `${b.metagroup === 'year_level' ? 'Year ' : ''}${b.group_identifier}`).join(', '),
          bindings: rollBindings
            .filter((binding) => !disabledBindings.includes(binding.bind_payable_id))
            .map((binding) => {
              const bindingActions = actionsGroupedByBinding[binding.bind_payable_id] || [];
              return {
                binding: binding,
                group: binding.group_identifier,
                metagroup: binding.metagroup,
                studentsToAdd: bindingActions
                  .filter((action) => action.rtype === 'AddStudentPayable')
                  .filter((action) => {
                    // Hide actions with max instance exceeded
                    const studentMaxInstancePayables = deferredResults?.max_instance_payables_by_student_id[action.student_id_ext] || [];
                    const isMaxed = studentMaxInstancePayables?.find((p) => p.proto_payable_id === binding.proto_payable_id);
                    return !isMaxed;
                  })
                  .map((action) => {
                    const student = deferredResults?.students_by_id[action.student_id_ext]; // studentsGroupedById[action.student_id_ext]?.[0];
                    const studentMaxInstancePayables = deferredResults?.max_instance_payables_by_student_id[action.student_id_ext] || [];
                    const isMaxed = studentMaxInstancePayables?.find((p) => p.proto_payable_id === binding.proto_payable_id);
                    return {
                      studentIdExt: action.student_id_ext,
                      studentName: student ? `${student.first_name} ${student.last_name}` : '-',
                      studentRoom: student?.room || '-',
                      reason: getReasonLabel(action.reason),
                      exceededMaxInstance: !!isMaxed,
                    }
                  }),
                studentsToRemove: bindingActions.filter((action) => action.rtype === 'RemoveStudentPayable').map((action) => {
                  const student = deferredResults?.students_by_id[action.student_id_ext];
                  return {
                    studentIdExt: action.student_id_ext,
                    studentName: student ? `${student.first_name} ${student.last_name}` : 'Student Left',
                    studentRoom: student ? student.room : '-',
                    reason: getReasonLabel(action.reason),
                  }
                }),
              }
            }),
          proposedActions: {
            remove: totalToRemove,
            add: totalToAdd,
          },
        }
      })
        // Filter out payable rules with no bindings
        .filter((row) => row.bindings.length > 0)
    }
  }, [deferredResults?.bind_payables_by_id, isLoading, disabledBindings]);

  return (
    <div>
      <Helmet>
        <title>Payable Maintenance</title>
      </Helmet>
      <Dialog open={bindingsToDisable && bindingsToDisable.length > 0} onOpenChange={() => {
        setBindingsToDisable([]);
      }}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Disable Payable Rule</DialogTitle>
          </DialogHeader>
          <div className='flex flex-col gap-4 text-card-foreground'>
            <div className="flex flex-col">
              <p className="text-sm font-museo text-text-helper">
                Payable
              </p>
              <p className="font-bold">
                {bindingsToDisable[0]?.proto_payable_name}
              </p>
            </div>
            {
              bindingsToDisable.length > 1 ? (
                <div className="flex flex-col gap-2">
                  <p>
                    Select rules to disable
                  </p>
                  <div className="flex flex-col gap-1">
                    {
                      bindingsToDisable
                        .filter((binding) => !disabledBindings.includes(binding.bind_payable_id))
                        .sort((a, b) => a.group_identifier.localeCompare(b.group_identifier, 'en', { sensitivity: 'base' }))
                        .map((binding) => {
                          return (
                            <div>
                              <Checkbox
                                name={`bindIds.${binding.bind_payable_id}`}
                                onCheckedChange={(checked) => {
                                  clearErrors('bindIds');
                                  setValue('bindIds', _.xor(bindIds, [binding.bind_payable_id]));
                                }}
                                label={`${binding.metagroup === 'year_level' ? 'Year ' : ''}${binding.group_identifier}`}
                                checked={bindIds.includes(binding.bind_payable_id)}
                              />
                            </div>
                          )
                        })
                    }
                    {errors.bindIds?.message && (
                      <p className="text-sm text-destructive">
                        {errors.bindIds?.message}
                      </p>
                    )}
                  </div>
                </div>
              ) : (
                <>
                  <p>
                    Are you sure you want to remove the rule on <span className='font-bold'>{bindingsToDisable[0]?.group_identifier}</span>?
                  </p>
                </>
              )
            }

            <div className="flex items-center gap-3 py-2">
              <CheckboxField
                control={control}
                name="hidePartPaid"
                label="Hide part paid"
              />
              <CheckboxField
                control={control}
                name="hideUnpaid"
                label="Hide unpaid"
              />
              <InfoTooltip
                messages={[
                  "We recommend you leave these unticked, so caregivers can continue to see outstanding amounts on their myKindo account. These outstanding amounts will also be included in any payment request emails sent."
                ]}
              />
            </div>
          </div>
          <DialogFooter className="flex flex-row sm:flex-row">
            <div className="flex items-center flex-1">
              <CheckboxField
                control={control}
                name="preview"
                label="Preview"
              />
            </div>
            <Button variant="outline" onClick={() => setBindingsToDisable([])}>Cancel</Button>
            <Button disabled={isSubmitting} onClick={handleSubmit(disableRules)}>
              Disable
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className="flex flex-col gap-4">
        <div>
          <Breadcrumbs
            items={[
              ...(rootBreadcrumbItems),
              { label: 'Payable Maintenance', to: `/app/admin/payable-maintenance`, active: true },
            ]}
          />
          <p className="text-sm">
            {currentSchool}
          </p>
        </div>
        <div className="flex flex-col gap-2 pb-6">
          <Card className="transition-shadow shadow-md hover:shadow-xl rounded-3xl">
            <CardHeader className="flex flex-col gap-2">
              <CardTitle className="font-museo">
                Payable Maintenance
              </CardTitle>
              <CardDescription>
                Manage previously applied payable rules
              </CardDescription>
            </CardHeader>
            <CardContent className="flex flex-col gap-6 p-0 sm:p-0">
              <MaintenanceTable
                schoolId={currentSchool}
                data={tableData || []}
                columns={columnsWithAction}
                isLoading={isLoading || isValidating}
                onRefresh={() => {
                  refetchMaintainables();
                }}
              />
            </CardContent>
          </Card>
        </div>

      </div>
    </div>
  )
})

export default PayableMaintenancePage;
